import { makeStyles, Spinner } from '@fluentui/react-components'

export function SymptomReportLoadingIcon() {
  const styles = useStyles()

  return <Spinner size="extra-tiny" className={styles.spinner} />
}

const useStyles = makeStyles({
  spinner: {
    padding: '0 4px',
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
})
