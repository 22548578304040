import { IPropertyItemValue } from '../PropertyTable.interface'
import { Text } from '@fluentui/react-components'
import { LinkPropertyComponent, isLinkPropertyItemValue } from './LinkPropertyComponent'
import { ObjectPropertyComponent } from './ObjectPropertyComponent'
import { TagsPropertyComponent, isAdoTagsPropertyItemValue } from './TagsPropertyComponent'

export const renderPropertyItemValue = (value: IPropertyItemValue) => {
  if (value === undefined) {
    return null
  }

  if (isLinkPropertyItemValue(value)) {
    return <LinkPropertyComponent {...value} />
  }

  if (isAdoTagsPropertyItemValue(value)) {
    return <TagsPropertyComponent {...value} />
  }

  if (typeof value === 'object') {
    return <ObjectPropertyComponent value={value} />
  }

  if (typeof value === 'string' || typeof value === 'number') {
    return <Text>{value}</Text>
  }

  if (typeof value === 'boolean') {
    return <Text>{value ? 'True' : 'False'}</Text>
  }

  return null
}
