import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  wrapper: {
    marginBottom: '32px',
  },

  title: {
    marginBottom: '12px',
  },

  blue: {
    color: tokens.colorCompoundBrandForeground1,
  },

  loadMore: {
    marginTop: '20px',
  },

  loadMoreTickets: {
    backgroundColor: tokens.colorNeutralBackground6,
  },

  loading: {
    minHeight: '600px',
  },
})
