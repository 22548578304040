import { ProductNames } from '@copilot-dash/domain'
import { Column } from '../../../../components/Layout'
import { GlobalProductProvider } from '../../../../providers/product'
import { HeaderNotificationBarForOcvPermission } from './HeaderNotificationBarForOcvPermission'

export function HeaderNotificationBar() {
  const hasOcvAccess = application.store.use((state) => state.basic.hasOcvAccess)
  const product = GlobalProductProvider.useCurrenProduct()
  const showAccessBanner = !hasOcvAccess && product === ProductNames.TeamsMeetingCopilot

  return <Column>{showAccessBanner && <HeaderNotificationBarForOcvPermission />}</Column>
}
