import { z } from 'zod'

export interface ApiCommentItem {
  readonly commentId: string
  readonly ticketId: string
  readonly userId: string
  readonly content: string
  readonly createTime: string
  readonly modifyTime?: string
}

export interface ApiCommentListResponse {
  readonly comments: Array<ApiCommentItem>
}

export const apiCommentItemSchema = z.object({
  commentId: z.string(),
  ticketId: z.string(),
  userId: z.string(),
  content: z.string(),
  createTime: z.string(),
  modifyTime: z.string().optional(),
})

export const apiCommentListResponseSchema = z.object({
  comments: z.array(apiCommentItemSchema),
})
