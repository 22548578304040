import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketAdditionalContext, ITicketData, ITicketSessionData, getProductNameById } from '@copilot-dash/domain'
import { compact, entries, mapValues } from 'lodash'
import { getErrorMessage } from '../../../../../components/ErrorConfigs/utils/getErrorMessage'
import { IPropertyItem } from '../../../../../components/Table/PropertyTable/PropertyTable'
import { useDateDisplayTextParser } from '../../../../../hooks/useDateDisplayTextParser'
import { tryParseUrl } from '../../common/Utils'

export class TicketContextConfig {
  static buildBlockItems(
    ticketData: ITicketData,
    timeParser: ReturnType<typeof useDateDisplayTextParser>,
    session: ITicketSessionData | undefined,
    emailSnapshot: PromiseSnapshot<string>,
  ): IPropertyItem[] {
    const adoLink = (() => {
      const vsoLink = ticketData?.vsoLink
      if (!vsoLink) {
        return null
      }
      const vsoUrl = tryParseUrl(vsoLink)
      if (!vsoUrl) {
        return null
      }
      const vsoTicketId =
        vsoUrl.pathname
          .split('/')
          .filter((segment) => segment.length > 0)
          .pop() ?? vsoLink
      return { vsoTicketId, vsoLink }
    })()

    let email: string
    switch (emailSnapshot.status) {
      case 'waiting':
        email = 'Loading...'
        break
      case 'done':
        email = emailSnapshot.data
        break
      case 'error':
        email = getErrorMessage(emailSnapshot.error)
        break
    }

    return compact([
      {
        name: 'DateTime',
        value: timeParser(ticketData.dateTimeUtc),
      },
      {
        name: 'EmailAddress',
        value: email,
      },
      {
        name: 'VerbatimFeedback',
        value: session?.verbatim,
      },
      {
        name: 'Categories',
        value: ticketData.categories,
      },
      {
        name: 'Status',
        value: ticketData.status,
      },
      {
        name: 'ResolvedReason',
        value: ticketData.resolvedReason,
      },
      {
        name: 'ClientName',
        value: ticketData.clientName,
      },
      {
        name: 'ScenarioName',
        value: ticketData.scenarioName,
      },
      {
        name: 'ExternalTicketId',
        value: ticketData.ticketId,
      },
      {
        name: 'Ring',
        value: ticketData.ring,
      },
      {
        name: 'ThumbFeedback',
        value: ticketData.thumbFeedback,
      },
      {
        name: 'TicketSource',
        value: ticketData.ticketSource,
      },
      {
        name: 'LogicalId',
        value: ticketData.logicalId,
      },
      {
        name: 'HasVerbatim',
        value: ticketData.hasVerbatim,
      },
      {
        name: 'ExternalTicketIds',
        value: ticketData.externalTicketIds,
      },
      {
        name: 'SourceId',
        value: ticketData.sourceId,
      },
      {
        name: 'OCVLink',
        value: {
          href: ticketData.oCVLink,
        },
      },
      {
        name: 'OCVLanguage',
        value: ticketData.oCVLanguage,
      },
      {
        name: 'ProductName',
        value: getProductNameById(ticketData.productId),
      },
      {
        name: 'E2eLatency',
        value: ticketData.e2eLatency,
      },
      {
        name: 'UiHost',
        value: ticketData.uiHost,
      },
      adoLink && {
        name: 'AdoLink',
        value: {
          text: adoLink.vsoTicketId,
          href: adoLink.vsoLink,
        },
      },
      ticketData.vsoDescription && {
        name: 'AdoDescription',
        value: ticketData.vsoDescription,
      },
      ticketData.vsoTags &&
        ticketData.vsoLink && {
          name: 'AdoTags',
          value: {
            tags: ticketData.vsoTags,
          },
        },
    ])
  }

  static buildAdditionalContextItems(context: ITicketAdditionalContext): IPropertyItem[] {
    const mappedObject = mapValues(context, (value, key) => {
      if (!value) {
        return value
      }

      if (value && value.type === 'Metadata' && typeof value.result === 'string') {
        const urlValue = tryParseUrl(value.result)
        if (urlValue) {
          return {
            href: urlValue.href,
          }
        }
      }

      return value.resultObject ?? value.result
    })

    return entries(mappedObject).map(([key, value]) => {
      return {
        name: key,
        value: value,
      }
    })
  }
}
