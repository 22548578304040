import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { LogoutScreen } from '../../../screens/logout/LogoutScreen'

export function LogoutRoutePage() {
  return (
    <PageTitle title="Logout">
      <LogoutScreen />
    </PageTitle>
  )
}
