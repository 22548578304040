import { I3sLogData, I3sLogDataItem } from '@copilot-dash/domain'
import { Tree, TreeItem, TreeItemLayout, makeStyles } from '@fluentui/react-components'

import { TreeItemGroundingDataSuspension } from './TreeItemGroundingDataSuspension'
import { TreeItemTitle } from './common/TreeItemTitle'

interface IProps {
  readonly data: I3sLogData
  readonly item: I3sLogDataItem
}

export function SssLogViewTreeItemOfflineGrounding({ data, item }: IProps) {
  const styles = useStyles()

  return (
    <TreeItem itemType="branch">
      <TreeItemTitle type="offlineGrounding" />
      <Tree>
        <TreeItem itemType="leaf">
          <TreeItemLayout className={styles.content}>
            <TreeItemGroundingDataSuspension />
          </TreeItemLayout>
        </TreeItem>
      </Tree>
    </TreeItem>
  )
}

const useStyles = makeStyles({
  content: {
    width: '100%',
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
})
