import { Spinner } from '@fluentui/react-components'
import FeedbackTrend from '../children/McpExperimentFeedbackTrend'
import { ErrorView } from '../../../components/Error'
import { useStyles } from '../McpExperimentExperimentScreen.styles'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IMcpExperimentFeedbackResponse } from '@copilot-dash/domain'

interface IProps {
  snapshot: PromiseSnapshot<IMcpExperimentFeedbackResponse>
  chartTimeWindow: string
}

export const ScreenLayout: React.FC<IProps> = ({ snapshot, chartTimeWindow }) => {
  const styles = useStyles()

  return (
    <div className={styles.dataContainer}>
      {snapshot.status === 'waiting' ? (
        <Spinner className={styles.spinnerStyle} size="extra-small" />
      ) : snapshot.status === 'done' ? (
        <FeedbackTrend data={snapshot.data} window={chartTimeWindow} />
      ) : (
        <ErrorView error={snapshot.error} />
      )}
    </div>
  )
}
