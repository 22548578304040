import { getErrorConfigs } from './getErrorConfigs'

export function getErrorMessage(error: unknown): string {
  const builders = getErrorConfigs(error)

  for (const builder of builders) {
    const message = builder.buildMessage?.()
    if (message) {
      return message
    }
  }

  return String(error)
}
