import { LegendItem } from 'chart.js'
import { createRoot, Root } from 'react-dom/client'
import { Legend as HtmlLegend } from './Legend'

const getOrCreateRoot = (function () {
  const rootMap = new Map<string, Root>()
  function _getOrCreateRoot(containerID: string) {
    if (rootMap.has(containerID)) {
      return rootMap.get(containerID)
    }
    const dom = document.getElementById(containerID)
    const root = dom && createRoot(dom)
    if (root) {
      rootMap.set(containerID, root)
      return root
    }
    return null
  }
  _getOrCreateRoot.unload = function (containerID?: string) {
    if (containerID) {
      rootMap.delete(containerID)
      return
    }
    rootMap.clear()
  }
  return _getOrCreateRoot
})()

export const DoughnutHtmlLegendPlugin = {
  id: 'htmlLegend',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  afterUpdate(chart: any, _args: any, options: any) {
    const containerRoot = getOrCreateRoot(options.containerID)

    const items = chart.options.plugins.legend.labels.generateLabels(chart).map((item: LegendItem) => ({
      index: item.index,
      datasetIndex: item.datasetIndex,
      strokeStyle: item.strokeStyle,
      hidden: item.hidden,
      text: item.text,
      fillStyle: item.fillStyle,
    }))

    const legendProps = {
      items: items,
      handleClickItem: (item: (typeof items)[0]) => {
        const chartType = chart.config.type
        if (chartType === 'pie' || chartType === 'doughnut') {
          chart.toggleDataVisibility(item.index)
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex))
        }
        chart.update()
      },
    }

    containerRoot && containerRoot.render(<HtmlLegend {...legendProps} />)
  },
  uninstall(_: unknown, __: unknown, options: unknown & { containerID: string }) {
    getOrCreateRoot.unload(options.containerID)
  },
}
