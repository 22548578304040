import { ITelemetryMetric } from '@copilot-dash/domain'
import {
  Accordion,
  AccordionItem,
  AccordionToggleEventHandler,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components'
import { TicketScreenStore } from '../../../../TicketScreenStore'
import { TicketScreenOutlineTab } from '../../../../store/IState'
import { MetricCardBody } from './MetricCardBody'
import { MetricCardHeader } from './MetricCardHeader'
import { useEffect, useRef } from 'react'

interface IProps {
  readonly messageId: string
  readonly metric: ITelemetryMetric
}

export function MetricCard({ messageId, metric }: IProps) {
  const styles = useStyles()
  const actions = TicketScreenStore.useActions()
  const selected = TicketScreenStore.use((state) => state.selectedScopeId === metric.scopeId)

  const rootElementRef = useRef<HTMLDivElement>(null)
  const selectedFromThisComponent = useRef<boolean>(false)

  const onToggle: AccordionToggleEventHandler = (event, data) => {
    if (data.openItems.length === 0) {
      actions.selectTurn(messageId)
    } else {
      actions.selectOutlineTab(TicketScreenOutlineTab.CallFlow)
      actions.selectTurnScope(messageId, metric.scopeId ?? '')

      selectedFromThisComponent.current = true
    }
  }

  useEffect(() => {
    const rootElement = rootElementRef.current
    if (!rootElement) {
      return
    }

    if (selected && !selectedFromThisComponent.current) {
      if (!isInViewport(rootElement)) {
        rootElementRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
      return
    }

    selectedFromThisComponent.current = false
  }, [selected])

  return (
    <Accordion
      ref={rootElementRef}
      className={mergeClasses(styles.root, selected && styles.selected)}
      collapsible
      onToggle={onToggle}
      openItems={selected ? ['default'] : []}
    >
      <AccordionItem value="default">
        <MetricCardHeader metric={metric} selected={selected} />
        <MetricCardBody metric={metric} />
      </AccordionItem>
    </Accordion>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '0px',
    border: `1px solid ${tokens.colorNeutralStroke2}`,
    borderRadius: tokens.borderRadiusMedium,
    // backgroundColor: tokens.colorNeutralBackground1,
    overflow: 'hidden',
    transition: `box-shadow ${tokens.durationNormal}`,
  },
  selected: {
    border: `1px solid ${tokens.colorBrandStroke1}`,
    boxShadow: tokens.shadow28,
  },
})

function isInViewport(element: HTMLElement) {
  const rect = element.getBoundingClientRect()
  return rect.top >= 0 && rect.left >= 0 && rect.bottom <= window.innerHeight && rect.right <= window.innerWidth
}
