import { ErrorViewBoundary } from '../Error'
import { AnyDataStore } from './AnyDataStore'
import { AnyDataLayout } from './layout/AnyDataLayout'

interface IProps {
  readonly data: unknown
}

export function AnyData({ data }: IProps) {
  return (
    <ErrorViewBoundary label="AnyData">
      <AnyDataStore data={data}>
        <AnyDataLayout />
      </AnyDataStore>
    </ErrorViewBoundary>
  )
}
