import { makeStyles, shorthands } from '@fluentui/react-components'

export const useStyles = makeStyles({
  button: {
    maxWidth: 'auto',
    maxHeight: 'auto',
    ...shorthands.border(0),
    ...shorthands.padding(0),
    ...shorthands.margin(0),
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: 'transparent',
    },
  },
})
