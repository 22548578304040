import { INewTicketData, TicketEmotionType, ColumnKey, ColumnIdNaColumnIdNameMapping } from '@copilot-dash/domain'
import { createExtendedTableColumn, ExtendedTableColumnDefinition } from '../Table/ExtendedTableColumn'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { mapADOStateToCopilotDashState } from '../TicketSummaryPanel/children/utils'
import { memo } from 'react'
import { useStyles } from './ColumnComponent.styles'
import { CopilotDashTooltip } from '../CopilotDashTooltip/CopilotDashTooltip'
import { mergeClasses, Text } from '@fluentui/react-components'
import { getTicketStepStageByFields } from '../TicketActionForm/utils'

export const ColumnConfigStatus: ExtendedTableColumnDefinition<INewTicketData> =
  createExtendedTableColumn<INewTicketData>({
    columnId: ColumnKey.Status,
    columnName: ColumnIdNaColumnIdNameMapping[ColumnKey.Status],
    isResizable: true,
    columnSize: {
      minWidth: 50,
      idealWidth: 120,
      padding: 0,
    },

    renderHeaderCell: () => <TableCellLayoutWithStyles>State</TableCellLayoutWithStyles>,
    renderCell: (item: INewTicketData) => (
      <TableCellLayoutWithStyles truncate>
        <RenderStatus item={item} />
      </TableCellLayoutWithStyles>
    ),
  })

const RenderStatus = memo(({ item }: { item: INewTicketData }) => {
  const styles = useStyles()
  if (item.thumbFeedback === TicketEmotionType.Positive) {
    return <Text className={styles.grayColor}>N/A</Text>
  } else {
    let text = ''

    const copilotDashState = mapADOStateToCopilotDashState(item.status, item.reasoning)
    if (item.status === 'Closed') {
      text = copilotDashState
    } else {
      text = getTicketStepStageByFields(copilotDashState, item.rootCauseList ?? [], item.teamArea)
    }
    return (
      <CopilotDashTooltip
        relationShip="label"
        toolTipContent={
          <Text truncate className={styles.tooltipContent}>
            {text}
          </Text>
        }
        className={styles.tooltip}
        toolTipParentContent={
          <Text
            className={mergeClasses(
              styles.text,
              text.startsWith('Closed') && styles.green,
              text === 'Team Assigned' && styles.yellow,
              text === 'Root Caused' && styles.blue,
            )}
          >
            {text}
          </Text>
        }
      />
    )
  }
})

RenderStatus.displayName = 'RenderStatus'
