interface IParams<T> {
  readonly primary: Promise<T>
  readonly secondary: Promise<T>
}

export function raceRequests<T>({ primary, secondary }: IParams<T>): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    race(resolve, reject)
  })

  async function race(resolve: (value: T) => void, reject: (reason?: unknown) => void) {
    primary
      .then((data) => {
        resolve(data)
      })
      .catch(() => {})

    secondary
      .then((data) => {
        resolve(data)
      })
      .catch(() => {})

    try {
      await secondary.catch(() => {})
      await primary
    } catch (error) {
      reject(error)
    }
  }
}
