import { ApiConversationGroup3 } from '@copilot-dash/api'
import { dayjs, PromiseSnapshot } from '@copilot-dash/core'
import { KustoLogTable, TicketCallFlow, TicketCallFlowEvent, TicketCallFlowService } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { compact, sortBy, uniq } from 'lodash'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawConversationGroup3 } from '../actions-raw-conversation/getRawConversationGroup3'
import { getTicketSession } from '../actions-ticket/getTicketSession'
import { validateTicketKustoExpiration } from '../validators/validateTicketKustoData'
import { validateTicketUserConsent } from '../validators/validateTicketUserConsent'

export function getCallFlow(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<TicketCallFlow> {
  return context.getOrFetch<TicketCallFlow>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.callFlow
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.callFlow = snapshot
    },
    fetch: async () => {
      // Check User consent
      const session = await getTicketSession(context, ticketId).promise
      validateTicketUserConsent(ticketId, session)

      // Check interaction
      const interaction = session.interactions.find((item) => item.messageId === messageId)
      if (!interaction) {
        // Check ticket Kusto expiration
        validateTicketKustoExpiration(ticketId, KustoLogTable.BizChat3SLatencyLog, session)

        // Check failed
        throw TicketError.create('NoKustoDueToInteractionFieldIsMissing', {
          ticketId,
          kustoLogTable: KustoLogTable.BizChat3SLatencyLog,
        })
      }

      const group3Promise = getRawConversationGroup3(context, ticketId, messageId).promise
      const group3 = await group3Promise

      const events = generateEvents(group3)
      const services = generateServices(events)

      return {
        events,
        services,
      }
    },
  })
}

function generateEvents(
  group3: ApiConversationGroup3,
  // stateAndDurationLogs: IKustoStateDurationLogItem[],
): TicketCallFlowEvent[] {
  const eventsFromGroup3 = generateEventsGroup3(group3)
  // const eventsFromLogs = generateEventsFromLogs(stateAndDurationLogs)

  // if (eventsFromLogs.length > 0) {
  //   const scopeIdOrderMap: Record<string, number> = {}
  //   eventsFromLogs.forEach((item, index) => {
  //     scopeIdOrderMap[item.id] = index
  //   })

  //   return sortBy(eventsFromGroup3, (item) => {
  //     return scopeIdOrderMap[item.id] ?? 0
  //   })
  // }

  return eventsFromGroup3
}

// function generateEventsFromLogs(stateAndDurationLogs: IKustoStateDurationLogItem[]): TicketCallFlowEvent[] {
//   return stateAndDurationLogs.map((item): TicketCallFlowEvent => {
//     return {
//       id: item.scopeId,
//       name: item.scopeName,
//       label: item.scopeName.split('.')[1] ?? item.scopeName,
//       status: undefined,
//       timestamp: item.timestamp,
//       serviceId: item.scopeName.split('.')[0] ?? item.scopeName,
//     }
//   })
// }

function generateEventsGroup3(group3: ApiConversationGroup3): TicketCallFlowEvent[] {
  const metrics = group3.conversation?.requests?.[0]?.telemetry?.metrics ?? []
  const events = metrics.map((item): TicketCallFlowEvent | undefined => {
    if (!item.scopeId || !item.serviceName) {
      return undefined
    }

    return {
      id: item.scopeId,
      name: item.scopeName ?? item.scopeId,
      label: item.path || item.scopeName?.split('.')[1] || item.scopeName || item.scopeId,
      status: item.status,
      timestamp: item.startTime,
      serviceId: item.serviceName,
    }
  })

  return sortBy(compact(events), (item) => {
    return dayjs(item.timestamp).valueOf()
  })
}

function generateServices(events: TicketCallFlowEvent[]): TicketCallFlowService[] {
  const serviceIds1 = events.map((item) => item.serviceId)
  const serviceIds2 = uniq(compact(serviceIds1))

  return serviceIds2.map((item) => {
    return {
      id: item,
      name: item,
    }
  })
}
