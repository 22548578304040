import { z } from 'zod'
export interface ApiBatchUpdateTicketStatusInfoResponse {
  readonly updateSuccessedExternalTicketIdList: string[]
  readonly updateFailedExternalTicketIdList: string[]
  readonly errorMessage?: string
}

export const ApiBatchUpdateTicketStatusInfoResponseSchema = z.object({
  updateSuccessedExternalTicketIdList: z.array(z.string()),
  updateFailedExternalTicketIdList: z.array(z.string()),
  errorMessage: z.string().optional(),
})
