import { getProductIdByName, ProductNames } from '@copilot-dash/domain'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { KEY_LEFT_DRAWER_OPEN } from './const'
import { createBatchTicketsStore } from './createBatchTicketsStore'
import { createTeamsStore } from './createTeamsStore'
import { createTicketsStore } from './createTicketsStore'
import { createTopIssueStore } from './createTopIssueStore'
import { createRootCauses } from './rootCausesStore'
import { ITeamViewStore } from './types'
import { createRouteStore } from './createRouteStore'

export const useTeamViewStore = create<
  ITeamViewStore,
  [['zustand/subscribeWithSelector', never], ['zustand/immer', never]]
>(
  subscribeWithSelector(
    immer((...args) => {
      const set = args[0]
      const get = args[1]
      return {
        productName: ProductNames.M365Chat,
        route: createRouteStore(...args),
        teams: createTeamsStore(...args),
        rootCauses: createRootCauses(...args),
        tickets: createTicketsStore(...args),
        batchTicketsSubStore: createBatchTicketsStore(...args),
        leftDrawerOpen: (localStorage.getItem(KEY_LEFT_DRAWER_OPEN) ?? 'true') === 'true',
        topIssues: createTopIssueStore(...args),
        openedRightDrawer: null,
        selectedTicket: null,
        orderBy: undefined,
        computed: {
          get filterPanelOpen() {
            return get().openedRightDrawer === 'filterPanel'
          },
          get ticketDetailOpen() {
            return get().openedRightDrawer === 'ticketDetail'
          },
          get columnSettingOpen() {
            return get().openedRightDrawer === 'columnSetting'
          },
          get batchUpdatePanelOpen() {
            return get().openedRightDrawer === 'batchUpdatePanel'
          },
          get productId() {
            return getProductIdByName(get().productName)
          },
        },
        setOrderBy: (type: 'Positive' | 'Reverse' | undefined) => {
          set((state) => {
            state.orderBy = type
          })
        },
        toggleLeftDrawer: (open) => {
          set((state) => {
            if (typeof open === 'function') {
              state.leftDrawerOpen = open(state.leftDrawerOpen)
              localStorage.setItem(KEY_LEFT_DRAWER_OPEN, String(open(state.leftDrawerOpen)))
            } else {
              state.leftDrawerOpen = open
              localStorage.setItem(KEY_LEFT_DRAWER_OPEN, String(open))
            }
          })
        },
        toggleFilterPanel: (open) => {
          set((state) => {
            let bool = open
            if (typeof open === 'function') {
              bool = open(state.computed.filterPanelOpen)
            }
            if (bool) {
              state.openedRightDrawer = 'filterPanel'
            } else {
              state.openedRightDrawer = state.openedRightDrawer === 'filterPanel' ? null : state.openedRightDrawer
            }
          })
        },
        toggleTicketDetail: (open) => {
          set((state) => {
            let bool = open
            if (typeof open === 'function') {
              bool = open(state.computed.ticketDetailOpen)
            }
            if (bool) {
              state.openedRightDrawer = 'ticketDetail'
            } else {
              state.openedRightDrawer = state.openedRightDrawer === 'ticketDetail' ? null : state.openedRightDrawer
            }
          })
        },
        toggleColumnSetting: (open) => {
          set((state) => {
            let bool = open
            if (typeof open === 'function') {
              bool = open(state.computed.columnSettingOpen)
            }
            if (bool) {
              state.openedRightDrawer = 'columnSetting'
            } else {
              state.openedRightDrawer = state.openedRightDrawer === 'columnSetting' ? null : state.openedRightDrawer
            }
          })
        },
        toggleBatchUpdatePanel: (open) => {
          set((state) => {
            let bool = open
            if (typeof open === 'function') {
              bool = open(state.openedRightDrawer === 'batchUpdatePanel')
            }
            if (bool) {
              state.openedRightDrawer = 'batchUpdatePanel'
            } else {
              state.openedRightDrawer = state.openedRightDrawer === 'batchUpdatePanel' ? null : state.openedRightDrawer
            }
          })
        },
        setOpenedRightDrawer: (drawer) => {
          set((state) => {
            state.openedRightDrawer = drawer
          })
        },
        setSelectedTicket: (ticket) => {
          set((state) => {
            state.selectedTicket = ticket
          })
        },
        setCurrentQuery: (query) => {
          set((state) => {
            state.currentQuery = query
          })
        },
      }
    }),
  ),
)
