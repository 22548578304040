import {
  DrawerHeader,
  DrawerHeaderTitle,
  Button,
  Text,
  makeStyles,
  shorthands,
  tokens,
} from '@fluentui/react-components'
import { Dismiss24Regular, Wrench16Filled } from '@fluentui/react-icons'

interface IProps {
  readonly onDismiss: () => void
}

export function ColumnSettingPanelHeader({ onDismiss }: IProps) {
  const styles = useStyles()
  return (
    <DrawerHeader>
      <DrawerHeaderTitle
        action={<Button appearance="subtle" aria-label="Close" icon={<Dismiss24Regular />} onClick={onDismiss} />}
      >
        <Wrench16Filled />
        <Text className={styles.title}>Column settings</Text>
      </DrawerHeaderTitle>
    </DrawerHeader>
  )
}

const useStyles = makeStyles({
  title: {
    ...shorthands.margin(0, 0, 0, '12px'),
    alignItems: 'center',
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightSemibold,
  },
})
