import { TabList, TabListProps } from '@fluentui/react-components'
import { z } from 'zod'
import { AnyDataStore } from '../../AnyDataStore'
import { AnyDataTab } from '../../store/IState'
import { AnyDataBarTab } from './AnyDataBarTab'

export function AnyDataBarTabs() {
  const actions = AnyDataStore.useActions()
  const tabs = AnyDataStore.use((state) => state.tabs)
  const selectedTab = AnyDataStore.use((state) => state.selectedTab)

  const handleOnSelected: TabListProps['onTabSelect'] = (_, data) => {
    const tab = z.nativeEnum(AnyDataTab).safeParse(data.value).data
    if (tab) {
      actions.selectTab(tab)
    }
  }

  return (
    <TabList selectedValue={selectedTab} onTabSelect={handleOnSelected}>
      {tabs.map((item) => (
        <AnyDataBarTab key={item} tab={item} />
      ))}
    </TabList>
  )
}
