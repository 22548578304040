import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import 'overlayscrollbars/overlayscrollbars.css'
import { CSSProperties, ReactNode } from 'react'
import { ScrollbarOptions } from './ScrollbarConfigs'

interface IProps {
  readonly children: ReactNode
  readonly className?: string
  readonly style?: CSSProperties
}

/**
 * https://www.npmjs.com/package/overlayscrollbars-react
 */
export function Scrollbar({ children, className, style }: IProps) {
  return (
    <OverlayScrollbarsComponent className={className} defer={false} options={ScrollbarOptions} style={style}>
      {children}
    </OverlayScrollbarsComponent>
  )
}
