import { makeStyles, shorthands } from '@fluentui/react-components'

export const useStyles = makeStyles({
  avatarIcon: {
    width: '32px',
    height: '32px',
    ...shorthands.margin('12px', 0, 0, '4px'),
    ...shorthands.borderRadius('50%'),
  },
})
