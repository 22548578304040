import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { CheckmarkCircleRegular } from '@fluentui/react-icons'
import { IErrorComponents } from '../../ErrorConfigs/interfaces/IErrorComponents'
import { Row, Spacer } from '../../Layout'

interface IProps {
  readonly components: IErrorComponents
}

export function ErrorViewPopoverHeaderLeading({ components }: IProps) {
  const styles = useStyles()

  switch (components.level) {
    case 'ERROR':
      return (
        <Row vAlign="center" className={mergeClasses(styles.root, styles.error)}>
          {components.icon}
          <Spacer size={8} />
          <span>{components.name}</span>
        </Row>
      )
    case 'WARNING':
      return (
        <Row vAlign="center" className={mergeClasses(styles.root, styles.warning)}>
          {components.icon}
          <Spacer size={8} />
          <span>{components.name}</span>
        </Row>
      )
    case 'OK':
      return (
        <Row vAlign="center" className={mergeClasses(styles.root, styles.ok)}>
          <CheckmarkCircleRegular />
          <Spacer size={8} />
          <span>OK</span>
        </Row>
      )
  }
}

const useStyles = makeStyles({
  root: {
    fontSize: '14px',
    padding: '0px 16px',
  },
  error: {
    color: tokens.colorStatusWarningForeground1,
    fontWeight: tokens.fontWeightSemibold,
  },
  warning: {
    color: tokens.colorStatusWarningForeground1,
  },
  ok: {
    color: tokens.colorStatusSuccessForeground1,
  },
})
