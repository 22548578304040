import { IState } from './IState'

interface IParams {
  readonly ticketId: string
  readonly selectedTurnId?: string
  readonly selectedEventId?: string
}

export function createState({ ticketId, selectedTurnId, selectedEventId }: IParams): IState {
  return {
    ticketId,
    selectedTurnId,
    selectedEventId,
    selectedServiceIds: [],
  }
}
