import { ApiBizChat3SLatencyLogItem, ApiKustoLogResponse } from '@copilot-dash/api'
import { IKustoBizChat3SLatencyLogItem } from '@copilot-dash/domain'

export function convertTo3SLatencyLog(raw: ApiKustoLogResponse): IKustoBizChat3SLatencyLogItem[] {
  return raw.logData['bizChat3SLatencyLogs (Kusto)']?.map(convertItem) ?? []
}

function convertItem(raw: ApiBizChat3SLatencyLogItem): IKustoBizChat3SLatencyLogItem {
  return {
    ...raw,
    decodedPerfTraceFile: raw.decodedPerfTraceFile,
    env_time: raw.env_time,
  }
}
