import { ColDef } from 'ag-grid-community'
import { AnyObject } from '../types/AnyObject'
import { generateAnyColumnDataType } from './generateAnyColumnDataType'
import { generateAnyColumnWidth } from './generateAnyColumnWidth'

export function generateAnyColumn(key: string, data: AnyObject[]): ColDef<AnyObject> {
  const width = generateAnyColumnWidth(key, data)
  const cellDataType = generateAnyColumnDataType(key, data)

  return {
    headerTooltip: key,
    field: key,
    cellDataType,
    tooltipField: key,
    initialWidth: width,
    editable: true,
    filter: true,
    valueGetter: (params) => {
      return params.data?.[key]?.value
    },
  }
}
