import { AgentTypesFilter, AppTypesFilter, CopilotExtensionIdsFilter, HasCopilotExtensionIdsFilter } from '../filters'
import { Control, Controller } from 'react-hook-form'

import { AgentCardFilters } from './config'
import { Body1Stronger } from '@fluentui/react-components'
import { Card } from './Card'
import { ITicketFilterFormData } from '../TicketFilterForm/TicketFilterForm.types'
import { SearchFilterFieldNames } from '@copilot-dash/domain'
import { useMemo } from 'react'
import { useStyles } from './Card.styles'
import { useTicketFilterStore } from '../../store/store'

interface IProps {
  control: Control<ITicketFilterFormData>
}

export function AgentCard({ control }: IProps) {
  const styles = useStyles()
  const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)

  const hasAgentCard = useMemo(() => {
    return filterPanelItems.some((item) => AgentCardFilters.includes(item))
  }, [filterPanelItems])

  if (!hasAgentCard) return null

  return (
    <section className={styles.section}>
      <Body1Stronger>Agent</Body1Stronger>
      <Card className={styles.card}>
        {/* Has CopilotExtensionIDs  */}
        {filterPanelItems?.includes(SearchFilterFieldNames.hasCopilotExtensionIds) && (
          <Controller
            name="hasCopilotExtensionIds"
            control={control}
            render={({ field }) => (
              <HasCopilotExtensionIdsFilter selectedValues={field.value} onChangeValue={field.onChange} />
            )}
          />
        )}
        {filterPanelItems?.includes(SearchFilterFieldNames.agentTypes) && (
          <Controller
            name="agentTypes"
            control={control}
            render={({ field }) => <AgentTypesFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}
        {filterPanelItems?.includes(SearchFilterFieldNames.appTypes) && (
          <Controller
            name="appTypes"
            control={control}
            render={({ field }) => <AppTypesFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {/* GPT Identifiers  */}
        {filterPanelItems?.includes(SearchFilterFieldNames.copilotExtensionIds) && (
          <Controller
            name="copilotExtensionIds"
            control={control}
            render={({ field }) => <CopilotExtensionIdsFilter value={field.value} onChangeValue={field.onChange} />}
          />
        )}
      </Card>
    </section>
  )
}
