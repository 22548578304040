import { Divider, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { ComponentProps } from 'react'

type Props = ComponentProps<typeof Divider> & {
  readonly space?: 0 | 1 | 2 | 4 | 6 | 8 | 10 | 12 | 16 | 20 | 24 | 32
}

export function InlineDivider(props: Props) {
  const styles = useStyles()
  const { space, vertical = false, ...rest } = props

  let spaceStyle: string | undefined
  if (space !== undefined) {
    if (vertical) {
      spaceStyle = styles[`v${space}`]
    } else {
      spaceStyle = styles[`h${space}`]
    }
  }

  return <Divider className={mergeClasses(styles.divider, spaceStyle)} vertical={vertical} {...rest} />
}

const useStyles = makeStyles({
  divider: {
    flexGrow: 0,
  },
  v0: { width: tokens.spacingVerticalNone },
  v1: { width: '1px' },
  v2: { width: tokens.spacingVerticalXXS },
  v4: { width: tokens.spacingVerticalXS },
  v6: { width: tokens.spacingVerticalSNudge },
  v8: { width: tokens.spacingVerticalS },
  v10: { width: tokens.spacingVerticalMNudge },
  v12: { width: tokens.spacingVerticalM },
  v16: { width: tokens.spacingVerticalL },
  v20: { width: tokens.spacingVerticalXL },
  v24: { width: tokens.spacingVerticalXXL },
  v32: { width: tokens.spacingVerticalXXXL },
  h0: { height: tokens.spacingHorizontalNone },
  h1: { height: '1px' },
  h2: { height: tokens.spacingHorizontalXXS },
  h4: { height: tokens.spacingHorizontalXS },
  h6: { height: tokens.spacingHorizontalSNudge },
  h8: { height: tokens.spacingHorizontalS },
  h10: { height: tokens.spacingHorizontalMNudge },
  h12: { height: tokens.spacingHorizontalM },
  h16: { height: tokens.spacingHorizontalL },
  h20: { height: tokens.spacingHorizontalXL },
  h24: { height: tokens.spacingHorizontalXXL },
  h32: { height: tokens.spacingHorizontalXXXL },
})
