import { AnyObject } from '../types/AnyObject'

const COLUMN_SIZE_MIN = 60
const COLUMN_SIZE_MAX = 500
const COLUMN_PADDING = 16

const SAMPLE_COUNT = 10
const SAMPLE_CHAR_SIZE = 8

export function generateAnyColumnWidth(key: string, objects: AnyObject[]): number {
  let maxDisplayTextLength = key.length

  const len = Math.min(SAMPLE_COUNT, objects.length)
  for (let i = 0; i < len; i++) {
    maxDisplayTextLength = Math.max(maxDisplayTextLength, objects[i]?.[key]?.valueText.length ?? 0)
  }

  const size = 2 * COLUMN_PADDING + maxDisplayTextLength * SAMPLE_CHAR_SIZE
  return Math.max(COLUMN_SIZE_MIN, Math.min(COLUMN_SIZE_MAX, size))
}
