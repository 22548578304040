import { INewTicketData } from '@copilot-dash/domain'
import { memo } from 'react'
import { groupTicketsByState } from './utils'
import { CollapsibleTable } from './CollapsibleTable'
import { Column } from '../../../../../../../components/Layout'

interface IGroupedTicketsByStateProps {
  readonly tickets: INewTicketData[]
  readonly hasMore?: boolean
  readonly loadMore?: (offset: number) => void
  readonly rowHoverStickySlot?: (rowData: INewTicketData) => React.ReactNode
}

const stateOrder = ['Team Assigned', 'Root Caused', 'Closed', 'Untriaged']

export const GroupedTicketsByState = memo(function GroupedTicketsByState(props: IGroupedTicketsByStateProps) {
  const { tickets } = props

  const nestArray = Object.entries(groupTicketsByState(tickets)).sort((a, b) => {
    return stateOrder.indexOf(a[0]) - stateOrder.indexOf(b[0])
  })

  return (
    <Column style={{ width: '100%' }}>
      {nestArray.map(([state, tickets], index) => (
        <CollapsibleTable
          key={state}
          tickets={tickets}
          title={state}
          defaultExpanded={index === 0}
          hasMore={props.hasMore}
          loadMore={props.loadMore}
          rowHoverStickySlot={props.rowHoverStickySlot}
        />
      ))}
    </Column>
  )
})
