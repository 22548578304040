import { SearchFilterFieldNames } from '@copilot-dash/domain'

export const AgentCardFilters: SearchFilterFieldNames[] = [
  SearchFilterFieldNames.agentTypes,
  SearchFilterFieldNames.appTypes,
  SearchFilterFieldNames.hasCopilotExtensionIds,
  SearchFilterFieldNames.copilotExtensionIds,
]

export const DateFilterCardFilters: SearchFilterFieldNames[] = [SearchFilterFieldNames.dateRange]

export const FeedbackSourceCardFilters: SearchFilterFieldNames[] = [
  SearchFilterFieldNames.client,
  SearchFilterFieldNames.channel,
  SearchFilterFieldNames.customerTypes,
  SearchFilterFieldNames.ring,
]

export const FeedbackDetailCardFilters: SearchFilterFieldNames[] = [
  SearchFilterFieldNames.thumbs,
  SearchFilterFieldNames.hasVerbatim,
  SearchFilterFieldNames.hasUserConsent,
]

export const PromptDetailsCardFilters: SearchFilterFieldNames[] = [
  SearchFilterFieldNames.promptLanguages,
  SearchFilterFieldNames.groundedPrompts,
  SearchFilterFieldNames.invocationType,
]

export const QueryProcessingCardFilters: SearchFilterFieldNames[] = [
  SearchFilterFieldNames.triggeredSkill,
  SearchFilterFieldNames.hitAvalon,
  SearchFilterFieldNames.optionsSets,
  SearchFilterFieldNames.sliceIds,
]

export const FeedbackHandlingCardFilters: SearchFilterFieldNames[] = [
  SearchFilterFieldNames.priority,
  SearchFilterFieldNames.dSATStatus,
  SearchFilterFieldNames.customTags,
  SearchFilterFieldNames.dSATAssignedTo,
]

export const CustomFilterCardFilters: SearchFilterFieldNames[] = [
  SearchFilterFieldNames.isTopi18N,
  SearchFilterFieldNames.isSTCAChina,
  SearchFilterFieldNames.hasErrorMessages,
  SearchFilterFieldNames.hasGPTExtension,
  SearchFilterFieldNames.hasMessageExtension,
  SearchFilterFieldNames.hasConnector,
  SearchFilterFieldNames.isGCIntent,
  SearchFilterFieldNames.hasConnectorResult,
  SearchFilterFieldNames.semanticSearchType,
  SearchFilterFieldNames.experienceType,
  SearchFilterFieldNames.errorCode,
  SearchFilterFieldNames.copilotExtensionIds,
]
