const VERTICAL_GAP = 10
const HORIZONTAL_OFFSET = 5
const MAX_TOP_LIMIT = 100

export function setFloatingElemPositionForLinkEditor(
  targetRect: DOMRect | null,
  floatingElem: HTMLElement,
  anchorElem: HTMLElement,
  verticalGap: number = VERTICAL_GAP,
  horizontalOffset: number = HORIZONTAL_OFFSET,
  maxTopLimit: number = MAX_TOP_LIMIT,
): void {
  const scrollerElem = anchorElem.parentElement

  if (!targetRect || !scrollerElem) {
    hideFloatingElement(floatingElem)
    return
  }

  const floatingElemRect = floatingElem.getBoundingClientRect()
  const anchorElementRect = anchorElem.getBoundingClientRect()
  const editorScrollerRect = scrollerElem.getBoundingClientRect()

  let top = targetRect.top - verticalGap
  let left = targetRect.left - horizontalOffset

  if (top < editorScrollerRect.top) {
    top += floatingElemRect.height + targetRect.height + verticalGap * 2
  }

  if (left + floatingElemRect.width > editorScrollerRect.right) {
    left = editorScrollerRect.right - floatingElemRect.width - horizontalOffset
  }

  top = Math.min(top - anchorElementRect.top, maxTopLimit)
  left -= anchorElementRect.left

  showFloatingElement(floatingElem, left, top)
}

function hideFloatingElement(floatingElem: HTMLElement) {
  floatingElem.style.visibility = 'hidden'
  floatingElem.style.opacity = '0'
  floatingElem.style.transform = 'translate(-10000px, -10000px)'
}

function showFloatingElement(floatingElem: HTMLElement, left: number, top: number) {
  requestAnimationFrame(() => {
    floatingElem.style.visibility = 'visible'
    floatingElem.style.opacity = '1'
    floatingElem.style.transform = `translate(${left}px, ${top}px)`
  })
}
