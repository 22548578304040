import { INewTicketData, SearchTextPrefixType } from '@copilot-dash/domain'
import { Skeleton, SkeletonItem, Text } from '@fluentui/react-components'
import { useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { ErrorView } from '../Error'
import { usePrivacyModeClass } from '../Privacy/usePrivacyModeClass'
import { ToolTipText } from '../Text/ToolTipText'
import { useStyles } from './ColumnComponent.styles'

export function ResponsiveUtteranceColumnComponent({
  item,
  searchTextPrefix,
  keyWords,
}: {
  item: INewTicketData
  searchTextPrefix: SearchTextPrefixType
  keyWords?: string
}) {
  const privacyModeClass = usePrivacyModeClass()
  const { ref, inView } = useInView({
    triggerOnce: true,
  })
  const styles = useStyles()

  const plainTextKeyWords = useMemo(() => {
    if (!keyWords) return keyWords
    if (searchTextPrefix === SearchTextPrefixType.All || searchTextPrefix === SearchTextPrefixType.Utterance)
      return keyWords.trim()
    return undefined
  }, [keyWords, searchTextPrefix])

  const tableCellContent = useMemo(() => {
    switch (inView) {
      case true:
        if (item.ticketId) return <UtteranceColumnComponent ticketId={item.ticketId} keyWords={plainTextKeyWords} />
        else return <Text className={styles.grayColor}>N/A</Text>
      default:
        return null
    }
  }, [inView, item, plainTextKeyWords, styles.grayColor])

  return (
    <div ref={ref} className={privacyModeClass}>
      {tableCellContent}
    </div>
  )
}

function UtteranceColumnComponent({ ticketId, keyWords }: { ticketId: string; keyWords?: string }) {
  const styles = useStyles()

  const utterance = application.store.use.getTicketUtterance(ticketId)

  const renderContent = () => {
    switch (utterance?.status) {
      case 'waiting':
        return (
          <Skeleton appearance="translucent" className={styles.skeleton}>
            <SkeletonItem className={styles.skeletonItem1} />
            <SkeletonItem className={styles.skeletonItem2} />
          </Skeleton>
        )
      case 'done': {
        return <ToolTipText text={utterance.data} keywords={keyWords} isTwoLinesTruncate={true} />
      }
      case 'error':
        return <ErrorView type="inline" error={utterance.error} />
    }
  }

  return <div>{renderContent()}</div>
}
