import { Button, Tooltip } from '@fluentui/react-components'
import { DismissRegular } from '@fluentui/react-icons'
import { useDashDialog } from '../../../../components/Dialog'

export function TicketScreenCloseButton() {
  const dialog = useDashDialog()
  if (!dialog) {
    return
  }

  const handleClick = () => {
    dialog.close()
  }

  return (
    <Tooltip content="Close" relationship="label">
      <Button appearance="subtle" onClick={handleClick} icon={<DismissRegular fontSize={24} />} />
    </Tooltip>
  )
}
