import { mergeClasses } from '@fluentui/react-components'
import { CSSProperties } from 'react'

interface IProps {
  readonly flex?: CSSProperties['flexGrow']
  readonly size?: CSSProperties['height']
  readonly height?: CSSProperties['height']
  readonly width?: CSSProperties['width']
  readonly className?: string
  readonly style?: CSSProperties
}

export function Spacer({ flex, size, height, width, className, style: outerStyle }: IProps) {
  let style: CSSProperties
  if (height !== undefined || width !== undefined || size !== undefined) {
    style = {
      height: size ?? height,
      width: size ?? width,
      flex: '0 0 auto',
    }
  } else {
    style = {
      flexGrow: flex ?? 1,
      flexShrink: 1,
      flexBasis: 'auto',
    }
  }

  return <div className={mergeClasses('Spacer', className)} style={Object.assign(style, outerStyle)} />
}
