import { FC, memo, useState, useEffect, useCallback, useLayoutEffect, useRef } from 'react'
import { useStyles } from './CustomTags.styles'
import { Dismiss16Filled, AddRegular } from '@fluentui/react-icons'
import { Row, Spacer, Column } from '../../Layout'
import {
  ComboboxProps,
  Option,
  Tag,
  Text,
  Combobox,
  Field,
  Skeleton,
  SkeletonItem,
  Button,
} from '@fluentui/react-components'
import { useAllCustomTags } from '@copilot-dash/store'

interface ICustomTagProps {
  value: string[]
  onChange: (value: string[]) => void
  className?: string
  style?: React.CSSProperties
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
  defaultValueText?: string
}
export const CustomTags: FC<ICustomTagProps> = memo(
  ({ value, className, style, fieldProps, onChange, defaultValueText }) => {
    const filterOptionsSnapshot = useAllCustomTags()
    const styles = useStyles()
    const [visibleTags, setVisibleTags] = useState(value && value.length > 0 ? value : [])
    const [showAddBtn, setShowAddBtn] = useState(true)
    const [focusDisplayValue, setFocusDisplayValue] = useState('')
    const [openSelect, setOpenSelect] = useState(false)
    const [isDuplicate, setIsDuplicate] = useState(false)
    const comboboxRef = useRef<HTMLInputElement>(null)

    const onRemoveTag = (e: React.MouseEvent, value: string) => {
      e.stopPropagation()
      const newValues = [...visibleTags].filter((tag) => tag !== value)
      onChange(newValues)
      Logger.telemetry.trackEvent('CustomTag/DeleteTag', { tags: newValues, deletedTag: value })
    }

    const handleAddTag = () => {
      setShowAddBtn(false)
      setOpenSelect(true)
      setFocusDisplayValue('')
      setTimeout(() => {
        comboboxRef.current?.focus()
      }, 0)
    }
    useLayoutEffect(() => {
      setVisibleTags(value && value.length > 0 ? value : [])
    }, [value])

    const onTagsChange = useCallback(
      (tag: string) => {
        const newValues = [...visibleTags]
        newValues.push(tag)
        onChange(newValues)
        setFocusDisplayValue('')
        setShowAddBtn(true)
        Logger.telemetry.trackEvent('CustomTag/AddTag', { tags: newValues, addedTag: tag })
      },
      [onChange, visibleTags, setFocusDisplayValue],
    )

    const onInputChange: ComboboxProps['onChange'] = (event) => {
      const value = event.target.value
      setFocusDisplayValue(value)
      setIsDuplicate(false)
    }

    const handleKeyDown = (event: { key: string }) => {
      if (event.key === 'Enter') {
        if (!visibleTags.includes(focusDisplayValue)) {
          onTagsChange(focusDisplayValue)
        } else {
          setIsDuplicate(true)
        }
      }
    }
    const onBlur = () => {
      if (focusDisplayValue) {
        if (!visibleTags.includes(focusDisplayValue)) {
          onTagsChange(focusDisplayValue)
          setFocusDisplayValue('')
        }
      } else {
        setShowAddBtn(true)
        setOpenSelect(false)
      }
      setIsDuplicate(false)
    }

    //filter the options based on the input value
    function filterOptions(optionsList: string[]) {
      const filteredOptions = optionsList
        .filter((item) => {
          return (
            item?.toLocaleLowerCase().includes(focusDisplayValue.toLocaleLowerCase().trim()) &&
            !visibleTags.includes(item)
          )
        })
        .sort((a, b) => {
          const x1 = a!.toLocaleUpperCase().trim()
          const x2 = b!.toLocaleUpperCase().trim()
          if (x1 < x2) {
            return -1
          } else if (x1 > x2) {
            return 1
          } else {
            return 0
          }
        })

      return filteredOptions.length > 0
        ? filteredOptions.map((item) => {
            if (!item) {
              return
            }
            return (
              <Option
                value={item}
                key={item}
                text={item}
                checkIcon={{
                  as: 'span',
                  children: null,
                  className: styles.optionCheckIcon,
                }}
                className={styles.optionContainer}
              >
                <Text className={styles.optionText} title={item}>
                  {' '}
                  {item}
                </Text>
              </Option>
            )
          })
        : null
    }
    const targetFinalOptions = filterOptions(filterOptionsSnapshot.data ?? [])

    //close the dropdown when the targetFinalOptions is null
    useEffect(() => {
      if (targetFinalOptions !== null) {
        setOpenSelect(true)
      } else {
        setOpenSelect(false)
      }
    }, [openSelect, targetFinalOptions])

    return (
      <Field label="Tags:" className={className} style={style} {...(fieldProps ?? {})}>
        {filterOptionsSnapshot.status === 'waiting' ? (
          <Skeleton>
            <Column>
              <SkeletonItem size={16} />
              <Spacer height={4} />
              <SkeletonItem size={16} />
            </Column>
          </Skeleton>
        ) : (
          <div style={{ width: '100%' }}>
            {defaultValueText === 'Mixed' && (
              <Tag
                dismissible
                value={'Mixed'}
                key={'Mixed'}
                tabIndex={-1}
                className={styles.tagStyle}
                dismissIcon={null}
              >
                <Text className={styles.textStyle}>{'Mixed tags'}</Text>
              </Tag>
            )}
            {visibleTags.map((tag, index) => (
              <Tag
                dismissible
                value={tag}
                key={tag}
                tabIndex={index}
                className={styles.tagStyle}
                dismissIcon={{
                  as: 'span',
                  children: <Dismiss16Filled className={styles.dismissButton} onClick={(e) => onRemoveTag(e, tag)} />,
                }}
              >
                <Text className={styles.textStyle} title={tag}>
                  {tag}
                </Text>
              </Tag>
            ))}
            {showAddBtn && (
              <Button
                onClick={handleAddTag}
                className={styles.addButton}
                appearance="transparent"
                icon={<AddRegular fontSize={18} />}
              >
                <Text> Add Tag</Text>
              </Button>
            )}
            {!showAddBtn && (
              <Combobox
                input={{ className: styles.dropdownContainer, ref: comboboxRef }}
                autoComplete="none"
                aria-autocomplete="none"
                appearance="underline"
                className={styles.dropdown}
                expandIcon={{
                  as: 'span',
                  children: null,
                }}
                positioning={{ align: 'end', pinned: true, position: 'below' }}
                listbox={{ className: styles.listbox }}
                onChange={onInputChange}
                onKeyDownCapture={handleKeyDown}
                open={openSelect}
                onBlur={onBlur}
                onOptionSelect={(_, data) => {
                  if (data.selectedOptions[0]) {
                    onTagsChange(data.selectedOptions[0])
                    if (data.optionText) {
                      setFocusDisplayValue(data.optionText)
                    }
                    setShowAddBtn(true)
                  }
                }}
              >
                {' '}
                {targetFinalOptions}
              </Combobox>
            )}
            {isDuplicate && (
              <Row>
                <Text className={styles.duplicateTag}>Tag already exists</Text>
              </Row>
            )}
          </div>
        )}
      </Field>
    )
  },
)

CustomTags.displayName = 'CustomTags'
