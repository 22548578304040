export interface IState {
  readonly data: unknown
  readonly tabs: AnyDataTab[]
  readonly selectedTab: AnyDataTab
  readonly table: ITableConfig
  readonly json: IJsonConfig
}

interface ITableConfig {
  readonly keyword?: string
  readonly searchResultCount?: number
}

interface IJsonConfig {
  readonly wrap: boolean
}

export enum AnyDataTab {
  Table = 'Table',
  Json = 'JSON',
}
