import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { Dispatch } from 'react'
import * as React from 'react'
import { FloatingLinkEditorToolbar } from './FloatingLinkEditorToolbar'

interface FloatingLinkEditorPluginProps {
  anchorElem?: HTMLElement
  isLinkEditMode: boolean
  setIsLinkEditMode: Dispatch<boolean>
}

export const FloatingLinkEditorPlugin: React.FC<FloatingLinkEditorPluginProps> = ({
  anchorElem = document.body,
  isLinkEditMode,
  setIsLinkEditMode,
}) => {
  const [editor] = useLexicalComposerContext()
  return (
    <FloatingLinkEditorToolbar
      editor={editor}
      anchorElem={anchorElem}
      isLinkEditMode={isLinkEditMode}
      setIsLinkEditMode={setIsLinkEditMode}
    />
  )
}
