import { Text, mergeClasses } from '@fluentui/react-components'
import { FC, useMemo, useCallback } from 'react'
import { Row, Column, Spacer } from '../../../Layout'
import { useStyles } from './StatusField.styles'
import { TicketIssueData, ActivityHistoryRootCauseContext } from '@copilot-dash/domain'
import { isStringArray } from '../config'
import { useActivityHistoryPanelStore } from '../../store/store'

export const ActivityUpdateCustomTags: FC = () => {
  const styles = useStyles()
  const selectedActivity = useActivityHistoryPanelStore((state) => state.selectedActivity)

  const isChange = useMemo(() => {
    return !!(
      selectedActivity?.fieldDiffs &&
      selectedActivity?.fieldDiffs['customTags'] &&
      (selectedActivity?.fieldDiffs['customTags'].newValue || selectedActivity?.fieldDiffs['customTags'].oldValue)
    )
  }, [selectedActivity])

  const renderValue = useCallback(
    (value: string | number | TicketIssueData[] | ActivityHistoryRootCauseContext[] | string[] | undefined) => {
      if (isStringArray(value)) {
        return value.map((tag, idx) => (
          <Text key={idx} className={mergeClasses(styles.tag, styles.customTag)} title={tag}>
            {tag}{' '}
          </Text>
        ))
      }
      return null
    },
    [styles.tag, styles.customTag],
  )

  const newValue = useMemo(
    () => renderValue(selectedActivity?.fieldDiffs?.['customTags']?.newValue),
    [selectedActivity, renderValue],
  )
  const oldValue = useMemo(
    () => renderValue(selectedActivity?.fieldDiffs?.['customTags']?.oldValue),
    [selectedActivity, renderValue],
  )

  if (!isChange) return null

  return (
    <Row vAlign="center">
      <Column className={styles.columnContainer}>
        <Text className={styles.statueKey}>Tags:</Text>
      </Column>
      {newValue && <Text style={{ width: 'fit-content' }}>{newValue}</Text>}
      <Spacer width="4px" />
      {oldValue && <Text className={styles.deleted}>{oldValue}</Text>}
    </Row>
  )
}
