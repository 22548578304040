import { ThemeMode } from '@copilot-dash/domain'
import { useCallback, useSyncExternalStore } from 'react'

const MEDIA_QUERY_DARK = '(prefers-color-scheme: dark)'

export function useThemeMode(mode?: ThemeMode): 'light' | 'dark' {
  const themeModeFromSetting = app.settings.themeMode.use()
  mode ??= themeModeFromSetting

  const subscribe = useCallback(
    (onStoreChange: () => void) => {
      if (mode !== 'system') {
        return () => {}
      }

      const mediaQuery = window.matchMedia(MEDIA_QUERY_DARK)
      mediaQuery.addEventListener('change', onStoreChange)
      return () => {
        mediaQuery.removeEventListener('change', onStoreChange)
      }
    },
    [mode],
  )

  const getSnapshot = useCallback(() => {
    switch (mode) {
      case 'light':
        return 'light'
      case 'dark':
        return 'dark'
      case 'system':
        return window.matchMedia(MEDIA_QUERY_DARK).matches ? 'dark' : 'light'
    }
  }, [mode])

  return useSyncExternalStore(subscribe, getSnapshot)
}
