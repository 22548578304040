import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  grayColor: {
    color: tokens.colorScrollbarOverlay,
  },
  card: {},
  body: {
    flex: '1 1 0',
    ...shorthands.padding('5px', '25px', '5px', '10px'),
    overflowX: 'hidden',
  },
  footer: {
    flex: '0 0 auto',
    minHeight: '242px',
    ...shorthands.padding(0, 0, '20px', '5px'),
  },
  waiting: {
    minHeight: '521px',
  },
})
