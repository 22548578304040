import { INewTicketData, TicketEmotionType, ColumnKey, ColumnIdNaColumnIdNameMapping } from '@copilot-dash/domain'
import { createExtendedTableColumn, ExtendedTableColumnDefinition } from '../Table/ExtendedTableColumn'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { Row, Column } from '../Layout'
import { CopilotDashTooltip } from '../CopilotDashTooltip/CopilotDashTooltip'
import { memo } from 'react'
import { useStyles } from './ColumnComponent.styles'
import { Text } from '@fluentui/react-components'

export const ColumnConfigRootCause: ExtendedTableColumnDefinition<INewTicketData> =
  createExtendedTableColumn<INewTicketData>({
    columnId: ColumnKey.RootCause,
    columnName: ColumnIdNaColumnIdNameMapping[ColumnKey.RootCause],
    isResizable: true,
    columnSize: {
      minWidth: 100,
      idealWidth: 250,
      padding: 0,
    },

    renderHeaderCell: () => <TableCellLayoutWithStyles>Root Cause</TableCellLayoutWithStyles>,
    renderCell: (item: INewTicketData) => (
      <TableCellLayoutWithStyles truncate>
        <RenderRootCauseList item={item} />
      </TableCellLayoutWithStyles>
    ),
  })

const RenderRootCauseList = memo(({ item }: { item: INewTicketData }) => {
  const styles = useStyles()
  if (item.thumbFeedback === TicketEmotionType.Positive) {
    return <Text className={styles.grayColor}>N/A</Text>
  } else if (!item.rootCauseList || item.rootCauseList.length === 0) {
    return <Text className={styles.grayColor}>Need to add</Text>
  }
  return (
    <CopilotDashTooltip
      relationShip="label"
      toolTipContent={
        <Column className={styles.tooltipContent}>
          {item.rootCauseList.map((item) => (
            <Row key={item.issueId}>{item.rootCauseTitle}</Row>
          ))}
        </Column>
      }
      className={styles.tooltip}
      toolTipParentContent={
        <Text className={styles.text}>{item.rootCauseList.map((item) => item.rootCauseTitle).join(', ')}</Text>
      }
    />
  )
})

RenderRootCauseList.displayName = 'RenderRootCauseList'
