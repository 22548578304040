import { IStore } from '@copilot-dash/core'
import { IDashDialogActions } from './DashDialogActions'
import { IDashDialogController } from './DashDialogController'
import { IDashDialogState } from './DashDialogState'

export function createDashDialogController(): IDashDialogController {
  let registeredStore: IStore<IDashDialogState, IDashDialogActions> | undefined = undefined

  return {
    open: (instance) => {
      if (!registeredStore) {
        throw new Error('No DialogStore registered')
      }

      return registeredStore.actions.open(instance)
    },

    close: (id) => {
      return registeredStore?.actions.close(id) ?? false
    },

    register: (store) => {
      if (registeredStore) {
        throw new Error('DialogStore already registered')
      }

      registeredStore = store
      return () => {
        registeredStore = undefined
      }
    },
  }
}
