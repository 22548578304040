import { JSONTree } from 'react-json-tree'
import jsonTreeConfig from '../../../Layout/json-tree-config'
import { useStyles } from './ObjectPropertyComponent.styles'
import { Column } from '../../../Layout'

export const ObjectPropertyComponent: React.FC<{ value: object }> = ({ value }) => {
  const styles = useStyles()
  return (
    <Column className={styles.container}>
      <JSONTree data={value} {...jsonTreeConfig} />
    </Column>
  )
}
