import { z } from 'zod'

export interface ApiRootCauseContext {
  readonly adoIssueId: string
  readonly title: string
  readonly userAction: string
  readonly project: string
  readonly areapath: string
  readonly adoLink: string
  readonly teamName?: string
}

export const apiRootCauseContextSchema = z.object({
  adoIssueId: z.string(),
  title: z.string(),
  userAction: z.string(),
  project: z.string(),
  areapath: z.string(),
  adoLink: z.string(),
  teamName: z.string().optional(),
})
