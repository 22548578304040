import { AgnosticRouteMatch } from '@remix-run/router'
import { IRoutePathParams } from '../../../types/IRouteParams'

export function generateParamsPathByMatches(matches: { params: AgnosticRouteMatch['params'] }[]): IRoutePathParams {
  const initial: Record<string, string | undefined> = {}

  return matches.reduce(
    (acc, match) => ({
      ...acc,
      ...match.params,
    }),
    initial,
  )
}
