import { getErrorConfigs } from './getErrorConfigs'

export function getErrorMessageDetails(error: unknown): string | undefined {
  const builders = getErrorConfigs(error)

  for (const builder of builders) {
    const message = builder.buildMessageDetails?.()
    if (message) {
      return message
    }
  }

  return undefined
}
