import { makeStyles, Tag, tokens } from '@fluentui/react-components'

interface IProps {
  readonly tag: string
}

export function TicketScreenTag({ tag }: IProps) {
  const styles = useStyles()

  return (
    <Tag className={styles.tag} size="small">
      {tag}
    </Tag>
  )
}

const useStyles = makeStyles({
  tag: {
    backgroundColor: tokens.colorNeutralBackground1Selected,
    borderRadius: '2px',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
})
