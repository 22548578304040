import { IStoreGetter, IStoreSetter } from '@copilot-dash/core'
import { IProductData } from '@copilot-dash/domain'
import { last } from 'lodash'
import { IGlobalProductSelectorData, IGlobalProductState } from './createGlobalProductState'

export type ITicketProductStoreActions = ReturnType<typeof createGlobalProductActions>

export function createGlobalProductActions(
  set: IStoreSetter<IGlobalProductState>,
  get: IStoreGetter<IGlobalProductState>,
) {
  return {
    register(data: IGlobalProductSelectorData): () => void {
      const item = get().selectors.find((item) => item.id === data.id)
      if (item) {
        return () => {}
      }

      set((state) => {
        state.selectors.push(data)
      })

      return () => {
        set((state) => {
          state.selectors = state.selectors.filter((item) => item.id !== data.id)
        })
      }
    },

    update(data: IGlobalProductSelectorData) {
      set((state) => {
        const index = state.selectors.findIndex((item) => item.id === data.id)

        if (index !== -1) {
          state.selectors[index] = data
        }
      })
    },

    selectProduct(value: IProductData) {
      last(get().selectors)?.onChange?.(value)
    },
  }
}
