import { IProductData } from '@copilot-dash/domain'
import { Option } from '@fluentui/react-components'

interface IProps {
  readonly data: IProductData
  readonly disabled: boolean
}

export function TicketProductDropdownOption({ data, disabled }: IProps) {
  return (
    <Option value={data.name} disabled={disabled}>
      {data.title}
    </Option>
  )
}
