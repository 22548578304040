import { makeStyles } from '@fluentui/react-components'
import { DashDialogProvider } from '../DashDialogProvider'
import { DashDialogItem } from '../children/DashDialogItem'

export function DashDialogProviderLayout() {
  const styles = useStyles()
  const items = DashDialogProvider.use((state) => state.items)

  return (
    <div className={styles.dialog}>
      {items.map((item) => {
        return <DashDialogItem key={item.id} id={item.id} instance={item.instance} />
      })}
    </div>
  )
}

const useStyles = makeStyles({
  dialog: {
    width: 0,
    maxWidth: 0,
    height: 0,
    maxHeight: 0,
    overflow: 'hidden',
  },
})
