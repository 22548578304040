import { AsyncSnapshots } from '@copilot-dash/core'
import { Button, makeStyles, Text, tokens } from '@fluentui/react-components'
import { isPlainObject } from 'lodash'
import * as React from 'react'
import { Row } from '../../../../../../../components/Layout'
import { PropertyTable } from '../../../../../../../components/Table/PropertyTable/PropertyTable'
import { IPropertyItemValue } from '../../../../../../../components/Table/PropertyTable/PropertyTable.interface'
import { useDateDisplayTextParser } from '../../../../../../../hooks/useDateDisplayTextParser'
import { TicketScreenStore } from '../../../../../TicketScreenStore'
import { LoadingStatusComponent } from '../../../../common/LoadingStatusComponent'

export function BizChat3SLatencyView() {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const selectedTurnId = TicketScreenStore.use((state) => state.selectedTurnId)

  if (!selectedTurnId) {
    const snapshot = AsyncSnapshots.error(new Error('No conversation selected'))
    return <LoadingStatusComponent promiseSnapshot={snapshot} content={() => null} />
  }

  return <Content ticketId={ticketId} selectedTurnId={selectedTurnId} />
}

function Content(props: { readonly ticketId: string; readonly selectedTurnId: string }) {
  const styles = useStyles()
  let timer: NodeJS.Timeout
  const ORIGIN = 'https://ui.perfetto.dev'
  const timeParser = useDateDisplayTextParser()

  const snapshot = app.store.use.get3SLatencyLogs(props.ticketId, props.selectedTurnId)

  const getValidValue = React.useCallback((value: unknown): IPropertyItemValue => {
    if (isPlainObject(value) || typeof value === 'string' || typeof value === 'number') {
      return value || undefined
    }
    return undefined
  }, [])

  const fetchAndOpen = (decodedPerfTraceFile: string | undefined) => {
    if (!decodedPerfTraceFile) {
      alert('Empty perf trace file!')
      return
    }
    const blob = new Blob([decodedPerfTraceFile], { type: 'plain/text' })
    blob.arrayBuffer().then((arrayBuffer) => {
      openTrace(arrayBuffer)
    })
  }

  function openTrace(arrayBuffer: ArrayBuffer) {
    const win = window.open(ORIGIN)
    window.addEventListener('message', (evt) => {
      if (evt.data !== 'PONG') return
      window.clearInterval(timer)
      win?.postMessage(arrayBuffer, ORIGIN)
    })
    timer = setInterval(() => win?.postMessage('PING', ORIGIN), 50)
  }

  return (
    <LoadingStatusComponent
      promiseSnapshot={snapshot}
      content={(biz3SLog) => {
        return biz3SLog.length > 0 ? (
          <Row fill>
            <PropertyTable
              blocks={
                biz3SLog.map((item) => {
                  return {
                    renderHeader: () => (
                      <Row gap="gap.small" vAlign="center">
                        <Text className={styles.titleText}>bizChat3SLatencyLogs (Kusto)</Text>
                        <Button appearance="primary" onClick={() => fetchAndOpen(item.decodedPerfTraceFile)}>
                          Open flame graph
                        </Button>
                      </Row>
                    ),
                    items: Object.keys(item).map((key) => {
                      return {
                        name: key,
                        value: key === 'env_time' ? timeParser(item.decodedPerfTraceFile) : getValidValue(item[key]),
                      }
                    }),
                    collapsible: true,
                    truncate: true,
                  }
                }) || []
              }
            />
          </Row>
        ) : (
          <Text>No Data</Text>
        )
      }}
    />
  )
}

const useStyles = makeStyles({
  titleText: {
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorNeutralForeground1,
    whiteSpace: 'nowrap',
  },
})
