export class PerformanceUtils {
  private static initializationStartTime: number = 0
  private static initializationEndTime: number = 0

  static onStartInitialization() {
    this.initializationStartTime = performance.now()
  }

  static onEndInitialization() {
    this.initializationEndTime = performance.now()

    const initBundlesDuration = Math.round(this.initializationStartTime)
    const initAppDuration = Math.round(this.initializationEndTime - this.initializationStartTime)

    Logger.telemetry.trackMetric('LoadJavascriptFilesDuration', { duration: initBundlesDuration })
    Logger.telemetry.trackMetric('InitAppDuration', { duration: initAppDuration })
  }
}
