import { IErrorComponents } from '../interfaces/IErrorComponents'
import { IErrorConfig } from '../interfaces/IErrorConfig'
import { getErrorConfigs } from './getErrorConfigs'

export function getErrorComponents(error: unknown): IErrorComponents {
  const builders = getErrorConfigs(error)

  function pick<T>(callback: (e: IErrorConfig) => T | null | undefined): T | null {
    for (const builder of builders) {
      const node = callback(builder)
      if (node) {
        return node
      }
    }

    return null
  }

  return {
    level: pick((e) => e.buildLevel?.()) ?? 'ERROR',
    name: pick((e) => e.buildName?.()),
    icon: pick((e) => e.buildIcon?.()),
    message: pick((e) => e.buildMessage?.()),
    messageDetails: pick((e) => e.buildMessageDetails?.()),
    context: pick((e) => e.buildContext?.()),
  }
}
