import { makeStyles, Text } from '@fluentui/react-components'
import { Row } from '../../../../components/Layout'

interface IProps {
  readonly title: string
}

export function SettingsScreenSectionTitle({ title }: IProps) {
  const styles = useStyles()

  return (
    <Row className={styles.root} vAlign="center">
      <Text size={400} weight="semibold">
        {title}
      </Text>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    paddingTop: '4px',
    paddingBottom: '4px',
    marginTop: '16px',
    marginBottom: '16px',
  },
})
