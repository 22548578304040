import { Table, TableBody, TableCell, TableRow, mergeClasses } from '@fluentui/react-components'
import { Fragment } from 'react/jsx-runtime'
import { useStyles } from './PropertyTable.styles'
import { IPropertyItemValue } from './PropertyTable.interface'
import { renderPropertyItemValue } from './column/PropertyItemValueRenderer'
import { PropertyNameComponent } from './column/PropertyNameComponent'
import { useCallback, useEffect, useState } from 'react'
import { Row } from '../../Layout'
import { ChevronDownFilled, ChevronRightFilled } from '@fluentui/react-icons'
import { TableCellLayoutWithStyles } from '../../../screens/table/TableCellLayoutWithStyles'

export interface IPropertyItem {
  name: string
  subName?: string
  value?: IPropertyItemValue | null
  renderValue?: (item: IPropertyItemValue) => JSX.Element
}
export interface IPropertyBlock {
  renderHeader?: () => React.ReactNode
  items: IPropertyItem[] | null
  collapsible?: boolean
  defaultCollapsed?: boolean
  truncate?: boolean
  renderError?: () => React.ReactNode
}

interface IPropertyTableProps {
  blocks: IPropertyBlock[]
}

export const PropertyTable: React.FC<IPropertyTableProps> = (props) => {
  const styles = useStyles()

  const [expanded, setExpanded] = useState<Set<number>>(new Set())

  const [initialed, setInitialed] = useState(false)

  useEffect(() => {
    const expandedSet = new Set<number>()
    props.blocks.forEach((block, index) => {
      if (!block.defaultCollapsed) {
        expandedSet.add(index)
      }
    })
    setExpanded(expandedSet)
    setInitialed(true)
  }, [props.blocks])

  const handleToggle = useCallback(
    (index: number) => () => {
      const newExpanded = new Set(expanded)
      if (newExpanded.has(index)) {
        newExpanded.delete(index)
      } else {
        newExpanded.add(index)
      }
      setExpanded(newExpanded)
    },
    [expanded],
  )

  const renderBlockHeader = useCallback(
    (block: IPropertyBlock, index: number) => {
      return (
        block.renderHeader && (
          <Row vAlign="center" gap="gap.small" className={styles.blockHeader}>
            {block.collapsible &&
              (expanded.has(index) ? (
                <ChevronDownFilled onClick={handleToggle(index)} />
              ) : (
                <ChevronRightFilled onClick={handleToggle(index)} />
              ))}
            {block.renderHeader()}
          </Row>
        )
      )
    },
    [expanded, handleToggle, styles.blockHeader],
  )
  const renderBlockError = useCallback(
    (block: IPropertyBlock, index: number) => {
      if (block.collapsible && expanded.has(index) && block.renderError) {
        return (
          <Row vAlign="center" gap="gap.small" hAlign="center">
            {block.renderError()}
          </Row>
        )
      }
      return null
    },
    [expanded],
  )

  return (
    initialed && (
      <Table className={styles.container} noNativeElements>
        <TableBody>
          {props.blocks.map((block, index) => (
            <Fragment key={index}>
              {renderBlockHeader(block, index)}
              {renderBlockError(block, index)}
              {(expanded.has(index) || !block.collapsible) &&
                block.items?.map((item, index) => {
                  const renderValue = item.renderValue || renderPropertyItemValue
                  return (
                    <TableRow key={index}>
                      <TableCell className={mergeClasses(styles.cell, styles.nameCell)}>
                        <TableCellLayoutWithStyles truncate={block.truncate}>
                          <PropertyNameComponent name={item.name} subName={item.subName} />
                        </TableCellLayoutWithStyles>
                      </TableCell>
                      <TableCell className={styles.cell}>
                        <TableCellLayoutWithStyles>{item.value && renderValue(item.value)}</TableCellLayoutWithStyles>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    )
  )
}
