import { ApiOds3sOfflineDataGroup4 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getOdsBlobMetadata } from '../actions-blob/getOdsBlobMetadata'
import { getTicketSession } from '../actions-ticket/getTicketSession'
import { validateTicket3sOfflineDataTimeliness } from '../validators/validateTicket3sOfflineData'
import { getRaw3sOfflineIndex } from './getRaw3sOfflineIndex'

export function getRaw3sOfflineDataGroup4(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
): PromiseSnapshot<ApiOds3sOfflineDataGroup4> {
  return context.getOrFetch<ApiOds3sOfflineDataGroup4>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.raw3sOfflineDataGroup4
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.raw3sOfflineDataGroup4 = snapshot
    },
    fetch: async () => {
      return await fetchFromV1()
    },
  })

  async function fetchFromV1(): Promise<ApiOds3sOfflineDataGroup4> {
    // Get turn index
    const index = await getRaw3sOfflineIndex(context, ticketId, turnId)

    // Get turn file
    const files = await getOdsBlobMetadata(context, ticketId).promise
    const file = files.sssOffline.find((file) => file.index === index && file.group === 'Group4')
    if (!file) {
      // Validate offline data timeliness
      const session = await getTicketSession(context, ticketId).promise
      validateTicket3sOfflineDataTimeliness(ticketId, session.feedbackTime)

      throw TicketError.create('No3sOffline', { ticketId, ticketMessageId: turnId })
    }

    return await context.api.ods.get3sOfflineDataGroup4(file.fileUrl)
  }
}
