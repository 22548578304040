import { useState } from 'react'
import { createStore } from './createStore'
import { IStore } from './IStore'
import { IStoreCreator } from './IStoreCreator'

export function useStore<TState, TActions>(creator: IStoreCreator<TState, TActions>): IStore<TState, TActions> {
  const [store] = useState(() => {
    return createStore(creator)
  })

  return store
}
