import { Button, makeStyles, OverflowItem, tokens, Tooltip, useOverflowMenu } from '@fluentui/react-components'
import { ChevronDownRegular, ChevronUpRegular } from '@fluentui/react-icons'
import { useCallback } from 'react'

interface IProps {
  readonly expanded: boolean
  readonly onChanged: (expanded: boolean) => void
}

export function TicketScreenTagOverflowButton({ expanded, onChanged }: IProps) {
  const styles = useStyles()
  const { isOverflowing, overflowCount } = useOverflowMenu<HTMLButtonElement>()

  const handleClick = useCallback(() => {
    onChanged(!expanded)
  }, [expanded, onChanged])

  if (!isOverflowing && !expanded) {
    return null
  }

  const buttonText = expanded ? 'Less Tags' : `More Tags`
  const tooltipText = expanded ? 'Show less tags' : `Show more ${overflowCount} tags`

  return (
    <OverflowItem id="overflow-button" priority={999999}>
      <Tooltip key={expanded.toString()} content={tooltipText} relationship={'label'} showDelay={500}>
        <Button
          className={styles.button}
          icon={expanded ? <ChevronUpRegular fontSize="12px" /> : <ChevronDownRegular fontSize="12px" />}
          appearance="subtle"
          size="small"
          shape="circular"
          onClick={handleClick}
        >
          {buttonText}
        </Button>
      </Tooltip>
    </OverflowItem>
  )
}

const useStyles = makeStyles({
  button: {
    flexShrink: 0,
    flexGrow: 0,
    color: tokens.colorBrandForeground1,
    fontSize: tokens.fontSizeBase200,

    ':hover': {
      color: tokens.colorBrandForeground1,
    },
  },
})
