import { Draft } from 'immer'
import { Path } from 'react-router-dom'
import { RouterNavigateOptions } from '@remix-run/router'
import { IRouteContext } from '../types/IRouteContext'
import { IRouteMapper } from '../types/IRouteMapper'
import { generatePath } from './actions/generatePath'
import { generatePathString } from './actions/generatePathString'
import { generateRedirectResponse } from './actions/generateRedirectResponse'
import { getArgs } from './actions/getArgs'
import { getArgsOptional } from './actions/getArgsOptional'
import { isCurrent } from './actions/isCurrent'
import { navigate } from './actions/navigate'
import { updateArgsIfActive } from './actions/updateArgsIfActive'
import { useArgs } from './actions/useArgs'
import { useArgsOptional } from './actions/useArgsOptional'
import { useIsCurrent } from './actions/useIsCurrent'

export function createNavigator<TArgs>(id: string, path: string, mapper: IRouteMapper<TArgs>) {
  const context: IRouteContext<TArgs> = { id, path, mapper }

  return {
    get id(): string {
      return id
    },

    isCurrent(): boolean {
      return isCurrent(context)
    },

    getArgs(): TArgs {
      return getArgs(context)
    },

    getArgsOptional(): TArgs | null {
      return getArgsOptional(context)
    },

    generatePath(args: TArgs): Readonly<Path> {
      return generatePath(args, context)
    },

    generatePathString(args: TArgs): string {
      return generatePathString(args, context)
    },

    generateRedirectResponse(args: TArgs): Response {
      return generateRedirectResponse(args, context)
    },

    navigate(args: TArgs, options?: RouterNavigateOptions): void {
      navigate(args, context, options)
    },

    updateArgsIfActive(updater: (draft: Draft<TArgs>) => void): boolean {
      return updateArgsIfActive(updater, context)
    },

    useIsCurrent(): boolean {
      return useIsCurrent(context)
    },

    useArgs(): TArgs {
      return useArgs(context)
    },

    useArgsOptional(): TArgs | null {
      return useArgsOptional(context)
    },
  }
}
