import { IPublicClientApplication } from '@azure/msal-browser'
import { Logger } from '@copilot-dash/logger'
import { DashAuthConstants } from '../DashAuthConstants'

export async function logout(client: IPublicClientApplication): Promise<void> {
  try {
    Logger.trace.info('Starting logout by redirect flow')
    await client.logout({
      postLogoutRedirectUri: DashAuthConstants.LOGOUT_REDIRECT_URI,
    })

    await new Promise((resolve) => setTimeout(resolve, DashAuthConstants.REDIRECT_WAIT_TIME_MS))
    throw new Error('Failed to logout by redirect flow. Cause: Timeout')
  } catch (e) {
    throw new Error('Failed to logout by redirect flow. Cause: ' + e)
  }
}
