import { TelemetryScope } from '@copilot-dash/logger'
import { DashStoreProvider } from '@copilot-dash/store'
import { ErrorViewBoundary } from './components/Error'
import { GlobalProductProvider } from './providers/product'
import { GlobalToastProvider } from './providers/toast/GlobalToastProvider'
import { AppRouterProvider } from './router'
import { ThemeProvider } from './theme/ThemeProvider'

export function DashboardApp() {
  return (
    <ErrorViewBoundary level="global" label="DashboardApp">
      <TelemetryScope scope="App">
        <DashStoreProvider store={app.store}>
          <ThemeProvider>
            <GlobalToastProvider>
              <GlobalProductProvider>
                <AppRouterProvider router={app.router} />
              </GlobalProductProvider>
            </GlobalToastProvider>
          </ThemeProvider>
        </DashStoreProvider>
      </TelemetryScope>
    </ErrorViewBoundary>
  )
}
