import { makeStyles, Text } from '@fluentui/react-components'
import { InlineDivider } from '../../../../components/Divider/InlineDivider'
import { Column, Row } from '../../../../components/Layout'

export function SettingsScreenOutlineHeader() {
  const styles = useStyles()

  return (
    <Column className={styles.root}>
      <Row className={styles.main} vAlign="center">
        <Text size={500} weight="semibold">
          Settings
        </Text>
      </Row>
      <InlineDivider appearance="subtle" inset />
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  main: {
    height: '64px',
    padding: '4px 16px',
  },
})
