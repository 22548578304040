import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoLogData, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicketSession } from '../actions-ticket/getTicketSession'
import { validateTicketDiagnosticDataForConversation } from '../validators/validateTicketDiagnosticData'
import { validateTicketKustoExpiration } from '../validators/validateTicketKustoData'
import { validateTicketUserConsent } from '../validators/validateTicketUserConsent'
import { fetchTicketKustoData } from './fetch/fetchTicketKustoData'

export function getTicketKustoData<
  T extends Exclude<
    KustoLogTable,
    KustoLogTable.BizChat3SLatencyLog | KustoLogTable.StateDurationLog | KustoLogTable.GwsLog
  >,
>(context: IDashStoreContext, ticketId: string, messageId: string, table: T): PromiseSnapshot<IKustoLogData> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.kusto?.[table]
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      const kusto = (turn.kusto ??= {})
      kusto[table] = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<IKustoLogData> {
    const session = await getTicketSession(context, ticketId).promise

    // 1. Check User consent
    validateTicketUserConsent(ticketId, session)

    // 3. Check interaction
    const interaction = session.interactions.find((item) => item.messageId === messageId)
    if (!interaction) {
      // 3.1. Check ticket Kusto expiration
      validateTicketKustoExpiration(ticketId, table, session)

      // 3.2. Throw error
      throw TicketError.create('NoKustoDueToInteractionFieldIsMissing', { ticketId, kustoLogTable: table })
    }

    const result = await fetchTicketKustoData(context, {
      table,
      interactionTime: interaction.interactionTime ?? '',
      messageId,
    })
    if (result[table].length > 0) {
      return result
    }

    // 5. Check ticket Kusto expiration
    validateTicketKustoExpiration(ticketId, table, session)

    // 6. Check ticket conversation diagnostic data
    // TODO: Some tables may not require conversation data
    validateTicketDiagnosticDataForConversation(ticketId, messageId, session)

    // 6. Unknown error
    throw TicketError.create('NoKusto', { ticketId, kustoLogTable: table })
  }
}
