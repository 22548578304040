import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  chartContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  chartItem: {
    display: 'flex',
    width: 'calc(50% - 16px)',
    borderRadius: tokens.borderRadiusXLarge,
    border: `1px solid ${tokens.colorNeutralBackground1Pressed}`,
    padding: '10px',
    flexDirection: 'column',
    margin: '2px 8px 8px',
    boxShadow: tokens.shadow8,
  },
  tableContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  tableItem: {
    display: 'flex',
    maxHeight: 'auto',
    width: 'calc(25% - 16px)',
    borderRadius: tokens.borderRadiusXLarge,
    border: `1px solid ${tokens.colorNeutralBackground1Pressed}`,
    padding: '15px',
    flexDirection: 'column',
    margin: '2px 8px 8px',
    boxShadow: tokens.shadow8,
  },
  labelItem: {
    marginLeft: '5px',
  },
  textItem: {
    color: tokens.colorNeutralForeground2LinkHover,
  },
  filterContainer: {
    display: 'inline-block',
  },
  filterItem: {
    display: 'inline-block',
    marginRight: '1rem',
    verticalAlign: 'top',
  },
  filterDropdownItem: {
    display: 'block',
  },
  barChartContainer: {
    width: '100%',
    height: '600px',
  },
})
