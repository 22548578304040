import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketData } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { convertTicketDataFromApiContext } from '../converters/convertTicketData'

export function getTicket(context: IDashStoreContext, ticketId: string): PromiseSnapshot<ITicketData> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.ticket
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.ticket = snapshot
    },
    fetch: async () => {
      const response = await context.api.logCollector.getCopilotTicket({ ExternalTicketId: ticketId })
      const ticket = response.copilotTicketDetailContext
      if (ticket) {
        return convertTicketDataFromApiContext(ticket)
      }

      const dsrResponse = await context.api.logCollector.getDSRTicket(ticketId)
      if (dsrResponse.feedbackItemId) {
        throw TicketError.create('DSRTicket', { ticketId })
      } else {
        throw TicketError.create('NoTicket', { ticketId })
      }
    },
  })
}
