import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical'
import { Dispatch, useCallback, useEffect, useRef, useState } from 'react'
import { useStyles } from './ToolbarPlugin.styles'
import { Toolbar, Text, Button, ToolbarDivider, mergeClasses } from '@fluentui/react-components'
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from '@lexical/list'
import {
  LinkRegular,
  ListRegular,
  TextBoldRegular,
  TextItalicRegular,
  TextNumberListLtrRegular,
  TextStrikethroughRegular,
  TextUnderlineRegular,
} from '@fluentui/react-icons'
import { CopilotDashTooltip } from '../../../CopilotDashTooltip/CopilotDashTooltip'
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import { sanitizeUrl } from '../../utils/url'
import { getSelectedNode } from '../../utils/getSelectedNode'
import { FileUploadButton } from '../imagePlugin/UploadImage'

interface IToolbarPluginProps {
  setIsLinkEditMode: Dispatch<boolean>
}

export function ToolbarPlugin({ setIsLinkEditMode }: IToolbarPluginProps) {
  const [editor] = useLexicalComposerContext()
  const toolbarRef = useRef(null)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)
  const [unorderedList, setIsUnorderedList] = useState(false)
  const [orderedList, setIsOrderedList] = useState(false)
  const [isLink, setIsLink] = useState(false)
  const styles = useStyles()
  const LowPriority = 1

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
      setIsStrikethrough(selection.hasFormat('strikethrough'))
      //link
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      setIsLink($isLinkNode(parent) || $isLinkNode(node))
    }
  }, [])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          $updateToolbar()
          return false
        },
        LowPriority,
      ),
    )
  }, [editor, $updateToolbar])

  const formatBulletList = () => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection) && selection.isCollapsed()) {
        const paragraphNode = $createParagraphNode()
        selection.insertNodes([paragraphNode])
        const textNode = paragraphNode.getFirstDescendant()
        if (textNode && $isTextNode(textNode)) {
          selection.setTextNodeRange(textNode, 0, textNode, 0)
        }
      }

      if (!unorderedList) {
        if (orderedList) {
          editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
          setIsOrderedList(false)
        }
        editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
        setIsUnorderedList(true)
      } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
        setIsUnorderedList(false)
      }
    })
  }

  const formatNumberedList = () => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection) && selection.isCollapsed()) {
        const paragraphNode = $createParagraphNode()
        selection.insertNodes([paragraphNode])
        const textNode = paragraphNode.getFirstDescendant()
        if (textNode && $isTextNode(textNode)) {
          selection.setTextNodeRange(textNode, 0, textNode, 0)
        }
      }

      if (!orderedList) {
        if (unorderedList) {
          editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
          setIsUnorderedList(false)
        }
        editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
        setIsOrderedList(true)
      } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
        setIsOrderedList(false)
      }
    })
  }

  const insertLink = useCallback(() => {
    if (!isLink) {
      setIsLinkEditMode(true)
      setIsLink(true)
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'))
    } else {
      setIsLinkEditMode(false)
      setIsLink(false)
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [editor, isLink, setIsLink, setIsLinkEditMode])

  return (
    <Toolbar aria-label="Default" style={{ padding: 0 }} ref={toolbarRef}>
      <CopilotDashTooltip
        relationShip="label"
        toolTipContent={<Text>Bold</Text>}
        toolTipParentContent={
          <Button
            className={mergeClasses(styles.toolbarButton, isBold ? styles.toolbarButtonActive : undefined)}
            icon={<TextBoldRegular />}
            appearance="transparent"
            aria-label="Format Bold"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
            }}
          />
        }
      />
      <CopilotDashTooltip
        relationShip="label"
        toolTipContent={<Text>italic</Text>}
        toolTipParentContent={
          <Button
            className={mergeClasses(styles.toolbarButton, isItalic ? styles.toolbarButtonActive : undefined)}
            icon={<TextItalicRegular />}
            appearance="transparent"
            aria-label="Format Italics"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
            }}
          />
        }
      />
      <CopilotDashTooltip
        relationShip="label"
        toolTipContent={<Text>Under Line</Text>}
        toolTipParentContent={
          <Button
            className={mergeClasses(styles.toolbarButton, isUnderline ? styles.toolbarButtonActive : undefined)}
            icon={<TextUnderlineRegular />}
            appearance="transparent"
            aria-label="Format Underline"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
            }}
          />
        }
      />
      <CopilotDashTooltip
        relationShip="label"
        toolTipContent={<Text>StrikeThrough</Text>}
        toolTipParentContent={
          <Button
            className={mergeClasses(styles.toolbarButton, isStrikethrough ? styles.toolbarButtonActive : undefined)}
            icon={<TextStrikethroughRegular />}
            appearance="transparent"
            aria-label="Format Strikethrough"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
            }}
          />
        }
      />
      <ToolbarDivider style={{ padding: 4 }} />
      <CopilotDashTooltip
        relationShip="label"
        toolTipContent={<Text>Bulleted list</Text>}
        toolTipParentContent={
          <Button
            className={mergeClasses(styles.toolbarButton, unorderedList ? styles.toolbarButtonActive : undefined)}
            icon={<ListRegular />}
            appearance="transparent"
            onClick={formatBulletList}
          />
        }
      />
      <CopilotDashTooltip
        relationShip="label"
        toolTipContent={<Text>Numbered list</Text>}
        toolTipParentContent={
          <Button
            className={mergeClasses(styles.toolbarButton, orderedList ? styles.toolbarButtonActive : undefined)}
            icon={<TextNumberListLtrRegular />}
            appearance="transparent"
            onClick={formatNumberedList}
          />
        }
      />
      <ToolbarDivider style={{ padding: 4 }} />
      <CopilotDashTooltip
        relationShip="label"
        toolTipContent={<Text>Insert link</Text>}
        toolTipParentContent={
          <Button
            className={mergeClasses(styles.toolbarButton, isLink ? styles.toolbarButtonActive : undefined)}
            icon={<LinkRegular />}
            appearance="transparent"
            onClick={insertLink}
          />
        }
      />
      <FileUploadButton editor={editor} />
    </Toolbar>
  )
}
