import { ColumnIdNaColumnIdNameMapping, ColumnKey, INewTicketData } from '@copilot-dash/domain'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { ExtendedTableColumnDefinition, createExtendedTableColumn } from '../Table/ExtendedTableColumn'
import { TimeView } from '../Time/TimeView'
import { TimezoneView } from '../Timezone/TimezoneView'

export const ColumnConfigDateTime: ExtendedTableColumnDefinition<INewTicketData> =
  createExtendedTableColumn<INewTicketData>({
    columnId: ColumnKey.Date,
    columnName: ColumnIdNaColumnIdNameMapping[ColumnKey.Date],
    isResizable: true,
    flex: 1,
    columnSize: {
      minWidth: 60,
      idealWidth: 120,
      padding: 0,
    },
    renderHeaderCell: () => {
      return (
        <TableCellLayoutWithStyles truncate>
          <TimezoneView>
            {(data) => {
              return <span>Date ({data.valueLabel})</span>
            }}
          </TimezoneView>
        </TableCellLayoutWithStyles>
      )
    },
    renderCell: (item: INewTicketData) => {
      return (
        <TableCellLayoutWithStyles>
          <TimeView value={item.dateTimeUtc} format="MM/DD HH:mm" />
        </TableCellLayoutWithStyles>
      )
    },
  })
