import { IDashAuth } from '@copilot-dash/auth'
import { IDashEnvironment } from '@copilot-dash/core'
import { IDashLogger, IDashLoggerOptions, Logger } from '@copilot-dash/logger'

const ACCOUNT_ID_INITIATING = 'Initiating'
const ACCOUNT_ID_NOT_LOGIN = 'Unauthenticated'

export function createAppLogger(env: IDashEnvironment, auth: IDashAuth): IDashLogger {
  const options: IDashLoggerOptions = {
    environment: env,
    getAccountId: () => {
      if (auth.isInitialized) {
        return auth.activeAccount?.localAccountId ?? ACCOUNT_ID_NOT_LOGIN
      } else {
        return ACCOUNT_ID_INITIATING
      }
    },
  }

  Logger.init(options)
  return Logger
}
