import { FC, memo } from 'react'
import { Field, Dropdown, Option } from '@fluentui/react-components'

export const ROOT_CAUSE_PRIORITY_OPTIONS = [1, 2, 3, 4] as const

interface IPriorityProps {
  value: number | undefined
  onChange: (value: number) => void
  orientation?: 'horizontal' | 'vertical'
  className?: string
  style?: React.CSSProperties
  defaultValueText?: string
  disabled?: boolean
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
}

export const Priority: FC<IPriorityProps> = memo(
  ({ value, onChange, orientation = 'vertical', className, style, defaultValueText = '', disabled, fieldProps }) => {
    return (
      <Field className={className} label="Priority:" orientation={orientation} style={style} {...(fieldProps ?? {})}>
        <Dropdown
          disabled={disabled}
          value={typeof value === 'number' ? `P${value}` : defaultValueText}
          selectedOptions={String(value) ? [String(value)] : []}
          onOptionSelect={(_, data) => data.selectedOptions[0] && onChange(Number(data.selectedOptions[0]))}
          appearance="underline"
        >
          {ROOT_CAUSE_PRIORITY_OPTIONS.map((item: number) => (
            <Option key={item} value={String(item)} text={`P${item}`}>
              <span>P{item}</span>
            </Option>
          ))}
        </Dropdown>
      </Field>
    )
  },
)

Priority.displayName = 'Priority'
