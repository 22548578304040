import { BrandVariants, createDarkTheme, createLightTheme, Theme } from '@fluentui/react-components'

/**
 * You can generate theme colors at:
 * https://react.fluentui.dev/?path=/docs/theme-theme-designer--page
 */
const THEME_COLOR_BRAND_VARIANTS: BrandVariants = {
  10: '#16152B',
  20: '#1E1D40',
  30: '#27265C',
  40: '#2E2E78',
  50: '#353696',
  60: '#3B3FB2',
  70: '#3E45C9',
  80: '#464FEB',
  90: '#515EF5',
  100: '#5F71FA',
  110: '#7385FF',
  120: '#8295FF',
  130: '#96A8FF',
  140: '#B0BEFF',
  150: '#CCD6FF',
  160: '#EBEFFF',
}

export const Themes = {
  light: createLightTheme(THEME_COLOR_BRAND_VARIANTS),
  dark: createDarkTheme(THEME_COLOR_BRAND_VARIANTS),
}

export function getTheme(isDarkMode: boolean): Theme {
  return isDarkMode ? createDarkTheme(THEME_COLOR_BRAND_VARIANTS) : createLightTheme(THEME_COLOR_BRAND_VARIANTS)
}
