import { z } from 'zod'

export interface ApiGetTeamDRIListByTeamIdResponse {
  readonly driList: Array<string>
  readonly errorMessage: string
}

export const apiGetTeamDRIListByTeamIdResponseSchema = z.object({
  driList: z.array(z.string()),
  errorMessage: z.string(),
})
