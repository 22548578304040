import { z } from 'zod'

export interface ApiRootCausesByTeamResponse {
  autoRootCausedDSATs: number
  unRootCausedDSATs: number
  allDSATs: number
  rootCauseList: Array<{
    rootCauseTitle: string
    reportingStatus?: string
    eTA?: string
    owner?: string
    issueId: string
    vsoAccount: string
    rootCauseCount: number
    rootCauseStatus?: string
    rootCausePriority?: number
  }>
  recommendedRootCausesList: Array<{
    rootCauseTitle: string
    reportingStatus?: string
    eTA?: string
    owner?: string
    issueId: string
    vsoAccount: string
    rootCauseCount: number
    rootCauseStatus?: string
    rootCausePriority?: number
  }>
}

export const apiRootCausesByTeamResponseSchema = z.object({
  autoRootCausedDSATs: z.number(),
  unRootCausedDSATs: z.number(),
  allDSATs: z.number(),
  rootCauseList: z.array(
    z.object({
      rootCauseTitle: z.string(),
      reportingStatus: z.string().optional(),
      eTA: z.string().optional(),
      owner: z.string().optional(),
      issueId: z.string(),
      vsoAccount: z.string(),
      rootCauseCount: z.number(),
      rootCauseStatus: z.string().optional(),
      rootCausePriority: z.number().optional(),
    }),
  ),
  recommendedRootCausesList: z.array(
    z.object({
      rootCauseTitle: z.string(),
      reportingStatus: z.string().optional(),
      eTA: z.string().optional(),
      owner: z.string().optional(),
      issueId: z.string(),
      vsoAccount: z.string(),
      rootCauseCount: z.number(),
      rootCauseStatus: z.string().optional(),
      rootCausePriority: z.number().optional(),
    }),
  ),
})
