import { IActivityHistory } from '@copilot-dash/domain'
import { Text, Tooltip } from '@fluentui/react-components'
import { FC } from 'react'
import { Column, Row, Spacer } from '../../Layout'
import { summarizeActivity } from './config'
import { useStyles } from './UserActivity.styles'
import { ActivityHistoryDetails } from './ActivityHistoryDetails'
import { useActivityHistoryPanelStore } from '../store/store'
import { ActivityTitle } from './ActivityTitle'
interface IProps {
  readonly activity: IActivityHistory
}

export const UserActivity: FC<IProps> = ({ activity }) => {
  const styles = useStyles()
  const selectedActivity = useActivityHistoryPanelStore((state) => state.selectedActivity)

  return (
    <Column fill className={styles.container}>
      <Row>
        <ActivityTitle activity={activity} />
      </Row>
      <Spacer height={4} />
      <Row>
        <Tooltip content={summarizeActivity(activity)} relationship={'label'}>
          <Text className={styles.summarizeActivity}>{summarizeActivity(activity)}</Text>
        </Tooltip>
      </Row>
      <Spacer height={4} />
      {selectedActivity?.activityId === activity.activityId && (
        <Row>
          <ActivityHistoryDetails />
        </Row>
      )}
      <Spacer height={12} />
    </Column>
  )
}
