import { ITicketData } from '@copilot-dash/domain'
import { Text } from '@fluentui/react-components'
import { ErrorView } from '../../../../components/Error'

interface IProps {
  readonly ticket: ITicketData
}

export function TicketScreenBarTicketTenant({ ticket }: IProps) {
  return (
    <Text>
      <Text weight="semibold">Tenant: </Text>
      {ticket.tenantName ? (
        <Text>{ticket.tenantName}</Text>
      ) : (
        <ErrorView.Custom type="inline" level="WARNING" message="N/A" />
      )}
    </Text>
  )
}
