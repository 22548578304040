import { IDashEnvironment, uuid } from '@copilot-dash/core'
import { createAppEnvRing } from './createAppEnvRing'

export function createAppEnv(): IDashEnvironment {
  return {
    ring: createAppEnvRing(),
    buildTime: import.meta.env.VITE_BUILD_TIME,
    buildVersion: Number(import.meta.env.VITE_BUILD_VERSION),
    buildBranch: import.meta.env.VITE_GIT_BRANCH,
    commitHash: import.meta.env.VITE_GIT_COMMIT_HASH,
    commitMessage: import.meta.env.VITE_GIT_COMMIT_MESSAGE,
    commitAuthor: import.meta.env.VITE_GIT_COMMIT_AUTHOR,
    commitTimestamp: import.meta.env.VITE_GIT_COMMIT_DATE,
    sessionId: uuid(),
  }
}
