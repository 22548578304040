import { isPlainObject, mapValues } from 'lodash'
import { z } from 'zod'

const DATE_TIME_MIN_LENGTH = 18
const DATE_TIME_MAX_LENGTH = 30
const DATE_TIME_SCHEMA_1 = z.string().datetime()

/**
 * It matches the following formats:
 * - 07/23/2024 02:28:27 AM
 * - 07/23/2024 02:28:27 PM
 * - 7/23/2024 02:28:27
 */
const DATE_TIME_SCHEMA_2 = z.string().regex(/^\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{1,2}:\d{1,2}( (AM|PM))?$/i)

export function parseToStandardDate(value: unknown): unknown {
  return replaceAllNullWithUndefined(value)

  function replaceAllNullWithUndefined(value: unknown): unknown {
    if (value === null) {
      return undefined
    }

    if (value && typeof value === 'string') {
      return processString(value)
    }

    if (value && Array.isArray(value)) {
      return value.map((val) => replaceAllNullWithUndefined(val))
    }

    if (value && typeof value === 'object' && isPlainObject(value)) {
      return mapValues(value, (val) => replaceAllNullWithUndefined(val))
    }

    return value
  }

  /**
   * This code is just a patch to cover up the trashy code for the server-side code
   *
   * The server side will returns two invalid UTC date time formats:
   * 1. 2024-06-21T06:09:46.807
   * 2. 6/21/2024 11:13:06 AM
   */
  function processString(value: string): string {
    if (value.length < DATE_TIME_MIN_LENGTH || value.length > DATE_TIME_MAX_LENGTH) {
      return value
    }

    if (value.endsWith('Z') || value.endsWith('z')) {
      return value
    }

    if (DATE_TIME_SCHEMA_1.safeParse(value + 'Z').success) {
      return value + 'Z'
    }

    if (DATE_TIME_SCHEMA_2.safeParse(value).success) {
      return value + ' Z'
    }

    return value
  }
}
