import { Button, makeStyles, Tooltip } from '@fluentui/react-components'
import { DismissRegular } from '@fluentui/react-icons'
import { IErrorComponents } from '../../ErrorConfigs/interfaces/IErrorComponents'

interface IProps {
  readonly components: IErrorComponents
  readonly onClose?: () => void
}

export function ErrorViewPopoverHeaderTrailing({ components, onClose }: IProps) {
  const styles = useStyles()
  if (!onClose) {
    return null
  }

  return (
    <Tooltip content={'Close'} relationship={'label'}>
      <Button className={styles.root} appearance="subtle" size="small" onClick={onClose} icon={<DismissRegular />} />
    </Tooltip>
  )
}

const useStyles = makeStyles({
  root: {
    margin: '2px 12px',
  },
})
