import { z } from 'zod'

export interface ApiTicketAdditionalContext {
  readonly [key: string]: ApiTicketAdditionalContextItem | undefined

  readonly ReplayErrorType?: ApiTicketAdditionalContextItem
  readonly TransactionIds?: ApiTicketAdditionalContextItem
  readonly 'Feedback Date'?: ApiTicketAdditionalContextItem
  readonly Is3SSearchTriggered?: ApiTicketAdditionalContextItem
}

export interface ApiTicketAdditionalContextItem {
  readonly Type: string
  readonly Result?: string | null
}

export const apiTicketAdditionalContextItemSchema = z.object({
  Type: z.string(),
  Result: z.string().optional().or(z.null()),
})

export const apiTicketAdditionalContextSchema = z
  .record(z.union([apiTicketAdditionalContextItemSchema, z.undefined()]))
  .and(
    z.object({
      ReplayErrorType: apiTicketAdditionalContextItemSchema.optional(),
      TransactionIds: apiTicketAdditionalContextItemSchema.optional(),
      'Feedback Date': apiTicketAdditionalContextItemSchema.optional(),
      Is3SSearchTriggered: apiTicketAdditionalContextItemSchema.optional(),
    }),
  )
