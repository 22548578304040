import { INewTicketData, SearchTextPrefixType } from '@copilot-dash/domain'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Column, Row, Spacer } from '../../../../../../../components/Layout'
import { ResizableColumnsTable } from '../../../../../../../components/Table/ResizableColumnsTable'
import { AllSearchTableColumns } from '../../../../../../../components/TicketTableColumnConfigs'
import { useSearchScreenActions, useSearchScreenState } from '../../../../../store'
import { FilterRegular, LinkDismissRegular } from '@fluentui/react-icons'
import {
  Text,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTrigger,
  InteractionTag,
  InteractionTagPrimary,
  InteractionTagSecondary,
  Spinner,
  TagGroup,
} from '@fluentui/react-components'
import { ISearchTicketOptions } from '@copilot-dash/store'
import { LoaderComponent } from '../../../../loader/LoaderComponent'
import { SearchRoute } from '../../../../../../../router'

interface IProps {
  readonly scenario: string
  readonly searchParams: Partial<ISearchTicketOptions>
  readonly expectedTicketCount: number
}

export const CustomSearchTickets = memo(function CustomSearchTickets(props: IProps) {
  const { scenario, searchParams, expectedTicketCount } = props

  const actions = useSearchScreenActions()
  const selectedRowId = useSearchScreenState((state) => state.clickedTicketInfo?.ticketId)
  const columns = app.settings.feedbackInsightsTopIssueDetailsColumns.use()
  const selectedColumns = useMemo(() => {
    const allColumns = AllSearchTableColumns(SearchTextPrefixType.All)
    return columns.map((column) => allColumns.find((c) => c.columnId === column)!).filter((c) => c)
  }, [columns])

  const args = SearchRoute.navigator.useArgsOptional()
  const issueId = args?.issueId
  const batchId = args?.issueBatchId

  const [tickets, setTickets] = useState<INewTicketData[]>([])
  const [loading, setLoading] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)

  const doubleConfirmRemoveTicketRef = useRef<INewTicketData | undefined>()
  const [removing, setRemoving] = useState(false)
  const handleRemoveTicket = useCallback(
    (feedbackId: string) => {
      if (batchId && issueId && feedbackId) {
        setRemoving(true)
        app.store.actions
          .removeTicketFromTopIssue(batchId, issueId, feedbackId)
          .then(() => {
            setTickets((pre) => pre.filter((item) => item.ticketId !== feedbackId))
          })
          .finally(() => {
            setRemoving(false)
            setDialogOpen(false)
          })
      }
    },
    [batchId, issueId],
  )

  const rowHoverStickySlot = useCallback(
    (rowData: INewTicketData) => (
      <DialogTrigger disableButtonEnhancement>
        <Button
          onClick={() => {
            doubleConfirmRemoveTicketRef.current = rowData
          }}
          icon={<LinkDismissRegular />}
          style={{ width: 'max-content' }}
          appearance="transparent"
        >
          Remove from Issue
        </Button>
      </DialogTrigger>
    ),
    [],
  )

  const onClose = useCallback(() => {
    actions.updateFeedbackInsightsIssueDetailDrillDownInfo(undefined, undefined)
  }, [actions])

  const searchParamsRef = useRef(searchParams)
  searchParamsRef.current = searchParams
  useEffect(() => {
    const curArgs = SearchRoute.navigator.getArgsOptional()
    if (!curArgs) return
    setLoading(true)
    app.store.actions
      .searchTickets({
        product: curArgs.product,
        // NOTE: There is a bug in web service api which must have a default range for now, Zhongxian will fix it later. Temporarily use a huge range of dates.
        defaultRange: { type: 'absolute', from: '2022-12-11T16:00:00Z', to: '2100-12-12T15:59:59Z' },
        clusteringIssueId: curArgs.issueId,
        batchId: curArgs.issueBatchId,
        count: expectedTicketCount,
        offset: 0,
        isAIF: false,
        teamId: 0,
        ticketStatus: 'TopIssue',
        ...searchParamsRef.current,
      })
      .then((result) => {
        setTickets(result.tickets)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [expectedTicketCount, scenario])

  return (
    <Dialog
      modalType="alert"
      open={dialogOpen}
      onOpenChange={(_, data) => {
        setDialogOpen(data.open)
        if (!data.open) {
          doubleConfirmRemoveTicketRef.current = undefined
        }
      }}
    >
      <Column style={{ width: '100%' }}>
        <Row vAlign="center" style={{ marginBottom: '24px' }}>
          <FilterRegular fontSize={16} />
          <Spacer width="12px" />
          <TagGroup onDismiss={onClose}>
            <InteractionTag shape="circular">
              <InteractionTagPrimary hasSecondaryAction>{scenario}</InteractionTagPrimary>
              <InteractionTagSecondary aria-label="remove" />
            </InteractionTag>
          </TagGroup>
        </Row>
        {loading ? (
          <Column vAlign="center" fill>
            <Spacer height={50} />
            <LoaderComponent />
          </Column>
        ) : (
          <ResizableColumnsTable
            items={tickets}
            columns={selectedColumns}
            keyPicker={(item) => item.ticketId}
            enableColumnResizing={true}
            rowClick={actions.rowClick}
            selectedRowId={selectedRowId}
            rowHoverStickySlot={rowHoverStickySlot}
          />
        )}
      </Column>
      <DialogSurface>
        <DialogBody>
          <DialogContent>
            <Text size={400} weight="semibold">
              Are you sure to remove the DSAT from the current issue clustering?
            </Text>
          </DialogContent>
          <DialogActions>
            <Button
              appearance="primary"
              disabled={removing}
              icon={removing ? <Spinner size="extra-tiny" /> : null}
              onClick={() => {
                doubleConfirmRemoveTicketRef.current &&
                  handleRemoveTicket(doubleConfirmRemoveTicketRef.current.ticketId)
              }}
            >
              Remove
            </Button>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Cancel</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
})
