import { IRootCauseListByTeam } from '@copilot-dash/domain'
import { useEffect, useState } from 'react'
import { useTeamViewStore } from '../../store'
import { isEmpty } from 'lodash'
import { usePromise } from '@copilot-dash/core'

export function useRootCauseList() {
  const productId = useTeamViewStore((state) => state.computed.productId)
  const teamId = useTeamViewStore((state) => state.teams.computed.selectedTeamId)
  const form = useTeamViewStore((state) => state.tickets.filterForm)

  const [rootCauseListByFilterPromise, setRootCauseListByFilterPromise] = useState<Promise<IRootCauseListByTeam>>(
    Promise.resolve<IRootCauseListByTeam>({ rootCauseList: [] }),
  )
  const rootCauseListByFilterSnapshot = usePromise(rootCauseListByFilterPromise)

  const rootCauseSnapshot = application.store.use((state) => state.team.rootCausesMap?.[`${productId}-${teamId}`])

  const searchByTicketCharacterTriggered = !isEmpty(form)

  useEffect(() => {
    if (productId && teamId) {
      application.store.actions.getOrFetchRootCauseListByTeam(productId, teamId)
    }
  }, [productId, teamId])

  useEffect(() => {
    if (teamId && searchByTicketCharacterTriggered) {
      setRootCauseListByFilterPromise(
        application.store.actions.searchRootCauseByTicketCharacter({ ...form, teamId: teamId }),
      )
    }
  }, [teamId, searchByTicketCharacterTriggered, form])

  return searchByTicketCharacterTriggered ? rootCauseListByFilterSnapshot : rootCauseSnapshot
}
