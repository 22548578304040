import { INewTicketData, ColumnKey, ColumnIdNaColumnIdNameMapping } from '@copilot-dash/domain'
import { ExtendedTableColumnDefinition, createExtendedTableColumn } from '../Table/ExtendedTableColumn'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'

export const ColumnConfigRing: ExtendedTableColumnDefinition<INewTicketData> =
  createExtendedTableColumn<INewTicketData>({
    columnId: ColumnKey.Ring,
    columnName: ColumnIdNaColumnIdNameMapping[ColumnKey.Ring],
    isResizable: true,
    columnSize: {
      minWidth: 50,
      idealWidth: 80,
      padding: 0,
    },

    renderHeaderCell: () => <TableCellLayoutWithStyles>Ring</TableCellLayoutWithStyles>,

    renderCell: (item: INewTicketData) => <TableCellLayoutWithStyles truncate>{item.ring}</TableCellLayoutWithStyles>,
  })
