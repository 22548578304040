import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    position: 'absolute',
    top: '8px',
    left: 0,
    zIndex: 10,
    maxWidth: '400px',
    width: '100%',
    opacity: 0,
    backgroundColor: tokens.colorNeutralBackground1,
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.3)',
    ...shorthands.borderRadius('4px'),
    transition: 'opacity 0.5s',
    willChange: 'transform',
  },
  linkInputContainer: { padding: '4px' },
  linkView: { margin: '4px' },
  linkPreview: {
    display: 'inline-block',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    padding: '0px 15px',
    flex: '1',
  },
})
