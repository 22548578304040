import { IRouteContext } from '../../types/IRouteContext'
import { isCurrent } from './isCurrent'
import { getCurrentRouteParams } from './utils/getCurrentRouteParams'

export function getArgsOptional<TArgs>(context: IRouteContext<TArgs>): TArgs | null {
  if (!isCurrent(context)) {
    return null
  }

  const currentRouteParams = getCurrentRouteParams()
  return context.mapper.mapToArgs(currentRouteParams)
}
