import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  commonRow: {
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground3Hover,
      cursor: 'pointer',
      [`& .stickyRow`]: {
        visibility: 'visible',
      },
    },
    ':active': {
      backgroundColor: tokens.colorNeutralBackground2,
    },
    [`& .stickyRow`]: {
      visibility: 'hidden',
      position: 'sticky',
      right: 0,
      alignSelf: 'stretch',
      paddingLeft: '22px',
      background: 'linear-gradient(to right, #ECECEC00, #EBEBEB 22px, #EBEBEB)',
    },
  },
  selectedRow: {
    backgroundColor: tokens.colorBrandBackground2,
    ':hover': {
      cursor: 'pointer',
    },
    ':active': {
      backgroundColor: tokens.colorBrandBackground2,
    },
  },
})
