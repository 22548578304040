import { PromiseSnapshots } from '@copilot-dash/core'
import { IComment, ICommentRequest } from '@copilot-dash/domain'
import { IDashStoreContext } from '../IDashStoreContext'

export async function createTicketComment(
  context: IDashStoreContext,
  ticketId: string,
  request: ICommentRequest,
): Promise<IComment> {
  return context.requestAnd({
    request: async () => {
      const response = await context.api.logCollector.createComments(ticketId, {
        CommentId: request.commentId,
        Content: request.content,
      })

      return response.comment
    },
    onSuccess: (state, data) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const snapshot = ticket.comments

      if (!snapshot) {
        ticket.comments = PromiseSnapshots.done([data])
        return
      }

      switch (snapshot.status) {
        case 'error':
          ticket.comments = PromiseSnapshots.done([data])
          break
        case 'waiting':
          // TODO: In this case, it's really difficult to determine what to do.
          break
        case 'done':
          ticket.comments = PromiseSnapshots.done([...snapshot.data, data])
          break
      }
    },
  })
}
