import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    width: '100%',
    backgroundColor: tokens.colorNeutralBackground3,
    ...shorthands.padding('4px', '16px', '8px', '16px'),
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStencil1Alpha),
  },
})
