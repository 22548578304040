import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    alignItems: 'center',
  },
  title: {
    fontSize: '14px',
    fontWeight: 400,
    color: tokens.colorNeutralForeground1,
  },
  addNew: {
    color: tokens.colorBrandForeground1,
  },
  customUserPhoto: {
    width: '24px',
    height: '24px',
    marginRight: '8px',
    fontSize: tokens.fontSizeBase200,
    ...shorthands.borderRadius('50%'),
  },
  customUserName: {
    fontSize: tokens.fontSizeBase300,
    maxWidth: '130px',
  },
  edit: {
    width: '20px',
    height: '20px',
    cursor: 'pointer',
  },
  singleUser: {
    marginRight: '8px',
  },
  tooltipContent: {
    display: 'flex',
    alignItems: 'center',
    height: '52px',
    width: '260px',
    ...shorthands.padding('8px'),
  },
  userInfo: {
    marginLeft: '4px',
  },
})
