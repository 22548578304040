import { DefaultTimezones } from '@copilot-dash/domain'
import { dayjs } from '@copilot-dash/core'
import { sortBy } from 'lodash'

export function GetTimeZoneList() {
  const timezoneWithOffsetArr: { id: string; display: string; offset: number }[] = []
  DefaultTimezones.forEach((timezone: string) => {
    const offset = dayjs().tz(timezone).utcOffset() / 60
    const absoluteValue = Math.abs(offset)
    const offsetHour = String(Math.trunc(absoluteValue)).padStart(2, '0')
    const offsetMinutes = String((absoluteValue % 1) * 60).padStart(2, '0')
    if (offset < 0) {
      timezoneWithOffsetArr.push({
        id: timezone,
        display: `(UTC-${offsetHour}:${offsetMinutes}) ${timezone}`,
        offset: offset,
      })
    } else {
      timezoneWithOffsetArr.push({
        id: timezone,
        display: `(UTC+${offsetHour}:${offsetMinutes}) ${timezone}`,
        offset: offset,
      })
    }
  })
  return sortBy(timezoneWithOffsetArr, [(item) => (item.id === 'UTC' ? -Infinity : item.offset), 'offset'])
}
