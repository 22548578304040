import { SymptomReportItem } from '@copilot-dash/domain'
import { TableCell } from '@fluentui/react-components'

interface IProps {
  readonly item: Extract<SymptomReportItem, { valueType: 'String' }>
}

export function ItemCellValueForString({ item }: IProps) {
  const displayValue = item.value

  return <TableCell title={item.value}>{displayValue}</TableCell>
}
