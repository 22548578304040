import { PromiseSnapshots } from '@copilot-dash/core'
import { IDashStoreContext } from '../IDashStoreContext'

export async function updateTicketCustomTag(
  context: IDashStoreContext,
  ticketId: string,
  customTags: string[],
): Promise<void> {
  context.use.setState((state) => {
    const item = (state.tickets[ticketId] ??= {})
    item.customTags = PromiseSnapshots.done(customTags)
  })
}
