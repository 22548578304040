import { DashErrorData } from './DashErrorData'
import { DashErrorType } from './DashErrorType'

export class DashError {
  readonly type: DashErrorType
  readonly data: DashErrorData

  constructor(type: DashErrorType, data: DashErrorData) {
    this.type = type
    this.data = data
  }
}
