import { ITagDefinition } from './ITagDefinition.type'
import { useSearchScreenState } from '../../store'
import { GetTagValueTextFunction } from './GetTagValueTextFunction'
import { TruncateTagFunction } from './TruncateTagFunction'

export const ResponseLinkTypeTagConfig = (): ITagDefinition => {
  const form = useSearchScreenState((state) => state.form)
  const valueTexts = GetTagValueTextFunction('Customized Tag', 'ResponseLinkType', form.responseLinkType || [])

  return {
    key: 'responseLinkType',
    tagName: 'Response link type',
    toolTipContentValue: valueTexts.join(', '),
    truncatedTagValue: TruncateTagFunction(valueTexts),
    IsShowTag: false,
    isTagClearable: true,
  }
}
