import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketChatTurn } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawConversationGroup1 } from '../actions-raw-conversation/getRawConversationGroup1'
import { convertTicketChatTurnFromConversationGroup1 } from '../converters/convertTicketChatTurnFromConversationGroup1'
import { convertTicketChatTurnFromConversationLegacy } from '../converters/convertTicketChatTurnFromConversationLegacy'
import { getRawConversation } from '../getRawConversation'

export function getTicketChatTurn(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
): PromiseSnapshot<ITicketChatTurn> {
  return context.getOrFetch<ITicketChatTurn>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.chat
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.chat = snapshot
    },
    fetch: async () => {
      try {
        return await fetchFromConversationGroup1()
      } catch (error) {
        try {
          return await fetchFromConversationLegacy()
        } catch {
          // do nothing
        }

        throw error
      }
    },
  })

  async function fetchFromConversationGroup1(): Promise<ITicketChatTurn> {
    const response = await getRawConversationGroup1(context, ticketId, turnId).promise
    return convertTicketChatTurnFromConversationGroup1(turnId, response)
  }

  async function fetchFromConversationLegacy(): Promise<ITicketChatTurn> {
    const response = await getRawConversation(context, ticketId, turnId).promise
    return convertTicketChatTurnFromConversationLegacy(turnId, response)
  }
}
