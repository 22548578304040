import { makeStyles } from '@fluentui/react-components'

export const useCommonStyles = makeStyles({
  maxHeight500: {
    maxHeight: '500px',
  },

  minWidth150: {
    minWidth: '150px',
  },
})
