import { AsyncStatus } from '@copilot-dash/core'
import { ISearchScreenStore } from '../ISearchScreenStore'
import { ISearchScreenState } from '../ISearchScreenStoreState'

export class SearchScreenTelemetryAction {
  private readonly pageStartTime: number

  private isDetectingFirstPageFirstDataLoadTime: boolean = true

  constructor(store: ISearchScreenStore) {
    this.pageStartTime = Date.now()
  }

  onStateChanged(state: ISearchScreenState, old: ISearchScreenState): void {
    const newSearchStatus = state.loadMoreSnapshot[0]?.status
    const oldSearchStatus = old.loadMoreSnapshot[0]?.status
    if (newSearchStatus && oldSearchStatus && newSearchStatus !== oldSearchStatus) {
      this.onSearchStatusChanged(newSearchStatus, oldSearchStatus)
    }
  }

  private onSearchStatusChanged(status: AsyncStatus, old: AsyncStatus): void {
    if (status === 'done') {
      this.onSearchSuccess()
    }
  }

  private onSearchSuccess(): void {
    // 1. Collect first page first data load time
    if (this.isDetectingFirstPageFirstDataLoadTime) {
      const duration = Date.now() - this.pageStartTime
      Logger.telemetry.trackMetric('SearchPageFirstPageFirstDataLoadTime', { duration })

      this.isDetectingFirstPageFirstDataLoadTime = false
    }
  }
}
