import { PromiseSnapshots } from '@copilot-dash/core'
import { IComment, IEditCommentRequest } from '@copilot-dash/domain'
import { IDashStoreContext } from '../IDashStoreContext'

export async function updateTicketComment(
  context: IDashStoreContext,
  ticketId: string,
  commentId: string,
  data: IEditCommentRequest,
): Promise<IComment> {
  return context.requestAnd({
    request: async () => {
      const response = await context.api.logCollector.editComments(ticketId, commentId, {
        Content: data.content,
      })

      return response.comment
    },
    onSuccess(state, data) {
      const ticket = (state.tickets[ticketId] ??= {})
      const snapshot = ticket.comments

      if (!snapshot) {
        ticket.comments = PromiseSnapshots.done([data])
        return
      }

      switch (snapshot.status) {
        case 'error':
          ticket.comments = PromiseSnapshots.done([data])
          break
        case 'waiting':
          // TODO: In this case, it's really difficult to determine what to do.
          break
        case 'done':
          {
            const index = snapshot.data.findIndex((item) => item.commentId === data.commentId)
            if (index === -1) {
              ticket.comments = PromiseSnapshots.done([...snapshot.data, data])
            } else {
              snapshot.data[index] = data
            }
          }
          break
      }
    },
  })
}
