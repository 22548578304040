import { get } from 'lodash'
import { TicketScreenTagList } from '../../../../screens/ticket/children/common/TicketScreenTagList'
import { IAdoTagsPropertyItemValue, IPropertyItemValue } from '../PropertyTable.interface'

export const isAdoTagsPropertyItemValue = (value: IPropertyItemValue): value is IAdoTagsPropertyItemValue => {
  return get(value, 'tags') !== undefined
}

export const TagsPropertyComponent: React.FC<IAdoTagsPropertyItemValue> = ({ tags }) => {
  return <TicketScreenTagList tags={tags} />
}
