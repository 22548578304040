import { ITopIssueExtendInfo } from '@copilot-dash/domain'
import { IDashStoreContext } from '../IDashStoreContext'
import { convertTopIssueExtendInfoFromApi } from './converters/convertTopIssueExtendInfo'

export async function getTopIssueExtendInfo(
  context: IDashStoreContext,
  batchId: string,
  issueIds: string[],
): Promise<Record<string, ITopIssueExtendInfo>> {
  const response = await context.api.logCollector.getIssueExtendInfoList({ BatchId: batchId, IssueIdList: issueIds })
  return response.feedbackInsightsList.reduce(
    (acc, item) => {
      const key = `${batchId}-${item.issueId}`
      acc[key] = convertTopIssueExtendInfoFromApi(item)
      return acc
    },
    {} as Record<string, ITopIssueExtendInfo>,
  )
}
