import { ITicketData } from '@copilot-dash/domain'
import { Text } from '@fluentui/react-components'
import { RouteLink } from '../../../../router'
import { tryParseUrl } from '../common/Utils'

interface IProps {
  readonly ticket: ITicketData
}

export function TicketScreenBarTicketADOLink({ ticket }: IProps) {
  const adoLinkRender = () => {
    const adoLink = (() => {
      const vsoLink = ticket.vsoLink
      if (!vsoLink) {
        return null
      }
      const vsoUrl = tryParseUrl(vsoLink)
      if (!vsoUrl) {
        return null
      }
      const vsoTicketId =
        vsoUrl.pathname
          .split('/')
          .filter((segment) => segment.length > 0)
          .pop() ?? vsoLink
      return { vsoTicketId, vsoLink }
    })()
    return (
      <>
        <Text weight="semibold">ADO:&nbsp;</Text>
        <RouteLink path={adoLink?.vsoLink ?? ''} openInNewTab type="link">
          {adoLink?.vsoTicketId}
        </RouteLink>
      </>
    )
  }

  return <Text>{ticket.vsoLink && ticket.vsoLink.length > 0 && adoLinkRender()}</Text>
}
