import { ITicketTurnTransactionHealthData } from '@copilot-dash/domain'
import { makeStyles, TableCell, TableRow, Text } from '@fluentui/react-components'

interface IProps {
  readonly data: ITicketTurnTransactionHealthData
}

export function RowForRetrieval3SProviderAndEntities({ data }: IProps) {
  const styles = useStyles()

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Retrieval - 3S provider & entities</Text>
        <br />
        Source: {data.source}
      </TableCell>
      <TableCell>
        <span className={styles.resultCount}>
          [EntityType, ResultCount]: {data.response.entitySets || 'NULL'}
          <br />
        </span>
        <span className={styles.resultCount}>
          [AnswerEntityType, ResultCount]: {data.response.answerEntitySets || 'NULL'}
        </span>
      </TableCell>
    </TableRow>
  )
}

// TODO: Need to be removed and replaced with the global styles
const useStyles = makeStyles({
  loading: {
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  errorColor: {
    color: 'red',
  },

  warningColor: {
    color: 'orange',
  },

  okColor: {
    color: 'green',
  },
  resultCount: {
    lineHeight: 2,
  },
})
