import { InputOnChangeData, mergeClasses } from '@fluentui/react-components'
import { SearchTextPrefixType } from '@copilot-dash/domain'
import { Row } from '../Layout'
import { SearchBox } from '@fluentui/react-search-preview'
import * as React from 'react'
import { useStyles } from './SearchTextFilter.styles'
import { PrefixFilter } from './PrefixFilter'

interface IProps {
  searchText?: string
  searchTextPrefix?: SearchTextPrefixType
  onSubmitSearchTextAndPrefix: (value: SearchTextPrefixType, searchText?: string) => void
  disableSearchTextFilter?: boolean
  className?: string
  placeholder?: string
}

export const SearchTextFilter: React.FC<IProps> = ({
  searchText,
  onSubmitSearchTextAndPrefix,
  disableSearchTextFilter,
  className,
  placeholder,
  searchTextPrefix,
}) => {
  const styles = useStyles()
  const [inputText, setInputText] = React.useState<string>('')
  const [prefix, setPrefix] = React.useState<SearchTextPrefixType>(searchTextPrefix || SearchTextPrefixType.All)

  const searchBoxRef = React.useRef<HTMLDivElement>(null)

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLSpanElement>,
    data: InputOnChangeData,
  ) => {
    if (inputText && !data.value) {
      onSubmitSearchTextAndPrefix(prefix || SearchTextPrefixType.All, data.value)
    }
    setInputText(data.value)
  }

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      onSubmitSearchTextAndPrefix(prefix || SearchTextPrefixType.All, inputText)
      Logger.telemetry.trackEvent('TicketFilter/Changed', { filter: 'searchText', value: inputText })
    }
  }

  const onBlur = (evt: React.FocusEvent<HTMLDivElement>) => {
    if (evt.currentTarget.contains(evt.relatedTarget) || searchBoxRef.current?.contains(evt.relatedTarget)) {
      return
    }
    onSubmit(inputText)
  }

  const onSubmit = (text: string) => {
    if (text) {
      onSubmitSearchTextAndPrefix(prefix, text)

      Logger.telemetry.trackEvent('TicketFilter/Changed', { filter: 'searchText', value: text })
    }
  }

  React.useEffect(() => {
    if (searchText) {
      setInputText(searchText)
    } else {
      setInputText('')
    }
  }, [searchText])

  React.useEffect(() => {
    setPrefix(searchTextPrefix || SearchTextPrefixType.All)
  }, [searchTextPrefix])

  const onInput = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value)
  }, [])

  return (
    <Row>
      <PrefixFilter
        searchTextPrefix={prefix}
        onChange={(value) => {
          if (inputText) {
            onSubmitSearchTextAndPrefix(value, inputText)
          }
          setPrefix(value)
        }}
        disabled={disableSearchTextFilter}
      />
      <SearchBox
        autoComplete="none"
        aria-autocomplete="none"
        placeholder={placeholder ?? placeholderMap[prefix]}
        value={inputText}
        onKeyDownCapture={handleKeyDown}
        onChange={onChange}
        className={mergeClasses(styles.container, className)}
        disabled={disableSearchTextFilter}
        onInput={onInput}
        onBlur={onBlur}
        type="search"
      />
    </Row>
  )
}

const placeholderMap: { [key in SearchTextPrefixType]: string } = {
  [SearchTextPrefixType.All]: 'Search utterances, responses, or verbatim',
  [SearchTextPrefixType.Utterance]: 'Search utterances',
  [SearchTextPrefixType.Response]: 'Search responses',
  [SearchTextPrefixType.Verbatim]: 'Search verbatim',
}
