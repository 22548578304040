import { z } from 'zod'
import { parseBase64ToStandardJson } from './parseBase64ToStandardJson'

export function parseJwt(token: string) {
  const parts = token.split('.')
  if (parts.length !== 3) {
    throw new Error('Invalid token')
  }

  return {
    header: z.record(z.unknown()).parse(parseBase64ToStandardJson(parts[0]!)),
    payload: z.record(z.unknown()).parse(parseBase64ToStandardJson(parts[1]!)),
    signature: parts[2],
  }
}
