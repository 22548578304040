import { AsyncLoader } from '@copilot-dash/core'
import {
  AreaPath,
  AreaPathListWithRootCauseList,
  IRootCauseItem,
  IUpdateRootCause,
  IUpdateTicketStatusInfoData,
  IUpdateTicketStatusResponse,
} from '@copilot-dash/domain'
import { WritableDraft } from 'immer'
import { IGlobalStoreActions } from './IGlobalStoreActions'
import { IGlobalStoreState } from './IGlobalStoreState'

export function createGlobalStoreActions(
  set: (callback: (state: WritableDraft<IGlobalStoreState>) => void) => void,
  get: () => IGlobalStoreState,
): IGlobalStoreActions {
  const asyncLoader = new AsyncLoader<AreaPathListWithRootCauseList>()
  const fetchAreaListAndRootCauseList = async (): Promise<void> => {
    const promise = application.store.actions.getTeamAreasAndRootCauses()
    asyncLoader.submit({
      promise,
      onChanged: (snapshot) => {
        set((state) => {
          state.areaListAndRootCauseList = snapshot
        })
      },
    })
  }

  const getAreaPathList = (): AreaPath[] => {
    return (get().areaListAndRootCauseList.data ?? []).map((areaPathAndRootCause) => areaPathAndRootCause.areaPath)
  }

  const getRootCauseListByAreaPath = (areaPath: AreaPath) => {
    const areaPathAndRootCause = (get().areaListAndRootCauseList.data ?? []).find(
      (areaPathAndRootCause) => areaPathAndRootCause.areaPath === areaPath,
    )
    return areaPathAndRootCause?.rootCauseList || []
  }

  const postTicketDetails = async (
    ticketDetails: IUpdateTicketStatusInfoData,
  ): Promise<IUpdateTicketStatusResponse> => {
    return application.store.actions.updateTicketStatusInfo(ticketDetails)
  }

  const addRootCauseListByAreaPath = (areaPath: AreaPath, rootCause: IRootCauseItem) => {
    const currentRootCauseList = getRootCauseListByAreaPath(areaPath)

    const existedRootCauseIndex = currentRootCauseList.findIndex((rc) => rc.issueId === rootCause.issueId)
    if (existedRootCauseIndex > -1) {
      set((state) => {
        state.areaListAndRootCauseList.data?.forEach((areaPathAndRootCause) => {
          if (areaPathAndRootCause.areaPath === areaPath) {
            areaPathAndRootCause.rootCauseList[existedRootCauseIndex] = rootCause
          }
        })
      })
    } else {
      set((state) => {
        state.areaListAndRootCauseList.data?.forEach((areaPathAndRootCause) => {
          if (areaPathAndRootCause.areaPath === areaPath) {
            areaPathAndRootCause.rootCauseList.push(rootCause)
          }
        })
      })
    }
  }

  const updateRootCause = (rootCause: IUpdateRootCause) => {
    const existedRootCauseIndex = getRootCauseListByAreaPath(rootCause.teamName ?? '').findIndex(
      (rc) => rc.issueId === rootCause.workItemId,
    )
    if (existedRootCauseIndex > -1) {
      set((state) => {
        state.areaListAndRootCauseList.data?.forEach((areaPathAndRootCause) => {
          if (areaPathAndRootCause.areaPath === rootCause.teamName) {
            areaPathAndRootCause.rootCauseList.forEach((rc) => {
              if (rc.issueId === rootCause.workItemId) {
                rc.title = rootCause.title
              }
            })
          }
        })
      })
    }
  }

  return {
    fetchAreaListAndRootCauseList,
    getAreaPathList,
    getRootCauseListByAreaPath,
    postTicketDetails,
    addRootCauseListByAreaPath,
    updateRootCause,
  }
}
