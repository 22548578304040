import { mergeClasses } from '@fluentui/react-components'
import { IAdaptiveCard } from 'adaptivecards'
import { AdaptiveCardContent } from './children/AdaptiveCardContent'
import { setupMarkdown } from './utils/setupMarkdown'

setupMarkdown()

interface IProps {
  readonly payload: IAdaptiveCard | object
  readonly className?: string
}

export function AdaptiveCard({ payload, className }: IProps): React.ReactNode {
  return <AdaptiveCardContent className={mergeClasses(className)} payload={payload} />
}
