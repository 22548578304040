import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicketSession } from '../actions-ticket/getTicketSession'

export async function getRaw3sOnlineIndex(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
  options?: {
    ignoreCheck?: boolean
  },
): Promise<number> {
  // 1. Check user consent
  const session = await getTicketSession(context, ticketId).promise
  if (!session.hasUserConsent) {
    throw TicketError.create('NoUserConsentGiven', { ticketId })
  }

  // 2. Check interaction
  const interaction = session.interactions.find((item) => item.messageId === messageId)
  if (!interaction) {
    throw TicketError.create('No3sOnline', { ticketId, ticketMessageId: messageId })
  }

  // 3. Check interaction error code
  const ignoreCheck = options?.ignoreCheck ?? false
  if (!ignoreCheck && interaction) {
    const data = interaction.diagnostic?.substrateSearch ?? []
    for (const item of data) {
      const statusCode = item.statusCode
      if (statusCode) {
        const error = TicketError.diagnostic(statusCode, ticketId)
        if (error) {
          throw error
        }
      }
    }
  }

  return interaction.index
}
