import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { IErrorComponents } from '../../ErrorConfigs/interfaces/IErrorComponents'
import { ErrorViewPopover } from '../components/ErrorViewPopover'
import { ErrorViewTooltip } from '../components/ErrorViewTooltip'
import { getErrorTooltipVisible } from './utils/getErrorTooltipVisible'

interface IProps {
  readonly components: IErrorComponents
  readonly className?: string
}

export function ErrorViewLayoutInline({ components, className }: IProps) {
  const styles = useStyles()

  const renderChild = () => {
    let levelStyle: string
    switch (components.level) {
      case 'OK':
        levelStyle = styles.ok
        break
      case 'WARNING':
        levelStyle = styles.warning
        break
      case 'ERROR':
        levelStyle = styles.error
        break
    }

    return <span className={mergeClasses(styles.root, levelStyle, className)}>{components.message}</span>
  }

  const tooltipVisible = getErrorTooltipVisible(components)
  if (!tooltipVisible) {
    return renderChild()
  }

  switch (components.level) {
    case 'ERROR':
    case 'WARNING':
      return (
        <ErrorViewPopover components={components} trigger="hover">
          {renderChild()}
        </ErrorViewPopover>
      )
    case 'OK':
      return <ErrorViewTooltip components={components}>{renderChild()}</ErrorViewTooltip>
  }
}

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ok: {
    color: tokens.colorNeutralForegroundDisabled,
  },
  warning: {
    color: tokens.colorNeutralForegroundDisabled,
  },
  error: {
    // TODO: Add a debug mode to enable this style
    color: tokens.colorNeutralForegroundDisabled,
    // color: tokens.colorStatusWarningForeground1,
    // fontWeight: tokens.fontWeightSemibold,
  },
})
