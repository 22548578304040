import { z } from 'zod'

export interface RootCauseInfoContext {
  readonly issueId: string
  readonly rootCauseTitle: string
}

export const rootCauseInfoContextSchema = z.object({
  issueId: z.string(),
  rootCauseTitle: z.string(),
})

export interface ApiCopilotTicketContext {
  readonly ticketId: string
  readonly dateTimeUtc?: string
  readonly externalTicketId: string
  readonly thumbFeedback?: string
  readonly tenantId?: string
  readonly priority?: number
  readonly areaPath?: string
  readonly workItemId?: string
  readonly vsoAccount?: string
  readonly rootCauseList?: Array<RootCauseInfoContext>
  readonly status?: '' | 'New' | 'Active' | 'Resolved' | 'Closed' | undefined
  readonly teamArea?: string
  readonly tenantName?: string
  readonly reasoning?: string
  readonly closedComment?: string
  readonly assignTo?: string
  readonly customTags?: string[]
  readonly displayTeamName?: string
  readonly teamId?: number
}

export const apiCopilotTicketContextSchema = z.object({
  ticketId: z.string(),
  dateTimeUtc: z.string().optional(),
  externalTicketId: z.string(),
  thumbFeedback: z.string().optional(),
  tenantId: z.string().optional(),
  priority: z.number().optional(),
  areaPath: z.string().optional(),
  workItemId: z.string().optional(),
  vsoAccount: z.string().optional(),
  rootCauseList: z.array(rootCauseInfoContextSchema).optional(),
  status: z
    .union([
      z.literal(''),
      z.literal('New'),
      z.literal('Active'),
      z.literal('Resolved'),
      z.literal('Closed'),
      z.undefined(),
    ])
    .optional(),
  teamArea: z.string().optional(),
  tenantName: z.string().optional(),
  reasoning: z.string().optional(),
  closedComment: z.string().optional(),
  assignTo: z.string().optional(),
  customTags: z.array(z.string()).optional(),
  displayTeamName: z.string().optional(),
  teamId: z.number().optional(),
})

export interface ApiCopilotTicketDetailContext extends ApiCopilotTicketContext {
  readonly categories?: Array<string>
  readonly clientName?: string
  readonly uiHost?: string
  readonly additionalContext?: string
  readonly scenarioName?: string
  readonly ring?: string
  readonly resolvedReason?: string
  readonly ticketSource?: string
  readonly vsoTags?: string
  readonly vsoDescription?: string
  readonly vsoLink?: string
  readonly logicalId?: string
  readonly reasonForTags?: string
  readonly hasVerbatim?: string
  readonly externalTicketIds?: string
  readonly sourceId?: string
  readonly oCVLink?: string
  readonly oCVLanguage?: string
  readonly productName?: string
  readonly e2eLatency?: number
}

export const apiCopilotTicketDetailContextSchema = apiCopilotTicketContextSchema.extend({
  categories: z.array(z.string()).optional(),
  clientName: z.string().optional(),
  uiHost: z.string().optional(),
  additionalContext: z.string().optional(),
  scenarioName: z.string().optional(),
  ring: z.string().optional(),
  resolvedReason: z.string().optional(),
  ticketSource: z.string().optional(),
  vsoTags: z.string().optional(),
  vsoDescription: z.string().optional(),
  vsoLink: z.string().optional(),
  logicalId: z.string().optional(),
  reasonForTags: z.string().optional(),
  externalTicketIds: z.string().optional(),
  sourceId: z.string().optional(),
  oCVLink: z.string().optional(),
  oCVLanguage: z.string().optional(),
  productName: z.string().optional(),
  e2eLatency: z.number().optional(),
})
