import { create } from 'zustand'
import { ProductIds, SearchFilterFieldNames, CopilotDashPath, IQuery } from '@copilot-dash/domain'

export enum HeaderTab {
  Filters = 'Filters',
  SharedQueries = 'SharedQueries',
  MyQueries = 'MyQueries',
}

interface TicketFilterState {
  selectedProductId: ProductIds
  setSelectedProductId: (productId: number) => void
  supportedTicketFilterItems: SearchFilterFieldNames[]
  setSupportedTicketFilterItems: (items: SearchFilterFieldNames[]) => void
  customizedFilters: SearchFilterFieldNames[]
  setCustomizedFilters: (items: SearchFilterFieldNames[]) => void
  selectDateRangeWithTime: boolean
  setSelectDateRangeWithTime: (value: boolean) => void
  selectedHeaderTab: HeaderTab
  setSelectedHeaderTab: (tab: HeaderTab) => void
  copilotDashPath: CopilotDashPath
  setCopilotDashPath: (path: CopilotDashPath) => void
  queryChanged: boolean
  setQueryChanged: (value: boolean) => void
  currentQuery?: IQuery
  setCurrentQuery: (query?: IQuery) => void
}

export const useTicketFilterStore = create<TicketFilterState>((set) => ({
  selectedProductId: ProductIds.M365Chat,
  setSelectedProductId: (productId) => set({ selectedProductId: productId }),
  supportedTicketFilterItems: [],
  setSupportedTicketFilterItems: (items) => set({ supportedTicketFilterItems: items }),
  customizedFilters: [],
  setCustomizedFilters: (items) => set({ customizedFilters: items }),
  selectDateRangeWithTime: false,
  setSelectDateRangeWithTime: (value) => set({ selectDateRangeWithTime: value }),
  selectedHeaderTab: HeaderTab.Filters,
  setSelectedHeaderTab: (tab) => set({ selectedHeaderTab: tab }),
  copilotDashPath: CopilotDashPath.search,
  setCopilotDashPath: (path) => set({ copilotDashPath: path }),
  queryChanged: false,
  setQueryChanged: (value) => set({ queryChanged: value }),
  currentQuery: undefined,
  setCurrentQuery: (query) => set({ currentQuery: query }),
}))
