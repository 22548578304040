import {
  ApiV2SymptomReportResponse,
  ApiV2SymptomReportTransactionItem,
  apiV2SymptomReportTransactionSchema,
} from '@copilot-dash/api'
import { SymptomReportData, SymptomReportGroup, SymptomReportItem } from '@copilot-dash/domain'
import { omit, sortBy } from 'lodash'
import { parseToBoolean } from './parseToBoolean'
import { parseToNumber } from './parseToNumber'
import { parseToNumberArray } from './parseToNumberArray'
import { parseToString } from './parseToString'
import { parseToStringArray } from './parseToStringArray'
import { parseToUnknown } from './parseToUnknown'

export function convertToSymptomReport(raw: ApiV2SymptomReportResponse): SymptomReportData {
  const items: SymptomReportItem[] = []
  const transactions: Record<string, SymptomReportItem[]> = {}

  for (const [key, value] of Object.entries(raw)) {
    const result = apiV2SymptomReportTransactionSchema.safeParse(value)
    if (result.success) {
      result.data.forEach((item) => {
        const transactionItem = convertToTransactionItem(key, item)
        const transactionItems = (transactions[item.TransactionId] ??= [])
        transactionItems.push(transactionItem)
      })
    } else {
      const item = convertToItem(key, value)
      items.push(item)
    }
  }

  const groups = Object.entries(transactions).map(([transactionId, items]): SymptomReportGroup => {
    return {
      transactionId: transactionId,
      items: sortItems(items),
    }
  })

  return {
    items,
    groups,
  }
}

function sortItems(items: SymptomReportItem[]): SymptomReportItem[] {
  return sortBy(items, (item) => {
    if (item.key === 'QueryString') {
      return 0
    }

    if (item.key === 'HttpStatusCode') {
      return 1
    }

    return 999
  })
}

function convertToTransactionItem(key: string, item: ApiV2SymptomReportTransactionItem): SymptomReportItem {
  const object = omit(item, 'TransactionId')
  const objectKeys = Object.keys(object)
  const objectKey = objectKeys[0]

  if (objectKey && objectKeys.length === 1) {
    return convertToItem(key, object[objectKey])
  } else {
    return convertToItem(key, object)
  }
}

function convertToItem(key: string, value: unknown): SymptomReportItem {
  if (value === undefined || value === null || value === '') {
    return {
      key,
      value: undefined,
      valueType: 'Empty',
    }
  }

  let item: SymptomReportItem | undefined
  item ??= parseToBoolean(key, value)
  item ??= parseToNumber(key, value)
  item ??= parseToNumberArray(key, value)
  item ??= parseToString(key, value)
  item ??= parseToStringArray(key, value)

  return item ?? parseToUnknown(key, value)
}
