import { Button } from '@fluentui/react-components'
import { TimezoneView } from '../../../../components/Timezone/TimezoneView'

export function HeaderTimezoneButton() {
  const showTimezone = application.env.ring === 'dev' || application.env.ring === 'test'
  if (!showTimezone) {
    return null
  }

  return (
    <TimezoneView>
      {(value) => {
        return <Button appearance="transparent">{value.valueLabel}</Button>
      }}
    </TimezoneView>
  )
}
