import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketSessionData } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { convertTicketSessionData } from '../converters/convertTicketSessionData'
import { getRawOdsTicket } from '../actions-raw-ods/getRawOdsTicket'
import { validateTicketExpiration } from '../validators/validateTicketExpiration'
import { getTicket } from './getTicket'

export function getTicketSession(context: IDashStoreContext, ticketId: string): PromiseSnapshot<ITicketSessionData> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.session
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.session = snapshot
    },
    fetch,
  })

  async function fetch() {
    try {
      const item = await getRawOdsTicket(context, ticketId).promise
      return convertTicketSessionData(ticketId, item)
    } catch (error) {
      await validateError(error)
      throw error
    }
  }

  async function validateError(error: unknown) {
    // 1. Validate ticket expiration
    const ticket = await getTicket(context, ticketId).promise
    validateTicketExpiration(ticketId, ticket.dateTimeUtc)
  }
}
