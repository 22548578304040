import { Label } from '@fluentui/react-components'
import { Column } from '../../../Layout'
import { CopilotDashCheckBox } from '../../../Filter/CopilotDashCheckBox'
import { memo } from 'react'
import { OptionSetHitAvalon } from '../../config/OptionSetHitAvalon'
interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (HitAvalon: string[]) => void
}
export const HitAvalonFilter: React.FC<IProps> = memo(({ selectedValues, onChangeValue }) => {
  // TODO: update filterOptions to use useFilterOptions after prod DB update
  const filterOptions = OptionSetHitAvalon

  return (
    <Column>
      <Label weight="semibold">Hit avalon:</Label>
      <CopilotDashCheckBox
        options={filterOptions}
        selectedOptions={selectedValues}
        onChange={onChangeValue}
        multiple={false}
      />
    </Column>
  )
})
HitAvalonFilter.displayName = 'HitAvalonFilter'
