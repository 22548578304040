import { IStoreGetter, IStoreSetter } from '@copilot-dash/core'
import { IActions } from './IActions'
import { IState, TicketScreenMainTab, TicketScreenOutlineTab } from './IState'

export function createActions(set: IStoreSetter<IState>, get: IStoreGetter<IState>): IActions {
  return {
    selectTurn(turnId: string): void {
      set((state) => {
        state.selectedTurnId = turnId
        state.selectedScopeId = undefined
      })
    },

    selectTurnScope(turnId: string, scopeId: string): void {
      set((state) => {
        state.selectedTurnId = turnId
        state.selectedScopeId = scopeId
      })
    },

    selectOutlineTab(tab: TicketScreenOutlineTab): void {
      set((state) => {
        state.selectedOutlineTab = tab
      })
    },

    selectMainTab(tab: TicketScreenMainTab): void {
      set((state) => {
        state.selectedMainTab = tab
      })
    },

    selectKustoTab(tab) {
      set((state) => {
        state.selectedKustoTab = tab
      })
    },

    handleDiscussionPanelButtonClick() {
      set((state) => {
        state.isOpenActivityDiscussionPanel = false
        state.isOpenDiscussionPanel = !state.isOpenDiscussionPanel
      })
    },

    handleActivityHistoryPanelButtonClick() {
      set((state) => {
        state.isOpenDiscussionPanel = false
        state.isOpenActivityDiscussionPanel = !state.isOpenActivityDiscussionPanel
      })
    },

    openDrawer(drawer) {
      set((state) => {
        state.isOpenDiscussionPanel = false
        state.isOpenActivityDiscussionPanel = false
        state.activeDrawer = drawer
      })
    },

    closeDrawer() {
      set((state) => {
        state.isOpenDiscussionPanel = false
        state.isOpenActivityDiscussionPanel = false
        state.activeDrawer = undefined
      })
    },
  }
}
