import { z } from 'zod'

const apiMcpExperimentFeedbackSchema = z.object({
  feedbackType: z.union([z.literal('Positive'), z.literal('Negative')]),
  timestamp: z.string(),
  flightValue: z.string(),
  feedbackSource: z.string(),
})

const apiMcpExperimentFeedbackByPromptSchema = z.object({
  promptName: z.string(),
  satRatio: z.number(),
  totalFeedback: z.number(),
  isChatSignificant: z.union([z.literal('True'), z.literal('False'), z.literal('')]),
  flightValue: z.string(),
})

export const apiMcpExperimentFeedbackResponseSchema = z.object({
  feedbackRecords: z.array(apiMcpExperimentFeedbackSchema),
  feedbackByPromptRecords: z.array(apiMcpExperimentFeedbackByPromptSchema),
})
