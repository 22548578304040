import { makeStyles } from '@fluentui/react-components'
import { InlineDivider } from '../../../../components/Divider/InlineDivider'
import { Column, Row, Spacer } from '../../../../components/Layout'
import { useScrollbar } from '../../../../components/Scrollbar/useScrollbar'
import { SettingsScreenTab, useSettingsScreenStore } from '../../store'
import { SettingsScreenHeader } from '../common/SettingsScreenHeader'
import { SettingsGeneralPanel } from './panels/SettingsGeneralPanel'
import { SettingsLabsPanel } from './panels/SettingsLabsPanel'
import { TicketScreenCloseButton } from './SettingsScreenCloseButton'

export function SettingsScreenMain() {
  const styles = useStyles()
  const scrollbar = useScrollbar()
  const selectedTab = useSettingsScreenStore((state) => state.selectedTab)

  return (
    <Column>
      <Column className={styles.header}>
        <Row vAlign="center">
          <SettingsScreenHeader />
          <Spacer />
          <TicketScreenCloseButton />
          <Spacer size="12px" />
        </Row>
        <InlineDivider appearance="subtle" inset />
      </Column>
      <Column ref={scrollbar} className={styles.body} vAlign="start">
        <Column>
          {selectedTab === SettingsScreenTab.General && <SettingsGeneralPanel />}
          {selectedTab === SettingsScreenTab.Labs && <SettingsLabsPanel />}
        </Column>
        <Spacer size="120px" />
      </Column>
    </Column>
  )
}

const useStyles = makeStyles({
  header: {
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  body: {
    flex: '1 1 auto',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
})
