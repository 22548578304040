import { Toaster } from '@fluentui/react-components'
import { ReactNode } from 'react'
import { toasterId } from '../../hooks/useToast'

interface IProps {
  readonly children: ReactNode
}

export function GlobalToastProvider({ children }: IProps) {
  return (
    <>
      <Toaster toasterId={toasterId} />
      {children}
    </>
  )
}
