import { makeStyles } from '@fluentui/react-components'
import { Row, Spacer } from '../../../../components/Layout'
import { useScreenPaddingStyle } from '../../../../layout'
import { TicketScreenBarLeading } from './TicketScreenBarLeading'
import { TicketScreenBarTicket } from './TicketScreenBarTicket'

export function TicketScreenBar() {
  const styles = useStyles()
  const paddingStyle = useScreenPaddingStyle()

  return (
    <Row vAlign="center" className={styles.root} style={paddingStyle}>
      <TicketScreenBarLeading />
      <Spacer />
      <TicketScreenBarTicket />
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    height: '64px',
  },
})
