import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  category: {
    height: '56px',
    width: '100%',
    display: 'flex',
    marginTop: '8px',
    marginBottom: '8px',
    justifyContent: 'left',
  },

  selected: {
    backgroundColor: tokens.colorNeutralForeground1Selected,
    color: tokens.colorNeutralStrokeOnBrand,

    '&:hover': {
      backgroundColor: tokens.colorNeutralForeground1Selected,
      color: tokens.colorNeutralStrokeOnBrand,
    },
  },
})
