import { SymptomReportItem } from '@copilot-dash/domain'
import { TableCell } from '@fluentui/react-components'
import { useMemo } from 'react'

interface IProps {
  readonly item: Extract<SymptomReportItem, { valueType: 'Boolean' }>
}

export function ItemCellValueForBoolean({ item }: IProps) {
  const displayText = useMemo(() => getDisplayText(item), [item])

  return <TableCell title={item.valueOriginal}>{displayText}</TableCell>
}

function getDisplayText({ key, value, valueOriginal }: Extract<SymptomReportItem, { valueType: 'Boolean' }>) {
  if (key === '3STriggered') {
    return value ? '3S is triggered' : '3S is not triggered'
  }

  return valueOriginal
}
