import { InternalAxiosRequestConfig } from 'axios'
import { ApiClient } from '../../client/ApiClient'
import { ApiClientEndpoint } from '../../client/ApiClientEndpoint'
import { DashApiOptions } from '../../DashApiOptions'

const BASE_URL = 'https://ocv.microsoft.com/'
const SCOPES_PROD = ['https://microsoft.onmicrosoft.com/OneCustomerVoice-Website-Auth-Prod/access_as_user']
// const SCOPES_PPE = ['https://microsoft.onmicrosoft.com/OneCustomerVoice-Website-Auth-PPE/access_as_user']
// const SCOPES_TEST = ['https://microsoft.onmicrosoft.com/OneCustomerVoice-Website-Auth-Test/access_as_user']

export class OcvApiClient extends ApiClient {
  constructor(options: DashApiOptions) {
    super({
      ...options,
      endpoint: ApiClientEndpoint.OCV,
      config: {
        baseURL: BASE_URL,
      },
    })
  }

  protected override async onRequest(config: InternalAxiosRequestConfig<unknown>): Promise<void> {
    const token = await this.context.acquireToken(SCOPES_PROD)
    config.headers.Authorization = `Bearer ${token}`
  }
}
