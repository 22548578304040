import { Text } from '@fluentui/react-components'

interface IProps {
  readonly path: string
}

export function UnknownScreenSubtitle(props: IProps) {
  return (
    <Text wrap={false} truncate>
      <span>Sorry. The page you requested </span>
      <b>{props.path}</b>
      <span> was not found on this site.</span>
    </Text>
  )
}
