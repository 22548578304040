import { NullableOption, User } from '@copilot-dash/api'
import { AsyncSnapshot, useAsyncLoader } from '@copilot-dash/core'
import { Button, Option, Persona, Spinner } from '@fluentui/react-components'
import { useState, useEffect, useCallback, useRef } from 'react'
import { Column, Spacer } from '../Layout'
import { CheckmarkRegular, MailRegular } from '@fluentui/react-icons'
import { CopilotDashPopover } from './CopilotDashPopover'
import { useDebounce } from '../../hooks/useDebounce'
import { useStyles } from './CopilotDashDropdown.styles'

interface IProps {
  readonly userId?: string
  readonly userEmail?: string
  readonly disable?: boolean
  readonly onSelect?: (userId: string, userEmail: string | undefined) => void
  readonly onDeselect?: () => void
  readonly className?: string
  readonly placeholder?: string
  showInput?: boolean
}

export function CopilotDashEmailDropdown({
  userId,
  userEmail,
  disable,
  onSelect,
  onDeselect: onDeSelect,
  placeholder = 'Select',
  showInput,
}: IProps) {
  const styles = useStyles()
  const [input, setInput] = useState<string>('')
  const [value, setValue] = useState<string>('')
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const debouncedValue = useDebounce(value.trim(), 300)
  const debouncedInput = useDebounce(input.trim(), 300)
  const [submit, snapshot] = useAsyncLoader(
    useCallback((input: string): Promise<User[]> | null => {
      if (input.trim().length === 0) {
        return null
      }

      return application.api.microsoftGraph.searchUsers(input).then((res) => res.value)
    }, []),
  )
  const submitRef = useRef(submit)
  submitRef.current = submit

  useEffect(() => {
    submitRef.current(debouncedValue)
  }, [debouncedValue])

  useEffect(() => {
    submitRef.current(debouncedInput)
  }, [debouncedInput])

  const onOptionSelect = useCallback(
    (userId: string | undefined, userPrincipalName: NullableOption<string> | undefined) => {
      if (userId && userPrincipalName) {
        onSelect?.(userId, userPrincipalName)
      } else {
        onDeSelect?.()
      }
      setInput('')
      setIsPopoverOpen(false)
    },
    [onDeSelect, onSelect],
  )

  useEffect(() => {
    if (userEmail) {
      setValue(userEmail)
    } else {
      setValue('')
    }
  }, [userEmail])

  const handleSelectCleanClick = () => {
    onDeSelect?.()
  }

  const renderContent = (snapshot: AsyncSnapshot<User[]>) => {
    switch (snapshot.status) {
      case 'none':
        return (
          <Option key="freeform" disabled>
            None
          </Option>
        )
      case 'waiting':
        return <Spinner />
      case 'done':
        if (snapshot.data.length === 0) {
          return (
            <Option key="freeform" disabled>
              No results found
            </Option>
          )
        }

        return snapshot.data.map((user) => {
          if (!user.userPrincipalName || !user.id) {
            return null
          }

          return (
            <Column key={user.id}>
              <Button
                icon={value === user.userPrincipalName ? <CheckmarkRegular /> : <Spacer width="26px" />}
                appearance="subtle"
                onClick={() => onOptionSelect(user.id, user.userPrincipalName)}
                className={styles.selectButton}
                title={user.userPrincipalName}
              >
                <Persona
                  avatar={{ color: 'colorful', 'aria-hidden': true }}
                  name={user.displayName ?? 'No display name'}
                  secondaryText={user.userPrincipalName}
                  size="small"
                />
              </Button>
            </Column>
          )
        })
      case 'error':
        return (
          <Option key="freeform" disabled>
            {`${snapshot.error}`}
          </Option>
        )
    }
  }
  const targetFinalOptions = renderContent(snapshot)
  return (
    <CopilotDashPopover
      optionContent={targetFinalOptions}
      value={value}
      placeholder={placeholder}
      selectedOptions={userId ? [userId] : []}
      handleSelectCleanClick={handleSelectCleanClick}
      inputValue={input}
      setInputValue={setInput}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      disable={disable}
      showInput={showInput}
      prefixIcon={<MailRegular fontSize={16} />}
    />
  )
}
