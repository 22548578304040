import { IDashStoreContext } from '../IDashStoreContext'

export async function uploadAttachmentToAzureBlob(
  context: IDashStoreContext,
  rawUrl: string,
  sasToken: string,
  file: File,
): Promise<void> {
  await context.api.logCollector.uploadAttachmentToAzureBlob({
    rawUrl: rawUrl,
    sasToken: sasToken,
    file: file,
  })
}
