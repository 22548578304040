import { useMemo, FC } from 'react'
import { CopilotDashTag } from '../../../../components/Tag/CopilotDashTag'
import { Body1Strong, Text, Body1 } from '@fluentui/react-components'
import { CopilotDashTooltip } from '../../../../components/CopilotDashTooltip/CopilotDashTooltip'
import { useStyles } from './SearchScreenTag.styles'
import { Column } from '../../../../components/Layout'

interface IProps {
  isTagClearable: boolean
  tagKey: string
  toolTipContentValue: string
  truncatedTagValue: string
  tagName: string
  onRemoveTag?: () => void
  onClickTagFunction?: () => void
  isSmallScreen: boolean
}

export const SearchScreenCopilotDashTag: FC<IProps> = ({
  isTagClearable,
  tagKey,
  toolTipContentValue,
  truncatedTagValue,
  tagName,
  onRemoveTag,
  onClickTagFunction,
  isSmallScreen,
}) => {
  const styles = useStyles()
  const tagContent = useMemo(
    () => (
      <CopilotDashTooltip
        relationShip="label"
        toolTipContent={
          <Column className={styles.tooltipContent}>
            <Body1Strong>{tagName}: </Body1Strong>
            <Body1>{toolTipContentValue}</Body1>
          </Column>
        }
        className={styles.tooltip}
        toolTipParentContent={
          <Text truncate wrap={false} style={isSmallScreen ? { width: '70px' } : {}} className={styles.textStyle}>
            <Body1Strong className={styles.strong1}>{tagName}: </Body1Strong>
            {truncatedTagValue}
          </Text>
        }
      />
    ),
    [
      tagName,
      toolTipContentValue,
      truncatedTagValue,
      styles.textStyle,
      isSmallScreen,
      styles.strong1,
      styles.tooltip,
      styles.tooltipContent,
    ],
  )
  return (
    <CopilotDashTag
      isTagClearable={isTagClearable}
      onClickTagFunction={onClickTagFunction}
      tagValue={tagKey}
      tagKey={tagKey}
      tagContent={tagContent}
      onRemoveTag={onRemoveTag}
    />
  )
}
