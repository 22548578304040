import { TableColumnDefinition, createTableColumn, TableColumnId } from '@fluentui/react-components'

export interface ColumnWidthState {
  columnId: TableColumnId
  width: number
  minWidth: number
  idealWidth: number
  padding: number
}

export interface ExtendedTableColumnDefinition<TItem> extends TableColumnDefinition<TItem> {
  columnName: string
  columnSize: Pick<ColumnWidthState, 'minWidth' | 'idealWidth' | 'padding'>
  isResizable?: boolean
  flex?: number
}

declare interface CreateTableColumnOptions<TItem> extends Partial<ExtendedTableColumnDefinition<TItem>> {
  columnId: TableColumnId
  columnName: string
  columnSize: Pick<ColumnWidthState, 'minWidth' | 'idealWidth' | 'padding'>
  isResizable?: boolean
}

export function createExtendedTableColumn<TItem>(
  options: CreateTableColumnOptions<TItem>,
): ExtendedTableColumnDefinition<TItem> {
  const baseColumn = createTableColumn(options)
  return {
    ...baseColumn,
    columnName: options.columnName,
    columnSize: options.columnSize,
    isResizable: options.isResizable,
    flex: options.flex,
  }
}
