export class TicketTagsHelper {
  readonly tags: string[]

  constructor(tags: string[]) {
    this.tags = tags
  }

  contains(tag: string): boolean {
    return this.tags.some((item) => this.isEquals(item, tag))
  }

  findValue(
    tag: string,
    options?: {
      exclude?: string[]
    },
  ): string | undefined {
    const exclude = options?.exclude ?? []

    for (const item of this.tags) {
      if (this.isNameEquals(item, tag)) {
        const containsInExclude = exclude.some((excludeItem) => this.isEquals(excludeItem, item))
        if (!containsInExclude) {
          const tagValue = this.getValue(item)
          if (tagValue) {
            return tagValue
          }
        }
      }
    }

    return undefined
  }

  findValues(tag: string): Array<string> {
    const values: Array<string> = []

    for (const item of this.tags) {
      if (this.isNameEquals(item, tag)) {
        const itemValue = this.getValue(item)
        if (itemValue) {
          values.push(itemValue)
        }
      }
    }

    return values
  }

  private isNameEquals(tag1: string, tag2: string) {
    return this.getName(tag1).toLowerCase() === this.getName(tag2).toLowerCase()
  }

  private isEquals(tag1: string, tag2: string) {
    const obj1 = this.parse(tag1)
    const obj2 = this.parse(tag2)

    return (
      obj1.name.toLowerCase() === obj2.name.toLowerCase() && obj1.value?.toLowerCase() === obj2.value?.toLowerCase()
    )
  }

  private getName(tag: string): string {
    return this.parse(tag).name
  }

  private getValue(tag: string): string | undefined {
    return this.parse(tag).value
  }

  private parse(tag: string): { name: string; value?: string } {
    const index = tag.indexOf(':')
    if (index === -1) {
      return { name: tag.trim(), value: undefined }
    }

    const name = tag.substring(0, index).trim()
    const value = tag.substring(index + 1).trim()

    return {
      name,
      value: value ? value : undefined,
    }
  }
}
