import { makeStyles, shorthands } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    width: '100%',
    maxWidth: '100vw',
    ...shorthands.flex(1),
  },
  header: {
    ...shorthands.padding('4px', '16px'),
  },
  body: {
    ...shorthands.padding('4px', '16px'),
  },
})
