export interface ApiV2Ticket {
  readonly id: string
  readonly createDateTime: string
  readonly version: number
  readonly verbatim?: string
  readonly tags: string[]
  readonly clientName: string
  readonly additionalContext: string
  readonly userId: string
  readonly tenantId: string
  readonly emailAddress?: string
  readonly scenarioName: string
  readonly ring?: ApiV2Ring
  readonly emotionType?: ApiV2EmotionType
  readonly ocvProperty?: ApiV2OcvProperty
  readonly productName?: string
  readonly interactionInfos: ApiV2InteractionInfo[]
  readonly statusProperty: ApiV2StatusProperty
  readonly kustoData: ApiV2KustoLogItem[]
  readonly feedbackBlobFiles: ApiV2FeedbackBlobFile[]
  readonly isEU: boolean
  readonly is3STriggered: boolean
  readonly hasUserConsent?: boolean
  readonly conversationId: string
  readonly messageId: string
  readonly utterance: string
  readonly response: string
  readonly processingSessionId: string
}

export enum ApiV2EmotionType {
  Empty = 'Empty',
  Positive = 'Positive',
  Negative = 'Negative',
}

export interface ApiV2OcvProperty {
  readonly id: string
  readonly link: string
  readonly language: string
  readonly environment: string
  readonly ocvIssuesTitles: string[]
  readonly attachmentMetadataOriginalNames: string[]
  readonly aiContextPromptLanguage: string
  readonly aiContextResponseMessageLanguage: string
  readonly featureArea: string
  readonly screenshotCount: string
  readonly ocvRawFileUrl: string
}

export interface ApiV2InteractionInfo {
  readonly index: number
  readonly conversationId: string
  readonly messageId: string
  readonly interactionTime: string
  readonly replayInfos: ApiV2ReplayInfo[]
  readonly originalServiceCallTraceIds: string[]
  readonly conversationHistoryUrl: string
  readonly clientName?: string
}

export interface ApiV2StatusProperty {
  readonly metaDataProcessStatus: ApiV2ProcessStatus
  readonly ocvProcessStatus: ApiV2ProcessStatus
  readonly kustoLogProcessStatus: Record<string, ApiV2ProcessStatus>
}

export interface ApiV2KustoLogItem {
  readonly id: string
  readonly createDateTime: string
  readonly url: string
  readonly kustoLogType: string
}

export interface ApiV2ProcessStatus {
  readonly status: ApiV2Status
  readonly retryCount: number
  readonly errorMessage?: string
  readonly collectedTime?: string
  readonly logLines: number
}

export enum ApiV2Status {
  Init = 'Init',
  Ongoing = 'Ongoing',
  Ready = 'Ready',
  Failed = 'Failed',
  Skip = 'Skip',
}

export interface ApiV2ReplayInfo {
  readonly transactionId: string
  readonly replayServiceCallTraceId: string
  readonly feedbackServiceCallTraceId: string
  readonly entityTypes: string[]
  readonly triggerFlag?: string
}

export interface ApiV2FeedbackBlobFile {
  readonly fileName?: string
  readonly fileUrl?: string
}

export enum ApiV2Ring {
  WW = 'WW',
  DONMT = 'DONMT',
  SIP = 'SIP',
  MSIT = 'MSIT',
  SDFv2 = 'SDFv2',
  Test = 'Test',
  TDF = 'TDF',
  Unknown = 'Unknown',
}
