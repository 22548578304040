/**
 * Extracts a portion of the text starting from the word before the specified keyword.
 * If the keyword is not found, returns the first 300 characters of the text (or the whole text if shorter),
 * appending "..." if the text is longer than 300 characters.
 * If the word before the keyword is longer than 15 characters, it truncates the word to the first 7 characters,
 * appends "...", and includes the last 5 characters.
 *
 * @param text - The input text from which to extract content.
 * @param keywords - The keyword to search for within the text.
 * @returns The extracted text with appropriate truncation and ellipsis.
 */

export function extractKeywordContent(text: string, keywords: string): string {
  const lowerCaseText = text.toLowerCase()
  const lowerCaseKeywords = keywords.toLowerCase()

  // Find the starting index of the keyword phrase in the text
  const startIndex = lowerCaseText.indexOf(lowerCaseKeywords)

  if (startIndex === -1) {
    // If keywords are not found, return the first 300 characters or the whole text if it's shorter, with "..." appended if longer than 300 characters
    return text.length > 300 ? text.slice(0, 300) + '...' : text
  }

  // Find the start index to include the word before the keyword phrase
  let extractStartIndex = 0
  let truncatedLastWordBefore = ''
  let lastWordBefore = ''
  if (startIndex > 0) {
    const textBeforeKeyword = text.slice(0, startIndex).trim()
    const wordsBefore = textBeforeKeyword.split(/\s+/)
    lastWordBefore = wordsBefore[wordsBefore.length - 1] || '' // Default to empty string if undefined

    // Truncate the last word before the keyword if it is longer than 15 characters
    truncatedLastWordBefore = lastWordBefore
    if (lastWordBefore.length > 15) {
      truncatedLastWordBefore = lastWordBefore.slice(0, 7) + '...'
    }

    extractStartIndex = textBeforeKeyword.lastIndexOf(lastWordBefore)
  }

  const extractEnd = Math.min(startIndex + keywords.length + 300, text.length)

  // Prepend "..." if the keyword is not at the beginning of the text
  const prependEllipsis = extractStartIndex > 0

  // Return the extracted text with the truncated last word before the keyword
  return (
    (prependEllipsis ? '... ' : '') +
    text.substring(extractStartIndex, extractEnd).replace(lastWordBefore, truncatedLastWordBefore)
  )
}
