import { WritableDraft } from 'immer'
import { ITicketSummaryPanelState } from './ITicketSummaryPanelState'

export function createTicketSummaryPanelState(
  set: (callback: (state: WritableDraft<ITicketSummaryPanelState>) => void) => void,
  get: () => ITicketSummaryPanelState,
  ticketId: string,
): ITicketSummaryPanelState {
  return {
    ticketId,
    ticketSnapshot: { status: 'none' },
    rootCauseSnapshot: { status: 'none' },
    ticketInfo: undefined,
    summaryDraft: null,
    summaryDraftSubmittable: false,
    customTagsSnapshot: { status: 'none' },
  }
}
