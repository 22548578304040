import { MenuItem, MenuItemProps, useIsOverflowItemVisible } from '@fluentui/react-components'
import { TicketScreenOverflowTab } from './TicketScreenOverflowTab'

interface IProps {
  readonly tab: TicketScreenOverflowTab
  readonly onClick: MenuItemProps['onClick']
}

export function TicketScreenOverflowTabsMenuItem({ tab, onClick }: IProps) {
  const isVisible = useIsOverflowItemVisible(tab.id)
  if (isVisible) {
    return null
  }

  return (
    <MenuItem key={tab.id} icon={tab.icon} onClick={onClick}>
      <div>{tab.name}</div>
    </MenuItem>
  )
}
