import { SymptomReportItem } from '@copilot-dash/domain'
import { makeStyles, TableCell, Text } from '@fluentui/react-components'
import { useMemo } from 'react'

interface IProps {
  readonly item: SymptomReportItem
}

export function ItemCellKey({ item }: IProps) {
  const styles = useStyles()
  const text = useMemo(() => DISPLAY_TEXT_MAP[item.key] ?? item.key, [item])

  return (
    <TableCell className={styles.root} title={item.key}>
      <Text wrap={false} truncate={true} block={true}>
        {text}
      </Text>
    </TableCell>
  )
}

const useStyles = makeStyles({
  root: {
    width: '250px',
  },
})

const DISPLAY_TEXT_MAP: Record<string, string> = {
  NextTurnSuggestion: 'Next Turn Suggestion Count',
  UtteranceLang: 'Language - Utterance',
  ResponseLang: 'Language - Response',
  WebToggle: 'Web Toggle',
  '3STriggered': '3S Triggered',
  ExperienceType: 'Experience Type',
  CitationCount: 'Citation Count',
}
