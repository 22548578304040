import { z } from 'zod'

export interface ApiQuery {
  readonly queryId: string
  readonly title: string
  readonly productId: number
  readonly teamId?: number
  readonly userId?: string
  readonly isTeamShared: boolean
  readonly createdByUserId: string
  readonly createdTime: string
  readonly lastModifiedByUserId: string
  readonly lastModifiedTime: string
  readonly copilotDashQuery: string
  readonly copilotDashPath: string
  readonly filterPayload: string
  readonly oDataQuery?: string
}

export interface ApiGetQueryResponse {
  readonly queries: Array<ApiQuery>
  readonly continuationToken?: string
  readonly hasMore?: boolean
  readonly errorMessage?: string
}

export const apiQuerySchema = z.object({
  queryId: z.string(),
  title: z.string(),
  productId: z.number(),
  teamId: z.number().optional(),
  userId: z.string().optional(),
  isTeamShared: z.boolean(),
  createdByUserId: z.string(),
  createdTime: z.string(),
  lastModifiedByUserId: z.string(),
  lastModifiedTime: z.string(),
  copilotDashQuery: z.string(),
  copilotDashPath: z.string(),
  filterPayload: z.string(),
  oDataQuery: z.string().optional(),
})

export const apiGetQueryResponseSchema = z.object({
  queries: z.array(apiQuerySchema),
  continuationToken: z.string().optional(),
  hasMore: z.boolean().optional(),
  errorMessage: z.string().optional(),
})
