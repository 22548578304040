import { get } from 'lodash'
import { RouteLink } from '../../../../router'
import { ILinkPropertyItemValue, IPropertyItemValue } from '../PropertyTable.interface'

export const isLinkPropertyItemValue = (value: IPropertyItemValue): value is ILinkPropertyItemValue => {
  return get(value, 'href') !== undefined
}

export const LinkPropertyComponent: React.FC<ILinkPropertyItemValue> = ({ text, href }) => {
  return (
    <RouteLink path={href} openInNewTab type="link">
      {text || href}
    </RouteLink>
  )
}
