import { parseProductName, DefaultProductChannelMapping } from '@copilot-dash/domain'
import { Route } from 'react-router-dom'
import { z } from 'zod'
import { NotFoundRoutePage } from '../404/NotFoundRoutePage'
import { SearchRoute } from '../product.search/SearchRoute'

export function ProductRouteForRedirecting() {
  return (
    <Route
      id={ID}
      path={PATH}
      element={<NotFoundRoutePage />}
      loader={(args) => {
        const params = PATH_SCHEMA.safeParse(args.params).data
        const product = parseProductName(params?.product)
        if (product) {
          return SearchRoute.navigator.generateRedirectResponse({
            product,
            tab: 'allFeedback',
            channel: DefaultProductChannelMapping[product],
          })
        }

        return null
      }}
    />
  )
}

const ID = 'product'
const PATH = '/:product'
const PATH_SCHEMA = z.object({ product: z.string() })
