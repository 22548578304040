import { IFeature } from '@copilot-dash/settings'
import { Switch } from '@fluentui/react-components'
import { ComponentProps } from 'react'

interface IProps {
  readonly feature: IFeature
}

export function FeatureFlightTile({ feature }: IProps) {
  const enabled = feature.use()

  const onChange: ComponentProps<typeof Switch>['onChange'] = (_, data) => {
    feature.set(data.checked ?? false)
  }

  return <Switch checked={enabled} label={feature.description} onChange={onChange} />
}
