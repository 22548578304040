import { BotRegular } from '@fluentui/react-icons'
import { Row } from '../../Layout'
import { Text, Tooltip, Avatar, makeStyles, tokens, shorthands } from '@fluentui/react-components'
import { FC } from 'react'
import { SystemType } from '@copilot-dash/domain'
interface IProps {
  readonly systemType?: SystemType
}

export const ActivityTitleAvatarForSystem: FC<IProps> = ({ systemType }) => {
  const styles = useStyles()
  const systemName =
    systemType === 'Automation'
      ? 'Automation Service'
      : systemType === 'Clustering'
        ? 'Clustering Service'
        : 'Copilot Dash Service'

  return (
    <Tooltip content={systemName} relationship={'label'}>
      <Row vAlign="center">
        <Avatar icon={<BotRegular fontSize={18} />} aria-label="Group" className={styles.bot} />
        <Text className={styles.text}>{systemName}</Text>
      </Row>
    </Tooltip>
  )
}

const useStyles = makeStyles({
  text: {
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    wordBreak: 'normal',
    maxWidth: '80%',
    marginRight: '8px',
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightSemibold,
  },

  bot: {
    width: '24px',
    height: '24px',
    ...shorthands.margin(0, '8px', 0, 0),
  },
})
