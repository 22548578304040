import { ApiUpdateRootCauseResponse } from '@copilot-dash/api'
import { IUpdateRootCause } from '@copilot-dash/domain'
import { IDashStoreContext } from '../IDashStoreContext'
import { RootCauseDataConverter } from './converters/RootCauseDataConverter'

export async function updateRootCause(
  context: IDashStoreContext,
  params: IUpdateRootCause,
): Promise<ApiUpdateRootCauseResponse> {
  const data = RootCauseDataConverter.updateDataToApi(params)
  const response = await context.api.logCollector.updateRootCause(data)
  return response
}
