import { makeStyles, mergeClasses, shorthands } from '@fluentui/react-components'
import { CSSProperties, forwardRef, ReactNode } from 'react'
import { Link, LinkProps, Path } from 'react-router-dom'

interface IProps {
  readonly type?: 'button' | 'link'
  readonly path: Path | string
  readonly reloadDocument?: boolean
  readonly replace?: boolean
  readonly children?: ReactNode
  readonly openInNewTab?: boolean
  readonly onClick?: () => void
  readonly className?: string
  readonly style?: CSSProperties
  readonly title?: string
  readonly state?: LinkProps['state']
}

export const RouteLink = forwardRef<HTMLAnchorElement, IProps>(function RouteLink(props, ref) {
  const styles = useStyles()
  const className = mergeClasses(props.type === 'link' ? styles.link : styles.button, props.className)
  const target = props.openInNewTab ? '_blank' : undefined

  const handleClick: LinkProps['onClick'] = (e) => {
    props.onClick?.()
    e.stopPropagation()
  }

  return (
    <Link
      ref={ref}
      className={className}
      style={props.style}
      reloadDocument={props.reloadDocument}
      to={props.path}
      replace={props.replace}
      target={target}
      rel="noreferrer noopener"
      onClick={handleClick}
      state={props.state}
      title={props.title}
    >
      {props.children}
    </Link>
  )
})

const useStyles = makeStyles({
  button: {
    ...shorthands.textDecoration('none'),
    color: 'inherit',
  },
  link: {
    ...shorthands.textDecoration('none'),
    '&:hover': {
      ...shorthands.textDecoration('underline'),
    },
  },
})
