import { useProductIdEndpoints } from '@copilot-dash/store'
import { useMemo } from 'react'
import { useSearchScreenState } from '../../store'
import { ITagDefinition } from './ITagDefinition.type'
import { TruncateTagFunction } from './TruncateTagFunction'
import { getProductIdByName } from '@copilot-dash/domain'

export const ClientTagConfig = (): ITagDefinition => {
  const form = useSearchScreenState((state) => state.form)
  const productEndpoints = useProductIdEndpoints({ productId: getProductIdByName(form.product) })
  const endpointUserFriendlyName = useMemo(() => {
    return productEndpoints.data
      ? productEndpoints.data
          .filter((endpoint) => form.client?.includes(endpoint.name))
          .map((endpoint) => endpoint.title)
      : []
  }, [form.client, productEndpoints.data])

  return {
    key: 'client',
    tagName: 'Endpoints',
    toolTipContentValue: endpointUserFriendlyName.join(', '),
    truncatedTagValue: TruncateTagFunction(endpointUserFriendlyName),
    IsShowTag: false,
    isTagClearable: true,
  }
}
