import { ISearchTicketArgs } from '@copilot-dash/store'
import { create } from 'zustand'
import { SearchFilterFieldNames } from '@copilot-dash/domain'

interface TicketFilterState {
  ticketFilterForm: ISearchTicketArgs
  setTicketFilterForm: (form: ISearchTicketArgs) => void
  supportedTicketFilterItems: SearchFilterFieldNames[]
  setSupportedTicketFilterItems: (items: SearchFilterFieldNames[]) => void
}

export const useTicketFilterStore = create<TicketFilterState>((set) => ({
  ticketFilterForm: {},
  setTicketFilterForm: (form) => set({ ticketFilterForm: form }),
  supportedTicketFilterItems: [],
  setSupportedTicketFilterItems: (items) => set({ supportedTicketFilterItems: items }),
}))
