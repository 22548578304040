import { PromiseSnapshot } from '@copilot-dash/core'
import { useMemo } from 'react'
import { AnyData } from '../../../../../components/AnyData/AnyData'

interface IProps {
  readonly ticketId: string
  readonly selectedTurnId: string
}

export function ConversationDataFromLegacy({ ticketId, selectedTurnId }: IProps) {
  const session = app.store.use.getTicketSession(ticketId)
  const conversation = app.store.use.getRawConversation(ticketId, selectedTurnId)

  const jsonData = useMemo(() => {
    return {
      traceId: selectedTurnId,
      conversation: getFromSnapshot(conversation),
      interaction: session.data?.interactions.find((item) => item.messageId === selectedTurnId)?.raw,
    }
  }, [conversation, selectedTurnId, session.data?.interactions])

  return <AnyData data={jsonData} />
}

function getFromSnapshot(snapshot: PromiseSnapshot<unknown>) {
  switch (snapshot.status) {
    case 'waiting':
      return '(Waiting for data...)'
    case 'done':
      return snapshot.data
    case 'error':
      return '(Empty)'
  }
}
