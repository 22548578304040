import { IRouteMapper } from '../types/IRouteMapper'
import { IRouteParams } from '../types/IRouteParams'

/**
 * An `IRouteMapper` implementation for cases where no arguments are mapped.
 */
export const VoidMapper: IRouteMapper<void> = {
  /**
   * Returns an empty `IRouteParams` object, as no arguments are mapped.
   *
   * @returns An empty `IRouteParams` object.
   */
  mapToParams(): IRouteParams {
    return {}
  },

  /**
   * Returns `undefined`, as no route parameters are expected to map back to arguments.
   *
   * @returns `undefined`, indicating no original arguments to return.
   */
  mapToArgs(): void {
    return
  },
}
