/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */

import { createContext, useContext } from 'react'
import { IStore } from './IStore'
import { IStoreContext } from './IStoreContext'

export function createStoreContext<TState, TActions>(): IStoreContext<TState, TActions> {
  const Context = createContext<IStore<TState, TActions> | null>(null)

  const useStore = () => {
    const store = useContext(Context)
    if (store) {
      return store
    }

    throw new Error('useStore must be used within a StoreProvider')
  }

  return {
    Provider: ({ store, children }) => {
      return <Context.Provider value={store}>{children}</Context.Provider>
    },
    use: (selector?: (state: TState) => unknown) => {
      const store = useStore()

      if (!selector) {
        return store.use()
      } else {
        return store.use(selector) as any
      }
    },
    useActions: () => {
      return useStore().actions
    },
  }
}
