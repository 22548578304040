import { ApiOdsDiagnosticData, ApiOdsInteractionInfos } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketDiagnosticData, ITicketSession3sOnlineData, ITicketSessionInteractionData } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawOdsTicketInteractions } from '../actions-raw-ods/getRawOdsTicketInteractions'
import { getTicketUserConsent } from './getTicketUserConsent'

export function getTicketInteraction(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<ITicketSessionInteractionData> {
  return context.getOrFetch<ITicketSessionInteractionData>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.interaction
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.interaction = snapshot
    },
    fetch: async () => {
      return fetchFromOds()
    },
  })

  async function fetchFromOds(): Promise<ITicketSessionInteractionData> {
    const interactions = await getRawOdsTicketInteractions(context, ticketId).promise
    const interaction = interactions.find((i) => i.MessageId === messageId)
    if (interaction) {
      return convert(interaction)
    }

    // Check if the interaction message id is empty
    for (const item of interactions) {
      if (!item.messageId) {
        const statusCode = item.Diagnostic?.Conversation?.StatusCode
        if (statusCode) {
          const error = TicketError.diagnostic(statusCode, ticketId)
          if (error) {
            throw error
          }
        }

        const onlineList = item.Diagnostic?.SubstrateSearch ?? []
        for (const online of onlineList) {
          const statusCode = online.StatusCode
          if (statusCode) {
            const error = TicketError.diagnostic(statusCode, ticketId)
            if (error) {
              throw error
            }
          }
        }
      }
    }

    // Check if has user consent
    const hasUserConsent = await getTicketUserConsent(context, ticketId).promise
    if (!hasUserConsent) {
      throw TicketError.create('NoUserConsentGiven', { ticketId })
    }

    // Throw error
    // TODO: It should throw a common error instead of a "conversation" error
    throw TicketError.create('NoConversationDueToMessageIdNotFound', { ticketId, ticketMessageId: messageId })
  }

  function convert(api: ApiOdsInteractionInfos): ITicketSessionInteractionData {
    return {
      messageId: api.MessageId ?? messageId,
      interactionTime: api.InteractionTimeStr,
      index: api.Index,
      diagnostic: api.Diagnostic ? getDiagnostic(api.Diagnostic) : undefined,
      impressionIds: api.ImpressionIds ?? [],
      sssOnlineDataList: get3sOnlineDataList(api),
      raw: api,
    }
  }

  function getDiagnostic(api: ApiOdsDiagnosticData): ITicketDiagnosticData {
    return {
      conversation: {
        statusCode: api.Conversation.StatusCode,
      },
      substrateSearch: (api.SubstrateSearch ?? []).map((item) => ({
        statusCode: item.StatusCode,
      })),
      offline: (api.Offline ?? []).map((item) => ({
        statusCode: item.StatusCode,
      })),
    }
  }

  function get3sOnlineDataList(api: ApiOdsInteractionInfos): ITicketSession3sOnlineData[] {
    return (api.SubstrateSearchReplayInfoList ?? [])?.map((item) => {
      return {
        transactionId: item.TransactionId ?? '',
        replayServiceCallTraceId: item.ReplayServiceCallTraceId,
      }
    })
  }
}
