import { Configuration } from '@azure/msal-browser'

export class DashAuthConstants {
  static readonly REDIRECT_WAIT_TIME_MS = 5000

  static readonly LOGIN_SCOPES = [
    'https://graph.microsoft.com/User.Read', //
    'https://graph.microsoft.com/User.ReadBasic.All',
  ]

  static readonly LOGOUT_REDIRECT_URI = '/'

  /**
   * 3S Dashboard application auth configuration
   *
   * Azure resource link:
   * https://ms.portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Overview/appId/ae24e463-6ce5-49b2-8627-e69d92467146
   */
  static readonly SSS_DASHBOARD: Configuration = {
    auth: {
      clientId: 'ae24e463-6ce5-49b2-8627-e69d92467146',
      authority: 'https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47',
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
  }
}
