import { Times, TimeType } from '@copilot-dash/core'
import { ReactElement, useMemo } from 'react'
import { TimeViewTooltip } from './TimeViewTooltip'

interface IProps {
  readonly value: TimeType
  readonly format?: string
  readonly defaultText?: string
  readonly children?: (text: string) => ReactElement
  readonly showTooltip?: boolean
}

export function TimeView({ value, format, defaultText, children, showTooltip = true }: IProps) {
  const timezone = app.settings.timezone.use()

  const timestampText = useMemo(() => {
    return Times.format(value, { timezone, format }) ?? defaultText ?? String(value)
  }, [defaultText, format, timezone, value])

  if (!showTooltip) {
    return <>{typeof children === 'function' ? children(timestampText) : <>{timestampText}</>}</>
  }
  return (
    <TimeViewTooltip value={value}>
      <span>{typeof children === 'function' ? children(timestampText) : <>{timestampText}</>}</span>
    </TimeViewTooltip>
  )
}
