import { AnyDataTab, IState } from './IState'
import { getSupportedTabs } from './utils/getSupportedTabs'

interface IParams {
  readonly data: unknown
}

export function createState({ data }: IParams): IState {
  const tabs = getSupportedTabs(data)

  return {
    data,
    tabs,
    selectedTab: tabs[0] ?? AnyDataTab.Json,
    table: {},
    json: {
      wrap: false,
    },
  }
}
