import { ApiAISearchResponse, ApiSearchItems } from '@copilot-dash/api'
import { EnableExceptionTracking } from '@copilot-dash/logger'
import { PromiseSnapshots } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'
import { IDashStoreState } from '../../IDashStoreState'
import { ISearchTicketOptions } from './SearchTicketAction.types'
import { AiSearchQueryBuilder } from './AiSearchQueryBuilder'
import { NewSearchCopilotTicketsResult } from './NewSearchTicketsAction.types'
import { AiTicketConverter } from './converters/AiTicketConverter'
import { z } from 'zod'

export class SearchTicketIdByAISearchAction {
  private readonly context: IDashStoreContext

  constructor(context: IDashStoreContext) {
    this.context = context
  }

  @EnableExceptionTracking()
  async search(options: ISearchTicketOptions): Promise<NewSearchCopilotTicketsResult> {
    const aiSearchQueryBuilder = new AiSearchQueryBuilder()
    const { searchQuery, filterQuery } = aiSearchQueryBuilder.buildQuery(options)
    const searchPrams = {
      query: searchQuery ? searchQuery : undefined,
    }
    const searchBody = {
      filter: filterQuery,
      skip: options.offset,
      size: options.count,
      //select: ['id'],
      orderBy: ['createDateTime desc'],
    }

    const response = await this.context.requestAnd({
      request: async () => {
        return await this.context.api.copilotDash.getAISearchTicket(searchPrams, searchBody)
      },
      onSuccess: (state: IDashStoreState, data: ApiAISearchResponse) => {
        data.searchItems.forEach((ticket: ApiSearchItems) => {
          const ticketId = ticket.id
          const stateTicket = state.tickets[ticketId] ?? {}

          state.tickets[ticketId] = {
            ...stateTicket,
            utterance: ticket.utterance ? PromiseSnapshots.done(ticket.utterance) : stateTicket.utterance,
            verbatim: ticket.verbatim ? PromiseSnapshots.done(ticket.verbatim) : stateTicket.verbatim,
            response: ticket.response ? PromiseSnapshots.done(ticket.response) : stateTicket.response,
            customTags: ticket.customTags ? PromiseSnapshots.done(ticket.customTags) : stateTicket.customTags,
            email:
              ticket.emailAddress && z.string().email().safeParse(ticket.emailAddress).success
                ? PromiseSnapshots.done(ticket.emailAddress)
                : stateTicket.email,
            hasUserConsent:
              ticket.hasUserConsent !== undefined
                ? PromiseSnapshots.done(ticket.hasUserConsent)
                : stateTicket.hasUserConsent,
          }
        })
      },
    })

    return AiTicketConverter.fromApi(response)
  }
}
