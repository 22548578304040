import { useStyles } from './ActivityHistoryDetails.styles'
import { useActivityHistoryPanelStore } from '../store/store'
import { ActivityComment } from './StatusChange/ActivityComment'
import { Column } from '../../Layout'
import { useScrollbar } from '../../Scrollbar/useScrollbar'
import { ActivityUpdateStatus } from './StatusChange/ActivityUpdateStatus'
import { ActivityConfirmRootCause } from './StatusChange/ActivityConfirmRootCause'
import { ActivityUpdateCustomTags } from './StatusChange/ActivityUpdateCustomTags'
export function ActivityHistoryDetails() {
  const styles = useStyles()
  const scrollbarRef = useScrollbar()
  const selectedActivity = useActivityHistoryPanelStore((state) => state.selectedActivity)

  return (
    <Column ref={scrollbarRef} className={styles.container}>
      {(selectedActivity?.activity === 'AddComment' ||
        selectedActivity?.activity === 'UpdateComment' ||
        selectedActivity?.activity === 'DeleteComment') && <ActivityComment />}
      {(selectedActivity?.activity === 'TicketStatusUpdate' ||
        selectedActivity?.activity === 'WorkItemCreated' ||
        selectedActivity?.activity === 'WorkItemAutoTriage') && <ActivityUpdateStatus />}
      {selectedActivity?.activity === 'SetTicketRootCausingActions' && <ActivityConfirmRootCause />}
      {selectedActivity?.activity === 'UpdateCustomTags' && <ActivityUpdateCustomTags />}
    </Column>
  )
}
