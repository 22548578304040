import { AsyncSnapshot } from '../async'

export interface ListenPromiseOptions<T> {
  readonly promise: Promise<T> | undefined | null
  readonly onSuccess?: (snapshot: AsyncSnapshot<T>) => void
  readonly onError?: (snapshot: AsyncSnapshot<T>) => void
  readonly onEnded?: (snapshot: AsyncSnapshot<T>) => void
  readonly onUpdated?: (snapshot: AsyncSnapshot<T>) => void
  readonly isAlive?: () => boolean
}

export function listenPromise<T>({
  promise,
  onSuccess,
  onError,
  onEnded,
  onUpdated,
  isAlive,
}: ListenPromiseOptions<T>) {
  const alive = (): boolean => {
    return isAlive ? isAlive() : true
  }

  if (!promise) {
    if (alive() && onUpdated) {
      onUpdated({ status: 'none' })
    }
    return
  }

  if (alive() && onUpdated) {
    onUpdated({ status: 'waiting' })
  }

  promise
    .then((data) => {
      if (!alive()) return
      if (onUpdated) onUpdated({ status: 'done', data })
      if (onSuccess) onSuccess({ status: 'done', data })
      if (onEnded) onEnded({ status: 'done', data })
    })
    .catch((error) => {
      if (!alive()) return
      if (onUpdated) onUpdated({ status: 'error', error })
      if (onError) onError({ status: 'error', error })
      if (onEnded) onEnded({ status: 'error', error })
    })
}
