import { DashRing, IDashEnvironment } from '@copilot-dash/core'
import { IFeature } from './common/IFeature'
import { createFeature } from './common/createFeature'

export function createFeatures(env: IDashEnvironment) {
  return {
    settingsEntry: create({
      key: 'settings_entry',
      rings: ['dev', 'test', 'sdf'],
      description: 'Show settings entry',
    }),

    ticketBasicInfo: create({
      key: 'EnableTicketBasicInfo',
      rings: ['dev'],
      description: 'Ticket details in side panel',
    }),

    mcpExperimentFeedbackBoard: create({
      key: 'EnableMcpExperimentFeedbackBoard',
      rings: ['dev'],
      description: 'MCP Experiment Feedback Board',
    }),

    aiSearch: create({
      key: 'EnableAISearch',
      rings: ['dev'],
      description: 'AI Search',
    }),

    v2Ticket: create({
      key: 'api_v2_ticket_enabled',
      rings: [],
      description: 'Enable API V2 for ticket',
    }),

    v2SymptomReport: create({
      key: 'api_v2_symptom_report_enabled',
      rings: [],
      description: 'Enable API V2 for symptom report',
    }),

    hasErrorMessagesFilter: create({
      key: 'ui_has_error_message_filter_enabled',
      rings: ['dev'],
      description: 'Has error messages filter',
    }),
  } satisfies Record<string, IFeature>

  function create(params: {
    key: string //
    rings: Exclude<DashRing, 'unknown'>[]
    description: string
  }): IFeature {
    const defValue = env.ring === 'unknown' ? false : params.rings.includes(env.ring)

    return createFeature({
      key: params.key,
      defaultValue: defValue,
      description: params.description,
    })
  }
}
