import { Edge, MarkerType, Node } from '@xyflow/react'
import { CallFlowEdge, CallFlowNode } from '../store/IState'

export function generateEdge(source: Node<CallFlowNode>, target: Node<CallFlowNode>): Edge<CallFlowEdge> {
  return {
    id: `${source.id}-${target.id}`,
    source: source.id,
    target: target.id,
    type: 'custom',
    data: {
      source: source.data,
      target: target.data,
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  }
}
