import { ITicketData } from '@copilot-dash/domain'
import { Text } from '@fluentui/react-components'
import { TimeView } from '../../../../components/Time/TimeView'

interface IProps {
  readonly ticket: ITicketData
}

export function TicketScreenBarTicketDate({ ticket }: IProps) {
  return (
    <Text>
      <Text weight="semibold">Date: </Text>
      <TimeView value={ticket.dateTimeUtc} format="MM/DD HH:mmA" />
    </Text>
  )
}
