import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  tab: {
    display: 'inline-block',
    fontWeight: tokens.fontWeightRegular,
    background: tokens.colorNeutralBackground1Selected,
    borderRadius: '20px',
    marginRight: '6px',
    padding: '6px 20px 7px 16px',
    border: 'none',
    whiteSpace: 'nowrap',
    flexShrink: 0,
  },

  activedTab: {
    background: tokens.colorPaletteMinkForeground2,
    color: tokens.colorNeutralForegroundInverted,
    borderRadius: '20px',
    marginRight: '6px',
    padding: '6px 20px 7px 16px',
    border: 'none',
    whiteSpace: 'nowrap',
    flexShrink: 0,
    ':hover': {
      backgroundColor: tokens.colorPaletteMinkForeground2,
      color: tokens.colorNeutralForegroundInverted,
    },
  },
})
