import { Bar } from 'react-chartjs-2'
import 'chart.js/auto'
import { ApiMcpExperimentFeedback } from '@copilot-dash/api'
import { useStyles } from './McpExperimentFeedbackTrend.styles'
import { getDayWindowed, getHourWindowed } from './tools/getTimeWindowedChart'

interface IProps {
  data: ApiMcpExperimentFeedback[]
  window: string
}

function convertISOToLocal(isoString: string): string {
  const date = new Date(isoString)

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

const TIME_WINDOW_DAY = '1d'
const TIME_WINDOW_HOUR = '1h'

const FeedbackBarChart: React.FC<IProps> = ({ data, window }) => {
  const styles = useStyles()

  let windowedData
  if (window === TIME_WINDOW_DAY) {
    windowedData = getDayWindowed(data)
  } else if (window === TIME_WINDOW_HOUR) {
    windowedData = getHourWindowed(data)
  } else {
    return
  }

  windowedData.pos.map((entry) => {
    entry.timestamp = convertISOToLocal(entry.timestamp)
  })
  windowedData.neg.map((entry) => {
    entry.timestamp = convertISOToLocal(entry.timestamp)
  })

  const labels = Array.from(
    new Set([...windowedData.pos.map((entry) => entry.timestamp), ...windowedData.neg.map((entry) => entry.timestamp)]),
  ).sort()

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Positive Feedback',
        data: labels.map((label) => {
          const entry = windowedData.pos.find((entry) => entry.timestamp === label)
          return entry ? entry.count : 0
        }),
        backgroundColor: '#0AB89B',
      },
      {
        label: 'Negative Feedback',
        data: labels.map((label) => {
          const entry = windowedData.neg.find((entry) => entry.timestamp === label)
          return entry ? entry.count : 0
        }),
        backgroundColor: '#F5A851',
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Count',
        },
      },
    },
  }

  return (
    <div className={styles.barChartContainer}>
      <Bar data={chartData} options={options} />
    </div>
  )
}

export default FeedbackBarChart
