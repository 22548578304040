import { AsyncLoader } from '@copilot-dash/core'
import { IRootCausesByTeam, IUpdateRootCause } from '@copilot-dash/domain'
import { StateCreator } from 'zustand'
import { ITeamViewRootCauses, ITeamViewStore } from './types'

export const createRootCauses: StateCreator<ITeamViewStore, [['zustand/immer', never]], [], ITeamViewRootCauses> = (
  set,
  get,
) => {
  const asyncLoader = new AsyncLoader<IRootCausesByTeam>()
  let preFetchTeamName = ''
  return {
    teamRootCauses: { status: 'none' },
    selectedRootCauseId: null,
    selectedVsoAccount: null,
    computed: {
      get selectedRootCause() {
        return get().rootCauses.teamRootCauses.data?.rootCauseList.find(
          (rootCause) => rootCause.issueId === get().rootCauses.selectedRootCauseId,
        )
      },
    },
    setSelectedRootCause: (rootCauseId = null, vsoAccount = null) => {
      set((state) => {
        state.rootCauses.selectedRootCauseId = rootCauseId
        state.rootCauses.selectedVsoAccount = vsoAccount
      })
    },
    fetchTeamRootCauses: async (teamName: string, forceRefresh = false) => {
      if (preFetchTeamName === teamName && !forceRefresh && get().rootCauses.teamRootCauses.status === 'done') return
      preFetchTeamName = teamName
      asyncLoader.submit({
        promise: application.store.actions.getTeamRootCauses(teamName),
        onChanged: (snapshot) => {
          set((state) => {
            state.rootCauses.teamRootCauses = snapshot
          })
        },
      })
    },

    updateTeamRootCause: async (data: IUpdateRootCause) => {
      const response = await application.store.actions.updateRootCause(data)
      if (response.errorMessage === '') {
        set((state) => {
          const rootCause = state.rootCauses.teamRootCauses.data?.rootCauseList.find(
            (rootCause) => rootCause.issueId === data.workItemId,
          )
          if (rootCause) {
            rootCause.rootCauseTitle = data.title
            rootCause.rootCauseStatus = data.rootCauseStatus
            rootCause.rootCausePriority = data.priority
            rootCause.eTA = data.originalCommittedDate
            rootCause.owner = data.owner
          }
        })
      }
      return response
    },
  }
}
