import { AsyncLoader, AsyncSnapshot } from '@copilot-dash/core'
import { ISearchTicketOptions, NewSearchCopilotTicketsResult } from '@copilot-dash/store'
import { stableStringify } from './const'

export function createTicketsSearcher() {
  const asyncLoader = new AsyncLoader<NewSearchCopilotTicketsResult>()
  const fetchingPool = new Map<string, Promise<NewSearchCopilotTicketsResult>>()
  const _lastFetchingKeyRef: { current: string } = { current: '' }
  const searchTicket = async (
    request: ISearchTicketOptions,
    onChange: (snapshot: AsyncSnapshot<NewSearchCopilotTicketsResult>, request?: ISearchTicketOptions) => void,
  ) => {
    const key = stableStringify(request)
    _lastFetchingKeyRef.current = key
    const promise = fetchingPool.get(key) || app.store.actions.searchTickets(request)
    fetchingPool.set(key, promise)

    asyncLoader.submit({
      promise,
      onChanged: (snapshot) => {
        if (key !== _lastFetchingKeyRef.current) {
          return
        }
        onChange(snapshot, request)
      },
      onFinished: () => {
        fetchingPool.delete(key)
      },
    })

    return promise
  }

  return searchTicket
}
