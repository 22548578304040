import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  messageContainer: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    width: '90%',
    position: 'relative',
    backgroundColor: tokens.colorNeutralBackground1Hover,
    cursor: 'pointer',
    ...shorthands.padding('5px', '10px'),
    ...shorthands.margin('13px', '0px', '13px', '5px'),
    ...shorthands.borderRadius('8px'),
  },
  messageBarTime: {
    color: tokens.colorNeutralStrokeAccessible,
    fontSize: '12px',
    lineHeight: '20px',
    ...shorthands.margin(0, 0, '8px', '5px'),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '40%',
  },
  editedTag: {
    color: tokens.colorNeutralStrokeAccessible,
    fontSize: '10px',
    lineHeight: '15px',
    ...shorthands.margin(0, 0, '3px', '5px'),
  },
  messageUserName: {
    color: tokens.colorNeutralForeground1,
    fontSize: '14px',
    fontWeight: tokens.fontWeightMedium,
    lineHeight: '20px',
    ...shorthands.margin(0, 0, '8px', 0),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '60%',
  },
  textareaContainer: {
    position: 'relative',
    width: '100%',
  },
  deleteButton: {
    color: tokens.colorStatusDangerBackground3,
  },
  editedContainer: { position: 'absolute', bottom: '-16px', left: '4px' },
  messageToolBar: { position: 'absolute', top: '-25px', right: '-4px' },
})
