import { IErrorLevel } from '../interfaces/IErrorLevel'
import { getErrorConfigs } from './getErrorConfigs'

export function getErrorLevel(error: unknown): IErrorLevel {
  const builders = getErrorConfigs(error)

  for (const builder of builders) {
    const level = builder.buildLevel?.()
    if (level) {
      return level
    }
  }

  return 'ERROR'
}
