import { makeStyles } from '@fluentui/react-components'
import { Column } from '../../../../components/Layout'
import { TicketScreenStore } from '../../TicketScreenStore'
import { TicketScreenMainTab } from '../../store/IState'
import { ConversationData } from './ticket-conversation/ConversationData'
import { TicketScreenKustoLogs } from './ticket-kusto/TicketScreenKustoLogs'
import { TicketScreenSymptomReport } from './ticket-symptom-report/TicketScreenSymptomReport'
import { TicketTelemetry } from './ticket-telemetry/TicketTelemetry'

export function TicketScreenMainBody() {
  const styles = useStyles()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const selectedTurnId = TicketScreenStore.use((state) => state.selectedTurnId)
  const selectedTab = TicketScreenStore.use((state) => state.selectedMainTab)

  return (
    <Column className={styles.root} vAlign="stretch" hAlign="stretch">
      {(() => {
        switch (selectedTab) {
          case undefined:
            return null
          case TicketScreenMainTab.SymptomReport:
            return <TicketScreenSymptomReport />
          case TicketScreenMainTab.ConversationData:
            return selectedTurnId && <ConversationData ticketId={ticketId} selectedTurnId={selectedTurnId} />
          case TicketScreenMainTab.Telemetry:
            return selectedTurnId && <TicketTelemetry ticketId={ticketId} messageId={selectedTurnId} />
          case TicketScreenMainTab.KustoLog:
            return <TicketScreenKustoLogs />
        }
      })()}
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    flex: 1,
  },
})
