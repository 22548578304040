import { FC, memo, useCallback, useEffect, useState } from 'react'
import { Column, Row, Spacer } from '../../../../components/Layout'
import { TopIssue } from './children/topIssue'
import { useStyles } from './FeedbackInsights.styles'
import { VIPUserDSATs } from './children/vipUserDSATs'
import { ErrorViewBoundary } from '../../../../components/Error'
import { TelemetryScope } from '@copilot-dash/logger'
import { useSearchScreenActions } from '../../store'
import { SearchRoute } from '../../../../router'
import { getProductIdByName, ITopIssuesBatchInfoList } from '@copilot-dash/domain'
import { Empty } from '../../../../components/Empty/Empty'
import { Dropdown, Text, Option } from '@fluentui/react-components'
import { CalendarRegular } from '@fluentui/react-icons'
import { TimeView } from '../../../../components/Time/TimeView'

export const FeedbackInsights: FC = memo(() => {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  const args = SearchRoute.navigator.useArgsOptional()
  const [topIssueBatchList, setTopIssueBatchList] = useState<ITopIssuesBatchInfoList>([])
  const selectedBatchId = args?.issueBatchId ?? topIssueBatchList[0]?.batchId
  const selectedBatchInfo = topIssueBatchList.find((item) => item.batchId === selectedBatchId)
  const productName = SearchRoute.navigator.useArgsOptional()?.product
  const productId = getProductIdByName(productName)
  const batchList = application.store.use((state) =>
    typeof productId === 'number' ? state.topIssue.batchList?.[productId] : null,
  )

  useEffect(() => {
    actions.onDismissTicketSummaryPanel()
  }, [actions])

  useEffect(() => {
    if (typeof productId === 'number') {
      application.store.actions.getOrFetchTeamIssueClusteringBatchInfoList(productId).then((snapshot) => {
        setTopIssueBatchList(snapshot)
        const currentArgs = SearchRoute.navigator.getArgsOptional()
        if (!currentArgs?.issueBatchId) {
          currentArgs?.product && SearchRoute.navigator.navigate({ ...currentArgs, issueBatchId: snapshot[0]?.batchId })
        }
      })
    }
  }, [productId])

  const handleBatchChange = useCallback(
    (batchId: string) => {
      args?.product && SearchRoute.navigator.navigate({ ...args, issueBatchId: batchId })
      actions.onDismissTicketSummaryPanel()
    },
    [args, actions],
  )

  if (batchList && batchList.status === 'done' && batchList.data.length === 0) {
    return <Empty title="No data" subTitle="There's no feedback insights data generated yet, but it's coming soon! " />
  }

  return (
    <ErrorViewBoundary label="FeedbackInsights">
      <TelemetryScope scope="FeedbackInsights">
        <Column className={styles.container}>
          <Row space="between">
            <Text weight="semibold" size={600}>
              Feedback Insights
            </Text>
            <Row style={{ alignItems: 'center' }}>
              <Text style={{ marginRight: '12px' }}>Time Period:</Text>
              <Dropdown
                selectedOptions={selectedBatchId ? [selectedBatchId] : []}
                onOptionSelect={(_, data) => data.optionValue && handleBatchChange(data.optionValue)}
                button={
                  selectedBatchInfo ? (
                    <Row vAlign="center">
                      <CalendarRegular fontSize={20} />
                      <Spacer width={4} />
                      <TimeView value={selectedBatchInfo.from} format="YYYY/M/D" />
                      {' - '}
                      <TimeView value={selectedBatchInfo.to} format="YYYY/M/D" />
                    </Row>
                  ) : undefined
                }
              >
                {topIssueBatchList.map((item) => (
                  <Option key={item.batchId} value={item.batchId} text={`${item.from}-${item.to}`}>
                    <Text>
                      <TimeView value={item.from} format="YYYY/M/D" />
                      {' - '}
                      <TimeView value={item.to} format="YYYY/M/D" />
                    </Text>
                  </Option>
                ))}
              </Dropdown>
            </Row>
          </Row>
          <TopIssue />
          <Spacer height={16} />
          <VIPUserDSATs />
        </Column>
      </TelemetryScope>
    </ErrorViewBoundary>
  )
})

FeedbackInsights.displayName = 'FeedbackInsights'
