import { AnyDataTab } from '../IState'

export function getSupportedTabs(data: unknown): AnyDataTab[] {
  return Object.values(AnyDataTab).filter((tab) => {
    switch (tab) {
      case AnyDataTab.Table:
        return Array.isArray(data) && data.length > 0
      case AnyDataTab.Json:
        return true
    }
  })
}
