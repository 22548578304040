import { last } from 'lodash'
import { useMemo } from 'react'
import { useMatches } from 'react-router-dom'
import { IRouteContext } from '../../types/IRouteContext'
import { useCurrentRouteParams } from './utils/useCurrentRouteParams'

export function useArgsOptional<TArgs>(context: IRouteContext<TArgs>): TArgs | null {
  const match = last(useMatches())
  const currentRouteParams = useCurrentRouteParams()

  return useMemo(() => {
    if (!match || match.id !== context.id) {
      return null
    }

    return context.mapper.mapToArgs(currentRouteParams)
  }, [context, currentRouteParams, match])
}
