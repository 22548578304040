import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketChatIndex, ITicketChatTurnIndex, ITicketSessionInteractionData } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { isEqual, sortBy, uniqWith } from 'lodash'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicketSession } from '../actions-ticket/getTicketSession'

export function getTicketChatIndex(context: IDashStoreContext, ticketId: string): PromiseSnapshot<ITicketChatIndex> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.chatIndex
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      ticket.chatIndex = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<ITicketChatIndex> {
    // 1. User consent
    const session = await getTicketSession(context, ticketId).promise
    if (!session.hasUserConsent) {
      throw TicketError.create('NoUserConsentGiven', { ticketId })
    }

    // 2. Throw error if no conversations
    const interactions = session.interactions
    const firstInteraction = interactions[0]
    if (!firstInteraction) {
      throw TicketError.create('NoConversations', { ticketId })
    }

    return convert(interactions)
  }

  function convert(interactions: ITicketSessionInteractionData[]): ITicketChatIndex {
    const turns: ITicketChatTurnIndex[] = interactions.map((item) => ({
      id: item.messageId,
      index: item.index,
    }))

    return {
      // ODS metadata has a bug where it contains duplicate interaction info
      turns: sortBy(uniqWith(turns, isEqual), (item) => -item.index),
    }
  }
}
