import { makeStyles } from '@fluentui/react-components'
import { useMemo } from 'react'

interface IProps {
  readonly response: unknown
}

export function ApiResponseTableAxiosResponse({ response = '' }: IProps) {
  const styles = useStyles()

  const text: string = useMemo(() => {
    if (typeof response === 'string') {
      return response
    }

    try {
      return JSON.stringify(response, null, 2)
    } catch (error) {
      return String(response)
    }
  }, [response])

  return <span className={styles.root}>{text}</span>
}

const useStyles = makeStyles({
  root: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    overflow: 'hidden',
  },
})
