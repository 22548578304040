import { FC, memo, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Column, Row, Spacer } from '../Layout'
import { TitleInput } from './fields/TitleInput'
import { Priority, ROOT_CAUSE_PRIORITY_OPTIONS } from './fields/Priority'
import { debounce } from 'lodash'
import { State } from './fields/State'
import { DescriptionTextarea } from './fields/DescriptionTextarea'
import { AssignTo } from '../TicketActionForm/fields/AssignTo'
import { DueDate } from './fields/DueDate'

export enum FieldNames {
  TITLE = 'title',
  DESCRIPTION = 'description',
  PRIORITY = 'priority',
  STATE = 'state',
  DUEDATE = 'due date',
  ASSIGNEDTO = 'assigned to',
}

export interface IFormData {
  [FieldNames.TITLE]: string
  [FieldNames.DESCRIPTION]?: string
  [FieldNames.STATE]: string
  [FieldNames.PRIORITY]: (typeof ROOT_CAUSE_PRIORITY_OPTIONS)[number]
  [FieldNames.DUEDATE]: string
  [FieldNames.ASSIGNEDTO]: string
}

interface IRootCauseCreateFormProps {
  afterValidate: (isValid: boolean, data: IFormData) => void
  defaultValue?: Partial<IFormData>
  fields?: FieldNames[]
}

const DEFAULT_VALUE: IFormData = {
  [FieldNames.TITLE]: '',
  [FieldNames.STATE]: '',
  [FieldNames.PRIORITY]: ROOT_CAUSE_PRIORITY_OPTIONS[0],
  [FieldNames.DUEDATE]: '',
  [FieldNames.ASSIGNEDTO]: '',
}

export const RootCauseCreateForm: FC<IRootCauseCreateFormProps> = memo(
  ({ afterValidate, defaultValue = DEFAULT_VALUE, fields = [FieldNames.PRIORITY, FieldNames.TITLE] }) => {
    const { control, trigger, watch } = useForm({
      mode: 'onChange',
      defaultValues: defaultValue,
    })

    const debouncedValidate = useMemo(() => {
      return debounce(async (value: IFormData) => {
        const isValid = await trigger()
        afterValidate(isValid, value)
      }, 200)
    }, [afterValidate, trigger])

    useEffect(() => {
      const subscription = watch((value, { type }) => {
        if (type === 'change') {
          debouncedValidate(value as IFormData)
        }
      })
      return () => subscription.unsubscribe()
    }, [debouncedValidate, watch])

    return (
      <Column>
        {fields.includes(FieldNames.TITLE) && (
          <Controller
            name={FieldNames.TITLE}
            control={control}
            rules={{ required: 'Required' }}
            render={({ field, fieldState }) => {
              return (
                <TitleInput
                  value={field.value}
                  onChange={field.onChange}
                  fieldProps={{
                    validationMessage: fieldState.error?.message,
                  }}
                />
              )
            }}
          />
        )}

        {fields.includes(FieldNames.DESCRIPTION) && (
          <>
            <Spacer height={26} />
            <Controller
              name={FieldNames.DESCRIPTION}
              control={control}
              render={({ field }) => {
                return <DescriptionTextarea value={field.value} onChange={field.onChange} />
              }}
            />
          </>
        )}
        <Spacer height={26} />

        <Row vAlign="start">
          {fields.includes(FieldNames.PRIORITY) && (
            <>
              <Controller
                name={FieldNames.PRIORITY}
                control={control}
                rules={{ required: 'Required' }}
                render={({ field, fieldState }) => {
                  return (
                    <Priority
                      value={field.value}
                      onChange={field.onChange}
                      fieldProps={{
                        validationMessage: fieldState.error?.message,
                      }}
                    />
                  )
                }}
              />
            </>
          )}
          {fields.includes(FieldNames.STATE) && (
            <>
              <Spacer />
              <Controller
                name={FieldNames.STATE}
                control={control}
                rules={{ required: 'Required' }}
                render={({ field, fieldState }) => {
                  return (
                    <State
                      value={field.value}
                      onChange={field.onChange}
                      fieldProps={{
                        validationMessage: fieldState.error?.message,
                      }}
                    />
                  )
                }}
              />
            </>
          )}
        </Row>

        {fields.includes(FieldNames.DUEDATE) && (
          <>
            <Spacer height={26} />
            <Controller
              name={FieldNames.DUEDATE}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <DueDate
                    value={field.value}
                    onChange={field.onChange}
                    orientation="horizontal"
                    style={{ gridTemplateColumns: '1fr' }}
                    fieldProps={{
                      validationMessage: fieldState.error?.message,
                    }}
                  />
                )
              }}
            />
          </>
        )}

        {fields.includes(FieldNames.ASSIGNEDTO) && (
          <>
            <Spacer height={26} />
            <Controller
              name={FieldNames.ASSIGNEDTO}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <AssignTo
                    value={field.value}
                    onChange={field.onChange}
                    orientation="horizontal"
                    style={{ gridTemplateColumns: '1fr' }}
                    defaultValueTexts={field.value}
                    fieldProps={{
                      validationMessage: fieldState.error?.message,
                    }}
                  />
                )
              }}
            />
          </>
        )}
        <Spacer height={60} />
      </Column>
    )
  },
)

RootCauseCreateForm.displayName = 'RootCauseCreateForm'
