import { PromiseSnapshot } from '@copilot-dash/core'
import { IActivityHistory, FieldDiff } from '@copilot-dash/domain'
import { IDashStoreContext } from '../IDashStoreContext'
import { ActivityHistoryConverter } from './converters/ActivityHistoryConverter'
import { isEmpty } from 'lodash'

export function getTicketActivityHistory(
  context: IDashStoreContext,
  ticketId: string,
  forceRequest?: boolean,
): PromiseSnapshot<IActivityHistory[]> {
  return context.getOrFetch({
    get: (state) => {
      return forceRequest ? undefined : state.tickets[ticketId]?.activityHistory
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      ticket.activityHistory = snapshot
    },
    fetch: async () => {
      const response = await context.api.logCollector.getActivityHistory(ticketId)
      return response.activityHistories
        .map(ActivityHistoryConverter.getActivityHistories)
        .filter(shouldIncludeActivity)
        .sort((a, b) => b.revision - a.revision)
    },
  })
}

function shouldIncludeActivity(activity: IActivityHistory): boolean {
  const fieldDiffs = activity.fieldDiffs ?? {}
  const filteredFieldDiffs = Object.keys(fieldDiffs)
    .filter((key) => {
      const diff = fieldDiffs[key]
      return key !== 'closedComment' && key !== 'reasoning' && key !== 'teamId' && (diff?.newValue || diff?.oldValue)
    })
    .reduce(
      (obj: Record<string, FieldDiff | undefined>, key) => {
        obj[key] = fieldDiffs[key]
        return obj
      },
      {} as Record<string, FieldDiff | undefined>,
    )

  return (
    activity.activity === 'FeedbackCreated' ||
    activity.activity === 'FeedbackCooked' ||
    (activity.activity !== 'Unset' && !isEmpty(filteredFieldDiffs))
  )
}
