import { Field, Textarea } from '@fluentui/react-components'
import { FC, memo } from 'react'

interface IDescriptionTextareaProps {
  value: string | undefined
  onChange: (value: string) => void
  disabled?: boolean
}

export const DescriptionTextarea: FC<IDescriptionTextareaProps> = memo(({ value, onChange, disabled }) => {
  return (
    <Field label="Description:" orientation="vertical">
      <Textarea
        value={value ?? ''}
        disabled={disabled}
        onChange={(_, data) => onChange(data.value)}
        placeholder="Enter description"
      />
    </Field>
  )
})

DescriptionTextarea.displayName = 'DescriptionTextarea'
