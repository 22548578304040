import { Field, Text } from '@fluentui/react-components'
import { FC, memo } from 'react'

interface IProps {
  value?: string
}

export const Query: FC<IProps> = memo(({ value }) => {
  return (
    <Field onClick={(e) => e.stopPropagation()}>
      <Text size={400} weight="semibold">
        Query details:
      </Text>
      {value}
    </Field>
  )
})

Query.displayName = 'Query'
