import DatePicker, { DateObject, DatePickerProps, DatePickerRef, Value } from 'react-multi-date-picker'
import { type ComponentProps, useMemo, useCallback, memo, useRef, forwardRef, MutableRefObject } from 'react'
import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import { getOffsetMicrosecondsBetweenLocalAndTargetTimezone, isDateArray, toMicroseconds } from './utils'

type IDatePickerWrappedProps = {
  timezone?: string
  value?: Value
  onChange?: DatePickerProps['onChange']
  isOpen?: boolean
} & Pick<
  ComponentProps<typeof DatePicker>,
  | 'className'
  | 'value'
  | 'minDate'
  | 'maxDate'
  | 'format'
  | 'range'
  | 'arrow'
  | 'showOtherDays'
  | 'disableYearPicker'
  | 'numberOfMonths'
  | 'onChange'
  | 'render'
  | 'onClose'
  | 'plugins'
  | 'dateSeparator'
  | 'monthYearSeparator'
  | 'onOpen'
>

export const DatePickerWrapped = memo(
  forwardRef<DatePickerRef, IDatePickerWrappedProps>(
    ({ timezone, value, onChange, className, minDate, maxDate, ...props }, ref) => {
      const styles = useStyles()
      const timezoneOffset = useMemo(
        () => (timezone ? getOffsetMicrosecondsBetweenLocalAndTargetTimezone(timezone) : 0),
        [timezone],
      )
      const valueLenRef = useRef(0)
      valueLenRef.current = Array.isArray(value) ? value.length : value ? 1 : 0

      const handleChange = useCallback(
        (
          selectedDates: DateObject | DateObject[] | null,
          options: {
            validatedValue: string | Array<string>
            input: HTMLElement
            isTyping: boolean
          },
        ) => {
          let ret: DateObject | DateObject[] | null = null
          if (Array.isArray(selectedDates)) {
            ret = selectedDates.map((date) => new DateObject(date))
            // if value is an array and the length is 1, it means the user is selecting the second date. So, we should set the time of the second date to the end of the day, and set the time of the first date to the start of the day.
            if (valueLenRef.current === 1 && ret.length === 2) {
              const firstDate = ret[0]
              firstDate?.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              const lastDate = ret[1]
              lastDate?.set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
            }
            // if selectedDates is an array and the length is 1, it means the user is selecting the first date. So, we should set the time of the first date to the start of the day.
            if (ret.length === 1) {
              const firstDate = ret[0]
              firstDate?.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            }
            ret.map((date) => date.add(timezoneOffset, 'milliseconds'))
          } else if (selectedDates) {
            ret = new DateObject(selectedDates)
            ret.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            ret.add(timezoneOffset, 'milliseconds')
          }
          onChange?.(ret, options)
        },
        [onChange, timezoneOffset],
      )

      const valueWithOffset = useMemo(() => {
        if (isDateArray(value)) {
          return [...value].map((date) => toMicroseconds(date) - timezoneOffset)
        } else if (value) {
          return toMicroseconds(value) - timezoneOffset
        }
        return value
      }, [value, timezoneOffset])

      const minDateWithOffset = useMemo(() => {
        if (minDate) {
          return toMicroseconds(minDate) - timezoneOffset
        }
        return minDate
      }, [minDate, timezoneOffset])

      const maxDateWithOffset = useMemo(() => {
        if (maxDate) {
          return toMicroseconds(maxDate) - timezoneOffset
        }
        return maxDate
      }, [maxDate, timezoneOffset])

      return (
        <DatePicker
          ref={ref as MutableRefObject<unknown>}
          onChange={handleChange}
          value={valueWithOffset}
          className={mergeClasses('DateRangePicker', styles.root)}
          minDate={minDateWithOffset}
          maxDate={maxDateWithOffset}
          {...props}
        />
      )
    },
  ),
)
DatePickerWrapped.displayName = 'DatePickerWrapped'

const useStyles = makeStyles({
  root: {
    color: tokens.colorNeutralForeground1,
    backgroundColor: tokens.colorNeutralBackground1,
    borderRadius: tokens.borderRadiusLarge,
    overflow: 'hidden',
    boxShadow: tokens.shadow16,

    '& .rmdp-calendar': {
      width: '270px',

      '& .rmdp-day': {
        color: tokens.colorNeutralForeground1,

        '&.rmdp-deactive': {
          color: tokens.colorNeutralForeground4,
        },

        '&.rmdp-disabled, &.rmdp-deactive.rmdp-disabled': {
          color: tokens.colorNeutralForegroundDisabled,
        },
      },

      '& .rmdp-header-values': {
        color: tokens.colorBrandForeground1,
      },

      '& .rmdp-day-picker': {
        justifyContent: 'center',

        '& .rmdp-week-day': {
          color: tokens.colorBrandForeground1,
        },

        '& .rmdp-range': {
          backgroundColor: tokens.colorBrandBackground,
          color: tokens.colorNeutralForegroundOnBrand,
        },

        '& .rmdp-today': {
          color: tokens.colorNeutralForegroundOnBrand,
        },
      },

      '& .rmdp-arrow-container.disabled .rmdp-arrow': {
        borderTopColor: tokens.colorNeutralForegroundDisabled,
        borderRightColor: tokens.colorNeutralForegroundDisabled,
        borderBottomColor: tokens.colorNeutralForegroundDisabled,
        borderLeftColor: tokens.colorNeutralForegroundDisabled,
      },

      '& .rmdp-month-picker': {
        backgroundColor: tokens.colorNeutralBackground1,
      },
    },
    '& .rmdp-panel-body li': {
      backgroundColor: tokens.colorBrandBackground,
    },
  },
  input: {
    width: '100%',
    marginLeft: '0px',
    ...shorthands.borderTop('1px', 'solid', tokens.colorBrandForeground1),
    ...shorthands.borderLeft('1px', 'solid', tokens.colorBrandForeground1),
    ...shorthands.borderRight('1px', 'solid', tokens.colorBrandForeground1),
    ...shorthands.borderBottom('2px', 'solid', tokens.colorBrandForeground1),
    '&> span': {
      color: tokens.colorBrandForeground1,
    },
    '&> input': {
      fontWeight: tokens.fontWeightSemibold,
    },
    ':hover': {
      ...shorthands.borderTop('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderLeft('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderRight('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderBottom('2px', 'solid', tokens.colorBrandForeground1),
    },
  },
})
