import { makeStyles } from '@fluentui/react-components'
import { useState } from 'react'
import { Column, Row } from '../../../components/Layout'
import { TicketDebugScreenDataView } from '../Children/TicketDebugScreenDataView'
import { TicketDebugScreenNavigator } from '../Children/TicketDebugScreenNavigator'
import { ITicketViewDefinition } from '../Store/TicketDebugState'

const TICKET_VIEW_DEFINITIONS = [
  {
    title: '[Service] Metadata',
    fetch(ticketId: string) {
      return application.api.logCollector.getCopilotTicket({ ExternalTicketId: ticketId })
    },
  },
  {
    title: '[ODS] Metadata',
    fetch(ticketId: string) {
      return application.api.ods.getMetadata(ticketId)
    },
  },
  {
    title: '[ODS] Files',
    fetch(ticketId: string) {
      return application.api.ods.getFiles(ticketId)
    },
  },
  {
    title: '[OCV] Metadata',
    fetch(ticketId: string) {
      return application.api.ocv.getItem(ticketId)
    },
  },
] satisfies ITicketViewDefinition[]

export function TicketDebugScreenLayout() {
  const classes = useStyles()

  const [selectedDefinition, setSelectedDefinition] = useState<ITicketViewDefinition>(TICKET_VIEW_DEFINITIONS[0]!)
  const handleOnSelect = (definition: ITicketViewDefinition) => {
    setSelectedDefinition(definition)
  }

  return (
    <Row hAlign="stretch" vAlign="stretch">
      {/* Tabs */}
      <Column style={{ width: 200 }} hAlign="stretch" vAlign="stretch">
        <TicketDebugScreenNavigator
          selected={selectedDefinition}
          definitions={TICKET_VIEW_DEFINITIONS}
          onSelect={handleOnSelect}
        />
      </Column>

      <Column className={classes.main} style={{ flex: 1 }} hAlign="stretch" vAlign="stretch">
        <TicketDebugScreenDataView key={selectedDefinition.title} definition={selectedDefinition} />
      </Column>
    </Row>
  )
}

const useStyles = makeStyles({
  main: {
    overflow: 'hidden',
  },
})
