import { AsyncSnapshot } from '@copilot-dash/core'
import { ITicketSummaryPanelState } from '../ITicketSummaryPanelState'
import { ITicketSummaryPanelStore } from '../ITicketSummaryPanelStore'

export class ReactiveTelemetryAction {
  private readonly store: ITicketSummaryPanelStore
  private readonly pageStartTime: number

  private isDetectingAllDataLoadTime: boolean = true
  private isSymptomReportDataLoadTime: boolean = true
  private isTicketSidePanelOpen: boolean = true

  constructor(store: ITicketSummaryPanelStore) {
    this.store = store
    this.pageStartTime = Date.now()
  }

  onStateChanged(state: ITicketSummaryPanelState, old: ITicketSummaryPanelState): void {
    if (state.ticketId !== old.ticketId) {
      this.isTicketSidePanelOpen = true
    }
    this.onSymptomReportChanged(state)
    this.onAnyChanged(state)
    this.onTicketSidePanelChanged(state)
  }

  private onTicketSidePanelChanged(status: ITicketSummaryPanelState) {
    if (
      this.isTicketSidePanelOpen &&
      this.isStatusLoaded(status.ticketSnapshot) &&
      this.isStatusLoaded(status.rootCauseSnapshot)
    ) {
      const metricData = {
        ticketId: status.ticketSnapshot.data?.ticketId,
        productEndpoint: status.ticketSnapshot.data?.clientName,
        ring: status.ticketSnapshot.data?.ring,
        hasRootCause: status.rootCauseSnapshot.data?.items && status.rootCauseSnapshot.data?.items?.length > 0,
        hasSymptomReport: true,
        thumb: status.ticketSnapshot.data?.thumbFeedback,
        avalon: status.ticketSnapshot.data?.isAvalon,
      }
      Logger.telemetry.trackEvent('OpenTicketSidePanel', metricData)
      Logger.telemetry.trackEvent('TicketSummaryPanel/Loaded', {
        ticketId: metricData.ticketId,
        productEndpoint: metricData.productEndpoint,
        ticketRing: metricData.ring,
      })
      if (metricData.hasRootCause) {
        Logger.telemetry.trackEvent('TicketSuggestionPanel/Loaded', {
          ticketId: metricData.ticketId,
          productEndpoint: metricData.productEndpoint,
          ticketRing: metricData.ring,
        })
      }
      this.isTicketSidePanelOpen = false
    }
  }

  private onSymptomReportChanged(status: ITicketSummaryPanelState) {
    if (this.isSymptomReportDataLoadTime && this.isStatusLoaded(status.ticketSnapshot)) {
      this.collectSymptomReportDataLoadTime()
      this.isSymptomReportDataLoadTime = false
    }
  }

  private onAnyChanged(state: ITicketSummaryPanelState): void {
    if (this.isDetectingAllDataLoadTime && this.isAllDataLoaded(state)) {
      this.collectAllDataLoadTime()
      this.isDetectingAllDataLoadTime = false
    }
  }

  private collectSymptomReportDataLoadTime(): void {
    const duration = Date.now() - this.pageStartTime
    const ticketSnapshot = this.store.state.ticketSnapshot.data
    const ticketId = this.store.state.ticketSnapshot.data ? this.store.state.ticketSnapshot.data.ticketId : ''
    const ticketRing = ticketSnapshot?.ring ? ticketSnapshot.ring : ''

    Logger.telemetry.trackMetric('SymptomReportLoadTime', {
      duration,
      ticketId,
      ticketRing,
    })
  }

  private collectAllDataLoadTime(): void {
    const duration = Date.now() - this.pageStartTime
    Logger.telemetry.trackMetric('TicketPageAllDataLoadTime', { duration })
  }

  private isAllDataLoaded(state: ITicketSummaryPanelState): boolean {
    return this.isStatusLoaded(state.ticketSnapshot) && this.isStatusLoaded(state.rootCauseSnapshot)
  }

  private isStatusLoaded(snapshot: AsyncSnapshot): boolean {
    return snapshot.status === 'done' || snapshot.status === 'error'
  }
}
