export const enum ProductIds {
  M365Chat = 1,
  M365ChatWebChat = 2,
  ExcelCopilot = 3,
  WordCopilot = 4,
  PPTCopilot = 5,
  TeamsMeetingCopilot = 6,
  ODSPCopilot = 7,
  TenantAdminFeedback = 8,
  LoopCopilot = 9,
  OneNoteCopilot = 10,
  OutlookCopilot = 11,
}
