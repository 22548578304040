import { Divider, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle } from '@fluentui/react-components'
import { useStyles } from './TeamAreaLayout.styles'
import { TeamFilter } from '../children/TeamFilter'
import Categories from '../children/Categories'
import { Column, Row, Spacer } from '../../../components/Layout'
import { useTeamViewStore } from '../store'
import { ToggleLeftDrawerIcon } from '../children/ToggleDrawerLeftIcon'
import { memo } from 'react'
import { motion } from 'framer-motion'
import { TeamDRI } from '../children/TeamDRI'

interface ITeamAreaLayoutProps {
  className?: string
}

export const TeamAreaLayout: React.FC<ITeamAreaLayoutProps> = memo(({ className }) => {
  const styles = useStyles()
  const drawerOpen = useTeamViewStore((state) => state.leftDrawerOpen)
  return (
    <motion.div
      layout
      animate={drawerOpen ? 'open' : 'close'}
      variants={{
        close: { width: 0, marginRight: 14 },
        open: { width: 320 },
      }}
      className={styles.motionLeftPanelContainer}
    >
      <Drawer type="inline" open={drawerOpen} className={styles.drawer}>
        <DrawerHeader>
          <DrawerHeaderTitle>
            <Row className={styles.alignItemsCenter}>
              <TeamFilter />
              <Spacer />
              <ToggleLeftDrawerIcon className={styles.noShrink} type="contract" />
            </Row>
          </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>
          <Divider className={styles.noFlexGrow} />
          <Categories />
        </DrawerBody>
        <DrawerFooter>
          <Column fill>
            <Divider style={{ marginBottom: '24px' }} />
            <TeamDRI />
          </Column>
        </DrawerFooter>
      </Drawer>
    </motion.div>
  )
})

TeamAreaLayout.displayName = 'TeamAreaLayout'
