import { TableCell, TableRow, Text } from '@fluentui/react-components'
import { ErrorView } from '../../Error'
import { SymptomReportLoadingIcon } from './SymptomReportLoadingIcon'

interface IProps {
  readonly ticketId: string
  readonly turnId: string
}

export function RowForWebSearch({ ticketId, turnId }: IProps) {
  const ticket = application.store.use.getTicket(ticketId)

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Web Search</Text>
      </TableCell>
      <TableCell>
        {(() => {
          switch (ticket.status) {
            case 'waiting':
              return <SymptomReportLoadingIcon />
            case 'error':
              return <ErrorView type="inline" error={ticket.error} />
            case 'done': {
              const webSearchTriggered = ticket.data.tag.webSearchTriggered
              if (!webSearchTriggered) {
                return 'WebSearch is not triggered'
              }

              return <span>WebSearch is triggered</span>
            }
          }
        })()}
      </TableCell>
    </TableRow>
  )
}
