import { Spinner } from '@fluentui/react-components'
import { ErrorView } from '../Error'
import { Column, Row } from '../Layout'
import { Scrollbar } from '../Scrollbar/Scrollbar'
import { useStyles } from './ActivityHistoryPanelLayout.styles'
import { ActivityHistoryBody } from './children/ActivityHistoryBody'
import { ActivityHistoryEmpty } from './children/ActivityHistoryEmpty'
import { useActivityHistoryPanelStore } from './store/store'

export function ActivityHistoryPanelLayout() {
  const styles = useStyles()
  const activityHistorySnapshot = useActivityHistoryPanelStore((state) => state.activityHistory)

  return (
    <Column className={styles.body} fill>
      <Scrollbar className={styles.scrollbar}>
        {(() => {
          switch (activityHistorySnapshot.status) {
            case 'waiting':
              return (
                <Row className={styles.waiting} fill vAlign="center" hAlign="center">
                  <Spinner label="Loading..." />
                </Row>
              )
            case 'error':
              return <ErrorView error={activityHistorySnapshot.error} />
            case 'done':
              if (activityHistorySnapshot.data.length === 0) {
                return <ActivityHistoryEmpty />
              }
              return <ActivityHistoryBody />
            default:
              return null
          }
        })()}
      </Scrollbar>
    </Column>
  )
}
