import { InternalAxiosRequestConfig } from 'axios'
import { z } from 'zod'
import { ApiClient } from '../../client/ApiClient'
import { ApiClientEndpoint } from '../../client/ApiClientEndpoint'
import { DashApiOptions } from '../../DashApiOptions'

const BASE_URL_TEST = 'https://copilotdash-test.microsoft.com'
const BASE_URL_SDF = 'https://copilotdash-sdf.microsoft.com'
const BASE_URL_MSIT = 'https://copilotdash.microsoft.com'

const SCOPES = ['d97af45c-a676-4654-a778-236750b20726/.default']

export class CopilotDashApiClient extends ApiClient {
  constructor(options: DashApiOptions) {
    super({
      ...options,
      endpoint: ApiClientEndpoint.CopilotDash,
      config: {},
    })
  }

  protected override async onRequest(config: InternalAxiosRequestConfig<unknown>): Promise<void> {
    const token = await this.context.acquireToken(SCOPES)

    config.baseURL = this.getBaseUrl()
    config.headers.Authorization = `Bearer ${token}`
  }

  private getBaseUrl(): string {
    const endpointUrl = this.context.getServerEndpointUrl()
    if (endpointUrl) {
      const url = z.string().url().safeParse(endpointUrl)
      if (url.success) {
        return endpointUrl
      }
    }

    const ring = this.context.getRing()
    switch (ring) {
      case 'dev':
      case 'test':
        return BASE_URL_TEST
      case 'sdf':
        return BASE_URL_SDF
      case 'msit':
      case 'unknown':
        return BASE_URL_MSIT
    }
  }
}
