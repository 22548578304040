import { isEqual } from 'lodash'
import { useMemo, useRef } from 'react'
import { useMatches } from 'react-router-dom'
import { IRoutePathParams } from '../../../types/IRouteParams'
import { generateParamsPathByMatches } from './generateParamsPathByMatches'

export function useCurrentRouteParamsPath(): IRoutePathParams {
  const matches = useMatches()
  const cacheRef = useRef<IRoutePathParams | null>(null)

  return useMemo(() => {
    const newValue = generateParamsPathByMatches(matches)
    const oldValue = cacheRef.current

    if (isEqual(newValue, oldValue)) {
      return cacheRef.current!
    }

    return (cacheRef.current = newValue)
  }, [matches])
}
