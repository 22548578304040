import { ITagDefinition } from './ITagDefinition.type'
import { useSearchScreenState } from '../../store'
import { TruncateTagFunction } from './TruncateTagFunction'

export const FlightsTagConfig = (): ITagDefinition => {
  const form = useSearchScreenState((state) => state.form)
  const valueTexts = form.flights ?? ''

  return {
    key: 'flights',
    tagName: 'Flights',
    toolTipContentValue: valueTexts,
    truncatedTagValue: TruncateTagFunction([valueTexts]),
    IsShowTag: false,
    isTagClearable: true,
  }
}
