import { INewTicketData, SearchTextPrefixType } from '@copilot-dash/domain'
import { ExtendedTableColumnDefinition } from '../Table/ExtendedTableColumn'
import {
  ColumnConfigDateTime,
  ColumnConfigEmailAddress,
  ColumnConfigEmotionType,
  ColumnConfigPriority,
  ColumnConfigRootCause,
  ColumnConfigStatus,
  ColumnConfigTeamArea,
  ColumnConfigTenantName,
  ColumnConfigResponse,
  ColumnConfigTicketId,
  ColumnConfigUserUtterance,
  ColumnConfigVerbatimFeedback,
  ColumnConfigRing,
  ColumnConfigEndpoint,
  ColumnConfigUserProfile,
  ColumnConfigAssignTo,
  ColumnConfigCustomTag,
} from './index'

export const AllSearchTableColumns = (
  searchTextPrefix: SearchTextPrefixType,
  keyWords?: string,
  email?: string,
  tenantIds?: string[],
): ExtendedTableColumnDefinition<INewTicketData>[] => [
  ColumnConfigTicketId,
  ColumnConfigDateTime,
  ColumnConfigUserUtterance(searchTextPrefix, keyWords),
  ColumnConfigResponse(searchTextPrefix, keyWords),
  ColumnConfigVerbatimFeedback(searchTextPrefix, keyWords),
  ColumnConfigEmotionType,
  ColumnConfigEmailAddress(email),
  ColumnConfigPriority,
  ColumnConfigRootCause,
  ColumnConfigStatus,
  ColumnConfigTeamArea,
  ColumnConfigTenantName(tenantIds),
  ColumnConfigRing,
  ColumnConfigEndpoint,
  ColumnConfigUserProfile,
  ColumnConfigAssignTo,
  ColumnConfigCustomTag(),
  //TODO: add more columns here...
]
