import { Avatar, makeStyles } from '@fluentui/react-components'
import { TicketUserTooltip } from '../../../../TicketUserTooltip/TicketUserTooltip'
import { TicketConversationHistory } from '../../../TicketConversationHistory'

export function UserMessageTileAvatar() {
  const styles = useStyles()
  const ticketId = TicketConversationHistory.use((state) => state.ticketId)

  return (
    <TicketUserTooltip ticketId={ticketId}>
      <Avatar className={styles.root} size={24} />
    </TicketUserTooltip>
  )
}

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
    marginTop: '4px',
  },
  img: {
    backgroundColor: 'transparent',
  },
})
