import { AsyncSnapshot } from '@copilot-dash/core'
import { ProgressBar } from '@fluentui/react-components'
import { ReactNode } from 'react'
import { ErrorView } from '../../../../components/Error'
import { useStyles } from './LoadingStatusComponent.styles'
import { Row } from '../../../../components/Layout'

interface LoadingStatusComponentProps<T> {
  readonly promiseSnapshot: AsyncSnapshot<T>
  readonly content: (data: T) => ReactNode
}

export function LoadingStatusComponent<T>(props: LoadingStatusComponentProps<T>) {
  const styles = useStyles()
  switch (props.promiseSnapshot.status) {
    case 'none':
      return null
    case 'waiting':
      return (
        <Row fill vAlign="center" hAlign="center" className={styles.container}>
          <ProgressBar className={styles.progressBar} />
        </Row>
      )
    case 'done':
      return props.content(props.promiseSnapshot.data)
    case 'error':
      return <ErrorView error={props.promiseSnapshot.error} className={styles.errorLayout} />
  }
}
