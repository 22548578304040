import { DefaultHasErrorMessagesfilterOptions } from '../../../../components/TicketsFilterPanel/children/filters'
import { IFilterOption } from '@copilot-dash/store'
import { ITagDefinition } from './ITagDefinition.type'
import { TruncateTagFunction } from './TruncateTagFunction'
import { useMemo } from 'react'
import { useSearchScreenState } from '../../store'

export const HasErrorMessagesTagConfig = (): ITagDefinition => {
  const form = useSearchScreenState((state) => state.form)
  const valueTexts = useMemo(() => {
    return form.hasErrorMessages?.map((item) => {
      const option = DefaultHasErrorMessagesfilterOptions.find((option: IFilterOption) => option.key === item)
      return option ? option.text : item
    })
  }, [form.hasErrorMessages])

  return {
    key: 'hasErrorMessages',
    tagName: 'Has error messages',
    toolTipContentValue: valueTexts?.join(', ') || '',
    truncatedTagValue: TruncateTagFunction(valueTexts ?? []),
    IsShowTag: false,
    isTagClearable: true,
  }
}
