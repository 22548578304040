import { useState, useMemo, useEffect, useCallback } from 'react'
import { OptionGroup, useId, InfoLabel, Text, Checkbox, makeStyles, tokens } from '@fluentui/react-components'
import type { InfoLabelProps } from '@fluentui/react-components'
import { IFilterOption } from './IFilterOption.types'
import { Column } from '../Layout'
import { CopilotDashPopover } from './CopilotDashPopover'

interface IProps {
  comboId: string
  filterType: string
  placeholder?: string
  infoLabelContent?: React.HTMLAttributes<HTMLElement> & InfoLabelProps['info']
  isGroup: boolean
  optionsList: IFilterOption[]
  defaultSelectedOption: string[] | undefined
  onChangeFilter: (item: string[]) => void
  showInput?: boolean
}

export const CopilotDashMultiSelectDropdown: React.FC<IProps> = ({
  comboId,
  filterType,
  placeholder = 'Select',
  infoLabelContent,
  isGroup,
  optionsList,
  defaultSelectedOption,
  onChangeFilter,
  showInput,
}) => {
  const styles = useStyles()
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [displayValue, setDisplayValue] = useState<string>('')
  const [inputValue, setInputValue] = useState('')
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)

  const optionsListWithAll = useMemo(() => {
    const all = optionsList.some((item) => item.key === 'all')
    if (!all) {
      return [{ key: 'all', text: 'Select All' }].concat(optionsList)
    }
    return optionsList
  }, [optionsList])

  const allItems = useMemo(() => {
    return Object.values(optionsList)
      .flat()
      .map((item: IFilterOption) => item.key)
  }, [optionsList])

  const allItemsWithSelectAll = useMemo(() => {
    const all = optionsList.some((item) => item.key === 'all')
    if (!all) {
      return [...allItems, 'all']
    }
    return allItems
  }, [optionsList, allItems])

  const groups = optionsList.reduce((acc: { [key: string]: IFilterOption[] }, item: IFilterOption) => {
    const menu = item.menu || ''
    ;(acc[menu] = acc[menu] || []).push(item)
    return acc
  }, {})

  useEffect(() => {
    setSelectedOptions(
      defaultSelectedOption
        ? defaultSelectedOption.length === optionsList.length
          ? [...defaultSelectedOption, 'all']
          : defaultSelectedOption
        : [],
    )
  }, [defaultSelectedOption, optionsList])

  useEffect(() => {
    setDisplayValue(
      selectedOptions.length === allItemsWithSelectAll.length
        ? 'All selected'
        : selectedOptions.length > 0
          ? selectedOptions
              .map((item) => {
                return optionsList.find((option) => option.key === item)?.text ?? null
              })
              .join(';')
          : '',
    )
  }, [selectedOptions, allItemsWithSelectAll.length, optionsList])

  const handleCheckboxChange = (checked: string | boolean, option: string) => {
    if (checked) {
      if (option === 'all' || [...selectedOptions, option].length === allItems.length) {
        setSelectedOptions(allItemsWithSelectAll)
        onChangeFilter(allItems)
      } else {
        setSelectedOptions([...selectedOptions, option])
        onChangeFilter([...selectedOptions, option])
      }
    } else {
      if (option === 'all') {
        setSelectedOptions([])
        onChangeFilter([])
      } else {
        setSelectedOptions(selectedOptions.filter((item) => item !== 'all' && item !== option))
        onChangeFilter(selectedOptions.filter((item) => item !== 'all' && item !== option))
      }
    }
  }
  const handleCheckBoxChecked = useCallback(
    (key: string) => {
      return selectedOptions.includes(key)
    },
    [selectedOptions],
  )

  const optionRender = (item: IFilterOption) => {
    return (
      <Column key={item.key} title={item.text}>
        <Checkbox
          checked={handleCheckBoxChecked(item.key)}
          label={item.text}
          onChange={(_, data) => handleCheckboxChange(data.checked, item.key)}
          className={styles.checkBox}
        />
      </Column>
    )
  }

  function groupFilterOptions(options: [string, IFilterOption[]][]) {
    const filteredOptions: { [key: string]: IFilterOption[] } = {}
    options.forEach(([group, items]: [string, IFilterOption[]]) => {
      const includesOption = items.filter((item) =>
        item.text.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()),
      )
      if (includesOption.length > 0) {
        filteredOptions[group] = includesOption
      }
    })

    return Object.keys(filteredOptions).length > 0 ? (
      <Column>
        <Checkbox
          checked={handleCheckBoxChecked('all')}
          key="all"
          label="Select All"
          onChange={(_, data) => handleCheckboxChange(data.checked, 'all')}
          className={styles.checkBox}
        />
        {Object.entries(filteredOptions).map(([group, items]: [string, IFilterOption[]]) =>
          group ? (
            <OptionGroup key={group} label={group} className={styles.optionGroup}>
              {items.map((item) => optionRender(item))}
            </OptionGroup>
          ) : (
            items.map((item) => optionRender(item))
          ),
        )}
      </Column>
    ) : (
      <Text>No option match your search.</Text>
    )
  }

  function filterOptions(optionsListWithAll: IFilterOption[]) {
    const filteredOptions = optionsListWithAll.filter((item) => {
      return item.text.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
    })
    return filteredOptions.length > 0 ? (
      <Column>{filteredOptions.map((item) => optionRender(item))}</Column>
    ) : (
      <Text>No option match your search.</Text>
    )
  }

  const targetFinalOptions = isGroup ? groupFilterOptions(Object.entries(groups)) : filterOptions(optionsListWithAll)

  const handleSelectCleanClick = () => {
    setSelectedOptions([])
    onChangeFilter([])
  }

  return (
    <Column>
      <InfoLabel id={useId(comboId)} weight="semibold" info={infoLabelContent}>
        {filterType}:
      </InfoLabel>
      <CopilotDashPopover
        optionContent={targetFinalOptions}
        value={displayValue}
        placeholder={placeholder}
        selectedOptions={selectedOptions}
        handleSelectCleanClick={handleSelectCleanClick}
        inputValue={inputValue}
        setInputValue={setInputValue}
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        showInput={showInput}
      />
    </Column>
  )
}

const useStyles = makeStyles({
  optionGroup: {
    wordBreak: 'break-word',
  },
  checkBox: {
    '&:hover': {
      backgroundColor: tokens.colorBrandBackgroundInvertedHover,
    },
  },
})
