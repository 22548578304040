export function ReplaceMarkdownSyntax(text: string): string {
  const imagePattern: RegExp = /!\[.*?\]\(.*?\)/g // image
  const htmlPattern: RegExp = /<[^>]*>/g // html tag
  const codeSnippetPattern: RegExp = /```[\s\S]*?```/g // code snippet
  const commentPattern: RegExp = /<!--[\s\S]*?-->/g // markdown comment
  const backtickPattern: RegExp = /(`+)(.*?)\1/g // ``
  const listItemPattern: RegExp = /^-\s+/gm // unordered list item
  const horizontalRulePattern: RegExp = /^---$/gm // horizontal rule
  const newlinePattern: RegExp = /(\r\n|\n|\r)/gm // new line

  // To improve performance, check only the first 300 letters(this value from cell width).
  let replacedText = text.replace(imagePattern, '[Image]')
  replacedText = replacedText.replace(htmlPattern, '')
  replacedText = replacedText.replace(codeSnippetPattern, '')
  replacedText = replacedText.replace(commentPattern, '')
  replacedText = replacedText.replace(backtickPattern, '$2')
  replacedText = replacedText.replace(listItemPattern, '') // remove list item marker
  replacedText = replacedText.replace(horizontalRulePattern, '') // remove horizontal rule
  replacedText = replacedText.replace(newlinePattern, ' ') // replace new line with space
  replacedText = replacedText.replace(/(^|\s)#+\s/g, '$1') // replace targeting Markdown syntax for headings

  return replacedText
}
