import { z } from 'zod'

const apiOds3sOnlineDataGroup3ResultSchema = z.record(z.unknown()).and(
  z.object({
    Type: z.string().optional(),
  }),
)

const apiOds3sOnlineDataGroup3ResultSetSchema = z.record(z.unknown()).and(
  z.object({
    Provenance: z.string().optional(),
    Results: z.array(apiOds3sOnlineDataGroup3ResultSchema).optional(),
  }),
)

const apiOds3sOnlineDataGroup3EntitySetSchema = z.record(z.unknown()).and(
  z.object({
    ResultSets: z.array(apiOds3sOnlineDataGroup3ResultSetSchema).optional(),
  }),
)

const apiOds3sOnlineDataGroup3TrimmedResponseSchema = z.record(z.unknown()).and(
  z.object({
    EntitySets: z.array(apiOds3sOnlineDataGroup3EntitySetSchema).optional(),
  }),
)

export const apiOds3sOnlineDataGroup3ReplayResponseSchema = z.record(z.unknown()).and(
  z.object({
    TraceId: z.string(),
    TrimmedResponse: apiOds3sOnlineDataGroup3TrimmedResponseSchema,
  }),
)

export const apiOds3sOnlineDataGroup3Schema = z.record(z.unknown()).and(
  z.object({
    ReplayResponse: apiOds3sOnlineDataGroup3ReplayResponseSchema,
  }),
)
