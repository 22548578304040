import { ApiError } from '@copilot-dash/api'
import { TicketError } from '@copilot-dash/error'
import { ErrorCircleRegular } from '@fluentui/react-icons'
import { ErrorConfigForApi } from './ErrorConfigForApi'
import { ErrorConfigForCustom } from './ErrorConfigForCustom'
import { ErrorConfigForTicket } from './ErrorConfigForTicket'
import { IErrorConfig } from './interfaces/IErrorConfig'
import { CustomError } from './types/CustomError'

export class ErrorConfig implements IErrorConfig {
  private readonly error: unknown

  constructor(error: unknown) {
    this.error = error
  }

  triage() {
    if (this.error instanceof ApiError) {
      return new ErrorConfigForApi(this.error)
    }

    if (this.error instanceof TicketError) {
      return new ErrorConfigForTicket(this.error)
    }

    if (this.error instanceof CustomError) {
      return new ErrorConfigForCustom(this.error)
    }

    return null
  }

  buildName() {
    return 'Error'
  }

  buildIcon() {
    return <ErrorCircleRegular />
  }

  buildMessage() {
    if (this.error instanceof Error) {
      return this.error.message
    }

    return String(this.error)
  }
}
