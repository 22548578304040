import { useMemo } from 'react'
import { Controller, Control } from 'react-hook-form'
import { Card } from './Card'
import { useStyles } from './Card.styles'
import { Body1Stronger } from '@fluentui/react-components'
import { ITicketFilterFormData } from '../TicketFilterForm/TicketFilterForm.types'
import { SearchFilterFieldNames } from '@copilot-dash/domain'
import { RingFilter, CustomerFilter, EndpointFilter, ChannelFilter } from '../filters'
import { useTicketFilterStore } from '../../store/store'
import { FeedbackSourceCardFilters } from './config'

interface IProps {
  control: Control<ITicketFilterFormData>
}

export function FeedbackSourceCard({ control }: IProps) {
  const styles = useStyles()
  const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)
  const selectedProductId = useTicketFilterStore((state) => state.selectedProductId)

  const hasFeedbackSourceCard = useMemo(() => {
    return filterPanelItems.some((item) => FeedbackSourceCardFilters.includes(item))
  }, [filterPanelItems])

  if (!hasFeedbackSourceCard) return null

  return (
    <section className={styles.section}>
      <Body1Stronger>Feedback source</Body1Stronger>
      <Card className={styles.card}>
        {filterPanelItems?.includes(SearchFilterFieldNames.client) && (
          <Controller
            name="client"
            control={control}
            render={({ field }) => (
              <EndpointFilter
                productId={selectedProductId}
                selectedValues={field.value}
                onChangeValue={field.onChange}
              />
            )}
          />
        )}

        {filterPanelItems?.includes(SearchFilterFieldNames.channel) && (
          <Controller
            name="channel"
            control={control}
            render={({ field }) => (
              <ChannelFilter
                productId={selectedProductId}
                selectedValues={field.value}
                onChangeValue={field.onChange}
              />
            )}
          />
        )}

        {filterPanelItems?.includes(SearchFilterFieldNames.customerTypes) && (
          <Controller
            name="customerTypes"
            control={control}
            render={({ field }) => <CustomerFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {filterPanelItems?.includes(SearchFilterFieldNames.ring) && (
          <Controller
            name="ring"
            control={control}
            render={({ field }) => <RingFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}
      </Card>
    </section>
  )
}
