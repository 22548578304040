const SPLASH_DIV_CLASS_READY = 'ready'
const SPLASH_DIV_ID = 'splash'

export class SplashUtils {
  static showSplash() {
    let splashElement = document.getElementById(SPLASH_DIV_ID)
    if (!splashElement) {
      splashElement = document.createElement('div')
      splashElement.id = SPLASH_DIV_ID

      document.body.appendChild(splashElement)
    }

    if (document.body.className.includes(SPLASH_DIV_CLASS_READY)) {
      document.body.classList.remove(SPLASH_DIV_CLASS_READY)
    }
  }

  static hideSplash() {
    if (document.body.className.includes(SPLASH_DIV_CLASS_READY)) {
      return
    }

    document.body.classList.add(SPLASH_DIV_CLASS_READY)

    setTimeout(() => {
      const splashElement = document.getElementById(SPLASH_DIV_ID)
      splashElement?.remove()
    }, 1000)
  }
}
