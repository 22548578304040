import { Logger } from '@copilot-dash/logger'
import { enableMapSet, setUseStrictShallowCopy } from 'immer'
import { createApp } from './app/createApp'
import { PerformanceUtils } from './utils/PerformanceUtils'
import { SplashUtils } from './utils/SplashUtils'

async function initialize(): Promise<void> {
  try {
    PerformanceUtils.onStartInitialization()
    SplashUtils.showSplash()

    await doInitialize1()
    await doInitialize2()
    await doInitialize3()

    PerformanceUtils.onEndInitialization()
    const duration = Math.round(performance.now())

    const accounts = application.auth.accounts.length
    const accountStatus =
      accounts > 1 ? `Found ${accounts} accounts` : accounts === 1 ? 'Found 1 account' : `No accounts found`

    Logger.trace.info(`Application initialized in ${duration} ms. (${accountStatus})`)
  } catch (error) {
    Logger.trace.error('Failed to initialize application. Please check!', error)
    throw error
  } finally {
    SplashUtils.hideSplash()
  }
}

async function doInitialize1(): Promise<void> {
  if (/^(\/){2,}$/.test(window.location.pathname)) {
    window.location.replace(window.location.origin)
    await new Promise((r) => setTimeout(r, 500))
    throw new Error('replace location failed')
  }
}

async function doInitialize2(): Promise<void> {
  const app = createApp()

  window.app = app
  window.application = app
  window.Logger = app.logger
}

async function doInitialize3(): Promise<void> {
  // Immer
  // https://immerjs.github.io/immer/
  enableMapSet()
  setUseStrictShallowCopy(true)

  // Application
  await application.auth.init()
}

export const initialization = initialize()
