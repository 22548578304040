import { Spinner } from '@fluentui/react-components'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

interface ILoadingProps {
  onVisible: () => void
}

export const Loading: React.FC<ILoadingProps> = (props) => {
  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (inView) {
      props.onVisible()
    }
  }, [inView, props])

  return <Spinner ref={ref} size="small" label={'loading '} />
}
