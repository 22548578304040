import { SymptomReportGroup } from '@copilot-dash/domain'
import { makeStyles, TableCell, TableRow, Text, tokens } from '@fluentui/react-components'

interface IProps {
  readonly groupIndex: number
  readonly group: SymptomReportGroup
}

export function GroupRowForTitle({ groupIndex, group }: IProps) {
  const styles = useStyles()
  const title = `3S Transaction ${groupIndex + 1} - ${group.transactionId}`

  return (
    <TableRow className={styles.root}>
      <TableCell colSpan={2}>
        <Text wrap={false} truncate={true} block={true}>
          {title}
        </Text>
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground5,

    ':hover': {
      backgroundColor: tokens.colorNeutralBackground6,
    },

    ':active': {
      backgroundColor: tokens.colorNeutralBackground4,
    },
  },
})
