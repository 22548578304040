import { TelemetryScope } from '@copilot-dash/logger'
import { useEffect } from 'react'
import { LoginScreenLayout } from './layout/LoginScreenLayout'
import { useLoginScreenStore } from './store'

export function LoginScreen(): React.JSX.Element {
  useEffect(() => {
    const initialState = useLoginScreenStore.getInitialState()
    useLoginScreenStore.setState(initialState)
  }, [])

  return (
    <TelemetryScope scope="LoginScreen">
      <LoginScreenLayout />
    </TelemetryScope>
  )
}
