import { EnableExceptionTracking, Logger } from '@copilot-dash/logger'
import { BaseTicketSearchAction } from './BaseTicketSearchAction'
import { IDashStoreContext } from '../../IDashStoreContext'
import { ISearchTicketOptions } from './SearchTicketAction.types'
import { uuid } from '@copilot-dash/core'
import { convertRootCauseList } from '../converters/convertRootCauseList'

interface IParams {
  readonly options: ISearchTicketOptions
}

export class SearchRootCauseByTicketCharacter extends BaseTicketSearchAction {
  private readonly context: IDashStoreContext

  constructor(context: IDashStoreContext) {
    super()
    this.context = context
  }

  @EnableExceptionTracking()
  async search(params: IParams) {
    const searchId = uuid()
    const startTime = Date.now()

    const request = this.createRequest({
      searchId: searchId,
      utteranceOrResponseOdsSessionIdList: [],
      otherOdsSessionIdList: [],
      options: params.options,
      odsSearchTriggered: false,
    })
    try {
      const response = await this.context.api.logCollector.searchRootCauseListByTicketCharacter(request)
      return convertRootCauseList(response, this.context)
    } finally {
      const duration = Date.now() - startTime

      Logger.telemetry.trackMetric('SearchTime', {
        duration,
        filters: params.options,
        searchId,
        page: 'Team View',
        odsSearchTriggered: false,
        isFetchingRootCause: true,
      })
    }
  }
}
