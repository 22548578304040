import { z } from 'zod'
export interface ApiUpdateTicketStatusInfoResponse {
  readonly updateStatus: boolean
  readonly errorMessage: string
}

export const apiUpdateTicketStatusInfoResponseSchema = z.object({
  updateStatus: z.boolean(),
  errorMessage: z.string(),
})
