import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { useMemo } from 'react'
import Markdown from 'react-markdown'
import { Row } from '../../../../../../components/Layout'
import { Scrollbar } from '../../../../../../components/Scrollbar/Scrollbar'

interface IProps {
  readonly content: string
}

export function MetricCardJsonBody({ content }: IProps) {
  const styles = useStyles()

  const markdownText = useMemo(() => {
    return '```json\n' + (content || 'None') + '\n```'
  }, [content])

  return (
    <Row className={styles.root} hAlign="stretch">
      <div className={styles.background} />
      <Scrollbar className={styles.scrollbar}>
        <Markdown className={mergeClasses(styles.markdown, content ? '' : styles.markdownForEmpty)}>
          {markdownText}
        </Markdown>
      </Scrollbar>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    maxHeight: '240px',
    width: '100%',
    border: `1px solid ${tokens.colorBrandStroke2}`,
    borderRadius: tokens.borderRadiusMedium,
    overflow: 'hidden',
    position: 'relative',
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: tokens.colorBrandBackground2,
    opacity: 0.2,
  },
  scrollbar: {
    width: '100%',
    padding: '8px 16px',
  },
  markdown: {
    margin: 0,
    padding: 0,
    height: 'fit-content',

    '& > pre': {
      margin: 0,
    },
  },
  markdownForEmpty: {
    color: tokens.colorNeutralForegroundDisabled,
  },
})
