import {
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  SelectionItemId,
  Table,
  TableBody,
  TableColumnId,
  TableColumnSizingOptions,
  TableHeader,
  TableHeaderCell,
  TableHeaderCellProps,
  TableRow,
  TableRowId,
  TableSelectionCell,
  Text,
  mergeClasses,
  useFluent,
  useScrollbarWidth,
  useTableColumnSizing_unstable,
  useTableFeatures,
  useTableSelection,
} from '@fluentui/react-components'
import {
  useState,
  useCallback,
  useRef,
  CSSProperties,
  useMemo,
  forwardRef,
  useImperativeHandle,
  ReactElement,
} from 'react'
import { ColumnWidthState, ExtendedTableColumnDefinition } from './ExtendedTableColumn'
import { useStyles } from './ResizableColumnsTable.styles'
import { FixedSizeList as List, ListOnScrollProps } from 'react-window'
import { min } from 'lodash'
import { Loading } from './Loading'
import { Column } from '../Layout'
import { ResizeDimensions, useResizeObserver } from '../../hooks/useResizeObserver'
import { adjustColumnWidthsToFitContainer } from './ColumnResizeUtils'
import { useDebouncedState } from '../../hooks/useDebounce'
import { TableRowItem } from './TableRowItem'
const DEFAULT_DIVIDER_WIDTH = 1

interface IProps<TItem> {
  readonly items: TItem[]
  readonly columns: ExtendedTableColumnDefinition<TItem>[]
  readonly keyPicker: (item: TItem, index: number) => string
  readonly rowClick?: (item?: TItem) => void
  readonly supportLoadMore?: boolean
  readonly loadMoreFunction?: (offset: number) => void
  readonly virtualizationConfig?: {
    itemSize: number
  }
  readonly className?: string
  readonly enableBodyScroll?: boolean
  readonly enableColumnResizing?: boolean
  readonly selectedRowId?: string
  readonly selectable?: boolean
  readonly selectionMode?: 'multiselect' | 'single'
  readonly onSelectionChange?: (selectedItems: TItem[] | undefined) => void
  readonly rowHoverStickySlot?: (rowData: TItem) => React.ReactNode
}

export interface ITableRef {
  resetSelection: () => void
}

const InnerResizableColumnsTable = <TItem,>(
  {
    items,
    columns,
    keyPicker,
    rowClick,
    supportLoadMore = false,
    loadMoreFunction,
    className,
    virtualizationConfig,
    enableBodyScroll = !!virtualizationConfig || false,
    enableColumnResizing = false,
    selectedRowId,
    selectable = false,
    selectionMode = 'multiselect',
    onSelectionChange,
    rowHoverStickySlot,
  }: IProps<TItem>,
  ref: React.Ref<ITableRef>,
) => {
  const styles = useStyles()
  const [columnSizingOptions, setColumnSizingOptions] = useDebouncedState<TableColumnSizingOptions>(() => {
    const sizes: { [key: TableColumnId]: Pick<ColumnWidthState, 'minWidth' | 'idealWidth' | 'padding'> } = {}
    columns.forEach((options) => {
      sizes[options.columnId] = options.columnSize
    })
    return sizes
  }, 250)

  const [height, setHeight] = useDebouncedState<number>(0, 250)
  const [selection, setSelection] = useState(() => new Set<TableRowId>([]))

  const { targetDocument } = useFluent()
  const scrollbarWidth = useScrollbarWidth({ targetDocument })
  const headerRef = useRef<HTMLDivElement | null>(null)
  const scrollRef = useRef<HTMLDivElement | null>(null)

  const infiniteTableRowPropsRef = useRef<{
    columns: ExtendedTableColumnDefinition<TItem>[]
    rows: {
      onClick: (e: React.MouseEvent) => void
      onKeyDown: (e: React.KeyboardEvent) => void
      selected: boolean
      appearance: 'none' | 'brand'
      item: TItem
      rowId: TableRowId
    }[]
    getTableCellProps: (columnId: TableColumnId) => Pick<TableHeaderCellProps, 'style'>
    loadMore: () => void
    rowClick: IProps<TItem>['rowClick']
    selection: Set<TableRowId>
    keyPicker: IProps<TItem>['keyPicker']
    selectable: IProps<TItem>['selectable']
    selectedRowId: IProps<TItem>['selectedRowId']
    selectionMode: IProps<TItem>['selectionMode']
    rowHoverStickySlot: IProps<TItem>['rowHoverStickySlot']
  }>()

  useImperativeHandle(ref, () => {
    return {
      resetSelection: () => {
        setSelection(() => new Set<TableRowId>([]))
      },
    }
  })

  const onColumnResize = useCallback(
    (_: unknown, { columnId, width }: { columnId: TableRowId; width: number }) => {
      setColumnSizingOptions((state) => ({
        ...state,
        [columnId]: {
          ...state[columnId],
          idealWidth: width,
        },
      }))
    },
    [setColumnSizingOptions],
  )

  const onResize = useCallback(
    (width: number, height: number) => {
      if (enableColumnResizing) {
        const a = adjustColumnWidthsToFitContainer(
          columns,
          (width ?? 0) - (scrollbarWidth ?? 0) - 2 * DEFAULT_DIVIDER_WIDTH,
        )
        setColumnSizingOptions((state) => ({
          ...state,
          ...a.reduce(
            (acc: Record<TableColumnId, ColumnWidthState>, current) => {
              acc[current.columnId] = {
                ...state[current.columnId],
                idealWidth: current.width,
                columnId: current.columnId,
                width: current.width,
                minWidth: state[current.columnId]?.minWidth || 0,
                padding: state[current.columnId]?.padding || 0,
              }
              return acc
            },
            {} as Record<TableColumnId, ColumnWidthState>,
          ),
        }))
      }

      let calculated = 0
      if (height && headerRef.current?.clientHeight) {
        const borderHeight = 1
        const maxHeight = height - headerRef.current.clientHeight - borderHeight * 2
        calculated = maxHeight

        if (virtualizationConfig?.itemSize) {
          const dividerHeight = 1
          calculated = min([items.length * virtualizationConfig.itemSize + dividerHeight, maxHeight]) ?? 0
        }
      }

      setHeight(calculated)
    },
    [
      columns,
      enableColumnResizing,
      items.length,
      scrollbarWidth,
      setColumnSizingOptions,
      setHeight,
      virtualizationConfig?.itemSize,
    ],
  )

  const containerRef = useResizeObserver(ResizeDimensions.Height, onResize)

  const {
    getRows,
    columnSizing_unstable: columnSizing,
    selection: { allRowsSelected, someRowsSelected, toggleAllRows, toggleRow, isRowSelected },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableColumnSizing_unstable({
        columnSizingOptions,
        autoFitColumns: false,
        containerWidthOffset: 10,
        onColumnResize,
      }),
      useTableSelection({
        selectionMode: selectionMode,
        selectedItems: selection,
        onSelectionChange: (e, data) => {
          if (onSelectionChange) {
            const newRowData = Array.from(data.selectedItems)
            const selectedData = newRowData.map((item: SelectionItemId) => {
              if (typeof item === 'number') {
                if (item >= 0 && item < items.length) {
                  return items[item]
                } else {
                  return undefined
                }
              } else {
                return undefined
              }
            })

            const newSelectedData: TItem[] = selectedData.filter((item): item is TItem => {
              return typeof item !== 'undefined'
            })

            onSelectionChange(newSelectedData)
          }
          setSelection(data.selectedItems)
        },
      }),
    ],
  )

  const rows = useMemo(
    () =>
      getRows((row) => {
        const selected = isRowSelected(row.rowId)
        return {
          ...row,
          onClick: (e: React.MouseEvent) => {
            toggleRow(e, row.rowId)
            e.stopPropagation()
          },
          onKeyDown: (e: React.KeyboardEvent) => {
            if (e.key === ' ') {
              e.preventDefault()
              toggleRow(e, row.rowId)
            }
          },
          selected,
          appearance: selected ? ('brand' as const) : ('none' as const),
        }
      }),
    [getRows, isRowSelected, toggleRow],
  )

  const loadMore = useCallback(() => {
    loadMoreFunction && loadMoreFunction(items.length)
  }, [items.length, loadMoreFunction])

  const handleScroll = useCallback(
    ({ scrollOffset }: ListOnScrollProps) => {
      if (scrollRef.current) {
        if (scrollRef.current.scrollHeight - scrollOffset - height < 2 * height) {
          loadMore()
        }
      }
    },
    [loadMore, height],
  )

  infiniteTableRowPropsRef.current = {
    columns,
    rows,
    getTableCellProps: columnSizing.getTableCellProps,
    loadMore,
    rowClick,
    selection,
    keyPicker,
    selectable,
    selectedRowId,
    selectionMode,
    rowHoverStickySlot,
  }

  const renderRow = useCallback(({ index, style }: { index: number; style?: CSSProperties }) => {
    const {
      columns: _columns,
      rows: _rows,
      getTableCellProps: _getTableCellProps,
      loadMore: _loadMore,
      rowClick: _rowClick,
      selection: _selection,
      keyPicker: _keyPicker,
      selectable: _selectable,
      selectedRowId: _selectedRowId,
      selectionMode: _selectionMode,
      rowHoverStickySlot: _rowHoverStickySlot,
    } = infiniteTableRowPropsRef.current!
    const key = _rows[index]?.item && _keyPicker(_rows[index]!.item, index)
    const row = _rows[index]

    return (
      <TableRowItem
        key={key ?? index}
        showLoadingRow={index === _rows.length}
        columns={_columns}
        row={row}
        getTableCellProps={_getTableCellProps}
        style={style}
        loadMore={_loadMore}
        focused={_rows?.[index]?.item && _selectedRowId === key}
        rowClick={_rowClick}
        selected={_selection.has(_rows?.[index]?.rowId ?? '-1')}
        selectable={_selectable}
        selectionMode={_selectionMode}
        rowHoverStickySlot={row?.item ? _rowHoverStickySlot?.(row.item) : undefined}
      />
    )
  }, [])

  const toggleAllKeydown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === ' ') {
        toggleAllRows(e)
        e.preventDefault()
      }
    },
    [toggleAllRows],
  )

  return (
    <Column ref={containerRef} className={mergeClasses(styles.container, className)}>
      <Column className={styles.tableLayout}>
        <Table
          className={styles.table}
          sortable
          aria-label="Table with sort"
          noNativeElements={true}
          {...columnSizing.getTableProps()}
        >
          <TableHeader ref={headerRef} className={styles.header}>
            <TableRow className={styles.headerRow}>
              {selectable ? (
                selectionMode === 'multiselect' ? (
                  <TableSelectionCell
                    checked={allRowsSelected ? true : someRowsSelected ? 'mixed' : false}
                    onClick={toggleAllRows}
                    onKeyDown={toggleAllKeydown}
                    checkboxIndicator={{ 'aria-label': 'Select all rows ' }}
                  />
                ) : (
                  <TableSelectionCell type="radio" invisible />
                )
              ) : null}
              {columns.map((column, index) => (
                <Menu openOnContext key={column.columnId}>
                  <TableHeaderCell
                    key={column.columnId}
                    {...(column.isResizable ? columnSizing.getTableHeaderCellProps(column.columnId) : {})}
                  >
                    <Text wrap={false} truncate weight="medium">
                      {column.renderHeaderCell()}
                    </Text>
                  </TableHeaderCell>
                  <MenuPopover>
                    <MenuList>
                      <MenuItem onClick={columnSizing.enableKeyboardMode(column.columnId)}>
                        Keyboard Column Resizing
                      </MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
              ))}
              {/** Scrollbar alignment for the header */}
              <div role="presentation" style={{ width: scrollbarWidth }} />
            </TableRow>
          </TableHeader>
          <TableBody>
            {virtualizationConfig ? (
              <List
                itemCount={rows.length + (supportLoadMore ? 1 : 0)}
                itemSize={virtualizationConfig.itemSize}
                itemData={rows}
                height={height}
                width="100%"
                onScroll={handleScroll}
                innerRef={scrollRef}
                style={{ overflowX: 'hidden' }}
              >
                {renderRow}
              </List>
            ) : (
              <Column
                fill
                style={
                  enableBodyScroll
                    ? {
                        maxHeight: height,
                      }
                    : undefined
                }
              >
                {rows.map((_row, index) => renderRow({ index }))}
                {supportLoadMore && (
                  <Column hAlign="center" vAlign="center" fill>
                    <Loading onVisible={loadMore} />
                  </Column>
                )}
              </Column>
            )}
          </TableBody>
        </Table>
      </Column>
    </Column>
  )
}

// NOTE: @Ethan - This is a workaround to fix the issue with the forwardRef type
// refer link: https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
export const ResizableColumnsTable = forwardRef(InnerResizableColumnsTable) as <TItem>(
  props: IProps<TItem> & { ref?: React.Ref<ITableRef> },
) => ReactElement
