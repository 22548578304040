import { useEffect, useState } from 'react'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { createTicketSummaryPanelActions } from './createTicketSummaryPanelActions'
import { createTicketSummaryPanelState } from './createTicketSummaryPanelState'
import { ITicketSummaryPanelState } from './ITicketSummaryPanelState'
import { TicketSummaryPanelStoreContext } from './TicketSummaryPanelStoreContext'

interface IProps {
  readonly ticketId: string
  readonly children: React.ReactNode
}

export function TicketSummaryPanelStoreProvider({ ticketId, children }: IProps) {
  const [value] = useState(() => {
    const useTicketSummaryPanelStore = create<ITicketSummaryPanelState>()(
      immer((set, get) => createTicketSummaryPanelState(set, get, ticketId)),
    )

    const store = {
      get state() {
        return useTicketSummaryPanelStore.getState()
      },
      use: useTicketSummaryPanelStore,
      setState: useTicketSummaryPanelStore.setState,
    }

    const actions = createTicketSummaryPanelActions(store)
    return { store, actions }
  })

  useEffect(() => {
    return value.store.use.subscribe((state, prevState) => {
      value.actions.onStateChanged(state, prevState)
    })
  }, [value])

  useEffect(() => {
    value.actions.updateTicketId(ticketId)
  }, [ticketId, value.actions])

  return <TicketSummaryPanelStoreContext.Provider value={value}>{children}</TicketSummaryPanelStoreContext.Provider>
}
