import { PromiseSnapshots } from '@copilot-dash/core'
import { I3sLogDataItem, I3sLogData } from '@copilot-dash/domain'
import { makeStyles, Tree, TreeItem, TreeItemLayout } from '@fluentui/react-components'
import { useMemo } from 'react'
import { TreeItemErrorView } from './common/TreeItemErrorView'
import { TreeItemJsonView } from './common/TreeItemJsonView'
import { TreeItemTitle } from './common/TreeItemTitle'

interface IProps {
  readonly data: I3sLogData
  readonly item: I3sLogDataItem
}

export function SssLogViewTreeItemKusto({ data, item }: IProps) {
  const styles = useStyles()

  const snapshot = useMemo(() => {
    if (item.logError) {
      return PromiseSnapshots.error(item.logError)
    }

    if (item.log) {
      return PromiseSnapshots.done(item.log)
    }

    return undefined
  }, [item])

  return (
    <TreeItem itemType="branch">
      <TreeItemTitle type="kusto" snapshot={snapshot} />
      <Tree>
        <TreeItem itemType="leaf">
          <TreeItemLayout className={styles.content}>
            {(() => {
              if (item.log) {
                return <TreeItemJsonView data={item.log} />
              }

              if (item.logError) {
                return <TreeItemErrorView error={item.logError} />
              }

              return null
            })()}
          </TreeItemLayout>
        </TreeItem>
      </Tree>
    </TreeItem>
  )
}

const useStyles = makeStyles({
  content: {
    width: '100%',
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
})
