import { ApiRootCauseListByTeamResponse } from '@copilot-dash/api'
import { isAssignedTo3SDashTorus } from '../../utils/is3SDashTorus'
import { IRootCauseListByTeam } from '@copilot-dash/domain'
import { getUserProfile } from '../getUserProfile'
import { IDashStoreContext } from '../../IDashStoreContext'

export function convertRootCauseList(
  apiData: ApiRootCauseListByTeamResponse,
  context: IDashStoreContext,
): IRootCauseListByTeam {
  const returnData: IRootCauseListByTeam = {
    ...apiData,
    rootCauseList: apiData.rootCauseList.map((item) => {
      const { owner, ...list } = item
      if (owner && !isAssignedTo3SDashTorus(owner)) {
        getUserProfile(context, owner)
      }
      return {
        ...list,
        owner: isAssignedTo3SDashTorus(owner) ? undefined : owner,
      }
    }),
  }

  return returnData
}
