import { makeStyles, shorthands } from '@fluentui/react-components'
import { Column, Spacer } from '../../../components/Layout'
import { LoginButton } from '../children/LoginButton'
import { LoginButtonTips } from '../children/LoginButtonTips'

const useStyles = makeStyles({
  container: {
    ...shorthands.padding('16px'),
  },
})

export function LoginScreenLayout(): React.JSX.Element {
  const styles = useStyles()

  return (
    <Column className={styles.container} hAlign="start" vAlign="start">
      <LoginButton />
      <Spacer height="16px" />
      <LoginButtonTips />
    </Column>
  )
}
