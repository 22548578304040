import { getUtcDate } from './getUtcDate'

export function getDefaultDates(timezone: string): { startDate: Date; endDate: Date } {
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const oneWeekAgo = new Date()
  oneWeekAgo.setDate(today.getDate() - 7)
  oneWeekAgo.setHours(0, 0, 0, 0)

  if (timezone !== 'UTC') {
    return {
      startDate: oneWeekAgo,
      endDate: today,
    }
  }

  return {
    startDate: getUtcDate(oneWeekAgo, timezone),
    endDate: getUtcDate(today, timezone),
  }
}
