import { z } from 'zod'

export interface ApiGetTicketIdByWorkItemIdRequest {
  readonly WorkItemId: string
  readonly TeamId: number
}

export interface ApiGetTicketIdByWorkItemIdResponse {
  readonly ticketId: string
}

export const apiGetTicketIdByWorkItemIdResponseSchema = z.object({
  ticketId: z.string(),
})
