import { Button, Tooltip } from '@fluentui/react-components'
import { getFeedbackUrl } from '../../../constants/getFeedbackUrl'
import { RouteLink } from '../../../router'

export function ErrorViewPopoverBodyActionsFeedbackButton() {
  const feedbackUrl = getFeedbackUrl()

  return (
    <RouteLink path={feedbackUrl} openInNewTab>
      <Tooltip content="Report this problem" relationship="label">
        <Button shape="circular">Report</Button>
      </Tooltip>
    </RouteLink>
  )
}
