import { parseProductName, ProductNames } from '@copilot-dash/domain'
import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { McpExperimentFeedbackScreen } from '../../../screens/mcpExperimentFeedback/McpExperimentFeedbackScreen'
import { McpExperimentRoute } from './McpExperimentRoute'
import { useMemo } from 'react'
import { GlobalProductSelector } from '../../../providers/product'
import { NotFoundRoutePage } from '../404/NotFoundRoutePage'

export function McpExperimentRoutePage() {
  const args = McpExperimentRoute.navigator.useArgs()

  const isProductValid = useMemo(() => {
    return parseProductName(args.product) !== undefined
  }, [args.product])
  if (!isProductValid) {
    return <NotFoundRoutePage />
  }

  return (
    <GlobalProductSelector
      value={args.product}
      isItemDisabled={(product) => product.name !== ProductNames.TeamsMeetingCopilot}
    >
      <PageTitle title="Experiment Feedback">
        <McpExperimentFeedbackScreen />
      </PageTitle>
    </GlobalProductSelector>
  )
}
