import { makeStyles, Toolbar } from '@fluentui/react-components'
import { Spacer } from '../../../Layout'
import { AnyDataStore } from '../../AnyDataStore'
import { AnyDataTab } from '../../store/IState'
import { AnyDataBarTabs } from './AnyDataBarTabs'
import { AnyJsonToolbar } from './json/AnyJsonToolbar'
import { AnyTableToolbar } from './table/AnyTableToolbar'

export function AnyDataBar() {
  const styles = useStyles()
  const selectedTab = AnyDataStore.use((state) => state.selectedTab)

  const renderToolbar = () => {
    switch (selectedTab) {
      case AnyDataTab.Table:
        return <AnyTableToolbar />
      case AnyDataTab.Json:
        return <AnyJsonToolbar />
    }
  }

  return (
    <Toolbar className={styles.root}>
      <AnyDataBarTabs />
      <Spacer />
      {renderToolbar()}
    </Toolbar>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '0 12px 0 4px',
  },
})
