import * as React from 'react'
import { CopilotDashEmailDropdown } from '../Filter/CopilotDashEmailDropdown'

interface EmailFilterProps {
  onChangeUser?: (userId?: string, emailAddress?: string) => void
  onDeselect: () => void
  emailAddress?: string
  userId?: string
  disableEmailFilter?: boolean
  className?: string
}

export const EmailFilter: React.FC<EmailFilterProps> = ({
  onChangeUser,
  onDeselect,
  emailAddress,
  userId,
  disableEmailFilter,
  className,
}) => {
  return (
    <CopilotDashEmailDropdown
      onSelect={(userId, userEmail) => {
        onChangeUser?.(userId, userEmail)
        Logger.telemetry.trackEvent('TicketFilter/Changed', { filter: 'FromEmail', value: userEmail })
      }}
      onDeselect={onDeselect}
      userId={userId}
      userEmail={emailAddress}
      disable={disableEmailFilter}
      className={className}
      placeholder="From Email"
      showInput={true}
    />
  )
}
