import { z } from 'zod'

const outputMessageItemSchema = z.record(z.unknown()).and(
  z.object({
    Text: z.string(),
  }),
)

export const apiOds3sOfflineDataGroup1Schema = z.record(z.unknown()).and(
  z.object({
    Utterance: z.string().optional(),
    OutputMessage: z.array(outputMessageItemSchema).optional(),
  }),
)
