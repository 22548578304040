import { Field, Input, Text, makeStyles, tokens } from '@fluentui/react-components'
import { FC, memo } from 'react'

interface ITitleInputProps {
  value: string | undefined
  onChange: (value: string) => void
  disabled?: boolean
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
}

export const Title: FC<ITitleInputProps> = memo(({ value, onChange, disabled, fieldProps = {} }) => {
  const styles = useStyles()

  return (
    <Field
      label={
        <Text size={400} weight="semibold">
          Title:{' '}
        </Text>
      }
      orientation="vertical"
      required
      {...fieldProps}
      onClick={(e) => e.stopPropagation()}
    >
      <Input
        value={value ?? ''}
        disabled={disabled}
        onChange={(_, data) => {
          onChange(data.value)
        }}
        placeholder="Enter title"
        className={styles.input}
      />
    </Field>
  )
})

Title.displayName = 'Title'

const useStyles = makeStyles({
  input: {
    backgroundColor: tokens.colorNeutralBackground4Pressed,
    border: 'none',
  },
})
