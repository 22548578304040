import { useMemo } from 'react'
import { Controller, Control } from 'react-hook-form'
import { Card } from './Card'
import { useStyles } from './Card.styles'
import { Body1Stronger } from '@fluentui/react-components'
import { ITicketFilterFormData } from '../TicketFilterForm/TicketFilterForm.types'
import { SearchFilterFieldNames, ProductIds } from '@copilot-dash/domain'
import { EmotionTypeFilter, HasVerbatimFilter, HasUserConsentFilter, HasVerbatimFilterForAIF } from '../filters'
import { useTicketFilterStore } from '../../store/store'
import { FeedbackDetailCardFilters } from './config'
interface IProps {
  control: Control<ITicketFilterFormData>
}

export function FeedbackDetailsCard({ control }: IProps) {
  const styles = useStyles()
  const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)
  const selectedProductId = useTicketFilterStore((state) => state.selectedProductId)

  const hasFeedbackDetailsCard = useMemo(() => {
    return filterPanelItems.some((item) => FeedbackDetailCardFilters.includes(item))
  }, [filterPanelItems])

  if (!hasFeedbackDetailsCard) return null

  return (
    <section className={styles.section}>
      <Body1Stronger>Feedback details</Body1Stronger>
      <Card className={styles.cardFeedbackTag}>
        {filterPanelItems?.includes(SearchFilterFieldNames.thumbs) && (
          <Controller
            name="thumbs"
            control={control}
            render={({ field }) => <EmotionTypeFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {filterPanelItems?.includes(SearchFilterFieldNames.hasVerbatim) && (
          <Controller
            name="hasVerbatim"
            control={control}
            render={({ field }) =>
              selectedProductId === ProductIds.TenantAdminFeedback ? (
                <HasVerbatimFilterForAIF selectedValues={field.value} onChangeValue={field.onChange} />
              ) : (
                <HasVerbatimFilter selectedValues={field.value} onChangeValue={field.onChange} />
              )
            }
          />
        )}

        {filterPanelItems?.includes(SearchFilterFieldNames.hasUserConsent) && (
          <Controller
            name="hasUserConsent"
            control={control}
            render={({ field }) => <HasUserConsentFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}
      </Card>
    </section>
  )
}
