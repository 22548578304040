import { Times, TimeType } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'

export function validateTicket3sOfflineDataTimeliness(ticketId: string, feedbackTime: TimeType) {
  if (Times.isWithinLastXHours(feedbackTime, 6)) {
    throw TicketError.create('No3sOfflineDueTo6Hours', { ticketId, ticketCreationTime: feedbackTime })
  }

  if (Times.isWithinLastXHours(feedbackTime, 48)) {
    throw TicketError.create('No3sOfflineDueTo48Hours', { ticketId, ticketCreationTime: feedbackTime })
  }
}
