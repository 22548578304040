import { ColDef } from 'ag-grid-community'
import { useMemo } from 'react'
import { AnyObject } from '../types/AnyObject'
import { generateAnyColumn } from '../utils/generateAnyColumn'
import { generateAnyColumnKeys } from '../utils/generateAnyColumnKeys'

export function useAnyTableColumns(data: AnyObject[]): ColDef<AnyObject, unknown>[] {
  return useMemo(() => {
    const keys = generateAnyColumnKeys(data)

    return keys.map((key) => {
      return generateAnyColumn(key, data)
    })
  }, [data])
}
