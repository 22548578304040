import { makeStyles } from '@fluentui/react-components'
import { ReactNode } from 'react'
import { RouterProvider } from 'react-router-dom'
import { IAppRouter } from './createAppRouter'

interface IProps {
  readonly router: IAppRouter
}

export function AppRouterProvider({ router }: IProps): ReactNode {
  const styles = useStyles()

  return (
    <div className={styles.root} id="appRouteProvider">
      <RouterProvider router={router.instance} />
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: '100vh',
  },
})
