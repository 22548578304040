import { SymptomReportItem } from '@copilot-dash/domain'

export function parseToNumber(key: string, value: unknown): SymptomReportItem | undefined {
  if (typeof value !== 'number') {
    return
  }

  return {
    key,
    value: value,
    valueType: 'Number',
  }
}
