import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { Column, Row } from '../../../components/Layout'
import { useScreenSpacing } from '../../../layout'
import { TicketScreenAction } from '../children/action/TicketScreenAction'
import { TicketScreenMain } from '../children/main/TicketScreenMain'
import { TicketOutline } from '../children/outline/TicketOutline'
import { TicketScreenVerbatim } from '../children/verbatim/TicketScreenVerbatim'

export function TicketScreenLayoutForData() {
  const styles = useStyles()
  const spacing = useScreenSpacing()

  return (
    <Column className={styles.root} style={{ paddingLeft: spacing, paddingRight: spacing }} gap="gap.medium">
      <TicketScreenVerbatim />
      <TicketScreenAction />
      <Row className={styles.body}>
        <TicketOutline />
        <TicketScreenMain />
      </Row>
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    flex: 1,
  },
  body: {
    flex: '1 1 0',
    overflow: 'hidden',
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
    borderBottom: 'none',
    borderTopLeftRadius: tokens.borderRadiusXLarge,
    borderTopRightRadius: tokens.borderRadiusXLarge,

    '& > *:first-child': {
      flex: '0 0 auto',
      width: '30%',

      borderRight: `1px solid ${tokens.colorNeutralStroke2}`,
    },

    '& > *:last-child': {
      flex: '1 1 0',
      overflow: 'hidden',
    },
  },
})
