import { FC } from 'react'
import { useStyles } from './SearchScreenTag.styles'
import { InteractionTag, InteractionTagPrimary, mergeClasses, TagSize } from '@fluentui/react-components'
import { CopilotDashTooltip } from '../../../../components/CopilotDashTooltip/CopilotDashTooltip'
import { Column } from '../../../../components/Layout'

interface IProps {
  tagCount: number
  foldedTags: JSX.Element
  className?: string
  tagSize?: TagSize
  onClickTagFunction?: () => void
}

export const TagCounter: FC<IProps> = ({ tagCount, foldedTags, className, tagSize, onClickTagFunction }) => {
  const styles = useStyles()
  return (
    <Column className={styles.container}>
      <InteractionTag size={tagSize ? tagSize : 'medium'}>
        <CopilotDashTooltip
          className={styles.tooltip}
          relationShip="label"
          toolTipContent={foldedTags}
          toolTipParentContent={
            <InteractionTagPrimary
              onClick={onClickTagFunction}
              className={mergeClasses(styles.interactionTagPrimary, className)}
            >
              +{tagCount}
            </InteractionTagPrimary>
          }
        />
      </InteractionTag>
    </Column>
  )
}
