import { useState, useEffect } from 'react'
import { Label, useId, Text, Button } from '@fluentui/react-components'
import { IFilterOption } from './IFilterOption.types'
import { Column, Spacer } from '../Layout'
import { CheckmarkRegular } from '@fluentui/react-icons'
import { CopilotDashPopover } from './CopilotDashPopover'
import { useStyles } from './CopilotDashDropdown.styles'

interface IProps {
  comboId: string
  filterType: string
  placeholder?: string
  optionsList: IFilterOption[]
  defaultSelectedOption: string | undefined
  onChangeFilter: (item: string[]) => void
}

export const CopilotDashSingleSelectDropdown: React.FC<IProps> = ({
  comboId,
  filterType,
  placeholder = 'Select',
  optionsList,
  defaultSelectedOption,
  onChangeFilter,
}) => {
  const styles = useStyles()
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const [value, setValue] = useState('')
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (defaultSelectedOption) {
      setSelectedOptions([defaultSelectedOption])
      const selectedOption = optionsList.find((option) => option.key === defaultSelectedOption)
      setValue(selectedOption?.text || '')
    } else {
      setSelectedOptions([])
      setValue('')
    }
  }, [defaultSelectedOption, optionsList])

  const onOptionSelect = (item: IFilterOption) => {
    setValue(item.text)
    setSelectedOptions([item.key])
    onChangeFilter([item.key])
    setIsPopoverOpen(false)
  }

  function filterOptions(optionsList: IFilterOption[]) {
    const filteredOptions = optionsList.filter((item) => {
      return item.text.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
    })
    return filteredOptions.length > 0 ? (
      filteredOptions.map((item) => (
        <Column key={item.key}>
          <Button
            icon={selectedOptions.includes(item.key) ? <CheckmarkRegular /> : <Spacer width="26px" />}
            appearance="subtle"
            className={styles.selectButton}
            onClick={() => onOptionSelect(item)}
            title={item.text}
          >
            {item.text}
          </Button>
        </Column>
      ))
    ) : (
      <Text>No option match your search.</Text>
    )
  }

  const targetFinalOptions = filterOptions(optionsList)

  const handleSelectCleanClick = () => {
    setSelectedOptions([])
    onChangeFilter([])
  }

  return (
    <Column>
      <Label id={useId(comboId)} weight="semibold">
        {filterType}:
      </Label>
      <CopilotDashPopover
        optionContent={targetFinalOptions}
        value={value}
        placeholder={placeholder}
        selectedOptions={selectedOptions}
        handleSelectCleanClick={handleSelectCleanClick}
        inputValue={inputValue}
        setInputValue={setInputValue}
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
      />
    </Column>
  )
}
