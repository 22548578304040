import { Route } from 'react-router-dom'
import { SettingsScreen } from '../../../screens/settings/SettingsScreen'
import { createNavigator } from '../../navigator/createNavigator'
import { VoidMapper } from '../../utils/VoidMapper'
import { SettingsRoutePage } from './SettingsRoutePage'

export function SettingsRoute() {
  return <Route id={ID} path={PATH} element={<SettingsRoutePage />} />
}

const ID = 'settings'
const PATH = '/settings'
SettingsRoute.navigator = createNavigator(ID, PATH, VoidMapper)

SettingsRoute.navigateToModal = () => {
  app.dialog.open({
    content: <SettingsScreen />,
  })
}
