import { makeStyles, shorthands } from '@fluentui/react-components'

export const useStyles = makeStyles({
  tableCellLayout: {
    width: '100%',
    ...shorthands.overflow('hidden'),
    ...shorthands.padding('8px'),
    wordWrap: 'break-word',
    wordBreak: 'normal',
    overflowWrap: 'break-word',
  },
})
