import { ITagDefinition } from './ITagDefinition.type'
import { useSearchScreenState } from '../../store'
import { TruncateTagFunction } from './TruncateTagFunction'
import { GetTagValueTextFunction } from './GetTagValueTextFunction'

export const ExperienceTypeConfig = (): ITagDefinition => {
  const form = useSearchScreenState((state) => state.form)
  const valueTexts = GetTagValueTextFunction('', '', form.experienceType || [], 'ExperienceType:')

  return {
    key: 'experienceType',
    tagName: 'PromptMetadata_ExperienceType',
    toolTipContentValue: valueTexts.join(', '),
    truncatedTagValue: TruncateTagFunction(valueTexts),
    IsShowTag: false,
    isTagClearable: true,
  }
}
