import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { IStore } from './IStore'
import { IStoreCreator } from './IStoreCreator'

export function createStore<TState, TActions>(creator: IStoreCreator<TState, TActions>): IStore<TState, TActions> {
  const useStore = create<TState & TActions>()(
    immer((set, get) => {
      const { state, actions } = creator(set, get)

      return {
        ...state,
        ...actions,
      }
    }),
  )

  return {
    use: useStore,
    get state() {
      return useStore.getState()
    },
    setState: useStore.setState,
    get actions() {
      return useStore.getState()
    },
  }
}
