import { PromiseSnapshot } from '@copilot-dash/core'
import { Spinner } from '@fluentui/react-components'
import { AnyData } from '../../../../components/AnyData/AnyData'
import { ErrorView } from '../../../../components/Error'
import { Row } from '../../../../components/Layout'

interface IProps {
  readonly snapshot: PromiseSnapshot<unknown[]>
}

export function TicketScreenLogView({ snapshot }: IProps) {
  switch (snapshot.status) {
    case 'waiting':
      return (
        <Row vAlign="center" hAlign="center" fill>
          <Spinner label="Loading..." labelPosition="below" />
        </Row>
      )
    case 'error':
      return (
        <Row vAlign="center" hAlign="center" fill>
          <ErrorView error={snapshot.error} />
        </Row>
      )
    case 'done':
      return <AnyData data={snapshot.data} />
  }
}
