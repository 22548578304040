import { Button, mergeClasses } from '@fluentui/react-components'
import * as React from 'react'
import { useStyles } from './Clickable.styles'

interface IClickableProps {
  onClick?: () => void
  className?: string
  children: React.ReactNode
}

export const Clickable: React.FC<IClickableProps> = React.memo(({ onClick, className, children }) => {
  return (
    <Button onClick={onClick} className={mergeClasses(useStyles().button, className)}>
      {children}
    </Button>
  )
})

Clickable.displayName = 'Clickable'
