import { Node } from '@xyflow/react'
import { countBy, fromPairs, sortBy, uniq } from 'lodash'
import { CallFlowNode } from '../store/IState'

interface IParams {
  readonly nodes: Node<CallFlowNode>[]
  readonly columnCount: number
}

export function layoutNodesColumn({ nodes, columnCount }: IParams): Record<string, number> {
  // 1. Count types
  const typeCounts = countBy(nodes.map((node) => node.data.item.serviceId))

  // 2. Sort types by count
  const types = uniq(nodes.map((node) => node.data.item.serviceId))
  const sortedTypes = sortBy(types, (type) => typeCounts[type] ?? 0).reverse()

  // 3. Initialize columns
  const columnTotalCounts: number[] = Array.from({ length: columnCount }, () => 0)
  const result: Record<string, number> = fromPairs(types.map((type) => [type, 0]))

  // 4. Special case for the first node. It should always be the middle column
  // const firstNodeType = nodes[0]?.data.type
  // if (firstNodeType) {
  //   const columnIndex = Math.floor(columnCount / 2)
  //   columns[columnIndex]! += typeCounts[firstNodeType]
  //   result[firstNodeType] = columnIndex
  // }

  // 5. Greedy distribution
  for (const type of sortedTypes) {
    const columnIndex = columnTotalCounts.indexOf(Math.min(...columnTotalCounts))
    columnTotalCounts[columnIndex]! += typeCounts[type] ?? 0
    result[type] = columnIndex
  }

  return result
}
