import { Button, mergeClasses, Spinner } from '@fluentui/react-components'
import { ArrowCircleUpFilled, CheckmarkRegular, DismissRegular } from '@fluentui/react-icons'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { COMMAND_PRIORITY_HIGH, KEY_DOWN_COMMAND } from 'lexical'
import { useEffect } from 'react'
import { Column, Row } from '../../../Layout'
import { LexicalRichTextEditorStore } from '../../LexicalRichTextEditorStore'
import { useStyles } from './KeyboardPlugin.styles'

export function KeyboardPlugin() {
  const styles = useStyles()

  const actions = LexicalRichTextEditorStore.useActions()
  const mode = LexicalRichTextEditorStore.use((state) => state.mode)
  const isSubmitting = LexicalRichTextEditorStore.use((state) => state.isSubmitting)

  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    actions.prepare(editor)

    const unregister = editor.registerCommand(
      KEY_DOWN_COMMAND,
      (event: KeyboardEvent) => {
        if (event.ctrlKey && event.key === 'Enter') {
          actions.submit()
          return true
        }
        return false
      },
      COMMAND_PRIORITY_HIGH,
    )

    return () => {
      unregister()
    }
  }, [actions, editor])

  return (
    <Column>
      {mode === 'create' ? (
        <Row>
          {!isSubmitting ? (
            <Button
              icon={<ArrowCircleUpFilled />}
              size="large"
              className={styles.sendButton}
              appearance="transparent"
              onClick={actions.submit}
              disabled={isSubmitting}
            />
          ) : (
            <Spinner size="tiny" className={mergeClasses(styles.baseLoadingStyle, styles.sendLoading)} />
          )}
        </Row>
      ) : (
        <Column>
          {!isSubmitting ? (
            <Column>
              <Button
                icon={<DismissRegular />}
                size="small"
                className={styles.cancelEditButton}
                appearance="transparent"
                onClick={actions.cancel}
                disabled={isSubmitting}
              />
              <Button
                icon={<CheckmarkRegular />}
                size="small"
                appearance="transparent"
                className={styles.submitEditButton}
                onClick={actions.submit}
                disabled={isSubmitting}
              />
            </Column>
          ) : (
            <Spinner size="tiny" className={mergeClasses(styles.baseLoadingStyle, styles.sendEditLoading)} />
          )}
        </Column>
      )}
    </Column>
  )
}
