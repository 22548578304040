import { WritableDraft } from 'immer'
import { ISettingsScreenActions } from './ISettingsScreenActions'
import { ISettingsScreenState, SettingsScreenTab } from './ISettingsScreenState'

export function createSettingsScreenActions(
  set: (callback: (state: WritableDraft<ISettingsScreenState>) => void) => void,
  get: () => ISettingsScreenState,
): ISettingsScreenActions {
  const selectTab = (tab: SettingsScreenTab) => {
    set((state) => {
      state.selectedTab = tab
    })
  }

  return {
    selectTab,
  }
}
