import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../IDashStoreContext'
import { IQuery } from '@copilot-dash/domain'
import { QueryDataConverter } from './converters/QueryDataConverter'

export function getQuery(context: IDashStoreContext, queryId: string): PromiseSnapshot<IQuery> {
  return context.getOrFetch({
    get: (state) => {
      return state.query.QueriesMap[queryId]
    },
    set: (state, snapshot) => {
      state.query.QueriesMap[queryId] = snapshot
    },
    fetch: async () => {
      const response = await context.api.logCollector.getQueryByQueryId(queryId)
      return QueryDataConverter.fromUpdateOrGetQueryApi(response)
    },
  })
}
