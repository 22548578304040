import { DashError } from '../core/DashError'
import { DashErrorLevel } from '../core/DashErrorLevel'
import { DashErrorType } from '../core/DashErrorType'
import { NetworkErrorCode } from './NetworkErrorCode'
import { NetworkErrorCodes } from './NetworkErrorCodes'
import { NetworkErrorData } from './NetworkErrorData'

export class NetworkError extends DashError {
  override readonly data: NetworkErrorData

  constructor(data: NetworkErrorData) {
    super(DashErrorType.Network, data)
    this.data = data
  }

  static create(code: number | undefined): NetworkError {
    const definition: NetworkErrorCode = this.getDefinition(code)
    const data: NetworkErrorData = {
      code,
      level: DashErrorLevel.Error,
      message: typeof definition.message === 'function' ? definition.message(code) : definition.message,
      description:
        typeof definition.messageDetail === 'function' ? definition.messageDetail(code) : definition.messageDetail,
    }

    return new NetworkError(data)
  }

  private static getDefinition(code: number | undefined): NetworkErrorCode {
    return (
      NetworkErrorCodes.find((item) => {
        if (code === undefined) {
          return false
        }

        if (item.code.length === 1) {
          return item.code[0] === code
        }

        return code >= item.code[0] && code <= item.code[1]
      }) ?? NetworkErrorCodes[NetworkErrorCodes.length - 1]!
    )
  }
}
