import { ApiError, AxiosError } from '@copilot-dash/api'
import { ApiResponseTableAxiosResponse } from './ApiResponseTableAxiosResponse'

interface IProps {
  readonly error: ApiError
  readonly cause: AxiosError
}

export function ApiResponseTableAxios({ error, cause }: IProps) {
  return (
    <table>
      <tbody>
        {/* 1. Status Code */}
        {cause.response?.status && (
          <tr>
            <td>StatusCode</td>
            <td>{cause.response?.status}</td>
          </tr>
        )}

        {/* 2. Status Message */}
        {cause.response?.statusText && (
          <tr>
            <td>Status Message</td>
            <td>{cause.response?.statusText}</td>
          </tr>
        )}

        {/* 3. Error Code */}
        <tr>
          <td>ErrorCode</td>
          <td>{cause.code}</td>
        </tr>

        {/* 4. Error Message */}
        <tr>
          <td>ErrorMessage</td>
          <td>{cause.message}</td>
        </tr>

        {/* 5. Response */}
        <tr>
          <td>Response</td>
          <td>
            <ApiResponseTableAxiosResponse response={cause.response?.data} />
          </td>
        </tr>
      </tbody>
    </table>
  )
}
