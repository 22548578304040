import { Field, Textarea } from '@fluentui/react-components'
import { FC, memo } from 'react'

interface INoActionableReasonProps {
  value: string | undefined
  onChange: (value: string) => void
  orientation?: 'horizontal' | 'vertical'
  className?: string
  style?: React.CSSProperties
  disabled?: boolean
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
}

export const NoActionableReason: FC<INoActionableReasonProps> = memo(
  ({ value, onChange, orientation, className, style, disabled, fieldProps }) => {
    return (
      <Field className={className} label={''} orientation={orientation} style={style} {...(fieldProps ?? {})}>
        <Textarea
          value={value}
          disabled={disabled}
          onChange={(_, data) => onChange(data.value)}
          placeholder="Tell us what data are missing to help you take actions."
        />
      </Field>
    )
  },
)

NoActionableReason.displayName = 'NoActionableReason'
