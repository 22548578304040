import { memo } from 'react'
import { CopilotDashSearchBox } from '../../../Filter/CopilotDashSearchBox'

interface IProps {
  value: string | undefined
  onChangeValue: (value?: string) => void
}

export const SliceIdsFilter: React.FC<IProps> = memo(({ value, onChangeValue }) => {
  return (
    <CopilotDashSearchBox
      value={value}
      onChangeValue={onChangeValue}
      title="Slice IDs:"
      placeholder="Search sliceIds"
    />
  )
})

SliceIdsFilter.displayName = 'SliceIdsFilter'
