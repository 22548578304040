import { z } from 'zod'

export interface ApiGetAssignedTeamMembersResponse {
  readonly assignedTeamMembers: Array<string>
  readonly errorMessage: string
}

export const apiGetAssignedTeamMembersResponseSchema = z.object({
  assignedTeamMembers: z.array(z.string()),
  errorMessage: z.string(),
})
