import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  card: {
    width: '500px',
    height: '400px',
    maxHeight: '90%',
    border: '1px solid #D1D1D1',
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'auto',
  },
  container: {
    width: '90%',
  },
  header: {
    paddingBlock: '20px',
  },
  headerIcon: {
    color: tokens.colorBrandForeground1,
    width: '26px',
    height: '26px',
  },
  headerTitle: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightBold,
    marginTop: '10px',
    lineHeight: tokens.lineHeightBase500,
    textAlign: 'center',
  },
  boldText: {
    fontWeight: tokens.fontWeightBold,
    marginTop: '10px',
    fontSize: tokens.fontSizeBase400,
  },
  bodyStep: {
    paddingTop: '20px',
  },
  step: {
    marginBlock: '6px',
  },
  step1: {
    display: 'flex',
  },
  step1Text: {
    marginRight: '4px',
    fontWeight: tokens.fontWeightSemibold,
  },
  link: {
    border: `1px solid ${tokens.colorBrandForeground1}`,
    borderRadius: '10px',
    paddingRight: '4px',
    ':hover': {
      backgroundColor: tokens.colorBrandBackground2Hover,
    },
  },
  bodyIcon: {
    color: tokens.colorBrandForeground1,
    marginInline: '4px',
  },
})
