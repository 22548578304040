import { TableCell, TableRow, Text } from '@fluentui/react-components'
import { countBy } from 'lodash'
import { useMemo } from 'react'
import { ErrorView } from '../../Error'
import { SymptomReportHealthLabel } from './SymptomReportHealthLabel'
import { SymptomReportLoadingIcon } from './SymptomReportLoadingIcon'

interface IProps {
  readonly ticketId: string
  readonly turnId: string
}

export function RowForSynthesisCitation({ ticketId, turnId: ticketMessageId }: IProps) {
  const ticket = application.store.use.getTicket(ticketId)
  const ticketConversationTurn = application.store.use.getTicketConversation(ticketId, ticketMessageId)
  const citations = application.store.use.getTicketChatTurnCitations(ticketId, ticketMessageId)

  const referenceText = useMemo(() => {
    const references = citations.data?.map((item) => item.type)
    const dict = countBy(references)

    const text = Object.keys(dict)
      .map((key) => `${key}: ${dict[key]}`)
      .join(', ')
      .trim()

    return text ? text : 'Empty'
  }, [citations.data])

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Synthesis</Text>
      </TableCell>
      <TableCell>
        {(() => {
          switch (ticket.status) {
            case 'waiting':
              return <SymptomReportLoadingIcon />
            case 'error':
              return <ErrorView type="inline" error={ticket.error} />
            case 'done': {
              switch (ticketConversationTurn.status) {
                case 'waiting':
                  return <SymptomReportLoadingIcon />
                case 'error':
                  return <ErrorView type="inline" error={ticketConversationTurn.error} />
                case 'done': {
                  return (
                    <>
                      {ticket.data.tag.isApology && (
                        <span>
                          <span>Response: isApology</span>
                          <SymptomReportHealthLabel status="error" text="true" />
                          <br />
                        </span>
                      )}
                      <span>[Citation type, Citation Count]: {referenceText}</span>
                    </>
                  )
                }
              }
            }
          }
        })()}
      </TableCell>
    </TableRow>
  )
}
