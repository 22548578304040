import { Spinner } from '@fluentui/react-components'
import { Column } from '../../../../components/Layout'

export function LoaderComponent() {
  return (
    <Column hAlign="center" vAlign="center">
      <Spinner labelPosition="below" label="Loading..." />
    </Column>
  )
}
