import { FC, memo } from 'react'
import { ICellBaseProps } from './types'
import { Text } from '@fluentui/react-components'

export const Tenants: FC<ICellBaseProps> = memo(({ data }) => {
  return (
    <Text size={300} weight="semibold">
      {data.impactedTenantsCount}
    </Text>
  )
})
Tenants.displayName = 'Tenants'
