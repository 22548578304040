import { z } from 'zod'

export const apiV2SymptomReportResponseSchema = z.record(z.unknown())

export const apiV2SymptomReportValueBooleanSchema = z.union([
  z.literal('True'),
  z.literal('False'),
  z.literal('On'),
  z.literal('Off'),
])

export const apiV2SymptomReportStringArraySchema = z.array(z.string())

export const apiV2SymptomReportNumberArraySchema = z.array(z.number())

export const apiV2SymptomReportTransactionItemSchema = z.record(z.unknown()).and(
  z.object({
    TransactionId: z.string(),
  }),
)

export const apiV2SymptomReportTransactionSchema = z.array(apiV2SymptomReportTransactionItemSchema)
