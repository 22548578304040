import { TelemetryScope } from '@copilot-dash/logger'
import { memo } from 'react'
import { IProps } from './ITicketSummaryPanel'
import { TicketSummaryPanelLayout } from './layout/TicketSummaryPanelLayout'
import { TicketSummaryPanelStoreProvider } from './store'

export const TicketSummaryPanel = memo((props: IProps) => {
  const {
    ticketId,
    isTicketSummaryPanelOpen,
    onDismissTicketSummaryPanel,
    ticketInfo,
    onSave = async () => {},
    productId,
    afterSavedCallback,
  } = props
  return (
    <TelemetryScope scope="TicketSummaryPanel" properties={{ ticketId }}>
      <TicketSummaryPanelStoreProvider key={`${ticketId}-${isTicketSummaryPanelOpen}`} ticketId={ticketId}>
        <TicketSummaryPanelLayout
          ticketId={ticketId}
          isTicketSummaryPanelOpen={isTicketSummaryPanelOpen}
          onDismissTicketSummaryPanel={onDismissTicketSummaryPanel}
          ticketInfo={ticketInfo}
          onSave={onSave}
          productId={productId}
          afterSavedCallback={afterSavedCallback}
        />
      </TicketSummaryPanelStoreProvider>
    </TelemetryScope>
  )
})

TicketSummaryPanel.displayName = 'TicketSummaryPanel'
