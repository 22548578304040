import {
  DefaultProductChannelMapping,
  P0_P1_AS_DEFAULT_SEARCH_PRODUCT_NAME_LIST,
  TeamViewSubMenuIds,
  TOP_ISSUES_SUPPORT_PRODUCT_NAME_LIST,
} from '@copilot-dash/domain'
import { Button, mergeClasses } from '@fluentui/react-components'
import {
  BranchCompareRegular,
  ClipboardTextLtrRegular,
  ListRegular,
  SparkleRegular,
  StarRegular,
} from '@fluentui/react-icons'
import * as React from 'react'
import { Path } from 'react-router-dom'
import { RouteLink, TeamRoute } from '../../../router'
import { ALL_TEAMS } from '../../../store/data/team/createTeamStore'
import { useTeamViewStore } from '../store'
import { CategoryTypeIds } from '../store/const'
import { useStyles } from './Categories.styles'
interface Category {
  id: TeamViewSubMenuIds
  name: string
  icon: JSX.Element
  visible: boolean
}

interface CategoriesProps {}

const Categories: React.FC<CategoriesProps> = React.memo(() => {
  const styles = useStyles()

  const lastSelectedTeam = useTeamViewStore((state) => state.teams.lastSelectedTeam)
  const lastSelectedTeamIsTheAllTeams = lastSelectedTeam === ALL_TEAMS

  const product = useTeamViewStore((state) => state.productName)
  const supportTopIssues = TOP_ISSUES_SUPPORT_PRODUCT_NAME_LIST.includes(product)
  const defaultP0P1 = P0_P1_AS_DEFAULT_SEARCH_PRODUCT_NAME_LIST.includes(product)

  const subMenuList: Category[] = [
    {
      id: TeamViewSubMenuIds.All,
      name: 'ALL DSATs',
      icon: <ListRegular />,
      visible: !lastSelectedTeamIsTheAllTeams,
    },
    {
      id: TeamViewSubMenuIds.UnRootCaused,
      name: 'Un-Root Caused DSATs',
      icon: <BranchCompareRegular />,
      visible: !lastSelectedTeamIsTheAllTeams,
    },
    {
      id: TeamViewSubMenuIds.TopIssues,
      name: 'Top Issues',
      icon: <SparkleRegular />,
      visible: supportTopIssues,
    },
    {
      id: TeamViewSubMenuIds.RootCauses,
      name: 'Root Causes',
      icon: <ClipboardTextLtrRegular />,
      visible: !lastSelectedTeamIsTheAllTeams,
    },
    {
      id: TeamViewSubMenuIds.SharedQueries,
      name: 'Shared Queries',
      icon: <StarRegular />,
      visible: true,
    },
  ].filter((v): v is Category => v.visible)

  const currentSelectedSubMenu = useTeamViewStore((state) => state.teams.selectedSubMenu)

  return lastSelectedTeam ? (
    <div>
      {subMenuList.map((subMenu) => {
        let path: Path | string = ''
        if (subMenu.id === TeamViewSubMenuIds.All) {
          path = TeamRoute.navigator.generatePath({
            product,
            subMenu: TeamViewSubMenuIds.All,
            teamName: lastSelectedTeam,
            ticketStatus: undefined,
            priority: undefined,
            channel: DefaultProductChannelMapping[product],
          })
        } else if (subMenu.id === TeamViewSubMenuIds.UnRootCaused) {
          path = TeamRoute.navigator.generatePath({
            product,
            subMenu: TeamViewSubMenuIds.UnRootCaused,
            teamName: lastSelectedTeam,
            ticketStatus: CategoryTypeIds.UnRootCaused,
            priority: defaultP0P1 ? ['0', '1'] : undefined,
            channel: DefaultProductChannelMapping[product],
          })
        } else if (subMenu.id === TeamViewSubMenuIds.TopIssues) {
          path = TeamRoute.navigator.generatePath({
            product,
            subMenu: TeamViewSubMenuIds.TopIssues,
            teamName: lastSelectedTeam,
            ticketStatus: undefined,
            channel: DefaultProductChannelMapping[product],
          })
        } else if (subMenu.id === TeamViewSubMenuIds.RootCauses) {
          path = TeamRoute.navigator.generatePath({
            product,
            subMenu: TeamViewSubMenuIds.RootCauses,
            teamName: lastSelectedTeam,
            channel: DefaultProductChannelMapping[product],
          })
        } else if (subMenu.id === TeamViewSubMenuIds.SharedQueries) {
          path = TeamRoute.navigator.generatePath({
            product,
            subMenu: TeamViewSubMenuIds.SharedQueries,
            teamName: lastSelectedTeam,
          })
        }

        return (
          <RouteLink path={path} key={subMenu.id}>
            <Button
              appearance="transparent"
              className={mergeClasses(styles.category, currentSelectedSubMenu === subMenu.id && styles.selected)}
              key={subMenu.id}
              icon={subMenu.icon}
              onClick={() => {
                Logger.telemetry.trackEvent('TeamView/ClickSubMenu', {
                  subMenu: subMenu.id,
                  product: product,
                  teamName: lastSelectedTeam,
                })
              }}
            >
              {subMenu.name}
            </Button>
          </RouteLink>
        )
      })}
    </div>
  ) : null
})
Categories.displayName = 'Categories'
export default Categories
