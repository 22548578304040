import { makeStyles } from '@fluentui/react-components'

export const usePrivacyModeClass = () => {
  const styles = useStyles()
  const privacyModeEnabled = app.settings.privacyModeEnabled.use()
  return privacyModeEnabled ? styles.privacy : ''
}

const useStyles = makeStyles({
  privacy: {
    filter: 'blur(4px)',
  },
})
