import { TableCell, TableRow, Text } from '@fluentui/react-components'
import { SymptomReportHealthLabel } from './SymptomReportHealthLabel'

interface IProps {
  readonly ticketId: string
}

export function RowForRetrievalLUDomain({ ticketId }: IProps) {
  const tag = application.store.use.getTicket(ticketId).data?.tag
  const luRequiredDomain = tag?.luRequiredDomain
  const sssTriggered = tag?.sssTriggered

  const renderChild = () => {
    if (luRequiredDomain && luRequiredDomain.length > 0) {
      return `LU Required domain: ${luRequiredDomain.join(', ')}`
    }

    return <span>No LURequiredDomain {sssTriggered && <SymptomReportHealthLabel status="error" />}</span>
  }

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Retrieval - LU Domain</Text>
      </TableCell>
      <TableCell>{renderChild()}</TableCell>
    </TableRow>
  )
}
