import { z } from 'zod'

export enum SearchTextPrefixType {
  All = 'All',
  Utterance = 'Utterance',
  Response = 'Response',
  Verbatim = 'Verbatim',
}

export const SearchTextPrefixTypeSchema = z.enum([
  SearchTextPrefixType.All,
  SearchTextPrefixType.Utterance,
  SearchTextPrefixType.Response,
  SearchTextPrefixType.Verbatim,
])
