import { PromiseSnapshot } from '@copilot-dash/core'
import { I3sLogData, I3sLogDataItem, IKustoGwsLogItem, ITicketSessionInteractionData } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { sortBy } from 'lodash'
import { IDashStoreContext } from '../../IDashStoreContext'
import { get3sGwsLog } from '../actions-kusto/get3sGwsLog'
import { getTicket } from '../actions-ticket/getTicket'
import { getTicketInteraction } from '../actions-ticket/getTicketInteraction'
import { getTicketSession } from '../actions-ticket/getTicketSession'
import { validateTicket3sOfflineDataTimeliness } from '../validators/validateTicket3sOfflineData'
import {
  validateOfflineDiagnosticCode,
  validateTicketDiagnosticDataFor3SSearch,
} from '../validators/validateTicketDiagnosticData'
import { get3sTransactionIds } from './get3sTransactionIds'

export function get3sLogs(context: IDashStoreContext, ticketId: string, turnId: string): PromiseSnapshot<I3sLogData> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.sssLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.sssLogs = snapshot
    },
    fetch: async () => {
      try {
        return await doFetch()
      } catch (e) {
        // Check if 3s is triggered
        const ticket = await getTicket(context, ticketId).promise
        if (!ticket.tag.sssTriggered) {
          throw TicketError.create('No3SDueToNotTriggered', { ticketId })
        }

        throw e
      }
    },
  })

  async function doFetch(): Promise<I3sLogData> {
    const turn = await getTicketInteraction(context, ticketId, turnId).promise
    const transactionIds = await get3sTransactionIds(context, ticketId, turnId).promise

    let gwsLogs: IKustoGwsLogItem[] = []
    let gwsLogsError: unknown
    try {
      gwsLogs = await get3sGwsLog(context, ticketId, turnId).promise
    } catch (e) {
      gwsLogsError = e
      if (!turn) {
        throw e
      }
    }

    const items = await Promise.all(
      transactionIds.map((transactionId) =>
        getItem(
          turn,
          transactionId,
          gwsLogs.find((log) => log.transactionId === transactionId),
          gwsLogsError,
        ),
      ),
    )

    const sortedItems = sortBy(items, (item) => {
      if (item.isQuery) return 0
      if (item.isAsyncQuery) return 1
      return 2
    })

    return {
      ticketId,
      ticketTurnId: turnId,
      ticketTurnIndex: turn.index,
      items: sortedItems,
    }
  }

  async function getItem(
    turn: ITicketSessionInteractionData,
    transactionId: string,
    log: IKustoGwsLogItem | undefined,
    logError: unknown,
  ): Promise<I3sLogDataItem> {
    const routeAction = log?.routeAction
    const isQuery = routeAction === 'query'
    const isAsyncQuery = routeAction === 'asyncQuery'.toLowerCase()

    const transactionLabels: string[] = []
    if (!isQuery && routeAction) {
      transactionLabels.push(routeAction)
    }

    const containsSearchMessageExtension = log?.requestedActions?.includes('SearchMessageExtension') ?? false
    if (containsSearchMessageExtension) {
      transactionLabels.push('SearchMessageExtension')
    }

    let hasOfflineData: boolean = false
    let offlineDataError: unknown
    if (!routeAction || isQuery) {
      try {
        hasOfflineData = await getHasOfflineData(turn, transactionId)
      } catch (e) {
        hasOfflineData = false
        offlineDataError = e
      }
    }

    let hasOnlineData: boolean = false
    let onlineDataError: unknown
    if (!routeAction || isQuery) {
      try {
        hasOnlineData = await getHasOnlineData(turn, transactionId)
      } catch (e) {
        hasOnlineData = false
        onlineDataError = e
      }
    }

    return {
      transactionId,
      transactionLabels,
      isQuery,
      isAsyncQuery,
      log,
      logError,
      hasOfflineData,
      offlineDataError,
      hasOnlineData,
      onlineDataError,
    }
  }

  async function getHasOfflineData(turn: ITicketSessionInteractionData, transactionId: string): Promise<boolean> {
    const data = turn.impressionIds.find((item) => item === transactionId)
    if (data) {
      return true
    }

    // Check offline diagnostic
    const session = await getTicketSession(context, ticketId).promise
    validateOfflineDiagnosticCode(ticketId, turnId, session)

    // Check 6 hours and 48 hours
    validateTicket3sOfflineDataTimeliness(ticketId, session.feedbackTime)

    // Throw error
    throw TicketError.create('No3sOffline', { ticketId, ticketMessageId: turnId })
  }

  async function getHasOnlineData(turn: ITicketSessionInteractionData, transactionId: string): Promise<boolean> {
    const data = turn.sssOnlineDataList.find((item) => item.transactionId === transactionId)
    if (data) {
      return true
    }

    // Check 3s diagnostic
    const session = await getTicketSession(context, ticketId).promise
    validateTicketDiagnosticDataFor3SSearch(ticketId, turnId, session)

    // Throw error
    throw TicketError.create('No3S', { ticketId, ticketMessageId: turnId })
  }
}
