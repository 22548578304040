import { IFilterOption } from '../../Filter/IFilterOption.types'

export const OptionSetPriority: IFilterOption[] = [
  {
    key: '0',
    text: 'P0',
  },
  {
    key: '1',
    text: 'P1',
  },
  {
    key: '2',
    text: 'P2',
  },
  {
    key: '3',
    text: 'P3',
  },
  {
    key: '4',
    text: 'P4',
  },
]
