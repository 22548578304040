import { Edge, Node } from '@xyflow/react'
import { CallFlowEdge, CallFlowNode } from '../store/IState'
import { generateEdge } from './generateEdge'

export function generateEdges(nodes: Node<CallFlowNode>[]): Edge<CallFlowEdge>[] {
  const edges: Edge<CallFlowEdge>[] = []

  for (let i = 0; i < nodes.length - 1; i++) {
    const source = nodes[i]
    const target = nodes[i + 1]
    if (source && target) {
      edges.push(generateEdge(source, target))
    }
  }

  return edges
}
