import { ungzip } from 'pako'
import { parseBase64ToBytes } from './parseBase64ToBytes'

export function parseBase64GzipStringToString(base64GzipString: string): string {
  // 1. Decode base64 string
  const binary = parseBase64ToBytes(base64GzipString)

  // 2. Decompress gzip data
  const decompressedData = ungzip(binary)

  // 3. Convert to string
  return new TextDecoder('utf-8').decode(decompressedData)
}
