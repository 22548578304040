import { TelemetryScope } from '@copilot-dash/logger'
import { ColumnSettingPanelLayout } from './layout/ColumnSettingPanelLayout'
import { ColumnKey } from '@copilot-dash/domain'

interface IProps {
  readonly isPanelOpen: boolean
  readonly onDismiss: () => void
  readonly NonRemovableColumns: ColumnKey[]
  readonly AllSupportedColumns: ColumnKey[]
  readonly selectedColumns: ColumnKey[]
  readonly setSelectedColumns: (columns: ColumnKey[]) => void
}

export function ColumnSettingPanel(props: IProps) {
  return (
    <TelemetryScope scope="ColumnSettingPanel">
      <ColumnSettingPanelLayout {...props} />
    </TelemetryScope>
  )
}
