import { makeStyles } from '@fluentui/react-components'

export const useStyles = makeStyles({
  card: {},
  waiting: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {
    padding: '0',
  },
  scrollbar: {
    height: '100%',
  },
})
