import { ITicketTurnTransactionHealthData } from '@copilot-dash/domain'
import { makeStyles, TableCell, TableRow, Text, tokens } from '@fluentui/react-components'

interface IProps {
  readonly index: number
  readonly data: ITicketTurnTransactionHealthData
}

export function RowFor3STransactionHeader({ index, data }: IProps) {
  const styles = useStyles()

  return (
    <TableRow className={styles.root}>
      <TableCell colSpan={2}>
        3S Transaction {index + 1} - {data.transactionId}{' '}
        {data.httpStatusCode === '500' && (
          <span style={{ marginLeft: '50px' }}>
            [3S Fail{' '}
            <Text weight="semibold" style={{ color: 'red' }}>
              Error
            </Text>
            ]
          </span>
        )}
      </TableCell>
    </TableRow>
  )
}

// TODO: Need to be removed and replaced with the global styles
const useStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralStroke2,
    fontSize: tokens.fontSizeBase300,
    ':hover': {
      backgroundColor: tokens.colorNeutralStroke2,
    },
    ':active': {
      backgroundColor: tokens.colorNeutralStroke2,
    },
  },
  loading: {
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  errorColor: {
    color: 'red',
  },

  warningColor: {
    color: 'orange',
  },

  okColor: {
    color: 'green',
  },
})
