import { makeStyles, Spinner, Text } from '@fluentui/react-components'
import { ErrorView } from '../../Error'
import { Column, Row } from '../../Layout'
import { LayoutForData } from './LayoutForData'

interface IProps {
  readonly ticketId: string
}

export function Layout({ ticketId }: IProps) {
  const styles = useStyles()
  const snapshot = app.store.use.getSymptomReport(ticketId)

  return (
    <Column className={styles.root} vAlign="stretch" hAlign="stretch">
      {(() => {
        switch (snapshot.status) {
          case 'waiting':
            return (
              <Row className={styles.waiting} vAlign="center" hAlign="center" fill>
                <Spinner label={<Text>Loading...</Text>} labelPosition="below" />
              </Row>
            )
          case 'error':
            return <ErrorView error={snapshot.error} />
          case 'done':
            return <LayoutForData data={snapshot.data} />
        }
      })()}
    </Column>
  )
}

const useStyles = makeStyles({
  root: {},
  waiting: {
    minHeight: '300px',
  },
})
