import { IDashStoreContext } from '../IDashStoreContext'

export async function deleteTicketComment(
  context: IDashStoreContext,
  ticketId: string,
  commentId: string,
): Promise<void> {
  return context.requestAnd({
    request: async () => {
      await context.api.logCollector.deleteComments(ticketId, commentId)
    },
    onSuccess(state, _) {
      const ticket = (state.tickets[ticketId] ??= {})
      const snapshot = ticket.comments
      if (!snapshot) {
        return
      }

      switch (snapshot.status) {
        case 'error':
          break
        case 'waiting':
          break
        case 'done':
          {
            const index = snapshot.data.findIndex((item) => item.commentId === commentId)
            if (index !== -1) {
              snapshot.data.splice(index, 1)
            }
          }
          break
      }
    },
  })
}
