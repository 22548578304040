import { z } from 'zod'

export interface ApiFilterTagItem {
  readonly groupNO: number
  readonly groupBit: number
  readonly isDisplay: boolean
  readonly originalValue?: string
  readonly filterCategory?: string
  readonly filterFieldName?: string
  readonly filterValue?: string
  readonly nameSpace?: string
  readonly source?: string
  readonly isActive: boolean
  readonly owner?: string
  readonly tagVersion?: string
  readonly tagDisplayValue?: string
  readonly createTime: string
  readonly lastUpdateTime: string
}

export interface ApiFilterTagListResponse {
  readonly filterTagList: Array<ApiFilterTagItem>
}

export const apiFilterTagItemSchema = z.object({
  groupNO: z.number(),
  groupBit: z.number(),
  isDisplay: z.boolean(),
  originalValue: z.string().optional(),
  filterCategory: z.string().optional(),
  filterFieldName: z.string().optional(),
  filterValue: z.string().optional(),
  nameSpace: z.string().optional(),
  source: z.string().optional(),
  isActive: z.boolean(),
  owner: z.string().optional(),
  tagVersion: z.string().optional(),
  tagDisplayValue: z.string().optional(),
  createTime: z.string(),
  lastUpdateTime: z.string(),
})

export const apiFilterTagListResponseSchema = z.object({
  filterTagList: z.array(apiFilterTagItemSchema),
})
