import { Spinner, makeStyles, tokens, Text, Button, Option } from '@fluentui/react-components'
import { useTeamViewStore } from '../../screens/team/store'
import { UserPhoto } from '../User/UserPhoto'
import { useDebounce } from '../../hooks/useDebounce'
import { CopilotDashPopover } from './CopilotDashPopover'
import { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { AsyncSnapshot, useAsyncLoader } from '@copilot-dash/core'
import { NullableOption, User } from '@copilot-dash/api'

interface IProps {
  emailAddress: string | undefined
  onChangeUser: (value: string | undefined) => void
  placeholder?: string
  showInput?: boolean
}

export const EmailTagPickerFilter = ({ emailAddress, onChangeUser, placeholder = 'Select', showInput }: IProps) => {
  const [selectedOption, setSelectedOption] = useState<string>('')
  const selectedOptions = useMemo(() => (selectedOption ? [selectedOption] : []), [selectedOption])
  const productId = useTeamViewStore((state) => state.computed.productId)
  const teamId = useTeamViewStore((state) => state.teams.computed.selectedTeamId)
  const [options, setOptions] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState<string>('')
  const [input, setInput] = useState<string>('')
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const debouncedInput = useDebounce(input.trim(), 300)

  const styles = useStyles()
  const [submit, snapshot] = useAsyncLoader(
    useCallback((input: string): Promise<User[]> | null => {
      if (input.trim().length === 0) {
        return null
      }

      return application.api.microsoftGraph.searchUsers(input).then((res) => res.value)
    }, []),
  )
  const submitRef = useRef(submit)
  submitRef.current = submit

  useEffect(() => {
    submitRef.current(debouncedInput)
  }, [debouncedInput])

  useEffect(() => {
    setSelectedOption(emailAddress ?? '')
  }, [emailAddress])

  const debouncedQuery = useDebounce(query, 300)

  useEffect(() => {
    if (debouncedQuery.trim().length > 0) {
      setQuery(debouncedQuery.trim())
    }
  }, [debouncedQuery])

  const defaultOptionsValue = useCallback(async () => {
    setLoading(true)
    if (productId && teamId) {
      const res = await application.store.actions.getAssignedTeamMembers(productId, teamId)
      const validEmail =
        res.assignedTeamMembers.length > 0 && res.assignedTeamMembers.filter((item) => isValidEmail(item))
      validEmail && setOptions(validEmail)
      setLoading(false)
    }
  }, [productId, teamId])

  useEffect(() => {
    if (isPopoverOpen) {
      defaultOptionsValue()
    }
  }, [isPopoverOpen, selectedOption, defaultOptionsValue])

  const onOptionSelect = (option: NullableOption<string> | undefined) => {
    if (option) {
      onChangeUser(option)
      setSelectedOption(option)
    } else {
      onChangeUser(undefined)
      setSelectedOption('')
    }
    setQuery('')
    setInput('')
  }
  function isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleSelectCleanClick = () => {
    onChangeUser(undefined)
    setSelectedOption('')
  }
  const defaultOptionsRender = (options: string[]) => {
    return options.length === 0 ? (
      loading ? (
        <Spinner />
      ) : (
        <Text>No results found</Text>
      )
    ) : (
      options.map((option) => {
        return (
          <Button
            key={option}
            icon={<UserPhoto userId={option} className={styles.avatar} />}
            appearance="subtle"
            className={styles.selectButton}
            onClick={() => onOptionSelect(option)}
          >
            <Text>{option}</Text>
          </Button>
        )
      })
    )
  }

  function filterOptions(options: string[], snapshot: AsyncSnapshot<User[]>) {
    switch (snapshot.status) {
      case 'none':
        return defaultOptionsRender(options)
      case 'waiting':
        return <Spinner />
      case 'done':
        if (snapshot.data.length === 0) {
          return (
            <Option key="freeform" disabled>
              No results found
            </Option>
          )
        }

        return snapshot.data.map((user) => {
          if (!user.userPrincipalName || !user.id) {
            return null
          }
          return (
            <Button
              key={user.id}
              icon={<UserPhoto userId={user.id} className={styles.avatar} />}
              appearance="subtle"
              onClick={() => onOptionSelect(user.userPrincipalName)}
              className={styles.selectButton}
            >
              <Text>{user.userPrincipalName}</Text>
            </Button>
          )
        })
      case 'error':
        return (
          <Option key="freeform" disabled>
            {`${snapshot.error}`}
          </Option>
        )
    }
  }
  const targetFinalOptions = filterOptions(options, snapshot)
  return (
    <CopilotDashPopover
      optionContent={targetFinalOptions}
      value={selectedOption}
      placeholder={placeholder}
      selectedOptions={selectedOptions}
      handleSelectCleanClick={handleSelectCleanClick}
      inputValue={input}
      setInputValue={setInput}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      showInput={showInput}
    />
  )
}

const useStyles = makeStyles({
  avatar: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    fontSize: tokens.fontSizeBase200,
  },
  displayName: {
    maxWidth: '130px',
    fontSize: tokens.fontSizeBase300,
  },

  optionList: {
    maxHeight: '50vh',
  },
  selectButton: {
    textAlign: 'left',
    justifyContent: 'left',
    fontWeight: tokens.fontWeightRegular,
  },
})
