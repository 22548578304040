import { useCallback, useRef, useState } from 'react'
import { AsyncSnapshot } from './AsyncSnapshot'
import { AsyncSnapshots } from './AsyncSnapshots'

export function useAsyncLoader<T, Args extends unknown[]>(
  callback: (...args: Args) => Promise<T> | T | null | undefined,
): [submit: (...args: Args) => void, snapshot: AsyncSnapshot<T>] {
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  const [snapshot, setSnapshot] = useState<AsyncSnapshot<T>>(AsyncSnapshots.none())
  const submitIdRef = useRef(0)

  const submit = useCallback((...args: Args) => {
    const submitId = ++submitIdRef.current
    const updateSnapshot = (newSnapshot: AsyncSnapshot<T>) => {
      setSnapshot((previous) => {
        if (submitId === submitIdRef.current && !AsyncSnapshots.isEqual(newSnapshot, previous)) {
          return newSnapshot
        } else {
          return previous
        }
      })
    }

    try {
      const result = callbackRef.current(...args)
      if (result instanceof Promise) {
        setSnapshot(AsyncSnapshots.waiting())
        result
          .then((data) => {
            updateSnapshot(AsyncSnapshots.done(data))
          })
          .catch((error) => {
            updateSnapshot(AsyncSnapshots.error(error))
          })
      } else if (result !== null && result !== undefined) {
        updateSnapshot(AsyncSnapshots.done(result))
      } else {
        updateSnapshot(AsyncSnapshots.none())
      }
    } catch (error) {
      updateSnapshot(AsyncSnapshots.error(error))
    }
  }, [])

  return [submit, snapshot]
}
