import * as React from 'react'
import { ThemeProvider } from '../../../theme/ThemeProvider'
import { RootScreenHeaderContent } from './RootScreenHeaderContent'

export const RootScreenHeader: React.FunctionComponent = () => {
  return (
    <ThemeProvider isDark>
      <RootScreenHeaderContent />
    </ThemeProvider>
  )
}
