import { Logger } from '@copilot-dash/logger'
import { useEffect, useState } from 'react'
import { ZodType } from 'zod'
import { ISetting } from './ISetting'

interface IParams<T> {
  readonly key: string
  readonly defaultValue: T
  readonly schema: ZodType<T>
  readonly keyPrefix?: string
}

export function createSetting<T>({
  key: keySuffix,
  defaultValue,
  schema,
  keyPrefix = 'dash_setting_',
}: IParams<T>): ISetting<T> {
  const key = keyPrefix + keySuffix
  const subscribers: Set<(value: T) => void> = new Set()
  let local: T = read() ?? defaultValue

  return {
    get value() {
      return local
    },
    set(value) {
      if (value !== local) {
        local = value
        write(value)
        publish(value)
      }
    },
    use,
  }

  function read(): T | null {
    const raw = localStorage.getItem(key)
    if (raw === null) {
      return null
    }

    try {
      return schema.parse(JSON.parse(raw))
    } catch (e) {
      return null
    }
  }

  function write(value: T): void {
    if (value === undefined || value === null) {
      localStorage.removeItem(key)
      return
    }

    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch (e) {
      Logger.trace.error(`Failed to write setting "${key}" to local storage`, e)
    }
  }

  function use(): T {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [state, setState] = useState(local)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      return subscribe(setState)
    }, [])

    return state
  }

  function subscribe(subscriber: (value: T) => void): () => void {
    subscribers.add(subscriber)

    return () => {
      subscribers.delete(subscriber)
    }
  }

  function publish(value: T): void {
    subscribers.forEach((subscriber) => subscriber(value))
  }
}
