import { BrowserAuthError, InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser'
import { Logger } from '@copilot-dash/logger'
import { DashAuthConstants } from '../DashAuthConstants'

export function acquireToken(client: IPublicClientApplication, scopes: Array<string>): Promise<string> {
  try {
    return acquireTokenSilent(scopes, true)
  } catch (e) {
    throw new Error('Failed to acquire token by scopes: ' + scopes + '. Cause: ' + e)
  }

  async function acquireTokenSilent(scopes: Array<string>, tryNextWhenFailed: boolean): Promise<string> {
    try {
      const result = await client.acquireTokenSilent({
        scopes: scopes,
      })

      return result.accessToken
    } catch (e) {
      if (tryNextWhenFailed && (e instanceof InteractionRequiredAuthError || e instanceof BrowserAuthError)) {
        Logger.trace.warn('Failed to acquire token by silent flow. Trying popup flow...', e)
        return acquireTokenRedirect(scopes)
      }

      throw new Error('Failed to acquire token by silent flow. Cause: ' + e)
    }
  }

  async function acquireTokenRedirect(scopes: Array<string>): Promise<string> {
    try {
      await client.acquireTokenRedirect({
        scopes: scopes,
      })
    } catch (e) {
      throw new Error('Failed to acquire token by redirect flow. Cause: ' + e)
    }

    await new Promise((resolve) => setTimeout(resolve, DashAuthConstants.REDIRECT_WAIT_TIME_MS))
    throw new Error('Failed to acquire token by redirect flow. Cause: Timeout')
  }
}
