import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { forwardRef } from 'react'
import { Row } from './Row'

interface IProps {
  readonly className?: string
  readonly children?: React.ReactNode
}

export const Body = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const styles = useStyles()

  return (
    <Row ref={ref} className={mergeClasses('Body', styles.body, props.className)} vAlign="stretch" hAlign="stretch">
      {props.children}
    </Row>
  )
})
Body.displayName = 'Body'

const useStyles = makeStyles({
  body: {
    flex: 1,
    minWidth: 0,
    minHeight: 0,

    '& > *': {
      flex: 1,
      minWidth: 0,
      minHeight: 0,
    },
  },
})
