import { Route } from 'react-router-dom'
import { createNavigator } from '../../navigator/createNavigator'
import { TicketDebugRouteMapper } from './TicketDebugRouteMapper'
import { TicketDebugRoutePage } from './TicketDebugRoutePage'

export function TicketDebugRoute() {
  return <Route id={ID} path={PATH} element={<TicketDebugRoutePage />} />
}

const ID = 'TicketDebugRoute'
const PATH = '/ticket/:ticketId/debug'
TicketDebugRoute.navigator = createNavigator(ID, PATH, TicketDebugRouteMapper)
