import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { getRawOcvTicket } from '../getRawOcvTicket'
import { getTicketVerbatimConversation } from '../getTicketVerbatimConversation'
import { validateTicketUserConsent } from '../validators/validateTicketUserConsent'
import { getTicketSession } from './getTicketSession'

export function getTicketUtterance(context: IDashStoreContext, ticketId: string): PromiseSnapshot<string> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.utterance
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.utterance = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<string> {
    if (context.enableV2Endpoint) {
      return fetchFromV2()
    }

    try {
      return await fetchFromConversation()
    } catch (error) {
      try {
        return await fetchFromOCV()
      } catch {
        throw error
      }
    }
  }

  async function fetchFromV2(): Promise<string> {
    const raw = await getRawV2Ticket(context, ticketId).promise
    const utterance = raw.utterance
    if (utterance?.trim()) {
      return utterance
    }

    throw TicketError.create('NoUtterance', { ticketId })
  }

  async function fetchFromConversation(): Promise<string> {
    const turn = await getTicketVerbatimConversation(context, ticketId).promise
    const utterance = turn.userMessage?.text
    if (utterance) {
      return utterance
    }

    // 1. User consent
    const session = await getTicketSession(context, ticketId).promise
    validateTicketUserConsent(ticketId, session)

    // 2. Unknown error
    throw TicketError.create('NoUtterance', { ticketId })
  }

  async function fetchFromOCV(): Promise<string> {
    const ocvTicket = await getRawOcvTicket(context, ticketId).promise
    const utterance = ocvTicket.AiContext?.Prompt
    if (utterance) {
      return utterance
    }

    throw TicketError.create('NoUtterance', { ticketId })
  }
}
