import { ITelemetryMetric } from '@copilot-dash/domain'
import { makeStyles, Table, TableBody, TableCell, TableRow, tokens } from '@fluentui/react-components'

interface IProps {
  readonly metric: ITelemetryMetric
}

export function MetricCardBodySummary({ metric }: IProps) {
  const styles = useStyles()

  return (
    <Table className={styles.root} size="small">
      <TableBody>
        <TableRow>
          <TableCell>Path</TableCell>
          <TableCell>{metric.path}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Latency</TableCell>
          <TableCell>{metric.latency}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Start Time</TableCell>
          <TableCell>{metric.startTime}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Status</TableCell>
          <TableCell>{metric.status}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

const useStyles = makeStyles({
  root: {
    border: `1px solid ${tokens.colorNeutralStroke2}`,
    borderRadius: tokens.borderRadiusMedium,

    '& td:first-child': {
      width: '120px',
    },
  },
})
