import { FC, useMemo } from 'react'
import { Column, Row } from '../../../../../components/Layout'
import { makeStyles, Text, tokens } from '@fluentui/react-components'
import { InsightsTag } from './InsightsTag'

interface IProps {
  tenantName: string
  enableTooltip?: boolean
}

export const CAPTenantTag: FC<IProps> = ({ tenantName, enableTooltip = false }) => {
  const styles = useStyles()
  const truncatedTag = useMemo(() => {
    return (
      <Row>
        <Text wrap={false} truncate className={styles.tenantName}>
          {tenantName}
        </Text>
      </Row>
    )
  }, [styles.tenantName, tenantName])

  const tooltipContent = useMemo(() => {
    return (
      <Column>
        <Text truncate size={200}>
          {tenantName}
        </Text>
      </Column>
    )
  }, [tenantName])

  return (
    <InsightsTag
      isTagClearable={false}
      tagKey={'CAPTenant'}
      toolTipContentValue={enableTooltip ? tooltipContent : undefined}
      truncatedTagValue={truncatedTag}
      className={styles.tagStyle}
    />
  )
}

const useStyles = makeStyles({
  tenantName: {
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightSemibold,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
  },
  tagStyle: {
    height: '26px',
  },
})
