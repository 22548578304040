import { ITeamRootCauseItem } from '@copilot-dash/domain'
import { memo } from 'react'
import { makeStyles, Text, tokens } from '@fluentui/react-components'
import { Row } from '../../../../../components/Layout'
import { ClipboardTextLtrFilled } from '@fluentui/react-icons'

export const Name = memo((props: { rowData: ITeamRootCauseItem }) => {
  const { rowData } = props
  const styles = useStyles()

  return (
    <Row vAlign="center">
      <ClipboardTextLtrFilled className={styles.icon} fontSize={20} />
      <Text size={400} weight="semibold" wrap className={styles.title}>
        {rowData.rootCauseTitle}
      </Text>
    </Row>
  )
})

Name.displayName = 'Name'

const useStyles = makeStyles({
  icon: {
    color: tokens.colorBrandForeground1,
    minWidth: '20px',
    marginRight: '8px',
  },
  title: {
    wordBreak: 'break-word',
  },
})
