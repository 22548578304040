import { DateObject } from 'react-multi-date-picker'

type DateValue = DateObject | Date | string | number

export function getOffsetMicrosecondsBetweenLocalAndTargetTimezone(timezone: string): number {
  const now = new Date()

  // Convert the current time to the selected time zone
  const localTimeString = now.toLocaleString('en-US')
  const targetTimeString = now.toLocaleString('en-US', { timeZone: timezone })
  const localTime = new Date(localTimeString)
  const targetTime = new Date(targetTimeString)

  // Calculate the offset in minutes
  const offsetMicrosecond = localTime.getTime() - targetTime.getTime()

  return offsetMicrosecond
}

export function isDateArray(value: unknown): value is DateValue[] {
  return Array.isArray(value)
}

export function toMicroseconds(value: DateValue): number {
  return value instanceof DateObject
    ? value.valueOf()
    : value instanceof Date
      ? value.getTime()
      : typeof value === 'string'
        ? Date.parse(value).valueOf()
        : value
}

export function toDate(value: DateValue): Date {
  return value instanceof DateObject
    ? value.toDate()
    : value instanceof Date
      ? value
      : typeof value === 'string'
        ? new Date(value)
        : new Date(value)
}
