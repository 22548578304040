import { IDashAuth } from '@copilot-dash/auth'
import { Router, RouterNavigateOptions, To } from '@remix-run/router'
import { createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import { RootRoute } from './routes/RootRoute'

export function createAppRouter(auth: IDashAuth) {
  let instance: Router | undefined
  const getInstance = () => {
    return (instance ??= createBrowserRouter(createRoutesFromElements(RootRoute(auth))))
  }

  return {
    get instance() {
      return getInstance()
    },
    get state() {
      return getInstance().state
    },
    navigate(to: To, opts?: RouterNavigateOptions): Promise<void> {
      return getInstance().navigate(to, opts)
    },
    openInNewTab(url: string): void {
      window.open(url, '_blank', 'noopener noreferrer')
    },
  }
}

export type IAppRouter = ReturnType<typeof createAppRouter>
