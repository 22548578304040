import { IDashStoreContext } from '../IDashStoreContext'
import { IBatchTicketCustomTagsType } from '@copilot-dash/domain'

export async function getBatchTicketsCustomTags(
  context: IDashStoreContext,
  ticketIds: string[],
): Promise<IBatchTicketCustomTagsType> {
  const response = await context.api.logCollector.getBatchTicketsCustomTags({ TicketIdList: ticketIds })
  return response.ticketCustomTags ?? {}
}
