import { ITelemetryMetric } from '@copilot-dash/domain'
import { Button } from '@fluentui/react-components'
import { FullScreenMaximizeRegular } from '@fluentui/react-icons'
import { MonacoJsonViewDialog } from '../../../../../../components/Monaco/MonacoJsonViewDialog'
import { MouseEventHandler } from 'react'

interface IProps {
  readonly metric: ITelemetryMetric
}

export function MetricCardHeaderOpenJson({ metric }: IProps) {
  const handleOnClick: MouseEventHandler = (event) => {
    event.stopPropagation()

    app.dialog.open({
      content: <MonacoJsonViewDialog data={metric.raw} />,
      title: true,
    })
  }

  return (
    <Button
      appearance="subtle"
      size="small"
      icon={<FullScreenMaximizeRegular />}
      title="Show raw JSON"
      onClick={handleOnClick}
    />
  )
}
