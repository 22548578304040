import { z } from 'zod'

export interface ApiRemoveTicketFromTopIssueRequest {
  readonly ClusterBatchId: string
  readonly ClusterIssueId: string
  readonly FeedbackId: string
}

export interface ApiRemoveTicketFromTopIssueResponse {
  readonly status: string
  readonly errorMessage: string
}

export const ApiRemoveTicketFromTopIssueResponseSchema = z.object({
  status: z.string(),
  errorMessage: z.string(),
})
