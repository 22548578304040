import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { createLoginScreenActions } from './createLoginScreenActions'
import { createLoginScreenState } from './createLoginScreenState'
import { ILoginScreenActions } from './ILoginScreenActions'
import { ILoginScreenState } from './ILoginScreenState'

export const useLoginScreenStore = create<ILoginScreenState & ILoginScreenActions>()(
  immer((set, get) => ({
    ...createLoginScreenState(),
    ...createLoginScreenActions(set, get),
  })),
)
