import { makeStyles } from '@fluentui/react-components'
import { useCallback, useMemo } from 'react'
import { Base16Theme, Theme } from 'react-base16-styling'
import { JSONTree, LabelRenderer, ShouldExpandNodeInitially } from 'react-json-tree'
import { useThemeMode } from '../../theme/useThemeMode'

interface IProps {
  readonly data: unknown
  readonly expandAll?: boolean
  readonly expandLevel?: number
  readonly hideRoot?: boolean
}

/**
 * https://github.com/reduxjs/redux-devtools/blob/75322b15ee7ba03fddf10ac3399881e302848874/src/react/themes/brewer.js
 */
export function JsonView({ data, expandAll = false, expandLevel = 0, hideRoot = false }: IProps) {
  const styles = useStyles()
  const themeMode = useThemeMode()

  const shouldExpandNodeInitially: ShouldExpandNodeInitially | undefined = useMemo(() => {
    if (expandAll) {
      return () => true
    }

    if (expandLevel === 0) {
      return () => false
    }

    if (expandLevel) {
      return (keyPath, data, level) => level < expandLevel
    }

    return undefined
  }, [expandAll, expandLevel])

  const labelRenderer: LabelRenderer = useCallback((keyPath, nodeType, expanded, expandable) => {
    const key = keyPath[0]
    return <span>{`${key}:`} </span>
  }, [])

  const theme = useMemo((): Theme => {
    return {
      extend: DEFAULT_THEME,
      valueLabel: {
        textDecoration: 'underline',
      },
    }
  }, [])

  return (
    <div className={styles.root}>
      <JSONTree
        data={data}
        theme={theme}
        hideRoot={hideRoot}
        invertTheme={themeMode === 'dark' ? false : true}
        labelRenderer={labelRenderer}
        shouldExpandNodeInitially={shouldExpandNodeInitially}
      />
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',

    '& > ul': {
      margin: '0 !important',
      backgroundColor: 'transparent !important',
    },
  },
})

const DEFAULT_THEME: Base16Theme = {
  scheme: 'CopilotDash',
  author: 'CopilotDash',
  base00: 'transparent',
  base01: '#2e2f30',
  base02: '#515253',
  base03: '#737475',
  base04: '#959697',
  base05: '#b7b8b9',
  base06: '#dadbdc',
  base07: '#fcfdfe',
  base08: '#e31a1c',
  base09: '#e6550d',
  base0A: '#dca060',
  base0B: '#31a354',
  base0C: '#80b1d3',
  base0D: '#3182bd',
  base0E: '#756bb1',
  base0F: '#b15928',
}
