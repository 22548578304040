import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  triggerContainer: {
    minWidth: 'auto',
    ':hover': {
      backgroundColor: tokens.colorNeutralForeground3Hover,
    },
  },
  avatarIcon: {
    width: '30px',
    height: '30px',
    ...shorthands.borderRadius('50%'),
  },
  panelLayout: {
    minWidth: '120px',
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.padding(0),
  },
  msBrandText: {
    color: tokens.colorNeutralForeground1,
    marginLeft: '14px',
  },
  logout: {
    ...shorthands.border(0),
    ...shorthands.padding('15px', '14px'),
  },
  panelUserContainer: {
    ...shorthands.padding(0, '16px'),
  },
  panelAvatar: {
    width: '88px',
    height: '88px',
    ...shorthands.margin('20px', 0),
  },
  panelUserDetails: {
    ...shorthands.padding('0', '16px'),
  },
  panelAddUser: {
    backgroundColor: tokens.colorNeutralBackground3,
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground3Hover,
    },
  },
  panelAddUserImage: {
    width: '25px',
    height: '25px',
    color: tokens.colorNeutralForeground4,
  },
  addUserContainer: {
    width: '40px',
    height: '40px',
    ...shorthands.margin('10px', '12px', '10px', '16px'),
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    ...shorthands.border('1px', 'solid', tokens.colorNeutralForeground4),
    ...shorthands.borderRadius('50%'),
  },
  addUserButton: {
    width: '100%',
  },
})
