import { useMemo } from 'react'
import { AnyDataStore } from '../../../../AnyDataStore'
import { AnyObject } from '../types/AnyObject'
import { generateObjects } from '../utils/generateObjects'

export function useAnyTableData(): AnyObject[] {
  const data = AnyDataStore.use((state) => state.data)

  return useMemo(() => {
    return generateObjects(data)
  }, [data])
}
