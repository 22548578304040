import { useState, FC, useMemo, useEffect, useCallback } from 'react'
import { Button, Option, Persona, Spinner } from '@fluentui/react-components'
import { IFilterOption } from './IFilterOption.types'
import { AsyncSnapshot } from '@copilot-dash/core'
import { Column, Spacer } from '../Layout'
import { CheckmarkRegular, PeopleListRegular } from '@fluentui/react-icons'
import { CopilotDashPopover } from './CopilotDashPopover'
import { useStyles } from './CopilotDashDropdown.styles'

interface IProps {
  defaultSelectedOption: string[] | undefined
  onChangeFilter: (item: string[] | undefined) => void
  snapshot: AsyncSnapshot<IFilterOption[]>
  customSearchOptionHint?: string
  keyword: string | undefined
  setkeyword: (value: string) => void
  className?: string
  supportCustomSearchOption?: boolean
  placeholder?: string
  showInput?: boolean
}

export const CopilotDashSearchableDropdown: FC<IProps> = ({
  defaultSelectedOption,
  onChangeFilter,
  snapshot,
  keyword = '',
  setkeyword,
  customSearchOptionHint = '',
  supportCustomSearchOption = false,
  placeholder = 'Select',
  showInput,
}) => {
  const styles = useStyles()
  const filterOptions: IFilterOption[] = useMemo(() => {
    switch (snapshot.status) {
      case 'done':
        return snapshot.data
      default:
        return []
    }
  }, [snapshot.data, snapshot.status])

  const [selectedOptionValue, setSelectedOptionValue] = useState<string[]>([])
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)

  const [value, setValue] = useState<string>('')

  useEffect(() => {
    if (defaultSelectedOption && defaultSelectedOption.length > 0) {
      setSelectedOptionValue(defaultSelectedOption)
      const defaultOptionsValue = filterOptions.find((option) => option.key === keyword)?.text ?? keyword
      setValue(defaultOptionsValue)
    } else {
      setSelectedOptionValue([])
      setValue('')
    }
  }, [defaultSelectedOption, filterOptions, keyword])

  const onOptionSelect = useCallback(
    (option: IFilterOption) => {
      setSelectedOptionValue([option.key])
      onChangeFilter([option.key])
      setValue(option.text)
      setIsPopoverOpen(false)
    },
    [onChangeFilter],
  )

  const handleSelectCleanClick = () => {
    setSelectedOptionValue([])
    onChangeFilter([])
  }

  const renderContent = useCallback(
    (snapshot: AsyncSnapshot<IFilterOption[]>) => {
      switch (snapshot.status) {
        case 'none':
          return (
            <Button
              icon={<Spacer width="26px" />}
              appearance="subtle"
              onClick={() => onOptionSelect({ key: keyword, text: keyword })}
              className={styles.selectButton}
              disabled={!supportCustomSearchOption}
            >
              Search for {customSearchOptionHint}: &quot;{keyword}&quot;
            </Button>
          )
        case 'waiting':
          return <Spinner />
        case 'done':
          return (
            <Column>
              {keyword && (
                <Button
                  icon={<Spacer width="26px" />}
                  appearance="subtle"
                  onClick={() => onOptionSelect({ key: keyword, text: keyword })}
                  className={styles.selectButton}
                  disabled={!supportCustomSearchOption}
                >
                  Search for {customSearchOptionHint}: &quot;{keyword}&quot;
                </Button>
              )}
              {snapshot.data.map((option) => (
                <Button
                  key={option.key}
                  icon={selectedOptionValue.includes(option.key) ? <CheckmarkRegular /> : <Spacer width="26px" />}
                  appearance="subtle"
                  onClick={() => onOptionSelect(option)}
                  className={styles.selectButton}
                  title={option.text}
                >
                  <Persona
                    avatar={{ color: 'colorful', 'aria-hidden': true }}
                    name={option.text}
                    secondaryText={option.key}
                  />
                </Button>
              ))}
            </Column>
          )
        case 'error':
          return (
            <Option key="freeform" disabled>
              {`${snapshot.error}`}
            </Option>
          )
      }
    },
    [
      customSearchOptionHint,
      keyword,
      selectedOptionValue,
      styles.selectButton,
      supportCustomSearchOption,
      onOptionSelect,
    ],
  )

  const targetFinalOptions = renderContent(snapshot)
  return (
    <CopilotDashPopover
      optionContent={targetFinalOptions}
      value={value}
      placeholder={placeholder}
      selectedOptions={selectedOptionValue}
      handleSelectCleanClick={handleSelectCleanClick}
      inputValue={keyword}
      setInputValue={setkeyword}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      showInput={showInput}
      prefixIcon={<PeopleListRegular fontSize={16} />}
    />
  )
}
