import {
  Dialog,
  DialogSurface,
  DialogTrigger,
  Button,
  DialogActions,
  DialogBody,
  DialogTitle,
  Spinner,
} from '@fluentui/react-components'
import { IQuery } from '@copilot-dash/domain'
import { FC, memo, useState, useCallback } from 'react'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { useToast } from '../../../../hooks/useToast'

interface IProps {
  open: boolean
  onClose: () => void
  query: IQuery
}

export const DeleteQueryDialog: FC<IProps> = memo(({ open, onClose, query }) => {
  const toast = useToast()

  const [deleting, setDeleting] = useState(false)

  const onOk = useCallback(
    async (data: IQuery) => {
      return application.store.actions
        .deleteQuery(data)
        .then(() => {
          toast.showSuccess('Query deleted successfully.', '', 'top')
          onClose()
        })
        .catch((err) => {
          toast.showError('Failed to delete', err.message)
        })
    },
    [toast, onClose],
  )

  return (
    <Dialog
      open={open}
      onOpenChange={(_, data) => {
        if (!data.open) {
          onClose()
        }
      }}
      modalType="alert"
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle
            action={
              <DialogTrigger action="close">
                <Button
                  appearance="subtle"
                  aria-label="close"
                  icon={<Dismiss24Regular />}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    onClose()
                  }}
                />
              </DialogTrigger>
            }
          >
            Do you want to delete the query: {query.title} ?
          </DialogTitle>

          <DialogActions position="end">
            <Button
              disabled={deleting}
              appearance="primary"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setDeleting(true)
                onOk(query).finally(() => setDeleting(false))
              }}
              icon={deleting ? <Spinner size="extra-tiny" /> : null}
            >
              OK
            </Button>
            <DialogTrigger disableButtonEnhancement>
              <Button
                appearance="secondary"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  onClose()
                }}
              >
                Cancel
              </Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
})

DeleteQueryDialog.displayName = 'DeleteQueryDialog'
