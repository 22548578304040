import { Switch, SwitchProps, Tooltip } from '@fluentui/react-components'
import { ReactNode } from 'react'

export function HeaderPrivacySwitch(): ReactNode {
  const checked = app.settings.privacyModeEnabled.use()

  const onChange: SwitchProps['onChange'] = (ev) => {
    Logger.telemetry.trackEvent('SwitchPrivacy', { checked: ev.currentTarget.checked })
    app.settings.privacyModeEnabled.set(ev.currentTarget.checked)
  }

  return (
    <Tooltip content="Mask sensitive data before share screen" relationship="label">
      <Switch label="Mask" onChange={onChange} checked={checked} />
    </Tooltip>
  )
}
