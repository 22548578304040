import { DashRing } from '@copilot-dash/core'

const DOMAIN_DEV = 'localhost'
const DOMAIN_TEST = 'copilotdash-test.microsoft.com'
const DOMAIN_SDF = 'copilotdash-sdf.microsoft.com'
const DOMAIN_MSIT = 'copilotdash.microsoft.com'

export function createAppEnvRing(): DashRing {
  if (import.meta.env.DEV) {
    return 'dev'
  }

  if (window.location.hostname.includes(DOMAIN_DEV)) {
    return 'dev'
  }

  if (window.location.hostname.includes(DOMAIN_TEST)) {
    return 'test'
  }

  if (window.location.hostname.includes(DOMAIN_SDF)) {
    return 'sdf'
  }

  if (window.location.hostname.includes(DOMAIN_MSIT)) {
    return 'msit'
  }

  return 'unknown'
}
