import { SymptomReportItem } from '@copilot-dash/domain'
import { TableCell } from '@fluentui/react-components'
import { useMemo } from 'react'

interface IProps {
  readonly item: Extract<SymptomReportItem, { valueType: 'NumberArray' }>
}

export function ItemCellValueForNumberArray({ item }: IProps) {
  const displayValue = useMemo(() => item.value.join(', '), [item])

  return <TableCell title={displayValue}>{displayValue}</TableCell>
}
