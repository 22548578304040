import { ApiV2Ring } from '@copilot-dash/api'
import { DefaultTriggeredSkillList, SearchTextPrefixType, TicketRingType } from '@copilot-dash/domain'
import { Times } from '@copilot-dash/core'
import { IQueryBuilderArgs } from './AiSearchQueryBuilder.types'
import { ISearchTicketOptions } from './SearchTicketAction.types'

export class AiSearchQueryBuilder {
  buildQuery(options: ISearchTicketOptions): { searchQuery?: string; filterQuery: string } {
    const args = this.prepareParam(options)
    const encodeArg = this.encodeParam(args)

    const searchQuery = this.createSearchQuery(encodeArg)
    const filterQuery = this.createFilter(encodeArg)
    return { searchQuery, filterQuery }
  }

  private prepareParam = (options: ISearchTicketOptions): IQueryBuilderArgs => {
    const { from, to } = Times.formatTimeRange(options.range ?? options.defaultRange, { timezone: 'UTC' })
    const triggeredSkills = options.triggeredSkill
      ?.map((val) => {
        const triggeredSkill = DefaultTriggeredSkillList.find((skill) => skill.key === val)
        return triggeredSkill ? triggeredSkill.options : []
      })
      .flat()

    const hasErrorMessagesConditions = options.hasErrorMessages
      ?.map((condition) => {
        return condition.includes('&') ? condition.split('&') : condition
      })
      .flat()

    const request: IQueryBuilderArgs = {
      userId: options.userId,
      tenantId: options.tenantIds,
      from: from,
      to: to,
      emotionType: options.thumbs?.length === 1 ? options.thumbs[0] : undefined,
      hasUserConsent:
        options.hasUserConsent?.length === 1 ? (options.hasUserConsent[0]?.startsWith('!') ? false : true) : undefined,
      clientName: options.client,
      scenarioName: options.channel,
      ring: options.ring?.map((ring: TicketRingType) => this.convertTicketRingTypeToApiV2Ring(ring)),

      verbatim:
        options.searchTextPrefix === SearchTextPrefixType.Verbatim ||
        options.searchTextPrefix === SearchTextPrefixType.All
          ? options.searchText
          : undefined,
      utterance:
        options.searchTextPrefix === SearchTextPrefixType.Utterance ||
        options.searchTextPrefix === SearchTextPrefixType.All
          ? options.searchText
          : undefined,
      response:
        options.searchTextPrefix === SearchTextPrefixType.Response ||
        options.searchTextPrefix === SearchTextPrefixType.All
          ? options.searchText
          : undefined,
      customTags: options.customTags,
      tags: [
        ...(options.promptLanguages && options.promptLanguages.length ? [options.promptLanguages.join('|')] : []),
        ...(options.groundedPrompts?.length ? [options.groundedPrompts.join('|')] : []),
        ...(options.isApology?.length ? [options.isApology.join('|')] : []),
        ...(options.hasVerbatim?.length ? [options.hasVerbatim.join('|')] : []),
        ...(options.customerTypes?.length ? [options.customerTypes.join('|')] : []),
        ...(options.invocationType?.length ? [options.invocationType.join('|')] : []),
        ...(triggeredSkills ?? []),
        ...(hasErrorMessagesConditions ?? []),
        ...(options.hasCitation?.length ? [options.hasCitation.join('|')] : []),
        ...(options.hasEntityCard?.length ? [options.hasEntityCard.join('|')] : []),
        ...(options.hitAvalon?.length ? [options.hitAvalon.join('|')] : []),
        ...(options.isSTCAChina?.length ? [options.isSTCAChina.join('|')] : []),
        ...(options.isTopi18N?.length ? [options.isTopi18N.join('|')] : []),
        ...(options.responseHeroType?.length ? [options.responseHeroType.join('|')] : []),
        ...(options.responseLinkType?.length ? [options.responseLinkType.join('|')] : []),
        ...(options.semanticSearchType?.length ? [options.semanticSearchType.join('|')] : []),
        ...(options.bizchatScenario?.length ? [options.bizchatScenario.join('|')] : []),
        ...(options.experienceType?.length ? [options.experienceType.join('|')] : []),
        ...(options.hasConnector?.length ? [options.hasConnector.join('|')] : []),
        ...(options.hasGPTExtension?.length ? [options.hasGPTExtension.join('|')] : []),
        ...(options.hasMessageExtension?.length ? [options.hasMessageExtension.join('|')] : []),
        ...(options.hasCopilotExtensionIds?.length ? [options.hasCopilotExtensionIds.join('|')] : []),
        ...(options.errorCode?.length ? [options.errorCode.join('|')] : []),
        ...(options.isGCIntent?.length ? [options.isGCIntent.join('|')] : []),
        ...(options.hasConnectorResult?.length ? [options.hasConnectorResult.join('|')] : []),
        ...(options.agentTypes?.length ? [options.agentTypes.join('|')] : []),
        ...(options.appTypes?.length ? [options.appTypes.join('|')] : []),
      ],
    }

    return request
  }

  private encodeParam = (args: IQueryBuilderArgs): IQueryBuilderArgs => {
    const escapeFilter = (text: string | undefined): string | undefined => {
      return text ? this.escapeSpecialCharactersInFilter(text) : undefined
    }

    const escapeArray = (arr: string[] | undefined): string[] | undefined => {
      return arr
        ? arr.map((item) => escapeFilter(item)).filter((item): item is string => item !== undefined)
        : undefined
    }

    const encodedRequest: IQueryBuilderArgs = {
      ...args,
      verbatim: args.verbatim ? this.escapeSpecialCharactersInSearchQuery(args.verbatim) : undefined,
      utterance: args.utterance ? this.escapeSpecialCharactersInSearchQuery(args.utterance) : undefined,
      response: args.response ? this.escapeSpecialCharactersInSearchQuery(args.response) : undefined,
      customTags: escapeArray(args.customTags),
      tags: escapeArray(args.tags),
    }

    return encodedRequest
  }

  /**
   * https://learn.microsoft.com/en-us/azure/search/query-lucene-syntax
   */
  private createSearchQuery = (args: IQueryBuilderArgs): string | undefined => {
    const searchTerms: string[] = []
    // Add searchable fields to searchQuery
    if (args.utterance) searchTerms.push(`utterance:"${args.utterance}"`)
    if (args.verbatim) searchTerms.push(`verbatim:"${args.verbatim}"`)
    if (args.response) searchTerms.push(`response:"${args.response}"`)

    return searchTerms.length > 0 ? searchTerms.join(' OR ') : undefined
  }

  /**
   * https://learn.microsoft.com/en-us/azure/search/search-query-odata-filter
   */
  private createFilter = (args: IQueryBuilderArgs): string => {
    const filters: string[] = []
    const addFilter = (condition: boolean, filter: string) => {
      if (condition) filters.push(filter)
    }

    // Add other fields to filterQuery
    addFilter(!!args.userId, `userId eq '${args.userId}'`)
    addFilter(!!args.tenantId?.length, `search.in(tenantId, '${args.tenantId?.join(',')}', ',')`)
    addFilter(!!args.from, `createDateTime ge ${args.from}`)
    addFilter(!!args.to, `createDateTime le ${args.to}`)
    addFilter(!!args.emotionType, `emotionType eq '${args.emotionType}'`)
    addFilter(args.hasUserConsent !== undefined, `hasUserConsent eq ${args.hasUserConsent}`)
    addFilter(!!args.clientName?.length, `search.in(clientName, '${args.clientName?.join(',')}', ',')`)
    addFilter(
      !!args.scenarioName?.length,
      `(${args.scenarioName?.map((name) => `scenarioName eq '${name}'`).join(' or ')})`,
    )
    addFilter(!!args.ring?.length, `search.in(ring, '${args.ring?.join(',')}', ',')`)
    addFilter(!!args.customTags?.length, `search.in(customTags, '${args.customTags?.join(',')}', ',')`)
    //Tag Based Filter
    addFilter(
      !!args.tags?.length,
      `(${args.tags
        ?.map((tag) => {
          if (tag.startsWith('!')) {
            return `not tags/any(t: t eq '${tag.substring(1)}')`
          } else if (tag.includes('|')) {
            return `tags/any(t: search.in(t, '${tag}', '|'))`
          } else {
            return `tags/any(t: t eq '${tag}')`
          }
        })
        .join(' and ')})`,
    )

    return filters.join(' and ')
  }

  private convertTicketRingTypeToApiV2Ring(ticketRingType: TicketRingType): ApiV2Ring {
    switch (ticketRingType) {
      case TicketRingType.MSIT:
        return ApiV2Ring.MSIT
      case TicketRingType.SDFV2:
        return ApiV2Ring.SDFv2
      case TicketRingType.SIP:
        return ApiV2Ring.SIP
      case TicketRingType.WW:
        return ApiV2Ring.WW
      case TicketRingType.DONMT:
        return ApiV2Ring.DONMT
      case TicketRingType.TDF:
        return ApiV2Ring.TDF
      default:
        return ApiV2Ring.Unknown
    }
  }

  /**
   * https://learn.microsoft.com/en-us/azure/search/query-lucene-syntax#escaping-special-characters
   */
  private escapeSpecialCharactersInSearchQuery(text: string): string {
    // Escape special characters in search query
    const specialCharacters = /[+\-&|!(){}[\]^"~*?:\\/]/g
    return text.replace(specialCharacters, '\\$&')
  }

  /**
   * https://learn.microsoft.com/en-us/azure/search/query-odata-filter-orderby-syntax#escaping-special-characters-in-string-constants
   */
  private escapeSpecialCharactersInFilter(text: string): string {
    // Escape single quotes by doubling them
    return text.replace(/'/g, "''")
  }
}
