import { ITopIssueList } from '@copilot-dash/domain'
import { IDashStoreContext } from '../IDashStoreContext'
import { TeamViewDataConverter } from './converters/TeamViewDataConverter'
import { PromiseSnapshot } from '@copilot-dash/core'

export async function getTeamIssueClusteringInfoList(
  context: IDashStoreContext,
  batchId: string,
  teamId: number,
): Promise<ITopIssueList> {
  const response = await context.api.logCollector.getIssueClusteringInfoList({
    BatchId: batchId,
    TeamId: teamId,
  })
  return TeamViewDataConverter.fromApiTopIssueClusteringInfoListResponse(response)
}

export function getOrFetchTeamIssueClusteringInfoList(
  context: IDashStoreContext,
  batchId: string,
  teamId: number,
): PromiseSnapshot<ITopIssueList> {
  return context.getOrFetch({
    get: (state) => {
      return state.topIssue.topIssueListMap[`${batchId}-${teamId}`]
    },
    set: (state, snapshot) => {
      state.topIssue.topIssueListMap[`${batchId}-${teamId}`] = snapshot
    },
    fetch: () => {
      return getTeamIssueClusteringInfoList(context, batchId, teamId)
    },
  })
}
