import { Button, CounterBadge } from '@fluentui/react-components'
import { ChatMultipleRegular, HistoryRegular, InfoRegular } from '@fluentui/react-icons'
import { Row } from '../../../../components/Layout'
import { TicketScreenStore } from '../../TicketScreenStore'
import { TicketScreenDrawerType } from '../../store/IState'

interface IProps {
  readonly type: TicketScreenDrawerType
}

export function TicketScreenBarTicketDrawerTrigger({ type }: IProps) {
  const actions = TicketScreenStore.useActions()

  const handleOnClick = () => {
    actions.openDrawer(type)
  }

  switch (type) {
    case TicketScreenDrawerType.TicketContext:
      return <Button size="medium" onClick={handleOnClick} title="Ticket Context" icon={<InfoRegular />} />
    case TicketScreenDrawerType.DiscussionPanel:
      return (
        <DiscussionPanelCounter>
          <Button size="medium" onClick={handleOnClick} title="Discussion" icon={<ChatMultipleRegular />} />
        </DiscussionPanelCounter>
      )
    case TicketScreenDrawerType.ActivityHistory:
      return <Button size="medium" onClick={handleOnClick} title="History" icon={<HistoryRegular />} />
  }
}

function DiscussionPanelCounter({ children }: { children: React.ReactNode }) {
  return (
    <Row style={{ position: 'relative' }}>
      {children}
      <DiscussionPanelCounterIndicator />
    </Row>
  )
}

function DiscussionPanelCounterIndicator() {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const snapshot = app.store.use.getTicketCommentCount(ticketId)

  switch (snapshot.status) {
    case 'waiting':
    case 'error':
      return null
    case 'done': {
      if (snapshot.data === 0) {
        return null
      }

      return (
        <CounterBadge
          count={snapshot.data}
          appearance="filled"
          size="small"
          style={{ position: 'absolute', top: '-10px', right: '-7px' }}
        />
      )
    }
  }
}
