import { TelemetryScope } from '@copilot-dash/logger'
import { useEffect } from 'react'
import { SettingsScreenLayout } from './layout/SettingsScreenLayout'
import { SettingsScreenTab, useSettingsScreenStore } from './store'

interface IProps {
  readonly tab?: SettingsScreenTab
}

export function SettingsScreen(props: IProps) {
  useEffect(() => {
    if (props.tab) {
      useSettingsScreenStore.setState({ selectedTab: props.tab })
    }
  }, [props.tab])

  return (
    <TelemetryScope scope="SettingsScreen">
      <SettingsScreenLayout />
    </TelemetryScope>
  )
}
