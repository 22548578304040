import { IRootCauseList } from '@copilot-dash/domain'
import { Divider, Field, Link, mergeClasses, Skeleton, SkeletonItem, Text } from '@fluentui/react-components'
import { AddRegular } from '@fluentui/react-icons'
import { FC, memo, useCallback, useLayoutEffect, useState } from 'react'
import { useToast } from '../../../hooks/useToast'
import { useGlobalStore } from '../../../store'
import { Column, Row, Spacer } from '../../Layout'
import { RootCauseCreateDialog } from '../../RootCauseCreateDialog/RootCauseCreateDialog'
import { IFormData as ICreateRootCauseFormData } from '../../RootCauseCreateDialog/RootCauseCreateForm'
import { EMPTY_TEAM_ID } from '../../../screens/team/store/const'
import { isNil } from 'lodash'
import { EMPTY_ROOT_CAUSE } from '../utils'
import { useStyles } from './RootCauses.styles'
import { RootCauseSelector } from './components/RootCauseSelector'

interface IRootCausesProps {
  value: string[] | undefined | null
  onChange: (value: string[]) => void
  productId?: number
  orientation?: 'horizontal' | 'vertical'
  loading?: boolean
  className?: string
  style?: React.CSSProperties
  defaultValueText?: string
  disabled?: boolean
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
  teamName?: string
  teamId?: number
  vsoAccount?: string
  children: React.ReactNode
  allowCreateNewRootCause?: boolean
  rootCauseGrouped?: Record<string, IRootCauseList>
}
export const RootCauses: FC<IRootCausesProps> = memo(
  ({
    value,
    onChange,
    productId,
    orientation = 'horizontal',
    loading = false,
    className,
    style,
    defaultValueText,
    disabled,
    fieldProps,
    teamName,
    teamId,
    vsoAccount,
    children,
    allowCreateNewRootCause = false,
    rootCauseGrouped = {},
  }) => {
    const styles = useStyles()
    const [dialogOpen, setDialogOpen] = useState(false)
    const [values, setValues] = useState(() => (value && value.length > 0 ? value : [EMPTY_ROOT_CAUSE]))

    useLayoutEffect(() => {
      setValues(value && value.length > 0 ? value : [EMPTY_ROOT_CAUSE])
    }, [value])

    const onRootCauseChange = useCallback(
      (index: number, issueId: string) => {
        const newValues = [...values]
        newValues[index] = issueId
        onChange(newValues)
      },
      [onChange, values],
    )

    const handleDeleteRootCause = useCallback(
      (index: number) => {
        const newValues = [...values]
        newValues.splice(index, 1)
        if (newValues.length === 0) {
          newValues.push(EMPTY_ROOT_CAUSE)
        }
        onChange(newValues)
      },
      [onChange, values],
    )

    const handleAddRootCause = useCallback(() => {
      setValues((pre) => [...pre, EMPTY_ROOT_CAUSE])
    }, [])

    const toast = useToast()

    const handleSaveAndAdd = useCallback(
      async (data: ICreateRootCauseFormData) => {
        if (isNil(teamId) || teamId === EMPTY_TEAM_ID || !vsoAccount) return
        return application.store.actions
          .createRootCause({
            title: data.title,
            description: data.description ? data.description : '',
            priority: data.priority,
            vsoAccount: vsoAccount,
            teamId: teamId,
            productId: productId,
          })
          .then((response) => {
            if (response.workItemId) {
              // NOTE: @Ethan - Update the root cause list in the store.
              if (teamName) {
                useGlobalStore.getState().addRootCauseListByAreaPath(teamName, {
                  issueId: response.workItemId,
                  title: data.title,
                  vsoAccount: vsoAccount,
                })
              }
              if (productId && teamId) {
                application.store.actions.appendRootCauseByTeam(productId, teamId, {
                  issueId: response.workItemId,
                  rootCauseTitle: data.title,
                  vsoAccount: vsoAccount,
                  reportingStatus: 'Active',
                  eTA: '',
                  owner: '',
                  rootCauseCount: 0,
                  enable: false,
                })
              }
              const emptyExistIndex = values.findIndex((value) => value === EMPTY_ROOT_CAUSE)
              if (emptyExistIndex > -1) {
                values[emptyExistIndex] = response.workItemId
                onChange([...values])
              } else {
                onChange([...values, response.workItemId])
              }
              setDialogOpen(false)
            } else {
              // handle error
              toast.showError('Failed to save', response.errorMessage)
            }
          })
          .catch((err) => {
            toast.showError('Failed to save', err.message)
          })
      },
      [teamId, vsoAccount, teamName, values, productId, onChange, toast],
    )

    const showAddBtn = values.every((str) => str !== EMPTY_ROOT_CAUSE) && values.length > 0

    return (
      <Field orientation={orientation} className={className} label="Root Cause:" style={style} {...(fieldProps ?? {})}>
        {loading ? (
          <Skeleton>
            <Column>
              <SkeletonItem size={16} />
              <Spacer height={4} />
              <SkeletonItem size={16} />
            </Column>
          </Skeleton>
        ) : (
          <div>
            {values.map((issueId, index) => {
              return (
                <RootCauseSelector
                  key={index}
                  value={issueId}
                  disabledValues={values}
                  onChange={(value) => {
                    onRootCauseChange(index, value)
                  }}
                  onRemove={() => handleDeleteRootCause(index)}
                  onCreateNewRootCauseClicked={() => setDialogOpen(true)}
                  defaultValueText={defaultValueText}
                  disabled={disabled}
                  teamName={teamName}
                  allowCreateNewRootCause={allowCreateNewRootCause}
                  rootCauseGrouped={rootCauseGrouped}
                />
              )
            })}
            <Row className={mergeClasses(styles.alignItemsCenter, styles.addNew)}>
              {showAddBtn && (
                <Row>
                  <Link as="button">
                    <Row onClick={handleAddRootCause}>
                      <AddRegular fontSize={18} />
                      <Spacer width={2} />
                      <Text>Add</Text>
                    </Row>
                  </Link>
                  {children && (
                    <Row>
                      <Spacer width={10} />
                      <Divider vertical />
                      <Spacer width={10} />
                    </Row>
                  )}
                </Row>
              )}
              {children}
            </Row>
            <RootCauseCreateDialog
              open={dialogOpen}
              onClose={() => {
                setDialogOpen(false)
              }}
              onOk={handleSaveAndAdd}
              okBtnText="Save and Add"
            />
          </div>
        )}
      </Field>
    )
  },
)

RootCauses.displayName = 'RootCauses'
