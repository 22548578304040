import {
  $applyNodeReplacement,
  type DOMConversionMap,
  type DOMConversionOutput,
  type DOMExportOutput,
  type EditorConfig,
  // type LexicalNode,
  type NodeKey,
  type SerializedTextNode,
  type Spread,
  TextNode,
} from 'lexical'

type SerializedMentionNode = Spread<
  {
    mentionName: string
    mentionUid: string
    mentionEmailAddress: string | undefined
    type: 'mention'
    version: 1
  },
  SerializedTextNode
>

function $convertMentionElement(domNode: HTMLElement): DOMConversionOutput | null {
  const textContent = domNode.textContent
  const mentionUid = domNode.getAttribute('data-lexical-mention-uid') || ''
  const mentionEmailAddress = domNode.getAttribute('data-lexical-mention-emailAddress') || ''
  if (textContent !== null) {
    const node = $createMentionNode(textContent, mentionUid, mentionEmailAddress)
    return {
      node,
    }
  }

  return null
}

export class MentionNode extends TextNode {
  __mentionName: string
  __mentionUid: string
  __mentionEmailAddress: string | undefined

  static override getType(): string {
    return 'mention'
  }

  static override clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__mentionName, node.__mentionUid, node.__mentionEmailAddress, node.__text, node.__key)
  }
  static override importJSON(serializedNode: SerializedMentionNode): MentionNode {
    const node = $createMentionNode(
      serializedNode.mentionName,
      serializedNode.mentionUid,
      serializedNode.mentionEmailAddress,
    )
    node.setTextContent(serializedNode.text)
    node.setFormat(serializedNode.format)
    node.setDetail(serializedNode.detail)
    node.setMode(serializedNode.mode)
    node.setStyle(serializedNode.style)
    return node
  }

  constructor(
    mentionName: string,
    mentionUid: string,
    mentionEmailAddress: string | undefined,
    text?: string,
    key?: NodeKey,
  ) {
    super(text ?? mentionName, key)
    this.__mentionName = mentionName
    this.__mentionUid = mentionUid
    this.__mentionEmailAddress = mentionEmailAddress
  }

  override exportJSON(): SerializedMentionNode {
    return {
      ...super.exportJSON(),
      mentionName: this.__mentionName,
      mentionUid: this.__mentionUid,
      mentionEmailAddress: this.__mentionEmailAddress,
      type: 'mention',
      version: 1,
    }
  }

  override createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config)
    dom.className = 'mention'
    dom.setAttribute('data-lexical-mention-uid', this.__mentionUid)
    this.__mentionEmailAddress && dom.setAttribute('data-lexical-mention-emailAddress', this.__mentionEmailAddress)
    dom.setAttribute('class', 'editor-text-mention')
    dom.textContent = this.__text
    return dom
  }

  override exportDOM(): DOMExportOutput {
    const element = document.createElement('span')
    element.setAttribute('data-lexical-mention', 'true')
    element.setAttribute('data-lexical-mention-uid', this.__mentionUid)
    this.__mentionEmailAddress && element.setAttribute('data-lexical-mention-emailAddress', this.__mentionEmailAddress)
    element.setAttribute('class', 'editor-text-mention')
    element.textContent = this.__text
    return { element }
  }

  static override importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-mention')) {
          return null
        }
        return {
          conversion: $convertMentionElement,
          priority: 1,
        }
      },
    }
  }

  override isTextEntity(): true {
    return true
  }

  override canInsertTextBefore(): boolean {
    return false
  }

  override canInsertTextAfter(): boolean {
    return false
  }
}

export function $createMentionNode(
  mentionName: string,
  mentionUid: string,
  mentionEmailAddress: string | undefined,
): MentionNode {
  const mentionNode = new MentionNode(mentionName, mentionUid, mentionEmailAddress)
  mentionNode.setMode('segmented').toggleDirectionless()
  return $applyNodeReplacement(mentionNode)
}

// function $isMentionNode(node: LexicalNode | null | undefined): node is MentionNode {
//   return node instanceof MentionNode
// }
