import { Tooltip } from '@fluentui/react-components'
import { ReactElement } from 'react'
import { IErrorComponents } from '../../ErrorConfigs/interfaces/IErrorComponents'

interface IProps {
  readonly components: IErrorComponents
  readonly children: ReactElement
}

export function ErrorViewTooltip({ components, children }: IProps) {
  return (
    <Tooltip
      content={{
        children: components.messageDetails ?? components.message,
        onClick: (e) => e.stopPropagation(),
      }}
      relationship="label"
    >
      {children}
    </Tooltip>
  )
}
