import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../IDashStoreContext'

export function getUserPhoto(context: IDashStoreContext, userId: string): PromiseSnapshot<Blob> {
  return context.getOrFetch({
    get: (state) => {
      return state.users[userId]?.photo
    },
    set: (state, snapshot) => {
      const user = (state.users[userId] ??= {})
      user.photo = snapshot
    },
    fetch: () => {
      return context.api.microsoftGraph.getUserPhoto(userId)
    },
  })
}
