import { PromiseSnapshot, PromiseSnapshots } from '@copilot-dash/core'
import { IKustoBizPerfLogItem, KustoLogTable } from '@copilot-dash/domain'
import { useMemo } from 'react'
import { useDashStore } from './DashStoreProvider'

interface IParams {
  readonly ticketId: string
  readonly messageId: string
}

export function useTicketKustoBizChatPerfLog(params: IParams): PromiseSnapshot<IKustoBizPerfLogItem[]> {
  const store = useDashStore()
  const result = store.use.getTicketKustoData(params.ticketId, params.messageId, KustoLogTable.BizChatPerfLog)

  return useMemo(() => {
    return PromiseSnapshots.map(result, (data) => {
      return data.BizChatPerfLog
    })
  }, [result])
}

export function useTicketKustoLlmLog(params: IParams) {
  const store = useDashStore()
  const result = store.use.getTicketKustoData(params.ticketId, params.messageId, KustoLogTable.LLMLog)

  return useMemo(() => {
    return PromiseSnapshots.map(result, (data) => {
      return data.LLMLog
    })
  }, [result])
}

export function useTicketKustoTraceLog(params: IParams) {
  const store = useDashStore()
  const result = store.use.getTicketKustoData(params.ticketId, params.messageId, KustoLogTable.TraceLog)

  return useMemo(() => {
    return PromiseSnapshots.map(result, (data) => {
      return data.TraceLog
    })
  }, [result])
}
