import { makeStyles } from '@fluentui/react-components'
import { useMemo } from 'react'
import { z } from 'zod'
import { TicketScreenKustoTab, TicketScreenKustoTabDefinitions } from '../../../store/IState'
import { TicketScreenStore } from '../../../TicketScreenStore'
import { TicketScreenOverflowTab } from '../../common/TicketScreenOverflowTab'
import { TicketScreenOverflowTabs } from '../../common/TicketScreenOverflowTabs'

export function TicketScreenKustoLogsBar() {
  const styles = useStyles()
  const actions = TicketScreenStore.useActions()

  const supportedKustoTabs = TicketScreenStore.use((state) => state.supportedKustoTabs)
  const selectedKustoTab = TicketScreenStore.use((state) => state.selectedKustoTab)

  const handleTabSelect = (tabId: TicketScreenKustoTab) => {
    actions.selectKustoTab(tabId)
  }

  const tabs = useMemo(() => {
    return supportedKustoTabs.map((tab): TicketScreenOverflowTab => {
      return {
        id: tab,
        name: TicketScreenKustoTabDefinitions[tab].name,
      }
    })
  }, [supportedKustoTabs])

  return (
    <TicketScreenOverflowTabs
      className={styles.root}
      tabs={tabs}
      selectedId={selectedKustoTab}
      onSelect={(tabId) => {
        const tab = z.nativeEnum(TicketScreenKustoTab).safeParse(tabId).data
        if (tab) {
          handleTabSelect(tab)
        }
      }}
    />
  )
}

const useStyles = makeStyles({
  root: {
    padding: '0 4px',
  },
})
