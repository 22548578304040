import { useContext } from 'react'
import { DashDialogInstanceContext } from '../context/DashDialogInstanceContext'
import { DashDialogProvider } from '../DashDialogProvider'
import { DashDialogStatus } from '../store/DashDialogState'

interface IUseDashDialog {
  readonly id: string
  readonly status: DashDialogStatus
  readonly close: () => void
}

export function useDashDialog(): IUseDashDialog | undefined {
  const id = useContext(DashDialogInstanceContext)
  const status = DashDialogProvider.use((state) => state.items.find((item) => item.id === id)?.status)
  const actions = DashDialogProvider.useActions()

  if (!id || !status) {
    return
  }

  return {
    id,
    status,
    close: () => actions.close(id),
  }
}
