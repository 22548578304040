import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { SettingsScreen } from '../../../screens/settings/SettingsScreen'

export function SettingsRoutePage() {
  return (
    <PageTitle title="Settings">
      <SettingsScreen />
    </PageTitle>
  )
}
