import { Label } from '@fluentui/react-components'
import { Column } from '../../../Layout'
import { CopilotDashCheckBox } from '../../../Filter/CopilotDashCheckBox'
import { TicketEmotionType } from '@copilot-dash/domain'
import { IFilterOption } from '../../../Filter/IFilterOption.types'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (selectedOptions: string[]) => void
}

export const EmotionTypeFilter: React.FC<IProps> = ({ selectedValues, onChangeValue }) => {
  const emotionTypeOptions: IFilterOption[] = [TicketEmotionType.Positive, TicketEmotionType.Negative].map(
    (emotionType) => ({
      key: emotionType,
      text: emotionType,
    }),
  )
  return (
    <Column>
      <Label weight="semibold">Emotion type:</Label>
      <CopilotDashCheckBox
        options={emotionTypeOptions}
        selectedOptions={selectedValues}
        onChange={onChangeValue}
        multiple={false}
      />
    </Column>
  )
}
