import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  avatar: {
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    fontSize: tokens.fontSizeBase200,
  },
  displayName: {
    maxWidth: '130px',
    fontSize: tokens.fontSizeBase300,
  },

  optionList: {
    maxHeight: '50vh',
  },
})
