import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    backgroundColor: 'transparent',
    ...shorthands.borderColor('transparent !important'),
  },
  selectedOption: {
    fontWeight: tokens.fontWeightSemibold,
    backgroundColor: 'transparent',
    color: tokens.colorNeutralForeground1,
    fontSize: tokens.fontSizeBase500,
    ...shorthands.padding(0),
    display: 'flex',
    justifyContent: 'start',
  },
})
