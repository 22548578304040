import { ApiV2Ticket } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'

export function getRawV2Ticket(context: IDashStoreContext, ticketId: string): PromiseSnapshot<ApiV2Ticket> {
  return context.getOrFetch<ApiV2Ticket>({
    get: (state) => {
      return state.tickets[ticketId]?.rawV2Ticket
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      ticket.rawV2Ticket = snapshot
    },
    fetch: async () => {
      return await context.api.copilotDash.getTicket(ticketId)
    },
  })
}
