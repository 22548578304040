import { createStoreContext, useStore } from '@copilot-dash/core'
import { useEffect } from 'react'
import { ErrorViewBoundary } from '../Error'
import { createDiscussionPanelActions } from './store/createDiscussionPanelActions'
import { createDiscussionPanelState } from './store/createDiscussionPanelState'
import { ITicketDiscussionPanelActions } from './store/ITicketDiscussionPanelActions'
import { ITicketDiscussionPanelState } from './store/ITicketDiscussionPanelState'
import { DiscussionPanelDrawer } from './layout/DiscussionPanelDrawer'

interface IProps {
  readonly ticketId: string
}

export function TicketDiscussionPanel({ ticketId }: IProps) {
  const store = useStore<ITicketDiscussionPanelState, ITicketDiscussionPanelActions>((set, get) => {
    return {
      state: createDiscussionPanelState(ticketId),
      actions: createDiscussionPanelActions({
        set,
        get,
      }),
    }
  })

  useEffect(() => {
    store.actions.init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Listen ticketId
  useEffect(() => {
    if (ticketId !== store.state.ticketId) {
      store.actions.updateTicketId(ticketId)
    }
  }, [store, ticketId])

  return (
    <StoreContext.Provider store={store}>
      <ErrorViewBoundary label="TicketDiscussionPanel">
        <DiscussionPanelDrawer />
      </ErrorViewBoundary>
    </StoreContext.Provider>
  )
}
const StoreContext = createStoreContext<ITicketDiscussionPanelState, ITicketDiscussionPanelActions>()
TicketDiscussionPanel.use = StoreContext.use
TicketDiscussionPanel.useActions = StoreContext.useActions
