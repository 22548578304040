import { ITagDefinition } from './ITagDefinition.type'
import { useSearchScreenState } from '../../store'
import { TruncateTagFunction } from './TruncateTagFunction'
import { OptionSetPriority } from '../../../../components/TicketsFilterPanel/config/OptionSetPriority'
import { useMemo } from 'react'

export const PriorityTagConfig = (): ITagDefinition => {
  const form = useSearchScreenState((state) => state.form)
  const valueTexts = useMemo(() => {
    return form.priority
      ? form.priority.map((item) => {
          const option = OptionSetPriority.find((option) => option.key === item)
          return option ? option.text : item
        })
      : []
  }, [form.priority])

  return {
    key: 'priority',
    tagName: 'Priority',
    toolTipContentValue: valueTexts.join(', '),
    truncatedTagValue: TruncateTagFunction(valueTexts),
    IsShowTag: false,
    isTagClearable: true,
  }
}
