import { makeStyles } from '@fluentui/react-components'
import { BaseEdge, Edge, EdgeProps, getSmoothStepPath } from '@xyflow/react'
import { CSSProperties } from 'react'
import { CallFlowEdge } from '../../store/IState'
import { TicketCallFlowStore } from '../../TicketCallFlowStore'
import { generateColor } from '../../utils/generateColor'

export function TurnViewFlowEdgeView({
  data,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}: EdgeProps<Edge<CallFlowEdge>>) {
  const styles = useStyles()

  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  const selection: 'selected' | 'unselected' | 'rested' = TicketCallFlowStore.use((state) => {
    if (state.selectedEventId) {
      return state.selectedEventId === data?.source.item.id || state.selectedEventId === data?.target.item.id
        ? 'selected'
        : 'unselected'
    } else {
      return 'rested'
    }
  })

  let themes: Record<'selected' | 'unselected' | 'rested', CSSProperties> | undefined
  if (data) {
    themes = {
      selected: {
        stroke: generateColor(data.source.item.serviceId),
      },
      unselected: {
        stroke: generateColor(data.source.item.serviceId, 'alpha2'),
      },
      rested: {
        stroke: generateColor(data.source.item.serviceId, 'alpha1'),
      },
    }
  }

  return (
    <BaseEdge
      className={styles.root}
      path={edgePath}
      markerEnd={markerEnd}
      style={{
        ...style,
        ...themes?.[selection],
      }}
    />
  )
}

const useStyles = makeStyles({
  root: {
    transition: 'stroke 0.5s',
  },
})
