import { z } from 'zod'

export const apiConversationGroup1SuggestedResponseSchema = z.record(z.unknown()).and(
  z.object({
    text: z.string(),
  }),
)

export const apiConversationGroup1AdaptiveCardBodySchema = z.record(z.unknown()).and(
  z.object({
    text: z.string().optional(),
  }),
)

export const apiConversationGroup1ReferenceMetadataSchema = z.record(z.unknown()).and(
  z.object({
    type: z.string().optional(),
  }),
)

export const apiConversationGroup1AdaptiveCardSchema = z.record(z.unknown()).and(
  z.object({
    body: z.array(apiConversationGroup1AdaptiveCardBodySchema).optional(),
  }),
)

export const apiConversationGroup1SourceAttributionSchema = z.record(z.unknown()).and(
  z.object({
    sourceType: z.string().optional(),
    referenceMetadata: apiConversationGroup1ReferenceMetadataSchema.optional(),
  }),
)

export const apiConversationGroup1MessageSchema = z.record(z.unknown()).and(
  z.object({
    text: z.union([z.string(), z.any()]).optional(),
    createdAt: z.string().optional(),
    suggestedResponses: z.array(apiConversationGroup1SuggestedResponseSchema).optional(),
    adaptiveCards: z.array(apiConversationGroup1AdaptiveCardSchema).optional(),
    sourceAttributions: z.array(apiConversationGroup1SourceAttributionSchema).optional(),
  }),
)

export const apiConversationGroup1ConversationSchema = z.record(z.unknown()).and(
  z.object({
    messages: z.array(apiConversationGroup1MessageSchema),
  }),
)

export const apiConversationGroup1Schema = z.record(z.unknown()).and(
  z.object({
    conversation: apiConversationGroup1ConversationSchema,
  }),
)
