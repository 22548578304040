import { FC, memo } from 'react'
import { ICellBaseProps } from './types'
import { makeStyles, Text } from '@fluentui/react-components'

export const Issue: FC<ICellBaseProps> = memo(({ data }) => {
  const styles = useStyles()
  return (
    <Text title={data.issueTitle} className={styles.text} size={300} weight="semibold">
      {data.issueTitle}
    </Text>
  )
})
Issue.displayName = 'Issue'

const useStyles = makeStyles({
  text: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordWrap: 'break-word',
    wordBreak: 'normal',
    overflowWrap: 'break-word',
  },
})
