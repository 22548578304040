import { TicketErrorData, TicketErrorSourceByNames } from '@copilot-dash/error'
import { TimeView } from '../../Time/TimeView'
import { TicketId } from './TicketId'

interface IProps {
  readonly data: TicketErrorData
}

export function TicketErrorContextView({ data }: IProps) {
  const ticketId = data.extra.ticketId
  const ticketCreationTime = data.extra.ticketCreationTime
  const errorCode = data.extra.errorCode
  const errorCauseBy = data.source
  const kustoTable = data.extra.kustoLogTable

  return (
    <table>
      <tbody>
        {/* 1. Ticket ID */}
        <tr>
          <td>TicketID</td>
          <td>
            <TicketId ticketId={ticketId} />
          </td>
        </tr>

        {/* 2. Ticket created time */}
        {ticketCreationTime && (
          <tr>
            <td>CreatedTime</td>
            <td>
              <TimeView value={ticketCreationTime} />
            </td>
          </tr>
        )}

        {/* 3. Error code */}
        {errorCode && (
          <tr>
            <td>ErrorCode</td>
            <td>{errorCode}</td>
          </tr>
        )}

        {/* 4. Error Caused by */}
        <tr>
          <td>CauseBy</td>
          <td>{TicketErrorSourceByNames[errorCauseBy]}</td>
        </tr>

        {/* 5. Kusto Table */}
        {kustoTable && (
          <tr>
            <td>Kusto log table</td>
            <td>{kustoTable}</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
