import { ApiError, ZodError } from '@copilot-dash/api'
import { ApiResponseTableZodPath } from './ApiResponseTableZodPath'
import { ApiResponseTableZodMessage } from './ApiResponseTableZodMessage'

interface IProps {
  readonly error: ApiError
  readonly cause: ZodError
}

export function ApiResponseTableZod({ error, cause }: IProps) {
  return (
    <table>
      <tbody>
        {/* 1. Value Path */}
        <tr>
          <td>Value Path</td>
          <td>
            <ApiResponseTableZodPath error={error} cause={cause} />
          </td>
        </tr>

        {/* 2. Value Issue */}
        <tr>
          <td>Value Issue</td>
          <td>
            <ApiResponseTableZodMessage error={error} cause={cause} />
          </td>
        </tr>
      </tbody>
    </table>
  )
}
