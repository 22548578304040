import { ITicketChatTurn } from '@copilot-dash/domain'
import { makeStyles } from '@fluentui/react-components'
import { Column, Row } from '../../../../Layout'
import { MessageContent } from '../common/MessageContent'
import { MessageLabel } from '../common/MessageLabel'
import { CopilotMessageTileAvatar } from './CopilotMessageTileAvatar'

interface IProps {
  readonly data: ITicketChatTurn
}

export function CopilotMessageTile({ data }: IProps) {
  const styles = useStyles()
  if (!data.botMessage) {
    return null
  }

  return (
    <Row className={styles.root}>
      <CopilotMessageTileAvatar />
      <Column className={styles.content} hAlign="stretch">
        <MessageContent
          text={data.botMessage.text}
          markdownText={data.botMessage.text}
          adaptiveCard={data.botMessage.adaptiveCard}
        />
        <MessageLabel data={data} />
      </Column>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    margin: '8px 16px',
    gap: '12px',
  },
  content: {
    flex: '1 1 0',
    gap: '8px',
    overflow: 'hidden',
  },
})
