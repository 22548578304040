import { Slot } from '@fluentui/react-components'
import { CloudBeakerFilled, CloudBeakerRegular, SettingsFilled, SettingsRegular } from '@fluentui/react-icons'
import { SettingsScreenTab } from '../../store'

type SettingsScreenOutlineConfig = {
  title: string
  icon: Slot<'span'>
  selectedIcon: Slot<'span'>
}

export const SettingsScreenOutlineConfigs: { [key in SettingsScreenTab]: SettingsScreenOutlineConfig } = {
  [SettingsScreenTab.General]: {
    title: 'General',
    icon: <SettingsRegular />,
    selectedIcon: <SettingsFilled />,
  },
  [SettingsScreenTab.Labs]: {
    title: 'Labs',
    icon: <CloudBeakerRegular />,
    selectedIcon: <CloudBeakerFilled />,
  },
}
