import { useLocation } from 'react-router-dom'
import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { UnknownScreen } from '../../../screens/404/UnknownScreen'

export function NotFoundRoutePage() {
  const location = useLocation()

  return (
    <PageTitle title="404 Page Not Found">
      <UnknownScreen path={location.pathname} />
    </PageTitle>
  )
}
