import { SymptomReportItem } from '@copilot-dash/domain'

export function parseToString(key: string, value: unknown): SymptomReportItem | undefined {
  if (typeof value !== 'string') {
    return
  }

  return {
    key,
    value: value,
    valueType: 'String',
  }
}
