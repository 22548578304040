import { useContext, useMemo } from 'react'
import { ITelemetryScope } from '../telemetry/ITelemetryScope'
import { TelemetryScopesContext } from './TelemetryScopesContext'

export const useTelemetryScopes = (current?: keyof ITelemetryScope): Array<keyof ITelemetryScope> => {
  const inheritedScopes = useContext(TelemetryScopesContext)
  return useMemo(() => {
    if (current) {
      return [...inheritedScopes, current]
    } else {
      return inheritedScopes
    }
  }, [inheritedScopes, current])
}
