import { z } from 'zod'

export enum TeamViewSubMenuIds {
  'All' = 'All',
  'UnRootCaused' = 'UnRootCaused',
  'TopIssues' = 'TopIssues',
  'RootCauses' = 'RootCauses',
  'SharedQueries' = 'SharedQueries',
}

export const teamViewSubMenuIdsSchema = z.nativeEnum(TeamViewSubMenuIds)
