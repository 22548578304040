import { SymptomReportGroup } from '@copilot-dash/domain'
import { Fragment } from 'react/jsx-runtime'
import { GroupRowForTitle } from './GroupRowForTitle'
import { ItemRow } from './ItemRow'

interface IProps {
  readonly groupIndex: number
  readonly group: SymptomReportGroup
}

export function Group({ groupIndex, group }: IProps) {
  return (
    <Fragment>
      <GroupRowForTitle groupIndex={groupIndex} group={group} />
      {group.items.map((item, index) => {
        return <ItemRow key={index} item={item} />
      })}
    </Fragment>
  )
}
