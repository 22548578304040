import { z } from 'zod'

export interface ApiTicketCustomTagsResponse {
  readonly ticketId?: string
  readonly customTags?: Array<string>
  readonly errorMessage?: string
}

export const apiTicketCustomTagsResponseSchema = z.object({
  ticketId: z.string().optional(),
  customTags: z.array(z.string()).optional(),
  errorMessage: z.string().optional(),
})
