import {
  IActivityHistory,
  activityHistoryRootCauseContextSchema,
  ticketIssueDataSchema,
  FieldDiff,
  ActivityHistoryRootCauseContext,
  TicketIssueData,
  ActivityHistoryType,
  activityHistoryTypeSchema,
  SystemType,
  systemTypeSchema,
} from '@copilot-dash/domain'
import { ActivityHistoryDiff } from '@copilot-dash/api'
import { z } from 'zod'

export class ActivityHistoryConverter {
  static readonly getActivityHistories = (data: ActivityHistoryDiff): IActivityHistory => {
    const diff = data.fieldDiffs
    const activity = this.safeParseActivityHistory(data.activity)
    const parsedDiff: Record<string, FieldDiff> = {}
    if (diff) {
      Object.keys(diff).forEach((key) => {
        const fieldDiff = diff[key]

        let oldValue: string | number | TicketIssueData[] | ActivityHistoryRootCauseContext[] | string[] | undefined
        let newValue: string | number | TicketIssueData[] | ActivityHistoryRootCauseContext[] | string[] | undefined
        if (fieldDiff && fieldDiff.oldValue && this.isString(fieldDiff.oldValue)) {
          oldValue = this.deserializeFieldValue(fieldDiff.oldValue)
        } else {
          oldValue = fieldDiff?.oldValue
        }

        if (fieldDiff && fieldDiff.newValue && this.isString(fieldDiff.newValue)) {
          newValue = this.deserializeFieldValue(fieldDiff.newValue)
        } else {
          newValue = fieldDiff?.newValue
        }
        parsedDiff[key] = {
          oldValue: oldValue,
          newValue: newValue,
        }
      })
    }
    return {
      revision: data.revision,
      activityId: data.activityId,
      ticketId: data.ticketId,
      timestamp: data.timestamp,
      activity: activity,
      actor: data.actor,
      userId: data.actor === 'user' ? data.userId : undefined,
      system: data.actor === 'system' ? this.safeParseSystemType(data.system) : undefined,
      fieldDiffs: parsedDiff,
    }
  }

  private static isString(value: unknown) {
    return typeof value === 'string'
  }
  public static safeParseSystemType(value: unknown): SystemType {
    const result = systemTypeSchema.safeParse(value)
    if (result.success) {
      return result.data
    } else {
      return 'Unset'
    }
  }
  public static safeParseActivityHistory(value: unknown): ActivityHistoryType {
    const result = activityHistoryTypeSchema.safeParse(value)
    if (result.success) {
      return result.data
    } else {
      return 'Unset'
    }
  }

  private static deserializeFieldValue(
    value: string,
  ): string | number | TicketIssueData[] | ActivityHistoryRootCauseContext[] | string[] {
    const schema = z.union([
      z.string(),
      z.number(),
      z.array(ticketIssueDataSchema),
      z.array(activityHistoryRootCauseContextSchema),
      z.array(z.string()),
    ])

    try {
      return schema.parse(JSON.parse(value))
    } catch (e) {
      return value
    }
  }
}
