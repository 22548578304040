import { z } from 'zod'
import { ApiRootCauseContext, apiRootCauseContextSchema } from './ApiRootCauseContext'

export const ApiRootCauseResponseSuccessMessage = 'success'

export interface ApiRootCauseResponse {
  readonly errorMessage: string
  readonly rootCauseActiveList?: Array<ApiRootCauseContext>
}

export const apiRootCauseResponseSchema = z.object({
  errorMessage: z.string(),
  rootCauseActiveList: z.array(apiRootCauseContextSchema).optional(),
})
