import { makeStyles, tokens, shorthands } from '@fluentui/react-components'

export const useStyles = makeStyles({
  skeleton: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    width: '250px',
    gap: '8px',
  },
  skeletonItem1: {
    width: '100%',
  },
  skeletonItem2: {
    width: '80%',
  },
  grayColor: {
    color: tokens.colorScrollbarOverlay,
  },
  tooltip: {
    backgroundColor: tokens.colorNeutralBackground3,
    boxShadow: tokens.shadow2,
    ...shorthands.padding('16px', '0px', '16px', '16px'),
  },
  tooltipContent: {
    paddingRight: '16px',
    fontSize: tokens.fontSizeBase300,
    lineHeight: tokens.lineHeightBase300,
  },
  text: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordWrap: 'break-word',
    wordBreak: 'normal',
    overflowWrap: 'break-word',
  },

  green: {
    color: tokens.colorPaletteLightTealBorderActive,
  },
  blue: {
    color: tokens.colorCompoundBrandBackground,
  },
  yellow: {
    color: tokens.colorStatusWarningBackground3,
  },
})
