import { ApiCacheOptions } from './ApiClient'

const ONE_SECOND = 1000
const ONE_MINUTE = ONE_SECOND * 60
const ONE_HOUR = ONE_MINUTE * 60
const ONE_DAY = ONE_HOUR * 24

export const Caches = {
  NONE: {
    ttl: 0,
  },

  SHORT: {
    ttl: 10 * ONE_MINUTE,
    methods: ['get', 'post'],
  },

  LONG: {
    ttl: ONE_DAY,
    methods: ['get', 'post'],
  },
} satisfies Record<string, ApiCacheOptions>
