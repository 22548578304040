import { makeStyles, mergeClasses } from '@fluentui/react-components'

interface IProps {
  readonly className?: string
  readonly children?: React.ReactNode
}

export function Right(props: IProps) {
  const styles = useStyles()

  return <div className={mergeClasses('Right', styles.right, props.className)}>{props.children}</div>
}

const useStyles = makeStyles({
  right: {
    display: 'flex',
    flex: '0 0 auto',
    overflow: 'auto',

    '& > *': {
      flex: '1 1 auto',
    },
  },
})
