import { Spinner, Table, TableBody, TableCell, TableRow, Text } from '@fluentui/react-components'
import { ErrorView } from '../../Error'
import { TicketIdCopyButton } from '../../ErrorConfigs/components/TicketIdCopyButton'
import { Row } from '../../Layout'
import { TimeView } from '../../Time/TimeView'
import { useTicketSummaryPanelStore } from '../store'
import { useStyles } from './TicketBasicInfo.styles'

export function TicketBasicInfo() {
  const styles = useStyles()
  const store = useTicketSummaryPanelStore()

  const ticketSnapshot = app.store.use.getTicket(store.state.ticketId)
  const ticketSessionSnapshot = app.store.use.getTicketSession(store.state.ticketId)
  const ticketEmailSnapshot = app.store.use.getTicketEmail(store.state.ticketId)

  const loading = <Spinner size="extra-tiny" className={styles.loading} />

  return (
    <Row>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Text weight="semibold">Ticket ID:</Text>
            </TableCell>
            <TableCell>
              {ticketSnapshot.status !== 'done' ? (
                loading
              ) : (
                <Row>
                  {ticketSnapshot.data?.ticketId ?? 'N/A'}
                  {ticketSnapshot.data?.ticketId && <TicketIdCopyButton ticketId={ticketSnapshot.data.ticketId} />}
                </Row>
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Text weight="semibold">Created by:</Text>
            </TableCell>
            <TableCell>
              {(() => {
                switch (ticketEmailSnapshot.status) {
                  case 'waiting':
                    return loading
                  case 'done':
                    return ticketEmailSnapshot.data
                  case 'error':
                    return <ErrorView type="inline" error={ticketEmailSnapshot.error} />
                }
              })()}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Text weight="semibold">Created time:</Text>
            </TableCell>
            <TableCell>
              {(() => {
                switch (ticketSnapshot.status) {
                  case 'waiting':
                    return loading
                  case 'error':
                    return <ErrorView type="inline" error={ticketEmailSnapshot.error} />
                  case 'done':
                    return <TimeView value={ticketSnapshot.data?.dateTimeUtc} defaultText="N/A" />
                }
              })()}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Text weight="semibold">Tenant name:</Text>
            </TableCell>
            <TableCell>
              {ticketSnapshot.status !== 'done' ? loading : ticketSnapshot.data?.tenantName ?? 'N/A'}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Text weight="semibold">Consent given:</Text>
            </TableCell>
            <TableCell>
              {(() => {
                switch (ticketSessionSnapshot.status) {
                  case 'waiting':
                    return loading
                  case 'done':
                    return ticketSessionSnapshot.data.hasUserConsent ? 'Yes' : 'No'
                  case 'error':
                    return <ErrorView type="inline" error={ticketSessionSnapshot.error} />
                }
              })()}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Row>
  )
}
