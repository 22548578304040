import { TicketCallFlowService } from '@copilot-dash/domain'
import { makeStyles, shorthands, Tag, Text } from '@fluentui/react-components'
import { CheckboxCheckedFilled, CheckboxUncheckedFilled } from '@fluentui/react-icons'
import { generateColor } from '../../utils/generateColor'

interface IProps {
  readonly service: TicketCallFlowService
  readonly selected: boolean
  readonly hasSelection: boolean
  readonly onToggle: () => void
}

export function TurnViewBarLegendItem({ service, selected, hasSelection, onToggle }: IProps) {
  const styles = useStyles()
  const color = generateColor(service.id)

  return (
    <Tag
      className={styles.root}
      appearance="outline"
      title={service.name}
      media={
        selected ? (
          <CheckboxCheckedFilled color={color} fontSize={20} />
        ) : (
          <CheckboxUncheckedFilled color={color} fontSize={20} />
        )
      }
      onClick={onToggle}
    >
      <Text className={styles.text} size={200} block wrap={false} truncate>
        {service.name}
      </Text>
    </Tag>
  )
}

const useStyles = makeStyles({
  root: {
    ...shorthands.borderColor('transparent'),
    margin: 0,
    padding: '0px',

    '& > .fui-Tag__media': {
      paddingRight: '0px',
    },
  },
  text: {
    maxWidth: '160px',
  },
})
