import { IRouteMapper } from '../../types/IRouteMapper'
import { IRouteParams } from '../../types/IRouteParams'
import { RouteDataReader } from '../../utils/RouteDataReader'
import { LoginRouteArgs } from './LoginRouteArgs'

const QUERY_PARAM_REDIRECT = 'redirect'

export const LoginRouteMapper: IRouteMapper<LoginRouteArgs> = {
  mapToParams(args: LoginRouteArgs): IRouteParams {
    return {
      queryParams: {
        [QUERY_PARAM_REDIRECT]: args.redirect,
      },
    }
  },

  mapToArgs(data: IRouteParams): LoginRouteArgs {
    const query = new RouteDataReader(data.queryParams)

    return {
      redirect: query.getString(QUERY_PARAM_REDIRECT),
    }
  },
}
