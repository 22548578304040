import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: tokens.colorNeutralBackground2,
    ...shorthands.flex(1),
    ...shorthands.overflow('hidden'),
  },
  leftPanel: {
    width: '334px',
  },
  rightPanel: {
    ...shorthands.flex(1),
  },
  divider: {
    width: '1px',
    height: '100%',
  },
})
