import { Button } from '@fluentui/react-components'
import { useLoginScreenStore } from '../store'

export function LoginButton() {
  const login = useLoginScreenStore((state) => state.login)
  const loginSnapshot = useLoginScreenStore((state) => state.loginSnapshot)

  const isWaiting = loginSnapshot.status === 'waiting'
  const hasError = loginSnapshot.error

  return (
    <Button appearance="primary" onClick={login} disabled={loginSnapshot.status === 'waiting'}>
      {isWaiting ? 'Redirecting' : hasError ? 'Try Again' : 'Sign In'}
    </Button>
  )
}
