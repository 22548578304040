import { Field } from '@fluentui/react-components'
import { FC, memo } from 'react'
import { PeopleTagPicker } from '../../PeoplePicker/PeopleTagPicker'

export type EmailAddress = string | undefined

interface IAssignToProps {
  value: EmailAddress
  onChange: (value: EmailAddress) => void
  orientation?: 'horizontal' | 'vertical'
  defaultValueTexts?: string
  className?: string
  style?: React.CSSProperties
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
}

export const AssignTo: FC<IAssignToProps> = memo(
  ({ value, onChange, orientation = 'horizontal', className, style, fieldProps }) => {
    return (
      <Field label="Assign To:" className={className} orientation={orientation} style={style} {...(fieldProps ?? {})}>
        <PeopleTagPicker value={value ? [value] : []} onSelect={(v) => onChange(v[0] ?? '')} />
      </Field>
    )
  },
)

AssignTo.displayName = 'AssignTo'
