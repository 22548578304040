import { User } from '@copilot-dash/api'
import { useAsyncLoader } from '@copilot-dash/core'
import {
  TagPicker,
  TagPickerList,
  TagPickerInput,
  TagPickerControl,
  TagPickerProps,
  TagPickerOption,
  TagPickerGroup,
  useTagPickerFilter,
  Spinner,
  Tag,
  Persona,
} from '@fluentui/react-components'
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDebounce } from '../../hooks/useDebounce'
import { Row } from '../Layout'
import { UserPhoto } from '../User/UserPhoto'
import { UserDisplayName } from '../User/UserDisplayName'
import { useStyles } from './PeopleTagPicker.styles'

type UserEmail = string
interface IPeopleTagPickerProps {
  readonly value?: Array<UserEmail>
  readonly disable?: boolean
  readonly onSelect?: (v: Array<UserEmail>) => void
  readonly className?: string
  readonly singleSelect?: boolean
  readonly appearance?: TagPickerProps['appearance']
}

export const PeopleTagPicker: FC<IPeopleTagPickerProps> = memo(function PeopleTagPicker({
  value,
  onSelect,
  singleSelect = true,
  appearance = 'underline',
}) {
  const styles = useStyles()
  const [query, setQuery] = useState<string>('')
  const onOptionSelect: TagPickerProps['onOptionSelect'] = (e, data) => {
    if (data.value === 'no-matches') {
      return
    }
    onSelect?.(singleSelect ? (value?.[0] === data.value ? [] : [data.value]) : data.selectedOptions)
    setQuery('')
  }

  const searchUsers = useCallback((input: string): Promise<User[]> | null => {
    if (input.trim().length === 0) {
      return null
    }

    return application.api.microsoftGraph.searchUsers(input).then((res) => res.value)
  }, [])

  const [submit, snapshot] = useAsyncLoader(searchUsers)
  const submitRef = useRef(submit)

  const debouncedQuery = useDebounce(query, 300)

  useEffect(() => {
    if (debouncedQuery.trim().length > 0) {
      submitRef.current?.(debouncedQuery.trim())
    }
  }, [debouncedQuery])

  const noOptionsElement = useMemo(() => {
    if (snapshot.status === 'done' && snapshot.data.length === 0) {
      return <TagPickerOption value="no-matches">No results found</TagPickerOption>
    }
    if (snapshot.status === 'waiting') {
      return <Spinner />
    }
    if (snapshot.status === 'error') {
      return <Row>An error occurred, please try again.</Row>
    }
    return <Row>None</Row>
  }, [snapshot])

  const userMap = useMemo(() => {
    // return {[user.userPrincipalName]: user}
    return snapshot.status === 'done'
      ? snapshot.data.reduce(
          (acc, user) => {
            user.userPrincipalName && (acc[user.userPrincipalName] = user)
            return acc
          },
          {} as Record<string, User>,
        )
      : {}
  }, [snapshot])

  const children = useTagPickerFilter({
    query,
    options:
      snapshot.status === 'done'
        ? snapshot.data.map((user) => user.userPrincipalName).filter((v): v is string => !!v)
        : [],
    noOptionsElement: noOptionsElement,
    renderOption: (option) => (
      <TagPickerOption key={option} value={option} text={option}>
        <Persona
          avatar={{ color: 'colorful', 'aria-hidden': true }}
          name={userMap[option]?.displayName ?? 'No display name'}
          secondaryText={userMap[option]?.userPrincipalName}
          size="small"
        />
      </TagPickerOption>
    ),

    filter: (option) =>
      !(value ?? []).includes(option) &&
      !!(
        option.toLowerCase().includes(query.toLowerCase()) ||
        userMap[option]?.displayName?.toLowerCase().includes(query.toLowerCase()) ||
        userMap[option]?.mail?.toLowerCase().includes(query.toLowerCase()) ||
        userMap[option]?.userPrincipalName?.toLowerCase().includes(query.toLowerCase()) ||
        userMap[option]?.givenName?.toLowerCase().includes(query.toLowerCase())
      ),
  })

  return (
    <TagPicker onOptionSelect={onOptionSelect} selectedOptions={value} appearance={appearance}>
      <TagPickerControl>
        <TagPickerGroup aria-label="Selected people">
          {(value ?? []).map((option) => (
            <Tag
              key={option}
              shape="rounded"
              media={<UserPhoto userId={option} className={styles.avatar} />}
              value={option}
            >
              <UserDisplayName userId={option} className={styles.displayName} fallback={option} />
            </Tag>
          ))}
        </TagPickerGroup>
        <TagPickerInput
          placeholder={value?.length ? '' : 'Select people'}
          aria-label="Select people"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </TagPickerControl>

      <TagPickerList className={styles.optionList}>{children}</TagPickerList>
    </TagPicker>
  )
})
