import { Logger } from '../..'

interface IOptions {
  readonly class?: string
  readonly method?: string
  readonly message?: string
}

export function EnableExceptionTracking(options: IOptions = {}) {
  return function <T, Args extends unknown[]>(
    target: unknown,
    key: string,
    descriptor: TypedPropertyDescriptor<(...args: Args) => T>,
  ) {
    const fun = descriptor.value!
    descriptor.value = function (...args: Args): T {
      try {
        const result = fun.apply(this, args)
        if (result instanceof Promise) {
          return result.catch((error) => {
            onError(options, target, key, error)
            throw error
          }) as T
        }

        return result
      } catch (error) {
        onError(options, target, key, error)
        throw error
      }
    }

    return descriptor
  }
}

function onError(options: IOptions, target: unknown, key: string, error: unknown) {
  let message = options.message
  if (!message) {
    const className = options.class ?? target?.constructor?.name ?? 'unknown'
    const methodName = options.method ?? key
    message = `An error occurred when calling method '${className}.${methodName}()'. \n${error}`
  }

  Logger.trace.error(message, error)
}
