import { z } from 'zod'

export interface ApiUpdateRootCauseRequest {
  Title: string
  Description: string
  Priority: number
  RootCauseStatus: string
  VsoAccount: string
  WorkItemId: string
  TeamId: number
  Owner?: string
  OriginalCommittedDate?: string
  ProductId?: number
}

export interface ApiUpdateRootCauseResponse {
  workItemId: string
  errorMessage: string
}

export const ApiUpdateRootCauseResponseSchema = z.object({
  workItemId: z.string(),
  errorMessage: z.string(),
})
