import { IRouteMapper } from '../../types/IRouteMapper'
import { RouteDataReader } from '../../utils/RouteDataReader'
import { WorkItemRouteArgs } from './WorkItemRouteArgs'

const PATH_PARAM_WORK_ITEM_ID = 'workItemId'
const PATH_PARAM_TEAM_ID = 'teamId'

export const WorkItemRouteMapper: IRouteMapper<WorkItemRouteArgs> = {
  mapToParams(args: WorkItemRouteArgs) {
    return {
      pathParams: {
        [PATH_PARAM_WORK_ITEM_ID]: args.workItemId,
        [PATH_PARAM_TEAM_ID]: String(args.teamId),
      },
    }
  },

  mapToArgs(data) {
    const path = new RouteDataReader(data.pathParams)
    const workItemId = path.getString(PATH_PARAM_WORK_ITEM_ID)
    const teamId = path.getNumber(PATH_PARAM_TEAM_ID)
    if (workItemId === undefined || teamId === undefined) {
      throw new Error(
        `Unable to map route params to 'WorkItemRouteArgs'. Missing required path param: '${PATH_PARAM_WORK_ITEM_ID}', ${PATH_PARAM_TEAM_ID}`,
      )
    }

    return {
      workItemId,
      teamId,
    }
  },
}
