import { SymptomReport } from '../../SymptomReport/SymptomReport'
import { useTicketSummaryPanelSelector } from '../store'

export function SymptomReportLayout() {
  const ticketId = useTicketSummaryPanelSelector((state) => state.ticketId)
  const turnId = useTicketSummaryPanelSelector((state) => state.selectedTurnId)
  if (!turnId) {
    return null
  }

  return <SymptomReport ticketId={ticketId} turnId={turnId} />
}
