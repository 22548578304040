import { ApiRootCauseResponse, ApiRootCauseResponseSuccessMessage } from '@copilot-dash/api'
import { ITicketRootCauseData } from '@copilot-dash/domain'
import { TicketRootCauseDataConverter } from './TicketRootCauseDataConverter'

export class TicketRootCauseResultConverter {
  static fromApi(response: ApiRootCauseResponse): ITicketRootCauseData {
    return {
      isSuccess: response.errorMessage === ApiRootCauseResponseSuccessMessage,
      items:
        response.rootCauseActiveList?.map((rootCause) => {
          return TicketRootCauseDataConverter.fromApi(rootCause)
        }) ?? [],
    }
  }
}
