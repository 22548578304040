import { ITicketChatTurn } from '@copilot-dash/domain'
import { Button, makeStyles } from '@fluentui/react-components'
import { Column, Row } from '../../../../Layout'
import { MessageLabel } from '../common/MessageLabel'
import { ChevronDownRegular, ChevronUpRegular } from '@fluentui/react-icons'
import { useMemo, useState } from 'react'
import { InvocationMessageContent } from './InvocationMessageContent'

interface IProps {
  readonly data: ITicketChatTurn
}

export function InvocationMessageTile({ data }: IProps) {
  const styles = useStyles()

  const [showMoreInvocations, setShowMoreInvocations] = useState(false)

  const invocationRender = useMemo(() => {
    if (data.invocationMessages) {
      const allInvocationRender = data.invocationMessages.map((item, index) => {
        return <InvocationMessageContent text={item.text} key={index} />
      })

      const filterInvocationRender = data.invocationMessages.slice(0, 2).map((item, index) => {
        return <InvocationMessageContent text={index === 1 ? `${item.text} ...` : item.text} key={index} />
      })

      if (data.invocationMessages.length > 2 && !showMoreInvocations) {
        return filterInvocationRender
      } else {
        return allInvocationRender
      }
    }
    return null
  }, [data.invocationMessages, showMoreInvocations])

  if (!data.invocationMessages) {
    return null
  }

  return (
    <Row className={styles.root}>
      <Column className={styles.content} hAlign="stretch">
        <Row className={styles.row}>
          <Column>{invocationRender}</Column>
          {data.invocationMessages && data.invocationMessages.length > 2 && (
            <Button
              icon={showMoreInvocations ? <ChevronUpRegular /> : <ChevronDownRegular />}
              appearance="subtle"
              onClick={() => setShowMoreInvocations(!showMoreInvocations)}
            />
          )}
        </Row>
        <MessageLabel data={data} />
      </Column>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    margin: '8px 16px',
    gap: '12px',
  },
  content: {
    flex: '1 1 0',
    gap: '8px',
    overflow: 'hidden',
    marginLeft: '35px',
  },
  row: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
})
