import { Badge, Button, makeStyles } from '@fluentui/react-components'
import { AlertRegular } from '@fluentui/react-icons'
import { ReactNode } from 'react'
import { RouteLink } from '../../../../router'
import { Times } from '@copilot-dash/core'
import { MonacoJsonView } from '../../../../components/Monaco/MonacoJsonView'

const RELEASE_NOTES_URL = 'https://aka.ms/copilotdash-release-notes'

export function HeaderReleaseNotesButton(): ReactNode {
  const styles = useStyles()

  let timeout: NodeJS.Timeout

  const buildTime = application.env.buildTime
  const isShowBadge = Times.isWithinLastXHours(buildTime, 48)
  const handleMouseDown = () => {
    timeout = setTimeout(() => {
      app.dialog.open({
        content: <MonacoJsonView data={app.env} isWrap={true} />,
        className: styles.dialog,
        title: 'Environment',
      })
    }, 500)
  }

  const handleMouseUp = () => {
    clearTimeout(timeout)
  }
  return (
    <RouteLink
      path={RELEASE_NOTES_URL}
      title="What's new"
      openInNewTab
      onClick={() => {
        clearTimeout(timeout)
      }}
    >
      <Button
        appearance="transparent"
        icon={
          <div className={styles.buttonIcon}>
            <AlertRegular fontSize={20} />
            {isShowBadge && <Badge size="tiny" className={styles.redDot} color="danger" />}
          </div>
        }
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      />
    </RouteLink>
  )
}

const useStyles = makeStyles({
  redDot: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  buttonIcon: {
    position: 'relative',
    display: 'flex',
  },
  editor: {
    height: '300px',
  },
  dialog: {
    width: '600px',
    height: '300px',
  },
})
