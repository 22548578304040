import { INewTicketData, ColumnKey, ColumnIdNaColumnIdNameMapping } from '@copilot-dash/domain'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { ExtendedTableColumnDefinition, createExtendedTableColumn } from '../Table/ExtendedTableColumn'
import { makeStyles, shorthands, Skeleton, SkeletonItem, tokens } from '@fluentui/react-components'
import { ErrorView } from '../Error'
import { UserProfile } from '../User/UserProfile'
import { memo, useMemo } from 'react'

export const ColumnConfigUserProfile: ExtendedTableColumnDefinition<INewTicketData> =
  createExtendedTableColumn<INewTicketData>({
    columnId: ColumnKey.UserProfile,
    columnName: ColumnIdNaColumnIdNameMapping[ColumnKey.UserProfile],
    isResizable: true,
    columnSize: {
      minWidth: 50,
      idealWidth: 260,
      padding: 0,
    },

    renderHeaderCell: () => <TableCellLayoutWithStyles>User Name</TableCellLayoutWithStyles>,

    renderCell: (item: INewTicketData) => <RenderUserProfile item={item} />,
  })

const RenderUserProfile = memo(({ item }: { item: INewTicketData }) => {
  const styles = useStyles()
  const userEmail = application.store.use.getTicketEmail(item.ticketId)

  const content = useMemo(() => {
    switch (userEmail.status) {
      case 'waiting':
        return (
          <Skeleton appearance="translucent">
            <SkeletonItem />
          </Skeleton>
        )
      case 'done':
        return (
          <UserProfile
            userId={userEmail.data}
            fallback={userEmail.data}
            customUserPhotoClassName={styles.customUserPhoto}
            customUserDisplayNameClassName={styles.customUserName}
          />
        )
      case 'error':
        return <ErrorView type="inline" error={userEmail.error} />
      default:
        return null
    }
  }, [styles.customUserName, styles.customUserPhoto, userEmail.data, userEmail.error, userEmail.status])

  return <TableCellLayoutWithStyles>{content}</TableCellLayoutWithStyles>
})

const useStyles = makeStyles({
  customUserPhoto: {
    width: '24px',
    height: '24px',
    ...shorthands.borderRadius('50%'),
  },
  customUserName: {
    fontSize: tokens.fontSizeBase300,
  },
})
RenderUserProfile.displayName = 'RenderUserProfile'
