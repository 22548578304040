import { Avatar, Text, Tooltip } from '@fluentui/react-components'
import { FC } from 'react'
import { useStyles } from './ActivityTitle.styles'
import { UserPhoto } from '../../User/UserPhoto'
import { Row } from '../../Layout'
interface IProps {
  readonly userId: string
}

export const ActivityTitleAvatarForUser: FC<IProps> = ({ userId }) => {
  const styles = useStyles()
  const userSnapshot = app.store.use.getUserProfile(userId)
  const user = userSnapshot.data

  return user?.displayName ? (
    <Tooltip content={user?.displayName} relationship={'label'}>
      <Row vAlign="center">
        <UserPhoto userId={userId} className={styles.userPhoto} />
        <Text className={styles.text}>{user?.displayName}</Text>
      </Row>
    </Tooltip>
  ) : (
    <Tooltip content={<>Unknown User({userId})</>} relationship={'label'}>
      <Row vAlign="center">
        <Avatar className={styles.userPhoto} />
        <Text className={styles.text}>Unknown User</Text>
      </Row>
    </Tooltip>
  )
}
