import { makeStyles } from '@fluentui/react-components'
import { Column, Row, Spacer } from '../../../components/Layout'
import { UnknownScreenGoHomeButton } from '../children/UnknownScreenGoHomeButton'
import { UnknownScreenSubtitle } from '../children/UnknownScreenSubtitle'
import { UnknownScreenTitle } from '../children/UnknownScreenTitle'

interface IProps {
  readonly path: string
}

export function UnknownScreenLayout(props: IProps) {
  const styles = useStyles()

  return (
    <Row vAlign="start" hAlign="center">
      <Column fill vAlign="center" hAlign="start" className={styles.container}>
        <UnknownScreenTitle />
        <Spacer size="8px" />
        <UnknownScreenSubtitle path={props.path} />
        <Spacer size="16px" />
        <UnknownScreenGoHomeButton />
      </Column>
    </Row>
  )
}

const useStyles = makeStyles({
  container: {
    width: 'auto',
    padding: '48px',
  },
})
