import { OPGProductEndpoints, ProductIds, ScenarioNames } from '@copilot-dash/domain'
import { TicketScreenMainTab } from '../store/IState'

export async function getSupportedMainTabs(ticketId: string): Promise<TicketScreenMainTab[]> {
  const ticket = await app.store.actions.getTicket(ticketId)
  const manifest = await app.store.actions.getTicketConversationManifest(ticketId)
  if (manifest.turns.length === 0) {
    return []
  }

  return Object.values(TicketScreenMainTab).filter((tab) => {
    switch (tab) {
      case TicketScreenMainTab.SymptomReport:
        return supportSymptomReport()
      case TicketScreenMainTab.ConversationData:
      case TicketScreenMainTab.Telemetry:
      case TicketScreenMainTab.KustoLog:
        return true
    }
  })

  function supportSymptomReport(): boolean {
    const isM365Chat = ticket.productId === ProductIds.M365Chat
    const isAIF = ticket.scenarioName?.toLowerCase() === ScenarioNames.AIF.toLowerCase()
    const isOPG = OPGProductEndpoints.some((client) => client?.toLowerCase() === ticket.clientName?.toLowerCase())

    return isM365Chat && !isAIF && !isOPG
  }
}
