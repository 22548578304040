import { PromiseSnapshot, PromiseSnapshots } from '@copilot-dash/core'
import { IProductChannelData } from '@copilot-dash/domain'
import { useMemo } from 'react'
import { useDashStore } from './DashStoreProvider'

interface IOptions {
  readonly productId: number | undefined
}

export function useProductIdChannels({ productId }: IOptions): PromiseSnapshot<IProductChannelData[]> {
  const store = useDashStore()
  const snapshot = store.use.getProducts()
  return useMemo(() => {
    return PromiseSnapshots.map(snapshot, (products) => {
      return products.find((e) => e.productId === productId)?.channels ?? []
    })
  }, [productId, snapshot])
}
