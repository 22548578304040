import { makeStyles, TabList } from '@fluentui/react-components'
import { Column, Spacer } from '../../../../components/Layout'
import { useScrollbar } from '../../../../components/Scrollbar/useScrollbar'
import { SettingsScreenTab, useSettingsScreenStore } from '../../store'
import { SettingsScreenOutlineTile } from './SettingsScreenOutlineTile'
import { z } from 'zod'

export function SettingsScreenOutlineBody() {
  const styles = useStyles()
  const scrollbar = useScrollbar()
  const selectedTab = useSettingsScreenStore((state) => state.selectedTab)

  const onSelected = (tab: SettingsScreenTab) => {
    useSettingsScreenStore.setState({ selectedTab: tab })
  }

  return (
    <Column ref={scrollbar} className={styles.root}>
      <Spacer height="8px" />
      <TabList
        selectedValue={selectedTab}
        vertical
        onTabSelect={(_, data) => {
          const tab = z.nativeEnum(SettingsScreenTab).safeParse(data.value).data
          if (tab) {
            onSelected(tab)
          }
        }}
      >
        <SettingsScreenOutlineTile tab={SettingsScreenTab.General} />
        <SettingsScreenOutlineTile tab={SettingsScreenTab.Labs} />
      </TabList>
      <Spacer height="40px" />
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    paddingLeft: '12px',
    paddingRight: '12px',
  },
})
