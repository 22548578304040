import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoStateDurationLogItem, ITicketSessionInteractionData, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicketSession } from '../actions-ticket/getTicketSession'
import { validateTicketKustoExpiration } from '../validators/validateTicketKustoData'
import { validateTicketUserConsent } from '../validators/validateTicketUserConsent'
import { convertToStateAndDurationLogs } from './converter/convertToStateAndDurationLogs'

export function getStateDurationLog(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IKustoStateDurationLogItem[]> {
  return context.getOrFetch<IKustoStateDurationLogItem[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.stateAndDurationLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.stateAndDurationLogs = snapshot
    },
    fetch: async () => {
      // Check User consent
      const session = await getTicketSession(context, ticketId).promise
      validateTicketUserConsent(ticketId, session)

      // Check interaction
      const interaction = session.interactions.find((item) => item.messageId === messageId)
      if (!interaction) {
        // Check ticket Kusto expiration
        validateTicketKustoExpiration(ticketId, KustoLogTable.BizChat3SLatencyLog, session)

        // Check failed
        throw TicketError.create('NoKustoDueToInteractionFieldIsMissing', {
          ticketId,
          kustoLogTable: KustoLogTable.BizChat3SLatencyLog,
        })
      }

      const logs = await doFetch(interaction)
      if (logs.length > 0) {
        return logs
      }

      // Check ticket Kusto expiration
      validateTicketKustoExpiration(ticketId, KustoLogTable.BizChat3SLatencyLog, session)

      // Check failed
      throw TicketError.create('NoKusto', { ticketId, kustoLogTable: KustoLogTable.StateDurationLog })
    },
  })

  async function doFetch(interaction: ITicketSessionInteractionData): Promise<IKustoStateDurationLogItem[]> {
    const response = await context.api.logCollector.getSydneySingleInfo({
      FetchLogName: 'stateDurationLog',
      HappenTimeArray: interaction.interactionTime ? [interaction.interactionTime] : [],
      MessageId: messageId,
    })

    return convertToStateAndDurationLogs(response)
  }
}
