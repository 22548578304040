import { makeStyles, tokens } from '@fluentui/react-components'
import { Body, Row } from '../../../components/Layout'
import { SettingsScreenMain } from '../children/main/SettingsScreenMain'
import { SettingsScreenOutline } from '../children/outline/SettingsScreenOutline'

export function SettingsScreenLayout() {
  const styles = useStyles()

  return (
    <Row>
      <SettingsScreenOutline className={styles.outline} />
      <Body className={styles.main}>
        <SettingsScreenMain />
      </Body>
    </Row>
  )
}

const useStyles = makeStyles({
  outline: {
    width: '220px',
    backgroundColor: tokens.colorNeutralBackground2,
  },
  main: {
    boxShadow: tokens.shadow2,
  },
})
