import { INewTicketData } from '@copilot-dash/domain'

export const findCommonCustomTags = (tickets: INewTicketData[]): string[] => {
  if (tickets.length === 0) return []
  let commonTags = new Set(tickets[0]?.customTags ?? [])
  for (let i = 1; i < tickets.length; i++) {
    const ticketTags = new Set(tickets[i]?.customTags ?? [])
    commonTags = new Set([...commonTags].filter((tag) => ticketTags.has(tag)))
  }

  return Array.from(commonTags)
}
