import { makeStyles } from '@fluentui/react-components'
export const SMALL_SCREEN_WIDTH = 1000

export const useStyles = makeStyles({
  wrapper: {
    marginBottom: '16px',
    rowGap: '8px',
  },

  utteranceAndResponseFilter: {
    width: '236px',
    flexShrink: 0,
    flexGrow: 0,
    height: '32px',
  },
  emailFilter: {
    width: '236px',
    flexShrink: 0,
    flexGrow: 0,
    height: '32px',
  },
  tenantIds: {
    width: '236px',
    flexShrink: 0,
    flexGrow: 0,
    height: '32px',
    minWidth: '236px',
  },
  right: {
    overflow: 'visible',
  },
})
