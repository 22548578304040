import { MessageBar, MessageBarBody } from '@fluentui/react-components'
import { useStyles } from './AvoidShareDataMessage.styles'
import * as React from 'react'

export function AvoidShareDataMessage() {
  const styles = useStyles()
  const KEY_AVOID_SHARE_MESSAGE_SHOWN = 'avoid_share_message_shown'

  const [showMessage, setShowMessage] = React.useState(false)
  const [messageShown] = React.useState(() => !!localStorage.getItem(KEY_AVOID_SHARE_MESSAGE_SHOWN))

  React.useEffect(() => {
    setTimeout(() => {
      setShowMessage(true)
    }, 10000)
  }, [])
  React.useEffect(() => {
    if (!messageShown) {
      localStorage.setItem(KEY_AVOID_SHARE_MESSAGE_SHOWN, 'true')
    }
  }, [messageShown])
  return (
    !messageShown &&
    !showMessage && (
      <MessageBar shape="square" intent="info" layout="singleline">
        <MessageBarBody className={styles.messageBody}>
          Please avoid sharing customer information from CopilotDash elsewhere to avoid any data compliance problems.
        </MessageBarBody>
      </MessageBar>
    )
  )
}
