import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { createGlobalStoreActions } from './createGlobalStoreActions'
import { createGlobalStoreState } from './createGlobalStoreState'
import { IStore } from './IStore'
import { createTeamStore } from './data/team/createTeamStore'

export const useGlobalStore = create<IStore, [['zustand/immer', never]]>(
  immer((...args) => {
    const set = args[0]
    const get = args[1]
    return {
      ...createGlobalStoreState(get),
      ...createGlobalStoreActions(set, get),
      team: createTeamStore(...args),
    }
  }),
)
