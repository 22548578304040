import {
  ApiOdsInteractionInfos,
  apiOdsInteractionInfosSchema,
  ApiOdsTicketData,
  parseBase64GzipStringTo,
} from '@copilot-dash/api'
import { z } from 'zod'

export function convertToRawTicketInteractions(api: ApiOdsTicketData): ApiOdsInteractionInfos[] {
  // Case 1: InteractionInfoGZip is present
  if (api.InteractionInfoGZip) {
    return parseBase64GzipStringTo(api.InteractionInfoGZip, z.array(apiOdsInteractionInfosSchema))
  }

  // Case 2: InteractionInfos is present
  if (api.InteractionInfos) {
    return api.InteractionInfos
  }

  // Case 3: Non converged ticket
  // This is a fallback for non converged tickets before June 2024
  const ConversationId = z.string().safeParse(api.ConvId).data
  const InteractionTimeStr = z.string().safeParse(api.UtteranceHappenTime).data
  const TransactionIds = z.string().array().safeParse(api.TransactionIds).data ?? []
  const ImpressionIdsString = z.string().safeParse(api.ImpressionIds).data
  const ImpressionIds = ImpressionIdsString?.split(',')
    .map((item) => item.trim())
    .filter((item) => item)

  return [
    {
      ConversationId,
      MessageId: api.TraceId,
      InteractionTimeStr,
      Index: 1,
      ClientName: api.ClientName ?? api['Client Name'],
      SubstrateSearchReplayInfoList: TransactionIds.map((transactionId) => ({
        TransactionId: transactionId,
      })),
      ImpressionIds,
    },
  ]
}
