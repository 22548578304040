import { ITagDefinition } from './ITagDefinition.type'
import { useSearchScreenState } from '../../store'
import { TruncateTagFunction } from './TruncateTagFunction'
import { GetTagValueTextFunction } from './GetTagValueTextFunction'

export const CustomerTagConfig = (): ITagDefinition => {
  const form = useSearchScreenState((state) => state.form)
  const valueTexts = GetTagValueTextFunction('User Profile', 'Customer Type', form.customerTypes || [])

  return {
    key: 'customerTypes',
    tagName: 'Customer type',
    toolTipContentValue: valueTexts.join(', '),
    truncatedTagValue: TruncateTagFunction(valueTexts),
    IsShowTag: false,
    isTagClearable: true,
  }
}
