import { KeywordsHighLightText } from './KeywordsHighLightText'
import { memo, FC, useMemo } from 'react'
import { Row } from '../Layout'
import { ReplaceMarkdownSyntax } from './HandleMarkdownSyntax'
import { useStyles } from './ToolTipText.styles'
import { tokens } from '@fluentui/react-components'
import { extractKeywordContent } from './utils'
import { FloatingTooltip, FloatingTooltipContent, FloatingTooltipTrigger } from '../FloatingTooltip'
interface IProps {
  text: string
  keywords?: string
  isTwoLinesTruncate?: boolean
  className?: string
}
export const ToolTipText: FC<IProps> = memo(({ text, keywords, isTwoLinesTruncate = false, className }) => {
  const replacedText = useMemo(() => {
    let replacedText = text
    if (keywords) {
      replacedText = extractKeywordContent(text, keywords)
    }
    return ReplaceMarkdownSyntax(replacedText)
  }, [text, keywords])
  const styles = useStyles()

  return (
    <FloatingTooltip>
      <FloatingTooltipTrigger>
        <Row>
          <KeywordsHighLightText
            text={replacedText}
            keywords={keywords}
            isTwoLinesTruncate={isTwoLinesTruncate}
            className={className}
          />
        </Row>
      </FloatingTooltipTrigger>
      <FloatingTooltipContent className={styles.tooltipContent} arrowColor={tokens.colorNeutralBackground3}>
        <KeywordsHighLightText text={text} keywords={keywords} className={className} />
      </FloatingTooltipContent>
    </FloatingTooltip>
  )
})
ToolTipText.displayName = 'ToolTipText'
