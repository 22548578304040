import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  countText: {
    paddingInline: '8px',
    fontWeight: tokens.fontWeightMedium,
  },
  skeleton: {
    width: '200px',
  },
})
