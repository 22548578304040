import { tokens } from '@fluentui/react-components'

export const transformCommentValue = async (
  oldCommentValue: string | undefined,
  activeAccountId?: string | undefined,
): Promise<string | undefined> => {
  let updatedValue = oldCommentValue

  const mentions = oldCommentValue?.match(/@<([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})>/g)
  if (mentions) {
    for (const mention of mentions) {
      const email = mention.replace('@<', '').replace('>', '')
      const displayName = (await app.store.actions.getUserProfile(email)).displayName
      const currentUser = activeAccountId && (await app.store.actions.getUserProfile(activeAccountId)).mail
      const isUserSelf = email === currentUser
      const color = isUserSelf ? tokens.colorStatusDangerBackground3 : tokens.colorPaletteCornflowerBorderActive
      const fontWeight = isUserSelf ? 'bold' : 'normal'
      updatedValue = updatedValue?.replace(
        mention,
        `<span style="color: ${color}; font-weight: ${fontWeight};">@${displayName}</span>`,
      )
    }
  }

  return updatedValue
}

const imgRegex = /<img[^>]*src=["']([^"']+)["'][^>]*>/g
export function cleanCommentValue(htmlString: string | undefined): string {
  if (!htmlString) {
    return ''
  }
  const sasTokenPrefix = '?skoid'
  return htmlString.replace(imgRegex, (match, src) => {
    if (src.includes(sasTokenPrefix)) {
      const cleanedSrc = src.split(sasTokenPrefix)[0]
      return match.replace(src, cleanedSrc)
    }
    return match
  })
}

export async function updateImageSrc(htmlString: string): Promise<string> {
  const matches = [...htmlString.matchAll(imgRegex)]
  let updatedHtmlString = htmlString

  for (const match of matches) {
    const originalSrc = match[1]
    if (originalSrc) {
      try {
        const response = await application.store.actions.getAttachmentUrl(decodeURIComponent(originalSrc))
        const newSrc = response.url
        updatedHtmlString = updatedHtmlString.replace(originalSrc, newSrc)
      } catch (error) {
        return updatedHtmlString
      }
    }
  }
  return updatedHtmlString
}
