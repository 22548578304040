import { AnyDataStore } from '../../AnyDataStore'
import { AnyDataTab } from '../../store/IState'
import { AnyTableFooter } from './table/AnyTableFooter'

export function AnyDataFooter() {
  const selectedTab = AnyDataStore.use((state) => state.selectedTab)

  switch (selectedTab) {
    case AnyDataTab.Table:
      return <AnyTableFooter />
    case AnyDataTab.Json:
      return null
  }
}
