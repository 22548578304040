import { INewTicketData, ColumnKey, ColumnIdNaColumnIdNameMapping, SearchTextPrefixType } from '@copilot-dash/domain'
import { ResponsiveResponseColumnComponent } from './ResponsiveResponseColumnComponent'
import { createExtendedTableColumn, ExtendedTableColumnDefinition } from '../Table/ExtendedTableColumn'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'

export const ColumnConfigResponse = (
  searchTextPrefix: SearchTextPrefixType,
  keyWords?: string,
): ExtendedTableColumnDefinition<INewTicketData> =>
  createExtendedTableColumn<INewTicketData>({
    columnId: ColumnKey.Response,
    columnName: ColumnIdNaColumnIdNameMapping[ColumnKey.Response],
    isResizable: true,
    flex: 2,
    columnSize: {
      minWidth: 150,
      idealWidth: 350,
      padding: 0,
    },
    renderHeaderCell: () => <TableCellLayoutWithStyles truncate>Response</TableCellLayoutWithStyles>,
    renderCell: (item: INewTicketData) => (
      <TableCellLayoutWithStyles truncate>
        {item.ticketId && (
          <ResponsiveResponseColumnComponent item={item} searchTextPrefix={searchTextPrefix} keyWords={keyWords} />
        )}
      </TableCellLayoutWithStyles>
    ),
  })
