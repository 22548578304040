import { SymptomReportItem } from '@copilot-dash/domain'
import { makeStyles, TableRow, tokens } from '@fluentui/react-components'
import { ItemCellKey } from './ItemCellKey'
import { ItemCellValue } from './ItemCellValue'

interface IProps {
  readonly item: SymptomReportItem
}

export function ItemRow({ item }: IProps) {
  const styles = useStyles()

  return (
    <TableRow className={styles.root} appearance="none">
      <ItemCellKey item={item} />
      <ItemCellValue item={item} />
    </TableRow>
  )
}

const useStyles = makeStyles({
  root: {
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground2,
    },

    ':active': {
      backgroundColor: tokens.colorNeutralBackground2,
    },
  },
})
