import { Tooltip, mergeClasses } from '@fluentui/react-components'
import * as React from 'react'
import { Column } from '../Layout'
import { useScrollbar } from '../Scrollbar/useScrollbar'
import { useStyles } from './CopilotDashTooltip.styles'

interface ITooltipProps {
  relationShip: 'label' | 'description' | 'inaccessible'
  toolTipContent: JSX.Element | string
  toolTipParentContent?: JSX.Element | null
  className?: string
}

export const CopilotDashTooltip: React.FC<ITooltipProps> = React.memo(
  ({ relationShip, toolTipContent, toolTipParentContent, className }) => {
    const styles = useStyles()
    const scrollbarRef = useScrollbar()

    return (
      <Tooltip
        withArrow
        relationship={relationShip}
        content={{
          onClick: (e) => {
            e.stopPropagation()
          },
          children: (
            <Column ref={scrollbarRef} className={mergeClasses(styles.scrollbar)} vAlign="start">
              {toolTipContent}
            </Column>
          ),
          className: mergeClasses(styles.tooltip, className),
        }}
      >
        {toolTipParentContent}
      </Tooltip>
    )
  },
)
CopilotDashTooltip.displayName = 'CopilotDashTooltip'
