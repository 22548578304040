import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  msgHeaderContainer: { display: 'flex', alignItems: 'flex-end' },
  messageBarTime: {
    color: tokens.colorNeutralStrokeAccessible,
    fontSize: '12px',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '40%',
  },
  messageUserName: {
    color: tokens.colorNeutralForeground1,
    fontSize: '14px',
    fontWeight: tokens.fontWeightBold,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '50%',
  },
  skeletonItem: {
    maxWidth: '50%',
  },
})
