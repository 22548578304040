import { useMemo, useRef, useState } from 'react'
import { Column, Row, Spacer } from '../../../../../../../components/Layout'
import { Link, Portal, Text } from '@fluentui/react-components'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IAny = any
type IChartJS = IAny
type IChartContext = IAny

const getOrCreateTooltip = (chart: IChartJS) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('.copilot-chartjs-tooltip')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.className = 'copilot-chartjs-tooltip'
    tooltipEl.style.background = '#fff'
    tooltipEl.style.boxShadow = '0 0 8px rgba(0, 0, 0, 0.6)'
    tooltipEl.style.opacity = 0
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.transform = 'translate(-50%, 0)'
    tooltipEl.style.transition = 'all .1s ease'
    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  return tooltipEl
}

export const useDoughnutTooltip = (onViewDSATsClick: (title: string) => void) => {
  const [tooltipElement, setTooltipElement] = useState<HTMLElement | null>(null)
  const [focusedTitle, setFocusedTitle] = useState<string>('')
  const [focusedCount, setFocusedCount] = useState<string>('')
  const [focusedProportion, setFocusedProportion] = useState<string>('')
  const [dotColor, setDotColor] = useState<string>('#fff')

  const isTooltipHoveredRef = useRef(false)

  const chartJsTooltipPlugin = useMemo(() => {
    return {
      enabled: false,
      external: (context: IChartContext) => {
        // Tooltip Element
        const { chart, tooltip: tooltipModel } = context
        const tooltipEl = getOrCreateTooltip(chart)
        !tooltipElement && setTooltipElement(tooltipEl)
        // Hide if no tooltip
        if (tooltipModel.opacity === 0 && !isTooltipHoveredRef.current) {
          // delay hide tooltip for 200ms to prevent flickering
          setTimeout(() => {
            if (!isTooltipHoveredRef.current) {
              tooltipEl.style.opacity = 0
            }
          }, 200)
        }
        if (tooltipModel.dataPoints.length === 0) {
          return
        }
        setFocusedTitle(tooltipModel.dataPoints[0].label)
        setFocusedCount(tooltipModel.dataPoints[0].formattedValue)

        const total = tooltipModel.dataPoints[0].dataset.data.reduce((a: number, b: number) => a + b, 0)
        setFocusedProportion(
          total > 0 ? Number((tooltipModel.dataPoints[0].raw / total) * 100).toFixed(2) + '%' : '0.00%',
        )
        setDotColor(tooltipModel.labelColors[0].backgroundColor)

        const position = context.chart.canvas.getBoundingClientRect()
        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1
        tooltipEl.style.position = 'absolute'
        tooltipEl.style.left = position.left + window.scrollX + tooltipModel.caretX + 'px'
        tooltipEl.style.top = position.top + window.scrollY + tooltipModel.caretY + 'px'
        tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px'
      },
    }
  }, [tooltipElement])

  const tooltipReactElement = useMemo(() => {
    if (!tooltipElement) {
      return null
    }
    return (
      <Portal mountNode={tooltipElement}>
        <Column
          style={{ padding: '16px' }}
          onMouseEnter={() => {
            isTooltipHoveredRef.current = true
          }}
          onMouseLeave={() => {
            isTooltipHoveredRef.current = false
            tooltipElement && (tooltipElement.style.opacity = '0')
          }}
        >
          <Row vAlign="center">
            <div style={{ backgroundColor: dotColor, width: '8px', height: '8px' }} />
            <Spacer width={8} />
            <Text>{focusedTitle}</Text>
            <Spacer width={30} />
            <Text>{focusedCount}</Text>
            <Text>({focusedProportion})</Text>
          </Row>
          <Spacer height={12} />
          <Link onClick={() => onViewDSATsClick(focusedTitle)}>View DSATs</Link>
        </Column>
      </Portal>
    )
  }, [dotColor, focusedCount, focusedProportion, focusedTitle, onViewDSATsClick, tooltipElement])

  return {
    tooltipReactElement,
    chartJsTooltipPlugin,
  }
}
