import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  buttonBase: {
    borderRadius: '8px',
    padding: '7px 20px',
    border: 'none',
    fontSize: '16px',
    backgroundColor: tokens.colorNeutralForeground2Selected,
    color: tokens.colorNeutralForegroundInverted,
  },
  buttonEnabled: {
    cursor: 'pointer',
    opacity: 1,
  },
  buttonDisabled: {
    cursor: 'not-allowed',
    opacity: 0.7,
  },
  containerStyle: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    gap: '10px',
    padding: '10px',
  },
  titleStyle: {
    padding: '10px',
    fontSize: '40px',
  },
  pageStyle: {
    overflow: 'auto',
    padding: '20px',
  },
  dataContainer: {
    paddingTop: '20px',
  },
  spinnerStyle: {
    paddingTop: '100px',
  },
})
