import { IDashAuth } from '@copilot-dash/auth'
import { ProductNames, DefaultProductChannelMapping } from '@copilot-dash/domain'
import { Route } from 'react-router-dom'
import { createNavigator } from '../../navigator/createNavigator'
import { VoidMapper } from '../../utils/VoidMapper'
import { LoginRoute } from '../login/LoginRoute'
import { SearchRoute } from '../product.search/SearchRoute'

export function HomeRoute(auth: IDashAuth) {
  return (
    <Route
      id={ID}
      path={PATH}
      loader={() => {
        if (auth.isAuthenticated) {
          return SearchRoute.navigator.generateRedirectResponse({
            product: ProductNames.M365Chat,
            tab: 'allFeedback',
            channel: DefaultProductChannelMapping[ProductNames.M365Chat],
          })
        } else {
          return LoginRoute.navigator.generateRedirectResponse({})
        }
      }}
    />
  )
}

const ID = 'home'
const PATH = '/'
HomeRoute.navigator = createNavigator(ID, PATH, VoidMapper)
