import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { createSettingsScreenActions } from './createSettingsScreenActions'
import { createSettingsScreenState } from './createSettingsScreenState'
import { ISettingsScreenActions } from './ISettingsScreenActions'
import { ISettingsScreenState } from './ISettingsScreenState'

export const useSettingsScreenStore = create<ISettingsScreenState & ISettingsScreenActions>()(
  immer((set, get) => ({
    ...createSettingsScreenState(),
    ...createSettingsScreenActions(set, get),
  })),
)
