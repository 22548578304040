import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    marginLeft: '32px',
    marginRight: '8px',
    width: '95%',
    maxHeight: '300px',
    backgroundColor: tokens.colorNeutralBackground2,
    borderRadius: '4px',
  },
})
