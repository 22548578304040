import { apiV2SymptomReportStringArraySchema } from '@copilot-dash/api'
import { SymptomReportItem } from '@copilot-dash/domain'

export function parseToStringArray(key: string, value: unknown): SymptomReportItem | undefined {
  const result = apiV2SymptomReportStringArraySchema.safeParse(value)
  if (!result.success) {
    return
  }

  return {
    key,
    value: result.data,
    valueType: 'StringArray',
  }
}
