import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
export const useStyles = makeStyles({
  section: {
    width: '100%',
    ...shorthands.margin('16px', '0px', '0px', '0px'),
  },
  card: {
    maxWidth: '100%',
    height: 'fit-content',
    position: 'relative',
    gap: '8px',
    borderRadius: '3px',
    ...shorthands.margin('4px', '0px', '0px', '0px'),
    ...shorthands.padding('10px'),
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
  },
  cardFeedbackTag: {
    gap: '4px',
  },
  cardDiv: {
    maxWidth: '100%',
    height: 'fit-content',
    position: 'relative',
    ...shorthands.margin('4px', '0px', '0px', '0px'),
    ...shorthands.padding('0px', '10px', '0px', '10px'),
    ...shorthands.borderRadius('3px'),
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
  },

  title: {
    ...shorthands.margin(0, 0, '12px'),
  },

  horizontalCardImage: {
    width: '64px',
    height: '64px',
  },

  headerImage: {
    ...shorthands.borderRadius('4px'),
    maxWidth: '44px',
    maxHeight: '44px',
  },

  caption: {
    color: tokens.colorNeutralForeground3,
  },

  text: {
    ...shorthands.margin(0),
  },
  textTransparent: {
    ...shorthands.margin(0),
    color: tokens.colorTransparentBackground,
  },
})
