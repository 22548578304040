import { apiV2SymptomReportValueBooleanSchema } from '@copilot-dash/api'
import { SymptomReportItem } from '@copilot-dash/domain'

export function parseToBoolean(key: string, value: unknown): SymptomReportItem | undefined {
  const result = apiV2SymptomReportValueBooleanSchema.safeParse(value)
  if (!result.success) {
    return
  }

  let booleanValue: boolean
  switch (result.data) {
    case 'True':
    case 'On':
      booleanValue = true
      break
    case 'False':
    case 'Off':
      booleanValue = false
      break
  }

  return {
    key,
    value: booleanValue,
    valueType: 'Boolean',
    valueOriginal: result.data,
  }
}
