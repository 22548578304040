import { z } from 'zod'
import { ApiCopilotTicketDetailContext, apiCopilotTicketDetailContextSchema } from './ApiCopilotTicketContext'

export interface ApiCopilotTicketDetailResponse {
  readonly copilotTicketDetailContext?: ApiCopilotTicketDetailContext
}

export const apiCopilotTicketDetailResponseSchema = z.object({
  copilotTicketDetailContext: apiCopilotTicketDetailContextSchema.optional(),
})
