import { ZodType } from 'zod'
import { RouteDataUtils } from './RouteDataUtils'

export class RouteDataReader {
  private readonly map: { [key: string]: unknown }

  constructor(map?: { [key: string]: unknown }) {
    this.map = map ?? {}
  }

  getString(key: string): string | undefined {
    return RouteDataUtils.parseAsString(this.map[key])
  }

  getNumber(key: string): number | undefined {
    return RouteDataUtils.parseAsNumber(this.map[key])
  }

  getBoolean(key: string): boolean | undefined {
    return RouteDataUtils.parseAsBoolean(this.map[key])
  }

  getDate(key: string): Date | undefined {
    return RouteDataUtils.parseAsDate(this.map[key])
  }

  getEnum<T extends string>(key: string, enumType: Record<string, T>): T | undefined {
    return RouteDataUtils.parseAsEnum(this.map[key], enumType)
  }

  getStringArray(key: string): Array<string> | undefined {
    return RouteDataUtils.parseAsStringArray(this.map[key])
  }

  getEnumArray<T extends string>(key: string, enumType: Record<string, T>): Array<T> | undefined {
    return RouteDataUtils.parseAsEnumArray(this.map[key], enumType)
  }

  get<T>(key: string, schema: ZodType<T>): T | undefined {
    return schema.safeParse(this.map[key]).data
  }

  require<T>(key: string, schema: ZodType<T>): T {
    const raw = this.map[key]
    if (raw === undefined) {
      throw new Error(`Failed to parse key '${key}' in route data. Missing required parameter '${key}'`)
    }

    const result = schema.safeParse(this.map[key])
    if (!result.success) {
      throw new Error(`Failed to parse key '${key}' in route data. ${result.error.message}`)
    }

    return result.data
  }
}
