import { TableCellLayout, TableCellLayoutProps, mergeClasses } from '@fluentui/react-components'
import { useStyles } from './TableCellLayoutWithStyles.styles'

export const TableCellLayoutWithStyles: React.FC<TableCellLayoutProps> = (props) => {
  const styles = useStyles()
  return (
    <TableCellLayout
      {...props}
      className={mergeClasses(styles.tableCellLayout, props.className)}
      truncate={props.truncate}
    />
  )
}
