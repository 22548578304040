import { Button } from '@fluentui/react-components'
import { FullScreenMaximizeRegular } from '@fluentui/react-icons'
import { MonacoJsonViewDialog } from '../../../../../../../../components/Monaco/MonacoJsonViewDialog'

interface IProps {
  readonly data: unknown
}

export function TreeItemOpenButton({ data }: IProps) {
  if (!data) {
    return null
  }

  const handleClick = () => {
    app.dialog.open({
      content: <MonacoJsonViewDialog data={data} />,
      title: true,
    })
  }

  return <Button appearance="subtle" icon={<FullScreenMaximizeRegular />} title="Open" onClick={handleClick} />
}
