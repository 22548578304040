import { ITicketChatTurn } from '@copilot-dash/domain'
import { Tag } from '@fluentui/react-components'

interface IProps {
  readonly data: ITicketChatTurn
}

export function MessageLabel({ data }: IProps) {
  switch (data.source) {
    case 'conversation-legacy':
    case 'conversation-group1':
      return null
    case 'offline-group1':
      return (
        <Tag appearance="brand" size="small">
          Offline
        </Tag>
      )
  }
}
