import { makeStyles, shorthands } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    ...shorthands.overflow('hidden', 'auto'),
    ...shorthands.gap('24px'),
    columnGap: 0,
    ...shorthands.padding('0px', '0px', '0px', '16px'),
  },
  body: {
    ...shorthands.overflow('hidden', 'auto'),
    ...shorthands.gap('24px'),
    columnGap: 0,
    ...shorthands.padding('0px', '46px', '0px', '16px'),
  },
  table: {
    ...shorthands.flex(1),
  },
  right: {
    boxShadow: '0px 0px 16px rgba(0, 0, 0, .14)',
    height: '100%',
    '& .fui-Drawer': {
      transitionDuration: '0s',
    },
  },
})
