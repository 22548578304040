import { z } from 'zod'
import { ApiTicketContext, apiTicketContextSchema } from './ApiTicketContext'

export interface ApiTicket {
  readonly context: ApiTicketContext
}

export const apiTicketSchema = z.object({
  context: apiTicketContextSchema,
})
