import { Column } from '../../../Layout'
import { Text, Tooltip } from '@fluentui/react-components'
import { useStyles } from './PropertyNameComponent.styles'

interface IPropertyItemNameProps {
  name: string
  subName?: string
}

export const PropertyNameComponent: React.FC<IPropertyItemNameProps> = ({ name, subName }) => {
  const styles = useStyles()

  return (
    <Tooltip content={subName ?? name} relationship="label">
      <Column vAlign="center" hAlign="start" className={styles.wrapper}>
        <Text weight="semibold" wrap={false} truncate className={styles.text}>
          {name}
        </Text>
        {subName && (
          <Text wrap={false} truncate className={styles.text}>
            {subName}
          </Text>
        )}
      </Column>
    </Tooltip>
  )
}
