import { AuthProviderCallback, Client } from '@microsoft/microsoft-graph-client'
import { User } from '@microsoft/microsoft-graph-types'
import { DashApiOptions } from '../../DashApiOptions'
import { MICROSOFT_GRAPH_SCOPES } from './MicrosoftGraphScopes'
import { ODataResponse } from './types'

export class MicrosoftGraphApi {
  private readonly options: DashApiOptions
  private readonly client: Client

  constructor(options: DashApiOptions) {
    this.options = options
    this.client = Client.init({
      authProvider: this.authProvider.bind(this),
    })
  }

  private async authProvider(done: AuthProviderCallback) {
    try {
      const token = await this.options.acquireToken(MICROSOFT_GRAPH_SCOPES)
      done(null, token)
    } catch (error) {
      done(error, null)
    }
  }

  /**
   * https://developer.microsoft.com/en-us/graph/graph-explorer?request=me%2Fphoto%2F%24value&version=v1.0
   */
  async getMyProfile(): Promise<User> {
    return await this.client.api('/me').get()
  }

  /**
   * https://developer.microsoft.com/en-us/graph/graph-explorer/?request=me%2Fphoto%2F%24value&version=v1.0
   */
  async getMyPhoto(): Promise<Blob> {
    return await this.client.api('/me/photo/$value').get()
  }

  async getUserPhoto(idOrEmail: string): Promise<Blob> {
    return await this.client.api(`/users/${encodeURIComponent(idOrEmail)}/photo/$value`).get()
  }

  /**
   * https://learn.microsoft.com/en-us/graph/search-query-parameter?tabs=http
   * TODO: update to access /me/people endpoint with People.Read Scope: https://developer.microsoft.com/en-us/graph/graph-explorer?request=me%2Fpeople%3F%24search%3Dxiaoml&method=GET&version=v1.0&GraphUrl=https://graph.microsoft.com
   */
  async searchUsers(input: string): Promise<ODataResponse> {
    const encodedInput = encodeURIComponent(input.trim())

    return await this.client
      .api('/users')
      .count(true)
      .header('ConsistencyLevel', 'eventual')
      .filter(
        `(startswith(userPrincipalName, '${encodedInput}') or startswith(displayName, '${encodedInput}')) and (endswith(userPrincipalName, '@microsoft.com') or endswith(userPrincipalName, '@ntdev.microsoft.com'))`,
      )
      .top(20)
      .get()
  }

  /**
   * https://learn.microsoft.com/en-us/graph/filter-query-parameter?tabs=http
   */
  async findUserByIdOrEmail(idOrEmail: string): Promise<User> {
    return await this.client.api(`/users/${encodeURIComponent(idOrEmail)}`).get()
  }
}
