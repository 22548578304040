import { ApiError } from '@copilot-dash/api'
import { PromiseSnapshot, dayjs } from '@copilot-dash/core'
import { IComment } from '@copilot-dash/domain'
import { sortBy } from 'lodash'
import { IDashStoreContext } from '../IDashStoreContext'

export function getTicketComments(context: IDashStoreContext, ticketId: string): PromiseSnapshot<IComment[]> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.comments
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.comments = snapshot
    },
    fetch: async () => {
      try {
        const response = await context.api.logCollector.getComments(ticketId)
        return sortBy(response.comments, (item) => dayjs(item.createTime).valueOf())
      } catch (error) {
        if (ApiError.getStatusCode(error) === 404) {
          return []
        } else {
          throw error
        }
      }
    },
  })
}
