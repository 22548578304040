import { IPublicClientApplication } from '@azure/msal-browser'
import { Logger } from '@copilot-dash/logger'
import { DashAuthConstants } from '../DashAuthConstants'
import { IDashAuthSwitchOptions } from '../IDashAuth'

export async function switchAccount(client: IPublicClientApplication, options?: IDashAuthSwitchOptions): Promise<void> {
  try {
    Logger.trace.info('Starting login switch account redirect flow')
    await client.loginRedirect({
      scopes: options?.scopes ?? DashAuthConstants.LOGIN_SCOPES,
      prompt: 'select_account',
    })

    await new Promise((resolve) => setTimeout(resolve, DashAuthConstants.REDIRECT_WAIT_TIME_MS))
    throw new Error('Failed to switch account by redirect flow. Cause: Timeout')
  } catch (e) {
    throw new Error('Failed to switch account by redirect flow. Cause:' + e)
  }
}
