import { ApiTicket, apiTicketSchema } from './ApiTicket'
import { z } from 'zod'

export interface ApiAIFTicketsResponse {
  readonly aifTickets: Array<ApiTicket>
  readonly hasMore: boolean
  readonly ticketCount?: number
  readonly errorMessage?: string
}

export const apiAIFTicketsResponseSchema = z.object({
  aifTickets: z.array(apiTicketSchema),
  hasMore: z.boolean(),
  ticketCount: z.number().optional(),
  errorMessage: z.string().optional(),
})
