import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import * as React from 'react'
import { ErrorViewBoundary } from '../../../components/Error'
import { Body, Column, Header } from '../../../components/Layout'
import { RootScreenHeader } from '../children/RootScreenHeader'
import { HeaderNotificationBar } from '../children/components/HeaderNotificationBar'

interface IProps {
  readonly children?: React.ReactNode
}

export function RootScreenLayout(props: IProps): React.JSX.Element {
  const styles = useStyles()

  return (
    <Column className={mergeClasses('RootScreenLayout', styles.root)} vAlign="stretch" hAlign="stretch">
      <Header className={styles.header}>
        <ErrorViewBoundary level="screen" label="RootScreenLayout:Header">
          <Column>
            <RootScreenHeader />
            {app.auth.permission?.general && <HeaderNotificationBar />}
          </Column>
        </ErrorViewBoundary>
      </Header>
      <Body>
        <ErrorViewBoundary level="screen" label="RootScreenLayout:Body">
          {props.children}
        </ErrorViewBoundary>
      </Body>
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    backgroundColor: tokens.colorNeutralBackground2,
  },
  header: {
    zIndex: 1,
  },
})
