import { ProductNames } from '@copilot-dash/domain'
import { redirect, Route } from 'react-router-dom'

export function SearchRouteForRedirecting() {
  return (
    <Route
      id={ID}
      path={PATH}
      loader={(args) => {
        const url = new URL(args.request.url)
        const urlSearchParams = url.searchParams

        // 1. Get product from query params
        const product = urlSearchParams.get('product')
        const encodedProduct = encodeURIComponent(product || ProductNames.M365Chat)

        // 2. Remove product from query params
        urlSearchParams.delete('product')

        return redirect(`/${encodedProduct}/search?${url.searchParams.toString()}`)
      }}
    />
  )
}

const ID = 'search'
const PATH = '/search'
