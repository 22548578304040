import { IPublicClientApplication } from '@azure/msal-browser'
import { Logger } from '@copilot-dash/logger'

export async function initialize(client: IPublicClientApplication): Promise<void> {
  // Step1: Initialize MSAL library
  try {
    await client.initialize()
  } catch (error) {
    throw new Error('Failed to initialize MSAL library. Please check!. Cause: ' + error)
  }

  // Step2: Handle redirect promise
  try {
    const result = await client.handleRedirectPromise()
    if (result) {
      Logger.trace.info('Login by redirect flow succeeded')
    }
  } catch (error) {
    Logger.trace.error('Failed to handle redirect promise from MSAL library. Please check!', error)
  }

  // Step3: Set active account
  const accounts = client.getAllAccounts()
  const activeAccount = client.getActiveAccount()
  const firstAccount = accounts[0]
  if (!activeAccount && firstAccount) {
    client.setActiveAccount(firstAccount)
  }
}
