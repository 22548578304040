import { ApiError } from '@copilot-dash/api'
import { ApiRequestTableEndpoint } from './ApiRequestTableEndpoint'
import { ApiRequestTableURL } from './ApiRequestTableURL'

interface IProps {
  readonly error: ApiError
}

export function ApiRequestTable({ error }: IProps) {
  return (
    <table>
      <tbody>
        {/* 1. Endpoint */}
        <tr>
          <td>Endpoint</td>
          <td>
            <ApiRequestTableEndpoint baseUrl={error.data.baseUrl} endpoint={error.data.endpoint} />
          </td>
        </tr>

        {/* 2. Method */}
        <tr>
          <td>Method</td>
          <td>{error.data.method}</td>
        </tr>

        {/* 3. URL */}
        <tr>
          <td>URL</td>
          <td>
            <ApiRequestTableURL error={error} />
          </td>
        </tr>
      </tbody>
    </table>
  )
}
