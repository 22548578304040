import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    overflow: 'auto',
  },
  tableContainer: {
    overflow: 'auto',
  },

  header: {
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },

  expandIcon: {
    marginRight: '20px',
    minWidth: '22px',
  },

  table: {
    marginTop: '12px',
    overflowX: 'auto',
    width: '100%',
    minWidth: 'fit-content',
  },

  headerRow: {
    height: '60px',
    borderTop: `1px solid ${tokens.colorNeutralStroke2}`,
    color: tokens.colorNeutralForeground4,
    margin: '0',
    padding: '0',
  },
  bodyRow: {
    minHeight: '60px',

    '&:hover .queriesActionIcon': {
      color: tokens.colorNeutralForeground2,
    },
  },
})
