import { ITicketSessionData } from '@copilot-dash/domain'

export function get3sOnlineErrorCode(data: ITicketSessionData, messageId: string | null): number | undefined {
  for (const interaction of data.interactions) {
    if (messageId === null || interaction.messageId === messageId) {
      if (interaction.diagnostic) {
        return interaction.diagnostic.substrateSearch[0]?.statusCode
      }
    }
  }

  return undefined
}
