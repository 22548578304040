import * as React from 'react'
import { TeamAreaLayout } from './TeamAreaLayout'
import { useStyles } from './TeamScreenLayout.styles'
import { TicketsLayout } from './TicketsLayout'
import { useTeamViewStore } from '../store'
import { teamViewTelemetry } from '../telemetry'
import { TelemetryScope } from '@copilot-dash/logger'
import { Row } from '../../../components/Layout'

export const TeamScreenLayout: React.FC = () => {
  const styles = useStyles()
  const lastSelectedTeam = useTeamViewStore((state) => state.teams.lastSelectedTeam)

  React.useEffect(() => {
    // tag with telemetry
    teamViewTelemetry.markTeamViewLoadNow()
  }, [])

  return (
    <Row className={styles.container}>
      {lastSelectedTeam && (
        <TelemetryScope scope="TeamViewScreen" properties={{ teamName: lastSelectedTeam }} key={lastSelectedTeam} />
      )}
      <TeamAreaLayout />
      <TicketsLayout className={styles.rightPanel}></TicketsLayout>
    </Row>
  )
}
