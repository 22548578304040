/* eslint-disable @typescript-eslint/no-explicit-any */
import { PromiseSnapshot } from '@copilot-dash/core'
import { mapValues } from 'lodash'
import { DefaultDashStoreActions } from './DefaultDashStoreActions'
import { IDashStoreContext } from './IDashStoreContext'

export type IDashStoreActions = {
  [K in keyof typeof DefaultDashStoreActions]: (typeof DefaultDashStoreActions)[K] extends (
    context: IDashStoreContext,
    ...args: infer Args
  ) => Promise<infer Res>
    ? (...args: Args) => Promise<Res>
    : (typeof DefaultDashStoreActions)[K] extends (
          context: IDashStoreContext,
          ...args: infer Args
        ) => PromiseSnapshot<infer Res>
      ? (...args: Args) => Promise<Res>
      : never
}

export function createDashStoreActions(context: IDashStoreContext): IDashStoreActions {
  return mapValues(DefaultDashStoreActions, (action) => {
    return (...args: unknown[]) => {
      const result = (action as any)(context, ...args) as ReturnType<typeof action>
      if (result instanceof Promise) {
        return result
      }

      switch (result.status) {
        case 'waiting':
          return result.promise
        case 'done':
          return Promise.resolve(result.data)
        case 'error':
          return Promise.reject(result.error)
      }
    }
  }) as IDashStoreActions
}
