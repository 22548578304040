import { INewTicketData, TicketEmotionType, ColumnKey, ColumnIdNaColumnIdNameMapping } from '@copilot-dash/domain'
import { createExtendedTableColumn, ExtendedTableColumnDefinition } from '../Table/ExtendedTableColumn'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { memo } from 'react'
import { useStyles } from './ColumnComponent.styles'
import { CopilotDashTooltip } from '../CopilotDashTooltip/CopilotDashTooltip'
import { Text } from '@fluentui/react-components'

export const ColumnConfigPriority: ExtendedTableColumnDefinition<INewTicketData> =
  createExtendedTableColumn<INewTicketData>({
    columnId: ColumnKey.Priority,
    columnName: ColumnIdNaColumnIdNameMapping[ColumnKey.Priority],
    isResizable: true,
    columnSize: {
      minWidth: 50,
      idealWidth: 80,
      padding: 0,
    },

    renderHeaderCell: () => <TableCellLayoutWithStyles>Priority</TableCellLayoutWithStyles>,

    renderCell: (item: INewTicketData) => (
      <TableCellLayoutWithStyles truncate>
        <RenderPriority item={item} />
      </TableCellLayoutWithStyles>
    ),
  })

const RenderPriority = memo(({ item }: { item: INewTicketData }) => {
  const styles = useStyles()
  if (item.thumbFeedback === TicketEmotionType.Positive) {
    return <Text className={styles.grayColor}>N/A</Text>
  } else if (item.priority === null || item.priority === undefined || item.priority === -1) return
  return (
    <CopilotDashTooltip
      relationShip="label"
      toolTipContent={
        <Text truncate className={styles.tooltipContent}>
          P{item.priority}
        </Text>
      }
      className={styles.tooltip}
      toolTipParentContent={<Text className={styles.text}>P{item.priority}</Text>}
    />
  )
})

RenderPriority.displayName = 'RenderPriority'
