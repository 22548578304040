import { SymptomReportItem } from '@copilot-dash/domain'
import { TableCell } from '@fluentui/react-components'

interface IProps {
  readonly item: Extract<SymptomReportItem, { valueType: 'Number' }>
}

export function ItemCellValueForNumber({ item }: IProps) {
  return <TableCell>{String(item.value)}</TableCell>
}
