import { AsyncSnapshot, PromiseSnapshots } from '@copilot-dash/core'
import { useMemo } from 'react'
import { useDashStore } from './DashStoreProvider'

export function useAllCustomTags(): AsyncSnapshot<string[]> {
  const store = useDashStore()
  const snapshot = store.use.getAllCustomTags()

  return useMemo(() => {
    return PromiseSnapshots.map(snapshot, (tag) => {
      return tag
    })
  }, [snapshot])
}
