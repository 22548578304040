import { ApiOdsFile } from '@copilot-dash/api'
import {
  BlobFileComplianceLevel,
  IOdsBlobFile,
  IOdsBlobMetadata,
  IOdsGroupedConversationFiles,
  IOdsTicketConversationFile,
  IOdsTicketFile3sOffline,
  IOdsTicketFile3sOnline,
} from '@copilot-dash/domain'
import { compact, reverse, sortBy } from 'lodash'

/**
 * Conversation File
 *
 * Legacy:
 * > ConversationHistory_.json
 *
 * Legacy:
 * > copilot_feedback_internal_log_ConversationHistory_1
 *
 * Grouped:
 * > copilot_feedback_internal_log_ConversationHistory_1_Group1
 * > copilot_feedback_internal_log_ConversationHistory_1_Group2
 * > copilot_feedback_internal_log_ConversationHistory_1_Group3
 * > copilot_feedback_internal_log_ConversationHistory_2_Group1
 * > copilot_feedback_internal_log_ConversationHistory_2_Group2
 */
const FILE_CONVERSATION_TYPE_OLD = /json/i
const FILE_CONVERSATION_NAME_OLD = /ConversationHistory_/i
const FILE_CONVERSATION_TYPE = /txt/i
const FILE_CONVERSATION_NAME = /copilot_feedback_internal_log_ConversationHistory_(\d+)/i
const FILE_GROUPED_CONVERSATION_TYPE = /json/i
const FILE_GROUPED_CONVERSATION_NAME = /^copilot_feedback_internal_log_ConversationHistory_(\d+)_Group(\d+)/i

/**
 * 3S offline File
 *
 * Legacy:
 * > BizChatAndOIVIC_OfflineDiagnosticsData.json
 *
 * Grouped:
 * > Copilot_Feedback_OfflineDiagnosticsData_Group1
 * > Copilot_Feedback_OfflineDiagnosticsData_Group2
 * > Copilot_Feedback_OfflineDiagnosticsData_Group3
 * > Copilot_Feedback_OfflineDiagnosticsData_Group4
 */
const FILE_OFFLINE_LEGACY_TYPE = /json/i
const FILE_OFFLINE_LEGACY_NAME = /^BizChatAndOIVIC_OfflineDiagnosticsData/i
const FILE_OFFLINE_GROUPED_TYPE = /json/i
const FILE_OFFLINE_GROUPED_NAME = /^Copilot_Feedback_OfflineDiagnosticsData_Group(\d+)/i

/**
 * 3S online File
 *
 * Legacy:
 * > copilot_feedback_internal_log_SearchReplay_1.txt
 *
 * Grouped:
 * > copilot_feedback_internal_log_SearchReplay_1_Group1
 * > copilot_feedback_internal_log_SearchReplay_1_Group2
 * > copilot_feedback_internal_log_SearchReplay_1_Group3
 * > copilot_feedback_internal_log_SearchReplay_1_Group4
 */
const FILE_3S_ONLINE_LEGACY_TYPE = /txt/i
const FILE_3S_ONLINE_LEGACY_NAME = /^copilot_feedback_internal_log_SearchReplay_(\d+)$/i
const FILE_3S_ONLINE_GROUPED_TYPE = /json/i
const FILE_3S_ONLINE_GROUPED_NAME = /^copilot_feedback_internal_log_SearchReplay_(\d+)_Group(\d+)$/i

export function convertToOdsBlobMetadata(files: ApiOdsFile[]): IOdsBlobMetadata {
  return {
    groupedConversationFiles: getGroupedConversationFiles(files),
    conversations: getConversationFiles(),
    sssOffline: getOfflineFiles(files),
    sssOnline: getReplayFiles(files),
    raw: files,
  }

  function getConversationFiles(): IOdsTicketConversationFile[] {
    const newFiles = getConversationFilesFromNewVersion()
    if (newFiles.length > 0) {
      return newFiles
    }

    return getConversationFilesFromOldVersion()
  }

  function getConversationFilesFromNewVersion(): IOdsTicketConversationFile[] {
    const files1 = files.filter((item) => {
      return FILE_CONVERSATION_TYPE.test(item.FileType) && FILE_CONVERSATION_NAME.test(item.FileName)
    })

    const files2 = files1.map((item): IOdsTicketConversationFile => {
      return {
        fileId: item.FileName,
        fileName: item.FileName,
        fileType: item.FileType,
        fileUrl: item.FileUrl,
        index: getVariableAsNumber(item, FILE_CONVERSATION_NAME) ?? -1,
      }
    })

    return sortBy(files2, (item) => item.index).reverse()
  }

  function getConversationFilesFromOldVersion(): IOdsTicketConversationFile[] {
    const file = files.find((file) => {
      return FILE_CONVERSATION_TYPE_OLD.test(file.FileType) && FILE_CONVERSATION_NAME_OLD.test(file.FileName)
    })

    if (!file) {
      return []
    }

    return [
      {
        fileId: file.FileName,
        fileName: file.FileName,
        fileType: file.FileType,
        fileUrl: file.FileUrl,
        index: 1,
      },
    ]
  }
}

function getOfflineFiles(apiFiles: ApiOdsFile[]): Array<IOdsTicketFile3sOffline> {
  return compact(apiFiles.map(getOfflineFile))
}

function getOfflineFile(apiFile: ApiOdsFile): IOdsTicketFile3sOffline | null {
  if (FILE_OFFLINE_LEGACY_TYPE.test(apiFile.FileType) && FILE_OFFLINE_LEGACY_NAME.test(apiFile.FileName)) {
    return {
      ...convertToFile(apiFile),
      index: 1,
      group: 'Legacy',
    }
  }

  if (FILE_OFFLINE_GROUPED_TYPE.test(apiFile.FileType) && FILE_OFFLINE_GROUPED_NAME.test(apiFile.FileName)) {
    const match = apiFile.FileName.match(FILE_OFFLINE_GROUPED_NAME)

    return {
      ...convertToFile(apiFile),
      index: 1,
      group: parseToGroup(match?.[1]),
    }
  }

  return null
}

function getReplayFiles(apiFiles: ApiOdsFile[]): Array<IOdsTicketFile3sOnline> {
  return compact(apiFiles.map(getReplayFile))
}

function getReplayFile(apiFile: ApiOdsFile): IOdsTicketFile3sOnline | null {
  if (FILE_3S_ONLINE_LEGACY_TYPE.test(apiFile.FileType) && FILE_3S_ONLINE_LEGACY_NAME.test(apiFile.FileName)) {
    const match = apiFile.FileName.match(FILE_3S_ONLINE_LEGACY_NAME)

    return {
      ...convertToFile(apiFile),
      index: parseToIndex(match?.[1]),
      group: 'Legacy',
    }
  }

  if (FILE_3S_ONLINE_GROUPED_TYPE.test(apiFile.FileType) && FILE_3S_ONLINE_GROUPED_NAME.test(apiFile.FileName)) {
    const match = apiFile.FileName.match(FILE_3S_ONLINE_GROUPED_NAME)

    return {
      ...convertToFile(apiFile),
      index: parseToIndex(match?.[1]),
      group: parseToGroup(match?.[2]),
    }
  }

  return null
}

function getGroupedConversationFiles(apiFiles: ApiOdsFile[]): IOdsGroupedConversationFiles[] {
  const files: Array<{
    readonly index: number
    group1: IOdsBlobFile | undefined
    group2: IOdsBlobFile | undefined
    group3: IOdsBlobFile | undefined
    group4: IOdsBlobFile | undefined
  }> = []

  for (const apiFile of apiFiles) {
    if (!FILE_GROUPED_CONVERSATION_TYPE.test(apiFile.FileType)) {
      continue
    }

    const matches = apiFile.FileName.match(FILE_GROUPED_CONVERSATION_NAME)
    if (!matches) {
      continue
    }

    const index = parseToNumber(matches[1])
    if (index === undefined) {
      continue
    }

    const file = (files[index] ??= {
      index,
      group1: undefined,
      group2: undefined,
      group3: undefined,
      group4: undefined,
    })

    switch (matches[2]) {
      case '1':
        file.group1 = convertToFile(apiFile)
        break
      case '2':
        file.group2 = convertToFile(apiFile)
        break
      case '3':
        file.group3 = convertToFile(apiFile)
        break
      case '4':
        file.group4 = convertToFile(apiFile)
    }
  }

  return reverse(compact(files))
}

function getVariableAsNumber(file: ApiOdsFile, regex: RegExp): number | undefined {
  const variable = getVariable(file, regex)
  if (!variable) {
    return
  }

  const value = Number.parseInt(variable)
  if (Number.isNaN(value)) {
    return
  }

  return value
}

function getVariable(file: ApiOdsFile, regex: RegExp): string | undefined {
  const matches = file.FileName.match(regex)
  if (!matches) {
    return
  }

  return matches[1]
}

function convertToFile(file: ApiOdsFile): IOdsBlobFile {
  return {
    fileId: file.FileName,
    fileName: file.FileName,
    fileType: file.FileType,
    fileUrl: file.FileUrl,
  }
}

function parseToNumber(value: string | undefined): number | undefined {
  if (!value) {
    return
  }

  const number = Number.parseInt(value)
  if (Number.isNaN(number)) {
    return
  }

  return number
}

function parseToIndex(value: string | undefined): number {
  if (!value) {
    return -1
  }

  const number = Number.parseInt(value)
  if (Number.isNaN(number)) {
    return -1
  }

  return number
}

function parseToGroup(value: string | undefined): BlobFileComplianceLevel | undefined {
  switch (value) {
    case undefined:
      return 'Legacy'
    case '1':
      return 'Group1'
    case '2':
      return 'Group2'
    case '3':
      return 'Group3'
    case '4':
      return 'Group4'
    default:
      return undefined
  }
}
