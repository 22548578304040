class TeamViewTelemetry {
  private _teamViewLoadedAt: number

  constructor() {
    this._teamViewLoadedAt = 0
  }

  markTeamViewLoadNow() {
    this._teamViewLoadedAt = Date.now()
  }
}

export const teamViewTelemetry = new TeamViewTelemetry()
