import { ApiOdsTicketData } from '@copilot-dash/api'

export function getUserConsentFromOdsMetadata(api: ApiOdsTicketData): boolean {
  // Check 1
  const userConsent1 = api['User Consent Given']
  if (userConsent1 === 'True') {
    return true
  }
  if (userConsent1 === 'False') {
    return false
  }

  // Check 2
  const userConsent2 = api.DiagnosticSessionAttributes?.['User Consent Given']
  if (userConsent2 === 'True') {
    return true
  }
  if (userConsent2 === 'False') {
    return false
  }

  // Check 3
  const tags = api.Tags
  if (Array.isArray(tags) && tags.includes('Feedback:HasUserConsent')) {
    return true
  }

  return false
}
