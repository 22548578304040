import { CopilotDashApi } from './api/copilot-dash/CopilotDashApi'
import { LogCollectorApi } from './api/LogCollector/LogCollectorApi'
import { MicrosoftGraphApi } from './api/MicrosoftGraph/MicrosoftGraphApi'
import { OcvApi } from './api/OCV/OcvApi'
import { OdsApi } from './api/ODS/OdsApi'
import { DashApiOptions } from './DashApiOptions'

export class DashApi {
  readonly copilotDash: CopilotDashApi
  readonly microsoftGraph: MicrosoftGraphApi
  readonly logCollector: LogCollectorApi
  readonly ods: OdsApi
  readonly ocv: OcvApi

  constructor(options: DashApiOptions) {
    this.copilotDash = new CopilotDashApi(options)
    this.microsoftGraph = new MicrosoftGraphApi(options)
    this.logCollector = new LogCollectorApi(options)
    this.ods = new OdsApi(options)
    this.ocv = new OcvApi(options)
  }
}
