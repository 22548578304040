import { RefObject, useLayoutEffect, useRef, useState } from 'react'

export function useElementWidth<T extends HTMLElement>(ref: RefObject<T>) {
  const widthRef = useRef<number>()
  const [width, setWidth] = useState<number | null>(null)

  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }

    const observer = new ResizeObserver((entries) => {
      const newWidth = entries[0]?.contentRect.width
      if (newWidth !== undefined && newWidth !== widthRef.current) {
        widthRef.current = newWidth
        setWidth(newWidth)
      }
    })

    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [ref])

  return width
}
