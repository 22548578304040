import { IStoreGetter, IStoreSetter, uuid } from '@copilot-dash/core'
import { findLast } from 'lodash'
import { IDashDialogActions } from './DashDialogActions'
import { IDashDialogState, IDashDialogStateItem } from './DashDialogState'

interface IProps {
  readonly set: IStoreSetter<IDashDialogState>
  readonly get: IStoreGetter<IDashDialogState>
}

export function createDashDialogActions({ set, get }: IProps): IDashDialogActions {
  return {
    open: (instance) => {
      const id = uuid()
      const item: IDashDialogStateItem = {
        id,
        status: 'open',
        instance,
      }

      set((state) => {
        state.items.push(item)
      })

      return id
    },
    close: (id) => {
      if (id === undefined) {
        doCloseTopMost()
      } else {
        doCloseById(id)
      }
    },
  }

  function doCloseById(id: string) {
    const item = get().items.find((i) => i.id === id)
    if (item && item.status === 'open') {
      doClose(item)
    }
  }

  function doCloseTopMost() {
    const item = findLast(get().items, (item) => item.status === 'open')
    if (item) {
      doClose(item)
    }
  }

  function doClose(item: IDashDialogStateItem) {
    set((state) => {
      const index = state.items.findIndex((i) => i.id === item.id)
      state.items[index]!.status = 'closing'
    })

    setTimeout(() => {
      set((state) => {
        const index = state.items.findIndex((i) => i.id === item.id)
        state.items.splice(index, 1)
      })
    }, 500)
  }
}
