import { FC } from 'react'
import { Column } from '../../../Layout'
import { useActivityHistoryPanelStore } from '../../store/store'
import { StateFieldChangeRow } from './StateFieldChangeRow'
import { PriorityFieldChangeRow } from './PriorityFieldChangeRow'
import { TeamFieldChangeRow } from './TeamFieldChangeRow'
import { RootCauseFieldChangeRow } from './RootCauseFieldChangeRow'
import { AssignToFieldChangeRow } from './AssignToFieldChangeRow'

export const ActivityUpdateStatus: FC = () => {
  const selectedActivity = useActivityHistoryPanelStore((state) => state.selectedActivity)

  return (
    <Column fill>
      <StateFieldChangeRow activity={selectedActivity} />
      <PriorityFieldChangeRow activity={selectedActivity} />
      <TeamFieldChangeRow activity={selectedActivity} />
      <AssignToFieldChangeRow activity={selectedActivity} />
      <RootCauseFieldChangeRow activity={selectedActivity} />
    </Column>
  )
}
