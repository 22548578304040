import { IPublicClientApplication } from '@azure/msal-browser'
import { IDashAuthPermissionData } from '../IDashAuth'
import { acquireTokenRoles } from './acquireTokenRoles'

const ROLE_BASIC = 'feedback.read.general'
const ROLE_ADVANCED = 'feedback.read.tier2'

export async function getPermission(client: IPublicClientApplication): Promise<IDashAuthPermissionData | null> {
  const account = client.getActiveAccount()
  if (!account) {
    return null
  }

  try {
    const roles = await acquireTokenRoles(client)
    return {
      roles,
      general: roles.includes(ROLE_BASIC),
      advanced: roles.includes(ROLE_ADVANCED),
    }
  } catch (e) {
    return {
      roles: [],
      general: false,
      advanced: false,
    }
  }
}
