import { INewTicketData, ColumnKey, ColumnIdNaColumnIdNameMapping } from '@copilot-dash/domain'
import { Text } from '@fluentui/react-components'
import { ExtendedTableColumnDefinition, createExtendedTableColumn } from '../Table/ExtendedTableColumn'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'

export const ColumnConfigEmotionType: ExtendedTableColumnDefinition<INewTicketData> =
  createExtendedTableColumn<INewTicketData>({
    columnId: ColumnKey.EmotionType,
    columnName: ColumnIdNaColumnIdNameMapping[ColumnKey.EmotionType],
    isResizable: true,
    flex: 1,
    columnSize: {
      minWidth: 50,
      idealWidth: 130,
      padding: 0,
    },
    renderHeaderCell: () => <TableCellLayoutWithStyles truncate>Emotion Type</TableCellLayoutWithStyles>,
    renderCell: (item: INewTicketData) => (
      <TableCellLayoutWithStyles truncate>
        <Text style={{ color: item.thumbFeedback === 'Negative' ? 'red' : 'green' }}>{item.thumbFeedback}</Text>
      </TableCellLayoutWithStyles>
    ),
  })
