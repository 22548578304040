import { FC } from 'react'
import { useStyles } from './InsightsTag.styles'
import { Row } from '../../../../../components/Layout'
import { TagCounter } from '../../tags/TagCounter'
import { VIPUserTag } from './VIPUserTag'
import { CAPTenantTag } from './CAPTenantTag'
import { FoldedTags } from './FoldedTags'

interface IProps {
  VIPUserIds: string[]
  CAPTenantNames: string[]
}

const Table_COLUMN_MAX_SHOW_Tags = 1
export const FeedbackInsightsTag: FC<IProps> = ({ VIPUserIds, CAPTenantNames }) => {
  const styles = useStyles()
  const totalTagCount = VIPUserIds.length + CAPTenantNames.length
  CAPTenantNames.sort((a, b) => a.localeCompare(b))
  return (
    <Row className={styles.container}>
      {VIPUserIds.length > 0 && VIPUserIds[0] ? (
        <VIPUserTag enableTooltip={true} userId={VIPUserIds[0]} />
      ) : (
        CAPTenantNames[0] && <CAPTenantTag enableTooltip={true} tenantName={CAPTenantNames[0]} />
      )}
      {totalTagCount > Table_COLUMN_MAX_SHOW_Tags && (
        <TagCounter
          tagCount={totalTagCount - Table_COLUMN_MAX_SHOW_Tags}
          foldedTags={
            <FoldedTags
              VIPUserIds={VIPUserIds.slice(Table_COLUMN_MAX_SHOW_Tags)}
              CAPTenantNames={
                VIPUserIds.length > 0 && VIPUserIds[0]
                  ? CAPTenantNames
                  : CAPTenantNames.slice(Table_COLUMN_MAX_SHOW_Tags)
              }
            />
          }
          className={styles.interactionTagPrimary}
          tagSize="small"
        />
      )}
    </Row>
  )
}
