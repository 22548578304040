import { Editor } from './editor/Editor'
import { LexicalRichTextEditorStore } from './LexicalRichTextEditorStore'

interface IProps {
  initialValue?: string | undefined
  mode?: 'create' | 'edit'
  onSubmit: (value: string | undefined) => Promise<void>
  onCancel?: () => void
}

export function LexicalRichTextEditor({ initialValue, mode = 'create', onSubmit, onCancel }: IProps) {
  return (
    <LexicalRichTextEditorStore initialValue={initialValue} mode={mode} onSubmit={onSubmit} onCancel={onCancel}>
      <Editor />
    </LexicalRichTextEditorStore>
  )
}
