import { AsyncSnapshots } from '@copilot-dash/core'
import { TicketScreenStore } from '../../../../../TicketScreenStore'
import { LoadingStatusComponent } from '../../../../common/LoadingStatusComponent'
import { TicketScreenLogView } from '../../../../common/TicketScreenLogView'

export const StateAndDurationView = () => {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const selectedTurnId = TicketScreenStore.use((state) => state.selectedTurnId)

  if (!selectedTurnId) {
    const snapshot = AsyncSnapshots.error(new Error('No conversation selected'))
    return <LoadingStatusComponent promiseSnapshot={snapshot} content={() => null} />
  }

  return <Content ticketId={ticketId} selectedTurnId={selectedTurnId} />
}

function Content(props: { readonly ticketId: string; readonly selectedTurnId: string }) {
  const snapshot = app.store.use.getStateDurationLog(props.ticketId, props.selectedTurnId)

  return <TicketScreenLogView snapshot={snapshot} />
}
