import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  blue: {
    color: tokens.colorCompoundBrandForeground1,
  },
  popoverSurface: {
    minWidth: '680px',
    outline: 'none',
  },
})
