import { SymptomReportData } from '@copilot-dash/domain'
import { makeStyles, Table, TableBody, tokens } from '@fluentui/react-components'
import { Group } from '../children/Group'
import { ItemRow } from '../children/ItemRow'

interface IProps {
  readonly data: SymptomReportData
}

export function LayoutForData({ data }: IProps) {
  const styles = useStyles()

  return (
    <Table className={styles.table}>
      <TableBody>
        {data.items.map((item, index) => (
          <ItemRow key={index} item={item} />
        ))}
        {data.groups.map((group, index) => (
          <Group key={index} group={group} groupIndex={index} />
        ))}
      </TableBody>
    </Table>
  )
}

const useStyles = makeStyles({
  table: {
    border: `1px solid ${tokens.colorNeutralStroke1}`,
    overflow: 'hidden',
  },
})
