export enum TicketErrorSource {
  User = 'User',
  FeedbackPlatform = 'FeedbackPlatform',
  CopilotClient = 'CopilotClient',
  Sydney = 'Sydney',
  SubstrateSearch = 'SubstrateSearch',
  ODS = 'ODS',
  Kusto = 'Kusto',
  ByDesign = 'ByDesign',
}

export const TicketErrorSourceByNames: Record<TicketErrorSource, string> = {
  [TicketErrorSource.User]: 'User',
  [TicketErrorSource.FeedbackPlatform]: 'Feedback Platform',
  [TicketErrorSource.CopilotClient]: 'Copilot Client',
  [TicketErrorSource.Sydney]: 'Sydney',
  [TicketErrorSource.SubstrateSearch]: 'Substrate Search',
  [TicketErrorSource.ODS]: 'ODS',
  [TicketErrorSource.Kusto]: 'Kusto',
  [TicketErrorSource.ByDesign]: 'By Design',
}
