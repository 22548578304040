import { makeStyles } from '@fluentui/react-components'
import { Row, Column } from '../../Layout'
import { ActivityHistoryCard } from './ActivityHistoryCard'

export function ActivityHistoryBody() {
  const styles = useStyles()

  return (
    <Row fill>
      <Column className={styles.side}>
        <ActivityHistoryCard />
      </Column>
    </Row>
  )
}

const useStyles = makeStyles({
  side: {
    width: '100%',
    margin: '8px 16px 0px 16px',
  },
})
