import { ApiConversationGroup1, ApiConversationGroup2 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { useMemo } from 'react'
import { AnyData } from '../../../../../components/AnyData/AnyData'

interface IProps {
  readonly ticketId: string
  readonly selectedTurnId: string
}

export function ConversationDataFromGroup({ ticketId, selectedTurnId }: IProps) {
  const session = app.store.use.getTicketSession(ticketId)
  const group1 = app.store.use.getRawConversationGroup1(ticketId, selectedTurnId)
  const group2 = app.store.use.getRawConversationGroup2(ticketId, selectedTurnId)

  const jsonData = useMemo(() => {
    return {
      traceId: selectedTurnId,
      conversation: {
        'Conversation Messages': getDataFromConversation(group1),
        '3S Diagnostics': getDataFromConversation(group2),
      },
      interaction: session.data?.interactions.find((item) => item.messageId === selectedTurnId)?.raw,
    }
  }, [group1, group2, selectedTurnId, session.data?.interactions])

  return <AnyData data={jsonData} />
}

function getDataFromConversation(snapshot: PromiseSnapshot<ApiConversationGroup1 | ApiConversationGroup2>) {
  switch (snapshot.status) {
    case 'waiting':
      return '(Waiting for data...)'
    case 'done':
      return snapshot.data.conversation
    case 'error':
      return '(Empty)'
  }
}
