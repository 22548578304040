import { ITicketChatTurnIndex } from '@copilot-dash/domain'
import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import { forwardRef } from 'react'
import { ErrorView } from '../../Error'
import { Column } from '../../Layout'
import { TurnViewHeader } from './TurnViewHeader'
import { TurnViewSkeleton } from './TurnViewSkeleton'
import { CopilotMessageTile } from './message/copilot/CopilotMessageTile'
import { CopilotMessageTileSuggestionsTile } from './message/copilot/CopilotMessageTileSuggestionsTile'
import { InvocationMessageTile } from './message/invocation/InvocationMessageTile'
import { UserMessageTile } from './message/user/UserMessageTile'

interface IProps {
  readonly ticketId: string
  readonly turn: ITicketChatTurnIndex
  readonly selected: boolean
  readonly selectedAppearance?: 'outline' | 'none'
  readonly onSelect?: () => void
}

export const TurnView = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement> & IProps>(function TurnView(
  { ticketId, turn, selected, selectedAppearance = 'outline', onSelect },
  ref,
) {
  const styles = useStyles()
  const conversation = application.store.use.getTicketConversation(ticketId, turn.id)

  const handleOnClick = () => {
    if (!selected) {
      onSelect?.()
    }
  }

  return (
    <Column ref={ref} className={styles.root} onClick={handleOnClick}>
      {renderChild()}
      <div
        className={mergeClasses(
          styles.border,
          selected ? 'selected' : 'unselected',
          selectedAppearance === 'outline' ? 'outline' : 'none',
        )}
      />
    </Column>
  )

  function renderChild() {
    switch (conversation.status) {
      case 'waiting':
        return <TurnViewSkeleton />
      case 'error':
        return <ErrorView error={conversation.error} />
      case 'done': {
        const data = conversation.data

        return (
          <Column hAlign="stretch">
            <TurnViewHeader data={data} />
            {data.userMessage && <UserMessageTile data={data} />}
            {data.invocationMessages && <InvocationMessageTile data={data} />}
            {data.botMessage && <CopilotMessageTile data={data} />}
            {data.botMessage && <CopilotMessageTileSuggestionsTile suggestions={data.botMessage.suggestions} />}
          </Column>
        )
      }
    }
  }
})

const useStyles = makeStyles({
  root: {
    padding: '12px 4px',
    borderRadius: tokens.borderRadiusXLarge,
    backgroundColor: tokens.colorNeutralBackground1,
    position: 'relative',
  },
  border: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: tokens.borderRadiusXLarge,
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),

    '&.selected': {
      pointerEvents: 'none',
    },

    '&.selected.outline': {
      ...shorthands.border('3px', 'solid', tokens.colorBrandStroke1),
    },

    '&.unselected': {
      cursor: 'pointer',
    },

    '&.unselected:hover': {
      ...shorthands.borderColor(tokens.colorBrandStroke2),
    },
  },
})
