import { makeStyles } from '@fluentui/react-components'
import { TicketIdCopyButton } from './TicketIdCopyButton'

interface IProps {
  readonly ticketId: string
}

export function TicketId({ ticketId }: IProps) {
  const styles = useStyles()

  return (
    <span className={styles.root}>
      <span>{ticketId}</span>
      <TicketIdCopyButton ticketId={ticketId} />
    </span>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '2px',
    fontSize: '0.875em',
    // backgroundColor: tokens.colorNeutralStrokeAlpha,
    // borderRadius: tokens.borderRadiusCircular,
  },
})
