import { AnyObjectValue } from '../types/AnyObjectValue'
import { generateObjectValueText } from './generateObjectValueText'

export function generateObjectValue(key: string, value: unknown): AnyObjectValue {
  return {
    key,
    value,
    valueText: generateObjectValueText(value),
  }
}
