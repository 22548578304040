import { z } from 'zod'

export interface ApiExtensibilityLog {
  readonly correlationId: string
  readonly botConversationId: string
  readonly botRequestId: string
  readonly copilotExtensionIds: string
  readonly messageId: string
  readonly [key: string]: unknown
}

export interface ApiExtensibilityLogsResponse {
  readonly extensibilityLogs: Array<ApiExtensibilityLog>
}

export const apiExtensibilityLogSchema = z.record(z.unknown()).and(
  z.object({
    correlationId: z.string(),
    botConversationId: z.string(),
    botRequestId: z.string(),
    copilotExtensionIds: z.string(),
    messageId: z.string(),
  }),
)

export const apiExtensibilityLogsResponseSchema = z.object({
  extensibilityLogs: z.array(apiExtensibilityLogSchema),
})
