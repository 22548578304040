import { makeStyles } from '@fluentui/react-components'
import { JsonView } from '../../../../../../../../components/JsonView/JsonView'

interface IProps {
  readonly data: unknown
}

export function TreeItemJsonView({ data }: IProps) {
  const styles = useStyles()

  return (
    <div className={styles.root}>
      <JsonView data={data} expandAll />
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
})
