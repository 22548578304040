import { AccountInfo, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser'
import { acquireToken } from './actions/acquireToken'
import { getPermission } from './actions/getPermission'
import { initialize } from './actions/initialization'
import { login } from './actions/login'
import { logout } from './actions/logout'
import { switchAccount } from './actions/switchAccount'
import { DashAuthConstants } from './DashAuthConstants'
import { IDashAuth, IDashAuthLoginOptions, IDashAuthPermissionData, IDashAuthSwitchOptions } from './IDashAuth'

export function createDashAuth(): IDashAuth {
  const client = new PublicClientApplication(DashAuthConstants.SSS_DASHBOARD)

  let initialized = false
  let permission: IDashAuthPermissionData | null = null

  return {
    async init(): Promise<void> {
      await initialize(client)
      permission = await getPermission(client)
      initialized = true
    },

    get isInitialized(): boolean {
      return initialized
    },

    get msal(): IPublicClientApplication {
      return client
    },

    get isAuthenticated(): boolean {
      return client.getAllAccounts().length > 0
    },

    get permission() {
      return permission
    },

    get accounts(): Array<AccountInfo> {
      return client.getAllAccounts()
    },

    get activeAccount(): AccountInfo | null {
      const activeAccount = client.getActiveAccount()
      if (activeAccount) {
        return activeAccount
      }

      const accounts = client.getAllAccounts()
      if (accounts.length > 0) {
        return accounts[0]!
      }

      return null
    },

    login(options?: IDashAuthLoginOptions): Promise<void> {
      return login(client, options)
    },

    acquireToken(scopes: Array<string>): Promise<string> {
      return acquireToken(client, scopes)
    },

    logout(): Promise<void> {
      return logout(client)
    },

    switch(options?: IDashAuthSwitchOptions): Promise<void> {
      return switchAccount(client, options)
    },
  }
}
