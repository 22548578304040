import { TicketCallFlowEvent } from '@copilot-dash/domain'
import { CallFlowNode } from '../store/IState'

export function generateNodeData(turnId: string, event: TicketCallFlowEvent): CallFlowNode {
  return {
    id: event.id,
    turnId,
    item: event,
  }
}
