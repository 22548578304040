import { makeStyles, TabList } from '@fluentui/react-components'
import { Spacer } from '../../../../components/Layout'
import { SearchRoute, TeamRoute, useRouteId, McpExperimentRoute } from '../../../../router'
import { HeaderTab, HeaderTabType } from './HeaderTab'

export function HeaderTabs() {
  const routeId = useRouteId()
  const styles = useStyles()
  let selectedTabType: HeaderTabType | null = null
  switch (routeId) {
    case SearchRoute.navigator.id:
      selectedTabType = HeaderTabType.All
      break
    case TeamRoute.navigator.id:
      selectedTabType = HeaderTabType.Team
      break
    case McpExperimentRoute.navigator.id:
      selectedTabType = HeaderTabType.McpExperimentFeedback
      break
  }

  return (
    <TabList selectedValue={selectedTabType} className={styles.list}>
      <HeaderTab tab={HeaderTabType.All} isSelected={selectedTabType === HeaderTabType.All} />
      <Spacer width="16px" />
      <HeaderTab tab={HeaderTabType.Team} isSelected={selectedTabType === HeaderTabType.Team} />
      <Spacer width="16px" />
      <HeaderTab
        tab={HeaderTabType.McpExperimentFeedback}
        isSelected={selectedTabType === HeaderTabType.McpExperimentFeedback}
      />
    </TabList>
  )
}
const useStyles = makeStyles({
  list: {
    width: '50%',
  },
})
