import {
  ApiGwsLogItem,
  ApiGwsLogItemDiagnosticData,
  ApiKustoLogResponse,
  apiGwsLogItemDiagnosticDataSchema,
  parseBase64GzipStringToString,
} from '@copilot-dash/api'
import { IKustoGwsLogItem } from '@copilot-dash/domain'
import { z } from 'zod'

export function convertTo3sGwsLogs(raw: ApiKustoLogResponse): IKustoGwsLogItem[] {
  const rows1 = raw.logData['substrateSearchInfoEvent (Kusto)']
  if (rows1 && rows1.length > 0) {
    return rows1.map((row) => convertToItem(row, false))
  }

  const rows2 = raw.logData['substrateSearchExceptionEvent (Kusto)']
  if (rows2 && rows2.length > 0) {
    return rows2.map((row) => convertToItem(row, true))
  }

  return []
}

function convertToItem(raw: ApiGwsLogItem, isSubstrateSearchExceptionEvent: boolean): IKustoGwsLogItem {
  const diagnosticDataDecoded = decodeBase64GzipJsonString(raw.diagnosticData)
  const diagnosticDataCustomized = decodeDiagnosticDataCustomized(diagnosticDataDecoded)

  return {
    ...raw,
    diagnosticData: raw.diagnosticData,
    diagnosticDataDecoded,
    diagnosticDataCustomized,
    responseMetaJson: raw.responseMetaJson,
    responseMetaJsonDecoded: decodeBase64GzipJsonString(raw.responseMetaJson),
    isSubstrateSearchExceptionEvent,
    httpStatusCode: raw.httpStatusCode,
    requestedActions: raw.requestedActions,
    routeAction: raw.routeAction,
    env_time: raw.env_time,
    transactionId: raw.transactionId,
  }
}

function decodeBase64GzipJsonString(str?: string): unknown {
  if (!str) {
    return str
  }

  try {
    let jsonString = str
    try {
      jsonString = parseBase64GzipStringToString(str)
    } catch (error) {
      // do nothing
    }

    return JSON.parse(jsonString)
  } catch (error) {
    return str
  }
}

function decodeDiagnosticDataCustomized(object?: unknown): IKustoGwsLogItem['diagnosticDataCustomized'] {
  if (!object || typeof object !== 'object') {
    return undefined
  }

  try {
    const decodeDiagnosticData = z.array(apiGwsLogItemDiagnosticDataSchema).parse(object)
    const diagnosticData = {}
    parseDiagnosticDataRecursive(decodeDiagnosticData, 'XapSearchWorkflowProviderV3', diagnosticData)
    return diagnosticData || {}
  } catch (error) {
    return undefined
  }
}

function parseDiagnosticDataRecursive(
  rawDataArray: ApiGwsLogItemDiagnosticData[],
  targetPN: string,
  parsedData: Record<string, unknown>,
): void {
  rawDataArray.forEach((d) => {
    if (d.PN === targetPN && d.InnerD) {
      const innerData = d.InnerD
      innerData.forEach((plugin) => {
        if (plugin.PN && plugin.DC !== undefined) {
          // check the type of plugin.DC
          if (typeof plugin.DC === 'string') {
            try {
              const fixedJson = plugin.DC.replace(/,\s*([}\]])/g, '$1')
              const parsedObject = JSON.parse(fixedJson)
              parsedData[plugin.PN] = parsedObject
            } catch (error) {
              // If parsing fails for plugin.DC, we assign the raw value.
              parsedData[plugin.PN] = plugin.DC
            }
          } else if (typeof plugin.DC === 'object') {
            // Recursively call the function for nested InnerD
            const nestedParsedData: Record<string, unknown> = {}
            parseDiagnosticDataRecursive([plugin], plugin.PN, nestedParsedData)
            parsedData[plugin.PN] = nestedParsedData
          }
        }
      })
    }
  })
}
