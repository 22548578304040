import { makeStyles, Text } from '@fluentui/react-components'

interface IProps {
  readonly status: 'success' | 'ok' | 'warning' | 'error'
  readonly text?: string
}

export function SymptomReportHealthLabel({ status, text }: IProps) {
  const styles = useStyles()

  switch (status) {
    case 'ok':
      text ??= 'OK'
      break
    case 'success':
      text ??= 'Success'
      break
    case 'warning':
      text ??= 'Warning'
      break
    case 'error':
      text ??= 'Error'
      break
  }

  return (
    <Text weight="semibold" className={styles[status]}>
      {text}
    </Text>
  )
}

const useStyles = makeStyles({
  ok: {
    color: 'green',
  },
  success: {
    color: 'green',
  },
  warning: {
    color: 'orange',
  },
  error: {
    color: 'red',
  },
})
