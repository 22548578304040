import { ITicketTurnTransactionHealthData } from '@copilot-dash/domain'
import { TableCell, TableRow, Text } from '@fluentui/react-components'

interface IProps {
  readonly data: ITicketTurnTransactionHealthData
}

export function RowForRetrievalSearchQuery({ data }: IProps) {
  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Retrieval - search query</Text>
      </TableCell>
      <TableCell>{data.query || 'NULL'}</TableCell>
    </TableRow>
  )
}
