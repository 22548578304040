import { IRootCauseList } from '@copilot-dash/domain'
import { IGlobalStoreState } from './IGlobalStoreState'

export function createGlobalStoreState(get: () => IGlobalStoreState): IGlobalStoreState {
  return {
    areaListAndRootCauseList: { status: 'none' },
    computed: {
      get allTeamsRootCauseList() {
        return (
          get().areaListAndRootCauseList.data?.reduce((acc, areaPathAndRootCause) => {
            acc.push(...areaPathAndRootCause.rootCauseList)
            return acc
          }, [] as IRootCauseList) || []
        )
      },
    },
  }
}
