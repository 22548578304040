const FEEDBACK_URL_BASE = 'https://o365exchange.visualstudio.com/O365%20Core/_workitems/create/Issue'
const FEEDBACK_URL_MSIT = `${FEEDBACK_URL_BASE}?templateId=d349cc63-7b83-4761-b622-b6f6260f17af&ownerId=625fb0a0-1ec6-4a0c-b89a-6f0fe29449c1`
const FEEDBACK_URL_SDF = `${FEEDBACK_URL_BASE}?templateId=41b1aa80-4f98-4085-a627-13e171e5ddfd&ownerId=625fb0a0-1ec6-4a0c-b89a-6f0fe29449c1`
const FEEDBACK_URL_TEST = `${FEEDBACK_URL_BASE}?templateId=44337e53-c8a5-4f26-b4fd-46c05b165817&ownerId=625fb0a0-1ec6-4a0c-b89a-6f0fe29449c1`

export function getFeedbackUrl() {
  switch (application.env.ring) {
    case 'msit':
    case 'unknown':
      return FEEDBACK_URL_MSIT
    case 'sdf':
      return FEEDBACK_URL_SDF
    case 'test':
    case 'dev':
      return FEEDBACK_URL_TEST
  }
}
