import { StateCreator } from 'zustand'
import { IBatchTicketsStore, ITeamViewStore } from './types'
import { IBatchTicketCustomTagsType } from '@copilot-dash/domain'
import { AsyncLoader } from '@copilot-dash/core'

export const createBatchTicketsStore: StateCreator<
  ITeamViewStore,
  [['zustand/immer', never]],
  [],
  IBatchTicketsStore
> = (set, get) => {
  const batchTicketCustomTagsAsyncLoader = new AsyncLoader<IBatchTicketCustomTagsType>()

  return {
    tickets: [],
    suggestion: null,
    setTickets: (tickets) => {
      set((state) => {
        state.batchTicketsSubStore.tickets = tickets
      })
    },
    setSuggestion: (suggestion) => {
      set((state) => {
        state.batchTicketsSubStore.suggestion = suggestion
      })
    },
    batchTicketCustomTagsSnapshot: { status: 'none' },

    fetchBatchTicketCustomTags: async (ticketIds: string[]) => {
      const promise = application.store.actions.getBatchTicketsCustomTags(ticketIds)
      batchTicketCustomTagsAsyncLoader.submit({
        promise,
        onChanged: (snapshot) => {
          set((state) => {
            // Join tickets with custom tags
            if (snapshot.data) {
              const updatedTickets = state.batchTicketsSubStore.tickets.map((ticket) => {
                const customTags = snapshot.data![ticket.ticketId] || []
                return { ...ticket, customTags }
              })
              state.batchTicketsSubStore.tickets = updatedTickets
            }
            state.batchTicketsSubStore.batchTicketCustomTagsSnapshot = snapshot
          })
        },
      })
      return promise
    },
  }
}
