import { makeStyles, Tooltip } from '@fluentui/react-components'
import { CheckmarkRegular, CopySelectRegular } from '@fluentui/react-icons'
import { useState } from 'react'

interface IProps {
  readonly ticketId: string
}

export function TicketIdCopyButton({ ticketId }: IProps) {
  const styles = useStyles()
  const [copied, setCopied] = useState(false)

  const onClick = () => {
    navigator.clipboard.writeText(ticketId).then(() => {
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    })
  }

  return (
    <Tooltip content={copied ? 'Copied' : 'Copy to clipboard'} relationship={'label'}>
      <span className={styles.root} onClick={onClick}>
        {copied ? <CheckmarkRegular /> : <CopySelectRegular />}
      </span>
    </Tooltip>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '0 2px',
    cursor: 'pointer',
  },
})
