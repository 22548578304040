import { TimeRange, Times } from '@copilot-dash/core'
import { getProductIdByName, INewTicketData, SearchTextPrefixType } from '@copilot-dash/domain'
import { makeStyles, shorthands, Text, tokens } from '@fluentui/react-components'
import { ColumnRegular } from '@fluentui/react-icons'
import { isNil } from 'lodash'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { ColumnSettingButton } from '../../../../../../components/Button/ColumnSettingButton'
import { Column, Right, Row, Spacer } from '../../../../../../components/Layout'
import { ResizableColumnsTable } from '../../../../../../components/Table/ResizableColumnsTable'
import { AllSearchTableColumns } from '../../../../../../components/TicketTableColumnConfigs'
import { SearchRoute } from '../../../../../../router'
import { useSearchScreenActions, useSearchScreenState } from '../../../../store'
import { LoaderComponent } from '../../../loader/LoaderComponent'

export const VIPUserDSATs = memo(() => {
  const style = styles()
  const [tableItems, setTableItems] = useState<INewTicketData[]>([])
  const [count, setCount] = useState<number | undefined>()
  const actions = useSearchScreenActions()
  const ticketId = useSearchScreenState((state) => state.clickedTicketInfo?.ticketId)

  const columns = app.settings.feedbackInsightsVipDSATsColumns.use()
  const selectedColumns = useMemo(() => {
    const allColumns = AllSearchTableColumns(SearchTextPrefixType.All)
    return columns.map((column) => allColumns.find((c) => c.columnId === column)!).filter((c) => c)
  }, [columns])

  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(true)
  const args = SearchRoute.navigator.useArgsOptional()
  const productName = SearchRoute.navigator.useArgsOptional()?.product
  const productId = getProductIdByName(productName)
  const batchList = application.store.use((state) =>
    typeof productId === 'number' ? state.topIssue.batchList?.[productId] : null,
  )

  useEffect(() => {
    actions.updateColumnSettingPanelVisibility(false)
  }, [actions])

  const range = useMemo((): TimeRange | undefined => {
    const batchInfo = batchList?.data?.find((item) => item.batchId === args?.issueBatchId)
    if (batchInfo) {
      const from = Times.startOfDay(batchInfo.from, 'UTC')
      const to = Times.endOfDay(batchInfo.to, 'UTC')
      return {
        type: 'absolute',
        from,
        to,
      }
    } else {
      return undefined
    }
  }, [args?.issueBatchId, batchList])

  const handleLoadMore = useCallback(
    (offset = 0) => {
      if (!range) {
        return
      }
      const query = SearchRoute.navigator.getArgsOptional()
      offset === 0 && setLoading(true)
      app.store.actions
        .searchTickets({
          product: query?.product || 'M365Chat',
          defaultRange: range,
          count: 50,
          offset: offset,
          isAIF: false,
          customerTypes: ['VIP Power User'],
          thumbs: ['Negative'],
        })
        .then((resp) => {
          setCount(resp.ticketCount)
          setHasMore(resp.hasMore)
          setTableItems((prev) => [...prev, ...(resp.tickets || [])])
        })
        .finally(() => {
          offset === 0 && setLoading(false)
        })
    },
    [range],
  )

  useEffect(() => {
    if (!range) {
      return
    }
    handleLoadMore()
  }, [handleLoadMore, range])

  return (
    <Column className={style.container}>
      <Row space="between" className={style.title}>
        <Text weight="semibold" size={500}>
          VIP User DSATs {isNil(count) ? null : `(${count})`}
        </Text>
        <Right>
          <ColumnSettingButton
            placeholder="Columns"
            apperance="transparent"
            icon={<ColumnRegular />}
            className={style.columnSetting}
            onClickButton={actions.handleColumnSettingButtonClick}
          />
        </Right>
      </Row>
      <Spacer height={22} />
      <Row>
        {loading ? (
          <Column vAlign="center" fill>
            <Spacer height={50} />
            <LoaderComponent />
          </Column>
        ) : (
          <ResizableColumnsTable
            className={style.table}
            items={tableItems}
            columns={selectedColumns}
            keyPicker={(data) => data.ticketId}
            supportLoadMore={hasMore}
            loadMoreFunction={handleLoadMore}
            enableColumnResizing={true}
            rowClick={actions.rowClick}
            selectedRowId={ticketId}
          />
        )}
      </Row>
    </Column>
  )
})
VIPUserDSATs.displayName = 'VIPUserDSATs'

const styles = makeStyles({
  columnSetting: {
    '&:hover': {
      ...shorthands.border('1px', 'solid', 'transparent'),
      backgroundColor: 'transparent',
      color: tokens.colorNeutralForeground1,
    },
  },
  container: {
    backgroundColor: tokens.colorNeutralBackground1,
    boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)`,
    borderRadius: '4px',
  },
  title: {
    width: '100%',
    padding: '20px',
    paddingBottom: 0,
  },
  table: {
    ...shorthands.border('none'),
  },
})
