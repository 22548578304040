export const OPGProductEndpoints = [
  'WordWebCopilotOCV',
  'WordWin32CopilotOCV',
  'WordMacCopilotOCV',
  'WordiOSCopilotOCV',
  'PPTWebCopilotOCV',
  'PPTWin32CopilotOCV',
  'PPTMacCopilotOCV',
  'PPTiOSCopilotOCV',
  'XLWebCopilotOCV',
  'XLWin32CopilotOCV',
  'XLMacCopilotOCV',
  'XLiOSCopilotOCV',
]
