import { DefaultProductChannelMapping, ProductNames, TEAM_VIEW_SUPPORT_PRODUCT_NAME_LIST } from '@copilot-dash/domain'
import { Tab, Text, makeStyles, tokens } from '@fluentui/react-components'
import { motion } from 'framer-motion'
import { useMemo } from 'react'
import { GlobalProductProvider } from '../../../../providers/product'
import { McpExperimentRoute, RouteLink, SearchRoute, TeamRoute } from '../../../../router'

const useStyles = makeStyles({
  text: {
    color: tokens.colorNeutralForeground4,
    display: 'block',
    maxWidth: '120px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  selectedText: {
    color: tokens.colorNeutralForeground1,
    display: 'block',
    maxWidth: '120px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
})

export enum HeaderTabType {
  All = 'Overview',
  Team = 'Team View',
  McpExperimentFeedback = 'Experiment Feedback',
}

interface IProps {
  readonly tab: HeaderTabType
  readonly isSelected: boolean
  readonly disabled?: boolean
}

export function HeaderTab({ tab, isSelected }: IProps) {
  const styles = useStyles()
  const product = GlobalProductProvider.useCurrenProduct() ?? ProductNames.M365Chat
  const disabled = tab === HeaderTabType.Team && !TEAM_VIEW_SUPPORT_PRODUCT_NAME_LIST.includes(product)

  const enableMcpFeature = app.features.mcpExperimentFeedbackBoard.use()
  const mcpDisabled =
    tab === HeaderTabType.McpExperimentFeedback && (product !== ProductNames.TeamsMeetingCopilot || !enableMcpFeature)

  const path = useMemo(() => {
    switch (tab) {
      case HeaderTabType.All:
        return SearchRoute.navigator.generatePath({
          product: product,
          tab: 'allFeedback',
          channel: DefaultProductChannelMapping[product],
        })
      case HeaderTabType.Team:
        return TeamRoute.navigator.generatePath({ product: product })
      case HeaderTabType.McpExperimentFeedback:
        return McpExperimentRoute.navigator.generatePath({ product: product })
    }
  }, [product, tab])

  if (mcpDisabled) {
    return null
  }

  return (
    <motion.div
      animate={{
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
    >
      <RouteLink
        path={path}
        style={{
          pointerEvents: disabled ? 'none' : 'auto',
        }}
      >
        <Tab value={tab} disabled={disabled}>
          <Text className={isSelected ? styles.selectedText : styles.text} size={400} weight="semibold">
            {tab}
          </Text>
        </Tab>
      </RouteLink>
    </motion.div>
  )
}
