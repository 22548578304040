import { makeStyles, shorthands } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    ...shorthands.padding('4px', '16px', '4px', '16px'),
    position: 'relative',
  },
  smallScreenWarning: {
    marginInline: 'auto',
  },
  largeScreenWarning: {
    position: 'absolute',
  },
})
