import { Node, Position } from '@xyflow/react'
import { CallFlowNode } from '../store/IState'

interface IParams {
  readonly nodes: Node<CallFlowNode>[]
}

export function layoutNodesHandles({ nodes }: IParams) {
  nodes.forEach((node, index): void => {
    const next = nodes[index + 1]
    if (!next) {
      return
    }

    // Case1: left to right
    if (node.position.x < next.position.x && node.position.y === next.position.y) {
      node.sourcePosition = Position.Right
      next.targetPosition = Position.Left
      return
    }

    // Case2: Top to bottom
    if (node.position.y < next.position.y && node.position.x === next.position.x) {
      node.sourcePosition = Position.Bottom
      next.targetPosition = Position.Top
      return
    }

    // Case3: Top left to bottom right
    if (node.position.y < next.position.y && node.position.x < next.position.x) {
      node.sourcePosition = Position.Bottom
      next.targetPosition = Position.Left
      return
    }

    // Case4: Top right to bottom left
    if (node.position.y < next.position.y && node.position.x > next.position.x) {
      node.sourcePosition = Position.Bottom
      next.targetPosition = Position.Right
      return
    }

    // Default
    node.sourcePosition = Position.Bottom
    next.targetPosition = Position.Top
  })
}
