import { useMemo } from 'react'
import { useScreenSpacing } from './useScreenSpacing'

export function useScreenPaddingStyle(): Pick<React.CSSProperties, 'padding' | 'paddingLeft' | 'paddingRight'> {
  const spacing = useScreenSpacing()

  return useMemo(() => {
    return {
      paddingLeft: spacing,
      paddingRight: spacing,
    }
  }, [spacing])
}
