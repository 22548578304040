import { Text, mergeClasses } from '@fluentui/react-components'
import { FC, useMemo, useCallback } from 'react'
import { Row, Column } from '../../../Layout'
import { useStyles } from './StatusField.styles'
import { IActivityHistory, TicketIssueData, ActivityHistoryRootCauseContext } from '@copilot-dash/domain'
import { isTicketIssueDataArray, filterEmptyIssues, getRootCauseDifferentValue } from '../config'

interface IProps {
  readonly activity?: IActivityHistory
}

export const RootCauseFieldChangeRow: FC<IProps> = ({ activity }) => {
  const styles = useStyles()

  const isChange = useMemo(() => {
    return !!(
      activity?.fieldDiffs &&
      activity?.fieldDiffs['issueList'] &&
      ((isTicketIssueDataArray(activity?.fieldDiffs['issueList'].newValue) &&
        filterEmptyIssues(activity?.fieldDiffs['issueList'].newValue).length > 0) ||
        (isTicketIssueDataArray(activity?.fieldDiffs['issueList'].oldValue) &&
          filterEmptyIssues(activity?.fieldDiffs['issueList'].oldValue).length > 0))
    )
  }, [activity])

  const renderValue = useCallback(
    (value: string | number | TicketIssueData[] | ActivityHistoryRootCauseContext[] | string[] | undefined) => {
      if (
        Array.isArray(value) &&
        value.length > 0 &&
        isTicketIssueDataArray(value) &&
        filterEmptyIssues(value).length > 0
      ) {
        const issues = filterEmptyIssues(value)
        return issues.map((issue, idx) => (
          <Text key={idx} title={issue.RootCauseTitle} className={mergeClasses(styles.tag, styles.customTag)}>
            {issue.RootCauseTitle}
          </Text>
        ))
      }
      return null
    },
    [styles.tag, styles.customTag],
  )

  const newValue = useMemo(
    () =>
      renderValue(
        getRootCauseDifferentValue(
          activity?.fieldDiffs?.['issueList']?.newValue,
          activity?.fieldDiffs?.['issueList']?.oldValue,
        ),
      ),
    [renderValue, activity],
  )

  const oldValue = useMemo(
    () =>
      renderValue(
        getRootCauseDifferentValue(
          activity?.fieldDiffs?.['issueList']?.oldValue,
          activity?.fieldDiffs?.['issueList']?.newValue,
        ),
      ),
    [renderValue, activity],
  )

  if (!isChange) return null

  return (
    <Row vAlign="start">
      <Column className={styles.columnContainer}>
        <Text className={styles.statueKey}>Root Cause:</Text>
      </Column>
      <Column className={styles.rootCauseValue}>
        {newValue && <Text>{newValue}</Text>}
        {oldValue && <Text className={styles.deleted}>{oldValue}</Text>}
      </Column>
    </Row>
  )
}
