import React, { useState } from 'react'
import { Combobox, makeStyles, Option, useId } from '@fluentui/react-components'
import type { ComboboxProps } from '@fluentui/react-components'
import { Row } from '../../../../components/Layout'

interface IProps {
  comboId: string
  defaultValue: string[]
  options: string[]
  placeholder: string
  onChangeFilter: (item: string[]) => void
}

export const RootCauseMultiselect = ({ comboId, defaultValue, options, placeholder, onChangeFilter }: IProps) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(defaultValue)
  const [inputValue, setInputValue] = useState('')
  const [isActive, setIsActive] = useState(false)
  const styles = useStyles()

  const [displayValue, setDisplayValue] = useState<string | undefined>(
    defaultValue === null || defaultValue === undefined || defaultValue.length === 0
      ? ''
      : defaultValue.length === 1
        ? options.filter((option) => defaultValue.includes(option)).map((option) => option)[0]
        : `${defaultValue[0]};+${defaultValue.length - 1}`,
  )

  const onSelect: ComboboxProps['onOptionSelect'] = (event, data) => {
    setSelectedOptions(data.selectedOptions)
    onChangeFilter(data.selectedOptions)
    setInputValue('')
  }

  const onFocus = () => {
    setInputValue('')
    setIsActive(true)
  }

  const onBlur = () => {
    setIsActive(false)
    setInputValue('')
    setDisplayValue(
      selectedOptions.length === 1
        ? options.filter((option) => selectedOptions.includes(option)).map((option) => option)[0]
        : selectedOptions.length > 0
          ? `${selectedOptions[0]};+${selectedOptions.length - 1}`
          : '',
    )
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayValue(event.target.value)
    setInputValue(event.target.value)
  }

  const _displayValue = isActive ? inputValue : displayValue

  return (
    <Row>
      <Combobox
        aria-labelledby={useId(comboId)}
        multiselect={true}
        placeholder={placeholder}
        value={_displayValue}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onOptionSelect={onSelect}
        className={styles.searchBox}
      >
        {options.map((option) => (
          <Option key={option}>{option}</Option>
        ))}
      </Combobox>
    </Row>
  )
}

const useStyles = makeStyles({
  searchBox: {
    width: '180px',
    flexShrink: 0,
    flexGrow: 0,
    height: '32px',
    minWidth: '180px',
    '& input': {
      width: '140px',
    },
  },
})
