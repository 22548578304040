import { AsyncSnapshot } from './AsyncSnapshot'

export class AsyncSnapshots {
  static readonly NONE = {
    status: 'none',
  } as const

  static readonly WAITING = {
    status: 'waiting',
  } as const

  static none() {
    return this.NONE
  }

  static waiting(): typeof AsyncSnapshots.WAITING

  static waiting<T>(data?: T): AsyncSnapshot<T> {
    if (data === undefined) {
      return this.WAITING
    }

    return {
      status: 'waiting',
      data,
    }
  }

  static done<T>(data: T) {
    return {
      status: 'done',
      data,
    } as const
  }

  static error(error: unknown) {
    return {
      status: 'error',
      error,
    } as const
  }

  static isEqual<T>(s1: AsyncSnapshot<T>, s2: AsyncSnapshot<T>): boolean {
    return s1.status === s2.status && s1.data === s2.data && s1.error === s2.error
  }

  static map<T, U>(snapshot: AsyncSnapshot<T>, transform: (data: T) => U): AsyncSnapshot<U> {
    const data = snapshot.data
    if (data === undefined) {
      return snapshot as AsyncSnapshot<U>
    }

    const transformedData = transform(data)
    return {
      status: snapshot.status,
      data: transformedData,
      error: snapshot.error,
    } as AsyncSnapshot<U>
  }

  static all<T extends [unknown, ...unknown[]]>(
    ...snapshots: { [K in keyof T]: AsyncSnapshot<T[K]> }
  ): AsyncSnapshot<T> {
    if (snapshots.length === 0) {
      return this.none()
    }

    if (snapshots.length === 1) {
      return snapshots[0] as AsyncSnapshot<T>
    }

    const error = snapshots.find((snapshot) => snapshot.status === 'error')
    if (error) {
      error as AsyncSnapshot<T>
    }

    const none = snapshots.find((snapshot) => snapshot.status === 'none')
    if (none) {
      return this.none()
    }

    const waiting = snapshots.find((snapshot) => snapshot.status === 'waiting')
    if (waiting) {
      return this.waiting()
    }

    const data = snapshots.map((snapshot) => snapshot.data)
    return {
      status: 'done',
      data,
    } as AsyncSnapshot<T>
  }
}
