import { useEffect, useLayoutEffect, useState } from 'react'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { ISearchScreenProps } from '../SearchScreen'
import { createSearchScreenActions } from './createSearchScreenActions'
import { createSearchScreenState } from './createSearchScreenState'
import { ISearchScreenState } from './ISearchScreenStoreState'
import { SearchScreenStoreContext } from './SearchScreenStoreContext'

interface IProps {
  readonly props: ISearchScreenProps
  readonly children: React.ReactNode
}

export function SearchScreenStoreProvider({ props, children }: IProps) {
  const [value] = useState(() => {
    const initialState = createSearchScreenState(props)
    const useBoundStore = create<ISearchScreenState>()(immer(() => initialState))
    const store = {
      get state() {
        return useBoundStore.getState()
      },
      use: useBoundStore,
      setState: useBoundStore.setState,
    }

    const actions = createSearchScreenActions(store, props)
    return { store, actions }
  })
  const isAISearchEnable = app.features.aiSearch.use()

  useLayoutEffect(() => {
    return value.store.use.subscribe((state, prevState) => {
      value.actions.onStateChanged(state, prevState, isAISearchEnable)
    })
  }, [value, isAISearchEnable])

  useLayoutEffect(() => {
    value.actions.onInit(isAISearchEnable)
  }, [value, isAISearchEnable])

  useEffect(() => {
    value.actions.updateProps(props, isAISearchEnable)
  }, [value, props, isAISearchEnable])

  return <SearchScreenStoreContext.Provider value={value}>{children}</SearchScreenStoreContext.Provider>
}
