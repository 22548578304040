export type TriggeredSkillName = 'WebSearchOnly' | '3SOnly' | '3SWebSearch' | 'LLMOnly'

export interface ITriggeredSkill {
  readonly key: TriggeredSkillName
  readonly text: string
  readonly options: string[]
}

export const DefaultTriggeredSkillList: ITriggeredSkill[] = [
  {
    key: 'WebSearchOnly',
    text: 'Web search only',
    options: ['!3S:Triggered', 'WebSearch:Triggered'],
  },
  {
    key: '3SOnly',
    text: '3S only',
    options: ['3S:Triggered', '!WebSearch:Triggered'],
  },
  {
    key: '3SWebSearch',
    text: '3S + Web search',
    options: ['3S:Triggered', 'WebSearch:Triggered'],
  },
  {
    key: 'LLMOnly',
    text: 'LLM only',
    options: ['!3S:Triggered', '!WebSearch:Triggered'],
  },
]

export const DefaultTriggeredSkillListCWC: ITriggeredSkill[] = [
  {
    key: 'WebSearchOnly',
    text: 'Web search only',
    options: ['!3S:Triggered', 'WebSearch:Triggered'],
  },
  {
    key: 'LLMOnly',
    text: 'LLM only',
    options: ['!3S:Triggered', '!WebSearch:Triggered'],
  },
]
