import { IQuery, ITeamData, ProductIds } from '@copilot-dash/domain'
import { useFilterOptions, useProductTeams } from '@copilot-dash/store'
import { memo, useEffect, useMemo, useState } from 'react'
import { SaveQueryDialog, saveQueryActionType } from '../../../SaveQueryDialog/SaveQueryDialog'
import { useTicketFilterStore } from '../../store/store'
import { ITicketFilterPanel } from '../../TicketFilterPanel.types'
import { getCleanedFilters, toODataQuery } from '../TicketFilterForm/TicketFilterForm.types'

interface ISaveQueryScreenProps extends ITicketFilterPanel {
  dialogOpen: boolean
  setDialogOpen: (open: boolean) => void
  currentQuery?: IQuery
  canUpdate: boolean
  type: saveQueryActionType
}

export const SaveQueryScreen = memo(function SaveQueryScreen(props: ISaveQueryScreenProps) {
  const productId = useTicketFilterStore((state) => state.selectedProductId)
  const copilotDashPath = useTicketFilterStore((state) => state.copilotDashPath)

  const timezone = app.settings.timezone.use()
  const filterOptionsSnapshot = useFilterOptions('', '')
  const [tenantName, setTenantName] = useState<string | undefined>()

  const tagsMetaDataMappingList = useMemo(() => {
    switch (filterOptionsSnapshot.status) {
      case 'done':
        return filterOptionsSnapshot.data
      default:
        return []
    }
  }, [filterOptionsSnapshot])

  const filterPayload = useMemo(() => {
    const combinedFilters = {
      ...props.externalArgs,
      ...props.ticketFilterForm,
      range: props.ticketFilterForm.range ?? props.defaultRange,
    }

    const cleanedFilters = getCleanedFilters(combinedFilters)

    return JSON.stringify(cleanedFilters)
  }, [props.ticketFilterForm, props.defaultRange, props.externalArgs])

  useEffect(() => {
    ;(async () => {
      if (props.ticketFilterForm.tenantIds && props.ticketFilterForm.tenantIds.length > 0) {
        await application.store.actions.searchM365Tenants(props.ticketFilterForm.tenantIds[0]).then((item) => {
          setTenantName(item[0]?.tenantName)
        })
      }
    })()
  }, [props.ticketFilterForm.tenantIds])

  const queryDetail = useMemo(() => {
    return toODataQuery(
      {
        ...props.externalArgs,
        ...props.ticketFilterForm,
        range: props.ticketFilterForm.range ?? props.defaultRange,
      },
      timezone,
      tagsMetaDataMappingList,
      tenantName,
    )
  }, [props.ticketFilterForm, props.defaultRange, props.externalArgs, tagsMetaDataMappingList, timezone, tenantName])

  const teamsSnapshot = useProductTeams(productId ?? ProductIds.M365Chat)

  const teamsOptions: ITeamData[] = useMemo(() => {
    switch (teamsSnapshot.status) {
      case 'done':
        return teamsSnapshot.data
      default:
        return []
    }
  }, [teamsSnapshot.data, teamsSnapshot.status])

  return (
    <SaveQueryDialog
      productId={productId}
      open={props.dialogOpen}
      onClose={() => {
        props.setDialogOpen(false)
      }}
      onSaved={() => {
        useTicketFilterStore.setState({ queryChanged: false })
      }}
      okBtnText="Save"
      queryDetail={queryDetail}
      copilotDashPath={copilotDashPath}
      filterPayload={filterPayload}
      canUpdate={props.canUpdate}
      isTeamShared={props.currentQuery?.isTeamShared}
      teamId={props.currentQuery?.teamId}
      queryId={props.currentQuery?.queryId}
      title={props.currentQuery?.title ?? ''}
      teamsOptions={teamsOptions}
      type={props.type}
    />
  )
})
