import { TimeRange, Times } from '@copilot-dash/core'
import { Input, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { CalendarLtrRegular, ChevronDownRegular } from '@fluentui/react-icons'
import { useMemo } from 'react'
import { DatePickerProps } from 'react-multi-date-picker'
import { DatePickerWrapped } from '../ReactMultiDatePickerWrapped/ReactMultiDatePickerWrapped'
import { TimeZoneInfo } from '../ReactMultiDatePickerWrapped/plugins/TimeZoneInfo'
import { DateRangePickerHeader } from './DateRangePickerHeader'

interface IProps {
  readonly range?: TimeRange
  readonly onChanged: (range: TimeRange) => void
  readonly minDate?: string
  readonly maxDate?: string
}

export function DateRangePicker({ range, onChanged, minDate, maxDate }: IProps) {
  const styles = useStyles()
  const timezone = app.settings.timezone.use()
  const localSystemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  // 1. Generate calendar value
  const value = useMemo(() => {
    if (!range) {
      return null
    }

    const localRange = Times.formatTimeRange(range, { timezone: localSystemTimezone, format: 'YYYY/MM/DD HH:mm:ss' })
    if (!localRange.from || !localRange.to) {
      return null
    }

    // NOTE: for 'YYYY-MM-DD' format, new Date('YYYY-MM-DD') will always treat it as UTC timezone
    return [new Date(localRange.from), new Date(localRange.to)]
  }, [localSystemTimezone, range])

  // 2. Handle calendar value change
  const handleChange: DatePickerProps['onChange'] = (date) => {
    if (!Array.isArray(date) || date.length !== 2) {
      return
    }

    const start = Times.startOfDay(date[0]?.toDate(), timezone)
    const end = Times.endOfDay(date[1]?.toDate(), timezone)
    onChanged({
      type: 'absolute',
      from: start,
      to: end,
    })
  }

  const renderInput: DatePickerProps['render'] = (value, openCalendar, handleValueChange) => {
    return (
      <Input
        className={styles.input}
        value={range && range.type === 'relative' ? Times.toStringOfRelativeTimeRange(range) : value}
        onChange={handleValueChange}
        onClick={openCalendar}
        contentBefore={<CalendarLtrRegular onClick={openCalendar} style={{ cursor: 'pointer' }} />}
        contentAfter={<ChevronDownRegular aria-label="Dropdown" onClick={openCalendar} style={{ cursor: 'pointer' }} />}
      />
    )
  }

  return (
    <DatePickerWrapped
      timezone={timezone}
      key={JSON.stringify(value)}
      value={value}
      minDate={minDate}
      maxDate={maxDate}
      format="MM/DD"
      range={true}
      arrow={false}
      showOtherDays={true}
      disableYearPicker={true}
      numberOfMonths={1}
      monthYearSeparator=" "
      onChange={handleChange}
      render={renderInput}
      plugins={[
        <DateRangePickerHeader
          key="MyPlugin"
          position="top"
          range={range}
          onChanged={(range) => {
            onChanged(range)
          }}
        />,
        <TimeZoneInfo key="MessageBarHitPlugin" position="bottom" timezone={timezone} />,
      ]}
      dateSeparator=" to "
    />
  )
}

const useStyles = makeStyles({
  input: {
    width: '100%',
    marginLeft: '0px',
    ...shorthands.borderTop('1px', 'solid', tokens.colorBrandForeground1),
    ...shorthands.borderLeft('1px', 'solid', tokens.colorBrandForeground1),
    ...shorthands.borderRight('1px', 'solid', tokens.colorBrandForeground1),
    ...shorthands.borderBottom('2px', 'solid', tokens.colorBrandForeground1),
    '&> span': {
      color: tokens.colorBrandForeground1,
    },
    '&> input': {
      fontWeight: tokens.fontWeightSemibold,
    },
    ':hover': {
      ...shorthands.borderTop('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderLeft('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderRight('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderBottom('2px', 'solid', tokens.colorBrandForeground1),
    },
  },
})
