import { Button, Tooltip } from '@fluentui/react-components'
import { SettingsRegular } from '@fluentui/react-icons'
import { SettingsRoute } from '../../../../router'

export function HeaderSettingsButton() {
  const isEnabled = app.features.settingsEntry.use()
  if (!isEnabled) {
    return null
  }

  const handleClick = () => {
    SettingsRoute.navigateToModal()
  }

  return (
    <Tooltip content="Settings" relationship="label">
      <Button appearance="subtle" icon={<SettingsRegular />} onClick={handleClick} />
    </Tooltip>
  )
}
