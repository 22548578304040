import { createStoreContext, useStore } from '@copilot-dash/core'
import { ReactNode, useEffect } from 'react'
import { ITicketDebugState } from './TicketDebugState'

interface IProps {
  readonly ticketId: string
  readonly children: ReactNode
}

export function TicketDebugStore({ ticketId, children }: IProps) {
  const store = useStore<ITicketDebugState, void>(() => ({
    state: {
      ticketId,
    },
    actions: void 0,
  }))

  useEffect(() => {
    store.setState((state) => {
      state.ticketId = ticketId
    })
  }, [store, ticketId])

  return <StoreContext.Provider store={store}>{children}</StoreContext.Provider>
}

const StoreContext = createStoreContext<ITicketDebugState, void>()
TicketDebugStore.use = StoreContext.use
