import { SymptomReportItem } from '@copilot-dash/domain'
import { ItemCellValueForBoolean } from './ItemCellValueForBoolean'
import { ItemCellValueForEmpty } from './ItemCellValueForEmpty'
import { ItemCellValueForJson } from './ItemCellValueForJson'
import { ItemCellValueForNumber } from './ItemCellValueForNumber'
import { ItemCellValueForNumberArray } from './ItemCellValueForNumberArray'
import { ItemCellValueForString } from './ItemCellValueForString'
import { ItemCellValueForStringArray } from './ItemCellValueForStringArray'
import { ItemCellValueForUnknown } from './ItemCellValueForUnknown'

interface IProps {
  readonly item: SymptomReportItem
}

export function ItemCellValue({ item }: IProps) {
  switch (item.valueType) {
    case 'Empty':
      return <ItemCellValueForEmpty item={item} />
    case 'Boolean':
      return <ItemCellValueForBoolean item={item} />
    case 'String':
      return <ItemCellValueForString item={item} />
    case 'StringArray':
      return <ItemCellValueForStringArray item={item} />
    case 'Number':
      return <ItemCellValueForNumber item={item} />
    case 'NumberArray':
      return <ItemCellValueForNumberArray item={item} />
    case 'Json':
      return <ItemCellValueForJson item={item} />
    case 'Unknown':
      return <ItemCellValueForUnknown item={item} />
  }
}
