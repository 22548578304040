import { DashLogger } from './DashLogger'
import { IDashLogger } from './interfaces/IDashLogger'
import { IDashLoggerOptions } from './interfaces/IDashLoggerOptions'

export class DashLoggerStatic implements IDashLogger {
  private delegate: IDashLogger | null = null

  init(options: IDashLoggerOptions) {
    this.delegate = new DashLogger(options)
  }

  get trace() {
    if (!this.delegate) {
      throw new Error('Logger not initialized')
    }

    return this.delegate.trace
  }

  get telemetry() {
    if (!this.delegate) {
      throw new Error('Logger not initialized')
    }

    return this.delegate.telemetry
  }
}
