import { z } from 'zod'

export interface ApiIssueClusteringBatchInfoListRequest {
  readonly productId: number
}

export interface ApiIssueClusteringBatchInfoListResponse {
  readonly clusteringBatchInfoList: Array<{
    readonly batchId: string
    readonly from: string
    readonly to: string
  }>
  readonly errorMessage: string
}

export const apiIssueClusteringBatchInfoListResponseSchema = z.object({
  clusteringBatchInfoList: z.array(
    z.object({
      batchId: z.string(),
      from: z.string(),
      to: z.string(),
    }),
  ),
  errorMessage: z.string(),
})
