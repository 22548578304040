import { InitializationTarget } from 'overlayscrollbars'
import { useOverlayScrollbars, UseOverlayScrollbarsParams } from 'overlayscrollbars-react'
import { useEffect, useMemo, useRef } from 'react'
import { ScrollbarOptions } from './ScrollbarConfigs'

/**
 * https://www.npmjs.com/package/overlayscrollbars-react
 */
import 'overlayscrollbars/overlayscrollbars.css'

const DEFAULT_PARAMS: UseOverlayScrollbarsParams = {
  options: ScrollbarOptions,
  defer: false,
}

/**
 * https://www.npmjs.com/package/overlayscrollbars-react
 */
export function useScrollbar<T extends InitializationTarget = HTMLDivElement>(params?: UseOverlayScrollbarsParams) {
  const ref = useRef<T>(null)

  const finalParams: UseOverlayScrollbarsParams = useMemo(() => {
    return {
      options: {
        ...DEFAULT_PARAMS.options,
        ...params?.options,
      },
      defer: params?.defer ?? DEFAULT_PARAMS.defer,
      target: ref.current,
    }
  }, [params])

  const [initialize] = useOverlayScrollbars(finalParams)
  useEffect(() => {
    if (ref.current) {
      initialize(ref.current)
    }
  }, [initialize])

  return ref
}
