import { IPublicClientApplication } from '@azure/msal-browser'
import { parseJwt } from '@copilot-dash/api'
import { z } from 'zod'
import { acquireToken } from './acquireToken'

const DEFAULT_SERVICE_SCOPE = ['d97af45c-a676-4654-a778-236750b20726/.default']

export async function acquireTokenRoles(client: IPublicClientApplication): Promise<Array<string>> {
  const token = await acquireToken(client, DEFAULT_SERVICE_SCOPE)
  const tokenObject = parseJwt(token)

  const roles = tokenObject.payload['roles']
  return z.array(z.string()).safeParse(roles).data ?? []
}
