import { PromiseSnapshot } from '@copilot-dash/core'
import { IProductData } from '@copilot-dash/domain'
import { makeStyles } from '@fluentui/react-components'
import { ReactNode, useMemo } from 'react'

interface IProps {
  readonly value: string
  readonly products: PromiseSnapshot<IProductData[]>
}

export function TicketProductDropdownButton({ value, products }: IProps): ReactNode {
  const styles = useStyles()

  const title = useMemo(() => {
    return products.data?.find((product) => product.name === value)?.title || value
  }, [value, products])

  return (
    <span title={title} className={styles.root}>
      {title}
    </span>
  )
}

const useStyles = makeStyles({
  root: {
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
})
