import { useEffect, useState } from 'react'
import { PromiseSnapshot, PromiseSnapshots } from '../async'

export function usePromise<T>(promise: Promise<T>): PromiseSnapshot<T> {
  const [snapshot, setState] = useState<PromiseSnapshot<T>>(() => {
    return PromiseSnapshots.waiting(promise)
  })

  useEffect(() => {
    let isAlive = true

    const update = (newSnapshot: PromiseSnapshot<T>) => {
      if (isAlive) {
        setState((snapshot) => {
          if (PromiseSnapshots.isEqual(snapshot, newSnapshot)) {
            return snapshot
          }

          return newSnapshot
        })
      }
    }

    update(PromiseSnapshots.waiting(promise))
    promise
      .then((data) => {
        update(PromiseSnapshots.done(data, promise))
      })
      .catch((error) => {
        update(PromiseSnapshots.error(error, promise))
      })

    return () => {
      isAlive = false
    }
  }, [promise])

  return snapshot
}
