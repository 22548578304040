import { makeStyles } from '@fluentui/react-components'

export const useStyles = makeStyles({
  wrapper: {
    overflowX: 'hidden',
  },
  text: {
    width: '100%',
  },
})
