import { useProductIdChannels } from '@copilot-dash/store'
import { useMemo } from 'react'
import { useSearchScreenState } from '../../store'
import { ITagDefinition } from './ITagDefinition.type'
import { TruncateTagFunction } from './TruncateTagFunction'
import { getProductIdByName } from '@copilot-dash/domain'

export const ChannelTagConfig = (): ITagDefinition => {
  const form = useSearchScreenState((state) => state.form)
  const productChannels = useProductIdChannels({ productId: getProductIdByName(form.product) })
  const channelUserFriendlyName = useMemo(() => {
    return productChannels.data
      ? productChannels.data.filter((channel) => form.channel?.includes(channel.name)).map((channel) => channel.title)
      : []
  }, [form.channel, productChannels.data])

  return {
    key: 'channel',
    tagName: 'Feedback Entry',
    toolTipContentValue: channelUserFriendlyName.join(', '),
    truncatedTagValue: TruncateTagFunction(channelUserFriendlyName),
    IsShowTag: false,
    isTagClearable: true,
  }
}
