import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  wrapper: {
    paddingTop: '16px',
    paddingBottom: '16px',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,
    },
  },

  accordionHeader: {
    alignItems: 'center',
  },

  accordionContent: {
    overflow: 'hidden',
  },

  title: {
    marginBottom: '12px',
  },

  blue: {
    color: tokens.colorCompoundBrandForeground1,
  },

  loadMore: {
    marginTop: '20px',
  },

  loadMoreTickets: {
    backgroundColor: tokens.colorNeutralBackground6,
  },

  loading: {
    maxHeight: '600px',
    minHeight: '80px',
  },

  icon: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },

  link: {
    color: tokens.colorBrandForegroundLink,
    cursor: 'pointer',
    transform: 'translateY(3px)',
  },

  tag: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    color: tokens.colorPaletteCornflowerForeground2,
    fontWeight: 600,
    fontSize: '12px',
  },

  tagIcon: {
    marginRight: '5px',
  },
})
