import { useState, useEffect, useRef, useMemo, ReactNode } from 'react'
import { mergeClasses, Text, Popover, PopoverTrigger, Button, PopoverSurface } from '@fluentui/react-components'
import { useStyles } from './CopilotDashPopover.styles'
import { Column, Row, Spacer } from '../Layout'
import { ChevronDownRegular, ChevronUpRegular, DismissRegular } from '@fluentui/react-icons'
import { CopilotDashDropdownInput } from './CopilotDashDropdownInput'
import { Scrollbar } from '../Scrollbar/Scrollbar'
import { debounce } from 'lodash'

interface IProps {
  optionContent: JSX.Element | (JSX.Element | null)[]
  placeholder?: string
  selectedOptions: string[]
  value: string
  handleSelectCleanClick: () => void
  inputValue: string
  setInputValue: (value: string) => void
  isPopoverOpen: boolean
  setIsPopoverOpen: (value: boolean) => void
  disable?: boolean
  showInput?: boolean
  prefixIcon?: ReactNode
}

export const CopilotDashPopover: React.FC<IProps> = ({
  optionContent,
  placeholder = 'Select',
  selectedOptions,
  value,
  handleSelectCleanClick,
  inputValue,
  setInputValue,
  isPopoverOpen,
  setIsPopoverOpen,
  disable,
  showInput,
  prefixIcon,
}) => {
  const styles = useStyles()
  const [surfaceWidth, setSurfaceWidth] = useState(0)
  const popoverRef = useRef<HTMLDivElement>(null)

  const debouncedSurfaceWidth = debounce((width: number) => setSurfaceWidth(width), 300)

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (popoverRef.current) {
        debouncedSurfaceWidth(popoverRef.current.offsetWidth)
      }
    })
    const currentPopoverRef = popoverRef.current
    if (currentPopoverRef) {
      resizeObserver.observe(currentPopoverRef)
    }

    return () => {
      if (currentPopoverRef) {
        resizeObserver.unobserve(currentPopoverRef)
      }
    }
  }, [popoverRef, debouncedSurfaceWidth])

  const popoverClassName = useMemo(() => {
    if (isPopoverOpen) {
      if (selectedOptions.length > 0) {
        return mergeClasses(styles.popoverBasic, styles.popoverExpandedSelected)
      }
      return mergeClasses(styles.popoverBasic, styles.popoverExpanded)
    } else {
      if (selectedOptions.length > 0) {
        return mergeClasses(styles.popoverBasic, styles.popoverSelected)
      }
      return mergeClasses(styles.popoverBasic, styles.popoverDefault)
    }
  }, [
    isPopoverOpen,
    selectedOptions,
    styles.popoverBasic,
    styles.popoverExpandedSelected,
    styles.popoverExpanded,
    styles.popoverSelected,
    styles.popoverDefault,
  ])
  return (
    <Button className={styles.button} appearance="subtle" disabled={disable}>
      <Popover
        positioning={{
          align: 'end',
          pinned: false,
          position: 'below',
        }}
        onOpenChange={(_, data) => setIsPopoverOpen(data.open)}
        open={isPopoverOpen}
      >
        <PopoverTrigger disableButtonEnhancement>
          <Row className={popoverClassName} space="between" ref={popoverRef} vAlign="center">
            <Row vAlign="center" className={styles.row}>
              {prefixIcon && (
                <>
                  {prefixIcon}
                  <Spacer size={4} />
                </>
              )}
              <Text className={value.length > 0 ? styles.value : mergeClasses(styles.value, styles.placeholder)}>
                {value.length > 0 ? value : placeholder}
              </Text>
            </Row>
            {isPopoverOpen ? (
              <ChevronUpRegular fontSize={20} />
            ) : selectedOptions.length > 0 ? (
              <Button
                icon={<DismissRegular />}
                appearance="subtle"
                size="small"
                onClick={(e) => {
                  handleSelectCleanClick()
                  e.stopPropagation()
                }}
                className={styles.selectCloseButton}
              />
            ) : (
              <ChevronDownRegular fontSize={20} />
            )}
          </Row>
        </PopoverTrigger>
        <PopoverSurface style={{ width: surfaceWidth, padding: 0 }}>
          <Column>
            {showInput && <CopilotDashDropdownInput inputValue={inputValue} setInputValue={setInputValue} />}
            <Scrollbar className={styles.scrollbar}>{optionContent}</Scrollbar>
          </Column>
        </PopoverSurface>
      </Popover>
    </Button>
  )
}
