import { IDashStoreContext } from '../IDashStoreContext'
import { NewSearchCopilotTicketsResult } from './search/NewSearchTicketsAction.types'
import { SearchTicketAction } from './search/SearchTicketAction'
import { ISearchTicketOptions } from './search/SearchTicketAction.types'

export function searchTickets(
  context: IDashStoreContext,
  options: ISearchTicketOptions,
  isAISearchEnable?: boolean,
): Promise<NewSearchCopilotTicketsResult> {
  return new SearchTicketAction(context).search(options, isAISearchEnable)
}
