import { Button, makeStyles, shorthands } from '@fluentui/react-components'
import { FC } from 'react'
import { DatePanelProps } from 'react-multi-date-picker/plugins/date_panel'
import { Row } from '../Layout'

interface IProps {
  onClick?: () => void
  submittable?: boolean
}

export const Submit: FC<IProps & Pick<DatePanelProps, 'position'>> = ({ onClick, submittable, position }) => {
  const styles = useStyles()
  return (
    <Row hAlign="center" className={styles.wrapper}>
      <Button appearance="primary" onClick={onClick} disabled={submittable}>
        OK
      </Button>
    </Row>
  )
}

const useStyles = makeStyles({
  wrapper: {
    ...shorthands.padding('10px'),
  },
})
