import { z } from 'zod'

export type ActorType = 'system' | 'user' | 'unset'

export interface ApiFieldDiff {
  oldValue?: string
  newValue?: string
}

export interface ActivityHistoryDiff {
  revision: number
  activityId: string
  ticketId: string
  timestamp: string
  activity: string
  actor: ActorType
  userId?: string
  system?: string
  fieldDiffs?: Record<string, ApiFieldDiff>
}

export interface ApiActivityHistoryResponse {
  activityHistories: ActivityHistoryDiff[]
  count: number
  errorMessage?: string
}

export const actorTypeSchema = z.union([z.literal('system'), z.literal('user'), z.literal('unset')])

export const apiFieldDiffSchema = z.object({
  oldValue: z.string().optional(),
  newValue: z.string().optional(),
})

export const activityHistoryDiffSchema = z.object({
  revision: z.number(),
  activityId: z.string(),
  ticketId: z.string(),
  timestamp: z.string(),
  activity: z.string(),
  actor: actorTypeSchema,
  userId: z.string().optional(),
  system: z.string().optional(),
  fieldDiffs: z.record(apiFieldDiffSchema).optional(),
})

export const apiActivityHistoryResponseSchema = z.object({
  activityHistories: z.array(activityHistoryDiffSchema),
  count: z.number(),
  errorMessage: z.string().optional(),
})
