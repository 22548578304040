import { TicketCallFlowEvent } from '@copilot-dash/domain'
import { Node } from '@xyflow/react'
import { CallFlowNode } from '../store/IState'
import { generateNodeData } from './generateNodeData'

const DEFAULT_WIDTH = 128
const DEFAULT_HEIGHT = 48

export function generateNode(turnId: string, event: TicketCallFlowEvent): Node<CallFlowNode> {
  const data = generateNodeData(turnId, event)

  return {
    id: data.id,
    data,
    type: 'custom',
    position: {
      x: 0,
      y: 0,
    },
    style: {
      padding: 0,
      margin: 0,
      border: 'none',
      boxShadow: 'none',
    },
    width: DEFAULT_WIDTH,
    height: DEFAULT_HEIGHT,
  }
}
