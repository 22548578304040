import { PromiseSnapshots } from '@copilot-dash/core'
import { IDashStoreContext } from '../IDashStoreContext'

export async function updateAllCustomTags(context: IDashStoreContext, customTags?: string[]): Promise<void> {
  context.use.setState((state) => {
    const currentTags = state.basic.allCustomTags?.data ?? []
    const tagsToMerge = customTags ?? []
    const mergedTags = Array.from(new Set([...currentTags, ...tagsToMerge]))

    state.basic.allCustomTags = PromiseSnapshots.done(mergedTags)
  })
}
