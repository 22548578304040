import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  motionLeftPanelContainer: {
    height: '100%',
    '& .fui-InlineDrawer': {
      transitionDuration: '0s',
    },
  },
  drawer: {
    height: '100%',
  },

  container: {
    ...shorthands.overflow('hidden', 'auto'),
    ...shorthands.padding('36px', '16px', '36px', '24px'),
    ...shorthands.gap('12px'),
    width: '100%',
    height: '100%',
    ...shorthands.borderRight('1px', 'solid', tokens.colorNeutralStroke1),
  },

  noFlexGrow: {
    flexGrow: 0,
  },

  alignItemsCenter: {
    alignItems: 'center',
  },

  pointer: {
    cursor: 'pointer',
  },

  noShrink: {
    flexShrink: 0,
  },
})
