import { Field, Input } from '@fluentui/react-components'
import { FC, memo } from 'react'

interface ITitleInputProps {
  value: string | undefined
  onChange: (value: string) => void
  disabled?: boolean
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
}

export const TitleInput: FC<ITitleInputProps> = memo(({ value, onChange, disabled, fieldProps = {} }) => {
  return (
    <Field label="Title:" orientation="vertical" required {...fieldProps}>
      <Input
        value={value ?? ''}
        disabled={disabled}
        onChange={(_, data) => onChange(data.value)}
        placeholder="Enter title"
        appearance="underline"
      />
    </Field>
  )
})

TitleInput.displayName = 'TitleInput'
