import { makeStyles, mergeClasses } from '@fluentui/react-components'

interface IProps {
  readonly className?: string
  readonly children?: React.ReactNode
}

export function Header(props: IProps) {
  const styles = useStyles()

  return <div className={mergeClasses('Header', styles.header, props.className)}>{props.children}</div>
}

const useStyles = makeStyles({
  header: {
    flex: '0 0 auto',
  },
})
