import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { LoginScreen } from '../../../screens/login/LoginScreen'

export function LoginRoutePage() {
  return (
    <PageTitle title="Login">
      <LoginScreen />
    </PageTitle>
  )
}
