import { Times, TimeType } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'

export function validateTicketExpiration(ticketId: string, createdDate: TimeType) {
  if (Times.isBeforeNDays(createdDate, 180)) {
    throw TicketError.create('Before180Days', { ticketId })
  }

  if (Times.isBefore(createdDate, '2024-03-18T00:00:00Z')) {
    throw TicketError.create('BeforeMarch182024', { ticketId })
  }
}
