import { ApiCreateRootCauseResponse } from '@copilot-dash/api'
import { ICreateRootCause } from '@copilot-dash/domain'
import { IDashStoreContext } from '../IDashStoreContext'
import { RootCauseDataConverter } from './converters/RootCauseDataConverter'

export async function createRootCause(
  context: IDashStoreContext,
  params: ICreateRootCause,
): Promise<ApiCreateRootCauseResponse> {
  const data = RootCauseDataConverter.postDataToApi(params)
  const response = await context.api.logCollector.createRootCause(data)
  return response
}
