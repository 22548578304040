import { useDashDialog } from '../components/Dialog'
import { useScreenType } from './useScreenType'

const SCREEN_SPACING_DESKTOP = 48
const SCREEN_SPACING_TABLET = 24
const SCREEN_SPACING_MOBILE = 16

export function useScreenSpacing(): number {
  const type = useScreenType()
  const dialog = useDashDialog()

  switch (type) {
    case 'desktop':
      if (dialog) {
        return SCREEN_SPACING_TABLET
      } else {
        return SCREEN_SPACING_DESKTOP
      }
    case 'tablet':
      return SCREEN_SPACING_TABLET
    case 'mobile':
      return SCREEN_SPACING_MOBILE
  }
}
