import { useMemo } from 'react'
import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { Column, Row, Spacer } from '../../../../../../../components/Layout'
import { Legend, LegendItem } from '../../../../../../../components/RatioProgressBar/Legend'
import { CardLoading } from '../../CardLoading'

interface AssignedTeamCardProps {
  loading: boolean
  dataList: Array<{
    count: number
    legendItem: LegendItem | undefined
  }>
}

export const IssueDetailDataCard = (props: AssignedTeamCardProps) => {
  const styles = useStyles()
  const dataValues = useMemo(() => props.dataList.map((item) => item.count), [props.dataList])
  const total = useMemo(() => dataValues.reduce((sum, value) => sum + value, 0), [dataValues])

  if (props.loading) {
    return <CardLoading />
  }

  return (
    <Column fill className={styles.container}>
      <Row className={styles.header}>
        <Column className={styles.headerItem}>Status</Column>
        <Spacer width={10} />
        <Column className={styles.headerItem}>DSATs</Column>
        <Spacer width={20} />
        <Column className={styles.headerItemLarge}>% of Issue</Column>
      </Row>
      <Column className={styles.contentContainer}>
        {props.dataList.map((item, index) => {
          const percentage = total > 0 ? ((item.count / total) * 100).toFixed(2) : '0.00'
          return (
            <Row key={index} className={styles.row}>
              <Column className={styles.rowItem}>
                {item.legendItem && <Legend items={[item.legendItem]} className={styles.legendIcon} />}
              </Column>
              <Spacer width={30} />
              <Column className={styles.rowItem}>{item.count}</Column>
              <Spacer width={20} />
              <Column className={styles.rowItemLarge}>{`${percentage}%`}</Column>
            </Row>
          )
        })}
      </Column>
    </Column>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.padding('10px', '10px', '20px', '10px'),
  },
  contentContainer: {
    overflowY: 'auto',
    scrollbarWidth: 'none',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    ...shorthands.padding('10px'),
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorNeutralForeground4,
    borderBottom: '1px solid lightgray',
  },
  headerItem: {
    flex: 1,
    textAlign: 'left',
  },
  headerItemLarge: {
    width: '70px',
    textAlign: 'left',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    ...shorthands.padding('10px'),
    borderBottom: '1px solid lightgray',
  },
  rowItem: {
    flex: 1,
    textAlign: 'left',
  },
  rowItemLarge: {
    width: '70px',
    textAlign: 'left',
  },
  legendIcon: {
    width: '8px',
    height: '8px',
  },
})
