import { IDashEnvironment } from '@copilot-dash/core'
import { IRequestContext } from '@microsoft/applicationinsights-common'
import { ICustomProperties, Snippet } from '@microsoft/applicationinsights-web'
import { ITelemetryCustomProperties } from '../telemetry'
import { getXMLRequestHeaders } from '../utils/getXMLRequestHeaders'

export class DashLoggerConfigs {
  static readonly CONSOLE_LOG_PREFIX = '[CopilotDash]'

  /**
   * 3S Dashboard application insights
   *
   * Azure resource:
   * https://ms.portal.azure.com/#@microsoft.onmicrosoft.com/resource/subscriptions/91b32e2a-8c41-48c8-b955-4f87d6e097ea/resourceGroups/3SDashLog/providers/microsoft.insights/components/3SDashApplicationInsights/overview
   */
  static getConfig1(env: IDashEnvironment): Snippet {
    return {
      config: {
        instrumentationKey: '540d4b2d-70e7-4d44-996b-5be38f2cce6d',
        appId: 'copilotdash',
        addRequestContext: (context) => {
          return this.buildRequestContext(env, context)
        },
      },
      version: env.buildVersion,
    }
  }

  /**
   * CopilotDash application insights
   */
  static getConfig2(env: IDashEnvironment): Snippet {
    let instrumentationKey: string
    switch (env.ring) {
      case 'dev':
      case 'test':
        instrumentationKey = '12b701c9-df84-46c7-8998-1d2b7926e9ba'
        break
      case 'sdf':
        instrumentationKey = '95ba3fd8-4099-47f2-a249-4f40e330fdf0'
        break
      case 'msit':
      case 'unknown':
        instrumentationKey = 'e052a546-7425-4252-bf94-4c1e91e984d8'
        break
    }

    return {
      config: {
        instrumentationKey,
        appId: 'copilotdash',
        addRequestContext: (context) => {
          return this.buildRequestContext(env, context)
        },
      },
      version: env.buildVersion,
    }
  }

  private static buildRequestContext(
    env: IDashEnvironment,
    context: IRequestContext | undefined,
  ): ITelemetryCustomProperties & ICustomProperties {
    const request = context?.request
    const xmlRequestHeaders = context?.xhr ? getXMLRequestHeaders(context.xhr) : {}

    return {
      app: 'CopilotDash',
      ring: env.ring,
      version: env.buildVersion,
      sessionId: request?.headers.get('X-Session-Id') ?? xmlRequestHeaders['X-Session-Id'] ?? env.sessionId,
      correlationId: request?.headers.get('X-Correlation-Id') ?? xmlRequestHeaders['X-Correlation-Id'],
      traceId: request?.headers.get('X-Trace-Id') ?? xmlRequestHeaders['X-Trace-Id'],
    }
  }
}
