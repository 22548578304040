import { SymptomReportItem } from '@copilot-dash/domain'
import { TableCell } from '@fluentui/react-components'

interface IProps {
  readonly item: Extract<SymptomReportItem, { valueType: 'Empty' }>
}

export function ItemCellValueForEmpty({ item }: IProps) {
  return <TableCell>{`Empty`}</TableCell>
}
