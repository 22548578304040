import { ApiError, ZodError } from '@copilot-dash/api'
import { IErrorConfig } from './interfaces/IErrorConfig'

export class ErrorConfigForApiSchema implements IErrorConfig {
  private readonly error: ApiError
  private readonly cause: ZodError

  constructor(error: ApiError, cause: ZodError) {
    this.error = error
    this.cause = cause
  }

  buildMessage() {
    return `Invalid response data format`
  }

  buildMessageDetails() {
    return 'We encountered an issue with the server response. The data format is unexpected. Please report this issue for assistance'
  }
}
