import { ITagDefinition } from './ITagDefinition.type'
import { useSearchScreenState } from '../../store'
import { TruncateTagFunction } from './TruncateTagFunction'
import { useMemo } from 'react'

export const EmotionTypeTagConfig = (): ITagDefinition => {
  const form = useSearchScreenState((state) => state.form)
  const valueTexts = useMemo(() => {
    return form.thumbs ?? []
  }, [form.thumbs])

  return {
    key: 'thumbs',
    tagName: 'Emotion type',
    toolTipContentValue: valueTexts.join(', '),
    truncatedTagValue: TruncateTagFunction(valueTexts ?? []),
    IsShowTag: false,
    isTagClearable: true,
  }
}
