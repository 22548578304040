import { createStoreContext, useStore } from '@copilot-dash/core'
import { parseProductName, ProductName } from '@copilot-dash/domain'
import { last } from 'lodash'
import { ReactNode } from 'react'
import { createGlobalProductActions, ITicketProductStoreActions } from './store/createGlobalProductActions'
import { createGlobalProductState, IGlobalProductState } from './store/createGlobalProductState'

interface IProps {
  readonly children: ReactNode
}

export function GlobalProductProvider({ children }: IProps) {
  const store = useStore<IGlobalProductState, ITicketProductStoreActions>((set, get) => ({
    state: createGlobalProductState(),
    actions: createGlobalProductActions(set, get),
  }))

  return <StoreContext.Provider store={store}>{children}</StoreContext.Provider>
}

const StoreContext = createStoreContext<IGlobalProductState, ITicketProductStoreActions>()

GlobalProductProvider.use = StoreContext.use
GlobalProductProvider.useActions = StoreContext.useActions
GlobalProductProvider.useCurrenProduct = (): ProductName | undefined => {
  const value = StoreContext.use((state) => last(state.selectors)?.value)
  return parseProductName(value)
}
