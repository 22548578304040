import { INewTicketData, ColumnKey, ColumnIdNaColumnIdNameMapping } from '@copilot-dash/domain'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { ExtendedTableColumnDefinition, createExtendedTableColumn } from '../Table/ExtendedTableColumn'
import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { UserProfile } from '../User/UserProfile'
import { memo, useMemo } from 'react'

export const ColumnConfigAssignTo: ExtendedTableColumnDefinition<INewTicketData> =
  createExtendedTableColumn<INewTicketData>({
    columnId: ColumnKey.AssignTo,
    columnName: ColumnIdNaColumnIdNameMapping[ColumnKey.AssignTo],
    isResizable: true,
    columnSize: {
      minWidth: 50,
      idealWidth: 260,
      padding: 0,
    },

    renderHeaderCell: () => (
      <TableCellLayoutWithStyles>{ColumnIdNaColumnIdNameMapping[ColumnKey.AssignTo]}</TableCellLayoutWithStyles>
    ),

    renderCell: (item: INewTicketData) => <RenderUserProfile item={item} />,
  })

const RenderUserProfile = memo(({ item }: { item: INewTicketData }) => {
  const styles = useStyles()
  const email = item.assignTo

  const content = useMemo(() => {
    if (!email) {
      return null
    }

    return (
      <UserProfile
        userId={email}
        fallback={email}
        customUserPhotoClassName={styles.customUserPhoto}
        customUserDisplayNameClassName={styles.customUserName}
      />
    )
  }, [styles.customUserName, styles.customUserPhoto, email])

  return <TableCellLayoutWithStyles>{content}</TableCellLayoutWithStyles>
})

const useStyles = makeStyles({
  customUserPhoto: {
    width: '24px',
    height: '24px',
    ...shorthands.borderRadius('50%'),
  },
  customUserName: {
    fontSize: tokens.fontSizeBase300,
  },
})
RenderUserProfile.displayName = 'RenderUserProfile'
