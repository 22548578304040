import { shorthands, makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  messageContainer: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    position: 'relative',
    ...shorthands.padding('0px', '10px'),
    ...shorthands.margin('4px', '4px'),
    borderRadius: '8px',
    backgroundColor: tokens.colorBrandBackground2,
  },
  markdown: {
    '& p': {
      ...shorthands.margin(0),
    },
    '& span': {
      fontWeight: tokens.fontWeightSemibold,
    },
    '& ul, & ol': {
      margin: '5px',
      whiteSpace: 'normal',
    },
  },

  deleted: {
    textDecoration: 'line-through',
    color: tokens.colorScrollbarOverlay,
    '& img': {
      filter: 'grayscale(50%) opacity(50%)',
    },
  },
})
