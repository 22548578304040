import { ITicketTurnTransactionHealthData } from '@copilot-dash/domain'
import { makeStyles, MessageBar, TableCell, TableRow, Text } from '@fluentui/react-components'
import { JSONTree } from 'react-json-tree'
import jsonTreeConfig from '../../Layout/json-tree-config'

interface IProps {
  readonly data: ITicketTurnTransactionHealthData
}

export function RowForRetrievalLU({ data }: IProps) {
  const styles = useStyles()

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Retrieval - LU</Text>
        <br />
        Source: {data.source}
      </TableCell>
      <TableCell>
        <p>
          <span>
            {data.lu?.LuAlteredQueries ? (
              'Has LUAlteredQueries'
            ) : (
              <span>
                No LUAlteredQueries{' '}
                <Text weight="semibold" style={{ color: 'orange' }}>
                  Warning
                </Text>
              </span>
            )}
            ,{' '}
          </span>
          <span>
            {data?.lu?.LlmLuOutput ? (
              'Has LlmLuOutput'
            ) : (
              <span>
                No LlmLuOutput{' '}
                <Text weight="semibold" style={{ color: 'orange' }}>
                  Warning
                </Text>
              </span>
            )}
            ,{' '}
          </span>
          <span>
            {data?.lu?.LuClassification ? (
              'Has LUClassification'
            ) : (
              <span>
                No LUClassification{' '}
                <Text weight="semibold" style={{ color: 'orange' }}>
                  Warning
                </Text>
              </span>
            )}
          </span>
        </p>
        {data?.lu && Object.keys(data?.lu).length > 0 && (
          <MessageBar icon={null} className={styles.symptomReportMessage}>
            <JSONTree data={data?.lu} {...jsonTreeConfig} hideRoot={true} />
          </MessageBar>
        )}
      </TableCell>
    </TableRow>
  )
}

// TODO: Need to be removed and replaced with the global styles
const useStyles = makeStyles({
  loading: {
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  symptomReportMessage: {
    overflowX: 'scroll',
    marginTop: '10px',
    marginBottom: '10px',
    paddingRight: '12px',
  },
})
