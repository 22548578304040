import { apiV2SymptomReportNumberArraySchema } from '@copilot-dash/api'
import { SymptomReportItem } from '@copilot-dash/domain'

export function parseToNumberArray(key: string, value: unknown): SymptomReportItem | undefined {
  const result = apiV2SymptomReportNumberArraySchema.safeParse(value)
  if (!result.success) {
    return
  }

  return {
    key,
    value: result.data,
    valueType: 'NumberArray',
  }
}
