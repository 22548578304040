import { createStoreContext, useStore } from '@copilot-dash/core'
import { ReactNode, useEffect } from 'react'
import { DashDialogProviderLayout } from './layout/DashDialogProviderLayout'
import { createDashDialogActions } from './store/createDashDialogActions'
import { createDashDialogState } from './store/createDashDialogState'
import { IDashDialogActions } from './store/DashDialogActions'
import { IDashDialogController } from './store/DashDialogController'
import { IDashDialogState } from './store/DashDialogState'

interface IProps {
  readonly dialog: IDashDialogController
  readonly children: ReactNode
}

export function DashDialogProvider({ dialog, children }: IProps): JSX.Element {
  const store = useStore<IDashDialogState, IDashDialogActions>((set, get) => ({
    state: createDashDialogState(),
    actions: createDashDialogActions({ set, get }),
  }))

  useEffect(() => {
    return dialog.register(store)
  }, [dialog, store])

  return (
    <StoreContext.Provider store={store}>
      <>
        {children}
        <DashDialogProviderLayout />
      </>
    </StoreContext.Provider>
  )
}

const StoreContext = createStoreContext<IDashDialogState, IDashDialogActions>()
DashDialogProvider.use = StoreContext.use
DashDialogProvider.useActions = StoreContext.useActions
