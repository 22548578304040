import { ApiError, ZodError } from '@copilot-dash/api'
import { makeStyles, tokens } from '@fluentui/react-components'
import { useMemo } from 'react'

interface IProps {
  readonly error: ApiError
  readonly cause: ZodError
}

export function ApiResponseTableZodPath({ error, cause }: IProps) {
  const styles = useStyles()

  const path = useMemo(() => {
    return cause.issues[0]?.path
      .map((path, index) => {
        if (index === 0) {
          return path
        }

        if (typeof path === 'number') {
          return `[${path}]`
        }

        return `.${path}`
      })
      .join('')
  }, [cause.issues])

  return <span className={styles.text}>{path}</span>
}

const useStyles = makeStyles({
  text: {
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorStatusWarningForeground1,
  },
})
