import { DrawerBody, makeStyles } from '@fluentui/react-components'
import { ActivityHistoryPanel } from '../../../../components/ActivityHistory/ActivityHistoryPanel'
import { TicketDiscussionPanel } from '../../../../components/DiscussionPanel/TicketDiscussionPanel'
import { Scrollbar } from '../../../../components/Scrollbar/Scrollbar'
import { TicketScreenDrawerType } from '../../store/IState'
import { TicketScreenStore } from '../../TicketScreenStore'
import { TicketContext } from './ticket-context/TicketContext'

interface IProps {
  readonly type: TicketScreenDrawerType
}

export function TicketScreenDrawerBody({ type }: IProps) {
  const styles = useStyles()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)

  return (
    <DrawerBody className={styles.root} style={{ paddingBottom: 0 }}>
      <Scrollbar className={styles.scrollbar}>
        {(() => {
          switch (type) {
            case TicketScreenDrawerType.TicketContext:
              return <TicketContext />
            case TicketScreenDrawerType.DiscussionPanel:
              return <TicketDiscussionPanel ticketId={ticketId} />
            case TicketScreenDrawerType.ActivityHistory:
              return <ActivityHistoryPanel ticketId={ticketId} />
          }
        })()}
      </Scrollbar>
    </DrawerBody>
  )
}

const useStyles = makeStyles({
  root: {
    padding: 0,
    margin: 0,
  },
  scrollbar: {
    height: '100%',
  },
})
