import { ProductIds, getProductTitleById } from '@copilot-dash/domain'
import { Field, Text } from '@fluentui/react-components'
import { FC, memo, useMemo } from 'react'

interface IProps {
  value: ProductIds
}

export const Product: FC<IProps> = memo(({ value }) => {
  const productTitle = useMemo(() => getProductTitleById(value), [value])
  return (
    <Field onClick={(e) => e.stopPropagation()}>
      <Text size={400} weight="semibold">
        Product: {productTitle}
      </Text>
    </Field>
  )
})

Product.displayName = 'Product'
