import { PromiseSnapshots } from '@copilot-dash/core'
import { ITicketData } from '@copilot-dash/domain'
import { IDashStoreContext } from '../IDashStoreContext'

export async function updateTicket(context: IDashStoreContext, ticketId: string, ticket: ITicketData): Promise<void> {
  context.use.setState((state) => {
    const item = (state.tickets[ticketId] ??= {})
    item.ticket = PromiseSnapshots.done(ticket)
    if (ticket.customTags && ticket.customTags.length > 0) {
      item.customTags = PromiseSnapshots.done(ticket.customTags)
    }
  })
}
