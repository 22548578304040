import { createStoreContext, useStore } from '@copilot-dash/core'
import { useEffect, useRef } from 'react'
import { IActions } from './store/IActions'
import { IState } from './store/IState'
import { createActions } from './store/createActions'
import { createState } from './store/createState'

interface IProps {
  initialValue: string | undefined
  mode: 'create' | 'edit'
  onSubmit: (value: string) => Promise<void>
  onCancel: (() => void) | undefined
  children: React.ReactNode
}

export function LexicalRichTextEditorStore({ initialValue, mode, onSubmit, onCancel, children }: IProps) {
  const onSubmitRef = useRef(onSubmit)
  onSubmitRef.current = onSubmit

  const onCancelRef = useRef(onCancel)
  onCancelRef.current = onCancel

  const store = useStore<IState, IActions>((set, get) => ({
    state: createState({
      initialValue,
      mode,
    }),
    actions: createActions({
      set,
      get,
      onCancelRef,
      onSubmitRef,
    }),
  }))

  useEffect(() => {
    store.setState((state) => {
      state.mode = mode
    })
  }, [mode, store])

  return <Context.Provider store={store}>{children}</Context.Provider>
}

const Context = createStoreContext<IState, IActions>()
LexicalRichTextEditorStore.use = Context.use
LexicalRichTextEditorStore.useActions = Context.useActions
