import { z } from 'zod'

export interface ApiNewSearchCopilotTicketItem {
  readonly dateTimeUtc?: string
  readonly externalTicketId: string
  readonly thumbFeedback?: string
  readonly tenantId?: string
  readonly priority?: number
  readonly areaPath?: string // areaPath is defined by ADO
  readonly teamArea?: string // teamArea is defined by CopilotDash
  readonly workItemId?: string
  readonly tenantName?: string
  readonly vsoLink?: string
  readonly vsoAccount?: string
  readonly ring?: string
  readonly endpoint?: string
  readonly rootCauseList?: Array<{
    readonly issueId: string
    readonly rootCauseTitle: string
    readonly vsoAccount: string
  }>
  readonly status?: 'New' | 'Active' | 'Resolved' | 'Closed' | ''
  readonly reasoning?: string
  readonly closedComment?: string
  readonly assignTo?: string
  readonly displayTeamName?: string
  readonly teamId?: number
}

export interface ApiNewSearchCopilotTicketsResponse {
  readonly tickets: Array<ApiNewSearchCopilotTicketItem>
  readonly hasMore: boolean
  readonly ticketCount: number
  readonly errorMessage: string
  readonly sATCount: number
  readonly dSATCount: number
}

export const apiNewSearchCopilotTicketItemSchema = z.object({
  dateTimeUtc: z.string().optional(),
  externalTicketId: z.string(),
  thumbFeedback: z.string().optional(),
  tenantId: z.string().optional(),
  priority: z.number().optional(),
  areaPath: z.string().optional(),
  teamArea: z.string().optional(),
  workItemId: z.string().optional(),
  tenantName: z.string().optional(),
  vsoLink: z.string().optional(),
  vsoAccount: z.string().optional(),
  ring: z.string().optional(),
  endpoint: z.string().optional(),
  rootCauseList: z
    .array(
      z.object({
        issueId: z.string(),
        rootCauseTitle: z.string(),
        vsoAccount: z.string(),
      }),
    )
    .optional(),
  status: z
    .union([z.literal('New'), z.literal('Active'), z.literal('Resolved'), z.literal('Closed'), z.literal('')])
    .optional(),
  reasoning: z.string().optional(),
  closedComment: z.string().optional(),
  assignTo: z.string().optional(),
  displayTeamName: z.string().optional(),
  teamId: z.number().optional(),
})

export const apiNewSearchCopilotTicketsResponseSchema = z.object({
  tickets: z.array(apiNewSearchCopilotTicketItemSchema),
  hasMore: z.boolean(),
  ticketCount: z.number(),
  errorMessage: z.string(),
  sATCount: z.number(),
  dSATCount: z.number(),
})
