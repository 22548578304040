import { TelemetryScope } from '@copilot-dash/logger'
import { UnknownScreenLayout } from './layout/UnknownScreenLayout'

interface IProps {
  readonly path: string
}

export function UnknownScreen(props: IProps) {
  return (
    <TelemetryScope scope="UnknownScreen">
      <UnknownScreenLayout path={props.path} />
    </TelemetryScope>
  )
}
