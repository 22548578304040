import { SymptomReportItem } from '@copilot-dash/domain'
import { makeStyles, TableCell, tokens } from '@fluentui/react-components'
import { useMemo } from 'react'
import { JsonView } from '../../JsonView/JsonView'

interface IProps {
  readonly item: Extract<SymptomReportItem, { valueType: 'Unknown' }>
}

export function ItemCellValueForUnknown({ item }: IProps) {
  const styles = useStyles()

  const jsonString = useMemo(() => {
    return JSON.stringify(item.value, null, 2)
  }, [item])

  return (
    <TableCell className={styles.root} title={jsonString}>
      <div className={styles.scrollbar}>
        {jsonString.length > 100 ? <JsonView data={item.value} hideRoot={true} /> : jsonString}
      </div>
    </TableCell>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '8px',
  },
  scrollbar: {
    padding: '8px 12px',
    backgroundColor: tokens.colorNeutralBackground2,
    border: `1px solid ${tokens.colorNeutralStroke2}`,
    borderRadius: '4px',
  },
})
