import { TelemetryScope } from '@copilot-dash/logger'
import { CustomizedFilterForm } from './CustomizedFilterForm'

export function CustomizedFilterScreen() {
  return (
    <TelemetryScope scope="CustomizedFilterScreen">
      <CustomizedFilterForm />
    </TelemetryScope>
  )
}
