import { useMemo } from 'react'
import { Column } from '../../../../../components/Layout'
import { SettingsScreenSection } from '../../common/SettingsScreenSection'
import { SettingsScreenSectionTitle } from '../../common/SettingsScreenSectionTitle'
import { FeatureFlightTile } from '../tiles/FeatureFlightTile'

export function SettingsLabsPanel() {
  const features = useMemo(() => {
    return Object.values(app.features)
  }, [])

  return (
    <Column hAlign="start">
      {/* 1. Experimental Features */}
      <SettingsScreenSection>
        <SettingsScreenSectionTitle title="Experimental Features" />
        {features.map((feature, index) => (
          <FeatureFlightTile key={index} feature={feature} />
        ))}
      </SettingsScreenSection>
    </Column>
  )
}
