import { TimeType } from '@copilot-dash/core'
import { useMemo } from 'react'

interface IProps {
  readonly value: TimeType
}

export function TimeViewTooltipContent({ value }: IProps) {
  const originalValue = useMemo(() => {
    return String(value ?? 'Empty')
  }, [value])

  // const localValue = useMemo(() => {
  //   return dayjs.utc(value).tz(dayjs.tz.guess()).format()
  // }, [value])

  // if (application.env.ring === 'msit') {
  //   return originalValue
  // }

  return (
    <table>
      <tbody>
        <tr>
          <td>UTC </td>
          <td>{originalValue}</td>
        </tr>
        {/* <tr>
          <td>Local</td>
          <td>{localValue}</td>
        </tr> */}
      </tbody>
    </table>
  )
}
