import { ApiRootCausesByTeamResponse } from '@copilot-dash/api'
import { isAssignedTo3SDashTorus } from '../../utils/is3SDashTorus'
import { IRootCausesByTeam } from '@copilot-dash/domain'

export function convertTeamRootCauseList(apiData: ApiRootCausesByTeamResponse): IRootCausesByTeam {
  const { rootCauseList, ...otherData } = apiData
  const convertRootCauseData = rootCauseList.map((item) => {
    const { owner, ...list } = item
    return {
      ...list,
      owner: isAssignedTo3SDashTorus(owner) ? undefined : owner,
    }
  })
  const convertRootCauseList = { rootCauseList: convertRootCauseData, ...otherData }
  return convertRootCauseList
}
