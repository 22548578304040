import { makeStyles } from '@fluentui/react-components'
import { IErrorComponents } from '../../ErrorConfigs/interfaces/IErrorComponents'
import { Row } from '../../Layout'
import { ErrorViewPopoverBodyActionsFeedbackButton } from './ErrorViewPopoverBodyActionsFeedbackButton'
import { ErrorViewPopoverBodyActionsGotItButton } from './ErrorViewPopoverBodyActionsGotItButton'

interface IProps {
  readonly components: IErrorComponents
  readonly onClose?: () => void
}

export function ErrorViewPopoverBodyActions({ components, onClose }: IProps) {
  const styles = useStyles()

  const buildPrimaryButton = () => {
    switch (components.level) {
      case 'ERROR':
        return <ErrorViewPopoverBodyActionsFeedbackButton />
      case 'WARNING':
        return <ErrorViewPopoverBodyActionsFeedbackButton />
      case 'OK':
        return <ErrorViewPopoverBodyActionsGotItButton onClose={onClose} />
    }
  }

  return (
    <Row className={styles.root} hAlign="end">
      {buildPrimaryButton()}
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    paddingTop: '16px',
  },
})
