import { AsyncSnapshot, PromiseSnapshots } from '@copilot-dash/core'
import { useMemo } from 'react'
import { useDashStore } from './DashStoreProvider'
import { ITeamData } from '@copilot-dash/domain'

export function useProductTeams(productId: number): AsyncSnapshot<ITeamData[]> {
  const store = useDashStore()
  const snapshot = store.use.getTeams(productId)

  return useMemo(() => {
    return PromiseSnapshots.map(snapshot, (team) => {
      return team
    })
  }, [snapshot])
}
