import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    ...shorthands.flex(1),
  },
  header: {
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },

  expandIcon: {
    marginRight: '20px',
    minWidth: '22px',
  },

  search: {
    width: '300px',
    flexGrow: 0,
    flexShrink: 0,
  },

  table: {
    marginTop: '24px',
  },

  headerRow: {
    height: '40px',
    color: tokens.colorNeutralForeground4,
  },
  bodyRow: {
    minHeight: '60px',
  },

  adaptiveFilter: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'end',
    minWidth: 0,
  },

  filterButton: {
    minWidth: 'fit-content',
  },
})
