import { Overflow, OverflowItem, Tab, TabList, makeStyles, mergeClasses } from '@fluentui/react-components'
import { ReactElement } from 'react'
import { TicketScreenOverflowTab } from './TicketScreenOverflowTab'
import { TicketScreenOverflowTabsMenu } from './TicketScreenOverflowTabsMenu'

interface IProps {
  readonly tabs: TicketScreenOverflowTab[]
  readonly selectedId?: string
  readonly onSelect?: (tabId: string) => void
  readonly className?: string
  readonly children?: (tab: TicketScreenOverflowTab) => ReactElement
}

export function TicketScreenOverflowTabs({ tabs, selectedId, onSelect, className, children }: IProps) {
  const styles = useExampleStyles()

  return (
    <div className={mergeClasses(styles.root, className)}>
      <Overflow minimumVisible={2}>
        {/* Property "selectedValue" has to be empty string here to make it as a controlled component */}
        <TabList selectedValue={selectedId ?? ''} onTabSelect={(_, d) => onSelect?.(String(d.value))}>
          {tabs.map((tab) => {
            return (
              <OverflowItem key={tab.id} id={tab.id} priority={tab.id === selectedId ? 2 : 1}>
                {children?.(tab) ?? (
                  <Tab value={tab.id} icon={tab.icon && <span>{tab.icon}</span>} disabled={tab.disabled}>
                    {tab.name}
                  </Tab>
                )}
              </OverflowItem>
            )
          })}
          <TicketScreenOverflowTabsMenu tabs={tabs} onSelect={onSelect} />
        </TabList>
      </Overflow>
    </div>
  )
}

const useExampleStyles = makeStyles({
  root: {
    overflow: 'hidden',
    height: 'fit-content',
  },
})
