import { FC, memo } from 'react'
import { Field, Dropdown, Option } from '@fluentui/react-components'

type ElementType<T extends ReadonlyArray<unknown>> = T[number]
const ADO_ISSUE_STATE_OPTIONS = ['Active', 'Closed'] as const

interface IStateProps {
  value: string | undefined
  onChange: (value: string) => void
  orientation?: 'horizontal' | 'vertical'
  className?: string
  style?: React.CSSProperties
  defaultValueText?: string
  disabled?: boolean
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
  disabledOptions?: ElementType<typeof ADO_ISSUE_STATE_OPTIONS>[]
}

export const State: FC<IStateProps> = memo(
  ({
    value,
    onChange,
    orientation = 'vertical',
    className,
    style,
    defaultValueText,
    disabled,
    fieldProps,
    disabledOptions = [],
  }) => {
    return (
      <Field className={className} label="State:" orientation={orientation} style={style} {...(fieldProps ?? {})}>
        <Dropdown
          disabled={disabled}
          value={value || defaultValueText || ''}
          selectedOptions={value ? [value] : []}
          onOptionSelect={(_, data) => {
            if (data.selectedOptions[0]) {
              onChange(data.selectedOptions[0])
            } else {
              onChange('')
            }
          }}
          appearance="underline"
        >
          {ADO_ISSUE_STATE_OPTIONS.filter((v) => v).map((item: ElementType<typeof ADO_ISSUE_STATE_OPTIONS>) => (
            <Option key={item} value={item} disabled={disabledOptions?.includes(item)}>
              {item}
            </Option>
          ))}
        </Dropdown>
      </Field>
    )
  },
)

State.displayName = 'State'
