import { z } from 'zod'

export interface ApiSetTeamDRIListResponse {
  readonly setStatus: boolean
  readonly errorMessage: string
}

export const apiSetTeamDRIListResponseSchema = z.object({
  setStatus: z.boolean(),
  errorMessage: z.string(),
})
