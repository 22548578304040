import { ITeamRootCauseItem } from '@copilot-dash/domain'
import { memo } from 'react'
import { TimeView } from '../../../../../components/Time/TimeView'
import { Text } from '@fluentui/react-components'

export const FixBy = memo((props: { rowData: ITeamRootCauseItem }) => {
  return (
    <>{props.rowData.eTA ? <TimeView value={props.rowData.eTA} format="YYYY/MM/DD" /> : <Text>Not selected</Text>}</>
  )
})

FixBy.displayName = 'FixBy'
