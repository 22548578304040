import { Text } from '@fluentui/react-components'
import { TicketLinkCopyButton } from '../../../../components/TicketTableColumnConfigs/TicketLinkCopyButton'
import { RouteLink, TicketRoute } from '../../../../router'
import { TicketScreenStore } from '../../TicketScreenStore'

export function TicketScreenBarTicketID() {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)

  return (
    <Text>
      <Text weight="semibold">ID: </Text>
      <RouteLink path={TicketRoute.navigator.generatePath({ id: ticketId })} openInNewTab type="link">
        {ticketId}
      </RouteLink>
      <TicketLinkCopyButton ticketId={ticketId} />
    </Text>
  )
}
