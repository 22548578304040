import { Fragment } from 'react'
import {
  Button,
  makeStyles,
  shorthands,
  tokens,
  mergeClasses,
  Body1Strong,
  ButtonProps,
} from '@fluentui/react-components'
import { WrenchSettingsRegular } from '@fluentui/react-icons'
import { CopilotDashTooltip } from '../CopilotDashTooltip/CopilotDashTooltip'

interface IProps {
  placeholder?: string
  apperance?: ButtonProps['appearance']
  icon?: ButtonProps['icon']
  className?: string
  isColumnSettingPanelOpen?: boolean
  onClickButton?: () => void
}

export const ColumnSettingButton: React.FC<IProps> = ({ ...props }) => {
  const styles = useStyles()

  return (
    <CopilotDashTooltip
      relationShip="label"
      toolTipContent={
        <Fragment>
          <Body1Strong> Column Settings </Body1Strong>
        </Fragment>
      }
      toolTipParentContent={
        <Button
          className={mergeClasses(
            styles.columnSetting,
            props.isColumnSettingPanelOpen ? styles.activeColumnSettingBtn : '',
            props.className,
          )}
          icon={props.icon ?? <WrenchSettingsRegular />}
          appearance={props.apperance ?? 'outline'}
          onClick={props.onClickButton}
          aria-label="Close Panel"
        >
          {props.placeholder}
        </Button>
      }
    />
  )
}

const useStyles = makeStyles({
  columnSetting: {
    '&:hover': {
      ...shorthands.border('1px', 'solid', tokens.colorBrandBackground),
      backgroundColor: tokens.colorNeutralBackground5Pressed,
    },
  },
  activeColumnSettingBtn: {
    ...shorthands.border('1px', 'solid', tokens.colorBrandBackground),
    backgroundColor: tokens.colorNeutralBackground5Pressed,
  },
})
