import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRaw3sOfflineDataGroup3 } from '../actions-raw-3s/getRaw3sOfflineDataGroup3'

export function get3sOfflineGeneralDiagnosticData(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
  transactionId: string,
): PromiseSnapshot<object> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.sssOfflineGeneralDiagnosticData?.[transactionId]
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      const sssGeneralDiagnosticData = (turn.sssOfflineGeneralDiagnosticData ??= {})
      sssGeneralDiagnosticData[transactionId] = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<object> {
    return (await getRaw3sOfflineDataGroup3(context, ticketId, turnId).promise) as object
  }
}
