import { INewTicketData } from '@copilot-dash/domain'
import { groupBy } from 'lodash'
import { mapADOStateToCopilotDashState } from '../../../../../../../components/TicketSummaryPanel/children/utils'
import { getTicketStepStageByFields, TICKET_STEP_STAGES } from '../../../../../../../components/TicketActionForm/utils'

function generateTicketState(ticket: INewTicketData) {
  const copilotDashState = mapADOStateToCopilotDashState(ticket.status, ticket.reasoning)
  let text = ''
  if (ticket.status === 'Closed') {
    text = copilotDashState
  } else {
    text = getTicketStepStageByFields(copilotDashState, ticket.rootCauseList ?? [], ticket.teamArea)
  }

  if (text.startsWith('Closed')) {
    return 'Closed'
  }
  if (text === TICKET_STEP_STAGES[1]) {
    return 'Team Assigned'
  }
  if (text === TICKET_STEP_STAGES[2]) {
    return 'Root Caused'
  }
  return 'Untriaged'
}

export function groupTicketsByState(tickets: INewTicketData[]) {
  const groupedTickets = groupBy(tickets, generateTicketState)
  return groupedTickets
}

export function groupTicketsByTeam(tickets: INewTicketData[]) {
  const groupedTickets = groupBy(tickets, 'teamArea')
  return groupedTickets
}
