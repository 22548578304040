import { IStoreGetter, IStoreSetter } from '@copilot-dash/core'
import { IActions } from './IActions'
import { IState } from './IState'

interface IParams {
  readonly set: IStoreSetter<IState>
  readonly get: IStoreGetter<IState>
}

export function createActions({ set, get }: IParams): IActions {
  return {
    selectTab(mode): void {
      set((state) => {
        state.selectedTab = mode
      })
    },

    toggleJsonWrap: () => {
      set((state) => {
        state.json.wrap = !state.json.wrap
      })
    },

    search: (keyword) => {
      set((state) => {
        state.table.keyword = keyword
      })
    },

    updateSearchResultCount: (count) => {
      set((state) => {
        state.table.searchResultCount = count
      })
    },
  }
}
