import { makeStyles } from '@fluentui/react-components'

export const useStyles = makeStyles({
  editorContainer: {
    flex: '1 0 1',
    width: '100%',
    background: '#fff',
    position: 'relative',
  },
  editorInput: {
    resize: 'none',
    fontSize: '15px',
    caretColor: 'rgb(5, 5, 5)',
    position: 'relative',
    tabSize: 1,
    outline: '0',
    padding: '10px',
    maxHeight: '200px',
    overflowY: 'auto',
    height: '100%',
  },
  editorPlaceholder: {
    color: '#999',
    overflow: 'hidden',
    position: 'absolute',
    textOverflow: 'ellipsis',
    top: '10px',
    left: '10px',
    fontSize: '15px',
    userSelect: 'none',
    display: 'inline-block',
    pointerEvents: 'none',
  },
  divider: { padding: '0 10px' },
})
