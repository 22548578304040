import { IStoreGetter, IStoreSetter } from '@copilot-dash/core'
import { ITicketConversationHistoryActions } from './ITicketConversationHistoryActions'
import { ITicketConversationHistoryState } from './ITicketConversationHistoryState'

interface IParams {
  readonly get: IStoreGetter<ITicketConversationHistoryState>
  readonly set: IStoreSetter<ITicketConversationHistoryState>
  readonly onSelectRef: React.RefObject<((turnId: string) => void) | undefined>
}

export function createConversationHistoryActions({
  get,
  set,
  onSelectRef,
}: IParams): ITicketConversationHistoryActions {
  return {
    selectConversation(turn) {
      onSelectRef.current?.(turn.id)
    },
  }
}
