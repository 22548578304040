import {
  DefaultProductChannelMapping,
  INewTicketData,
  SearchTextPrefixType,
  TeamViewSubMenuIds,
  getProductIdByName,
} from '@copilot-dash/domain'
import { Dropdown, Option, OptionOnSelectData, SelectionEvents, Text } from '@fluentui/react-components'
import { CalendarRegular } from '@fluentui/react-icons'
import { compact } from 'lodash'
import * as React from 'react'
import { ErrorView } from '../../../components/Error'
import { Row, Spacer } from '../../../components/Layout'
import { ExtendedTableColumnDefinition } from '../../../components/Table/ExtendedTableColumn'
import { ITableRef } from '../../../components/Table/ResizableColumnsTable'
import { AllSearchTableColumns } from '../../../components/TicketTableColumnConfigs'
import { TimeView } from '../../../components/Time/TimeView'
import { TeamRoute } from '../../../router'
import { useTeamViewStore } from '../store'
import { QuickSearch } from './QuickSearch'
import { TableLoading } from './TableLoading'
import { useStyles } from './TicketsTableForTopIssues.styles'
import { ToggleLeftDrawerIcon } from './ToggleDrawerLeftIcon'
import { TopIssueTickets } from './TopIssueTickets'

interface ITopIssuesTicketsProps {
  onRowClick?: (row?: INewTicketData) => void
  clickedTicketKey?: string
  keyPicker?: (item: INewTicketData, index: number) => string
}

export const TicketsTableForTopIssues: React.FC<ITopIssuesTicketsProps> = React.memo(
  ({
    onRowClick,
    clickedTicketKey,
    keyPicker = (item: INewTicketData, _: number) => {
      return item.ticketId
    },
  }) => {
    const tablesRef = React.useRef<{
      [key: string]: ITableRef | null
    }>({})

    const currentArgs = useTeamViewStore((state) => state.route.args)
    const selectedBatchId = currentArgs?.topIssueBatchId
    const productName = currentArgs?.product
    const productId = getProductIdByName(productName)

    const topIssueListResponse = useTeamViewStore((state) => state.topIssues.topIssueList)
    const issueClusteringBatchList = useTeamViewStore((state) => state.topIssues.issueClusteringBatchList)
    const topIssueBatchList = issueClusteringBatchList.data ?? []

    const leftPanelExpanded = useTeamViewStore((state) => state.leftDrawerOpen)
    const styles = useStyles()
    const columnSetting = app.settings.teamViewColumns.use()

    const searchText = useTeamViewStore((state) => state.tickets.filterForm.searchText)
    const email = useTeamViewStore((state) => state.tickets.filterForm.email)
    const tenantIds = useTeamViewStore((state) => state.tickets.filterForm.tenantIds)
    const searchTextPrefix = useTeamViewStore((state) => state.tickets.filterForm.searchTextPrefix)

    const tableColumns = React.useMemo(() => {
      const defaultColumns = AllSearchTableColumns(
        searchTextPrefix || SearchTextPrefixType.All,
        searchText,
        email,
        tenantIds,
      )
      const newSelectedColumns: ExtendedTableColumnDefinition<INewTicketData>[] = compact(
        columnSetting.map((columnId) => defaultColumns.find((column) => column.columnId === columnId)),
      )

      return newSelectedColumns
    }, [columnSetting, searchTextPrefix, email, searchText, tenantIds])

    const headerText = 'Top Issues'

    const currentSelectedGroupKeyRef = React.useRef('')
    const handleSelectionChange = React.useCallback((selectedItems: INewTicketData[] | undefined, groupKey: string) => {
      if (groupKey !== currentSelectedGroupKeyRef.current) {
        tablesRef.current[currentSelectedGroupKeyRef.current]?.resetSelection()
      }
      currentSelectedGroupKeyRef.current = groupKey
      useTeamViewStore.getState().batchTicketsSubStore.setSuggestion(null)
      useTeamViewStore.getState().batchTicketsSubStore.setTickets(selectedItems ?? [])
      useTeamViewStore.getState().toggleBatchUpdatePanel((selectedItems ?? [])?.length > 0)
      selectedItems &&
        useTeamViewStore
          .getState()
          .batchTicketsSubStore.fetchBatchTicketCustomTags(selectedItems.map((item) => item.ticketId))
    }, [])

    const handleBatchChange = React.useCallback(
      (event: SelectionEvents, data: OptionOnSelectData) => {
        if (data.optionValue === selectedBatchId) return
        useTeamViewStore.getState().topIssues.clearTopIssueList()
        const currentArgs = useTeamViewStore.getState().route.args
        TeamRoute.navigator.navigate({
          product: currentArgs.product,
          subMenu: currentArgs.subMenu,
          teamName: currentArgs.teamName,
          topIssueBatchId: data.optionValue,
          channel: DefaultProductChannelMapping[currentArgs.product],
        })
      },
      [selectedBatchId],
    )

    const selectedTeamId = useTeamViewStore((state) => state.teams.computed.selectedTeamId)
    React.useEffect(() => {
      if (typeof selectedTeamId === 'number' && selectedBatchId) {
        useTeamViewStore.getState().topIssues.fetchTopIssueList(selectedBatchId, selectedTeamId)
      }
    }, [selectedBatchId, selectedTeamId])

    React.useEffect(() => {
      if (typeof productId === 'number') {
        useTeamViewStore
          .getState()
          .topIssues.fetchIssueClusteringBatchList(productId)
          .then((resp) => {
            if (resp?.[0] && !selectedBatchId) {
              const currentArgs = useTeamViewStore.getState().route.args
              if (currentArgs.subMenu === TeamViewSubMenuIds.TopIssues) {
                TeamRoute.navigator.navigate(
                  {
                    product: currentArgs.product,
                    subMenu: currentArgs.subMenu,
                    teamName: currentArgs.teamName,
                    topIssueBatchId: resp[0].batchId,
                    channel: DefaultProductChannelMapping[currentArgs.product],
                  },
                  {
                    replace: true,
                  },
                )
              }
            }
          })
      }
    }, [selectedBatchId, productId])

    const selectedBatchInfo = topIssueBatchList.find((item) => item.batchId === selectedBatchId)
    const isTheEarliestBatch = selectedBatchId === topIssueBatchList[topIssueBatchList.length - 1]?.batchId

    const content = React.useMemo(() => {
      if (topIssueListResponse.status === 'waiting') {
        return <TableLoading className={styles.loading} />
      }
      if (topIssueListResponse.status === 'error') {
        return <ErrorView error={topIssueListResponse.error} />
      }
      if (topIssueListResponse.status === 'done') {
        return topIssueListResponse.data.length > 0 ? (
          topIssueListResponse.data.map((issue) => (
            <TopIssueTickets
              key={issue.issueId}
              batchId={selectedBatchId}
              issue={issue}
              columns={tableColumns}
              clickedTicketKey={clickedTicketKey}
              keyPicker={keyPicker}
              onRowClick={onRowClick}
              onSelectionChange={(tickets) => handleSelectionChange(tickets, issue.issueId)}
              tableRef={(ref) => {
                tablesRef.current[issue.issueId] = ref
              }}
              estimatedHeight={40 * issue.ticketCount}
              hideNewTag={isTheEarliestBatch}
              topIssueLength={topIssueListResponse.data.length}
            />
          ))
        ) : (
          <ErrorView.Custom level="WARNING" message="No Top Issues generated" />
        )
      }
      return null
    }, [
      clickedTicketKey,
      handleSelectionChange,
      isTheEarliestBatch,
      keyPicker,
      onRowClick,
      selectedBatchId,
      styles.loading,
      tableColumns,
      topIssueListResponse,
    ])

    return (
      <>
        <Row className={styles.header} wrap>
          {leftPanelExpanded ? null : <ToggleLeftDrawerIcon className={styles.expandIcon} type="expand" />}
          <Text size={600} weight="semibold">
            {headerText}
          </Text>
          <Spacer />
          <Row vAlign="center" wrap>
            <Text className={styles.rollingDate}>Weekly rolling date: </Text>
            <Spacer width={8} />
            <Dropdown
              selectedOptions={selectedBatchId ? [selectedBatchId] : []}
              onOptionSelect={handleBatchChange}
              button={
                selectedBatchInfo ? (
                  <Row vAlign="center">
                    <CalendarRegular fontSize={20} />
                    <Spacer width={4} />
                    <TimeView value={selectedBatchInfo.from} format="YYYY/M/D" />
                    {' - '}
                    <TimeView value={selectedBatchInfo.to} format="YYYY/M/D" />
                  </Row>
                ) : undefined
              }
            >
              {topIssueBatchList.map((item) => (
                <Option key={item.batchId} value={item.batchId} text={`${item.from}-${item.to}`}>
                  <Text>
                    <TimeView value={item.from} format="YYYY/M/D" />
                    {' - '}
                    <TimeView value={item.to} format="YYYY/M/D" />
                  </Text>
                </Option>
              ))}
            </Dropdown>
          </Row>
        </Row>
        <QuickSearch />
        {content}
      </>
    )
  },
)

TicketsTableForTopIssues.displayName = 'TicketsTableForTopIssues'
