import { createContext, ReactNode, useContext } from 'react'
import { IDashStore } from '../IDashStore'

const DashStoreContext = createContext<IDashStore | null>(null)

interface IProps {
  store: IDashStore
  children: ReactNode
}

export function DashStoreProvider({ store, children }: IProps) {
  return <DashStoreContext.Provider value={store}>{children}</DashStoreContext.Provider>
}

export function useDashStore() {
  const store = useContext(DashStoreContext)
  if (!store) {
    throw new Error('useDashStore must be used within a DashStoreProvider')
  }

  return store
}
