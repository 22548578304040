import { AdaptiveCard } from 'adaptivecards'
import * as markdown from 'markdown-it'

export function setupMarkdown() {
  AdaptiveCard.onProcessMarkdown = (text, result) => {
    const outputHtml = markdown.default().render(text)
    result.outputHtml = outputHtml
    result.didProcess = true
  }
}
