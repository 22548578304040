import { AsyncLoader } from '@copilot-dash/core'
import { ITicketData } from '@copilot-dash/domain'
import { ITicketSummaryPanelState } from '../ITicketSummaryPanelState'
import { ITicketSummaryPanelStore } from '../ITicketSummaryPanelStore'

export class ReactiveFeedbackTicketAction {
  private readonly store: ITicketSummaryPanelStore
  private readonly loader: AsyncLoader<ITicketData>

  constructor(store: ITicketSummaryPanelStore) {
    this.store = store
    this.loader = new AsyncLoader()

    this.submit(store.state.ticketId)
  }

  onStateChanged(state: ITicketSummaryPanelState, old: ITicketSummaryPanelState) {
    if (state.ticketId !== old.ticketId) {
      this.submit(state.ticketId)
    }
  }

  private submit(ticketId: string | undefined): void {
    this.loader.submit({
      promise: () => {
        if (!ticketId) {
          return null
        }

        return application.store.actions.getTicket(ticketId)
      },
      onChanged: (snapshot) => {
        this.store.setState({ ticketSnapshot: snapshot })
      },
    })
  }
}
