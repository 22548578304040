import { IErrorComponents } from '../interfaces/IErrorComponents'

export class CustomError extends Error {
  readonly data: CustomErrorData

  constructor(data: CustomErrorData) {
    super(data.message)
    this.data = data
    this.name = 'CustomError'
  }
}

export type CustomErrorData = Partial<IErrorComponents> & {
  readonly message: string
}
