import { ApiAISearchResponse, ApiSearchItems } from '@copilot-dash/api'
import { getProductIdByEndpoint, INewTicketData, TicketEmotionType } from '@copilot-dash/domain'
import { NewSearchCopilotTicketsResult } from '../NewSearchTicketsAction.types'

export class AiTicketConverter {
  static readonly fromApi = (api: ApiAISearchResponse): NewSearchCopilotTicketsResult => {
    const tickets: INewTicketData[] = api.searchItems.map((ticket: ApiSearchItems) => {
      return this.fromApiContext(ticket)
    })
    const hasMore = api.startIndex + api.stepNumber < api.count
    const satCount = api.emotionTypeCounts['Positive'] || 0
    const dsatCount = api.emotionTypeCounts['Negative'] || 0
    return {
      tickets,
      hasMore,
      satCount,
      dsatCount,
      ticketCount: api.count,
    }
  }

  static readonly fromApiContext = (item: ApiSearchItems): INewTicketData => {
    return {
      ...item,
      ticketId: item.id,
      dateTimeUtc: item.createDateTime,
      thumbFeedback: item.emotionType,
      tenantId: item.tenantId,
      ring: item.ring,
      endpoint: item.clientName,
      emotionType: item.emotionType === 'Positive' ? TicketEmotionType.Positive : TicketEmotionType.Negative,
      productId: getProductIdByEndpoint(item.clientName),
    }
  }
}
