import { makeStyles } from '@fluentui/react-components'

export const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    marginBottom: '1px',
    background: '#fff',
    padding: '4px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    verticalAlign: 'middle',
  },
  toolbarItem: {
    border: '0',
    display: 'flex',
    background: 'none',
    borderRadius: '10px',
    padding: '8px',
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
  buttonSpace: {
    marginRight: '2px',
  },
  toolbarButton: {
    cursor: 'pointer',
    opacity: 0.8,
  },
  toolbarButtonActive: {
    backgroundColor: 'rgba(223, 232, 250, 0.3)',
    opacity: 1,
  },
})
