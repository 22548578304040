import { z } from 'zod'

export interface ApiAllCustomTagsResponse {
  readonly customTags?: string[]
  readonly errorMessage?: string
}

export const apiAllCustomTagsResponseSchema = z.object({
  customTags: z.array(z.string()).optional(),
  errorMessage: z.string().optional(),
})
