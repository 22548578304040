import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStencil1Alpha),
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    ...shorthands.overflow('auto', 'hidden'),
    height: 'fit-content',
    width: '100%',
  },

  blockHeader: {
    ...shorthands.padding('16px', '16px'),
    backgroundColor: tokens.colorNeutralBackground3,
  },

  nameCell: {
    width: '200px',
    flexBasis: '200px',
    flexGrow: 0,
  },

  cell: {
    width: '100%',
    minHeight: '40px',
    overflow: 'hidden',
    ...shorthands.padding('4px', '12px'),
  },
})
