import { z } from 'zod'

export interface ApiIssueClusteringInfoListRequest {
  BatchId: string
  TeamId: number
}

export interface ApiIssueClusteringInfo {
  readonly issueTitle: string
  readonly issueId: string
  readonly priority: number
  readonly coverage: number | null | undefined
  readonly trends: number | null | undefined
  readonly isNew: boolean
  readonly ticketCount: number
  readonly impactedTenantsCount: number
}

export type ApiIssueClusteringInfoListResponse = {
  issueClusteringInfoList: Array<ApiIssueClusteringInfo>
  errorMessage: string
}

export const apiIssueClusteringInfoListResponseSchema = z.object({
  issueClusteringInfoList: z.array(
    z.object({
      issueTitle: z.string(),
      issueId: z.string(),
      priority: z.number(),
      coverage: z.number().nullable(),
      trends: z.number().nullable(),
      isNew: z.boolean(),
      ticketCount: z.number(),
      impactedTenantsCount: z.number(),
    }),
  ),
  errorMessage: z.string(),
})
