import { FC, useMemo } from 'react'
import { Column, Row } from '../../../../../components/Layout'
import { UserProfile } from '../../../../../components/User/UserProfile'
import { Link, makeStyles, shorthands, Text, tokens, mergeClasses } from '@fluentui/react-components'
import { InsightsTag } from './InsightsTag'
import { useSearchScreenActions } from '../../../store'

interface IProps {
  userId: string
  enableTooltip?: boolean
  className?: string
}

export const VIPUserTag: FC<IProps> = ({ userId, enableTooltip = false, className }) => {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  const truncatedTag = useMemo(() => {
    return (
      <UserProfile
        userId={userId}
        customUserPhotoClassName={styles.customUserPhoto}
        customUserDisplayNameClassName={mergeClasses(styles.customUserName, className)}
        isEnableDisplayNameToolTip={enableTooltip}
        tooltipContent={(user) => (
          <Column>
            <Row>
              <Text weight="semibold">{user.displayName}</Text>
            </Row>
            <Row>
              <Link
                onClick={() => {
                  actions.updateFeedbackInsightsIssueDetailDrillDownInfo(`User: ${user.displayName}`, {
                    userId: userId,
                  })
                }}
              >
                View DSATs
              </Link>
            </Row>
          </Column>
        )}
      />
    )
  }, [actions, enableTooltip, styles.customUserName, styles.customUserPhoto, userId, className])

  return (
    <Row>
      <InsightsTag
        isTagClearable={false}
        tagKey={'VIPUser'}
        truncatedTagValue={truncatedTag}
        className={styles.tagStyle}
      />
    </Row>
  )
}

const useStyles = makeStyles({
  customUserPhoto: {
    width: '18px',
    height: '18px',
    ...shorthands.borderRadius('50%'),
  },
  customUserName: {
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightSemibold,
    maxWidth: '70px',
  },
  tagStyle: {
    //No corresponding color found in fluentui
    backgroundColor: '#F1FAFF',
    ':hover': {
      backgroundColor: '#F1FAFF',
    },
    ':active': {
      backgroundColor: '#F1FAFF',
    },
    height: '26px',
  },
})
