import { Row } from '../Layout'
import { useStyles } from './SwitchButton.style'

type SwitchProps = {
  selected: 'left' | 'right'
  onToggle: (side: 'left' | 'right') => void
  labels: [string, string]
}

export const SwitchButton: React.FC<SwitchProps> = ({ selected, onToggle, labels }) => {
  const styles = useStyles()

  return (
    <Row className={styles.switchContainer}>
      <div
        className={`${styles.button} ${styles.leftButton} ${
          selected === 'left' ? `${styles.active} ${styles.leftButtonActive}` : styles.inactive
        }`}
        onClick={() => onToggle('left')}
      >
        {labels[0]}
      </div>
      <div
        className={`${styles.button} ${styles.rightButton} ${
          selected === 'right' ? `${styles.active} ${styles.rightButtonActive}` : styles.inactive
        }`}
        onClick={() => onToggle('right')}
      >
        {labels[1]}
      </div>
    </Row>
  )
}
