import { Route } from 'react-router-dom'
import { WorkItemRoutePage } from './WorkItemRoutePage'
import { createNavigator } from '../../navigator/createNavigator'
import { WorkItemRouteMapper } from './WorkItemRouteMapper'

const ID = 'workItem'
const PATH = '/team/:teamId/workItem/:workItemId'

// Test env: https://copilotdash-test.microsoft.com/team/37/workItem/fe6d804d-7250-4186-91c2-fc99b82069b6
export function WorkItemRoute() {
  return <Route id={ID} path={PATH} element={<WorkItemRoutePage />} />
}

WorkItemRoute.navigator = createNavigator(ID, PATH, WorkItemRouteMapper)
