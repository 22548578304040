import { TelemetryScope } from '@copilot-dash/logger'
import { useEffect } from 'react'
import { SymptomReportLayout } from './layout/SymptomReportLayout'

interface IProps {
  readonly ticketId: string
  readonly turnId: string
}

export function SymptomReport({ ticketId, turnId }: IProps) {
  useEffect(() => {
    let isAlive = true

    const promises: Promise<unknown>[] = [
      application.store.actions.getTicket(ticketId),
      application.store.actions.getTicketSession(ticketId),
      application.store.actions.get3sGwsLog(ticketId, turnId),
      application.store.actions.getTicketHealthData(ticketId, turnId),
    ]

    Promise.all(promises.map((item) => item.catch())).then(async () => {
      const ticket = await application.store.actions.getTicket(ticketId)
      if (isAlive) {
        Logger.telemetry.trackEvent('TicketSymptomReport/Loaded', {
          ticketId,
          ticketRing: ticket.ring,
          productEndpoint: ticket.clientName,
        })
      }
    })

    return () => {
      isAlive = false
    }
  }, [ticketId, turnId])

  return (
    <TelemetryScope scope="SymptomReportView" properties={{ ticketId: ticketId }}>
      <SymptomReportLayout ticketId={ticketId} turnId={turnId} />
    </TelemetryScope>
  )
}
