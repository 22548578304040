import { ITicketTurnTransactionHealthData } from '@copilot-dash/domain'
import { makeStyles, MessageBar, TableCell, TableRow, Text } from '@fluentui/react-components'
import { JSONTree } from 'react-json-tree'
import jsonTreeConfig from '../../Layout/json-tree-config'

interface IProps {
  readonly data: ITicketTurnTransactionHealthData
}

export function RowForRetrievalGwsLog({ data }: IProps) {
  const styles = useStyles()

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Retrieval</Text>
        <br />
        Source: GWS log
      </TableCell>
      <TableCell>
        {data.gwsLog && (
          <MessageBar icon={null} className={styles.symptomReportMessage}>
            <JSONTree data={data.gwsLog} {...jsonTreeConfig} hideRoot={true} />
          </MessageBar>
        )}
      </TableCell>
    </TableRow>
  )
}

// TODO: Need to be removed and replaced with the global styles
const useStyles = makeStyles({
  symptomReportMessage: {
    overflowX: 'scroll',
    marginTop: '10px',
    marginBottom: '10px',
    paddingRight: '12px',
  },
})
