import { IRouteQueryParams } from '../../../types/IRouteParams'

export function generatePathQueryStringByQueryParams(
  queryParams: IRouteQueryParams | undefined,
  withQuestionMark: boolean = true,
) {
  if (!queryParams) {
    return ''
  }

  const urlSearchParams = new URLSearchParams()
  for (const [key, value] of Object.entries(queryParams)) {
    if (typeof value === 'string') {
      urlSearchParams.append(key, value)
    } else if (Array.isArray(value)) {
      if (value.length === 0) {
        // Add an empty string to indicate that the key exists with an empty array as its value
        urlSearchParams.append(key, '')
      } else {
        value.forEach((v) => {
          urlSearchParams.append(key, v)
        })
      }
    }
  }

  let queryString = urlSearchParams.toString()
  if (queryString.length > 0 && withQuestionMark) {
    queryString = `?${queryString}`
  }

  return queryString
}
