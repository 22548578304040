import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { TicketDebugScreen } from '../../../screens/TicketDebug/TicketDebugScreen'
import { TicketDebugRoute } from './TicketDebugRoute'

export function TicketDebugRoutePage() {
  const args = TicketDebugRoute.navigator.useArgs()

  return (
    <PageTitle title={`Ticket Debug | ${args.ticketId}`}>
      <TicketDebugScreen ticketId={args.ticketId} />
    </PageTitle>
  )
}
