import { IQuery } from '@copilot-dash/domain'
import { memo } from 'react'
import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { UserProfile } from '../../../../../components/User/UserProfile'

export const ModifiedBy = memo((props: { rowData: IQuery }) => {
  const styles = useStyles()
  const userId = props.rowData.lastModifiedBy
  return (
    <UserProfile
      userId={userId}
      fallback={userId}
      customUserPhotoClassName={styles.customUserPhoto}
      customUserDisplayNameClassName={styles.customUserName}
    />
  )
})

const useStyles = makeStyles({
  customUserPhoto: {
    width: '24px',
    height: '24px',
    fontSize: tokens.fontSizeBase200,
    ...shorthands.borderRadius('50%'),
  },
  customUserName: {
    fontSize: tokens.fontSizeBase300,
  },
})

ModifiedBy.displayName = 'ModifiedBy'
