import { IFilterOption } from '../../Filter/IFilterOption.types'
// TODO: Delete this file after prod DB update
export const OptionSetHitAvalon: IFilterOption[] = [
  {
    key: 'BotEndpoint: Avalon',
    text: 'Yes',
  },
  {
    key: '!BotEndpoint: Avalon',
    text: 'No',
  },
]
