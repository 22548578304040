const colorPalette = generateColorPalette(generateAlphaValue('default'))
const colorPaletteAlpha1 = generateColorPalette(generateAlphaValue('alpha1'))
const colorPaletteAlpha2 = generateColorPalette(generateAlphaValue('alpha2'))

const hashMap = new Map<string, number>()
let hashIndex = 0

export function generateColor(seed: string, alpha: 'default' | 'alpha1' | 'alpha2' = 'default'): string {
  const seedHash = generateColorHash(seed)
  switch (alpha) {
    case 'default':
      return colorPalette[Math.abs(seedHash) % colorPalette.length]!
    case 'alpha1':
      return colorPaletteAlpha1[Math.abs(seedHash) % colorPaletteAlpha1.length]!
    case 'alpha2':
      return colorPaletteAlpha2[Math.abs(seedHash) % colorPaletteAlpha2.length]!
  }
}

function generateColorHash(seed: string): number {
  const index = hashMap.get(seed)
  if (index !== undefined) {
    return index
  }

  const newIndex = ++hashIndex
  hashMap.set(seed, newIndex)
  return newIndex
}

function generateAlphaValue(alpha: 'default' | 'alpha1' | 'alpha2'): number {
  switch (alpha) {
    case 'default':
      return 1.0
    case 'alpha1':
      return 0.6
    case 'alpha2':
      return 0.3
  }
}

function generateColorPalette(alpha: number) {
  return [
    `rgba(60, 179, 113, ${alpha})`,
    `rgba(255, 69, 0, ${alpha})`,
    `rgba(30, 144, 255, ${alpha})`,
    `rgba(138, 43, 226, ${alpha})`,
    `rgba(165, 42, 42, ${alpha})`,
    `rgba(211, 211, 211, ${alpha})`,
    `rgba(255, 165, 0, ${alpha})`,
  ]
}
