import { Edge, MarkerType, Node, ReactFlow } from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import { TurnViewSkeletonFlowNodeView } from './TurnViewSkeletonFlowNodeView'

const basicNode: Partial<Node<Record<string, unknown>>> = {
  type: 'custom',
  data: { label: 'Node' },
  width: 128,
  height: 40,
}

const basicEdge: Partial<Edge> = {
  type: 'smoothstep',
  markerEnd: {
    type: MarkerType.ArrowClosed,
  },
}

const initialNodes: Node<Record<string, unknown>>[] = [
  { id: 'node-1', data: { label: 'Node 1' }, position: { x: 250, y: 5 }, ...basicNode },
  // { id: 'node-2', data: { label: 'Node 2' }, position: { x: 100, y: 100 }, ...basicNode },
  { id: 'node-3', data: { label: 'Node 3' }, position: { x: 400, y: 100 }, ...basicNode },
  { id: 'node-4', data: { label: 'Node 4' }, position: { x: 400, y: 200 }, ...basicNode },
]

const initialEdges = [
  // { id: 'edge1-2', source: 'node-1', target: 'node-2', ...basicEdge },
  { id: 'edge1-3', source: 'node-1', target: 'node-3', ...basicEdge },
  { id: 'edge3-4', source: 'node-3', target: 'node-4', ...basicEdge },
]

export function TurnViewSkeletonFlow() {
  return (
    <ReactFlow
      defaultNodes={initialNodes}
      defaultEdges={initialEdges}
      nodeTypes={{
        custom: TurnViewSkeletonFlowNodeView,
      }}
      proOptions={{
        hideAttribution: true,
      }}
      draggable={false}
      panOnDrag={false}
      maxZoom={1.0}
      minZoom={1.0}
      fitView={true}
    />
  )
}
