import { ApiBizPerfLogItem, ApiLlmKustoLogItem, ApiTraceKustoLogItem } from '@copilot-dash/api'
import { IKustoBizPerfLogItem, IKustoLLMLogItem, IKustoTraceLogItem } from '@copilot-dash/domain'

export class KustoLogItemConvert {
  static fromApiBizPerfLogItem(api: ApiBizPerfLogItem, logName: string): IKustoBizPerfLogItem {
    return {
      ...api,
      logName: logName,
    }
  }

  static fromApiLlmKustoLogItem(api: ApiLlmKustoLogItem): IKustoLLMLogItem {
    return api
  }

  static fromApiTraceKustoLogItem(api: ApiTraceKustoLogItem): IKustoTraceLogItem {
    const { tIMESTAMP, ...rest } = api
    return {
      ...rest,
      timestamp: tIMESTAMP,
    }
  }
}
