import { PromiseSnapshot } from '@copilot-dash/core'
import { ITeamData } from '@copilot-dash/domain'
import { IDashStoreContext } from '../IDashStoreContext'

export function getTeams(context: IDashStoreContext, productId: number): PromiseSnapshot<ITeamData[]> {
  return context.getOrFetch<ITeamData[]>({
    get: (state) => {
      return state.team.teams?.[productId]
    },
    set: (state, snapshot) => {
      const teams = (state.team.teams ??= {})
      teams[productId] = snapshot
    },
    fetch: async () => {
      const response = await context.api.logCollector.getTeamList({ ProductId: productId })
      return response.teamList.map((team) => ({
        id: team.teamId,
        name: team.teamName,
      }))
    },
  })
}
