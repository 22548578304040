import { IFilterOption } from '../../Filter/IFilterOption.types'
import { TicketDSATStatusType } from '@copilot-dash/domain'

export const OptionSetDSATStatus: IFilterOption[] = [
  {
    key: TicketDSATStatusType.DSATStatusUntriaged,
    text: TicketDSATStatusType.DSATStatusUntriaged,
  },
  {
    key: TicketDSATStatusType.DSATStatusTeamAssigned,
    text: TicketDSATStatusType.DSATStatusTeamAssigned,
  },
  {
    key: TicketDSATStatusType.DSATStatusRootCaused,
    text: TicketDSATStatusType.DSATStatusRootCaused,
  },
  {
    key: TicketDSATStatusType.DSATStatusClosedFixed,
    text: TicketDSATStatusType.DSATStatusClosedFixed,
  },
  {
    key: TicketDSATStatusType.DSATStatusClosedByDesign,
    text: TicketDSATStatusType.DSATStatusClosedByDesign,
  },
  {
    key: TicketDSATStatusType.DSATStatusClosedNotActionable,
    text: TicketDSATStatusType.DSATStatusClosedNotActionable,
  },
]
