import { Column } from '../../components/Layout'
import { Spinner } from '@fluentui/react-components'

export function ScreenLoadingView() {
  return (
    <Column vAlign="center" hAlign="center">
      <Spinner label="Loading..." labelPosition="below" />
    </Column>
  )
}
