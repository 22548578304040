import { ApiOdsSearchConditions, ApiOdsSearchRequest } from '@copilot-dash/api'
import { ScenarioNames } from '@copilot-dash/domain'
import { EnableExceptionTracking, Logger } from '@copilot-dash/logger'
import { uniq, intersection } from 'lodash'
import { IDashStoreContext } from '../../IDashStoreContext'

interface IParams {
  readonly utterance?: string
  readonly response?: string
  readonly optionsSets?: string
  readonly sliceIds?: string
  readonly copilotExtensionIds?: string
  readonly flights?: string

  readonly clients: string[]
  readonly fromDate: string | undefined
  readonly toDate: string | undefined
}

const searchExcludeClients = [
  'M365ChatM365APPIOSOCV',
  'M365ChatM365APPAndroidOCV',
  'M365ChatM365APPIOSWebTabWCOCV',
  'M365ChatM365APPAndroidWebTabWCOCV',
  'M365ChatM365APPIOSStandaloneWCOCV',
  'M365ChatM365APPAndroidStandaloneWCOCV',
  'M365ChatM365APPIOSFreeWCOCV',
  'M365ChatM365APPAndroidFreeWCOCV',
  'M365CHATOUTLOOKMACWebTabWCOCV',
  'M365CHATMONARCHWebTabWCOCV',
  'M365CHATOUTLOOKWIN32WebTabWCOCV',
  'M365CHATOUTLOOKMACStandaloneWCOCV',
  'M365CHATMONARCHStandaloneWCOCV',
  'M365CHATOUTLOOKWIN32StandaloneWCOCV',
  'M365CHATOUTLOOKMACFreeWCOCV',
  'M365CHATMONARCHFreeWCOCV',
  'M365CHATOUTLOOKWIN32FreeWCOCV',
]

export class SearchTicketIdByODSAction {
  private readonly context: IDashStoreContext

  constructor(context: IDashStoreContext) {
    this.context = context
  }

  @EnableExceptionTracking()
  async search(
    params: IParams,
    searchId: string,
  ): Promise<{ utteranceOrResponseOdsSessionIdList: string[]; otherOdsSessionIdList: string[] }> {
    const startTime = Date.now()

    const clients = params.clients.filter((client) => !searchExcludeClients.includes(client))

    // Create requests for different search parameters. Each request contains ODS conditions combined with OR logic.
    // The results of utteranceOrResponseRequest will be separate, while the results of other requests will be combined using AND logic.
    const utteranceOrResponseRequest = this.createUtteranceOrResponseRequest(params, clients)
    const optionsSetsRequest = this.createOptionsSets(params, clients)
    const sliceIdsRequest = this.createSliceIds(params, clients)
    const copilotExtensionIdsRequest = this.createCopilotExtensionIds(params, clients)
    const flightsRequest = this.createFlights(params, clients)

    // Filter out null requests to ensure only valid requests are included
    const otherRequests: ApiOdsSearchRequest[] = [
      optionsSetsRequest,
      sliceIdsRequest,
      copilotExtensionIdsRequest,
      flightsRequest,
    ].filter((req): req is ApiOdsSearchRequest => req !== null)

    const utteranceOrResponsePromise = utteranceOrResponseRequest
      ? this.context.api.ods.search(utteranceOrResponseRequest)
      : Promise.resolve([])
    const otherPromises = otherRequests.map((request) => this.context.api.ods.search(request))

    const [utteranceOrResponseList, ...otherLists] = await Promise.all([utteranceOrResponsePromise, ...otherPromises])

    // Find the intersection of all other lists (AND logic)
    const combinedList = otherLists.reduce((acc, list) => intersection(acc, list), otherLists[0] || [])
    const duration = Date.now() - startTime
    Logger.telemetry.trackMetric('AiSearch', { duration, filters: params, searchId: searchId })
    return {
      utteranceOrResponseOdsSessionIdList: uniq(utteranceOrResponseList),
      otherOdsSessionIdList: uniq(combinedList),
    }
  }

  private createUtteranceOrResponseRequest(params: IParams, clients: string[]): ApiOdsSearchRequest | null {
    if (!params.response && !params.utterance) return null

    const conditions: ApiOdsSearchConditions[] = []

    if (params.utterance) {
      conditions.push(
        {
          value: params.utterance,
          filenamePrefix: 'ConversationHistory_',
          property: 'utterance',
          operator: 'Contains',
        },
        {
          value: params.utterance,
          filenamePrefix: 'copilot_feedback_internal_log_ConversationHistory_1',
          property: 'utterance',
          operator: 'Contains',
        },
      )
    }

    if (params.response) {
      conditions.push(
        {
          value: params.response,
          filenamePrefix: 'ConversationHistory_',
          property: 'response',
          operator: 'Contains',
        },
        {
          value: params.response,
          filenamePrefix: 'copilot_feedback_internal_log_ConversationHistory_1',
          property: 'response',
          operator: 'Contains',
        },
      )
    }
    return {
      Scenario: ScenarioNames.CopilotChatFeedback,
      ClientNames: clients,
      StartDate: params.fromDate,
      EndDate: params.toDate,
      conditions: conditions,
    }
  }

  private createSliceIds(params: IParams, clients: string[]): ApiOdsSearchRequest | null {
    if (!params.sliceIds) return null

    const conditions: ApiOdsSearchConditions[] = []

    conditions.push({
      value: params.sliceIds,
      filenamePrefix: 'copilot_feedback_internal_log_ConversationHistory_1_Group3',
      property: 'sliceids',
      operator: 'Contains',
    })
    return {
      Scenario: ScenarioNames.CopilotChatFeedback,
      ClientNames: clients,
      StartDate: params.fromDate,
      EndDate: params.toDate,
      conditions: conditions,
    }
  }

  private createCopilotExtensionIds(params: IParams, clients: string[]): ApiOdsSearchRequest | null {
    if (!params.copilotExtensionIds) return null
    const conditions: ApiOdsSearchConditions[] = []

    if (params.copilotExtensionIds) {
      conditions.push({
        value: params.copilotExtensionIds,
        filenamePrefix: 'copilot_feedback_internal_log_ConversationHistory_1_Group1',
        property: 'gptidentifiers',
        operator: 'Contains',
      })
    }
    return {
      Scenario: ScenarioNames.CopilotChatFeedback,
      ClientNames: clients,
      StartDate: params.fromDate,
      EndDate: params.toDate,
      conditions: conditions,
    }
  }

  private createOptionsSets(params: IParams, clients: string[]): ApiOdsSearchRequest | null {
    if (!params.optionsSets) return null
    const conditions: ApiOdsSearchConditions[] = []

    if (params.optionsSets) {
      conditions.push({
        value: params.optionsSets,
        filenamePrefix: 'copilot_feedback_internal_log_ConversationHistory_1_Group3',
        property: 'optionssets',
        operator: 'Contains',
      })
    }

    return {
      Scenario: ScenarioNames.CopilotChatFeedback,
      ClientNames: clients,
      StartDate: params.fromDate,
      EndDate: params.toDate,
      conditions: conditions,
    }
  }

  private createFlights(params: IParams, clients: string[]): ApiOdsSearchRequest | null {
    if (!params.flights) return null
    const conditions: ApiOdsSearchConditions[] = []

    if (params.flights) {
      conditions.push({
        value: params.flights,
        filenamePrefix: 'copilot_feedback_internal_log_ConversationHistory_1_Group3',
        property: 'flights',
        operator: 'Contains',
      })
    }

    return {
      Scenario: ScenarioNames.CopilotChatFeedback,
      ClientNames: clients,
      StartDate: params.fromDate,
      EndDate: params.toDate,
      conditions: conditions,
    }
  }
}
