import { User } from '@copilot-dash/api'
import { Avatar, Image } from '@fluentui/react-components'
import { useMemo, useRef } from 'react'
import { Row } from '../Layout'
import { useStyles } from './UserPhoto.styles'

interface IProps {
  userId: string
  className?: string
}

export function UserPhoto({ userId, className }: IProps) {
  const styles = useStyles()

  const avatarRef = useRef(null)
  const userSnapshot = app.store.use.getUserProfile(userId)

  const user: User | undefined = useMemo(() => {
    switch (userSnapshot.status) {
      case 'done':
        return userSnapshot.data
      default:
        return undefined
    }
  }, [userSnapshot.data, userSnapshot.status])

  const userPhotoSnapshot = app.store.use.getUserPhoto(userId)

  const avatarUrl: string | undefined = useMemo(() => {
    switch (userPhotoSnapshot.status) {
      case 'done':
        return URL.createObjectURL(userPhotoSnapshot.data)
      default:
        return undefined
    }
  }, [userPhotoSnapshot.data, userPhotoSnapshot.status])

  return (
    <Row>
      {avatarUrl ? (
        <Image ref={avatarRef} src={avatarUrl} className={className ? className : styles.avatarIcon} />
      ) : user?.displayName ? (
        <Avatar name={user?.displayName} className={className ? className : styles.avatarIcon} />
      ) : (
        <Avatar className={className ? className : styles.avatarIcon} />
      )}
    </Row>
  )
}
