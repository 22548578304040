import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Spinner,
} from '@fluentui/react-components'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { FC, memo, useRef, useState } from 'react'
import { FieldNames, IFormData, RootCauseCreateForm } from './RootCauseCreateForm'

interface IRootCauseCreateDialogProps {
  open: boolean
  onClose: () => void
  onOk: (data: IFormData) => Promise<void>
  okBtnText?: string
  fields?: FieldNames[]
  defaultValue?: Partial<IFormData>
  title?: string
}

export const RootCauseCreateDialog: FC<IRootCauseCreateDialogProps> = memo(
  ({
    open,
    onClose,
    onOk,
    okBtnText = 'Save',
    fields = [FieldNames.PRIORITY, FieldNames.TITLE],
    defaultValue,
    title = 'Create new root cause',
  }) => {
    const [isSavable, setIsSavable] = useState(false)
    const [saving, setSaving] = useState(false)
    const formDataRef = useRef<IFormData | null>(null)

    return (
      <Dialog
        open={open}
        onOpenChange={(_, data) => {
          if (!data.open) {
            onClose()
          }
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle
              action={
                <DialogTrigger action="close">
                  <Button appearance="subtle" aria-label="close" icon={<Dismiss24Regular />} />
                </DialogTrigger>
              }
            >
              {title}
            </DialogTitle>
            <DialogContent>
              <RootCauseCreateForm
                fields={fields}
                afterValidate={(isValid, data) => {
                  setIsSavable(isValid)
                  if (isValid) {
                    formDataRef.current = data
                  }
                }}
                defaultValue={defaultValue}
              />
            </DialogContent>
            <DialogActions position="end">
              <Button
                disabled={!isSavable || saving}
                appearance="primary"
                onClick={() => {
                  if (formDataRef.current) {
                    setSaving(true)
                    onOk(formDataRef.current).finally(() => setSaving(false))
                  }
                }}
                icon={saving ? <Spinner size="extra-tiny" /> : null}
              >
                {okBtnText}
              </Button>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Cancel</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    )
  },
)

RootCauseCreateDialog.displayName = 'RootCauseCreateDialog'
