import { TimeType } from '@copilot-dash/core'
import { Tooltip } from '@fluentui/react-components'
import { ReactElement } from 'react'
import { TimeViewTooltipContent } from './TimeViewTooltipContent'

interface IProps {
  readonly value: TimeType
  readonly children: ReactElement
}

export function TimeViewTooltip({ value, children }: IProps) {
  return (
    <Tooltip
      content={{
        children: <TimeViewTooltipContent value={value} />,
        onClick: (e) => {
          e.stopPropagation()
        },
      }}
      relationship={'label'}
    >
      {children}
    </Tooltip>
  )
}
