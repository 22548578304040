import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketSessionInteractionData } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { compact, uniq } from 'lodash'
import { IDashStoreContext } from '../../IDashStoreContext'
import { get3sGwsLog } from '../actions-kusto/get3sGwsLog'
import { getTicket } from '../actions-ticket/getTicket'
import { getTicketInteraction } from '../actions-ticket/getTicketInteraction'

export function get3sTransactionIds(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<string[]> {
  return context.getOrFetch<string[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.sssTransactionIds
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.sssTransactionIds = snapshot
    },
    fetch: async () => {
      const transaction = await getTicketInteraction(context, ticketId, messageId).promise
      const transactionIds = await doFetch(transaction)
      if (transactionIds.length !== 0) {
        return transactionIds
      }

      // Check if 3s is triggered
      const ticket = await getTicket(context, ticketId).promise
      if (!ticket.tag.sssTriggered) {
        throw TicketError.create('No3SDueToNotTriggered', { ticketId, ticketMessageId: messageId })
      }

      // Check if the turn is the last turn
      if (transaction.index !== 1) {
        throw TicketError.create('No3sOfflineDueToNotLatestTurn', { ticketId, ticketMessageId: messageId })
      }

      // Ops! No data found
      throw TicketError.create('No3S', { ticketId, ticketMessageId: messageId })
    },
  })

  async function doFetch(data: ITicketSessionInteractionData): Promise<string[]> {
    const transactionIdsFromInteractionInfo = await doFetchFromInteractionInfo(data)
    const transactionIdsFromGwsLog = await doFetchFromGwsLogs()

    return uniq(
      compact([
        ...transactionIdsFromInteractionInfo, //
        ...transactionIdsFromGwsLog,
      ]),
    )
  }

  async function doFetchFromInteractionInfo(data: ITicketSessionInteractionData): Promise<string[]> {
    return compact([
      ...data.sssOnlineDataList.map((item) => item.transactionId), //
      ...data.impressionIds,
    ])
  }

  async function doFetchFromGwsLogs(): Promise<string[]> {
    try {
      const logs = await get3sGwsLog(context, ticketId, messageId).promise
      return compact(logs.map((log) => log.transactionId))
    } catch (e) {
      return []
    }
  }
}
