import { makeStyles, Text, tokens } from '@fluentui/react-components'

export function UnknownScreenTitle() {
  const styles = useStyles()

  return (
    <Text wrap size={700}>
      <span>404.</span>
      <span> </span>
      <span className={styles.description}>Page Not Found</span>
    </Text>
  )
}

const useStyles = makeStyles({
  description: {
    color: tokens.colorNeutralForegroundDisabled,
  },
})
