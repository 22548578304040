import { ApiConversationGroup3, ApiConversationGroup3Metric } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getOdsBlobMetadata } from '../actions-blob/getOdsBlobMetadata'
import { getRawConversationIndex } from './getRawConversationIndex'
import { tryParseJson } from '../converters/utils'

const DECODE_METRIC_KEY = ['input', 'output', 'extendedData']

function extractDecodedData(metric: ApiConversationGroup3Metric): Record<string, unknown> | undefined {
  const decodedData: Record<string, unknown> = {}

  for (const key of DECODE_METRIC_KEY) {
    const metricValue = metric[key]

    if (typeof metricValue === 'string') {
      const segments = metricValue.split('\r\n').map((segment) => tryParseJson(segment) || segment)
      const isParsed = segments.some((segment) => typeof segment === 'object') || segments.length > 1

      if (isParsed) {
        decodedData[key] = segments.length === 1 ? segments[0] : segments
      }
    }
  }

  return Object.keys(decodedData).length > 0 ? decodedData : undefined
}

function decodeMetricsDataIfNeeded(conversationGroup3: ApiConversationGroup3): ApiConversationGroup3 {
  const conversation = conversationGroup3.conversation
  const request = conversation?.requests?.[0]
  const telemetry = request?.telemetry
  const metrics = telemetry?.metrics

  if (metrics && conversationGroup3.conversation?.requests) {
    const updatedMetrics = metrics.map((item) => {
      const decodedData = extractDecodedData(item)

      if (!decodedData) {
        return item
      }

      return {
        ...item,
        decodedData,
      }
    })

    conversationGroup3.conversation.requests[0] = {
      ...request,
      telemetry: {
        ...telemetry,
        metrics: updatedMetrics,
      },
    }
  }

  return conversationGroup3
}

export function getRawConversationGroup3(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
): PromiseSnapshot<ApiConversationGroup3> {
  return context.getOrFetch<ApiConversationGroup3>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.rawConversationGroup3
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.rawConversationGroup3 = snapshot
    },
    fetch: async () => {
      // Get turn index
      const turnIndex = await getRawConversationIndex(context, ticketId, turnId)

      // Get from grouped file
      const files = await getOdsBlobMetadata(context, ticketId).promise
      const file = files.groupedConversationFiles.find((item) => item.index === turnIndex)?.group3
      if (!file) {
        throw TicketError.create('NoConversations', { ticketId, ticketMessageId: turnId })
      }

      const conversationGroup3 = await context.api.ods.getConversationGroup3(file.fileUrl)

      return decodeMetricsDataIfNeeded(conversationGroup3)
    },
  })
}
