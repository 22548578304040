import { makeStyles, Skeleton, SkeletonItem, tokens } from '@fluentui/react-components'
import { Handle, Node, NodeProps, Position } from '@xyflow/react'

export function TurnViewSkeletonFlowNodeView({
  width,
  height,
  sourcePosition = Position.Bottom,
  targetPosition = Position.Top,
}: NodeProps<Node<Record<string, unknown>>>) {
  const styles = useStyles()

  return (
    <>
      <Handle type="target" position={targetPosition} className={styles.handle} />
      <Skeleton className={styles.skeleton}>
        <SkeletonItem shape="rectangle" />
      </Skeleton>
      <Handle type="source" position={sourcePosition} className={styles.handle} />
    </>
  )
}

const useStyles = makeStyles({
  skeleton: {
    border: `1px solid ${tokens.colorNeutralStroke1}`,
    borderRadius: tokens.borderRadiusMedium,
    overflow: 'hidden',
    height: '100%',
    display: 'flex',

    '& > *': {
      flex: 1,
      height: '100%',
    },
  },

  handle: {
    opacity: 0,

    '&.react-flow__handle-top': {
      transform: 'translate(-50%, 0)',
    },

    '&.react-flow__handle-bottom': {
      transform: 'translate(-50%, 0)',
    },

    '&.react-flow__handle-left': {
      transform: 'translate(0, -50%)',
    },

    '&.react-flow__handle-right': {
      transform: 'translate(0, -50%)',
    },
  },
})
