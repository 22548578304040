import { TicketDebugScreenLayout } from './Layout/TicketDebugScreenLayout'
import { TicketDebugStore } from './Store/TicketDebugStore'

interface IProps {
  readonly ticketId: string
}

export function TicketDebugScreen({ ticketId }: IProps) {
  return (
    <TicketDebugStore ticketId={ticketId}>
      <TicketDebugScreenLayout />
    </TicketDebugStore>
  )
}
