import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { TicketScreen } from '../../../screens/ticket/TicketScreen'
import { TicketRoute } from './TicketRoute'

export function TicketRoutePage() {
  const args = TicketRoute.navigator.useArgs()
  const title = `Ticket - ${args.id}`

  return (
    <PageTitle title={title}>
      <TicketScreen ticketId={args.id} />
    </PageTitle>
  )
}
