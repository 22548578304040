import { DebounceSettingsLeading, debounce } from 'lodash'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'

const useDebouncedCallback = <T extends unknown[], S>(
  fn: (...params: T) => S,
  wait: number = 250,
  options?: DebounceSettingsLeading,
) =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useCallback(debounce(fn, wait, options), [fn, options, wait])

export const useDebouncedState = <T>(
  init: T | (() => T),
  wait: number = 250,
  options?: DebounceSettingsLeading,
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState(init)
  const debounced = useDebouncedCallback(setState, wait, options)

  return [state, debounced]
}

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}
