import * as React from 'react'
import { useStyles } from './McpExperimentFeedbackTrend.styles'
import { FilterMultiSelectDropdown } from './components/filterMultiSelectDropdown'
import { FilterDropdownWithFreeTextSearch } from './components/filterDropdownWithFreeTextSearch'
import { FilterDatePicker } from './components/filterDatePicker'

interface IProps {
  datePickerRange: Date[]
  flightName: string
  deployRings: string[]
  clientRings: string[]
  clientPlatforms: string[]
  setDatePickerRange: (dateRange: Date[]) => void
  setFlightName: (flightName: string) => void
  setDeployRings: (deployRings: string[]) => void
  setClientRings: (clientRings: string[]) => void
  setClientPlatforms: (clientPlatforms: string[]) => void
}

const FLIGHTS = [
  'EnableSydneyMCPChat',
  'EnableMcpGPTTurbo0409MeetingNotes',
  'McpGPT4Turbo',
  'enableFreeTextToFixedPrompt',
  'teamsMeetingQnATranscriptNameResolverForEnglishLanguage',
  'teamsMeetingQnATranscriptNameResolverForJapaneseLanguage',
]

const DEPLOY_RINGS = ['msit', 'prod', 'sdf']

const CLIENT_RINGS = ['ring0', 'ring0_s', 'ring1', 'ring1_5', 'ring2', 'ring3_6', 'ring3_9', 'unk_fv']

const CLIENT_PLATFORM = ['Web', 'iOS', 'Android']

const comboId = 'McpExperimentFlightNameFilter'

const FeedbackFilters: React.FC<IProps> = ({
  datePickerRange,
  flightName,
  deployRings,
  clientRings,
  clientPlatforms,
  setFlightName,
  setDatePickerRange,
  setDeployRings,
  setClientRings,
  setClientPlatforms,
}) => {
  const styles = useStyles()

  return (
    <div className={styles.filterContainer}>
      <FilterDatePicker currentDatePickerRange={datePickerRange} setDatePickerRange={setDatePickerRange} />
      <FilterDropdownWithFreeTextSearch
        comboId={comboId}
        label="Select Flight"
        options={FLIGHTS}
        currentFilter={flightName}
        setFilter={setFlightName}
      />
      <FilterMultiSelectDropdown
        label="Select Deploy Rings"
        options={DEPLOY_RINGS}
        currentFilter={deployRings}
        setFilter={setDeployRings}
      />
      <FilterMultiSelectDropdown
        label="Select Client Rings"
        options={CLIENT_RINGS}
        currentFilter={clientRings}
        setFilter={setClientRings}
      />
      <FilterMultiSelectDropdown
        label="Select Client Platforms"
        options={CLIENT_PLATFORM}
        currentFilter={clientPlatforms}
        setFilter={setClientPlatforms}
      />
    </div>
  )
}
export default FeedbackFilters
