import { WritableDraft } from '@copilot-dash/core'
import { IDashStoreState } from '../IDashStoreState'
import { IDashStoreUse } from '../createDashStoreUse'

interface IParams<T> {
  readonly use: IDashStoreUse
  readonly request: () => T | Promise<T>
  readonly onSuccess?: (state: WritableDraft<IDashStoreState>, data: T) => void
  readonly onError?: (state: WritableDraft<IDashStoreState>, error: unknown) => void
}

export async function requestAnd<T>({ use, request, onSuccess, onError }: IParams<T>): Promise<T> {
  const handleOnSuccess = (data: T) => {
    if (onSuccess) {
      use.setState((state) => {
        onSuccess(state, data)
      })
    }
  }

  const handleOnError = (error: unknown) => {
    if (onError) {
      use.setState((state) => {
        onError(state, error)
      })
    }
  }

  try {
    await Promise.resolve()
    const result = await request()
    handleOnSuccess(result)
    return result
  } catch (error) {
    handleOnError(error)
    throw error
  }
}
