import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoGwsLogItem, ITicketSessionInteractionData, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicket } from '../actions-ticket/getTicket'
import { getTicketSession } from '../actions-ticket/getTicketSession'
import {
  validateTicketDiagnosticDataFor3SSearch,
  validateTicketDiagnosticDataForConversation,
} from '../validators/validateTicketDiagnosticData'
import { validateTicketKustoAIF, validateTicketKustoExpiration } from '../validators/validateTicketKustoData'
import { validateTicketUserConsent } from '../validators/validateTicketUserConsent'
import { convertTo3sGwsLogs } from './converter/convertTo3sGwsLogs'

export function get3sGwsLog(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IKustoGwsLogItem[]> {
  return context.getOrFetch<IKustoGwsLogItem[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.threeSGwsLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.threeSGwsLogs = snapshot
    },
    fetch: async () => {
      // Check User consent
      const session = await getTicketSession(context, ticketId).promise
      validateTicketUserConsent(ticketId, session)

      // Check ticket Kusto AIF
      const ticket = await getTicket(context, ticketId).promise
      validateTicketKustoAIF(ticketId, KustoLogTable.GwsLog, ticket)

      // Check ticket diagnostic data
      validateTicketDiagnosticDataFor3SSearch(ticketId, messageId, session)

      // Check interaction
      const interaction = session.interactions.find((item) => item.messageId === messageId)
      if (!interaction) {
        // Check ticket Kusto expiration
        validateTicketKustoExpiration(ticketId, KustoLogTable.GwsLog, session)

        // Check failed
        throw TicketError.create('NoKustoDueToInteractionFieldIsMissing', {
          ticketId,
          kustoLogTable: KustoLogTable.GwsLog,
        })
      }

      const logs = await doFetch(interaction)
      if (logs.length > 0) {
        return logs
      }

      // Check 3s is triggered
      if (!ticket.tag.sssTriggered) {
        throw TicketError.create('No3SDueToNotTriggered', { ticketId, ticketMessageId: messageId })
      }

      // Check ticket Kusto expiration
      validateTicketKustoExpiration(ticketId, KustoLogTable.GwsLog, session)

      // Check ticket conversation diagnostic data
      // TODO: Some tables may not require conversation data
      validateTicketDiagnosticDataForConversation(ticketId, messageId, session)

      // Check failed
      throw TicketError.create('NoKusto', { ticketId, kustoLogTable: KustoLogTable.GwsLog })
    },
  })

  async function doFetch(interaction: ITicketSessionInteractionData): Promise<IKustoGwsLogItem[]> {
    const response = await context.api.logCollector.getSydneySingleInfo({
      FetchLogName: 'gwsLog',
      HappenTimeArray: interaction.interactionTime ? [interaction.interactionTime] : [],
      MessageId: messageId,
    })

    return convertTo3sGwsLogs(response)
  }
}
