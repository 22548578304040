import { ITagDefinition } from './ITagDefinition.type'
import { useSearchScreenState } from '../../store'
import { TruncateTagFunction } from './TruncateTagFunction'
import { GetTagValueTextFunction } from './GetTagValueTextFunction'

export const GroundedPromptsTagConfig = (): ITagDefinition => {
  const form = useSearchScreenState((state) => state.form)
  const valueTexts = GetTagValueTextFunction('Prompt Details', 'Grounded Prompts', form.groundedPrompts || [])

  return {
    key: 'groundedPrompts',
    tagName: 'Grounded prompts',
    toolTipContentValue: valueTexts.join(', '),
    truncatedTagValue: TruncateTagFunction(valueTexts),
    IsShowTag: false,
    isTagClearable: true,
  }
}
