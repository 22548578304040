import { Outlet } from 'react-router-dom'
import { DashDialogProvider } from '../../components/Dialog'
import { PermissionScreen } from '../../screens/permission/PermissionScreen'
import { RootShell } from '../../screens/root/RootShell'

export function RootRoutePage() {
  return (
    <DashDialogProvider dialog={app.dialog}>
      <RootShell>
        {(() => {
          if (app.auth.permission?.general === false) {
            return <PermissionScreen />
          }

          return <Outlet />
        })()}
      </RootShell>
    </DashDialogProvider>
  )
}
