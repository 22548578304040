import { ITicketDiscussionPanelState } from './ITicketDiscussionPanelState'

export function createDiscussionPanelState(ticketId: string): ITicketDiscussionPanelState {
  return {
    ticketId,
    commentsSnapshot: { status: 'none' },
    postCommentSnapshot: { status: 'none' },
    editCommentSnapshot: { status: 'none' },
    deleteCommentSnapshot: { status: 'none' },
  }
}
