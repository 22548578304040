/* eslint-disable no-console */
import { IApplicationInsights, ITraceTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web'
import { DashLoggerConfigs } from './configs/DashLoggerConfigs'
import { IDashLoggerContext } from './interfaces/IDashLoggerContext'
import { IDashLoggerTrace } from './interfaces/IDashLoggerTrace'

export class DashLoggerTrace implements IDashLoggerTrace {
  private readonly clients: IApplicationInsights[]
  private readonly context: IDashLoggerContext

  constructor(clients: IApplicationInsights[], context: IDashLoggerContext) {
    this.clients = clients
    this.context = context
  }

  verbose(...messages: unknown[]): void {
    this.track(messages, SeverityLevel.Verbose)
  }

  info(...messages: unknown[]): void {
    this.track(messages, SeverityLevel.Information)
  }

  warn(...messages: unknown[]): void {
    this.track(messages, SeverityLevel.Warning)
  }

  error(...messages: unknown[]): void {
    this.track(messages, SeverityLevel.Error)
  }

  private track(messages: unknown[], severityLevel: SeverityLevel): void {
    this.trackForConsole(messages, severityLevel)
    this.trackForTelemetry(messages, severityLevel)
  }

  private trackForConsole(messages: unknown[], severityLevel: SeverityLevel) {
    switch (severityLevel) {
      case SeverityLevel.Verbose:
      case SeverityLevel.Information:
        console.info(DashLoggerConfigs.CONSOLE_LOG_PREFIX, ...messages)
        break
      case SeverityLevel.Warning:
        console.warn(DashLoggerConfigs.CONSOLE_LOG_PREFIX, ...messages)
        break
      case SeverityLevel.Error:
      case SeverityLevel.Critical:
        console.error(DashLoggerConfigs.CONSOLE_LOG_PREFIX, ...messages)
        break
    }
  }

  private trackForTelemetry(messages: unknown[], severityLevel: SeverityLevel) {
    const trace: ITraceTelemetry = {
      message: this.formatMessages(messages),
      severityLevel,
    }

    this.context.prepare()
    this.clients.forEach((client) => client.trackTrace(trace, this.context.properties))
  }

  private formatMessages(messages: unknown[]): string {
    return messages.map((message) => this.formatMessage(message)).join('\n')
  }

  private formatMessage(message: unknown): string {
    try {
      if (!message) {
        return ''
      }

      if (message instanceof Error) {
        return message.message
      }

      if (typeof message === 'string' || typeof message === 'number' || typeof message === 'boolean') {
        return message.toString()
      }

      return JSON.stringify(message)
    } catch (e) {
      return `${message}`
    }
  }
}
