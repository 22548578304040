import { IImage } from '@copilot-dash/domain'
import { IDashStoreContext } from '../IDashStoreContext'

export async function uploadImage(context: IDashStoreContext, fileName: string): Promise<IImage> {
  const response = await context.api.logCollector.postAttachment({
    fileName: fileName,
  })

  return response
}
