import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { Column, Row } from '../../Layout'
import { AnyDataBar } from '../children/bar/AnyDataBar'
import { AnyDataBody } from '../children/body/AnyDataBody'
import { AnyDataFooter } from '../children/footer/AnyDataFooter'

export function AnyDataLayout() {
  const styles = useStyles()

  return (
    <Column className={styles.root}>
      <AnyDataBar />
      <Row className={styles.body} vAlign="stretch" hAlign="stretch">
        <AnyDataBody />
      </Row>
      <AnyDataFooter />
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    maxHeight: '100%',
    height: '100%',

    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke3),
  },
  body: {
    flex: 1,
    overflow: 'hidden',

    ...shorthands.borderTop('1px', 'solid', tokens.colorNeutralStroke3),
    ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralStroke3),
  },
})
