import { IPublicClientApplication } from '@azure/msal-browser'
import { Logger } from '@copilot-dash/logger'
import { DashAuthConstants } from '../DashAuthConstants'
import { IDashAuthLoginOptions } from '../IDashAuth'

export async function login(client: IPublicClientApplication, options?: IDashAuthLoginOptions): Promise<void> {
  try {
    Logger.trace.info('Starting login by redirect flow')
    await client.loginRedirect({
      scopes: options?.scopes ?? DashAuthConstants.LOGIN_SCOPES,
      redirectUri: options?.redirectUri,
    })

    await new Promise((resolve) => setTimeout(resolve, DashAuthConstants.REDIRECT_WAIT_TIME_MS))
    throw new Error('Failed to login by redirect flow. Cause: Timeout')
  } catch (e) {
    throw new Error('Failed to login by redirect flow. Cause:' + e)
  }
}
