import { INewTicketData, ColumnKey, ColumnIdNaColumnIdNameMapping } from '@copilot-dash/domain'
import { ResponsiveCustomTagColumnComponent } from './ResponsiveCustomTagColumnComponent'
import { createExtendedTableColumn, ExtendedTableColumnDefinition } from '../Table/ExtendedTableColumn'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'

export const ColumnConfigCustomTag = (): ExtendedTableColumnDefinition<INewTicketData> =>
  createExtendedTableColumn<INewTicketData>({
    columnId: ColumnKey.CustomTags,
    columnName: ColumnIdNaColumnIdNameMapping[ColumnKey.CustomTags],
    isResizable: true,
    flex: 2,
    columnSize: {
      minWidth: 150,
      idealWidth: 200,
      padding: 0,
    },
    renderHeaderCell: () => <TableCellLayoutWithStyles truncate>Tags</TableCellLayoutWithStyles>,
    renderCell: (item: INewTicketData) => (
      <TableCellLayoutWithStyles truncate>
        {item.ticketId && <ResponsiveCustomTagColumnComponent item={item} />}
      </TableCellLayoutWithStyles>
    ),
  })
