import { INewTicketData, TicketEmotionType, ColumnKey, ColumnIdNaColumnIdNameMapping } from '@copilot-dash/domain'
import { createExtendedTableColumn, ExtendedTableColumnDefinition } from '../Table/ExtendedTableColumn'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { memo } from 'react'
import { useStyles } from './ColumnComponent.styles'
import { CopilotDashTooltip } from '../CopilotDashTooltip/CopilotDashTooltip'
import { Text } from '@fluentui/react-components'

export const ColumnConfigTeamArea: ExtendedTableColumnDefinition<INewTicketData> =
  createExtendedTableColumn<INewTicketData>({
    columnId: ColumnKey.Team,
    columnName: ColumnIdNaColumnIdNameMapping[ColumnKey.Team],
    isResizable: true,
    columnSize: {
      minWidth: 120,
      idealWidth: 200,
      padding: 0,
    },

    renderHeaderCell: () => <TableCellLayoutWithStyles>Team</TableCellLayoutWithStyles>,

    renderCell: (item: INewTicketData) => (
      <TableCellLayoutWithStyles truncate>
        <RenderTeam item={item} />
      </TableCellLayoutWithStyles>
    ),
  })

const RenderTeam = memo(({ item }: { item: INewTicketData }) => {
  const styles = useStyles()
  if (item.thumbFeedback === TicketEmotionType.Positive) {
    return <Text className={styles.grayColor}>N/A</Text>
  } else if (item.teamArea === '' || item.teamArea === undefined) return
  return (
    <CopilotDashTooltip
      relationShip="label"
      toolTipContent={
        <Text truncate className={styles.tooltipContent}>
          {item.teamArea}
        </Text>
      }
      className={styles.tooltip}
      toolTipParentContent={<Text className={styles.text}>{item.teamArea}</Text>}
    />
  )
})

RenderTeam.displayName = 'RenderTeam'
