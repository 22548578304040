import { AsyncLoader } from '@copilot-dash/core'
import { ITeamList } from '@copilot-dash/domain'
import { StateCreator } from 'zustand'
import { IStore } from '../../IStore'
import { IStoreTeam } from './types'

export const ALL_TEAMS = 'All Teams'

export const createTeamStore: StateCreator<IStore, [['zustand/immer', never]], [], IStoreTeam> = (set, get) => {
  const asyncLoaderForTeam = new AsyncLoader<ITeamList>()
  return {
    teamNameList: { status: 'none' },
    teamList: { status: 'none' },

    prepareTeamList: async (productId) => {
      const promise = application.store.actions.getTeams(productId)
      asyncLoaderForTeam.submit({
        promise: promise,
        onChanged: (snapshot) => {
          set((state) => {
            state.team.teamList = snapshot
            if (snapshot.status === 'done' && snapshot.data) {
              state.team.teamList.data = [...snapshot.data]
              state.team.teamList.data.sort((a, b) => a.name.localeCompare(b.name))
            }
          })
        },
      })
      return promise
    },
  }
}
