import { z } from 'zod'

export interface ApiM365TenantItem {
  readonly tenantId: string
  readonly tenantName: string
  readonly candidateCreatedDate?: string
  readonly enabledCreatedDate?: string
  readonly isPreviewCandidate: boolean
  readonly isPreviewEnabled: boolean
  readonly isS500: boolean
  readonly copilotEnabledUsers?: number
  readonly copilotAvailableUnits?: number
  readonly copilotSkuName?: string
  readonly createTime: string
  readonly modifyTime?: string
  readonly ticketCount: number
}

export interface ApiM365TenantListResponse {
  readonly m365TenantList: Array<ApiM365TenantItem>
}

export const apiM365TenantItemSchema = z.object({
  tenantId: z.string(),
  tenantName: z.string(),
  candidateCreatedDate: z.string().optional(),
  enabledCreatedDate: z.string().optional(),
  isPreviewCandidate: z.boolean(),
  isPreviewEnabled: z.boolean(),
  isS500: z.boolean(),
  copilotEnabledUsers: z.number().optional(),
  copilotAvailableUnits: z.number().optional(),
  copilotSkuName: z.string().optional(),
  createTime: z.string(),
  modifyTime: z.string().optional(),
  ticketCount: z.number(),
})

export const apiM365TenantListResponseSchema = z.object({
  m365TenantList: z.array(apiM365TenantItemSchema),
})
