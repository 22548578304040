import { ToolbarButton } from '@fluentui/react-components'
import { TextWrapOffRegular, TextWrapRegular } from '@fluentui/react-icons'
import { AnyDataStore } from '../../../AnyDataStore'

export function AnyJsonToolbarWrapSwitch() {
  const actions = AnyDataStore.useActions()
  const wrap = AnyDataStore.use((state) => state.json.wrap)

  return (
    <ToolbarButton
      title={wrap ? 'Click to disable text wrap' : 'Click to enable text wrap'}
      icon={wrap ? <TextWrapRegular /> : <TextWrapOffRegular />}
      onClick={actions.toggleJsonWrap}
    />
  )
}
