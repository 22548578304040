import { IRouteQueryParams } from '../../../types/IRouteParams'

export function generateParamsQueryBySearchParams(urlSearchParams: URLSearchParams): IRouteQueryParams {
  const queryParams: Record<string, string | string[]> = {}

  for (const key of urlSearchParams.keys()) {
    const values = urlSearchParams.getAll(key)
    if (values.length === 1) {
      queryParams[key] = values[0]!
    } else if (values.length > 1) {
      queryParams[key] = values
    }
  }

  return queryParams
}
