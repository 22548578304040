import { makeStyles, MessageBar, MessageBarBody } from '@fluentui/react-components'
import { RouteLink } from '../../../../router'

const MESSAGE_TEXT = `You don't have OCV permission to view additional information. Request `
const MESSAGE_LINK = 'https://ocv.microsoft.com/#/manage/?tab=Permissions'
const MESSAGE_LINK_TEXT = 'apply for access'
const MESSAGE_TRAILING_TEXT = '.'

export function HeaderNotificationBarForOcvPermission() {
  const styles = useStyles()

  return (
    <MessageBar className={styles.root} layout="singleline" intent="warning">
      <MessageBarBody className={styles.messageBody}>
        {MESSAGE_TEXT}
        <RouteLink path={MESSAGE_LINK} openInNewTab={true} type="link">
          {MESSAGE_LINK_TEXT}
        </RouteLink>
        {MESSAGE_TRAILING_TEXT}
      </MessageBarBody>
    </MessageBar>
  )
}

const useStyles = makeStyles({
  root: {
    // TODO: The left and right padding should be same and responsive.
    paddingLeft: '52px',
    paddingRight: '24px',
    borderRadius: '0',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    textOverflow: 'ellipsis',
  },

  messageBody: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
  },
})
