import { useFilterOptions } from '@copilot-dash/store'
import { Label, Spinner } from '@fluentui/react-components'
import { memo, useMemo } from 'react'
import { CopilotDashCheckBox } from '../../../Filter/CopilotDashCheckBox'
import { IFilterOption } from '../../../Filter/IFilterOption.types'
import { Column } from '../../../Layout'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (item: string[]) => void
}
export const IsGCIntentFilter: React.FC<IProps> = memo(({ selectedValues, onChangeValue }) => {
  const filterOptionsSnapshot = useFilterOptions('Customized tag', 'IsGCIntent')
  const filterOptions: IFilterOption[] = useMemo(() => {
    switch (filterOptionsSnapshot.status) {
      case 'done':
        return filterOptionsSnapshot.data.flatMap((item) => [
          item,
          {
            key: '!' + item.key,
            text: 'No',
          },
        ])
      default:
        return []
    }
  }, [filterOptionsSnapshot.data, filterOptionsSnapshot.status])

  return filterOptionsSnapshot.status === 'waiting' ? (
    <Spinner size="extra-small" />
  ) : (
    <Column>
      <Label weight="semibold">Is GC Intent:</Label>
      <CopilotDashCheckBox
        options={filterOptions}
        selectedOptions={selectedValues}
        onChange={onChangeValue}
        multiple={false}
      />
    </Column>
  )
})
IsGCIntentFilter.displayName = 'IsGCIntentFilter'
