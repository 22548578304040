import { makeStyles } from '@fluentui/react-components'

export const useStyles = makeStyles({
  ul: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  li: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '2px 0',
  },
  legendBox: {
    width: '8px',
    height: '8px',
    borderRadius: '2px',
    flexShrink: 0,
  },
  legendLabel: {
    color: '#000',
    fontSize: '12px',
    userSelect: 'none',
  },
  legendContainer: {
    display: 'flex',
    height: '120px',
    width: '30%',
    marginRight: '2px',
    marginTop: '40px',
    scrollbarWidth: 'none',
    overflowX: 'hidden',
  },
})
