import { makeStyles } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    height: '200px',
  },

  progressBar: {
    width: '50%',
    maxWidth: '400px',
  },

  errorLayout: {
    marginTop: '96px',
  },
})
