import { z } from 'zod'
import { ApiV2EmotionType, ApiV2Ring, ApiV2Status } from './ApiV2Ticket'

export const apiV2EmotionTypeSchema = z.nativeEnum(ApiV2EmotionType)

export const apiV2OcvPropertySchema = z.object({
  id: z.string(),
  link: z.string(),
  language: z.string(),
  environment: z.string(),
  ocvIssuesTitles: z.array(z.string()),
  attachmentMetadataOriginalNames: z.array(z.string()),
  aiContextPromptLanguage: z.string(),
  aiContextResponseMessageLanguage: z.string(),
  featureArea: z.string(),
  screenshotCount: z.string(),
  ocvRawFileUrl: z.string(),
})

export const apiV2KustoLogItemSchema = z.object({
  id: z.string(),
  createDateTime: z.string(),
  url: z.string(),
  kustoLogType: z.string(),
})

export const apiV2StatusSchema = z.nativeEnum(ApiV2Status)

export const apiV2ReplayInfoSchema = z.object({
  transactionId: z.string(),
  replayServiceCallTraceId: z.string(),
  feedbackServiceCallTraceId: z.string(),
  entityTypes: z.array(z.string()),
  triggerFlag: z.string().optional(),
})

export const apiV2FeedbackBlobFileSchema = z.object({
  fileName: z.string().optional(),
  fileUrl: z.string().optional(),
})

export const apiV2RingSchema = z.nativeEnum(ApiV2Ring)

export const apiV2InteractionInfoSchema = z.object({
  index: z.number(),
  conversationId: z.string(),
  messageId: z.string(),
  interactionTime: z.string(),
  replayInfos: z.array(apiV2ReplayInfoSchema),
  originalServiceCallTraceIds: z.array(z.string()),
  conversationHistoryUrl: z.string(),
  clientName: z.string().optional(),
})

export const apiV2ProcessStatusSchema = z.object({
  status: apiV2StatusSchema,
  retryCount: z.number(),
  errorMessage: z.string().optional(),
  collectedTime: z.string().optional(),
  logLines: z.number(),
})

export const apiV2StatusPropertySchema = z.object({
  metaDataProcessStatus: apiV2ProcessStatusSchema,
  ocvProcessStatus: apiV2ProcessStatusSchema,
  kustoLogProcessStatus: z.record(apiV2ProcessStatusSchema),
})

export const apiV2TicketSchema = z.object({
  id: z.string(),
  createDateTime: z.string(),
  version: z.number(),
  verbatim: z.string().optional(),
  tags: z.array(z.string()),
  clientName: z.string(),
  additionalContext: z.string(),
  userId: z.string(),
  tenantId: z.string(),
  emailAddress: z.string().optional(),
  scenarioName: z.string(),
  ring: apiV2RingSchema.optional(),
  emotionType: apiV2EmotionTypeSchema.optional(),
  ocvProperty: apiV2OcvPropertySchema.optional(),
  productName: z.string().optional(),
  interactionInfos: z.array(apiV2InteractionInfoSchema),
  statusProperty: apiV2StatusPropertySchema,
  kustoData: z.array(apiV2KustoLogItemSchema),
  feedbackBlobFiles: z.array(apiV2FeedbackBlobFileSchema),
  isEU: z.boolean(),
  is3STriggered: z.boolean(),
  hasUserConsent: z.boolean().optional(),
  conversationId: z.string(),
  messageId: z.string(),
  utterance: z.string(),
  response: z.string(),
  processingSessionId: z.string(),
})
