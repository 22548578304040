import { createContext, useContext } from 'react'
import { ISearchScreenStore } from './ISearchScreenStore'
import { ISearchScreenActions } from './ISearchScreenStoreActions'
import { ISearchScreenState } from './ISearchScreenStoreState'

export const SearchScreenStoreContext = createContext<{
  readonly store: ISearchScreenStore
  readonly actions: ISearchScreenActions
} | null>(null)

function useSearchScreenContext() {
  const value = useContext(SearchScreenStoreContext)
  if (value === null) {
    throw new Error('useSearchScreenContext must be used within a SearchScreenStoreProvider')
  }

  return value
}

export function useSearchScreenState<T>(selector: (state: ISearchScreenState) => T): T {
  return useSearchScreenContext().store.use(selector)
}

export function useSearchScreenActions(): ISearchScreenActions {
  return useSearchScreenContext().actions
}
