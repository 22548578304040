import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer'
import { QuoteNode } from '@lexical/rich-text'
import { useMemo } from 'react'
import { EditorLayout } from '../editor-layout/EditorLayout'
import { ImageNode } from '../editor-nodes/ImageNode'
import { MentionNode } from '../editor-nodes/MentionNode'
import './Editor.css'

export function Editor() {
  return useMemo(() => {
    return (
      <LexicalComposer initialConfig={initialConfig}>
        <EditorLayout />
      </LexicalComposer>
    )
  }, [])
}

const initialConfig: InitialConfigType = {
  namespace: 'CopilotDash rich text',
  nodes: [
    ListNode, //
    ListItemNode,
    LinkNode,
    QuoteNode,
    MentionNode,
    AutoLinkNode,
    ImageNode,
  ],
  onError(error: Error) {
    throw error
  },
  theme: {
    code: 'editor-code',
    link: 'editor-link',
    list: {
      listitem: 'editor-listitem',
      nested: {
        listitem: 'editor-nested-listitem',
      },
      ol: 'editor-list-ol',
      ul: 'editor-list-ul',
    },
    ltr: 'ltr',
    paragraph: 'editor-paragraph',
    placeholder: 'editor-placeholder',
    quote: 'editor-quote',
    rtl: 'rtl',
    text: {
      bold: 'editor-text-bold',
      code: 'editor-text-code',
      hashtag: 'editor-text-hashtag',
      italic: 'editor-text-italic',
      overflowed: 'editor-text-overflowed',
      strikethrough: 'editor-text-strikethrough',
      underline: 'editor-text-underline',
      underlineStrikethrough: 'editor-text-underlineStrikethrough',
    },
  },
}
