import { ToolbarButton } from '@fluentui/react-components'
import { DismissRegular, SearchRegular } from '@fluentui/react-icons'

interface IProps {
  readonly visible: boolean
  readonly onToggle: () => void
}

export function AnyTableToolbarSearchButton({ visible, onToggle }: IProps) {
  switch (visible) {
    case true:
      return <ToolbarButton title="Close" icon={<DismissRegular />} onClick={onToggle} />
    case false:
      return <ToolbarButton title="Search" icon={<SearchRegular />} onClick={onToggle} />
  }
}
