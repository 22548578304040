import { IProductData } from '@copilot-dash/domain'
import { ReactNode, useEffect, useId, useMemo, useRef } from 'react'
import { GlobalProductProvider } from './GlobalProductProvider'
import { IGlobalProductSelectorData } from './store/createGlobalProductState'

interface IProps {
  readonly value?: string
  readonly onChange?: (product: IProductData) => void
  readonly disabled?: boolean
  readonly isItemDisabled?: (product: IProductData) => boolean
  readonly children: ReactNode
}

export function GlobalProductSelector({ value, onChange, disabled, isItemDisabled, children }: IProps) {
  const id = useId()
  const actions = GlobalProductProvider.useActions()

  const data: IGlobalProductSelectorData = useMemo(() => {
    return {
      id,
      value,
      onChange,
      disabled,
      isItemDisabled,
    }
  }, [disabled, id, onChange, isItemDisabled, value])

  const dataRef = useRef(data)
  dataRef.current = data

  // 1. Register
  useEffect(() => {
    return actions.register(dataRef.current)
  }, [actions])

  // 2. Update
  useEffect(() => {
    actions.update(data)
  }, [actions, data])

  return children
}
