import { makeStyles, tokens, shorthands } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    maxWidth: '100%',
    overflow: 'hidden',
  },
  time: {
    color: tokens.colorScrollbarOverlay,
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    wordBreak: 'normal',
    maxWidth: '45%',
    fontSize: tokens.fontSizeBase200,
  },
  text: {
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    wordBreak: 'normal',
    maxWidth: '80%',
    marginRight: '8px',
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightSemibold,
  },
  icon: {
    color: tokens.colorScrollbarOverlay,
    fontSize: tokens.fontSizeBase200,
    marginLeft: '4px',
  },
  transparentIcon: {
    color: 'transparent',
    fontSize: tokens.fontSizeBase200,
    marginLeft: '4px',
  },
  userPhoto: {
    width: '24px',
    height: '24px',
    ...shorthands.margin(0, '8px', 0, 0),
    borderRadius: '50%',
    fontSize: tokens.fontSizeBase200,
  },
})
