import { FC, memo } from 'react'
import { toDate } from '../utils'
import { Column, Row, Spacer } from '../../Layout'
import { makeStyles, shorthands, Text } from '@fluentui/react-components'
import { TimeSelector } from './components/TimeSelector'
import { Times } from '@copilot-dash/core'
import { isNil } from 'lodash'
import { DateObject } from 'react-multi-date-picker'

interface ITimeRangePickerPanelProps {
  position: string
  state?: {
    value: DateObject[] | null | undefined
  }
  datePickerProps?: {
    onChange?: (
      date: DateObject | DateObject[] | null,
      options?: {
        validatedValue: string | Array<string>
        input: HTMLElement
        isTyping: boolean
      },
    ) => void | false
  }
  handleChange?: (
    selectedDate: DateObject[],
    options: { selectedDate: DateObject[]; focused: DateObject | null | undefined },
  ) => void
  onClickDate?: (date: DateObject | null | undefined) => void
}

export const TimeRangePickerPanel: FC<ITimeRangePickerPanelProps> = memo(
  ({ position, state, handleChange, onClickDate, datePickerProps }) => {
    const styles = useStyles()

    const onChange = (value: Date | null, index: number) => {
      if (handleChange && value) {
        const newValue = [...(state?.value || [])]
        newValue[index]?.setDate(value)
        newValue.sort((a, b) => a.valueOf() - b.valueOf())
        onClickDate?.(newValue[index])
        handleChange(newValue, { ...state, selectedDate: newValue, focused: newValue[index] })
        datePickerProps?.onChange?.(newValue)
      }
    }
    if (isNil(state?.value)) return
    const list = Array.isArray(state.value) ? state.value : []
    if (list.length === 0) return
    const from = list[0]
    const to = list[1]
    return (
      <Column className={styles.panel}>
        <Row className={styles.timePickerRow}>
          {from ? (
            <>
              <Text weight="medium">
                {Times.format(toDate(from), {
                  format: 'MM/DD',
                  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                })}
              </Text>
              <Spacer width={6} />
              <TimeSelector value={toDate(from)} onChange={(date) => onChange(date, 0)} />
            </>
          ) : null}
          {to ? (
            <>
              <Spacer width={10} />
              <Text>to</Text>
              <Spacer width={10} />
              <Text weight="medium">
                {Times.format(toDate(to), {
                  format: 'MM/DD',
                  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                })}
              </Text>
              <Spacer width={6} />
              <TimeSelector value={toDate(to)} onChange={(date) => onChange(date, 1)} />
            </>
          ) : null}
        </Row>
      </Column>
    )
  },
)

TimeRangePickerPanel.displayName = 'TimeRangePickerPanel'

const useStyles = makeStyles({
  panel: {
    ...shorthands.padding('16px'),
  },
  timePickerRow: {
    alignItems: 'baseline',
    justifyContent: 'center',
  },
})
