import { INewTicketData, ColumnKey, ColumnIdNaColumnIdNameMapping, DefaultProductEndpoints } from '@copilot-dash/domain'
import { ExtendedTableColumnDefinition, createExtendedTableColumn } from '../Table/ExtendedTableColumn'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'

export const ColumnConfigEndpoint: ExtendedTableColumnDefinition<INewTicketData> =
  createExtendedTableColumn<INewTicketData>({
    columnId: ColumnKey.Endpoint,
    columnName: ColumnIdNaColumnIdNameMapping[ColumnKey.Endpoint],
    isResizable: true,
    columnSize: {
      minWidth: 50,
      idealWidth: 200,
      padding: 0,
    },

    renderHeaderCell: () => <TableCellLayoutWithStyles>Endpoint</TableCellLayoutWithStyles>,

    renderCell: (item: INewTicketData) => (
      <TableCellLayoutWithStyles truncate>
        {DefaultProductEndpoints.find((endpoint) => endpoint.name.toLowerCase() === item.endpoint?.toLowerCase())
          ?.title ?? item.endpoint}
      </TableCellLayoutWithStyles>
    ),
  })
