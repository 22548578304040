import { Component, ReactNode } from 'react'
import { ErrorView } from './ErrorView'

interface IProps {
  readonly level?: 'global' | 'screen' | 'component'
  readonly label: string
  readonly children?: ReactNode
  readonly className?: string
}

interface IState {
  readonly error?: unknown
}

export class ErrorViewBoundary extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      error: null,
    }
  }

  override componentDidCatch(error: Error) {
    // Throw error in development mode for component level
    // if (application.env.ring === 'dev') {
    //   if (this.props.level === 'component') {
    //     throw error
    //   }
    // }

    Logger.trace.error(`ErrorBoundary: ${this.props.label}. ${error.message}`, error)
    this.setState({
      error,
    })
  }

  override render() {
    if (this.state.error) {
      return <ErrorView className={this.props.className} error={this.state.error} />
    }

    return this.props.children
  }
}
