import { Combobox, Field, Option } from '@fluentui/react-components'
import { useStyles } from '../McpExperimentFeedbackTrend.styles'

interface IProps {
  label: string
  options: string[]
  currentFilter: string[]
  setFilter(filter: string[]): void
}

export const FilterMultiSelectDropdown: React.FC<IProps> = ({ label, options, currentFilter, setFilter }) => {
  const styles = useStyles()

  const handleChange = (event: React.SyntheticEvent, data: { selectedOptions: string[] }) => {
    setFilter(data.selectedOptions)
  }

  return (
    <div className={styles.filterItem}>
      <Field label={label}>
        <Combobox
          multiselect={true}
          placeholder="Select"
          selectedOptions={currentFilter}
          value={currentFilter.join(', ')}
          onOptionSelect={handleChange}
        >
          {options.map((window: string) => (
            <Option key={window}>{window}</Option>
          ))}
        </Combobox>
      </Field>
    </div>
  )
}
