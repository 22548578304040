import { TelemetryScope } from '@copilot-dash/logger'
import { Layout } from './layout/Layout'

interface IProps {
  readonly ticketId: string
}

export function SymptomReportV2({ ticketId }: IProps) {
  return (
    <TelemetryScope scope="SymptomReportViewV2" properties={{ ticketId: ticketId }}>
      <Layout ticketId={ticketId} />
    </TelemetryScope>
  )
}
