import * as React from 'react'
import { Fragment } from 'react'
import {
  Button,
  Body1Strong,
  makeStyles,
  shorthands,
  tokens,
  mergeClasses,
  CounterBadge,
  ButtonProps,
} from '@fluentui/react-components'
import { Filter16Filled } from '@fluentui/react-icons'
import { CopilotDashTooltip } from '../CopilotDashTooltip/CopilotDashTooltip'

interface IProps {
  placeholder?: string
  isTicketsFilterPanelOpen?: boolean
  onClickButton?: () => void
  badgeCount?: number
  className?: string
  appearance?: ButtonProps['appearance']
}

export const TicketsFilterButton: React.FC<IProps> = (props) => {
  const styles = useStyles()

  return (
    <CopilotDashTooltip
      relationShip="label"
      toolTipContent={
        <Fragment>
          <Body1Strong> Filters </Body1Strong>
        </Fragment>
      }
      toolTipParentContent={
        <div className={props.className} style={{ position: 'relative' }}>
          <Button
            className={mergeClasses(styles.filter, props.isTicketsFilterPanelOpen ? styles.activeFilterBtn : '')}
            size="medium"
            icon={<Filter16Filled />}
            onClick={props.onClickButton}
            appearance={props.appearance}
          >
            {props.placeholder}
          </Button>
          <CounterBadge
            count={props.badgeCount}
            appearance="filled"
            style={{ position: 'absolute', top: '-10px', right: '0px' }}
          />
        </div>
      }
    />
  )
}

const useStyles = makeStyles({
  filter: {
    '&:hover': {
      ...shorthands.border('1px', 'solid', tokens.colorBrandBackground),
      backgroundColor: tokens.colorNeutralBackground5Pressed,
    },
  },
  activeFilterBtn: {
    ...shorthands.border('1px', 'solid', tokens.colorBrandBackground),
    backgroundColor: tokens.colorNeutralBackground5Pressed,
  },
})
