import { InternalAxiosRequestConfig } from 'axios'
import { ApiClient } from '../../client/ApiClient'
import { ApiClientEndpoint } from '../../client/ApiClientEndpoint'
import { DashApiOptions } from '../../DashApiOptions'

const BASE_URL_DEV = 'https://api.diagnosticssdf.office.com'
const BASE_URL_PROD = 'https://portal.diagnostics.office.com'

const SCOPES_DEV = ['https://api.diagnosticssdf.office.com/read']
const SCOPES_PROD = ['https://portal.diagnostics.office.com/read']

export class OdsApiClient extends ApiClient {
  constructor(options: DashApiOptions) {
    super({
      ...options,
      endpoint: ApiClientEndpoint.ODS,
      config: {},
    })
  }

  protected override async onRequest(config: InternalAxiosRequestConfig<unknown>): Promise<void> {
    let baseUrl: string
    let scopes: string[]

    const ring = this.context.getRing()
    switch (ring) {
      case 'dev':
        baseUrl = BASE_URL_DEV
        scopes = SCOPES_DEV
        break
      case 'test':
      case 'sdf':
      case 'msit':
      case 'unknown':
        baseUrl = BASE_URL_PROD
        scopes = SCOPES_PROD
        break
    }

    config.baseURL = baseUrl
    config.headers.Authorization = `Bearer ${await this.context.acquireToken(scopes)}`
  }
}
