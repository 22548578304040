import { Spinner, makeStyles, tokens, Text } from '@fluentui/react-components'
import { Column, Row, Spacer } from '../../../Layout'
import { QueryRowInPanel } from '../queries/QueryRowInPanel'
import { useTicketFilterStore } from '../../store/store'
import { FC, memo } from 'react'
import { ErrorView } from '../../../Error/ErrorView'

interface IProps {
  readonly className?: string
  readonly children?: React.ReactNode
}
export const MyQueriesTab: FC<IProps> = memo((props: IProps) => {
  const styles = useStyles()
  const selectedProductId = useTicketFilterStore((state) => state.selectedProductId)
  const queriesSnapshot = application.store.use.getMyQueries(selectedProductId)

  return (
    <Column fill>
      {queriesSnapshot.status === 'waiting' ? (
        <Row fill vAlign="center" hAlign="center">
          <div className={styles.loading}>
            <Spinner labelPosition="below" label="Loading..." />
          </div>
        </Row>
      ) : (
        <Column>
          <Spacer height="12px" />
          <Text className={styles.title}>My Queries</Text>
          <Spacer height="4px" />
          {queriesSnapshot.data?.length === 0 ? (
            <ErrorView.Custom level="WARNING" message="No queries" />
          ) : (
            queriesSnapshot.data?.map((query) => <QueryRowInPanel key={query.queryId} query={query} />)
          )}
        </Column>
      )}
    </Column>
  )
})

MyQueriesTab.displayName = 'MyQueriesTab'

const useStyles = makeStyles({
  button: {
    textAlign: 'center',
    padding: '0',
    color: tokens.colorBrandForegroundLink,
    margin: '16px 0',
    cursor: 'pointer',
  },
  loading: {
    width: '100%',
    textAlign: 'center',
  },
  title: {
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightBold,
  },
})
