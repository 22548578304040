import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicketInteraction } from '../actions-ticket/getTicketInteraction'
import { getTicketUserConsent } from '../actions-ticket/getTicketUserConsent'

export async function getRawConversationIndex(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): Promise<number> {
  // 1. Check user consent
  const hasUserConsent = await getTicketUserConsent(context, ticketId).promise
  if (!hasUserConsent) {
    throw TicketError.create('NoUserConsentGiven', { ticketId })
  }

  // 2. Get turn
  const interaction = await getTicketInteraction(context, ticketId, messageId).promise

  // 3. Check turn error code
  const statusCode = interaction.diagnostic?.conversation?.statusCode
  if (statusCode) {
    const error = TicketError.diagnostic(statusCode, ticketId)
    if (error) {
      throw error
    }
  }

  return interaction.index
}
