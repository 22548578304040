import { MessageBar, MessageBarBody, MessageBarTitle, makeStyles } from '@fluentui/react-components'

const TITLE = `Feedback grounding data is being suspended`
const SUBTITLE = `To prevent possible data leakage, we are applying a policy to redact grounding data for all CopilotDash users. The ETA for reopening will be shared later. We apologize for any inconvenience and thank you for your understanding.`

export function TreeItemGroundingDataSuspension() {
  const styles = useStyles()

  return (
    <MessageBar className={styles.root}>
      <MessageBarBody>
        <MessageBarTitle>{TITLE}</MessageBarTitle>
        <br />
        {SUBTITLE}
      </MessageBarBody>
    </MessageBar>
  )
}

const useStyles = makeStyles({
  root: {
    margin: '12px 0',
  },
})
