import { usePromise } from '@copilot-dash/core'
import { useMemo } from 'react'
import { AnyData } from '../../../components/AnyData/AnyData'
import { ErrorView } from '../../../components/Error'
import { Row } from '../../../components/Layout'
import { ITicketViewDefinition } from '../Store/TicketDebugState'
import { TicketDebugStore } from '../Store/TicketDebugStore'

interface IProps {
  readonly definition: ITicketViewDefinition
}

export function TicketDebugScreenDataView({ definition }: IProps) {
  const ticketId = TicketDebugStore.use((state) => state.ticketId)

  const promise = useMemo(() => {
    return definition.fetch(ticketId)
  }, [definition, ticketId])

  const promiseSnapshot = usePromise(promise)

  switch (promiseSnapshot.status) {
    case 'waiting':
      return (
        <Row vAlign="center" hAlign="center">
          Loading...
        </Row>
      )
    case 'error':
      return (
        <Row vAlign="center" hAlign="center">
          <ErrorView error={promiseSnapshot.error} />
        </Row>
      )
    case 'done':
      return <AnyData data={promiseSnapshot.data} />
  }
}
