import { AsyncSnapshot } from '@copilot-dash/core'
import { INewTicketData, IUpdateTicketStatusInfoData } from '@copilot-dash/domain'
import { ISearchTicketOptions } from '@copilot-dash/store'
import { produce } from 'immer'
import { cloneDeep, isNil } from 'lodash'
import { StateCreator } from 'zustand'
import {
  CategoryTypeIds,
  defaultLinkedTicketsArgsByRootCause,
  defaultArgsForAll,
  defaultArgsForTopIssues,
  defaultArgsForUnRootCause,
  defaultFilterForm,
  EMPTY_TEAM_ID,
  defaultRecommendTicketsArgsByRootCause,
} from './const'
import { createTicketsSearcher } from './createTicketsSearcher'
import { ITeamViewStore, ITeamViewTickets, NewSearchCopilotTicketsResult } from './types'

type Writable<T> = {
  -readonly [K in keyof T]: T[K]
}

export const createTicketsStore: StateCreator<ITeamViewStore, [['zustand/immer', never]], [], ITeamViewTickets> = (
  set,
  get,
) => {
  const _ticketIdSetByCategory = new Set<string>()
  const _linkedTicketIdSetByRootCause = new Set<string>()
  const _recommendTicketIdSetByRootCause = new Set<string>()
  const _unRootCauseTicketIdSetByRecommendationId = new Map<string, Set<string>>()
  const _topIssueTicketIdSetByBatchIdAndIssueId = new Map<string, Set<string>>()

  let _preArgsForAll = defaultArgsForAll
  let _preLinkedDSATsArgsForRootCause = defaultLinkedTicketsArgsByRootCause
  let _preRecommendDSATsArgsForRootCause = defaultRecommendTicketsArgsByRootCause
  let _preArgsForUnRootCause = defaultArgsForUnRootCause
  let _preArgsForTopIssues = defaultArgsForTopIssues

  const allDSATsSearcher = createTicketsSearcher()
  const unRootCauseSearcherMap = new Map<string, ReturnType<typeof createTicketsSearcher>>()
  const rootCauseSearcher = createTicketsSearcher()
  const recommendDSATsSearcher = createTicketsSearcher()
  const topIssueSearcherMap = new Map<string, ReturnType<typeof createTicketsSearcher>>()

  // const topIssueBatchInfoListAsyncLoader = new AsyncLoader<ITopIssuesBatchInfoList>()
  // const topIssueListAsyncLoader = new AsyncLoader<ITopIssueList>()

  async function _handleTicketsFromAll(snapshot: AsyncSnapshot<NewSearchCopilotTicketsResult>) {
    set((state) => {
      if (_preArgsForAll.offset === 0) {
        state.tickets.ticketListByAll = cloneDeep(snapshot)
        _ticketIdSetByCategory.clear()
      } else {
        state.tickets.ticketListByAll.data?.tickets.push(
          ...(snapshot.data?.tickets ?? []).filter(
            (item) => item.ticketId && !_ticketIdSetByCategory.has(item.ticketId),
          ),
        )
        ;(snapshot.data?.tickets ?? []).forEach((item) => {
          item.ticketId && _ticketIdSetByCategory.add(item.ticketId)
        })
      }
      if (snapshot.status === 'done') {
        state.tickets.hasMoreTicketsForAll = snapshot.data?.hasMore ?? false
      }
    })
  }

  async function _handleTicketsForRootCause(snapshot: AsyncSnapshot<NewSearchCopilotTicketsResult>) {
    set((state) => {
      if (_preLinkedDSATsArgsForRootCause.offset === 0) {
        state.tickets.ticketListByRootCause = cloneDeep(snapshot)
        _linkedTicketIdSetByRootCause.clear()
      } else {
        state.tickets.ticketListByRootCause.data?.tickets.push(
          ...(snapshot.data?.tickets ?? []).filter(
            (item) => item.ticketId && !_linkedTicketIdSetByRootCause.has(item.ticketId),
          ),
        )
        ;(snapshot.data?.tickets ?? []).forEach((item) => {
          item.ticketId && _linkedTicketIdSetByRootCause.add(item.ticketId)
        })
      }
      if (snapshot.status === 'done') {
        state.tickets.hasMoreLinkedTicketsForRootCause = snapshot.data?.hasMore ?? false
      }
    })
  }

  async function _handleRecommendTicketsForRootCause(snapshot: AsyncSnapshot<NewSearchCopilotTicketsResult>) {
    set((state) => {
      if (_preRecommendDSATsArgsForRootCause.offset === 0) {
        state.tickets.recommendTicketListByRootCause = cloneDeep(snapshot)
        _recommendTicketIdSetByRootCause.clear()
      } else {
        state.tickets.recommendTicketListByRootCause.data?.tickets.push(
          ...(snapshot.data?.tickets ?? []).filter(
            (item) => item.ticketId && !_recommendTicketIdSetByRootCause.has(item.ticketId),
          ),
        )
        ;(snapshot.data?.tickets ?? []).forEach((item) => {
          item.ticketId && _recommendTicketIdSetByRootCause.add(item.ticketId)
        })
      }
      if (snapshot.status === 'done') {
        state.tickets.hasMoreRecommendTicketsForRootCause = snapshot.data?.hasMore ?? false
      }
    })
  }

  function _handleTicketsForUnRootCause(
    snapshot: AsyncSnapshot<NewSearchCopilotTicketsResult>,
    request?: ISearchTicketOptions,
  ) {
    const recommendationId = request?.recommendedRootCauseId
    if (!recommendationId) return
    if (!_unRootCauseTicketIdSetByRecommendationId.get(recommendationId)) {
      _unRootCauseTicketIdSetByRecommendationId.set(recommendationId, new Set())
    }
    set((state) => {
      if (request.offset === 0 || !state.tickets.unRootCausedTickets[recommendationId]?.data) {
        state.tickets.unRootCausedTickets[recommendationId] = cloneDeep(snapshot)
        _unRootCauseTicketIdSetByRecommendationId.get(recommendationId)?.clear()
      } else if (state.tickets.unRootCausedTickets[recommendationId]?.data) {
        state.tickets.unRootCausedTickets[recommendationId]?.data?.tickets.push(
          ...(snapshot.data?.tickets ?? []).filter(
            (item) =>
              item.ticketId && !_unRootCauseTicketIdSetByRecommendationId.get(recommendationId)?.has(item.ticketId),
          ),
        )
        ;(snapshot.data?.tickets ?? []).forEach((item) => {
          item.ticketId && _unRootCauseTicketIdSetByRecommendationId.get(recommendationId)?.add(item.ticketId)
        })
      }

      if (snapshot.status === 'done') {
        state.orderBy = undefined
        if (
          state.tickets.unRootCausedTickets[recommendationId] &&
          state.tickets.unRootCausedTickets[recommendationId]!.data
        ) {
          state.tickets.unRootCausedTickets[recommendationId]!.data!.hasMore = snapshot.data?.hasMore ?? false
        }
      }
    })
  }

  function _handleTicketsForTopIssue(
    snapshot: AsyncSnapshot<NewSearchCopilotTicketsResult>,
    request?: ISearchTicketOptions,
  ) {
    const batchId = request?.batchId
    const issueId = request?.clusteringIssueId
    if (!batchId || !issueId) return
    const id = `${batchId}-${issueId}`
    if (!_topIssueTicketIdSetByBatchIdAndIssueId.get(id)) {
      _topIssueTicketIdSetByBatchIdAndIssueId.set(id, new Set())
    }
    set((state) => {
      if (request.offset === 0 || !state.tickets.topIssueTickets[id]?.data) {
        state.tickets.topIssueTickets[id] = cloneDeep(snapshot)
        _topIssueTicketIdSetByBatchIdAndIssueId.get(id)?.clear()
      } else if (state.tickets.topIssueTickets[id]?.data) {
        state.tickets.topIssueTickets[id]?.data?.tickets.push(
          ...(snapshot.data?.tickets ?? []).filter(
            (item) => item.ticketId && !_topIssueTicketIdSetByBatchIdAndIssueId.get(id)?.has(item.ticketId),
          ),
        )
      }
      ;(snapshot.data?.tickets ?? []).forEach((item) => {
        item.ticketId && _topIssueTicketIdSetByBatchIdAndIssueId.get(id)?.add(item.ticketId)
      })

      if (snapshot.status === 'done') {
        if (state.tickets.topIssueTickets[id] && state.tickets.topIssueTickets[id]!.data) {
          state.tickets.topIssueTickets[id]!.data!.hasMore = snapshot.data?.hasMore ?? false
        }
      }
    })
  }

  return {
    ticketsTabType: 'category',
    ticketListByAll: { status: 'none' },
    ticketListByRootCause: { status: 'none' },
    recommendTicketListByRootCause: { status: 'none' },
    unRootCausedTickets: {},
    hasMoreTicketsForAll: true,
    hasMoreLinkedTicketsForRootCause: true,
    hasMoreRecommendTicketsForRootCause: true,
    ticketUpdatedByAcceptSuggestionCounter: 1,
    filterForm: defaultFilterForm,
    topIssueTickets: {},

    computed: {
      get unRootCauseTicketsCount() {
        if (!get().tickets.unRootCausedTickets) return null
        if (Object.values(get().tickets.unRootCausedTickets).some((data) => data.status === 'waiting')) return null
        return Object.keys(get().tickets.unRootCausedTickets).reduce((acc, key) => {
          return acc + (get().tickets.unRootCausedTickets[key]?.data?.ticketCount ?? 0)
        }, 0)
      },
    },

    setTicketsTabType: (type: 'category' | 'rootCause') => {
      set((state) => {
        state.tickets.ticketsTabType = type
      })
    },
    fetchTicketListForAll: (filter = {}) => {
      const teamId = get().teams.computed.selectedTeamId
      if (isNil(teamId) || teamId === EMPTY_TEAM_ID) return Promise.resolve(undefined)

      set((state) => {
        state.tickets.ticketListByAll = { status: 'none' }
      })

      _preArgsForAll = produce(defaultArgsForAll, (draft) => {
        draft.teamId = teamId
        draft.ticketStatus = CategoryTypeIds.All
        draft.offset = 0
        draft = Object.assign(draft, filter)
        draft.ticketStatus = CategoryTypeIds.All
      })
      return allDSATsSearcher(_preArgsForAll, _handleTicketsFromAll)
    },

    fetchTicketListByRootCause(issueId, vsoAccount, filter = {}) {
      const teamId = get().teams.computed.selectedTeamId
      if (isNil(teamId) || teamId === EMPTY_TEAM_ID) return Promise.resolve(undefined)

      set((state) => {
        state.tickets.ticketListByRootCause = { status: 'none' }
      })

      _preLinkedDSATsArgsForRootCause = produce(defaultLinkedTicketsArgsByRootCause, (draft) => {
        draft.teamId = teamId
        draft.issueId = issueId
        draft.vsoAccount = vsoAccount
        draft.offset = 0
        draft = Object.assign(draft, filter)
      })

      return rootCauseSearcher(_preLinkedDSATsArgsForRootCause, _handleTicketsForRootCause)
    },

    loadMoreTicketListForAll(offset) {
      _preArgsForAll = produce(_preArgsForAll, (draft) => {
        draft.offset = offset
      })
      return allDSATsSearcher(_preArgsForAll, _handleTicketsFromAll)
    },

    loadMoreTicketListByRootCause(offset) {
      _preLinkedDSATsArgsForRootCause = produce(_preLinkedDSATsArgsForRootCause, (draft) => {
        draft.offset = offset
      })
      return rootCauseSearcher(_preLinkedDSATsArgsForRootCause, _handleTicketsForRootCause)
    },

    filterTicketListInAll(filter) {
      const teamName = get().teams.lastSelectedTeam
      if (!teamName) return Promise.resolve(undefined)

      set((state) => {
        state.tickets.ticketListByAll = { status: 'none' }
      })

      _preArgsForAll = produce(_preArgsForAll, (draft) => {
        draft.teamName = teamName
        draft = Object.assign(draft, filter)
        draft.offset = 0
        draft.ticketStatus = CategoryTypeIds.All
      })
      set((state) => {
        state.tickets.filterForm = Object.assign(state.tickets.filterForm, filter)
      })
      return allDSATsSearcher(_preArgsForAll, _handleTicketsFromAll)
    },

    filterTicketListInRootCause(filter) {
      const teamName = get().teams.lastSelectedTeam
      if (!teamName) return Promise.resolve(undefined)

      set((state) => {
        state.tickets.ticketListByRootCause = { status: 'none' }
      })

      _preLinkedDSATsArgsForRootCause = produce(_preLinkedDSATsArgsForRootCause, (draft) => {
        draft.teamName = teamName
        draft = Object.assign(draft, filter)
        draft.offset = 0
      })
      set((state) => {
        state.tickets.filterForm = Object.assign(state.tickets.filterForm, filter)
      })
      return rootCauseSearcher(_preLinkedDSATsArgsForRootCause, _handleTicketsForRootCause)
    },

    resetFilterForm() {
      set((state) => {
        state.tickets.filterForm = defaultFilterForm
      })
    },
    setFilterForm(filter) {
      set((state) => {
        state.tickets.filterForm = { ...defaultFilterForm, ...filter }
      })
    },

    async checkAndUpdateTicketByTicketId(ticketId: string, info: IUpdateTicketStatusInfoData) {
      set((state) => {
        const tickets: Array<Writable<INewTicketData> | undefined> = []

        // all feedback ticket
        tickets.push(state.tickets.ticketListByAll.data?.tickets.find((ticket) => ticket.ticketId === ticketId))

        // root cause feedback ticket
        tickets.push(state.tickets.ticketListByRootCause.data?.tickets.find((ticket) => ticket.ticketId === ticketId))

        // unroot cause feedback ticket
        for (const ticketList of Object.values(state.tickets.unRootCausedTickets)) {
          tickets.push(ticketList.data?.tickets.find((ticket) => ticket.ticketId === ticketId))
        }

        // issue feedback ticket
        for (const ticketList of Object.values(state.tickets.topIssueTickets)) {
          tickets.push(ticketList.data?.tickets.find((ticket) => ticket.ticketId === ticketId))
        }

        // update them
        for (const ticket of tickets) {
          if (ticket) {
            ticket.teamArea = info.areaPath
            ticket.status = info.status
            ticket.priority = info.priority
            ticket.rootCauseList = info.issueList.map((item) => ({
              issueId: item.issueId,
              rootCauseTitle: item.title,
            }))
            ticket.reasoning = info.reasoning
            ticket.closedComment = info.closedComment
            ticket.assignTo = info.assignTo
            ticket.customTags = info.customTags
          }
        }
      })
    },

    async filterUnRootCausedTickets(recommendationId, filter) {
      const teamId = get().teams.computed.selectedTeamId
      if (isNil(teamId) || teamId === EMPTY_TEAM_ID) return Promise.resolve(undefined)
      _preArgsForUnRootCause = produce(defaultArgsForUnRootCause, (draft) => {
        draft.teamId = teamId
        draft.offset = 0
        draft = Object.assign(draft, filter)
        draft.count = 10
        draft.ticketStatus = CategoryTypeIds.UnRootCaused
      })

      set((state) => {
        state.tickets.unRootCausedTickets[recommendationId] = { status: 'none' }
      })

      const searcher = unRootCauseSearcherMap.get(recommendationId) || createTicketsSearcher()
      unRootCauseSearcherMap.set(recommendationId, searcher)

      searcher({ ..._preArgsForUnRootCause, recommendedRootCauseId: recommendationId }, _handleTicketsForUnRootCause)
    },

    async loadMoreUnRootCausedTickets(recommendationId, offset) {
      const searcher = unRootCauseSearcherMap.get(recommendationId) || createTicketsSearcher()
      unRootCauseSearcherMap.set(recommendationId, searcher)
      await searcher(
        { ..._preArgsForUnRootCause, recommendedRootCauseId: recommendationId, offset },
        _handleTicketsForUnRootCause,
      )
      return
    },

    clearUnRootCausedTickets(recommendationId) {
      set((state) => {
        state.tickets.unRootCausedTickets[recommendationId] = { status: 'none' }
      })
    },

    async fetchTopIssueTickets(issueId, teamId, batchId, filter) {
      _preArgsForTopIssues = produce(defaultArgsForTopIssues, (draft) => {
        draft.teamId = teamId
        draft.clusteringIssueId = issueId
        draft.batchId = batchId
        draft = Object.assign(draft, filter)
        draft.offset = 0
        draft.count = 20
        draft.ticketStatus = CategoryTypeIds.TopIssue
      })
      const id = `${batchId}-${issueId}`
      set((state) => {
        state.tickets.topIssueTickets[id] = { status: 'none' }
      })

      const searcher = topIssueSearcherMap.get(id) || createTicketsSearcher()
      topIssueSearcherMap.set(id, searcher)
      searcher({ ..._preArgsForTopIssues, clusteringIssueId: issueId, batchId }, _handleTicketsForTopIssue)
    },
    async loadMoreTopIssueTickets(issueId: string, teamId: number, batchId: string, offset: number) {
      const id = `${batchId}-${issueId}`
      const searcher = topIssueSearcherMap.get(id) || createTicketsSearcher()
      topIssueSearcherMap.set(id, searcher)
      await searcher(
        { ..._preArgsForTopIssues, clusteringIssueId: issueId, batchId, offset },
        _handleTicketsForTopIssue,
      )
      return
    },
    clearTopIssueTickets(key) {
      set((state) => {
        state.tickets.topIssueTickets[key] = { status: 'none' }
      })
    },

    fetchRecommendTicketListByRootCause(issueId, vsoAccount, filter = {}) {
      const teamId = get().teams.computed.selectedTeamId
      if (isNil(teamId) || teamId === EMPTY_TEAM_ID) return Promise.resolve(undefined)

      set((state) => {
        state.tickets.recommendTicketListByRootCause = { status: 'none' }
      })

      _preRecommendDSATsArgsForRootCause = produce(defaultRecommendTicketsArgsByRootCause, (draft) => {
        draft = Object.assign(draft, filter)
        draft.count = 20
        draft.ticketStatus = CategoryTypeIds.UnRootCaused
        draft.teamId = teamId
        draft.recommendedRootCauseId = issueId
        draft.offset = 0
      })

      return recommendDSATsSearcher(_preRecommendDSATsArgsForRootCause, _handleRecommendTicketsForRootCause)
    },
    loadMoreRecommendTicketListByRootCause(offset, count = 20) {
      _preRecommendDSATsArgsForRootCause = produce(_preRecommendDSATsArgsForRootCause, (draft) => {
        draft.offset = offset
        draft.count = count
      })
      return recommendDSATsSearcher(_preRecommendDSATsArgsForRootCause, _handleRecommendTicketsForRootCause)
    },
  }
}
