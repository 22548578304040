import { ApiGetTicketIdByWorkItemIdRequest, ApiGetTicketIdByWorkItemIdResponse } from '@copilot-dash/api'
import { IDashStoreContext } from '../IDashStoreContext'

export async function getTicketIdByWorkItemId(
  context: IDashStoreContext,
  params: ApiGetTicketIdByWorkItemIdRequest,
): Promise<ApiGetTicketIdByWorkItemIdResponse> {
  const response = await context.api.logCollector.getTicketIdByWorkItemId(params)
  return response
}
