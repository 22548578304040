import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  selectButton: {
    textAlign: 'left',
    justifyContent: 'left',
    fontWeight: tokens.fontWeightRegular,
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: tokens.colorBrandBackgroundInvertedHover,
    },
  },
})
