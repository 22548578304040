import { z } from 'zod'

export interface ApiBatchGetTopIssueExtendInfoRequest {
  readonly BatchId: string
  readonly IssueIdList: string[]
}

export interface ApiTopIssueExtendInfo {
  readonly ticketStatusCount: {
    closed: number
    rootCaused: number
    teamAssigned: number
  }
  readonly vIPPowerUserIdList: string[]
  readonly cAPTenantNameList: string[]
  readonly issueTitle: string
  readonly issueId: string
  readonly priority: number
  readonly coverage: number
  readonly trends: number
  readonly isNew: boolean
  readonly ticketCount: number
  readonly impactedTenantsCount: number
}

export interface ApiBatchGetTopIssueExtendInfoResponse {
  readonly feedbackInsightsList: Array<ApiTopIssueExtendInfo>
}

export const ApiBatchGetTopIssueExtendInfoResponseSchema = z.object({
  feedbackInsightsList: z.array(
    z.object({
      ticketStatusCount: z.object({
        closed: z.number(),
        rootCaused: z.number(),
        teamAssigned: z.number(),
      }),
      vIPPowerUserIdList: z.array(z.string()),
      cAPTenantNameList: z.array(z.string()),
      issueTitle: z.string(),
      issueId: z.string(),
      priority: z.number(),
      coverage: z.number(),
      trends: z.number(),
      isNew: z.boolean(),
      ticketCount: z.number(),
      impactedTenantsCount: z.number(),
    }),
  ),
})
