import { ApiOdsInteractionInfos } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'
import { convertToRawTicketInteractions } from './converters/convertToRawTicketInteractions'
import { getRawOdsTicket } from './getRawOdsTicket'

export function getRawOdsTicketInteractions(
  context: IDashStoreContext,
  ticketId: string,
): PromiseSnapshot<ApiOdsInteractionInfos[]> {
  return context.getOrFetch<ApiOdsInteractionInfos[]>({
    get: (state) => {
      return state.tickets[ticketId]?.rawOdsInteractions
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.rawOdsInteractions = snapshot
    },
    fetch: async () => {
      const raw = await getRawOdsTicket(context, ticketId).promise
      return convertToRawTicketInteractions(raw)
    },
  })
}
