import { useTicketKustoBizChatPerfLog } from '@copilot-dash/store'
import { TableCell, TableRow, Text } from '@fluentui/react-components'
import { useMemo } from 'react'
import { SymptomReportHealthLabel } from './SymptomReportHealthLabel'

const LATENCY_THRESHOLD = 21000

interface IProps {
  readonly ticketId: string
  readonly turnId: string
}

export function RowForSynthesisChatFCFR({ ticketId, turnId: ticketConversationTurnId }: IProps) {
  const ticket = application.store.use.getTicket(ticketId)
  const perfLogs = useTicketKustoBizChatPerfLog({ ticketId, messageId: ticketConversationTurnId })

  const latency: number | undefined = useMemo(() => {
    if (ticket.data?.e2eLatency) {
      return ticket.data.e2eLatency
    }

    const chatFCFR = perfLogs.data?.[0]?.chatFCFR
    const chatFCFRNumber = Number(chatFCFR)
    if (chatFCFRNumber && chatFCFRNumber > 0) {
      return chatFCFRNumber
    }

    return undefined
  }, [ticket.data, perfLogs.data])

  const renderChild = () => {
    if (!latency) {
      return <span>ChatFCFR: NULL</span>
    }

    return (
      <span>
        <span>ChatFCFR: {latency} </span>
        <SymptomReportHealthLabel status={latency <= LATENCY_THRESHOLD ? 'ok' : 'warning'} />
      </span>
    )
  }

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Synthesis</Text>
      </TableCell>
      <TableCell>
        <Text>{renderChild()}</Text>
      </TableCell>
    </TableRow>
  )
}
