import { AbsoluteTimeRange, ITimezoneData, RelativeTimeRange, TimeRange, TimeType } from './TimeTypes'
import { dayjs } from './dayjs'

export class Times {
  static isValid(time: TimeType): boolean {
    if (!time) {
      return false
    }

    return dayjs.utc(time).isValid()
  }

  static isSame(time: TimeType[], that: TimeType[]): boolean
  static isSame(time: TimeType, that: TimeType): boolean
  static isSame(time: TimeType | TimeType[], that: TimeType | TimeType[]): boolean {
    if (!time || !that) {
      return false
    }

    if (Array.isArray(time) || Array.isArray(that)) {
      if (!Array.isArray(time) || !Array.isArray(that)) {
        return false
      }

      if (time.length !== that.length) {
        return false
      }

      for (let i = 0; i < time.length; i++) {
        if (!Times.isSame(time[i], that[i])) {
          return false
        }
      }

      return true
    }

    const timeObject = dayjs.utc(time)
    const thatObject = dayjs.utc(that)
    if (!timeObject.isValid() || !thatObject.isValid()) {
      return false
    }

    return timeObject.isSame(thatObject)
  }

  static isBefore(time: TimeType, that: TimeType): boolean {
    if (!time || !that) {
      return false
    }

    const timestampObject = dayjs.utc(time)
    if (!timestampObject.isValid()) {
      return false
    }

    const thatObject = dayjs.utc(that)
    if (!thatObject.isValid()) {
      return false
    }

    return timestampObject.isBefore(thatObject)
  }

  static isBeforeNDays(time: TimeType, day: number): boolean {
    if (!time) {
      return false
    }

    const timeObject = dayjs.utc(time)
    if (!timeObject.isValid()) {
      return false
    }

    return timeObject.isBefore(dayjs().subtract(day, 'day'))
  }

  static format(time: TimeType, options: { timezone: string; format?: string }): string | undefined {
    if (!time) {
      return undefined
    }

    const timeObject = dayjs.utc(time)
    if (!timeObject.isValid()) {
      return undefined
    }

    const timeObjectWithTimezone = timeObject.tz(options?.timezone)
    if (!timeObjectWithTimezone.isValid()) {
      return undefined
    }

    return timeObjectWithTimezone.format(options?.format)
  }

  static getTimezoneData(timezone: string): ITimezoneData {
    function getTimezoneOffsetLabel(timezone: string): string {
      const offset = dayjs().tz(timezone).utcOffset()

      const unit = offset >= 0 ? '+' : '-'

      const hours = Math.floor(Math.abs(offset) / 60)
        .toString()
        .padStart(2, '0')

      const minutes = (Math.abs(offset) % 60).toString().padStart(2, '0')

      return `${unit}${hours}:${minutes}`
    }

    return {
      value: timezone,
      valueLabel: timezone,
      offset: dayjs().tz(timezone).utcOffset(),
      offsetLabel: getTimezoneOffsetLabel(timezone),
    }
  }

  static getPastDaysTimeRange(
    days: number,
    options: { timezone: string; format?: string },
  ): [start: string, end: string] {
    return this.getPastTimeRange(days, {
      timezone: options.timezone,
      unit: 'days',
      format: options.format,
    })
  }

  static getPastTimeRange(
    days: number,
    options: { timezone: string; unit: 'hours' | 'days' | 'weeks' | 'months'; format?: string },
  ): [start: string, end: string] {
    const { timezone, format } = options

    // There are 2 bugs about dayjs subtract and timezone
    // 1. `subtract` must be called before `tz`
    // 2. UTC timezone have to use `utc` function
    if (timezone.toUpperCase() === 'UTC') {
      return [
        dayjs.utc().subtract(days, options.unit).startOf(options.unit).format(format), //
        dayjs.utc().endOf(options.unit).format(format),
      ]
    } else {
      return [
        dayjs().subtract(days, options.unit).tz(timezone).startOf(options.unit).format(format), //
        dayjs().tz(timezone).endOf(options.unit).format(format),
      ]
    }
  }

  static startOfDay(time: TimeType, timezone: string): string {
    if (timezone.toUpperCase() === 'UTC') {
      return dayjs.utc(time).startOf('day').format()
    } else {
      return dayjs(time).tz(timezone).startOf('day').format()
    }
  }

  static endOfDay(time: TimeType, timezone: string): string {
    if (timezone.toUpperCase() === 'UTC') {
      return dayjs.utc(time).endOf('day').format()
    } else {
      return dayjs(time).tz(timezone).endOf('day').format()
    }
  }

  static isWithinLastXHours(time: TimeType, hours: number): boolean {
    if (!time) {
      return false
    }

    const timeObject = dayjs.utc(time)
    if (!timeObject.isValid()) {
      return false
    }

    return timeObject.isAfter(dayjs().subtract(hours, 'hour')) && timeObject.isBefore(dayjs())
  }

  /**
   * This function calculates the remaining time of a task based on the start time and the hours needed for the task.
   *
   * If the start time is invalid or this task is already ended, it returns undefined.
   */
  static remainingRelativeTime(startTime: TimeType, hours: number): string | undefined {
    if (!startTime) {
      return undefined
    }

    const timeObject = dayjs.utc(startTime)
    if (!timeObject.isValid()) {
      return undefined
    }

    const endTime = timeObject.add(hours, 'hour')
    if (endTime.isBefore(dayjs())) {
      return undefined
    }

    return endTime.fromNow()
  }

  static min(times: TimeType[]): string | undefined {
    const validateTimes = times.map((item) => dayjs.utc(item)).filter((item) => item.isValid())
    if (validateTimes.length === 0) {
      return ''
    }

    return dayjs.min(validateTimes)?.toISOString()
  }

  static max(times: TimeType[]): string | undefined {
    const validateTimes = times.map((item) => dayjs.utc(item)).filter((item) => item.isValid())
    if (validateTimes.length === 0) {
      return ''
    }

    return dayjs.max(validateTimes)?.toISOString()
  }

  static formatTimeRange(
    range: TimeRange | undefined,
    options: { timezone: string; format?: string },
  ): { from?: string; to?: string } {
    if (!range) {
      return {}
    }

    switch (range.type) {
      case 'relative':
        return Times.formatRelativeTimeRange(range, options)
      case 'absolute':
        return Times.formatAbsoluteTimeRange(range, options)
    }
  }

  private static formatRelativeTimeRange(
    range: RelativeTimeRange,
    options: { timezone: string; format?: string },
  ): { from?: string; to?: string } {
    const [from, to] = Times.getPastTimeRange(range.value, {
      unit: range.unit,
      timezone: options.timezone,
      format: options.format,
    })

    return { from, to }
  }

  private static formatAbsoluteTimeRange(
    range: AbsoluteTimeRange,
    options: { timezone: string; format?: string },
  ): { from?: string; to?: string } {
    return {
      from: Times.format(range.from, options),
      to: Times.format(range.to, options),
    }
  }

  static toStringOfRelativeTimeRange(range: RelativeTimeRange): string {
    switch (range.unit) {
      case 'hours':
        return range.value === 1 ? 'Last 1 hour' : `Last ${range.value} hours`
      case 'days':
        return range.value === 1 ? 'Last 1 day' : `Last ${range.value} days`
      case 'weeks':
        return range.value === 1 ? 'Last 1 week' : `Last ${range.value} weeks`
      case 'months':
        return range.value === 1 ? 'Last 1 month' : `Last ${range.value} months`
    }
  }
}
