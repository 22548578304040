import { FC } from 'react'
import { Text, shorthands, makeStyles, tokens } from '@fluentui/react-components'
import { Row, Column } from '../../../Layout'
import { useActivityHistoryPanelStore } from '../../store/store'
import { isRootCauseContextArray } from '../config'
import { TicketIssueData, ActivityHistoryRootCauseContext } from '@copilot-dash/domain'

export const ActivityConfirmRootCause: FC = () => {
  const styles = useStyles()

  const selectedActivity = useActivityHistoryPanelStore((state) => state.selectedActivity)

  const renderValue = (
    value: string | number | TicketIssueData[] | ActivityHistoryRootCauseContext[] | string[] | undefined,
  ) => {
    if (Array.isArray(value) && value.length > 0 && isRootCauseContextArray(value)) {
      return value.map((action, idx) => (
        <Text key={idx} title={action.Title}>
          {action.Title}
        </Text>
      ))
    }

    return null
  }

  return (
    <Column>
      <Row>
        {renderValue(selectedActivity?.fieldDiffs?.['rootCauseActiveContext']?.newValue) && (
          <Text className={styles.tag}>
            {renderValue(selectedActivity?.fieldDiffs?.['rootCauseActiveContext']?.newValue)}
          </Text>
        )}
      </Row>
    </Column>
  )
}

const useStyles = makeStyles({
  tag: {
    backgroundColor: tokens.colorBrandBackground2,
    ...shorthands.padding('4px', '4px'),
    ...shorthands.margin('4px', '4px'),
    borderRadius: '4px',
  },
})
