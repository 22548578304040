import { IM365TenantItem } from '@copilot-dash/domain'
import { IDashStoreContext } from '../IDashStoreContext'

const DEFAULT_LIMIT = 20

interface IOptions {
  readonly limit?: number
}

export async function searchM365Tenants(
  context: IDashStoreContext,
  keyword: string | undefined,
  options?: IOptions,
): Promise<IM365TenantItem[]> {
  const response = await context.api.logCollector.getM365TenantList({
    Keyword: keyword,
    Limit: options?.limit ?? DEFAULT_LIMIT,
  })

  return response.m365TenantList
}
