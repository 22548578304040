import { z } from 'zod'

export interface ApiTeamListResponse {
  readonly teamList: Array<{
    readonly teamName: string
    readonly teamId: number
  }>
  readonly errorMessage: string
}

export const apiTeamListResponseSchema = z.object({
  teamList: z.array(
    z.object({
      teamName: z.string(),
      teamId: z.number(),
    }),
  ),
  errorMessage: z.string(),
})
