import { makeStyles, Tab } from '@fluentui/react-components'
import { AnyDataTab } from '../../store/IState'

interface IProps {
  readonly tab: AnyDataTab
}

export function AnyDataBarTab({ tab }: IProps) {
  const styles = useStyles()

  let name: string | object = {}
  let title: string | -1 = -1
  switch (tab) {
    case AnyDataTab.Table:
      name = 'Table'
      title = 'Display result set as a table'
      break
    case AnyDataTab.Json:
      name = 'JSON'
      title = 'Display result set as JSON'
      break
  }

  return (
    <Tab className={styles.root} value={tab} title={title}>
      {name}
    </Tab>
  )
}

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
  },
})
