import { ITelemetryMetric } from '@copilot-dash/domain'
import { AccordionPanel, makeStyles, tokens } from '@fluentui/react-components'
import { Column } from '../../../../../../components/Layout'
import { MetricCardBodyRaw } from './MetricCardBodyRaw'
import { MetricCardBodySummary } from './MetricCardBodySummary'
import { MetricCardJson } from './MetricCardJson'

interface IProps {
  readonly metric: ITelemetryMetric
}

export function MetricCardBody({ metric }: IProps) {
  const styles = useStyles()

  return (
    <AccordionPanel className={styles.container}>
      <Column className={styles.root} gap="gap.medium">
        <MetricCardBodySummary metric={metric} />
        {!!metric.input && <MetricCardJson title="Input" data={metric.input} />}
        {!!metric.output && <MetricCardJson title="Output" data={metric.output} />}
        <MetricCardBodyRaw metric={metric} />
      </Column>
    </AccordionPanel>
  )
}

const useStyles = makeStyles({
  container: {
    margin: '0px',
    borderTop: `1px solid ${tokens.colorNeutralStroke2}`,
  },
  root: {
    padding: '16px',
  },
})
