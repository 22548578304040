import { useProductIdEndpoints } from '@copilot-dash/store'
import { useMemo } from 'react'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { IFilterOption } from '../../../Filter/IFilterOption.types'

interface IProps {
  productId: number | undefined
  selectedValues: string[] | undefined
  onChangeValue: (endpoints: string[]) => void
}

export const EndpointFilter: React.FC<IProps> = ({ productId, selectedValues, onChangeValue }) => {
  const productEndpoints = useProductIdEndpoints({ productId })
  const endpointList: IFilterOption[] = useMemo(() => {
    return (
      productEndpoints.data?.map((endpoint) => ({
        key: endpoint.name,
        text: endpoint.title,
        menu: endpoint.menu,
      })) ?? []
    )
  }, [productEndpoints.data])

  return (
    <CopilotDashMultiSelectDropdown
      comboId={'combo-clientFilter'}
      filterType={'Endpoints'}
      isGroup={true}
      optionsList={endpointList}
      defaultSelectedOption={selectedValues}
      onChangeFilter={onChangeValue}
      showInput={true}
    />
  )
}
