import { TimeRange } from '@copilot-dash/core'
import { InfoLabel } from '@fluentui/react-components'
import 'react-multi-date-picker/styles/colors/yellow.css'
import { DateRangePicker } from '../../../DatePicker/DateRangePicker'
import { DateTimeRangePicker } from '../../../DateTimeRangePicker/DateTimeRangePicker'
import { Column } from '../../../Layout'
import { useStyles } from './TimeFilter.styles'

interface IProps {
  range: TimeRange | undefined
  onChanged: (range: TimeRange) => void
  withTime?: boolean
  minDate?: string
  maxDate?: string
}

export function TimeFilter({ range, onChanged, withTime = false, minDate, maxDate }: IProps) {
  const styles = useStyles()

  return (
    <Column className={styles.root}>
      <InfoLabel weight="semibold">Date:</InfoLabel>
      {withTime ? (
        <DateTimeRangePicker range={range} onChanged={onChanged} minDate={minDate} maxDate={maxDate} />
      ) : (
        <DateRangePicker range={range} onChanged={onChanged} minDate={minDate} maxDate={maxDate} />
      )}
    </Column>
  )
}
