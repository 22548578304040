export * from './src/interfaces/IDashLogger'
export * from './src/interfaces/IDashLoggerOptions'
export * from './src/telemetry'
export * from './src/utils'

import { DashLoggerStatic } from './src/DashLoggerStatic'
import { IDashLogger } from './src/interfaces/IDashLogger'
import { IDashLoggerOptions } from './src/interfaces/IDashLoggerOptions'

export const Logger: IDashLogger & { init(context: IDashLoggerOptions): void } = new DashLoggerStatic()
