import { isPlainObject } from 'lodash'

export function serializeParamsToString(params: Record<string, unknown>): string {
  const searchParams = new URLSearchParams()
  for (const key in params) {
    const value = params[key]
    // 1. Empty
    if (value === undefined || value === null) {
      continue
    }

    // 2. Array
    if (Array.isArray(value)) {
      value.forEach((item) => searchParams.append(key, item))
      continue
    }

    // 3. Object
    if (isPlainObject(value)) {
      searchParams.append(key, JSON.stringify(value))
      continue
    }

    // 4. Others
    searchParams.append(key, value.toString())
  }

  return searchParams.toString()
}
