import { ITeamRootCauseItem } from '@copilot-dash/domain'
import { memo } from 'react'
import { makeStyles, shorthands, tokens, Text } from '@fluentui/react-components'
import { UserProfile } from '../../../../../components/User/UserProfile'

export const AssignTo = memo((props: { rowData: ITeamRootCauseItem }) => {
  const styles = useStyles()
  const email = props.rowData.owner
  if (!email) {
    return <Text>Not assigned</Text>
  }
  return (
    <UserProfile
      userId={email}
      fallback={email}
      customUserPhotoClassName={styles.customUserPhoto}
      customUserDisplayNameClassName={styles.customUserName}
    />
  )
})

const useStyles = makeStyles({
  customUserPhoto: {
    width: '24px',
    height: '24px',
    fontSize: tokens.fontSizeBase200,
    ...shorthands.borderRadius('50%'),
  },
  customUserName: {
    fontSize: tokens.fontSizeBase300,
  },
})

AssignTo.displayName = 'AssignTo'
