import { makeStyles } from '@fluentui/react-components'
import { ReactNode } from 'react'
import { Column } from '../../../../components/Layout'

interface IProps {
  readonly children?: ReactNode
}

export function SettingsScreenSection(props: IProps) {
  const styles = useStyles()

  return (
    <Column className={styles.root} hAlign="stretch">
      {props.children}
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    width: '500px',
    minWidth: '300px',
    maxWidth: '800px',
    padding: '8px 16px',
  },
})
