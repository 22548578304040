import {
  makeStyles,
  Tooltip,
  tokens,
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
} from '@fluentui/react-components'
import { CheckmarkRegular, CopySelectRegular, DismissCircleRegular } from '@fluentui/react-icons'
import { useState, Fragment } from 'react'
const TIME_OUT_THRESHOLD = 2000

interface IProps {
  readonly ticketId: string
}

export function TicketLinkCopyButton({ ticketId }: IProps) {
  const styles = useStyles()
  const toasterId = useId('toaster')
  const { dispatchToast } = useToastController(toasterId)
  const [status, setStatus] = useState<'waiting' | 'success' | 'error'>('waiting')

  const notify = (intent: 'success' | 'error') => {
    switch (intent) {
      case 'error':
        dispatchToast(
          <Toast>
            <ToastTitle>Failed to copy...</ToastTitle>
          </Toast>,
          { timeout: TIME_OUT_THRESHOLD, toastId: toasterId, intent: intent },
        )

        break
      case 'success':
        dispatchToast(
          <Toast>
            <ToastTitle>Copied Link to clipboard</ToastTitle>
          </Toast>,
          { timeout: TIME_OUT_THRESHOLD, toastId: toasterId, intent: intent },
        )
        break
    }
  }

  const onClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation()
    let intent: 'success' | 'error' = 'success'
    const link = `${window.location.protocol}//${window.location.host}/ticket/${ticketId}`
    //const title = `Ticket - ${ticketId}`
    //const markdownLink = `[${title}](${link})`

    navigator.clipboard
      .writeText(link)
      .then(() => {
        setStatus('success')
        intent = 'success'
      })
      .catch((error) => {
        setStatus('error')
        intent = 'error'
      })
      .finally(() => {
        notify(intent)
        setTimeout(() => setStatus('waiting'), TIME_OUT_THRESHOLD)
      })
  }

  return (
    <Fragment>
      <Tooltip
        content={
          status === 'success'
            ? 'Copied Link to clipboard'
            : status === 'error'
              ? 'Failed to copy...'
              : 'Copy Link to clipboard'
        }
        relationship={'label'}
      >
        <span className={styles.root} onClick={onClick}>
          {status === 'success' ? (
            <CheckmarkRegular className={styles.successIcon} />
          ) : status === 'error' ? (
            <DismissCircleRegular className={styles.errorIcon} />
          ) : (
            <CopySelectRegular />
          )}
        </span>
      </Tooltip>
      <Toaster toasterId={toasterId} />
    </Fragment>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '0 2px',
    cursor: 'pointer',
  },
  errorIcon: {
    color: tokens.colorStatusDangerBackground3,
  },
  successIcon: {
    color: tokens.colorStatusSuccessBackground3,
  },
})
