import { makeStyles } from '@fluentui/react-components'
import { IErrorComponents } from '../../ErrorConfigs/interfaces/IErrorComponents'
import { Column } from '../../Layout'
import { useScrollbar } from '../../Scrollbar/useScrollbar'
import { ErrorViewPopoverBodyActions } from './ErrorViewPopoverBodyActions'
import { ErrorViewPopoverBodyMessage } from './ErrorViewPopoverBodyMessage'
import { ErrorViewPopoverBodyContext } from './ErrorViewPopoverBodyContext'

interface IProps {
  readonly components: IErrorComponents
  readonly onClose?: () => void
}

export function ErrorViewPopoverBody({ components, onClose }: IProps) {
  const styles = useStyles()
  const scrollbar = useScrollbar()

  return (
    <div className={styles.root} ref={scrollbar}>
      <Column>
        <ErrorViewPopoverBodyMessage components={components} />
        <ErrorViewPopoverBodyContext components={components} />
        <ErrorViewPopoverBodyActions components={components} onClose={onClose} />
      </Column>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    overflow: 'auto',
    maxHeight: '200px',
    padding: '16px 24px',
    wordBreak: 'break-word',
  },
})
