import { TableCell, TableRow, Text } from '@fluentui/react-components'
import { Row, Spacer } from '../../Layout'
import { SymptomReportHealthLabel } from './SymptomReportHealthLabel'

interface IProps {
  readonly ticketId: string
}

export function RowForSynthesisError({ ticketId }: IProps) {
  const ticketOcv = application.store.use.getTicketOcv(ticketId)
  const issues = ticketOcv.data?.OcvIssues ?? []

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Synthesis - OCV Issues</Text>
      </TableCell>
      <TableCell>
        {(() => {
          if (issues.length === 0) {
            return 'NULL'
          }

          return issues.map((item, index) => {
            const title = item.Title
            if (!title) {
              return
            }
            return (
              <Row key={index}>
                <span>{title}</span>
                <Spacer width={4} />
                {(() => {
                  if (title.toLowerCase().includes('error')) {
                    return <SymptomReportHealthLabel status="error" />
                  }

                  if (title.toLowerCase().includes('empty response')) {
                    return <SymptomReportHealthLabel status="warning" text="Warning" />
                  }

                  return null
                })()}
              </Row>
            )
          })
        })()}
      </TableCell>
    </TableRow>
  )
}
