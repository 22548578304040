import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketChatTurn } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../IDashStoreContext'
import { getTicketChatTurn } from './actions-conversation/getTicketChatTurn'
import { getTicketSession } from './actions-ticket/getTicketSession'

export function getTicketVerbatimConversation(
  context: IDashStoreContext,
  ticketId: string,
): PromiseSnapshot<ITicketChatTurn> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.verbatimConversation
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      ticket.verbatimConversation = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<ITicketChatTurn> {
    const session = await getTicketSession(context, ticketId).promise
    const interaction = session.interactions?.find((i) => i.index === 1)
    const turnId = interaction?.messageId

    if (!turnId) {
      throw TicketError.create('NoConversations', { ticketId })
    }

    return getTicketChatTurn(context, ticketId, turnId).promise
  }
}
