import { Toast, ToastBody, ToastTitle, useToastController, ToastPosition } from '@fluentui/react-components'
import { uniqueId } from 'lodash'
import * as React from 'react'

export const toasterId = uniqueId('toaster-')

export const useToast = () => {
  const { dispatchToast } = useToastController(toasterId)

  const showInfo = React.useCallback(
    (title?: string, message?: string, position?: ToastPosition) => {
      dispatchToast(
        <Toast>
          {title && <ToastTitle>{title}</ToastTitle>}
          {message && <ToastBody>{message}</ToastBody>}
        </Toast>,
        { intent: 'info', position },
      )
    },
    [dispatchToast],
  )

  const showWarning = React.useCallback(
    (title?: string, message?: string, position?: ToastPosition, timeout?: number) => {
      dispatchToast(
        <Toast>
          {title && <ToastTitle>{title}</ToastTitle>}
          {message && <ToastBody>{message}</ToastBody>}
        </Toast>,
        { intent: 'warning', position, timeout },
      )
    },
    [dispatchToast],
  )

  const showError = React.useCallback(
    (title?: string, message?: string, position?: ToastPosition) => {
      dispatchToast(
        <Toast>
          {title && <ToastTitle>{title}</ToastTitle>}
          {message && <ToastBody>{message}</ToastBody>}
        </Toast>,
        { intent: 'error', position },
      )
    },
    [dispatchToast],
  )

  const showSuccess = React.useCallback(
    (title?: string, message?: string, position?: ToastPosition, timeout?: number) => {
      dispatchToast(
        <Toast>
          {title && <ToastTitle>{title}</ToastTitle>}
          {message && <ToastBody>{message}</ToastBody>}
        </Toast>,
        { intent: 'success', position, timeout },
      )
    },
    [dispatchToast],
  )

  return {
    showInfo,
    showWarning,
    showError,
    showSuccess,
  }
}
