import { Tooltip } from '@fluentui/react-components'
import { ReactElement, useMemo } from 'react'
import { getErrorMessage } from '../ErrorConfigs/utils/getErrorMessage'
import { getErrorMessageDetails } from '../ErrorConfigs/utils/getErrorMessageDetails'

interface IProps {
  readonly ticketId: string
  readonly children: ReactElement
}

export function TicketClientTooltip({ ticketId, children }: IProps) {
  const ticket = application.store.use.getTicket(ticketId)

  const content = useMemo(() => {
    switch (ticket.status) {
      case 'waiting':
        return 'Loading...'
      case 'done':
        return ticket.data?.clientName ?? 'No client name found'
      case 'error':
        return getErrorMessageDetails(ticket.error) ?? getErrorMessage(ticket.error)
    }
  }, [ticket])

  return (
    <Tooltip content={content} relationship={'label'}>
      {children}
    </Tooltip>
  )
}
