import { AsyncLoader, AsyncSnapshots, IStoreGetter, IStoreSetter } from '@copilot-dash/core'
import { IComment, ICommentRequest, IEditCommentRequest } from '@copilot-dash/domain'
import { ITicketDiscussionPanelActions } from './ITicketDiscussionPanelActions'
import { ITicketDiscussionPanelState } from './ITicketDiscussionPanelState'

interface IParams {
  readonly get: IStoreGetter<ITicketDiscussionPanelState>
  readonly set: IStoreSetter<ITicketDiscussionPanelState>
}

export function createDiscussionPanelActions({ get, set }: IParams): ITicketDiscussionPanelActions {
  const commentsLoader = new AsyncLoader<IComment[]>()
  const postCommentLoader = new AsyncLoader<IComment>()
  const deleteCommentLoader = new AsyncLoader<void>()
  return {
    //Initialization
    init() {
      commentsLoader.submit({
        promise: () => {
          return application.store.actions.getTicketComments(get().ticketId)
        },
        onChanged: (snapshot) => {
          set((state) => {
            state.commentsSnapshot = snapshot
          })
        },
      })
    },

    //Update ticketId
    updateTicketId: (ticketId: string) => {
      set((state) => {
        state.ticketId = ticketId
        state.commentsSnapshot = AsyncSnapshots.waiting()
      })
      commentsLoader.submit({
        promise: () => {
          if (!ticketId) {
            return null
          }
          return app.store.actions.getTicketComments(ticketId)
        },
        onChanged: (snapshot) => {
          set((state) => {
            state.commentsSnapshot = snapshot
          })
        },
      })
    },

    //Post comment
    postComment: (ticketId: string, comment: ICommentRequest) => {
      return postCommentLoader.submit({
        promise: () => {
          if (!comment || !ticketId) {
            return null
          }
          return app.store.actions.createTicketComment(ticketId, comment)
        },
        onChanged: (snapshot) => {
          set((state) => {
            state.postCommentSnapshot = snapshot
            if (snapshot.status === 'done' && state.commentsSnapshot.status === 'done') {
              state.commentsSnapshot.data.push(snapshot.data)
            }
          })
        },
      })
    },

    //Edit comment
    editComment: (ticketId: string, commentId: string, comment: IEditCommentRequest) => {
      return postCommentLoader.submit({
        promise: () => {
          if (!comment || !commentId || !ticketId) {
            return null
          }
          return application.store.actions.updateTicketComment(ticketId, commentId, comment)
        },
        onChanged: (snapshot) => {
          set((state) => {
            state.editCommentSnapshot = snapshot
            if (snapshot.status === 'done' && state.commentsSnapshot.status === 'done') {
              state.commentsSnapshot.data.map((comment) => {
                if (comment.commentId === commentId) {
                  comment.content = snapshot.data.content
                  comment.modifyTime = snapshot.data.modifyTime
                }
              })
            }
          })
        },
      })
    },

    //Delete comment
    deleteComment: (ticketId: string, commentId: string) => {
      deleteCommentLoader.submit({
        promise: () => {
          if (!ticketId || !commentId) {
            return null
          }
          return app.store.actions.deleteTicketComment(ticketId, commentId)
        },
        onChanged: (snapshot) => {
          set((state) => {
            state.deleteCommentSnapshot = snapshot
            if (snapshot.status === 'done' && state.commentsSnapshot.status === 'done') {
              state.commentsSnapshot.data = state.commentsSnapshot.data.filter(
                (comment) => comment.commentId !== commentId,
              )
            }
          })
        },
      })
    },
    resetEditCommentSnapshot: () => {
      set((state) => {
        state.editCommentSnapshot.status = 'none'
        state.editCommentSnapshot.data = undefined
      })
    },
  }
}
