import { useRouteError } from 'react-router-dom'
import { ErrorView } from '../../components/Error'
import { Column } from '../../components/Layout'

export function RootRouteError() {
  const error = useRouteError()

  return (
    <Column vAlign="center" hAlign="center" fill>
      <ErrorView error={error} />
    </Column>
  )
}
