import { ApiError, ZodError } from '@copilot-dash/api'
import { makeStyles, tokens } from '@fluentui/react-components'

interface IProps {
  readonly error: ApiError
  readonly cause: ZodError
}

export function ApiResponseTableZodMessage({ error, cause }: IProps) {
  const styles = useStyles()

  return <span className={styles.text}>{cause.issues[0]?.message}</span>
}

const useStyles = makeStyles({
  text: {
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorStatusWarningForeground1,
  },
})
