import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    maxWidth: '511px',
    width: '100%',
  },
  title: {
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightSemibold,
    marginLeft: tokens.spacingHorizontalSNudge,
  },
  tagTitleRow: {
    width: '120px',
    fontWeight: tokens.fontWeightRegular,
    fontSize: tokens.fontSizeBase300,
    color: tokens.colorNeutralForeground4,
    ...shorthands.padding('0px', '6px'),
  },
  tagValueRow: {
    display: 'flex',
    flex: '1 1 auto',
    flexWrap: 'wrap',
    ...shorthands.gap('8px'),
    overflow: 'hidden',
  },
  interactionTagPrimary: {
    backgroundColor: tokens.colorNeutralBackground4,
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground4,
    },
    ':active': {
      backgroundColor: tokens.colorNeutralBackground4,
    },
  },
  grayColor: {
    color: tokens.colorNeutralForeground4,
  },
  userName: {
    maxWidth: '140px',
  },
})
