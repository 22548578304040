import { ApiConversationGroup2 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getOdsBlobMetadata } from '../actions-blob/getOdsBlobMetadata'
import { getRawConversationIndex } from './getRawConversationIndex'

export function getRawConversationGroup2(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
): PromiseSnapshot<ApiConversationGroup2> {
  return context.getOrFetch<ApiConversationGroup2>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.rawConversationGroup2
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.rawConversationGroup2 = snapshot
    },
    fetch: async () => {
      return await fetchFromV1()
    },
  })

  async function fetchFromV1(): Promise<ApiConversationGroup2> {
    // Get turn index
    const turnIndex = await getRawConversationIndex(context, ticketId, turnId)

    // Get from grouped file
    const files = await getOdsBlobMetadata(context, ticketId).promise
    const file = files.groupedConversationFiles.find((item) => item.index === turnIndex)?.group2
    if (!file) {
      throw TicketError.create('NoConversations', { ticketId, ticketMessageId: turnId })
    }

    return await context.api.ods.getConversationGroup2(file.fileUrl)
  }
}
