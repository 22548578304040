import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    ':not(:first-of-type)': {
      marginTop: '24px',
    },
  },

  accordionContent: {
    overflow: 'hidden',
  },

  header: {
    ...shorthands.padding('16px'),
    backgroundColor: tokens.colorNeutralBackground3,
    cursor: 'pointer',
    borderRadius: '8px',
  },

  icon: {
    display: 'flex',
    alignItems: 'center',
  },
})
