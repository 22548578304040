import { INewTicketData, ColumnKey, ColumnIdNaColumnIdNameMapping } from '@copilot-dash/domain'
import { ExtendedTableColumnDefinition, createExtendedTableColumn } from '../Table/ExtendedTableColumn'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { Text } from '@fluentui/react-components'
import { useStyles } from './ColumnComponent.styles'
import { ToolTipText } from '../Text/ToolTipText'

const ConfigTenantName = ({ item, keyWords }: { item: INewTicketData; keyWords?: string[] }) => {
  const styles = useStyles()
  return (
    <TableCellLayoutWithStyles truncate>
      {item.tenantName ? (
        <ToolTipText
          text={item.tenantName}
          keywords={item.tenantId === keyWords?.[0] ? item.tenantName : undefined}
          isTwoLinesTruncate={true}
        />
      ) : (
        <Text className={styles.grayColor}>N/A</Text>
      )}
    </TableCellLayoutWithStyles>
  )
}

export const ColumnConfigTenantName = (keyWords?: string[]): ExtendedTableColumnDefinition<INewTicketData> =>
  createExtendedTableColumn<INewTicketData>({
    columnId: ColumnKey.TenantName,
    columnName: ColumnIdNaColumnIdNameMapping[ColumnKey.TenantName],
    isResizable: true,
    flex: 1,
    columnSize: {
      minWidth: 50,
      idealWidth: 130,
      padding: 0,
    },
    renderHeaderCell: () => <TableCellLayoutWithStyles truncate>Tenant Name</TableCellLayoutWithStyles>,
    renderCell: (item: INewTicketData) => <ConfigTenantName item={item} keyWords={keyWords} />,
  })
