import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { getRawOcvTicket } from '../getRawOcvTicket'
import { getTicketVerbatimConversation } from '../getTicketVerbatimConversation'
import { validateTicketUserConsent } from '../validators/validateTicketUserConsent'
import { getTicketSession } from './getTicketSession'

export function getTicketResponse(context: IDashStoreContext, ticketId: string): PromiseSnapshot<string> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.response
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.response = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<string> {
    if (context.enableV2Endpoint) {
      return doFetchFromV2()
    }

    try {
      return await fetchFromConversation()
    } catch (odsError) {
      try {
        return await fetchFromOCV()
      } catch (ocvError) {
        throw odsError
      }
    }
  }

  async function doFetchFromV2(): Promise<string> {
    const raw = await getRawV2Ticket(context, ticketId).promise
    const response = raw.response
    if (response.trim()) {
      return response
    }

    // Check if user has given consent
    const hasUserConsent = raw.hasUserConsent
    if (!hasUserConsent) {
      throw TicketError.create('NoUserConsentGiven', { ticketId })
    }

    throw TicketError.create('NoResponse', { ticketId })
  }

  async function fetchFromConversation(): Promise<string> {
    const turn = await getTicketVerbatimConversation(context, ticketId).promise
    const response = turn.botMessage?.text
    if (response) {
      return response
    }

    // 1. User consent
    const session = await getTicketSession(context, ticketId).promise
    validateTicketUserConsent(ticketId, session)

    throw TicketError.create('NoResponse', { ticketId })
  }

  async function fetchFromOCV(): Promise<string> {
    const ocvTicket = await getRawOcvTicket(context, ticketId).promise
    const response = ocvTicket.AiContext?.ResponseMessage
    if (response) {
      return response
    }

    throw TicketError.create('NoResponse', { ticketId })
  }
}
