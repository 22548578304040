import { Text, makeStyles, tokens } from '@fluentui/react-components'
import { Row } from '../../Layout'

export function ActivityHistoryEmpty() {
  const styles = useStyles()

  return (
    <Row fill vAlign="center" hAlign="center">
      <Text className={styles.grayColor}>No Activity History found</Text>
    </Row>
  )
}

const useStyles = makeStyles({
  grayColor: {
    color: tokens.colorScrollbarOverlay,
  },
})
