import { ApiKustoLogResponse, ApiStateAndDurationKustoLogItem } from '@copilot-dash/api'
import { IKustoStateDurationLogItem } from '@copilot-dash/domain'

export function convertToStateAndDurationLogs(raw: ApiKustoLogResponse): IKustoStateDurationLogItem[] {
  return raw.logData['turingBotMonitoredScopeLogs (Kusto)']?.map(convertItem) ?? []
}

function convertItem(raw: ApiStateAndDurationKustoLogItem): IKustoStateDurationLogItem {
  const { tIMESTAMP, ...rest } = raw
  return {
    ...rest,
    timestamp: tIMESTAMP,
  }
}
