import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  root: {
    justifyItems: 'start',
    gap: '2px',
  },
  selected: {
    ...shorthands.borderTop('1px', 'solid', tokens.colorBrandForeground1),
    ...shorthands.borderLeft('1px', 'solid', tokens.colorBrandForeground1),
    ...shorthands.borderRight('1px', 'solid', tokens.colorBrandForeground1),
    ...shorthands.borderBottom('2px', 'solid', tokens.colorBrandForeground1),
    '&> span': {
      color: tokens.colorBrandForeground1,
    },
    '&> input': {
      fontWeight: tokens.fontWeightSemibold,
    },
    ':hover': {
      ...shorthands.borderTop('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderLeft('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderRight('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderBottom('2px', 'solid', tokens.colorBrandForeground1),
    },
  },
})
