import {
  Menu,
  MenuButtonProps,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  SplitButton,
} from '@fluentui/react-components'
import { RouteLink, TicketRoute } from '../../../router'
import { IUpdateTicketStatusInfoData } from '@copilot-dash/domain'

interface IProps {
  ticketId: string
  afterSavedCallback?: (savedInfo: IUpdateTicketStatusInfoData) => void
}

export function DebugMoreButton({ ticketId, afterSavedCallback }: IProps) {
  const onClick = () => {
    TicketRoute.navigator.navigateToTicketModal({ id: ticketId, afterSavedCallback })
    Logger.telemetry.trackEvent('TicketSummaryPanel/DebugMoreButton/Click', { ticketId })
  }

  const onOpenInNewTabClicked = () => {
    Logger.telemetry.trackEvent('TicketSummaryPanel/DebugMoreOnNewPage/Click', { ticketId })
  }
  return (
    <Menu positioning="below-end">
      <MenuTrigger disableButtonEnhancement>
        {(triggerProps: MenuButtonProps) => (
          <SplitButton appearance="primary" menuButton={triggerProps} primaryActionButton={{ onClick }}>
            Debug more
          </SplitButton>
        )}
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          <MenuItem>
            <RouteLink
              openInNewTab
              path={TicketRoute.navigator.generatePath({ id: ticketId })}
              onClick={onOpenInNewTabClicked}
            >
              Open on new page
            </RouteLink>
          </MenuItem>
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
