import { ApiMcpExperimentFeedback } from '@copilot-dash/api'

interface ITimeWindow {
  timestamp: string
  feedbackType: 'Positive' | 'Negative'
  count: number
}

const COUNT_EXISTS = 1

export function getDayWindowed(data: ApiMcpExperimentFeedback[]) {
  const dailyData = data.map((record) => ({
    ...record,
    timestamp: record.timestamp ? new Date(new Date(record.timestamp).setHours(0, 0, 0, 0)) : undefined,
  }))

  const positiveMap = new Map<string, ITimeWindow>()
  const negativeMap = new Map<string, ITimeWindow>()
  dailyData.forEach((record) => {
    const key = record.timestamp?.toISOString() || ''
    if (record.feedbackType === 'Positive') {
      if (!positiveMap.has(key)) {
        positiveMap.set(key, { timestamp: key, feedbackType: record.feedbackType, count: COUNT_EXISTS })
      } else {
        const current = positiveMap.get(key)
        if (current) {
          positiveMap.set(key, {
            timestamp: key,
            feedbackType: record.feedbackType,
            count: current.count + COUNT_EXISTS,
          })
        }
      }
    } else if (record.feedbackType === 'Negative') {
      if (!negativeMap.has(key)) {
        negativeMap.set(key, { timestamp: key, feedbackType: record.feedbackType, count: COUNT_EXISTS })
      } else {
        const current = negativeMap.get(key)
        if (current) {
          negativeMap.set(key, {
            timestamp: key,
            feedbackType: record.feedbackType,
            count: current.count + COUNT_EXISTS,
          })
        }
      }
    }
  })

  return { pos: Array.from(positiveMap.values()), neg: Array.from(negativeMap.values()) }
}

export function getHourWindowed(data: ApiMcpExperimentFeedback[]) {
  const hourlyData = data.map((record) => ({
    ...record,
    timestamp: record.timestamp ? new Date(new Date(record.timestamp).setMinutes(0, 0, 0)) : undefined,
  }))

  const positiveMap = new Map<string, ITimeWindow>()
  const negativeMap = new Map<string, ITimeWindow>()
  hourlyData.forEach((record) => {
    const key = record.timestamp?.toISOString() || ''
    if (record.feedbackType === 'Positive') {
      if (!positiveMap.has(key)) {
        positiveMap.set(key, { timestamp: key, feedbackType: record.feedbackType, count: COUNT_EXISTS })
      } else {
        const current = positiveMap.get(key)
        if (current) {
          positiveMap.set(key, {
            timestamp: key,
            feedbackType: record.feedbackType,
            count: current.count + COUNT_EXISTS,
          })
        }
      }
    } else if (record.feedbackType === 'Negative') {
      if (!negativeMap.has(key)) {
        negativeMap.set(key, { timestamp: key, feedbackType: record.feedbackType, count: COUNT_EXISTS })
      } else {
        const current = negativeMap.get(key)
        if (current) {
          negativeMap.set(key, {
            timestamp: key,
            feedbackType: record.feedbackType,
            count: current.count + COUNT_EXISTS,
          })
        }
      }
    }
  })

  return { pos: Array.from(positiveMap.values()), neg: Array.from(negativeMap.values()) }
}
