import { Text, makeStyles, tokens } from '@fluentui/react-components'
import { Row } from '../../Layout'

export function DiscussionPanelEmpty() {
  const styles = useStyles()

  return (
    <Row fill vAlign="center" hAlign="center">
      <Text className={styles.grayColor}>No comment found</Text>
    </Row>
  )
}

const useStyles = makeStyles({
  grayColor: {
    color: tokens.colorScrollbarOverlay,
  },
})
