import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const COLUMN_SETTING_PANEL_WIDTH = 360

export const useStyles = makeStyles({
  drawer: {
    width: `${COLUMN_SETTING_PANEL_WIDTH}px`,
  },

  flexTabLayout: {
    ...shorthands.padding('12px', '0px'),
  },

  flexTextLayout: {
    ...shorthands.padding('0px', '0px', '12px', '0px'),
    ...shorthands.gap('12px'),
  },

  add16RegularLayout: {
    cursor: 'pointer',
    marginLeft: '4px',
  },
  whiteReOrderDotsVertical16RegularLayout: {
    color: 'transparent',
  },
  hoverReOrderDotsVertical16RegularLayout: {
    color: tokens.colorNeutralForeground1Hover,
  },
  dismiss16RegularLayout: {
    cursor: 'pointer',
    color: tokens.colorStatusDangerBackground3,
    marginLeft: '4px',
  },
  disabledDismiss16RegularLayout: {
    cursor: 'not-allowed',
    color: tokens.colorNeutralForegroundDisabled,
    marginLeft: '4px',
  },
  rowItemConfigLayout: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground1Hover,
    },
  },
  supportMove: {
    cursor: 'move',
  },
  title: {
    ...shorthands.margin(0, 0, 0, '12px'),
    alignItems: 'center',
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightSemibold,
  },

  borderTextLayout: {
    flexGrow: 1,
    ...shorthands.borderRadius('3px'),
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
    ...shorthands.padding('4px', '4px'),
  },
  header: {
    backgroundColor: tokens.colorNeutralForeground1,
    color: tokens.colorNeutralForegroundInverted,
    height: '56px',
    ...shorthands.padding('0px', '24px', '0px', '52px'),
  },
  headerRight: {
    ...shorthands.padding('0px', '0px', '0px', '4px'),
  },
})
