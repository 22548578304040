import { ApiError } from '@copilot-dash/api'
import { GlobeErrorRegular } from '@fluentui/react-icons'
import { ReactNode } from 'react'
import { ApiErrorContextView } from './components/ApiErrorContextView'
import { ErrorConfigForApiNetwork } from './ErrorConfigForApiNetwork'
import { ErrorConfigForApiSchema } from './ErrorConfigForApiSchema'
import { IErrorConfig } from './interfaces/IErrorConfig'

export class ErrorConfigForApi implements IErrorConfig {
  private readonly error: ApiError

  constructor(error: ApiError) {
    this.error = error
  }

  triage(): IErrorConfig | null {
    switch (this.error.data.details.type) {
      case 'zod':
        return new ErrorConfigForApiSchema(this.error, this.error.data.details.cause)
      case 'axios':
        return new ErrorConfigForApiNetwork(this.error, this.error.data.details.cause)
      case 'unknown':
        return null
    }
  }

  buildName() {
    return 'API Error'
  }

  buildIcon() {
    return <GlobeErrorRegular />
  }

  buildMessageDetails() {
    return `An error occurred while communicating with the API. Please check the context for more details.`
  }

  buildContext(): ReactNode {
    return <ApiErrorContextView error={this.error} />
  }
}
