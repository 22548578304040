import { Button, Tooltip } from '@fluentui/react-components'
import { HomeRoute, RouteLink } from '../../../router'

export function UnknownScreenGoHomeButton() {
  const path = HomeRoute.navigator.generatePath()

  return (
    <RouteLink path={path}>
      <Tooltip content="Go to the home page" relationship="label">
        <Button>Go Home</Button>
      </Tooltip>
    </RouteLink>
  )
}
