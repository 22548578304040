import { ITimezoneData, Times } from '@copilot-dash/core'
import { ReactElement, useMemo } from 'react'
import { TimezoneViewPopover } from './TimezoneViewPopover'

interface IProps {
  readonly children?: (data: ITimezoneData) => ReactElement
}

export function TimezoneView({ children }: IProps) {
  const timezone = app.settings.timezone.use()
  const data = useMemo(() => Times.getTimezoneData(timezone), [timezone])

  return <TimezoneViewPopover>{children ? children(data) : <span>{data.valueLabel}</span>}</TimezoneViewPopover>
}
