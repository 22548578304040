import { ReactNode } from 'react'
import { IErrorConfig } from './interfaces/IErrorConfig'
import { CustomError } from './types/CustomError'

export class ErrorConfigForCustom implements IErrorConfig {
  private readonly error: CustomError

  constructor(error: CustomError) {
    this.error = error
  }

  buildLevel() {
    return this.error.data.level ?? 'ERROR'
  }

  buildName() {
    return this.error.data.name
  }

  buildIcon() {
    return this.error.data.icon
  }

  buildMessage() {
    return this.error.data.message
  }

  buildMessageDetails() {
    return this.error.data.messageDetails
  }

  buildContext(): ReactNode {
    return this.error.data.context
  }
}
