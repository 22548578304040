import { ApiOds3sOnlineDataGroup4 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getOdsBlobMetadata } from '../actions-blob/getOdsBlobMetadata'
import { getRaw3sOnlineIndex } from './getRaw3sOnlineIndex'

export function getRaw3sOnlineDataGroup4(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
): PromiseSnapshot<ApiOds3sOnlineDataGroup4> {
  return context.getOrFetch<ApiOds3sOnlineDataGroup4>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.raw3sOnlineDataGroup4
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.raw3sOnlineDataGroup4 = snapshot
    },
    fetch: async () => {
      return await fetchFromV1()
    },
  })

  async function fetchFromV1(): Promise<ApiOds3sOnlineDataGroup4> {
    // Get turn index
    const index = await getRaw3sOnlineIndex(context, ticketId, turnId)

    // Get turn file
    const files = await getOdsBlobMetadata(context, ticketId).promise
    const file = files.sssOnline.find((file) => file.index === index && file.group === 'Group4')
    if (!file) {
      throw TicketError.create('No3sOnline', { ticketId, ticketMessageId: turnId })
    }

    return await context.api.ods.get3sOnlineDataGroup4(file.fileUrl)
  }
}
