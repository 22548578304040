import { PromiseSnapshot } from '@copilot-dash/core'
import { makeStyles, Spinner, Tag, Text, tokens, TreeItemLayout } from '@fluentui/react-components'
import { ErrorCircleRegular } from '@fluentui/react-icons'
import { useMemo } from 'react'
import { getErrorLevel } from '../../../../../../../../components/ErrorConfigs/utils/getErrorLevel'
import { Row, Spacer } from '../../../../../../../../components/Layout'
import { TreeItemOpenButton } from './TreeItemOpenButton'

interface IProps {
  readonly type: 'kusto' | 'offlineGeneric' | 'onlineGeneric' | 'offlineGrounding' | 'onlineGrounding'
  readonly snapshot?: PromiseSnapshot<unknown>
}

export function TreeItemTitle({ type, snapshot }: IProps) {
  const styles = useStyles()

  const expandIcon = useMemo(() => {
    if (snapshot?.status === 'waiting') {
      return <Spinner size="extra-tiny" />
    }

    if (snapshot?.status === 'error') {
      const level = getErrorLevel(snapshot.error)
      switch (level) {
        case 'ERROR':
        case 'WARNING':
          return <ErrorCircleRegular />
        case 'OK':
          return ''
      }
    }

    return undefined
  }, [snapshot])

  const title = useMemo(() => {
    switch (type) {
      case 'kusto':
        return `Kusto logs`
      case 'offlineGeneric':
        return `General diagnostic data`
      case 'offlineGrounding':
        return `Grounding data`
      case 'onlineGeneric':
        return `General diagnostic data`
      case 'onlineGrounding':
        return `Grounding data`
    }
  }, [type])

  const label = useMemo(() => {
    switch (type) {
      case 'kusto':
        return null
      case 'offlineGeneric':
      case 'offlineGrounding':
        return (
          <Tag size="small" shape="circular" className={styles.offlineTag}>
            Offline
          </Tag>
        )
      case 'onlineGeneric':
      case 'onlineGrounding':
        return (
          <Tag size="small" shape="circular" className={styles.onlineTag}>
            Online
          </Tag>
        )
    }
  }, [styles, type])

  return (
    <TreeItemLayout
      expandIcon={expandIcon}
      actions={
        <>
          <TreeItemOpenButton data={snapshot?.data} />
          <Spacer width={16} />
        </>
      }
    >
      <Row vAlign="center" className={styles.root}>
        <Text>{title}</Text>
        {label}
      </Row>
    </TreeItemLayout>
  )
}

const useStyles = makeStyles({
  root: {
    gap: '12px',
  },
  offlineTag: {
    color: tokens.colorBrandForeground1,
    backgroundColor: tokens.colorBrandBackground2,
  },
  onlineTag: {
    color: tokens.colorPaletteGreenForeground1,
    backgroundColor: tokens.colorPaletteLightGreenBackground1,
  },
})
