import { makeStyles, tokens, Text, mergeClasses } from '@fluentui/react-components'
import { Row } from '../Layout'

interface LegendProps {
  readonly items: LegendItem[]
  readonly className?: string
}
export interface LegendItem {
  readonly color: string
  readonly label: string
}

export const Legend: React.FC<LegendProps> = ({ items, className }) => {
  const styles = useStyles()
  return (
    <Row className={styles.container}>
      {items.map((item, index) => (
        <Row key={index} style={{ display: 'flex', alignItems: 'center' }}>
          <Row
            style={{
              backgroundColor: item.color,
            }}
            className={mergeClasses(styles.legendIcon, className)}
          />
          <Text className={styles.text}>{item.label}</Text>
        </Row>
      ))}
    </Row>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  legendIcon: {
    width: '12px',
    height: '12px',
    marginRight: '8px',
    borderRadius: '2px',
  },
  text: {
    fontSize: tokens.fontSizeBase300,
  },
})
