import { z } from 'zod'

export interface ApiBatchTicketsCustomTagsResponse {
  readonly ticketCustomTags?: { [ticketId: string]: string[] }
  readonly errorMessage?: string
}

export const apiBatchTicketsCustomTagsResponseSchema = z.object({
  ticketCustomTags: z.record(z.array(z.string())).optional(),
  errorMessage: z.string().optional(),
})
