import { StateCreator } from 'zustand'
import { ITeamViewRouteStore, ITeamViewStore } from './types'
import { ProductNames } from '@copilot-dash/domain'
import { isEqual } from 'lodash'

export const createRouteStore: StateCreator<ITeamViewStore, [['zustand/immer', never]], [], ITeamViewRouteStore> = (
  set,
  get,
) => ({
  args: {
    product: ProductNames.M365Chat,
  },
  setArgs: (args) => {
    set((state) => {
      if (!isEqual(state.route.args, args)) {
        state.route.args = args
      }
    })
  },
})
