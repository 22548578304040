import { ApiOds3sOnlineDataGroup2Item, ApiOds3sOnlineDataGroup3 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoGwsLogItem, ITicketTurnHealthData, ITicketTurnTransactionHealthData } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { get3sGwsLog } from '../actions-kusto/get3sGwsLog'
import { getRaw3sOnlineDataGroup2 } from '../actions-raw-3s/getRaw3sOnlineDataGroup2'
import { getRaw3sOnlineDataGroup3 } from '../actions-raw-3s/getRaw3sOnlineDataGroup3'
import { getTicket } from '../actions-ticket/getTicket'
import { getTicketSession } from '../actions-ticket/getTicketSession'

export function getTicketHealthData(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
): PromiseSnapshot<ITicketTurnHealthData> {
  return context.getOrFetch<ITicketTurnHealthData>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.health
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.health = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<ITicketTurnHealthData> {
    const ticket = await getTicket(context, ticketId).promise
    if (!ticket.tag.sssTriggered) {
      throw TicketError.create('No3SDueToNotTriggered', { ticketId })
    }

    const logsPromise = get3sGwsLog(context, ticketId, turnId).promise
    const group2Promise = getRaw3sOnlineDataGroup2(context, ticketId, turnId).promise
    const group3Promise = getRaw3sOnlineDataGroup3(context, ticketId, turnId).promise

    // From GWS Logs
    const results: Record<string, ITicketTurnTransactionHealthData> = {}
    const logs = await logsPromise
    for (const item of logs) {
      if (item.httpStatusCode === '500' && item.transactionId) {
        results[item.transactionId] = getHealthItemData(item, item.transactionId, undefined, undefined, 'GWS log')
      }
    }

    // From 3s online data
    const session = await getTicketSession(context, ticketId).promise
    const interaction = session.interactions.find((item) => item.messageId === turnId)
    const onlineData = interaction?.sssOnlineDataList ?? []

    const group2 = await group2Promise
    const group3 = await group3Promise.catch(() => undefined)

    onlineData.forEach((item, index) => {
      const online = group2[index]
      if (item.transactionId) {
        results[item.transactionId] = getHealthItemData(
          logs.find((log) => log.transactionId === item.transactionId),
          item.transactionId,
          online,
          group3?.find((transaction) => {
            return transaction.ReplayResponse.TraceId === item.transactionId
          }),
          '3S online data',
        )
      }
    })

    return results
  }

  function getHealthItemData(
    fullGWSLog: IKustoGwsLogItem | undefined,
    transactionId: string,
    data: ApiOds3sOnlineDataGroup2Item | undefined,
    group3Transaction: ApiOds3sOnlineDataGroup3 | undefined,
    source: string,
  ): ITicketTurnTransactionHealthData {
    const newDiagnosticData: Record<string, unknown> = {}
    if (fullGWSLog) {
      const diagnosticData = fullGWSLog.diagnosticDataCustomized
      Object.keys(diagnosticData ?? {}).forEach((key) => {
        if (
          key.startsWith('ShouldExecuteDomain') &&
          diagnosticData?.[key]?.toString().includes('ShouldExecute: True')
        ) {
          newDiagnosticData[key] = diagnosticData[key]
        }
      })
      if (diagnosticData?.PeopleNaturalSearchAnswerWorkflow) {
        newDiagnosticData['PeopleNaturalSearchAnswerWorkflow'] = diagnosticData?.PeopleNaturalSearchAnswerWorkflow
      }
    }

    const query =
      data?.ReplayRequest?.AnswerEntityRequests?.[0]?.Query?.DisplayQueryString ||
      data?.ReplayRequest?.AnswerEntityRequests?.[0]?.Query?.QueryString ||
      data?.ReplayRequest?.EntityRequests?.[0]?.Query?.DisplayQueryString ||
      data?.ReplayRequest?.EntityRequests?.[0]?.Query?.QueryString

    const lu = data?.ReplayResponse?.TrimmedResponse?.ExtendedData?.Info

    return {
      transactionId,
      source,
      httpStatusCode: fullGWSLog?.httpStatusCode,
      query,
      lu,
      response: {
        entitySets: getEntitySets(group3Transaction),
        answerEntitySets: '',
      },
      gwsLog: {
        diagnosticData: newDiagnosticData,
        responseMetaJson: fullGWSLog?.responseMetaJsonDecoded,
      },
    }
  }

  function getEntitySets(group3Transaction: ApiOds3sOnlineDataGroup3 | undefined): string {
    const results: string[] = []
    for (const entitySet of group3Transaction?.ReplayResponse.TrimmedResponse.EntitySets ?? []) {
      for (const resultSet of entitySet.ResultSets ?? []) {
        const entityType = resultSet.Results?.[0]?.Type
        const provenance = resultSet.Provenance
        const first = entityType === 'Message' ? provenance || entityType : entityType
        const second = resultSet.Results?.length || 0

        results.push(`[${first}, ${second}]`)
      }
    }

    return results.join(', ')
  }
}
