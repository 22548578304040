import { makeStyles, Overflow, OverflowItem, TagGroup } from '@fluentui/react-components'
import { compact } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { Row, Spacer } from '../../../../components/Layout'
import { useScrollbar } from '../../../../components/Scrollbar/useScrollbar'
import { TicketScreenTag } from './TicketScreenTag'
import { TicketScreenTagOverflowButton } from './TicketScreenTagOverflowButton'

interface IProps {
  readonly tags: string[] | string | undefined
  readonly collapsible?: boolean
}

export function TicketScreenTagList(props: IProps) {
  const styles = useStyles()
  const scrollbarRef = useScrollbar()
  const [expanded, setExpanded] = useState(!props.collapsible)

  const finalTags = useMemo(() => {
    if (!props.tags) {
      return []
    }

    if (typeof props.tags === 'string') {
      return compact(props.tags.split(';').map((tag) => tag.trim()))
    }

    return props.tags
  }, [props.tags])

  const handleExpand = useCallback((expand: boolean) => {
    setExpanded(expand)
  }, [])

  return (
    <div ref={scrollbarRef} className={styles.scrollbar}>
      <Overflow minimumVisible={1} padding={120}>
        <Row vAlign="end" hAlign="start" gap="gap.small">
          <TagGroup className={styles.tagGroup}>
            {finalTags.map((tag, index) => {
              return expanded ? (
                <TicketScreenTag key={index} tag={tag} />
              ) : (
                <OverflowItem key={index} id={tag}>
                  <span>
                    <TicketScreenTag tag={tag} />
                  </span>
                </OverflowItem>
              )
            })}
          </TagGroup>
          <Spacer />
          {props.collapsible && <TicketScreenTagOverflowButton onChanged={handleExpand} expanded={expanded} />}
        </Row>
      </Overflow>
    </div>
  )
}

const useStyles = makeStyles({
  scrollbar: {
    flex: '1 1 auto',
    maxHeight: '120px',
  },
  tagGroup: {
    gap: '8px',
    flexWrap: 'wrap',
  },
})
