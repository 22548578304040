import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    ...shorthands.padding('30px', '24px'),
    overflowY: 'auto',
  },
  cardsContainer: {
    display: 'flex',
    ...shorthands.border('1px', 'solid', tokens.colorNeutralBackground6),
    ...shorthands.borderRadius('8px'),
  },
  card: {
    width: '33%',
  },
  divider: {
    ...shorthands.padding('15px', '0px'),
    width: '2px',
  },
})
