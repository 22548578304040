import { ITagDefinition } from './ITagDefinition.type'
import { useSearchScreenState } from '../../store'
import { TruncateTagFunction } from './TruncateTagFunction'
import { useMemo } from 'react'
import { DefaultTriggeredSkillList, ITriggeredSkill } from '@copilot-dash/domain'

export const TriggeredSkillTagConfig = (): ITagDefinition => {
  const form = useSearchScreenState((state) => state.form)
  const valueTexts = useMemo(() => {
    return form.triggeredSkill?.map((item) => {
      const option = DefaultTriggeredSkillList.find((option: ITriggeredSkill) => option.key === item)
      return option ? option.text : item
    })
  }, [form.triggeredSkill])

  return {
    key: 'triggeredSkill',
    tagName: 'Triggered skill',
    toolTipContentValue: valueTexts?.join(', ') || '',
    truncatedTagValue: TruncateTagFunction(valueTexts ?? []),
    IsShowTag: false,
    isTagClearable: true,
  }
}
