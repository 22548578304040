import { Combobox, Option, Field, OptionOnSelectData, SelectionEvents, useId } from '@fluentui/react-components'
import { useStyles } from '../McpExperimentFeedbackTrend.styles'

interface IProps {
  comboId: string
  label: string
  options: string[]
  currentFilter: string
  setFilter(filter: string): void
}

export const FilterDropdownWithFreeTextSearch: React.FC<IProps> = ({
  comboId,
  label,
  options,
  currentFilter,
  setFilter,
}) => {
  const styles = useStyles()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value)
    setFilter(event.target.value)
  }

  const handleDropdownChange = (_: SelectionEvents, data: OptionOnSelectData) => {
    setFilter(String(data.optionValue))
  }

  return (
    <div className={styles.filterItem}>
      <Field label={label}>
        <Combobox
          aria-labelledby={useId(comboId)}
          placeholder="Select"
          value={currentFilter}
          onChange={handleInputChange}
          onOptionSelect={handleDropdownChange}
        >
          {options.map((flight: string) => (flight !== currentFilter ? <Option key={flight}>{flight}</Option> : null))}
        </Combobox>
      </Field>
    </div>
  )
}
