import { IRouteMapper } from '../../types/IRouteMapper'
import { IRouteParams } from '../../types/IRouteParams'
import { RouteDataReader } from '../../utils/RouteDataReader'
import { TicketDebugRouteArgs } from './TicketDebugRouteArgs'

const PATH_PARAM_TICKET_ID = 'ticketId'

export const TicketDebugRouteMapper: IRouteMapper<TicketDebugRouteArgs> = {
  mapToParams(args: TicketDebugRouteArgs): IRouteParams {
    return {
      pathParams: {
        [PATH_PARAM_TICKET_ID]: args.ticketId,
      },
    }
  },

  mapToArgs(data: IRouteParams): TicketDebugRouteArgs {
    const path = new RouteDataReader(data.pathParams)
    const ticketId = path.getString(PATH_PARAM_TICKET_ID)
    if (ticketId === undefined) {
      throw new Error(
        `Unable to map route params to 'TicketDebugRouteArgs'. Missing required path param: '${PATH_PARAM_TICKET_ID}'`,
      )
    }

    return {
      ticketId,
    }
  },
}
