import { useState } from 'react'
import { Row, Spacer } from '../../../../Layout'
import { AnyDataStore } from '../../../AnyDataStore'
import { AnyTableToolbarSearchButton } from './AnyTableToolbarSearchButton'
import { AnyTableToolbarSearchInput } from './AnyTableToolbarSearchInput'

export function AnyTableToolbarSearch() {
  const actions = AnyDataStore.useActions()
  const [visible, setVisible] = useState(false)

  const handleOnToggle = () => {
    setVisible((prev) => !prev)
    actions.search('')
  }

  return (
    <Row>
      {visible && <AnyTableToolbarSearchInput />}
      {visible && <Spacer size={4} />}
      <AnyTableToolbarSearchButton visible={visible} onToggle={handleOnToggle} />
    </Row>
  )
}
