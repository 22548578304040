import { makeStyles, Text, tokens } from '@fluentui/react-components'
import { useLoginScreenStore } from '../store'

export function LoginButtonTips() {
  const styles = useStyles()
  const error = useLoginScreenStore((state) => state.loginSnapshot.error)

  if (!error) {
    return null
  }

  return <Text className={styles.text}>{String(error)}</Text>
}

const useStyles = makeStyles({
  text: {
    color: tokens.colorStatusWarningForeground1,
  },
})
