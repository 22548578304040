import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  mentionDropDown: {
    width: '260px',
    maxHeight: '200px',
    overflowY: 'auto',
    scrollbarWidth: 'none',

    boxShadow: '0 5px 10px #0000004d',
    borderRadius: '4px',
    padding: '8px 0',
  },

  status: {
    padding: '6px, 2px',
    cursor: 'pointer',
    zIndex: 1000000,
  },

  option: {
    marginBottom: '10px',
    padding: '6px, 2px',
    cursor: 'pointer',
    zIndex: 1000000,
  },

  optionSelected: {
    backgroundColor: tokens.colorNeutralBackground1Selected,
  },
})
