import { z } from 'zod'

export function getXMLRequestHeaders(request: XMLHttpRequest): Record<string, string> {
  try {
    const customRequest: CustomXMLHttpRequest | undefined = customXMLHttpRequestSchema.safeParse(request).data
    if (!customRequest) {
      return {}
    }

    const headers: Record<string, string> = {}
    customRequest._ajaxData.xh.forEach((header) => {
      headers[header.n] = header.v
    })
    return headers
  } catch {
    return {}
  }
}

interface CustomXMLHttpRequest {
  _ajaxData: {
    xh: Array<{
      n: string
      v: string
    }>
  }
}

const customXMLHttpRequestSchema = z.object({
  _ajaxData: z.object({
    xh: z.array(
      z.object({
        n: z.string(),
        v: z.string(),
      }),
    ),
  }),
})
