import { z } from 'zod'

// Step1:
export interface ApiAttachmentRequest {
  readonly fileName: string
}

export interface ApiAttachmentResponse {
  readonly url: string
  readonly sasToken: string
  readonly errorMessage?: string
}

export const ApiAttachmentResponseSchema = z.object({
  url: z.string(),
  sasToken: z.string(),
  errorMessage: z.string().optional(),
})

// Step2:
export interface ApiAttachmentFileRequest {
  readonly rawUrl: string
  readonly sasToken: string
  readonly file: File
}

// Step3:
export interface ApiAttachmentUrlResponse {
  readonly url: string
  readonly errorMessage?: string
}

export const ApiAttachmentUrlResponseSchema = z.object({
  url: z.string(),
  errorMessage: z.string().optional(),
})
