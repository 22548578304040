import { PromiseSnapshot, PromiseSnapshotDone, PromiseSnapshotError, PromiseSnapshotWaiting } from './PromiseSnapshot'

export class PromiseSnapshots {
  static waiting<T>(promise: Promise<T>): PromiseSnapshotWaiting<T> {
    return {
      status: 'waiting',
      promise,
    }
  }

  static done<T>(data: T, promise?: Promise<T>): PromiseSnapshotDone<T> {
    return {
      status: 'done',
      promise: promise ?? Promise.resolve(data),
      data,
    }
  }

  static error<T>(error: unknown, promise?: Promise<T>): PromiseSnapshotError<T> {
    return {
      status: 'error',
      promise: promise ?? Promise.reject(error),
      error,
    }
  }

  static isEqual<T>(s1: PromiseSnapshot<T>, s2: PromiseSnapshot<T>): boolean {
    return s1.status === s2.status && s1.data === s2.data && s1.error === s2.error && s1.promise === s2.promise
  }

  static map<T, U>(snapshot: PromiseSnapshot<T>, transform: (data: T) => U): PromiseSnapshot<U> {
    switch (snapshot.status) {
      case 'waiting':
        return {
          status: 'waiting',
          promise: snapshot.promise.then(transform).catch((error) => error),
        }
      case 'error':
        return {
          status: 'error',
          promise: snapshot.promise.then(transform).catch((error) => error),
          error: snapshot.error,
        }
      case 'done':
        return {
          status: 'done',
          promise: snapshot.promise.then(transform).catch((error) => error),
          data: transform(snapshot.data),
        }
    }
  }
}
