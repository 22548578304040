import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Overflow,
  OverflowItem,
  useIsOverflowItemVisible,
  useOverflowMenu,
} from '@fluentui/react-components'
import { MoreHorizontalRegular } from '@fluentui/react-icons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useStyles } from './OverflowTabList.styles'
import { Row } from '../Layout'

const getTabValue = (item: ITabItem): string => {
  if (typeof item === 'object') {
    return item.value
  }
  return item
}

interface IOverflowTabListProps<T> {
  items: T[]
  renderItem: (item: T) => JSX.Element
  onSelect: (item: T) => void
  defaultSelected?: T
  minimumVisible?: number
}

type ITabObject = { value: string }

type ITabItem = string | ITabObject
export const OverflowTabList = <T extends ITabItem>({
  items,
  renderItem,
  onSelect,
  minimumVisible = 1,
}: IOverflowTabListProps<T>) => {
  const [selected, setSelected] = useState<T | undefined>(items[0])
  const styles = useStyles()

  useEffect(() => {
    selected && onSelect(selected)
  }, [onSelect, selected, items])

  useEffect(() => {
    items && setSelected(items[0])
  }, [items])

  const handleMenuSelect = useCallback((item: T) => {
    setSelected(item)
  }, [])

  const onClick = useCallback(
    (value: T) => {
      setSelected(items.find((item) => item === value) as T)
    },
    [items],
  )

  return (
    <Overflow minimumVisible={minimumVisible} padding={20}>
      <Row vAlign="center">
        {items.map((item) => (
          <OverflowItem key={getTabValue(item)} id={getTabValue(item)} priority={selected === item ? 2 : 1}>
            <Button
              className={selected === getTabValue(item) ? styles.activedTab : styles.tab}
              onClick={() => onClick(item)}
            >
              {renderItem(item)}
            </Button>
          </OverflowItem>
        ))}
        <OverflowMenu renderItem={renderItem} onSelect={handleMenuSelect} items={items} />
      </Row>
    </Overflow>
  )
}

const OverflowMenu = <T extends ITabItem>({
  items,
  renderItem,
  onSelect,
}: Pick<IOverflowTabListProps<T>, 'onSelect' | 'items' | 'renderItem'>) => {
  const { ref, isOverflowing, overflowCount } = useOverflowMenu<HTMLButtonElement>()
  return (
    isOverflowing && (
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <Button
            appearance="transparent"
            ref={ref}
            icon={<MoreHorizontalRegular />}
            aria-label={`${overflowCount} more tabs`}
            role="tab"
          />
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            {items.map((item) => (
              <OverflowMenuItem key={getTabValue(item)} item={item} onSelect={onSelect} renderItem={renderItem} />
            ))}
          </MenuList>
        </MenuPopover>
      </Menu>
    )
  )
}

type IOverflowMenuItemProps<T> = Pick<IOverflowTabListProps<T>, 'onSelect' | 'renderItem'> & {
  item: T
}

const OverflowMenuItem = <T extends ITabItem>({ item, onSelect, renderItem }: IOverflowMenuItemProps<T>) => {
  const value = useMemo(() => getTabValue(item), [item])
  const isVisible = useIsOverflowItemVisible(value)

  const onClick = useCallback(() => {
    onSelect(item)
  }, [item, onSelect])

  return (
    !isVisible && (
      <MenuItem key={value} onClick={onClick}>
        {renderItem(item)}
      </MenuItem>
    )
  )
}
