import { Times } from '@copilot-dash/core'
import { ITicketData, ITicketSessionData, KustoLogTable, ScenarioNames } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'

const TICKET_EXPIRATION_DAYS_FOR_3S = 28
const TICKET_EXPIRATION_DAYS = 30

export function validateTicketKustoExpiration(
  ticketId: string,
  table: KustoLogTable,
  ticketSession: ITicketSessionData,
): void {
  if (table === KustoLogTable.GwsLog) {
    if (Times.isBeforeNDays(ticketSession.feedbackTime, TICKET_EXPIRATION_DAYS_FOR_3S)) {
      throw TicketError.create('No3SDataDueToExpired', { ticketId, ticketCreationTime: ticketSession.feedbackTime })
    }
    return
  }

  if (Times.isBeforeNDays(ticketSession.feedbackTime, TICKET_EXPIRATION_DAYS)) {
    throw TicketError.create('NoKustoDueToExpired', { ticketId, ticketCreationTime: ticketSession.feedbackTime })
  }
}

export function validateTicketKustoAIF(ticketId: string, table: KustoLogTable, ticket: ITicketData) {
  if (table === KustoLogTable.GwsLog && ticket.scenarioName === ScenarioNames.AIF) {
    throw TicketError.create('No3SDataDueToAifTicket', { ticketId })
  }
}
