import { ApiConversationGroup3Metric } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { ITelemetryMetric } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawConversationGroup3 } from '../actions-raw-conversation/getRawConversationGroup3'

export function getTelemetryMetrics(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<ITelemetryMetric[]> {
  return context.getOrFetch<ITelemetryMetric[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.telemetryMetrics
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.telemetryMetrics = snapshot
    },
    fetch: async () => {
      const data = await getRawConversationGroup3(context, ticketId, messageId).promise

      const rawMetrics = data.conversation?.requests?.[0]?.telemetry?.metrics ?? []
      const metrics = rawMetrics.map(convertItem)
      if (metrics.length > 0) {
        return metrics
      }

      throw new Error('No telemetry metrics found')
    },
  })

  function convertItem(raw: ApiConversationGroup3Metric): ITelemetryMetric {
    return {
      serviceName: raw.serviceName,
      scopeId: raw.scopeId,
      scopeName: raw.scopeName,
      startTime: raw.startTime,
      status: raw.status,
      statusLevel: getStatusLevel(raw),
      path: raw.path,
      latency: raw.latencyMilliseconds,
      input: raw.input,
      output: raw.output,
      raw,
    }
  }

  function getStatusLevel(raw: ApiConversationGroup3Metric): 'success' | 'error' | 'unknown' {
    const status = raw.status?.toLowerCase()
    if (!status) {
      return 'unknown'
    }

    if (status.includes('success') || status.includes('ok') || status.includes('pass') || status.includes('good')) {
      return 'success'
    }

    if (status.includes('error') || status.includes('fail')) {
      return 'error'
    }

    return 'unknown'
  }
}
