import { FC, memo } from 'react'
import { ICellBaseProps } from './types'
import { makeStyles, Skeleton, SkeletonItem, tokens } from '@fluentui/react-components'
import { Row } from '../../../../../../../components/Layout'
import {
  RatioProgressBar,
  RatioProgressBarProps,
} from '../../../../../../../components/RatioProgressBar/RatioProgressBar'

interface IStateProps extends ICellBaseProps {
  tableTotalTicketStatusCount: number | undefined
}
const MIN_WIDTH_PERCENTAGE = 5
export const State: FC<IStateProps> = memo(({ data, tableTotalTicketStatusCount }) => {
  const styles = useStyles()
  if (data.extendInfo.status === 'waiting') {
    return (
      <Skeleton style={{ width: '100%' }} aria-label="Loading Content">
        <SkeletonItem />
      </Skeleton>
    )
  }

  const untriagedCount = data.extendInfo.data
    ? data.extendInfo.data.ticketCount -
      data.extendInfo.data.ticketStatusCount.closed -
      data.extendInfo.data.ticketStatusCount.rootCaused -
      data.extendInfo.data.ticketStatusCount.teamAssigned
    : 0

  const ratioProgressBarData: RatioProgressBarProps = {
    combinedTotal: tableTotalTicketStatusCount ?? 100,
    minWidthPercentage: MIN_WIDTH_PERCENTAGE,
    items: [
      {
        value: data.extendInfo.data?.ticketStatusCount.closed ?? 0,
        color: tokens.colorPaletteGreenBackground2,
        label: 'Closed',
      },
      {
        value: data.extendInfo.data?.ticketStatusCount.rootCaused ?? 0,
        color: tokens.colorCompoundBrandBackground,
        label: 'Root Caused',
      },
      {
        value: data.extendInfo.data?.ticketStatusCount.teamAssigned ?? 0,
        color: tokens.colorPaletteMarigoldBackground3,
        label: 'Team Assigned',
      },
      {
        value: untriagedCount,
        color: tokens.colorNeutralForeground4,
        label: 'Untriaged',
      },
    ],
  }

  return (
    <Row className={styles.container}>
      <RatioProgressBar
        combinedTotal={ratioProgressBarData.combinedTotal}
        minWidthPercentage={ratioProgressBarData.minWidthPercentage}
        items={ratioProgressBarData.items}
      />
    </Row>
  )
})

const useStyles = makeStyles({
  container: {
    display: 'block',
    width: '100%',
  },
})

State.displayName = 'State'
