import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  breakAll2Lines: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    wordBreak: 'normal',
    overflowWrap: 'break-word',
    ...shorthands.overflow('hidden'),
  },
  grayColor: {
    color: tokens.colorScrollbarOverlay,
  },
  markdown: {
    whiteSpace: 'normal',
    '& p': {
      ...shorthands.margin(0),
    },
    '& span': {
      backgroundColor: tokens.colorBrandBackgroundInvertedSelected,
      fontWeight: tokens.fontWeightSemibold,
    },
  },
})
