import { makeStyles } from '@fluentui/react-components'
import { Column } from '../../../../../components/Layout'
import { TicketAsyncView } from '../../common/TicketAsyncView'
import { MetricCard } from './card/MetricCard'

interface IProps {
  readonly ticketId: string
  readonly messageId: string
}

export function TicketTelemetry({ ticketId, messageId }: IProps) {
  const styles = useStyles()
  const snapshot = app.store.use.getTelemetryMetrics(ticketId, messageId)

  return (
    <TicketAsyncView snapshot={snapshot}>
      {(data) => {
        return (
          <Column className={styles.root} gap="gap.small">
            {data.map((metric, index) => {
              return <MetricCard key={index} messageId={messageId} metric={metric} />
            })}
          </Column>
        )
      }}
    </TicketAsyncView>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '16px',
    paddingBottom: '256px',
  },
})
