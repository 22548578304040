import { Button, DrawerHeader, makeStyles, shorthands, Text, tokens } from '@fluentui/react-components'
import { DismissRegular } from '@fluentui/react-icons'
import { Row, Spacer } from '../../../../components/Layout'
import { TicketScreenStore } from '../../TicketScreenStore'
import { TicketScreenDrawerType } from '../../store/IState'

interface IProps {
  readonly type: TicketScreenDrawerType
}

export function TicketScreenDrawerHeader({ type }: IProps) {
  const styles = useStyles()
  const actions = TicketScreenStore.useActions()

  const handleClose = () => {
    actions.closeDrawer()
  }

  let title: string
  switch (type) {
    case TicketScreenDrawerType.TicketContext:
      title = 'Ticket Context'
      break
    case TicketScreenDrawerType.DiscussionPanel:
      title = 'Discussion'
      break
    case TicketScreenDrawerType.ActivityHistory:
      title = 'History'
      break
  }

  return (
    <DrawerHeader className={styles.root}>
      <Row vAlign="center" gap="gap.small">
        <Button appearance="subtle" aria-label="Close" icon={<DismissRegular />} onClick={handleClose} title="Close" />
        <Text size={500} weight="semibold">
          {title}
        </Text>
        <Spacer />
      </Row>
    </DrawerHeader>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '12px 12px',
    margin: 0,
    ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralStroke3),
  },
})
