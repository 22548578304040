import { ApiError } from '@copilot-dash/api'
import { ApiResponseTableAxios } from './ApiResponseTableAxios'
import { ApiResponseTableZod } from './ApiResponseTableZod'

interface IProps {
  readonly error: ApiError
}

export function ApiResponseTable({ error }: IProps) {
  switch (error.data.details.type) {
    case 'zod':
      return <ApiResponseTableZod error={error} cause={error.data.details.cause} />
    case 'axios':
      return <ApiResponseTableAxios error={error} cause={error.data.details.cause} />
    case 'unknown':
      return null
  }
}
