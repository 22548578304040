import { makeStyles, Tab, TabList, TabListProps } from '@fluentui/react-components'
import { ITicketViewDefinition } from '../Store/TicketDebugState'

interface IProps {
  readonly selected: ITicketViewDefinition
  readonly definitions: ITicketViewDefinition[]
  readonly onSelect: (definition: ITicketViewDefinition) => void
}

export function TicketDebugScreenNavigator({ selected, definitions, onSelect }: IProps) {
  const classes = useStyles()

  const handleOnSelect: TabListProps['onTabSelect'] = (event, data) => {
    const value = data.value
    if (typeof value === 'number') {
      const definition = definitions[value]
      if (definition) {
        onSelect(definition)
      }
    }
  }

  return (
    <TabList
      className={classes.root}
      selectedValue={definitions.indexOf(selected)}
      vertical
      onTabSelect={handleOnSelect}
    >
      {definitions.map((item, index) => (
        <Tab key={index} value={index} title={item.title}>
          {item.title}
        </Tab>
      ))}
    </TabList>
  )
}

const useStyles = makeStyles({
  root: {
    width: '200px',
    padding: '16px 0px',

    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
})
