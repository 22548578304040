import { useMemo } from 'react'
import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { TicketScreen } from '../../../screens/ticket/TicketScreen'
import { WorkItemRoute } from './WorkItemRoute'
import { usePromise } from '@copilot-dash/core'
import { ErrorView } from '../../../components/Error'
import { Spinner } from '@fluentui/react-components'

export function WorkItemRoutePage() {
  const args = WorkItemRoute.navigator.useArgs()

  const ticketIdPromise = useMemo(() => {
    return app.store.actions.getTicketIdByWorkItemId({ TeamId: args.teamId, WorkItemId: args.workItemId })
  }, [args.teamId, args.workItemId])
  const ticketIdSnapshot = usePromise(ticketIdPromise)

  const title = `Work Item - ${args.workItemId}`
  return (
    <PageTitle title={title}>
      {ticketIdSnapshot.status === 'waiting' && <Spinner>Loading...</Spinner>}
      {ticketIdSnapshot.status === 'error' && <ErrorView error={ticketIdSnapshot.error} />}
      {ticketIdSnapshot.status === 'done' && <TicketScreen ticketId={ticketIdSnapshot.data.ticketId} />}
    </PageTitle>
  )
}
