import { ITicketRootCauseData } from '@copilot-dash/domain'
import { IDashStoreContext } from '../IDashStoreContext'
import { TicketRootCauseResultConverter } from './converters/TicketRootCauseResultConverter'

export async function getTicketRootCause(context: IDashStoreContext, ticketId: string): Promise<ITicketRootCauseData> {
  const response = await context.api.logCollector.getTicketExistedRootCausingActions({
    ExternalTicketId: ticketId,
  })

  return TicketRootCauseResultConverter.fromApi(response)
}
