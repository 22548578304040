import { useFilterOptions } from '@copilot-dash/store'
import { useMemo } from 'react'
import { IFilterOption } from '../../../../components/Filter/IFilterOption.types'

export const GetTagValueTextFunction = (
  filterCategory: string,
  filterFiledName: string,
  value: string[],
  filterPrefix?: string,
) => {
  const filterOptionsSnapshot = useFilterOptions(filterCategory, filterFiledName, filterPrefix)
  const filterOptions: IFilterOption[] = useMemo(() => {
    switch (filterOptionsSnapshot.status) {
      case 'done':
        return filterOptionsSnapshot.data
      default:
        return []
    }
  }, [filterOptionsSnapshot.data, filterOptionsSnapshot.status])

  const valueTexts = useMemo(() => {
    return value.map((item) => {
      // If the item starts with '!', it means the filter option is negated
      if (item.startsWith('!')) {
        const option = filterOptions.find((option) => option.key === item.slice(1))
        return option ? 'No' : item
      } else {
        const option = filterOptions.find((option) => option.key === item)
        return option ? option.text : item
      }
    })
  }, [filterOptions, value])

  return valueTexts
}
