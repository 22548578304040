import { TableCell, TableRow, Text } from '@fluentui/react-components'

interface IProps {
  readonly ticketId: string
  readonly turnId: string
}

export function RowForRetrieval3S({ ticketId, turnId }: IProps) {
  const ticket = application.store.use.getTicket(ticketId).data
  const tag = ticket?.tag
  if (!tag) {
    return null
  }

  const renderContent = () => {
    if (!tag.sssTriggered) {
      return '3S is not triggered'
    }

    return (
      <span>
        <span>3S is triggered </span>
      </span>
    )
  }

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Retrieval - 3S</Text>
      </TableCell>
      <TableCell>{renderContent()}</TableCell>
    </TableRow>
  )
}
