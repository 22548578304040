import { ITeamData, ProductIds } from '@copilot-dash/domain'
import { useProductTeams } from '@copilot-dash/store'
import { Dropdown, Option, SkeletonItem, Text, makeStyles, tokens } from '@fluentui/react-components'
import { isNil } from 'lodash'
import { memo, useEffect, useMemo, useState } from 'react'
import { Column, Spacer } from '../../../Layout'
import { useTicketFilterStore } from '../../store/store'
import { TeamQueries } from './TeamQueries'

export const SharedQueryTab = memo(() => {
  const styles = useStyles()
  const selectedProductId = useTicketFilterStore((state) => state.selectedProductId)
  const [teamName, setTeamName] = useState<string>('')
  const teamId = app.settings.sharedQueriesTeamId.use()

  const onTeamIdChange = (value: number) => {
    Logger.telemetry.trackEvent('SavedQueries/ChangeTeam', {
      teamId: value,
      productId: selectedProductId,
    })

    const newTeam = {
      ...teamId,
      [selectedProductId.toString()]: value,
    }
    app.settings.sharedQueriesTeamId.set(newTeam)
    setTeamName(teamsOptions.find((team) => team.id === value)?.name ?? '')
  }
  const teamsSnapshot = useProductTeams(selectedProductId ?? ProductIds.M365Chat)

  const teamsOptions: ITeamData[] = useMemo(() => {
    switch (teamsSnapshot.status) {
      case 'done':
        return teamsSnapshot.data
      default:
        return []
    }
  }, [teamsSnapshot.data, teamsSnapshot.status])

  useEffect(() => {
    setTeamName(teamsOptions.find((team) => team.id === teamId[selectedProductId])?.name ?? '')
  }, [teamId, teamsOptions, selectedProductId])

  const currentTeamId = useMemo(() => {
    return teamId[selectedProductId]
  }, [teamId, selectedProductId])

  return (
    <Column fill>
      <Spacer height="20px" />
      <Text className={styles.title}>Team</Text>
      <Spacer height="12px" />
      {teamsSnapshot.status === 'waiting' ? (
        <SkeletonItem />
      ) : (
        <Dropdown
          value={teamName || ''}
          onOptionSelect={(_, data) => data.selectedOptions[0] && onTeamIdChange(Number(data.selectedOptions[0]))}
          appearance="underline"
          placeholder="Select a Team"
          positioning={{ align: 'end', pinned: true, position: 'below' }}
        >
          {[...teamsOptions]
            .sort((a, b) => a.name.toLocaleUpperCase().trim().localeCompare(b.name.toLocaleUpperCase().trim()))
            .map((item) => (
              <Option key={item.id} value={String(item.id)} text={item.name} title={item.name}>
                <span>{item.name}</span>
              </Option>
            ))}
        </Dropdown>
      )}
      <Spacer height="16px" />
      <Text className={styles.title}>Shared Queries</Text>
      {!isNil(currentTeamId) && <TeamQueries teamId={currentTeamId} />}{' '}
    </Column>
  )
})

SharedQueryTab.displayName = 'SharedQueryTab'

const useStyles = makeStyles({
  title: {
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightBold,
  },
  loading: {
    width: '100%',
    textAlign: 'center',
  },
})
