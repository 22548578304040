import { Button, Input, makeStyles, tokens } from '@fluentui/react-components'
import { DismissRegular } from '@fluentui/react-icons'

interface IProps {
  inputValue: string
  setInputValue: (value: string) => void
}

export const CopilotDashDropdownInput: React.FC<IProps> = ({ inputValue, setInputValue }) => {
  const styles = useStyles()

  const handleInputChange = (value: string) => {
    setInputValue(value)
  }

  const handleInputCleanClick = () => {
    setInputValue('')
  }

  return (
    <Input
      className={styles.input}
      appearance="underline"
      onChange={(_, data) => handleInputChange(data.value)}
      value={inputValue}
      contentAfter={
        inputValue.length > 0 ? (
          <Button icon={<DismissRegular />} appearance="subtle" size="small" onClick={handleInputCleanClick} />
        ) : null
      }
    />
  )
}

const useStyles = makeStyles({
  input: {
    backgroundColor: tokens.colorNeutralCardBackgroundPressed,
    borderRadius: '4px',
    margin: '6px',
  },
})
