import { ReactNode } from 'react'
import { ErrorView } from '../../../components/Error'

interface IProps {
  readonly error: unknown
}

export function TicketScreenLayoutForError(props: IProps): ReactNode {
  return <ErrorView error={props.error} />
}
