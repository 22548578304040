import { ApiUpdateTicketStatusInfoRequest } from '@copilot-dash/api'
import { IUpdateTicketStatusInfoData } from '@copilot-dash/domain'

export class TicketStatusInfoConverter {
  static toAPIData(data: IUpdateTicketStatusInfoData): ApiUpdateTicketStatusInfoRequest {
    return {
      WorkItemId: data.workItemId,
      ExternalTicketId: data.ticketId,
      Status: data.status,
      Priority: data.priority,
      TeamArea: data.areaPath,
      IssueList:
        data.issueList?.map((issue) => ({
          IssueId: issue.issueId,
          VsoAccount: issue.vsoAccount,
          RootCauseTitle: issue.title,
        })) ?? [],
      Reasoning: data.reasoning,
      ClosedComment: data.closedComment,
      AssignTo: data.assignTo,
      CustomTags: data.customTags,
    }
  }
}
