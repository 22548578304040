import { I3sLogDataItem, I3sLogData } from '@copilot-dash/domain'
import { makeStyles, Tree, TreeItem, TreeItemLayout } from '@fluentui/react-components'
import { SssLogViewTreeItemKusto } from './SssLogViewTreeItemKusto'
import { SssLogViewTreeItemOfflineGeneric } from './SssLogViewTreeItemOfflineGeneric'
import { SssLogViewTreeItemOfflineGrounding } from './SssLogViewTreeItemOfflineGrounding'
import { SssLogViewTreeItemOnlineGeneric } from './SssLogViewTreeItemOnlineGeneric'
import { SssLogViewTreeItemOnlineGrounding } from './SssLogViewTreeItemOnlineGrounding'
import { SssLogViewTreeTitle } from './SssLogViewTreeTitle'

interface IProps {
  readonly data: I3sLogData
  readonly item: I3sLogDataItem
  readonly index: number
}

export function SssLogViewTree({ data, item, index }: IProps) {
  const styles = useStyles()

  return (
    <Tree defaultOpenItems={index === 0 ? ['root'] : []}>
      <TreeItem itemType="branch" value="root">
        <TreeItemLayout>
          <SssLogViewTreeTitle data={data} item={item} index={index} />
        </TreeItemLayout>

        <Tree className={styles.root}>
          <SssLogViewTreeItemKusto data={data} item={item} />
          <SssLogViewTreeItemOfflineGeneric data={data} item={item} />
          <SssLogViewTreeItemOnlineGeneric data={data} item={item} />
          <SssLogViewTreeItemOfflineGrounding data={data} item={item} />
          <SssLogViewTreeItemOnlineGrounding data={data} item={item} />
        </Tree>
      </TreeItem>
    </Tree>
  )
}

const useStyles = makeStyles({
  root: {
    '& .fui-TreeItem .fui-Tree': {
      width: '100%',
      cursor: 'default',

      '&:hover': {
        backgroundColor: 'unset',
      },
    },

    '& .fui-TreeItem .fui-Tree .fui-TreeItem .fui-TreeItemLayout': {
      marginLeft: '-24px',
    },

    '& .fui-TreeItem .fui-Tree .fui-TreeItem .fui-TreeItemLayout .fui-TreeItemLayout__main': {
      width: '100%',
    },
  },
})
