import { Button } from '@fluentui/react-components'

interface IProps {
  onClose?: () => void
}

export function ErrorViewPopoverBodyActionsGotItButton({ onClose }: IProps) {
  return (
    <Button shape="circular" onClick={onClose}>
      Got it
    </Button>
  )
}
