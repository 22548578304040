import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {},
  dismissButton: {
    color: tokens.colorNeutralForeground1,
    height: tokens.lineHeightBase100,
  },
  tagStyle: {
    cursor: 'pointer',
    borderRadius: '8px',
    margin: '0px 8px 4px 0px ',
    maxWidth: '100%',
  },
  textStyle: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
  },

  dismissTextStyle: {
    marginRight: '10px',
    color: tokens.colorNeutralForeground1,
  },
  dropdownContainer: {
    maxWidth: '100%',
    minWidth: '0',
    width: '150px',
  },
  dropdown: {
    maxWidth: '100%',
    minWidth: '0',
    width: '150px',
  },
  listbox: {
    maxWidth: '100%',
    minWidth: '0',
    width: '150px',
    maxHeight: '500px',
    overflowY: 'auto',
  },
  duplicateTag: {
    color: tokens.colorStatusDangerBorderActive,
  },
  addButton: {
    color: tokens.colorBrandForegroundLink,
    padding: '0px',
    marginLeft: '-8px',
    whiteSpace: 'nowrap',
  },
  addColor: {
    color: tokens.colorBrandForegroundLink,
  },
  addIcon: {
    alignItems: 'end',
    alignContent: 'end',
  },
  optionCheckIcon: {
    padding: '0px',
    margin: '0px',
  },
  optionContainer: {
    padding: '4px',
    margin: '0px',
  },
  optionText: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
  },
})
