import { SearchBox, SearchBoxProps } from '@fluentui/react-components'
import { AnyDataStore } from '../../../AnyDataStore'

export function AnyTableToolbarSearchInput() {
  const keyword = AnyDataStore.use((state) => state.table.keyword)
  const actions = AnyDataStore.useActions()

  const handleOnChanged: SearchBoxProps['onChange'] = (_, data) => {
    const value = data.value.trim()
    actions.search(value)
  }

  return (
    <SearchBox
      title="Type to search results"
      value={keyword ?? ''}
      placeholder="Search"
      onChange={handleOnChanged}
      type="search"
      autoFocus
      dismiss={null}
    />
  )
}
