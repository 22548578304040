import { Row } from '../../../../Layout'
import { AnyJsonToolbarWrapSwitch } from './AnyJsonToolbarWrapSwitch'

export function AnyJsonToolbar() {
  return (
    <Row vAlign="center">
      <AnyJsonToolbarWrapSwitch />
    </Row>
  )
}
