import { IDashStoreContext } from '../../IDashStoreContext'
import { IMcpExperimentFeedbackResponse } from '@copilot-dash/domain'
import {
  getMcpExperimentFeedbackAllFeedbacksTable,
  getMcpExperimentFeedbackControlChart,
  getMcpExperimentFeedbackFeedbackByCanvasTable,
  getMcpExperimentFeedbackSatRatioTable,
  getMcpExperimentFeedbackTreatmentChart,
  getMcpExperimentFeedbackByPrompt,
} from './tools/getChartsAndTables'

export async function getMcpExperimentFeedback(
  context: IDashStoreContext,
  startTime: string,
  endTime: string,
  flightName: string,
  timeWindow: string,
  deployRings: string[],
  clientRings: string[],
  clientPlatforms: string[],
): Promise<IMcpExperimentFeedbackResponse> {
  const data = await context.api.logCollector.getMcpExperimentFeedbackResponse({
    startTime,
    endTime,
    flightName,
    timeWindow,
    deployRings: deployRings.join(','),
    clientRings: clientRings.join(','),
    clientPlatforms: clientPlatforms.join(','),
  })
  return {
    controlFeedbacks: getMcpExperimentFeedbackControlChart(data.feedbackRecords),
    treatmentFeedbacks: getMcpExperimentFeedbackTreatmentChart(data.feedbackRecords),
    allFeedbacks: getMcpExperimentFeedbackAllFeedbacksTable(data.feedbackRecords),
    satRatioFeedbacks: getMcpExperimentFeedbackSatRatioTable(data.feedbackRecords),
    byCanvasFeedbacks: getMcpExperimentFeedbackFeedbackByCanvasTable(data.feedbackRecords),
    byPromptFeedbacks: getMcpExperimentFeedbackByPrompt(data.feedbackByPromptRecords),
  }
}
