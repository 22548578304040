import { isArray, isBoolean, isDate, isNumber, isString, map, toNumber } from 'lodash'

export class RouteDataUtils {
  static parseAsString(value: unknown): string | undefined {
    if (value === undefined || value === null) {
      return
    }
    if (value === '') {
      return ''
    }

    if (isString(value) || isNumber(value) || isBoolean(value)) {
      return String(value)
    }

    if (isArray(value) && value.length > 0) {
      return this.parseAsString(value[0])
    }

    return
  }

  static parseAsNumber(value: unknown): number | undefined {
    if (value === undefined || value === null || value === '') {
      return
    }

    if (isNumber(value) && !isNaN(value)) {
      return value
    }

    if (isString(value)) {
      const number = toNumber(value)
      if (!isNaN(number)) {
        return number
      }
    }

    return
  }

  static parseAsBoolean(value: unknown): boolean | undefined {
    if (value === undefined || value === null || value === '') {
      return
    }

    if (isBoolean(value)) {
      return value
    }

    switch (String(value).toLowerCase()) {
      case 'true':
        return true
      case 'false':
        return false
      default:
        return undefined
    }
  }

  static parseAsDate(value: unknown): Date | undefined {
    if (value === undefined || value === null || value === '') {
      return
    }

    if (isDate(value) || isString(value) || isNumber(value)) {
      const date = new Date(value)
      if (!isNaN(date.getTime()) && date.getTime() > 0) {
        return date
      }
    }

    return
  }

  static parseAsEnum<T extends string>(value: unknown, enumType: Record<string, T>): T | undefined {
    if (value === undefined || value === null || value === '') {
      return
    }

    if (isString(value) && Object.values(enumType).includes(value as T)) {
      return value as T
    }

    return
  }

  static parseAsStringArray(value: unknown): Array<string> | undefined {
    if (value === undefined || value === null) {
      return
    }
    if (value === '') {
      return []
    }
    if (isString(value)) {
      return [value]
    }

    if (isArray(value)) {
      return map(value, (v) => this.parseAsString(v)).filter((v) => v !== undefined) as Array<string>
    }

    return
  }

  static parseAsEnumArray<T extends string>(value: unknown, enumType: Record<string, T>): Array<T> | undefined {
    if (value === undefined || value === null || value === '') {
      return
    }

    if (isArray(value)) {
      const parsedArray: Array<T> = []
      for (const v of value) {
        const parsedValue = this.parseAsEnum(v, enumType)
        if (parsedValue !== undefined) {
          parsedArray.push(parsedValue)
        }
      }
      return parsedArray
    }

    return [this.parseAsEnum(value, enumType)].filter((v) => v !== undefined) as Array<T>
  }
}
