import { ITicketSessionData } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { get3sOnlineErrorCode } from '../utils/get3sOnlineErrorCode'
import { getConversationOfflineErrorCode } from '../utils/getConversationOfflineErrorCode'

/**
 * Conversation
 */
export function validateTicketDiagnosticDataForConversation(
  ticketId: string,
  messageId: string | null,
  data: ITicketSessionData,
) {
  const errorCode = getConversationOfflineErrorCode(data, messageId)
  if (errorCode) {
    const error = TicketError.diagnostic(errorCode, ticketId)
    if (error) {
      throw error
    }
  }

  return undefined
}

/**
 * 3S
 */
export function validateTicketDiagnosticDataFor3SSearch(ticketId: string, messageId: string, data: ITicketSessionData) {
  const errorCode = get3sOnlineErrorCode(data, messageId)
  if (errorCode) {
    const error = TicketError.diagnostic(errorCode, ticketId)
    if (error) {
      throw error
    }
  }
}

/**
 * Offline
 */
export function validateOfflineDiagnosticCode(ticketId: string, messageId: string, data: ITicketSessionData) {
  const interaction = data.interactions.find((interaction) => interaction.messageId === messageId)
  if (!interaction) {
    return
  }

  const codes = interaction.diagnostic?.offline?.map((item) => item.statusCode)
  if (!codes) {
    return
  }

  codes.forEach((code) => {
    const error = TicketError.diagnostic(code, ticketId)
    if (error) {
      throw error
    }
  })
}
