import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  header: {
    marginBottom: '12px',
  },

  accordion: {
    backgroundColor: tokens.colorNeutralCardBackground,
    ...shorthands.padding('4px', '4px'),
  },
  border: {
    borderRadius: tokens.borderRadiusLarge,
    ...shorthands.border('2px', 'dashed', tokens.colorPaletteBlueBorderActive),
  },

  justification: {
    marginTop: '16px',
  },

  action: {
    marginTop: '16px',
    marginBottom: '16px',
  },

  addBtn: {
    ...shorthands.border('1px', 'solid', tokens.colorPaletteBlueBorderActive),
    color: tokens.colorCompoundBrandForeground1,

    '&:hover': {
      ...shorthands.border('1px', 'solid', tokens.colorCompoundBrandForeground1Hover),
      color: tokens.colorCompoundBrandForeground1Hover,
    },
  },

  area: {
    flexGrow: 1,
    flexShrink: 0,
  },

  accordionHeader: {
    alignItems: 'start',
    paddingTop: '8px',
  },
})
