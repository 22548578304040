import { ApiMcpExperimentFeedback, ApiMcpExperimentFeedbackByPrompt } from '@copilot-dash/api'
import {
  IMcpExperimentAllFeedbackRecord,
  IMcpExperimentFeedbackByCanvasRecord,
  IMcpExperimentFeedbackByPrompt,
  IMcpExperimentSatRatioRecord,
} from '@copilot-dash/domain'
import { groupBy } from 'lodash'

export function getMcpExperimentFeedbackControlChart(data: ApiMcpExperimentFeedback[]): ApiMcpExperimentFeedback[] {
  return data.filter((record: ApiMcpExperimentFeedback) => record.flightValue === 'Control')
}

export function getMcpExperimentFeedbackTreatmentChart(data: ApiMcpExperimentFeedback[]): ApiMcpExperimentFeedback[] {
  return data.filter((record: ApiMcpExperimentFeedback) => record.flightValue === 'Treatment')
}

export function getMcpExperimentFeedbackAllFeedbacksTable(
  data: ApiMcpExperimentFeedback[],
): IMcpExperimentAllFeedbackRecord[] {
  let pos = 0,
    neg = 0
  data.forEach((record: ApiMcpExperimentFeedback) => {
    pos += record.feedbackType === 'Positive' ? 1 : 0
    neg += record.feedbackType === 'Negative' ? 1 : 0
  })

  const output: IMcpExperimentAllFeedbackRecord[] = []
  output.push({ feedbackType: 'Positive', count: pos })
  output.push({ feedbackType: 'Negative', count: neg })

  return output
}

export function getMcpExperimentFeedbackSatRatioTable(
  data: ApiMcpExperimentFeedback[],
): IMcpExperimentSatRatioRecord[] {
  const grouped = groupBy(data, 'flightValue')
  const output: IMcpExperimentSatRatioRecord[] = []

  for (const key in grouped) {
    const records = grouped[key]
    let pos = 0,
      neg = 0
    if (!records) {
      continue
    }
    records.forEach((record: ApiMcpExperimentFeedback) => {
      pos += record.feedbackType === 'Positive' ? 1 : 0
      neg += record.feedbackType === 'Negative' ? 1 : 0
    })

    const satRatio = pos + neg === 0 ? 0 : pos / (pos + neg)
    output.push({ flightValue: key, satRatio: satRatio.toFixed(2), totalFeedback: pos + neg })
  }

  return output
}

export function getMcpExperimentFeedbackFeedbackByCanvasTable(
  data: ApiMcpExperimentFeedback[],
): IMcpExperimentFeedbackByCanvasRecord[] {
  const RECAP_KEY = 'recap'
  const MEETING_KEY = 'meeting'
  const RECAP = 'R'
  const MEETING = 'M'

  const groupedByFlight = groupBy(data, 'flightValue')
  const output: IMcpExperimentFeedbackByCanvasRecord[] = []

  for (const flightKey in groupedByFlight) {
    const flightGroup = groupedByFlight[flightKey]

    const groupedBySource = groupBy(flightGroup, 'feedbackSource')

    for (const sourceKey in groupedBySource) {
      const records = groupedBySource[sourceKey]
      if (!records) {
        continue
      }
      const { pos, neg } = records.reduce(
        (acc, record) => {
          if (record.feedbackType === 'Positive') {
            acc.pos += 1
          } else if (record.feedbackType === 'Negative') {
            acc.neg += 1
          }
          return acc
        },
        { pos: 0, neg: 0 },
      )

      const total = pos + neg
      const satRatio = pos / total

      if (sourceKey.toLowerCase().includes(RECAP_KEY)) {
        output.push({ canvas: RECAP, satRatio: satRatio.toFixed(2), flightValue: flightKey, totalFeedback: total })
      } else if (sourceKey.toLowerCase().includes(MEETING_KEY)) {
        output.push({ canvas: MEETING, satRatio: satRatio.toFixed(2), flightValue: flightKey, totalFeedback: total })
      }
    }
  }

  return output
}

export function getMcpExperimentFeedbackByPrompt(
  data: ApiMcpExperimentFeedbackByPrompt[],
): IMcpExperimentFeedbackByPrompt[] {
  return data.map((record) => ({
    promptName: record.promptName,
    satRatio: record.satRatio,
    totalFeedback: record.totalFeedback,
    isChatSignificant: record.isChatSignificant ? record.isChatSignificant === 'True' : undefined,
    flightValue: record.flightValue,
  }))
}
