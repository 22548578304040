import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { motion } from 'framer-motion'
import { useEffect, useMemo, useState } from 'react'
import { Column } from '../../../../components/Layout'
import { Scrollbar } from '../../../../components/Scrollbar/Scrollbar'
import { TicketScreenStore } from '../../TicketScreenStore'
import { TicketScreenOutlineTab } from '../../store/IState'
import { TicketOutlineCallFlow } from './call-flow/TicketOutlineCallFlow'
import { TicketOutlineConversation } from './conversation/TicketOutlineConversation'

export function TicketOutlineBody() {
  const styles = useStyles()

  const tabs = useMemo(() => {
    return Object.values(TicketScreenOutlineTab)
  }, [])

  const selectedTab = TicketScreenStore.use((state) => state.selectedOutlineTab)
  const selectedIndex = tabs.indexOf(selectedTab)

  return (
    <Column className={styles.root}>
      {tabs.map((tab, index) => {
        return <LazyTabContent tab={tab} key={index} tabIndex={index} selectedTabIndex={selectedIndex} />
      })}
    </Column>
  )
}

function LazyTabContent({
  tab,
  tabIndex,
  selectedTabIndex,
}: {
  tab: TicketScreenOutlineTab
  tabIndex: number
  selectedTabIndex: number
}) {
  const selected = tabIndex === selectedTabIndex
  const styles = useStyles()
  const [initialized, setInitialized] = useState(tabIndex === selectedTabIndex)

  useEffect(() => {
    if (selected && !initialized) {
      setInitialized(true)
    }
  }, [initialized, selected, tab])

  const translateX = `${(tabIndex - selectedTabIndex) * 100}%`

  return (
    <motion.div
      className={mergeClasses(`LazyTabContent-${tabIndex}`, styles.content)}
      style={{
        pointerEvents: selected ? 'auto' : 'none',
      }}
      animate={{
        opacity: selected ? 1 : 0,
        scale: selected ? 1 : 0.1,
        transform: `translateX(${translateX})`,
      }}
      transition={{
        ease: 'easeInOut',
      }}
    >
      <Scrollbar className={styles.contentScrollBar}>
        {(() => {
          if (!initialized && !selected) {
            return null
          }

          switch (tab) {
            case TicketScreenOutlineTab.Conversation:
              return <TicketOutlineConversation />
            case TicketScreenOutlineTab.CallFlow:
              return <TicketOutlineCallFlow />
          }
        })()}
      </Scrollbar>
    </motion.div>
  )
}

const useStyles = makeStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  contentScrollBar: {
    height: '100%',
    width: '100%',
  },
})
