import { z } from 'zod'

export interface ApiDSRTicketResponse {
  readonly feedbackItemId?: string
  readonly externalTicketId?: string
}

export const apiDSRTicketResponseSchema = z.object({
  feedbackItemId: z.string().optional(),
  externalTicketId: z.string().optional(),
})
