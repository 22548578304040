import {
  ApplicationInsights,
  IEventTelemetry,
  IMetricTelemetry,
  IPageViewTelemetry,
} from '@microsoft/applicationinsights-web'
import { IDashLoggerContext } from './interfaces/IDashLoggerContext'
import { IDashLoggerTelemetry } from './interfaces/IDashLoggerTelemetry'

export class DashLoggerTelemetry implements IDashLoggerTelemetry {
  private readonly clients: ApplicationInsights[]
  private readonly context: IDashLoggerContext

  constructor(clients: ApplicationInsights[], context: IDashLoggerContext) {
    this.clients = clients
    this.context = context
  }

  trackView(name: unknown, properties: unknown & { duration: number }): void {
    const telemetry: IPageViewTelemetry = {
      name: String(name),
      properties: {
        ...properties,
        ...this.context.properties,
      },
    }

    this.context.prepare()
    this.clients.forEach((client) => client.trackPageView(telemetry))
  }

  trackMetric(name: string, properties: object & { duration: number }): void {
    const telemetry: IMetricTelemetry = {
      name: name,
      average: properties.duration,
      properties: {
        ...properties,
        ...this.context.properties,
      },
    }

    this.context.prepare()
    this.clients.forEach((client) => client.trackMetric(telemetry, this.context.properties))
  }

  trackEvent(name: unknown, properties?: object): void {
    const telemetry: IEventTelemetry = {
      name: String(name),
      properties: {
        ...properties,
        ...this.context.properties,
      },
    }

    this.context.prepare()
    this.clients.forEach((client) => client.trackEvent(telemetry))
  }
}
