import { DecoratorNode, NodeKey } from 'lexical'

type SerializedImageNode = {
  src: string
  alt: string
  type: 'image'
  version: 1
}

export class ImageNode extends DecoratorNode<JSX.Element> {
  __src: string
  __alt: string

  static override getType() {
    return 'image'
  }

  static override clone(node: ImageNode) {
    return new ImageNode(node.__src, node.__alt, node.__key)
  }

  setSrc(src: string) {
    const writable = this.getWritable()
    writable.__src = src
  }

  constructor(src: string, alt: string, key?: NodeKey) {
    super(key)
    this.__src = src
    this.__alt = alt
  }

  override exportJSON(): SerializedImageNode {
    return {
      src: this.__src,
      alt: this.__alt,
      type: 'image',
      version: 1,
    }
  }

  static override importJSON(serializedNode: SerializedImageNode): ImageNode {
    const { src, alt } = serializedNode
    return new ImageNode(src, alt)
  }

  override createDOM() {
    const img = document.createElement('img')
    img.src = this.__src
    img.alt = this.__alt
    img.className = 'editor-img'
    return img
  }

  override updateDOM(prevNode: ImageNode, dom: HTMLElement) {
    if (dom instanceof HTMLImageElement) {
      if (prevNode.__src !== this.__src) {
        dom.src = this.__src
      }
      if (prevNode.__alt !== this.__alt) {
        dom.alt = this.__alt
      }
    }
    return true
  }

  override decorate(): JSX.Element {
    return <img src={this.__src} alt={this.__alt} key={this.__key} className="editor-img" />
  }
}

export function $createImageNode(src: string, alt: string): ImageNode {
  return new ImageNode(src, alt)
}
