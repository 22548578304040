import { Dropdown, Field, OptionOnSelectData, SelectionEvents, Option, makeStyles } from '@fluentui/react-components'

interface IProps {
  label: string
  options: string[]
  currentFilter: string
  setFilter(filter: string): void
}

const useStyles = makeStyles({
  filterItem: {
    display: 'inline-block',
    marginRight: 0,
    verticalAlign: 'top',
    marginLeft: 'auto',
  },
  filterDropdownItem: {
    display: 'block',
  },
})

export const FilterDropdown: React.FC<IProps> = ({ label, options, currentFilter, setFilter }) => {
  const styles = useStyles()

  const handleChange = (_: SelectionEvents, data: OptionOnSelectData) => {
    setFilter(String(data.optionValue))
  }

  return (
    <div className={styles.filterItem}>
      <Field label={label}>
        <Dropdown
          className={styles.filterDropdownItem}
          placeholder="Select"
          onOptionSelect={handleChange}
          defaultValue={currentFilter}
        >
          {options.map((window: string) => (window !== currentFilter ? <Option key={window}>{window}</Option> : null))}
        </Dropdown>
      </Field>
    </div>
  )
}
