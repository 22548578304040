import { IProductData } from '@copilot-dash/domain'

export interface IGlobalProductState {
  readonly selectors: IGlobalProductSelectorData[]
}

export interface IGlobalProductSelectorData {
  readonly id: string
  readonly value?: string
  readonly onChange?: (value: IProductData) => void
  readonly disabled?: boolean
  readonly isItemDisabled?: (value: IProductData) => boolean
}

export function createGlobalProductState(): IGlobalProductState {
  return {
    selectors: [],
  }
}
