import { isEqual } from 'lodash'
import { useMemo, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { IRouteQueryParams } from '../../../types/IRouteParams'
import { generateParamsQueryBySearchParams } from './generateParamsQueryBySearchParams'

export function useCurrentRouteParamsQuery(): IRouteQueryParams {
  const [searchParams] = useSearchParams()
  const cacheRef = useRef<IRouteQueryParams | null>(null)

  return useMemo(() => {
    const newValue = generateParamsQueryBySearchParams(searchParams)
    const oldValue = cacheRef.current

    if (isEqual(newValue, oldValue)) {
      return cacheRef.current!
    }

    return (cacheRef.current = newValue)
  }, [searchParams])
}
