import { INewTicketData, ColumnKey, ColumnIdNaColumnIdNameMapping } from '@copilot-dash/domain'
import { ExtendedTableColumnDefinition, createExtendedTableColumn } from '../Table/ExtendedTableColumn'
import { ResponsiveEmailColumnComponent } from './ResponsiveEmailColumnComponent'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'

export const ColumnConfigEmailAddress = (email?: string): ExtendedTableColumnDefinition<INewTicketData> =>
  createExtendedTableColumn<INewTicketData>({
    columnId: ColumnKey.Email,
    columnName: ColumnIdNaColumnIdNameMapping[ColumnKey.Email],
    isResizable: true,
    flex: 1,
    columnSize: {
      minWidth: 80,
      idealWidth: 190,
      padding: 0,
    },
    renderHeaderCell: () => <TableCellLayoutWithStyles truncate>Email</TableCellLayoutWithStyles>,
    renderCell: (item: INewTicketData) => (
      <TableCellLayoutWithStyles truncate>
        {item.ticketId && <ResponsiveEmailColumnComponent item={item} keyWords={email} />}
      </TableCellLayoutWithStyles>
    ),
  })
