import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  switchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '153px',
    height: '32px',
    borderRadius: '8px',
    position: 'relative',
    backgroundColor: tokens.colorNeutralBackground4,
    border: `1px solid ${tokens.colorNeutralBackground4}`,
  },
  button: {
    padding: '5px',
    fontSize: tokens.fontSizeBase200,
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.5s ease, color 0.5s ease, margin 0.5s ease',
    position: 'relative',
    zIndex: 1,
    borderRadius: '8px',
    '&:first-child': {
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
    },
    '&:last-child': {
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },
  active: {
    backgroundColor: tokens.colorNeutralBackground1,
    color: tokens.colorNeutralForeground1,
    fontWeight: tokens.fontWeightMedium,
    zIndex: 2,
  },
  inactive: {
    color: tokens.colorNeutralForeground1,
  },
  leftButton: {
    flex: 1.5,
  },
  rightButton: {
    flex: 1,
  },
  leftButtonActive: {
    marginRight: '4px',
  },
  rightButtonActive: {
    marginLeft: '4px',
  },
})
