import { useLayoutEffect, useRef } from 'react'

export enum ResizeDimensions {
  Width = 'width',
  Height = 'height',
  Both = 'both',
}

export function useResizeObserver<T extends HTMLDivElement>(
  dimensions: ResizeDimensions,
  onResize: (width: number, height: number) => void,
) {
  const ref = useRef<T>(null)
  const width = useRef<number>(0)
  const height = useRef<number>(0)

  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }

    const updateAndNotify = (entry: ResizeObserverEntry) => {
      width.current = entry.contentRect.width
      height.current = entry.contentRect.height
      onResize(entry.contentRect.width, entry.contentRect.height)
    }

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        switch (dimensions) {
          case ResizeDimensions.Width:
            width.current !== entry.contentRect.width && updateAndNotify(entry)
            break
          case ResizeDimensions.Height:
            height.current !== entry.contentRect.height && updateAndNotify(entry)
            break
          case ResizeDimensions.Both:
            if (width.current !== entry.contentRect.width || height.current !== entry.contentRect.height) {
              updateAndNotify(entry)
            }
            break
        }
      })
    })

    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [onResize, dimensions])

  return ref
}
