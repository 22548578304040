import { IDashDialogInstance } from '../store/DashDialogActions'
import { DashDialogItemForBottom } from './DashDialogItemForBottom'
import { DashDialogItemForCenter } from './DashDialogItemForCenter'

interface IProps {
  readonly id: string
  readonly instance: IDashDialogInstance
}

export function DashDialogItem({ id, instance }: IProps) {
  switch (instance.position ?? 'center') {
    case 'center':
      return <DashDialogItemForCenter id={id} instance={instance} />
    case 'bottom':
      return <DashDialogItemForBottom id={id} instance={instance} />
  }
}
