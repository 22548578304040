import Steps from 'rc-steps'
import 'rc-steps/assets/index.css'
import { FC, memo } from 'react'
import { getCurrentStepIndex, TICKET_STEP_STAGES, TICKET_STEP_STAGES_WITHOUT_ROOT_CAUSE } from '../utils'
import { useStyles } from './TicketStatusStep.styles'

interface ITicketStatusStepProps<T extends boolean> {
  withoutRootCause?: T
  currentStep: T extends true
    ? (typeof TICKET_STEP_STAGES_WITHOUT_ROOT_CAUSE)[number]
    : (typeof TICKET_STEP_STAGES)[number]
}

export const TicketStatusStep: FC<ITicketStatusStepProps<boolean>> = memo(
  ({ withoutRootCause = false, currentStep }) => {
    const styles = useStyles()
    const steps = withoutRootCause ? TICKET_STEP_STAGES_WITHOUT_ROOT_CAUSE : TICKET_STEP_STAGES
    const current = getCurrentStepIndex(currentStep, withoutRootCause)
    return (
      <Steps
        className={styles.steps}
        current={current}
        items={steps.map((str, index) => ({ title: str, status: index <= current ? 'process' : 'wait' }))}
      ></Steps>
    )
  },
)

TicketStatusStep.displayName = 'TicketStatusStep'
