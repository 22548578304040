import { makeStyles } from '@fluentui/react-components'
import { Row } from '../../../../components/Layout'
import { TicketScreenBarLeadingBack } from './TicketScreenBarLeadingBack'
import { TicketScreenBarLeadingTitle } from './TicketScreenBarLeadingTitle'

export function TicketScreenBarLeading() {
  const styles = useStyles()

  return (
    <Row className={styles.root} vAlign="center">
      <TicketScreenBarLeadingBack />
      <TicketScreenBarLeadingTitle />
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    gap: '8px',
  },
})
