import { ErrorConfig } from '../ErrorConfig'
import { IErrorConfig } from '../interfaces/IErrorConfig'

export function getErrorConfigs(error: unknown): IErrorConfig[] {
  const builders: IErrorConfig[] = [new ErrorConfig(error)]

  let index = 0
  while (index < builders.length) {
    const next = builders[index]?.triage?.()
    if (next) {
      builders.push(next)
    }
    index++
  }

  return builders.reverse()
}
