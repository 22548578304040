import * as React from 'react'
import { Helmet } from 'react-helmet'

interface IProps {
  readonly title: string | undefined | null
  readonly children: React.ReactNode
}

export const PageTitle: React.FC<IProps> = ({ title, children }) => {
  let finalTitle = 'M365 Copilot Dash'
  if (title) {
    finalTitle = `${title} | M365 Copilot Dash`
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{finalTitle}</title>
      </Helmet>
      {children}
    </React.Fragment>
  )
}
