import { z } from 'zod'
import { ApiCommentItem, apiCommentItemSchema } from './ApiCommentsResponse'

export interface ApiUpsertCommentResponse {
  readonly comment: ApiCommentItem
  readonly errorMessage?: string
}

export const apiUpsertCommentResponseSchema = z.object({
  comment: apiCommentItemSchema,
  errorMessage: z.string().optional(),
})
