import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  steps: {
    '& .rc-steps-item-process .rc-steps-item-icon': {
      backgroundColor: tokens.colorPaletteCornflowerBorderActive,
      ...shorthands.borderColor(tokens.colorPaletteCornflowerBorderActive),
    },
    '& .rc-steps-item-wait  .rc-steps-item-icon': {
      backgroundColor: tokens.colorNeutralBackground4,
      ...shorthands.borderColor(tokens.colorNeutralBackground4),
    },
    '& .rc-steps-item-wait .rc-steps-item-icon > .rc-steps-icon': {
      color: tokens.colorNeutralForeground1,
    },

    '& .rc-steps-item-process .rc-steps-item-title': {
      color: tokens.colorNeutralForeground1,
      fontWeight: tokens.fontWeightSemibold,
    },

    '& .rc-steps-item-wait .rc-steps-item-title': {
      color: tokens.colorNeutralForeground1,
      fontWeight: tokens.fontWeightSemibold,
    },
  },
})
