import { IDashEnvironment } from '@copilot-dash/core'
import { IApplicationInsights } from '@microsoft/applicationinsights-web'
import { IDashLoggerContext } from './interfaces/IDashLoggerContext'
import { IDashLoggerOptions } from './interfaces/IDashLoggerOptions'
import { ITelemetryCustomProperties } from './telemetry'

export class DashLoggerContext implements IDashLoggerContext {
  private readonly clients: IApplicationInsights[]
  private readonly options: IDashLoggerOptions
  private readonly basicProperties: ITelemetryCustomProperties

  private currentAccountId: string | undefined

  constructor(clients: IApplicationInsights[], options: IDashLoggerOptions) {
    this.clients = clients
    this.options = options
    this.basicProperties = this.getProperties()
  }

  get environment(): IDashEnvironment {
    return this.options.environment
  }

  get properties(): ITelemetryCustomProperties {
    return this.basicProperties
  }

  prepare(): void {
    this.prepareAccount()
  }

  private prepareAccount(): void {
    const accountId = this.options.getAccountId()
    if (accountId === this.currentAccountId) {
      return
    }

    this.currentAccountId = accountId
    if (accountId) {
      this.clients.forEach((client) => {
        client.setAuthenticatedUserContext(accountId, accountId, true)
      })
    } else {
      this.clients.forEach((client) => {
        client.clearAuthenticatedUserContext()
      })
    }
  }

  private getProperties(): ITelemetryCustomProperties {
    return {
      app: 'CopilotDash',
      version: String(this.environment.buildVersion),
      ring: this.getRing(),
    }
  }

  private getRing(): ITelemetryCustomProperties['ring'] {
    switch (this.environment.ring) {
      case 'dev':
        return 'dev'
      case 'test':
        return 'test'
      case 'sdf':
        return 'sdf'
      case 'msit':
        return 'msit'
      case 'unknown':
        return 'unknown'
    }
  }
}
