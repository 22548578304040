import { PromiseSnapshot } from '@copilot-dash/core'
import { SymptomReportData } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { convertToSymptomReport } from './converter/convertToSymptomReport'

export function getSymptomReport(context: IDashStoreContext, ticketId: string): PromiseSnapshot<SymptomReportData> {
  return context.getOrFetch<SymptomReportData>({
    get: (state) => {
      return state.tickets[ticketId]?.symptomReport
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      ticket.symptomReport = snapshot
    },
    fetch: async () => {
      const response = await context.api.copilotDash.getTicketSymptomReport(ticketId)
      return convertToSymptomReport(response)
    },
  })
}
