import { dayjs, ITimezoneData, Times } from '@copilot-dash/core'
import { Field, Radio, RadioGroup, RadioGroupProps } from '@fluentui/react-components'
import { useState } from 'react'

export function TimezoneSelector() {
  const timezone = app.settings.timezone.use()

  const [timezones] = useState<ITimezoneData[]>(() => {
    return getTimezones(timezone)
  })

  const handleOnChange: RadioGroupProps['onChange'] = (_, data) => {
    app.settings.timezone.set(data.value)
  }

  return (
    <Field label="Timezone">
      <RadioGroup value={timezone} onChange={handleOnChange}>
        {timezones.map((timezone) => (
          <Radio
            key={timezone.value}
            value={timezone.value}
            label={`${timezone.valueLabel} (${timezone.offsetLabel})`}
          />
        ))}
      </RadioGroup>
    </Field>
  )
}

function getTimezones(currentTimezone: string): ITimezoneData[] {
  const timezones: ITimezoneData[] = [Times.getTimezoneData('UTC')]

  const local = dayjs.tz.guess()
  if (local.toLowerCase() !== 'utc') {
    timezones.push(Times.getTimezoneData(local))
  }

  const existing = timezones.find((tz) => tz.value === currentTimezone)
  if (!existing) {
    timezones.push(Times.getTimezoneData(currentTimezone))
  }

  return timezones
}
