import { makeStyles } from '@fluentui/react-components'
import { useMemo } from 'react'
import { ErrorView } from '../../../../../components/Error'
import { Row } from '../../../../../components/Layout'
import { Scrollbar } from '../../../../../components/Scrollbar/Scrollbar'
import { SymptomReport } from '../../../../../components/SymptomReport/SymptomReport'
import { SymptomReportV2 } from '../../../../../components/SymptomReportV2/SymptomReportV2'
import { TicketScreenStore } from '../../../TicketScreenStore'

export function TicketScreenSymptomReport() {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const ticketTurnId = TicketScreenStore.use((state) => state.selectedTurnId)

  if (!ticketTurnId) {
    return
  }

  return <Content ticketId={ticketId} turnId={ticketTurnId} />
}

function Content({ ticketId, turnId }: { ticketId: string; turnId: string }) {
  const styles = useStyles()
  const sessionSnapshot = app.store.use.getTicketSession(ticketId)
  const v2Enabled = app.features.v2SymptomReport.use()

  // Only last turn supports symptom report
  const isLastTurn = useMemo(() => {
    const interaction = sessionSnapshot.data?.interactions?.find((item) => item.messageId === turnId)
    return interaction?.index === 1
  }, [sessionSnapshot.data?.interactions, turnId])
  if (!isLastTurn) {
    return (
      <Row vAlign="center" hAlign="center" style={{ minHeight: '300px' }}>
        <ErrorView.Custom message="Symptom Report is unavailable for this message" level="OK" />
      </Row>
    )
  }

  if (v2Enabled) {
    return (
      <Scrollbar className={styles.root}>
        <SymptomReportV2 ticketId={ticketId} />
      </Scrollbar>
    )
  }

  return (
    <Scrollbar className={styles.root}>
      <SymptomReport ticketId={ticketId} turnId={turnId} />
    </Scrollbar>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '16px 16px',
  },
})
