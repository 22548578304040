import { ITagDefinition } from './ITagDefinition.type'
import { useSearchScreenState } from '../../store'
import { TruncateTagFunction } from './TruncateTagFunction'
import { GetTagValueTextFunction } from './GetTagValueTextFunction'
import { TagMetaDataSearchTicketArgsNames } from '@copilot-dash/store'

export const HasCopilotExtensionIdsTagConfig = (): ITagDefinition => {
  const form = useSearchScreenState((state) => state.form)
  const valueTexts = GetTagValueTextFunction(
    'Customized tag',
    'HasCopilotExtensionIds',
    form[TagMetaDataSearchTicketArgsNames.hasCopilotExtensionIds] || [],
  )

  return {
    key: 'hasCopilotExtensionIds',
    tagName: 'Has CopilotExtensionIds',
    toolTipContentValue: valueTexts.join(', '),
    truncatedTagValue: TruncateTagFunction(valueTexts),
    IsShowTag: false,
    isTagClearable: true,
  }
}
