import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { DashLoggerConfigs } from './configs/DashLoggerConfigs'
import { DashLoggerContext } from './DashLoggerContext'
import { DashLoggerTelemetry } from './DashLoggerTelemetry'
import { DashLoggerTrace } from './DashLoggerTrace'
import { IDashLogger } from './interfaces/IDashLogger'
import { IDashLoggerOptions } from './interfaces/IDashLoggerOptions'
import { IDashLoggerTelemetry } from './interfaces/IDashLoggerTelemetry'
import { IDashLoggerTrace } from './interfaces/IDashLoggerTrace'

export class DashLogger implements IDashLogger {
  readonly trace: IDashLoggerTrace
  readonly telemetry: IDashLoggerTelemetry

  constructor(options: IDashLoggerOptions) {
    const client1 = new ApplicationInsights(DashLoggerConfigs.getConfig1(options.environment))
    client1.loadAppInsights()
    client1.trackPageView()

    const client2 = new ApplicationInsights(DashLoggerConfigs.getConfig2(options.environment))
    client2.loadAppInsights()
    client2.trackPageView()

    const clients = [client1, client2]

    const context = new DashLoggerContext(clients, options)
    this.trace = new DashLoggerTrace(clients, context)
    this.telemetry = new DashLoggerTelemetry(clients, context)
  }
}
