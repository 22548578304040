import { IErrorComponents } from '../../ErrorConfigs/interfaces/IErrorComponents'
import { ErrorViewType } from '../ErrorViewType'
import { ErrorViewLayoutBlock } from './ErrorViewLayoutBlock'
import { ErrorViewLayoutInline } from './ErrorViewLayoutInline'

interface IProps {
  readonly type: ErrorViewType
  readonly components: IErrorComponents
  readonly className?: string
}

export function ErrorViewLayout({ type, components, className }: IProps) {
  switch (type) {
    case 'block':
      return <ErrorViewLayoutBlock className={className} components={components} />
    case 'inline':
      return <ErrorViewLayoutInline className={className} components={components} />
  }
}
