import { NetworkErrorCode } from './NetworkErrorCode'

export const NetworkErrorCodes = [
  {
    code: [401],
    message: `401 Unauthorized`,
    messageDetail: `You are not authorized to view this page. Please contact support if you believe this is an error.`,
  },
  {
    code: [403],
    message: `403 Forbidden`,
    messageDetail: `You do not have permission to view this page. Please contact support if you believe this is an error.`,
  },
  {
    code: [404],
    message: `404 Not Found`,
    messageDetail: `The requested resource was not found. It might have been removed, had its name changed, or is temporarily unavailable. Please verify the URL or contact support.`,
  },
  {
    code: [400, 499],
    message: (code) => `${code} Request Error`,
    messageDetail: `There was an error with your request. Please check the request parameters and try again.`,
  },
  {
    code: [500],
    message: `500 Internal Server Error`,
    messageDetail: `The server encountered an internal error and could not complete your request. Please try again later or contact support if the issue persists.`,
  },
  {
    code: [502],
    message: `502 Bad Gateway`,
    messageDetail: `The server received an invalid response from the upstream server. Please try again later or contact support if the issue persists.`,
  },
  {
    code: [503],
    message: `503 Service Unavailable`,
    messageDetail: `The server is currently unavailable due to overload or maintenance. Please try again later or contact support if the issue persists.`,
  },
  {
    code: [504],
    message: `504 Gateway Timeout`,
    messageDetail: `The server did not receive a timely response from the upstream server. Please try again later or contact support if the issue persists.`,
  },
  {
    code: [500, 599],
    message: (code) => `${code} Server Error`,
    messageDetail: `The server encountered an unexpected error and could not complete your request. Please try again later or contact support for assistance.`,
  },
  {
    code: ['default'],
    message: (code) => {
      if (code === undefined) {
        return 'Network Error'
      } else {
        return `${code} Network Error`
      }
    },
    messageDetail: `There was a network error. Please check your connection and try again later or contact support if the issue persists.`,
  },
] as const satisfies NetworkErrorCode[]
