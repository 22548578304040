import { ColumnIdNaColumnIdNameMapping, ColumnKey, INewTicketData } from '@copilot-dash/domain'
import { makeStyles } from '@fluentui/react-components'
import { RouteLink, TicketRoute } from '../../router'
import { TableCellLayoutWithStyles } from '../../screens/table/TableCellLayoutWithStyles'
import { Row } from '../Layout'
import { createExtendedTableColumn, ExtendedTableColumnDefinition } from '../Table/ExtendedTableColumn'
import { TicketLinkCopyButton } from './TicketLinkCopyButton'
const ColumnConfigTicket = (item: INewTicketData) => {
  const styles = useStyles()
  const ticketId = item.ticketId

  return (
    <TableCellLayoutWithStyles className={styles.layout}>
      <Row>
        <RouteLink
          className={styles.ticketId}
          path={TicketRoute.navigator.generatePath({ id: ticketId })}
          type="link"
          openInNewTab
        >
          {item.ticketId}
        </RouteLink>
        <TicketLinkCopyButton ticketId={ticketId} />
      </Row>
    </TableCellLayoutWithStyles>
  )
}

export const ColumnConfigTicketId: ExtendedTableColumnDefinition<INewTicketData> =
  createExtendedTableColumn<INewTicketData>({
    columnId: ColumnKey.TicketId,
    columnName: ColumnIdNaColumnIdNameMapping[ColumnKey.TicketId],
    isResizable: true,
    flex: 1,
    columnSize: {
      minWidth: 60,
      idealWidth: 90,
      padding: 0,
    },
    renderHeaderCell: () => <TableCellLayoutWithStyles truncate>Ticket ID</TableCellLayoutWithStyles>,
    renderCell: (item) => <ColumnConfigTicket {...item} />,
  })

const useStyles = makeStyles({
  layout: {
    display: 'block',
  },
  ticketId: {
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    direction: 'rtl',
    display: 'block',
  },
})
