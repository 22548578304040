import { AsyncLoader } from '@copilot-dash/core'
import { WritableDraft } from 'immer'
import { ILoginScreenActions } from './ILoginScreenActions'
import { ILoginScreenState } from './ILoginScreenState'

export function createLoginScreenActions(
  set: (callback: (state: WritableDraft<ILoginScreenState>) => void) => void,
  get: () => ILoginScreenState,
): ILoginScreenActions {
  const loginLoader = new AsyncLoader<void>()

  const login = () => {
    loginLoader.submit({
      promise: async () => {
        await application.auth.login()
      },
      onChanged: (snapshot) => {
        set((state) => {
          state.loginSnapshot = snapshot
        })
      },
    })
  }

  return {
    login,
  }
}
