import { makeStyles, Text } from '@fluentui/react-components'

export function ApiResponseTableTitle() {
  const styles = useStyles()

  return (
    <Text className={styles.text} weight="semibold">
      HTTP Response
    </Text>
  )
}

const useStyles = makeStyles({
  text: {
    padding: '8px 0',
  },
})
