import { ApiSetRootCauseRequest } from '@copilot-dash/api'
import { ITicketRootCauseData, ITicketRootCauseItem } from '@copilot-dash/domain'
import { IDashStoreContext } from '../IDashStoreContext'
import { TicketRootCauseResultConverter } from './converters/TicketRootCauseResultConverter'

interface IOptions {
  readonly items: ITicketRootCauseItem[]
  readonly updateAreaAndIssues?: boolean
}

/**
 * TODO：This name is confusing. It's actually accepting a list of root cause suggestions.
 */
export async function updateTicketRootCause(
  context: IDashStoreContext,
  ticketId: string,
  options: IOptions,
): Promise<ITicketRootCauseData> {
  const request: ApiSetRootCauseRequest = {
    ExternalTicketId: ticketId,
    RootCauseActiveList: options.items,
    UpdateAreaAndIssues: options?.updateAreaAndIssues,
  }

  const response = await context.api.logCollector.setTicketRootCausingActions(request)
  return TicketRootCauseResultConverter.fromApi(response)
}
